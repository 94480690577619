import { FieldsEnum, FormValuesT } from './constants';
import { SystemSettingsChangesT } from 'broker-admin/store/price-settings/system-settings/models';

const prepareApiSystemSettings = (formValues: FormValuesT): SystemSettingsChangesT => {
    return {
        DRIVER_WORKING_DAY: formValues[FieldsEnum.driverWorkingDay],
        DRIVER_REST_TIME: formValues[FieldsEnum.driverRestTime],
    };
};

export default prepareApiSystemSettings;
