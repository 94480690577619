import { SpotRequestDetailsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_SPOT_REQUEST_DETAILS_REQUEST =
    'broker-admin/spot-request-details/FETCH_SPOT_REQUEST_DETAILS_REQUEST';
export const FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN =
    'broker-admin/spot-request-details/FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN';
export const FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR =
    'broker-admin/spot-request-details/FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR';
export const FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS =
    'broker-admin/spot-request-details/FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS';

export const ASSIGN_BID_TO_SPOT_REQUEST = 'broker-admin/spot-request-details/ASSIGN_BID_TO_SPOT_REQUEST';

export const UPDATE_SPOT_REQUEST_REQUEST_BEGIN = 'broker-admin/spot-request-details/UPDATE_SPOT_REQUEST_REQUEST_BEGIN';
export const UPDATE_SPOT_REQUEST_REQUEST_ERROR = 'broker-admin/spot-request-details/UPDATE_SPOT_REQUEST_REQUEST_ERROR';
export const UPDATE_SPOT_REQUEST_REQUEST_SUCCESS =
    'broker-admin/spot-request-details/UPDATE_SPOT_REQUEST_REQUEST_SUCCESS';

export type SpotRequestDetailsStateT = {
    details: SpotRequestDetailsT | null;
    fetchDetailsRequestStatus: RequestStatusT;
    updateRequestStatus: RequestStatusT;
};

export type SpotRequestDetailsByIdStateT = Record<SpotRequestIdT, SpotRequestDetailsStateT>;

export type FetchSpotRequestDetailsRequestActionT = {
    type: typeof FETCH_SPOT_REQUEST_DETAILS_REQUEST;
    id: SpotRequestIdT;
    options?: FetchOptionsT;
};

export type FetchSpotRequestDetailsRequestBeginActionT = {
    type: typeof FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN;
    id: SpotRequestIdT;
};

export type FetchSpotRequestDetailsRequestErrorActionT = {
    type: typeof FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR;
    error: Error;
    id: SpotRequestIdT;
};

export type FetchSpotRequestDetailsRequestSuccessActionT = {
    type: typeof FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS;
    details: SpotRequestDetailsT | null;
    id: SpotRequestIdT;
};

export type AssignBidToSpotRequestActionT = {
    type: typeof ASSIGN_BID_TO_SPOT_REQUEST;
    id: SpotRequestIdT;
    bidId: BidIdT;
};

export type UpdateSpotRequestRequestBeginActionT = {
    type: typeof UPDATE_SPOT_REQUEST_REQUEST_BEGIN;
    id: SpotRequestIdT;
};

export type UpdateSpotRequestRequestErrorActionT = {
    type: typeof UPDATE_SPOT_REQUEST_REQUEST_ERROR;
    error: Error;
    id: SpotRequestIdT;
};

export type UpdateSpotRequestRequestSuccessActionT = {
    type: typeof UPDATE_SPOT_REQUEST_REQUEST_SUCCESS;
    id: SpotRequestIdT;
};

export type SpotRequestDetailsActionT =
    | FetchSpotRequestDetailsRequestActionT
    | FetchSpotRequestDetailsRequestBeginActionT
    | FetchSpotRequestDetailsRequestErrorActionT
    | FetchSpotRequestDetailsRequestSuccessActionT
    | UpdateSpotRequestRequestBeginActionT
    | UpdateSpotRequestRequestErrorActionT
    | UpdateSpotRequestRequestSuccessActionT;
