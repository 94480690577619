import { PartnerTypeEnum } from 'common/utils/api/models';

export enum QueryKeysEnum {
    partnersPage = 'partners-page',
    partnersFilters = 'partners-filters',
    partnersSort = 'partners-sort',
}

export enum QueryFiltersKeysEnum {
    withUpdates = 'withUpdates',
    keyAccountManagerId = 'keyAccountManagerId',
    keyAccountManagerName = 'keyAccountManagerName',
    dispatcherId = 'dispatcherId',
    dispatcherName = 'dispatcherName',
    countryCode = 'countryCode',
    partnerType = 'partnerType',
    searchText = 'searchText',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.withUpdates]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.keyAccountManagerId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.keyAccountManagerName]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dispatcherId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dispatcherName]: QueryParam.StringT;
    [QueryFiltersKeysEnum.countryCode]: QueryParam.StringT;
    [QueryFiltersKeysEnum.partnerType]: PartnerTypeEnum;
    [QueryFiltersKeysEnum.searchText]: QueryParam.StringT;
}>;
