import { put, select, takeEvery } from 'redux-saga/effects';
import { UPDATE_DOCKING_HOURS, UpdateDockingHoursActionT } from './types';
import { updateDispatchRequestBegin, updateDispatchRequestError, updateDispatchRequestSuccess } from './actions';
import { selectUpdateDispatchRequest } from './selectors';
import { logWarning } from 'common/utils/logger';

import { fetchDispatchDetails } from 'broker-admin/store/dispatch-details/actions';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { dispatchesRefreshChannel } from 'broker-admin/store/dispatches/channels';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';

function* updateDockingHoursSaga(action: UpdateDockingHoursActionT): WrapGeneratorT<void> {
    const { dispatchId, query } = action;

    if (!dispatchId) {
        logWarning('empty dispatchId');
        return;
    }

    const requestStatus: ReturnType<typeof selectUpdateDispatchRequest> = yield select(selectUpdateDispatchRequest);
    if (requestStatus.loading) {
        return;
    }

    yield put(updateDispatchRequestBegin(dispatchId));

    const [error]: ReturnApiT<typeof brokerTranziitApi.updateBrokerWindowAppointment> =
        yield brokerTranziitApi.updateBrokerWindowAppointment(dispatchId, query);

    if (error) {
        yield put(updateDispatchRequestError(dispatchId, error));
    } else {
        yield put(updateDispatchRequestSuccess(dispatchId));
    }

    yield put(fetchDispatchDetails(dispatchId, { isForceUpdate: true }));
    dispatchesRefreshChannel.emit({});
    stateMachineRefreshChannel.emit({});
}

function* dispatchEditSaga(): WrapGeneratorT<void> {
    yield takeEvery(UPDATE_DOCKING_HOURS, updateDockingHoursSaga);
}

export default dispatchEditSaga;
