import { put, select, takeEvery } from 'redux-saga/effects';
import {
    FETCH_BROKER_ORDER_DETAILS_REQUEST,
    FETCH_BROKER_ORDERS_PAGE_REQUEST,
    FetchBrokerOrderDetailsActionT,
    FetchBrokerOrdersPageActionT,
} from './types';
import {
    fetchBrokerOrderDetailsBegin,
    fetchBrokerOrderDetailsError,
    fetchBrokerOrderDetailsSuccess,
    fetchBrokerOrdersPageBegin,
    fetchBrokerOrdersPageError,
    fetchBrokerOrdersPageSuccess,
    resetBrokerOrders,
} from './actions';

import isEqual from 'lodash/isEqual';
import { selectBrokerOrdersPages, selectBrokerOrdersQuery } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';

function* fetchBrokerOrdersSaga(action: FetchBrokerOrdersPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query } = action;

    const prevQuery: ReturnType<typeof selectBrokerOrdersQuery> = yield select(selectBrokerOrdersQuery);
    const pages: ReturnType<typeof selectBrokerOrdersPages> = yield select(selectBrokerOrdersPages);
    const isSameQuery = isEqual(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetBrokerOrders());
    }

    yield put(fetchBrokerOrdersPageBegin(query, pageNumber));

    const [error, response]: ReturnApiT<typeof brokerTranziitApi.fetchBrokerOrdersPage> =
        yield brokerTranziitApi.fetchBrokerOrdersPage({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchBrokerOrdersPageError(query, pageNumber, error));
        return;
    }

    yield put(fetchBrokerOrdersPageSuccess(query, pageNumber, response));
}

function* fetchBrokerOrderDetailsSaga(action: FetchBrokerOrderDetailsActionT): WrapGeneratorT<void> {
    const { id } = action;

    if (!id) {
        return;
    }

    yield put(fetchBrokerOrderDetailsBegin(id));

    const [error, orderDetails]: ReturnApiT<typeof brokerTranziitApi.getOrderDetails> =
        yield brokerTranziitApi.getOrderDetails(id);

    if (error) {
        yield put(fetchBrokerOrderDetailsError(id, error));
        return;
    }

    yield put(fetchBrokerOrderDetailsSuccess(id, orderDetails));
}

function* ordersSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_BROKER_ORDERS_PAGE_REQUEST, fetchBrokerOrdersSaga);
    yield takeEvery(FETCH_BROKER_ORDER_DETAILS_REQUEST, fetchBrokerOrderDetailsSaga);
}

export default ordersSaga;
