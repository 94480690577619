import React from 'react';
import classNames from 'classnames/bind';

import styles from './PriceSettingsRuleFiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT } from './constants';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import {
    GroupOptionT,
    PriceComponentOptionT,
    RepeatStrategyOptionT,
} from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/models';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import ShipperDropdown from 'broker-admin/components/dropdowns/ShipperSuggest/ShipperDropdown';
import { RepeatStrategyTypeEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import TrailerTypeMultipleDropdown from 'broker-admin/components/dropdowns/TrailerTypeMultipleDropdown/TrailerTypeMultipleDropdown';
import CountriesCheckboxOptionsList from 'common/components/checkbox-options-lists/CountriesCheckboxOptionsList/CountriesCheckboxOptionsList';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import { getGroupOptions, getPriceComponentsOptions } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/utils';
import { PriceSettingsRuleFilterSidebarDataT } from 'broker-admin/layouts/SideBars/PriceSettingsRuleFiltersSidebarContent/models';
import { SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { BrokerSidebarDataT } from 'broker-admin/layouts/SideBars/models';
import { useQueryParam } from 'use-query-params';
import { createJsonParams } from 'common/utils/query';
import { QueryFiltersT, QueryKeysEnum } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/query-models';
import {
    PriceComponentTypeT,
    PriceSettingsRuleGroupEnum,
    REPEAT_STRATEGY_T_MAP,
    RepeatStrategyTypeT,
} from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/constants';

import { getQueryFilters } from './get-query-filters';

const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(Object.values(FieldsEnum));

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<PriceSettingsRuleFilterSidebarDataT, BrokerSidebarDataT>;

const PriceSettingsRuleFiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose } = props;

    const { t } = useTranslation();

    const [queryFilters, setQueryFilters] = useQueryParam<QueryFiltersT>(
        QueryKeysEnum.rulesFilters,
        createJsonParams<QueryFiltersT>({}),
    );

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(queryFilters);
        const errors = validateForm(t, values);

        return [values, errors];
    }, [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const queryFilters = getQueryFilters(values);

            setQueryFilters(queryFilters);

            formikHelpers.setTouched({});

            if (onClose) {
                onClose();
            }
        },
    });

    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    const isDisabledSubmit = !hasAnyFieldValueChanges;

    const groupOptions = getGroupOptions(t);

    const renderOption = (option: GroupOptionT | null | undefined) => (option ? <span>{option.label}</span> : null);

    const getOptionValue = (option: GroupOptionT) => option?.value;

    const priceComponentsOptions = getPriceComponentsOptions(t);

    const repeatStrategyOptions = React.useMemo(() => {
        return [
            {
                label: t(REPEAT_STRATEGY_T_MAP[RepeatStrategyTypeEnum.none]),
                value: RepeatStrategyTypeEnum.none,
            },
            {
                label: t(REPEAT_STRATEGY_T_MAP[RepeatStrategyTypeEnum.weekly]),
                value: RepeatStrategyTypeEnum.weekly,
            },
            {
                label: t(REPEAT_STRATEGY_T_MAP[RepeatStrategyTypeEnum.monthly]),
                value: RepeatStrategyTypeEnum.monthly,
            },
            {
                label: t(REPEAT_STRATEGY_T_MAP[RepeatStrategyTypeEnum.yearly]),
                value: RepeatStrategyTypeEnum.yearly,
            },
        ];
    }, [t]);

    React.useEffect(() => {
        const fromCountryCodes = formik.values[FieldsEnum.fromCountryCodes];

        if (fromCountryCodes?.length !== 1) {
            formik.setFieldValue(FieldsEnum.fromCountryZipCodes, '');
        }
    }, [formik.values[FieldsEnum.fromCountryCodes]]);

    React.useEffect(() => {
        const toCountryCodes = formik.values[FieldsEnum.toCountryCodes];

        if (toCountryCodes?.length !== 1) {
            formik.setFieldValue(FieldsEnum.toCountryZipCodes, '');
        }
    }, [formik.values[FieldsEnum.toCountryCodes]]);

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    testSelector="rule-filters"
                    title={t('price-settings.rules.filters.title')}
                    onClose={onClose}
                />
            </HeaderSideBarLayout>
            <SideBarLayout testSelector="rule-filters">
                <div className={cx('section')}>
                    <div className={cx('section__title')}>{t('price-settings.rules.filters.sections.name')}</div>
                    <div className={cx('section__block')}>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--name')}
                                name={FieldsEnum.name}
                                error={formik.errors[FieldsEnum.name]}
                                meta={formik.getFieldMeta(FieldsEnum.name)}
                                label={t('price-settings.rules.filters.fields.name.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.name}
                                        placeholder={t('price-settings.rules.filters.fields.name.placeholder')}
                                        value={formik.values[FieldsEnum.name]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasClearControl
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--group')}
                                name={FieldsEnum.group}
                                error={formik.errors[FieldsEnum.group]}
                                meta={formik.getFieldMeta(FieldsEnum.group)}
                                label={t('price-settings.rules.filters.fields.group.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <DropdownInput<GroupOptionT, PriceSettingsRuleGroupEnum | null>
                                        testSelector="group"
                                        selectedValue={formik.values[FieldsEnum.group]}
                                        placeholder={t('price-settings.rules.filters.fields.group.placeholder')}
                                        options={groupOptions}
                                        onSelect={props.onChange}
                                        renderOption={renderOption}
                                        getOptionValue={getOptionValue}
                                        overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                        hasClearControl
                                        onReset={() => {
                                            props.onChange(null);
                                        }}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        <FormikField
                            name={FieldsEnum.trailerTypeIds}
                            error={formik.errors[FieldsEnum.trailerTypeIds]}
                            meta={formik.getFieldMeta(FieldsEnum.trailerTypeIds)}
                            label={t('price-settings.rules.filters.fields.trailer-types.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <TrailerTypeMultipleDropdown
                                    values={formik.values[FieldsEnum.trailerTypeIds]}
                                    hasClearControl
                                    onSelect={props.onChange}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    placeholder={t('price-settings.rules.filters.fields.trailer-types.placeholder')}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            name={FieldsEnum.shipperId}
                            error={formik.errors[FieldsEnum.shipperId]}
                            meta={formik.getFieldMeta(FieldsEnum.shipperId)}
                            label={t('price-settings.rules.filters.fields.shipper.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <ShipperDropdown
                                    hasAllOption
                                    value={formik.values[FieldsEnum.shipperId]}
                                    initialValue={initialValues[FieldsEnum.shipperId]}
                                    initialLabel=""
                                    hasClearControl
                                    onSelect={props.onChange}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    placeholder={t('price-settings.rules.filters.fields.shipper.placeholder')}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                />
                            )}
                        </FormikField>
                    </div>
                </div>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>{t('price-settings.rules.form.sections.countries')}</div>
                    <div className={cx('section__block')}>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--countries')}
                                name={FieldsEnum.fromCountryCodes}
                                error={formik.errors[FieldsEnum.fromCountryCodes]}
                                meta={formik.getFieldMeta(FieldsEnum.fromCountryCodes)}
                                label={t('price-settings.rules.form.fields.from-country-codes.label', {
                                    count: formik.values[FieldsEnum.fromCountryCodes].length,
                                })}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <CountriesCheckboxOptionsList
                                        isShowSelectAllTrigger
                                        selectedValues={formik.values[FieldsEnum.fromCountryCodes]}
                                        hasWarning={props.hasWarning}
                                        hasError={props.hasError}
                                        onSelect={props.onChange}
                                        testSelectorPrefix="from_country_option"
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--countries')}
                                name={FieldsEnum.toCountryCodes}
                                error={formik.errors[FieldsEnum.toCountryCodes]}
                                meta={formik.getFieldMeta(FieldsEnum.toCountryCodes)}
                                label={t('price-settings.rules.form.fields.to-country-codes.label', {
                                    count: formik.values[FieldsEnum.toCountryCodes].length,
                                })}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <CountriesCheckboxOptionsList
                                        isShowSelectAllTrigger
                                        selectedValues={formik.values[FieldsEnum.toCountryCodes]}
                                        hasWarning={props.hasWarning}
                                        hasError={props.hasError}
                                        onSelect={props.onChange}
                                        testSelectorPrefix="to_country_option"
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        {/* <FieldGroup> */}
                        {/*    <FormikField */}
                        {/*        className={cx('field--zips')} */}
                        {/*        name={FieldsEnum.fromCountryZipCodes} */}
                        {/*        error={formik.errors[FieldsEnum.fromCountryZipCodes]} */}
                        {/*        meta={formik.getFieldMeta(FieldsEnum.fromCountryZipCodes)} */}
                        {/*        label={t('price-settings.rules.filters.fields.zips.label')} */}
                        {/*        setFieldValue={formik.setFieldValue} */}
                        {/*        setFieldTouched={formik.setFieldTouched} */}
                        {/*    > */}
                        {/*        {(props) => ( */}
                        {/*            <Input */}
                        {/*                name={FieldsEnum.fromCountryZipCodes} */}
                        {/*                value={formik.values[FieldsEnum.fromCountryZipCodes]} */}
                        {/*                placeholder={t( */}
                        {/*                    'price-settings.rules.filters.fields.zips.placeholder', */}
                        {/*                )} */}
                        {/*                onChange={props.onChange} */}
                        {/*                onBlur={props.onBlur} */}
                        {/*                onFocus={props.onFocus} */}
                        {/*                hasError={props.hasError} */}
                        {/*                hasWarning={props.hasWarning} */}
                        {/*            /> */}
                        {/*        )} */}
                        {/*    </FormikField> */}
                        {/*    <FormikField */}
                        {/*        className={cx('field--zips')} */}
                        {/*        name={FieldsEnum.toCountryZipCodes} */}
                        {/*        error={formik.errors[FieldsEnum.toCountryZipCodes]} */}
                        {/*        meta={formik.getFieldMeta(FieldsEnum.toCountryZipCodes)} */}
                        {/*        label={t('price-settings.rules.filters.fields.zips.label')} */}
                        {/*        setFieldValue={formik.setFieldValue} */}
                        {/*        setFieldTouched={formik.setFieldTouched} */}
                        {/*    > */}
                        {/*        {(props) => ( */}
                        {/*            <Input */}
                        {/*                name={FieldsEnum.toCountryZipCodes} */}
                        {/*                value={formik.values[FieldsEnum.toCountryZipCodes]} */}
                        {/*                placeholder={t( */}
                        {/*                    'price-settings.rules.filters.fields.zips.placeholder', */}
                        {/*                )} */}
                        {/*                onChange={props.onChange} */}
                        {/*                onBlur={props.onBlur} */}
                        {/*                onFocus={props.onFocus} */}
                        {/*                hasError={props.hasError} */}
                        {/*                hasWarning={props.hasWarning} */}
                        {/*            /> */}
                        {/*        )} */}
                        {/*    </FormikField> */}
                        {/* </FieldGroup> */}
                    </div>
                </div>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>
                        {t('price-settings.rules.filters.sections.rule-options')}
                    </div>
                    <div className={cx('section__block')}>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--price-component')}
                                name={FieldsEnum.impactPriceComponent}
                                error={formik.errors[FieldsEnum.impactPriceComponent]}
                                meta={formik.getFieldMeta(FieldsEnum.impactPriceComponent)}
                                label={t('price-settings.rules.filters.fields.impact-price-component.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <DropdownInput<PriceComponentOptionT, PriceComponentTypeT | null>
                                        selectedValue={formik.values[FieldsEnum.impactPriceComponent]}
                                        placeholder={t(
                                            'price-settings.rules.filters.fields.impact-price-component.placeholder',
                                        )}
                                        options={priceComponentsOptions}
                                        onSelect={props.onChange}
                                        renderOption={(option) => (option ? <span>{option.label}</span> : null)}
                                        getOptionValue={(option) => option?.value}
                                        overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                        testSelector="impact-price-component"
                                        hasClearControl
                                        onReset={() => {
                                            props.onChange(null);
                                        }}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--repeat-strategy')}
                                label={t('price-settings.rules.filters.fields.repeat-strategy.label')}
                                name={FieldsEnum.repeatStrategy}
                                error={formik.errors[FieldsEnum.repeatStrategy]}
                                meta={formik.getFieldMeta(FieldsEnum.repeatStrategy)}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <DropdownInput<RepeatStrategyOptionT, RepeatStrategyTypeT | null>
                                        selectedValue={formik.values[FieldsEnum.repeatStrategy]}
                                        testSelector="repeated"
                                        options={repeatStrategyOptions}
                                        onSelect={props.onChange}
                                        placeholder={t(
                                            'price-settings.rules.filters.fields.repeat-strategy.placeholder',
                                        )}
                                        renderOption={(option) => (option ? <span>{option.label}</span> : null)}
                                        getOptionValue={(option) => option.value}
                                        overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                        hasClearControl
                                        onReset={() => {
                                            props.onChange(null);
                                        }}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        {/* <FieldGroup> */}
                        {/*    <FormikField */}
                        {/*        className={cx('field--valid-date')} */}
                        {/*        name={FieldsEnum.validFrom} */}
                        {/*        error={formik.errors[FieldsEnum.validFrom]} */}
                        {/*        meta={formik.getFieldMeta(FieldsEnum.validFrom)} */}
                        {/*        label={t('price-settings.rules.form.fields.valid-from.label')} */}
                        {/*        setFieldValue={formik.setFieldValue} */}
                        {/*        setFieldTouched={formik.setFieldTouched} */}
                        {/*    > */}
                        {/*        {(props) => ( */}
                        {/*            <DatePicker */}
                        {/*                value={formik.values[FieldsEnum.validFrom]} */}
                        {/*                placeholder={t( */}
                        {/*                    'price-settings.rules.form.fields.valid-from.placeholder', */}
                        {/*                )} */}
                        {/*                onChange={props.onChange} */}
                        {/*                overlayPosition={DatePickerOverlayPositionEnum.left} */}
                        {/*            /> */}
                        {/*        )} */}
                        {/*    </FormikField> */}
                        {/*    <FormikField */}
                        {/*        className={cx('field--valid-date')} */}
                        {/*        name={FieldsEnum.validTill} */}
                        {/*        error={formik.errors[FieldsEnum.validTill]} */}
                        {/*        meta={formik.getFieldMeta(FieldsEnum.validTill)} */}
                        {/*        label={t('price-settings.rules.form.fields.valid-till.label')} */}
                        {/*        setFieldValue={formik.setFieldValue} */}
                        {/*        setFieldTouched={formik.setFieldTouched} */}
                        {/*    > */}
                        {/*        {(props) => ( */}
                        {/*            <DatePicker */}
                        {/*                value={formik.values[FieldsEnum.validTill]} */}
                        {/*                placeholder={t( */}
                        {/*                    'price-settings.rules.form.fields.valid-till.placeholder', */}
                        {/*                )} */}
                        {/*                onChange={props.onChange} */}
                        {/*                overlayPosition={DatePickerOverlayPositionEnum.right} */}
                        {/*            /> */}
                        {/*        )} */}
                        {/*    </FormikField> */}
                        {/* </FieldGroup> */}
                    </div>
                </div>
            </SideBarLayout>
            <FooterSideBarLayout isTransparent hasPaddings>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isDisabledSubmit}
                    className={cx('apply')}
                    type="submit"
                    testSelector="apply"
                >
                    {t('price-settings.rules.filters.apply')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default PriceSettingsRuleFiltersSidebarContent;
