import requestStatus from 'common/utils/request-status';
import keyBy from 'lodash/keyBy';
import {
    FETCH_MANAGERS_REQUEST_BEGIN,
    FETCH_MANAGERS_REQUEST_ERROR,
    FETCH_MANAGERS_REQUEST_SUCCESS,
    ManagersActionT,
    ManagersStateT,
} from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import { isNonNil } from 'common/utils';

const initialState: ManagersStateT = {
    request: requestStatus.initial,
    byId: {},
    allIds: [],
};

export default (state = initialState, action: ManagersActionT | DestroySessionActionT): ManagersStateT => {
    switch (action.type) {
        case FETCH_MANAGERS_REQUEST_BEGIN: {
            return {
                ...state,
                request: requestStatus.loading,
            };
        }
        case FETCH_MANAGERS_REQUEST_SUCCESS: {
            const { managers } = action;

            const allIds: Array<string> = managers.map((manager) => manager.id).filter(isNonNil);
            const byId = keyBy(managers, 'id');

            return {
                ...state,
                byId,
                allIds,
                request: requestStatus.ok,
            };
        }
        case FETCH_MANAGERS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                request: requestStatus.setError(error),
            };
        }
        case DESTROY_SESSION: {
            return initialState;
        }
        default:
            return state;
    }
};
