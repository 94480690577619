import {
    FETCH_BROKER_ORDER_DETAILS_REQUEST,
    FETCH_BROKER_ORDER_DETAILS_REQUEST_BEGIN,
    FETCH_BROKER_ORDER_DETAILS_REQUEST_ERROR,
    FETCH_BROKER_ORDER_DETAILS_REQUEST_SUCCESS,
    FETCH_BROKER_ORDERS_PAGE_REQUEST,
    FETCH_BROKER_ORDERS_PAGE_REQUEST_BEGIN,
    FETCH_BROKER_ORDERS_PAGE_REQUEST_ERROR,
    FETCH_BROKER_ORDERS_PAGE_REQUEST_SUCCESS,
    FetchBrokerOrderDetailsActionT,
    FetchBrokerOrderDetailsBeginActionT,
    FetchBrokerOrderDetailsErrorActionT,
    FetchBrokerOrderDetailsSuccessActionT,
    FetchBrokerOrdersPageActionT,
    FetchBrokerOrdersPageBeginActionT,
    FetchBrokerOrdersPageErrorActionT,
    FetchBrokerOrdersPageSuccessActionT,
    RESET_BROKER_ORDERS,
    ResetBrokerOrdersActionT,
} from './types';
import { BrokerOrderDetailsT, BrokerOrderT, FetchOrdersPageQueryT } from './models';

import { PageResponseT } from 'common/utils/pagination/models';

export const fetchBrokerOrdersPage = (
    pageNumber: PageNumberT,
    query: FetchOrdersPageQueryT,
): FetchBrokerOrdersPageActionT => ({
    type: FETCH_BROKER_ORDERS_PAGE_REQUEST,
    query,
    pageNumber,
});

export const fetchBrokerOrdersPageBegin = (
    query: FetchOrdersPageQueryT,
    pageNumber: PageNumberT,
): FetchBrokerOrdersPageBeginActionT => ({
    type: FETCH_BROKER_ORDERS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
});

export const fetchBrokerOrdersPageSuccess = (
    query: FetchOrdersPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<BrokerOrderT> | null,
): FetchBrokerOrdersPageSuccessActionT => ({
    type: FETCH_BROKER_ORDERS_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
});

export const fetchBrokerOrdersPageError = (
    query: FetchOrdersPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchBrokerOrdersPageErrorActionT => ({
    type: FETCH_BROKER_ORDERS_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
});

export const resetBrokerOrders = (): ResetBrokerOrdersActionT => ({
    type: RESET_BROKER_ORDERS,
});

export const fetchBrokerOrderDetails = (id: BrokerOrderT['id']): FetchBrokerOrderDetailsActionT => ({
    type: FETCH_BROKER_ORDER_DETAILS_REQUEST,
    id,
});

export const fetchBrokerOrderDetailsBegin = (id: BrokerOrderT['id']): FetchBrokerOrderDetailsBeginActionT => ({
    type: FETCH_BROKER_ORDER_DETAILS_REQUEST_BEGIN,
    id,
});

export const fetchBrokerOrderDetailsSuccess = (
    id: BrokerOrderT['id'],
    details: BrokerOrderDetailsT | null,
): FetchBrokerOrderDetailsSuccessActionT => ({
    type: FETCH_BROKER_ORDER_DETAILS_REQUEST_SUCCESS,
    id,
    details,
});

export const fetchBrokerOrderDetailsError = (
    id: BrokerOrderT['id'],
    error: Error,
): FetchBrokerOrderDetailsErrorActionT => ({
    type: FETCH_BROKER_ORDER_DETAILS_REQUEST_ERROR,
    error,
    id,
});
