import * as React from 'react';
import isEqual from 'lodash/isEqual';
import LinkDropOffIcon, { getLinkDropOffIconProps } from 'common/icons/LinkDropOffIcon';
import TruckDropOffIcon, { getTruckDropOffIconProps } from 'common/icons/TruckDropOffIcon';
import TrailerDropOffIcon, { getTrailerDropOffIconProps } from 'common/icons/TrailerDropOffIcon';
import { InitDropPointT } from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/AssignmentForm/models';
import NumberIcon, { NumberIconProps } from 'common/icons/NumberIcon';
import { IconMetaT } from 'common/components/GeoSuggest/GeoSuggest';

export const renderTruckDropOffIcon =
    (
        preset: 'assignment-input-normal' | 'assignment-input-selected',
        initDropPoint: InitDropPointT | null,
        truckDropOffLocation: LocationT | null,
        trailerDropOffLocation: LocationT | null,
    ) =>
    (iconMeta: IconMetaT) => {
        if (initDropPoint && isEqual(initDropPoint?.location?.point, truckDropOffLocation?.point)) {
            return (
                <NumberIcon
                    number={(initDropPoint?.index || 0) + 1}
                    {...NumberIconProps.getAssignmentControlProps(iconMeta)}
                />
            );
        }

        if (
            !isEqual(initDropPoint?.location?.point, truckDropOffLocation?.point) &&
            !isEqual(initDropPoint?.location?.point, trailerDropOffLocation?.point) &&
            isEqual(trailerDropOffLocation?.point, truckDropOffLocation?.point)
        ) {
            return <LinkDropOffIcon {...getLinkDropOffIconProps(preset)} />;
        }

        return <TruckDropOffIcon {...getTruckDropOffIconProps(preset)} />;
    };

export const renderTrailerDropOffIcon =
    (
        preset: 'assignment-input-normal' | 'assignment-input-selected',
        initDropPoint: InitDropPointT | null,
        truckDropOffLocation: LocationT | null,
        trailerDropOffLocation: LocationT | null,
    ) =>
    (iconMeta: IconMetaT) => {
        if (initDropPoint && isEqual(initDropPoint?.location?.point, trailerDropOffLocation?.point)) {
            return (
                <NumberIcon
                    number={(initDropPoint?.index || 0) + 1}
                    {...NumberIconProps.getAssignmentControlProps(iconMeta)}
                />
            );
        }

        if (
            !isEqual(initDropPoint?.location?.point, truckDropOffLocation?.point) &&
            !isEqual(initDropPoint?.location?.point, trailerDropOffLocation?.point) &&
            isEqual(trailerDropOffLocation?.point, truckDropOffLocation?.point)
        ) {
            return <LinkDropOffIcon {...getLinkDropOffIconProps(preset)} />;
        }

        return <TrailerDropOffIcon {...getTrailerDropOffIconProps(preset)} />;
    };
