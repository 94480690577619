import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    strokeColor: StyleGuideColorsEnum;
    size: number;
};

const TuneIcon: React.FC<PropsT> = (props) => {
    const { size, className, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" className={className}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M3.25 4.75h7.5v1.5h-7.5v-1.5zm3 4.5h-3v1.5h3v1.5h1.5v-4.5h-1.5v1.5zm4.5 3h-1.5v4.5h1.5v-1.5h6v-1.5h-6v-1.5zm-7.5 1.5h4.5v1.5h-4.5v-1.5zm6-4.5h7.5v1.5h-7.5v-1.5zm4.5-6h-1.5v4.5h1.5v-1.5h3v-1.5h-3v-1.5z"
                />
            </g>
        </svg>
    );
};

TuneIcon.displayName = 'TuneIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default TuneIcon;
