import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './SpotRequestStatusPill.scss';
import { useTranslation } from 'react-i18next';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { ApiSpotRequestStatusT, SpotRequestStatStatusEnum } from 'broker-admin/utils/api/spot-broker-tranziit/models';
import { SPOT_REQUEST_STATUS_T_MAP } from 'common/components/status/SpotRequestStatus/SpotRequestStatus';

type PropsT = {
    status?: ApiSpotRequestStatusT | null;
    isSymmetrical?: boolean;
};

const cx = classNames.bind(styles);

const PILL_THEME_BY_STATUS: Record<ApiSpotRequestStatusT, PillLabelThemeEnum> = {
    [SpotRequestStatStatusEnum.open]: PillLabelThemeEnum.grey,
    [SpotRequestStatStatusEnum.expired]: PillLabelThemeEnum.tomatoRed,
    [SpotRequestStatStatusEnum.accepted]: PillLabelThemeEnum.orange,
    [SpotRequestStatStatusEnum.assigned]: PillLabelThemeEnum.brandDark,
    [SpotRequestStatStatusEnum.canceled]: PillLabelThemeEnum.light,
};

const SpotRequestStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, isSymmetrical } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    const theme = PILL_THEME_BY_STATUS[status] || PillLabelThemeEnum.charcoal;

    return (
        <>
            <PillLabel isNoWrap isSymmetrical={isSymmetrical} theme={theme} testSelector="spot-request-status">
                {t(SPOT_REQUEST_STATUS_T_MAP[status])}
            </PillLabel>
        </>
    );
});

export default SpotRequestStatusPill;
