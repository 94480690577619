import { StateT } from 'broker-admin/store/models';
import { isNonNil } from 'common/utils';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import isNil from 'lodash/isNil';
import { MS_IN_HOUR } from 'common/utils/time';

const DEFAULT_RADIUSES = [1000];

export const selectAssignmentRadiuses = (state: StateT): number[] => {
    const { data: settings, fetchRequest } = state.settings;

    const settingsRadiuses: number[] = [
        settings?.FIRST_RADIUS_OF_ASSIGNMENT_FILTER?.value,
        settings?.SECOND_RADIUS_OF_ASSIGNMENT_FILTER?.value,
        settings?.THIRD_RADIUS_OF_ASSIGNMENT_FILTER?.value,
    ]
        .map((rawValue) => {
            return parseSimpleNumber(rawValue);
        })
        .filter(isNonNil);

    if (fetchRequest.ok && settings && !settingsRadiuses.filter(isNonNil).length) {
        return DEFAULT_RADIUSES;
    }

    return settingsRadiuses;
};

export const selectSpotRequestLimitMs = (state: StateT): number => {
    const value = state.settings.data?.SPOT_REQUEST_LIMIT?.value;
    if (isNil(value)) {
        return 0;
    }

    const parsedValue = parseSimpleNumber(value);
    if (parsedValue) {
        return parsedValue * MS_IN_HOUR;
    }

    return 0;
};
