import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [FieldsEnum.deadlineTimeWindow] as const;

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
    };

    return errors;
};

export default validate;
