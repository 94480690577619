export enum FieldsEnum {
    firstRadiusOfAssignmentFilter = 'FIRST_RADIUS_OF_ASSIGNMENT_FILTER',
    secondRadiusOfAssignmentFilter = 'SECOND_RADIUS_OF_ASSIGNMENT_FILTER',
    thirdRadiusOfAssignmentFilter = 'THIRD_RADIUS_OF_ASSIGNMENT_FILTER',
    timeToPickupTruck = 'TIME_TO_PICKUP_TRUCK',
    timeToDropTruck = 'TIME_TO_DROP_TRUCK',
    timeToHookTrailer = 'TIME_TO_HOOK_TRAILER',
    timeToDropTrailer = 'TIME_TO_DROP_TRAILER',
    geoMatrixCoefficient = 'GEO_MATRIX_COEFFICIENT',
    averageTruckSpeed = 'AVERAGE_TRUCK_SPEED',
}

export type FormValuesT = {
    [FieldsEnum.firstRadiusOfAssignmentFilter]: string;
    [FieldsEnum.secondRadiusOfAssignmentFilter]: string;
    [FieldsEnum.thirdRadiusOfAssignmentFilter]: string;
    [FieldsEnum.timeToHookTrailer]: string;
    [FieldsEnum.timeToDropTrailer]: string;
    [FieldsEnum.timeToPickupTruck]: string;
    [FieldsEnum.timeToDropTruck]: string;
    [FieldsEnum.geoMatrixCoefficient]: string;
    [FieldsEnum.averageTruckSpeed]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
