export enum FieldsEnum {
    createdByShipperId = 'createdByShipperId',
    createdByShipperName = 'createdByShipperName',

    createdByUserId = 'created-by-user-id',
    createdByUserName = 'created-by-user-name',

    createDates = 'createDates',

    dictTrailerTypeId = 'dictTrailerTypeId',

    pickupAddress = 'pickupAddress',
    dropoffAddress = 'dropoffAddress',

    pickupDates = 'pickupDates',
    dropoffDates = 'dropoffDates',
}

export type FormValuesT = {
    [FieldsEnum.createdByShipperId]: string | null;
    [FieldsEnum.createdByShipperName]: string | null;

    [FieldsEnum.createdByUserId]: string | null;
    [FieldsEnum.createdByUserName]: string | null;

    [FieldsEnum.createDates]: DateRangeT | null;

    [FieldsEnum.dictTrailerTypeId]: string | null;

    [FieldsEnum.pickupAddress]: string | null;
    [FieldsEnum.dropoffAddress]: string | null;

    [FieldsEnum.pickupDates]: DateRangeT | null;
    [FieldsEnum.dropoffDates]: DateRangeT | null;
};
