import { FieldsEnum, FormValuesT } from './constants';
import { SystemSettingsT } from 'broker-admin/store/price-settings/system-settings/models';

const getInitialValues = (systemSettings: SystemSettingsT | null | undefined): FormValuesT => {
    return {
        [FieldsEnum.spotRequestLimit]: systemSettings?.SPOT_REQUEST_LIMIT || '',
        [FieldsEnum.expiresSoonAlert]: systemSettings?.EXPIRES_SOON || '',
    };
};

export default getInitialValues;
