import {
    ACTIVATE_RULES_REQUEST,
    ActivationRulesActionT,
    CREATE_RULE_REQUEST,
    CREATE_RULE_REQUEST_BEGIN,
    CREATE_RULE_REQUEST_ERROR,
    CREATE_RULE_REQUEST_SUCCESS,
    CreateRuleActionT,
    CreateRuleBeginActionT,
    CreateRuleErrorActionT,
    CreateRuleSuccessActionT,
    DEACTIVATE_RULES_REQUEST,
    DeactivationRulesActionT,
    DELETE_RULES_REQUEST,
    DELETE_RULES_REQUEST_BEGIN,
    DELETE_RULES_REQUEST_ERROR,
    DELETE_RULES_REQUEST_SUCCESS,
    DeleteRulesActionT,
    DeleteRulesBeginActionT,
    DeleteRulesErrorActionT,
    DeleteRulesSuccessActionT,
    FETCH_RULE_DETAILS_REQUEST,
    FETCH_RULE_DETAILS_REQUEST_BEGIN,
    FETCH_RULE_DETAILS_REQUEST_ERROR,
    FETCH_RULE_DETAILS_REQUEST_SUCCESS,
    FETCH_RULES_PAGE_REQUEST,
    FETCH_RULES_PAGE_REQUEST_BEGIN,
    FETCH_RULES_PAGE_REQUEST_ERROR,
    FETCH_RULES_PAGE_REQUEST_SUCCESS,
    FetchRuleDetailsActionT,
    FetchRuleDetailsBeginActionT,
    FetchRuleDetailsErrorActionT,
    FetchRuleDetailsSuccessActionT,
    FetchRulesPageActionT,
    FetchRulesPageBeginActionT,
    FetchRulesPageErrorActionT,
    FetchRulesPageSuccessActionT,
    RESET_RULE_PAGES,
    ResetRulePagesActionT,
    UPDATE_RULE_REQUEST,
    UPDATE_RULE_REQUEST_BEGIN,
    UPDATE_RULE_REQUEST_ERROR,
    UPDATE_RULE_REQUEST_SUCCESS,
    UpdateRuleActionT,
    UpdateRuleBeginActionT,
    UpdateRuleErrorActionT,
    UpdateRuleSuccessActionT,
} from './types';
import { FetchRulePageQueryT, RuleDetailsT, RuleDraftT, RuleT } from './models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchRuleDetails = (ruleId: RuleIdT): FetchRuleDetailsActionT => ({
    type: FETCH_RULE_DETAILS_REQUEST,
    ruleId,
});

export const fetchRuleDetailsBegin = (ruleId: RuleIdT): FetchRuleDetailsBeginActionT => ({
    type: FETCH_RULE_DETAILS_REQUEST_BEGIN,
    ruleId,
});

export const fetchRuleDetailsSuccess = (
    ruleId: RuleIdT,
    ruleDetails: RuleDetailsT | null,
): FetchRuleDetailsSuccessActionT => ({
    type: FETCH_RULE_DETAILS_REQUEST_SUCCESS,
    ruleId,
    ruleDetails,
});

export const fetchRuleDetailsError = (ruleId: RuleIdT, error: Error): FetchRuleDetailsErrorActionT => ({
    type: FETCH_RULE_DETAILS_REQUEST_ERROR,
    ruleId,
    error,
});

export const fetchRulesPage = (
    pageNumber: PageNumberT,
    query: FetchRulePageQueryT,
    options?: FetchOptionsT,
): FetchRulesPageActionT => ({
    type: FETCH_RULES_PAGE_REQUEST,
    pageNumber,
    query,
    options,
});

export const fetchRulesPageBegin = (
    query: FetchRulePageQueryT,
    pageNumber: PageNumberT,
): FetchRulesPageBeginActionT => ({
    type: FETCH_RULES_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
});

export const fetchRulesPageSuccess = (
    query: FetchRulePageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<RuleT> | null,
): FetchRulesPageSuccessActionT => ({
    type: FETCH_RULES_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
});

export const fetchRulesPageError = (
    query: FetchRulePageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchRulesPageErrorActionT => ({
    type: FETCH_RULES_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
});

export const updateRules = (ruleId: RuleIdT, ruleDraft: RuleDraftT): UpdateRuleActionT => ({
    type: UPDATE_RULE_REQUEST,
    ruleId,
    ruleDraft,
});

export const updateRuleBegin = (): UpdateRuleBeginActionT => ({
    type: UPDATE_RULE_REQUEST_BEGIN,
});

export const updateRuleSuccess = (): UpdateRuleSuccessActionT => ({
    type: UPDATE_RULE_REQUEST_SUCCESS,
});

export const updateRuleError = (error: Error): UpdateRuleErrorActionT => ({
    type: UPDATE_RULE_REQUEST_ERROR,
    error,
});

export const createRule = (ruleDraft: RuleDraftT): CreateRuleActionT => ({
    type: CREATE_RULE_REQUEST,
    ruleDraft,
});

export const createRuleBegin = (): CreateRuleBeginActionT => ({
    type: CREATE_RULE_REQUEST_BEGIN,
});

export const createRuleSuccess = (): CreateRuleSuccessActionT => ({
    type: CREATE_RULE_REQUEST_SUCCESS,
});

export const createRuleError = (error: Error): CreateRuleErrorActionT => ({
    type: CREATE_RULE_REQUEST_ERROR,
    error,
});

export const deleteRules = (ids: Array<RuleIdT>): DeleteRulesActionT => ({
    type: DELETE_RULES_REQUEST,
    ids,
});

export const deleteRulesBegin = (): DeleteRulesBeginActionT => ({
    type: DELETE_RULES_REQUEST_BEGIN,
});

export const deleteRulesSuccess = (): DeleteRulesSuccessActionT => ({
    type: DELETE_RULES_REQUEST_SUCCESS,
});

export const deleteRulesError = (error: Error): DeleteRulesErrorActionT => ({
    type: DELETE_RULES_REQUEST_ERROR,
    error,
});

export const activateRules = (ids: Array<RuleIdT>): ActivationRulesActionT => ({
    type: ACTIVATE_RULES_REQUEST,
    ids,
});

export const deactivateRules = (ids: Array<RuleIdT>): DeactivationRulesActionT => ({
    type: DEACTIVATE_RULES_REQUEST,
    ids,
});

export const resetRulePages = (mutationListOptions: MutationListOptionsT): ResetRulePagesActionT => ({
    type: RESET_RULE_PAGES,
    mutationListOptions,
});
