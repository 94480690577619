import React from 'react';
import { useTranslation } from 'react-i18next';
import { PRICE_COMPONENT_T_MAP } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/constants';
import { checkIsPriceComponent } from '../../utils';
import { PriceComponentTypeEnum } from 'broker-admin/utils/api/broker-tranziit/models';

type PropsT = {
    priceComponent: PriceComponentTypeEnum | null | undefined;
};

const PriceComponentFilterPill: React.FC<PropsT> = React.memo((props) => {
    const { priceComponent } = props;

    const { t } = useTranslation();

    if (!priceComponent) {
        return null;
    }

    if (checkIsPriceComponent(priceComponent)) {
        return <span>{t(PRICE_COMPONENT_T_MAP[priceComponent])}</span>;
    }

    return <span>{priceComponent}</span>;
});

export default PriceComponentFilterPill;
