import React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerTypeControl.scss';

import { PartnerTypeEnum } from 'common/utils/api/models';
import NotificationLabel, {
    NotificationLabelThemeEnum,
} from 'common/components/notifications/NotificationLabel/NotificationLabel';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import DropdownControl, {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { useTranslation } from 'react-i18next';
import DropdownControlTriggerLabel from 'design-system/components/dropdowns/option/DropdownControlTriggerLabel/DropdownControlTriggerLabel';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import { fetchPartnersStats } from 'broker-admin/store/partners-stats/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectPartnersStats, selectPartnersStatsRequest } from 'broker-admin/store/partners-stats/selectors';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { partnersRefreshChannel } from 'broker-admin/store/partners/channels';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';

const cx = classNames.bind(styles);

type TypeT = PartnerTypeEnum | null;

type PropsT = {
    selectedType: TypeT;
    onSelect: (type: TypeT) => void;
    className?: string;
};

const PartnerTypeControl: React.FC<PropsT> = (props) => {
    const { selectedType, onSelect, className } = props;

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchPartnersStats());
    }, []);

    const refreshPageHandler = React.useCallback(() => {
        dispatch(fetchPartnersStats({ isForceUpdate: true }));
    }, []);
    useChannelSubscribe(partnersRefreshChannel, refreshPageHandler);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchPartnersStats({ isForceUpdate: false }));
    }, []);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const partnersStats = useSelector(selectPartnersStats);
    const partnersStatsRequest = useSelector(selectPartnersStatsRequest);

    const allUpdateCount =
        (partnersStats?.[PartnerTypeEnum.shipper]?.notVerifiedCount || 0) +
        (partnersStats?.[PartnerTypeEnum.carrier]?.notVerifiedCount || 0) +
        (partnersStats?.[PartnerTypeEnum.broker]?.notVerifiedCount || 0);

    const shippersUpdateCount = partnersStats?.[PartnerTypeEnum.shipper]?.notVerifiedCount || null;
    const carriersUpdateCount = partnersStats?.[PartnerTypeEnum.carrier]?.notVerifiedCount || null;

    const { t } = useTranslation();

    const renderNotificationIcon = (notificationsCount: number | null): React.ReactNode => {
        if (!notificationsCount) {
            return null;
        }

        if (partnersStatsRequest.loading) {
            return <ControlLoaderIcon fillColor={StyleGuideColorsEnum.gray} size={DEFAULT_ICON_SIZE} />;
        }

        return (
            <NotificationLabel isShowPlusSign count={notificationsCount} theme={NotificationLabelThemeEnum.charcoal} />
        );
    };
    const renderOption = (title: string, notificationsCount: number | null): React.ReactElement | null => {
        return <DropdownControlOptionLabel icon={renderNotificationIcon(notificationsCount)} label={title} />;
    };

    const options: Array<DropdownControlOptionT | SpecialOptionEnum> = [
        {
            label: renderOption(t('partner.filters.types.all'), allUpdateCount),
            onSelect: () => onSelect(null),
        },
        SpecialOptionEnum.separator,
        {
            label: renderOption(t('partner.filters.types.shippers'), shippersUpdateCount),
            onSelect: () => onSelect(PartnerTypeEnum.shipper),
        },
        {
            label: renderOption(t('partner.filters.types.carrier'), carriersUpdateCount),
            onSelect: () => onSelect(PartnerTypeEnum.carrier),
        },
    ];

    let triggerNode: React.ReactNode = null;
    if (selectedType === null) {
        triggerNode = (
            <DropdownControlTriggerLabel
                icon={renderNotificationIcon(allUpdateCount)}
                label={t('partner.filters.types.all')}
            />
        );
    }

    if (selectedType === PartnerTypeEnum.shipper) {
        triggerNode = (
            <DropdownControlTriggerLabel
                icon={renderNotificationIcon(shippersUpdateCount)}
                label={t('partner.filters.types.shippers')}
            />
        );
    }

    if (selectedType === PartnerTypeEnum.carrier) {
        triggerNode = (
            <DropdownControlTriggerLabel
                icon={renderNotificationIcon(carriersUpdateCount)}
                label={t('partner.filters.types.carrier')}
            />
        );
    }

    return (
        <DropdownControl
            className={className}
            options={options}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    label={triggerNode}
                    isPressed={isActive}
                    onClick={onClick}
                    reflectionTheme={ReflectionThemeEnum.light}
                    rightIcon={<ArrowsIcon />}
                />
            )}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
        />
    );
};

export default PartnerTypeControl;
