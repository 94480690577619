import { RequestStatusT } from 'common/utils/request-status';

import { ApiRFQStatsT, RFQStatsQueryT, RFQStatsT } from './models';

export const FETCH_RFQ_STATS_REQUEST = 'broker-admin/partner/rfq-stats/FETCH_RFQ_STATS_REQUEST';
export const FETCH_RFQ_STATS_REQUEST_BEGIN = 'broker-admin/partner/rfq-stats/FETCH_RFQ_STATS_REQUEST_BEGIN';
export const FETCH_RFQ_STATS_REQUEST_ERROR = 'broker-admin/partner/rfq-stats/FETCH_RFQ_STATS_REQUEST_ERROR';
export const FETCH_RFQ_STATS_REQUEST_SUCCESS = 'broker-admin/partner/rfq-stats/FETCH_RFQ_STATS_REQUEST_SUCCESS';
export const RESET = 'broker-admin/partner/rfq-stats/RESET';

export type PartnerRFQStatsStateT = {
    query: RFQStatsQueryT | null;
    stats: RFQStatsT | null;
};

export type RFQStatsStateT = {
    fetchRequest: RequestStatusT;
    byId: Record<PartnerIdT, PartnerRFQStatsStateT>;
};

export type FetchRFQStatsActionT = {
    type: typeof FETCH_RFQ_STATS_REQUEST;
    query: RFQStatsQueryT;
};

export type FetchRFQStatsBeginActionT = {
    type: typeof FETCH_RFQ_STATS_REQUEST_BEGIN;
    query: RFQStatsQueryT;
};

export type FetchRFQStatsSuccessActionT = {
    type: typeof FETCH_RFQ_STATS_REQUEST_SUCCESS;
    query: RFQStatsQueryT;
    stats: ApiRFQStatsT;
};

export type FetchRFQStatsErrorActionT = {
    type: typeof FETCH_RFQ_STATS_REQUEST_ERROR;
    query: RFQStatsQueryT;
    error: Error;
};

export type ResetRFQStatsActionT = {
    type: typeof RESET;
};

export type RFQStatsActionT =
    | FetchRFQStatsActionT
    | FetchRFQStatsBeginActionT
    | FetchRFQStatsSuccessActionT
    | FetchRFQStatsErrorActionT
    | ResetRFQStatsActionT;
