import requestStatus from 'common/utils/request-status';

import {
    FETCH_RFQ_STATS_REQUEST_BEGIN,
    FETCH_RFQ_STATS_REQUEST_ERROR,
    FETCH_RFQ_STATS_REQUEST_SUCCESS,
    PartnerRFQStatsStateT,
    RESET,
    RFQStatsActionT,
    RFQStatsStateT,
} from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import { checkIsSameQuery } from 'common/utils/pagination/utils';

export const initialPartnerState: PartnerRFQStatsStateT = {
    query: null,
    stats: null,
};

const initialState: RFQStatsStateT = {
    fetchRequest: requestStatus.initial,
    byId: {},
};

export default (state = initialState, action: RFQStatsActionT | DestroySessionActionT): RFQStatsStateT => {
    switch (action.type) {
        case FETCH_RFQ_STATS_REQUEST_BEGIN: {
            const { query } = action;

            const partnerState = state.byId[query.companyId] || initialPartnerState;

            return {
                ...state,
                fetchRequest: requestStatus.loading,
                byId: {
                    ...state.byId,
                    [query.companyId]: {
                        ...partnerState,
                        query,
                    },
                },
            };
        }
        case FETCH_RFQ_STATS_REQUEST_SUCCESS: {
            const { query, stats } = action;

            const partnerState = state.byId[query.companyId] || initialPartnerState;
            if (!checkIsSameQuery(partnerState.query, query)) {
                return state;
            }

            return {
                ...state,
                fetchRequest: requestStatus.ok,
                byId: {
                    ...state.byId,
                    [query.companyId]: {
                        ...partnerState,
                        stats,
                    },
                },
            };
        }

        case FETCH_RFQ_STATS_REQUEST_ERROR: {
            const { query, error } = action;

            const partnerState = state.byId[query.companyId] || initialPartnerState;
            if (!checkIsSameQuery(partnerState.query, query)) {
                return state;
            }

            return {
                ...state,
                fetchRequest: requestStatus.setError(error),
                byId: {
                    ...state.byId,
                    [query.companyId]: {
                        ...partnerState,
                        stats: null,
                    },
                },
            };
        }

        case RESET: {
            return {
                ...initialState,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
