import { FieldsEnum, FormValuesT } from './constants';
import { QueryFiltersT, QueryFiltersKeysEnum } from 'broker-admin/layouts/PartnersPage/query-models';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    return {
        [QueryFiltersKeysEnum.keyAccountManagerId]: values[FieldsEnum.keyAccountManagerId] || undefined,
        [QueryFiltersKeysEnum.keyAccountManagerName]: values[FieldsEnum.keyAccountManagerName] || undefined,
        [QueryFiltersKeysEnum.dispatcherId]: values[FieldsEnum.dispatcherId] || undefined,
        [QueryFiltersKeysEnum.dispatcherName]: values[FieldsEnum.dispatcherName] || undefined,
        [QueryFiltersKeysEnum.countryCode]: values[FieldsEnum.countryCode] || undefined,
    };
};

export default getQueryFilters;
