import { QueryFiltersT } from 'common/models';
import { formatPageQuerySort } from 'common/utils/page-sort';
import { BrokerOrderSortEnum, OrderStatusEnum } from 'common/utils/api/models';
import { QueryFiltersKeysEnum } from 'common/constants';
import { FetchOrdersPageQueryT } from 'broker-admin/store/orders/models';
import { PageSortT } from 'common/utils/query';

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<BrokerOrderSortEnum> | null,
): FetchOrdersPageQueryT => {
    const excludeStatuses = (queryFilters?.excludeStatuses || []) as OrderStatusEnum[];
    const dictTrailerId = queryFilters?.[QueryFiltersKeysEnum.dictTrailerId];

    return {
        dispatcherId: queryFilters?.[QueryFiltersKeysEnum.dispatcherId],
        keyAccountManagerId: queryFilters?.[QueryFiltersKeysEnum.keyAccountManagerId],
        carrierId: queryFilters?.[QueryFiltersKeysEnum.carrierId],
        shipperId: queryFilters?.[QueryFiltersKeysEnum.shipperId],
        dictTrailerId: typeof dictTrailerId === 'string' ? +dictTrailerId : undefined,
        destination: queryFilters?.[QueryFiltersKeysEnum.dropoffAddress],
        origin: queryFilters?.[QueryFiltersKeysEnum.pickupAddress],
        // TODO backend task https://tranziit.atlassian.net/browse/TZT-2643
        // ?? truck emission
        // TODO backend task https://tranziit.atlassian.net/browse/TZT-2643
        // createdDateFrom: queryFilters?.[QueryFiltersKeysEnum.createdDateFrom],
        // TODO backend task https://tranziit.atlassian.net/browse/TZT-2643
        // createdDateTo: queryFilters?.[QueryFiltersKeysEnum.createdDateTo],
        dropOffDockingHoursFrom: queryFilters?.[QueryFiltersKeysEnum.dropOffDateFrom],
        dropOffDockingHoursTo: queryFilters?.[QueryFiltersKeysEnum.dropOffDateTo],
        pickupDockingHoursFrom: queryFilters?.[QueryFiltersKeysEnum.pickupDateFrom],
        pickupDockingHoursTo: queryFilters?.[QueryFiltersKeysEnum.pickupDateTo],
        excludeStatuses: [...excludeStatuses],

        // TODO backend task https://tranziit.atlassian.net/browse/TZT-2643
        // ...getQueryFromQuickFilters(queryFilters?.[QueryFiltersKeysEnum.quickFilter]),

        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
