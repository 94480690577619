import {
    DispatchDetailsActionT,
    DispatchDetailsByIdStateT,
    DispatchDetailsStateT,
    FETCH_DISPATCH_DETAILS_REQUEST_BEGIN,
    FETCH_DISPATCH_DETAILS_REQUEST_ERROR,
    FETCH_DISPATCH_DETAILS_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import isEqual from 'lodash/isEqual';

const initialState: DispatchDetailsByIdStateT = {};

export const initialDispatchDetailsState: DispatchDetailsStateT = {
    dispatchDetails: null,
    requestStatus: requestStatus.initial,
};

export default (
    state = initialState,
    action: DispatchDetailsActionT | DestroySessionActionT,
): DispatchDetailsByIdStateT => {
    switch (action.type) {
        case FETCH_DISPATCH_DETAILS_REQUEST_BEGIN: {
            const { id } = action;

            const dispatchDetailsState = state[id] || initialDispatchDetailsState;

            return {
                ...state,
                [id]: {
                    ...dispatchDetailsState,
                    requestStatus: requestStatus.loading,
                },
            };
        }

        case FETCH_DISPATCH_DETAILS_REQUEST_ERROR: {
            const { error, id } = action;

            const dispatchDetailsState = state[id] || initialDispatchDetailsState;

            return {
                ...state,
                [id]: {
                    ...dispatchDetailsState,
                    requestStatus: requestStatus.setError(error),
                },
            };
        }

        case FETCH_DISPATCH_DETAILS_REQUEST_SUCCESS: {
            const { dispatchDetails, id } = action;

            const dispatchDetailsState = state[id] || initialDispatchDetailsState;

            return {
                ...state,
                [id]: {
                    ...dispatchDetailsState,
                    requestStatus: requestStatus.ok,
                    dispatchDetails: isEqual(dispatchDetails, dispatchDetailsState.dispatchDetails)
                        ? dispatchDetailsState.dispatchDetails
                        : dispatchDetails,
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
