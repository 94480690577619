import * as React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './OriginLocationPin.scss';
import PickedPinIcon from 'common/icons/PickedPinIcon';
import OriginPinIcon from 'common/icons/OriginPinIcon';

type PropsT = {
    className?: string;
    isVisited?: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    lat?: number;
    // eslint-disable-next-line react/no-unused-prop-types
    lng?: number;
};

const cx = classNames.bind(styles);

const OriginLocationPin: React.FC<PropsT> = React.memo((props) => {
    const { isVisited, className } = props;

    const OriginIconComponent = isVisited ? PickedPinIcon : OriginPinIcon;

    return <OriginIconComponent key="origin" className={cs(cx('location'), className)} />;
});

export default OriginLocationPin;
