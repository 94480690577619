import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './StatusCell.scss';
import { BrokerOrderT } from 'broker-admin/store/orders/models';
import OrderStatusPill from 'common/components/status-pill/OrderStatusPill/OrderStatusPill';

type PropsT = {
    order: BrokerOrderT;
};

const cx = classNames.bind(styles);

const StatusCell: React.FC<PropsT> = React.memo((props) => {
    const { order } = props;

    return <OrderStatusPill status={order?.status} />;
});

export default StatusCell;
