import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './CancelSpotRequest.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import TimeLeftForStart from 'common/layouts/sidebar-footers/TimeLeftForStart/TimeLeftForStart';
import { useDispatch, useSelector } from 'react-redux';
import { cancelSpotRequestByDispatch } from 'broker-admin/store/spot/actions';
import { selectSpotUpdateRequestStatus } from 'broker-admin/store/spot/selectors';
import { logWarning } from 'common/utils/logger';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import CancelSpotRequestConfirmation, {
    CancelSpotRequestConfirmationDataT,
} from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/dialogs/CancelSpotRequestConfirmation/CancelSpotRequestConfirmation';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { findActualTour } from 'broker-admin/store/dispatch-details/utils/find-actual-tour';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    dispatchDetails: DispatchDetailsT | null | undefined;
    isDisableAnyActions: boolean;
};

const CancelSpotRequest: React.FC<PropsT> = React.memo((props) => {
    const { dispatchDetails, isDisableAnyActions } = props;

    const requestStatus = useSelector(selectSpotUpdateRequestStatus);
    const dispatch = useDispatch();

    const cancelSpotRequestConfirmation = useModalDialog<CancelSpotRequestConfirmationDataT>();
    const handleCancelSpotRequest = (dispatchId: DispatchIdT) => {
        cancelSpotRequestConfirmation.setData({
            dispatchId,
        });
    };
    const handleConfirmCancelSpotRequest = (dispatchId: DispatchIdT) => {
        dispatch(cancelSpotRequestByDispatch(dispatchId));
    };

    useCloseModalDialogAfterRequest(requestStatus, [cancelSpotRequestConfirmation]);

    const { t } = useTranslation();

    if (!dispatchDetails) {
        return null;
    }

    const handleCancelSpot = () => {
        if (!dispatchDetails?.id) {
            logWarning('empty dispatchDetails.id');
            return;
        }

        handleCancelSpotRequest(dispatchDetails.id);
    };

    const actualTour = findActualTour(dispatchDetails?.tours);
    const firstWaypoint = actualTour?.waypoints?.[0] || null;
    const deadline = firstWaypoint?.correctedDateTimeFrom || firstWaypoint?.originalDateTimeFrom || null;

    return (
        <>
            <TimeLeftForStart
                deadline={deadline}
                alreadyStartedLabel={t('assignment.tour-already-started')}
                deadlineLabel={t('assignment.to-assign')}
                renderActions={(isDisabled) => (
                    <Button
                        theme={ButtonThemeEnum.secondary}
                        className={cx('action')}
                        onClick={handleCancelSpot}
                        isDisabled={isDisabled || isDisableAnyActions}
                        isLoading={requestStatus.loading}
                    >
                        {t('dispatch-details.cancel-spot.actions.cancel')}
                    </Button>
                )}
            />
            <CancelSpotRequestConfirmation
                data={cancelSpotRequestConfirmation.data}
                onConfirmation={handleConfirmCancelSpotRequest}
                onCancel={cancelSpotRequestConfirmation.onCancel}
                onClose={cancelSpotRequestConfirmation.onClose}
            />
        </>
    );
});

export default CancelSpotRequest;
