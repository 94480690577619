import * as React from 'react';

import { Route, Switch } from 'react-router-dom';

import SpotRequestListPage from 'broker-admin/layouts/SpotRequestsPage/SpotRequestListPage/SpotRequestListPage';
import SpotRequestDetailsPage from 'broker-admin/layouts/SpotRequestsPage/SpotRequestDetailsPage/SpotRequestDetailsPage';
import { SpotRoutesEnum } from 'broker-admin/constants';

type PropsT = {};

const SpotRequestsPage: React.FC<PropsT> = React.memo(() => {
    return (
        <Switch>
            <Route path={SpotRoutesEnum.spotRequestDetails}>
                <SpotRequestDetailsPage />
            </Route>
            <Route path="*">
                <SpotRequestListPage />
            </Route>
        </Switch>
    );
});

export default SpotRequestsPage;
