import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './PartnersTable.scss';
import { useTranslation } from 'react-i18next';
import LocationCell from './cell-renderers/LocationCell/LocationCell';
import TypeCell from './cell-renderers/TypeCell/TypeCell';
import Table, { TableColumnT, TableRowMetaT, TableRowModsEnum } from 'common/components/Table/Table';
import { PartnerT } from 'broker-admin/store/partners/models';
import ControlsCell from './cell-renderers/ControlsCell/ControlsCell';
import NameCell from './cell-renderers/NameCell/NameCell';
import ContactCell from './cell-renderers/ContactCell/ContactCell';
import { PartnerTypeEnum } from 'common/utils/api/models';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import { checkAllVerified } from 'broker-admin/layouts/PartnersPage/PartnersTable/utils';
import LockIcon from 'common/icons/LockIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { isNonNil } from 'common/utils';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

const cx = classNames.bind(styles);

type PropsT = {
    partners: Array<PartnerT>;
    className?: string;
    goToPartnerDetails: (event: React.MouseEvent, partnerType: PartnerTypeEnum, partnerId: PartnerIdT) => void;
    isLoading: boolean;
    canDeletePartner: boolean;
};

const PartnersTable: React.FC<PropsT> = React.memo((props) => {
    const { partners, className, goToPartnerDetails, isLoading, canDeletePartner } = props;

    const { t } = useTranslation();

    const handleDelete = () => {
        // TODO
    };

    const columns: Array<TableColumnT<PartnerT, void>> = [
        {
            renderHeader: () => <span>{t('partners-table.columns.name')}</span>,
            headerClassName: cx('table__header', 'table__header--name'),
            render: (partner: PartnerT) => <NameCell partner={partner} />,
            className: cx('table__column--name'),
        },
        {
            renderHeader: () => <span>{t('partners-table.columns.location')}</span>,
            headerClassName: cx('table__header'),
            render: (partner: PartnerT) => <LocationCell partner={partner} />,
            className: cx('table__column--any'),
        },
        {
            renderHeader: () => <span>{t('partners-table.columns.contact')}</span>,
            headerClassName: cx('table__header'),
            render: (partner: PartnerT) => <ContactCell partner={partner} />,
            className: cx('table__column--any'),
        },
        {
            renderHeader: () => <span>{t('partners-table.columns.type')}</span>,
            headerClassName: cx('table__header', 'table__header--type'),
            render: (partner: PartnerT) => <TypeCell partner={partner} />,
            className: cx('table__column--type'),
        },
        {
            renderHeader: () => null,
            render: (partner: PartnerT) => {
                if (partner?.locked) {
                    return (
                        <Tooltip
                            position={TooltipPositionEnum.centerLeft}
                            theme={TooltipThemeEnum.black}
                            className={cx('lock')}
                            tooltipNode={
                                <TooltipContent theme={TooltipContentThemeEnum.black}>
                                    {t('partners-table.locked-partner')}
                                </TooltipContent>
                            }
                        >
                            {(isShow, childrenClassName) => (
                                <LockIcon
                                    className={childrenClassName}
                                    fillColor={StyleGuideColorsEnum.charcoal}
                                    size={DEFAULT_ICON_SIZE}
                                />
                            )}
                        </Tooltip>
                    );
                }

                return null;
            },
            className: cx('table__column--controls'),
        },
        canDeletePartner
            ? {
                  renderHeader: () => null,
                  render: (partner: PartnerT) => (
                      <>
                          <ControlsCell onDelete={handleDelete} canDeletePartner={canDeletePartner} />
                      </>
                  ),
                  className: cx('table__column--controls'),
              }
            : null,
    ].filter(isNonNil);

    const handleSelectRow = (event: React.MouseEvent, partner: PartnerT) => {
        goToPartnerDetails(event, partner.profileType[0] as PartnerTypeEnum, partner.id);
    };

    const getRowMods = (meta: TableRowMetaT, partner: PartnerT) => {
        const isAllVerified = checkAllVerified(partner);
        return {
            [TableRowModsEnum.hasLeftGreenBorder]: false,
            [TableRowModsEnum.hasLeftRedBorder]: false,
            [TableRowModsEnum.hasLeftGreyBorder]: !isAllVerified,
            [TableRowModsEnum.isSelected]: false,
            [TableRowModsEnum.isHighlighted]: !isAllVerified,
        };
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<PartnerT> tableName="partners" isLoading={isLoading} rows={partners}>
                {(rows, isUsedPrevRows) => (
                    <Table<PartnerT, void>
                        className={className}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        onSelectRow={handleSelectRow}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default PartnersTable;
