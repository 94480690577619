import { put, select, takeEvery } from 'redux-saga/effects';
import {
    CREATE_PARTNER_REQUEST,
    CreatePartnerRequestActionT,
    FETCH_PARTNERS_PAGE_REQUEST,
    FetchPartnersPageActionT,
    INVITE_PARTNER_REQUEST,
    InvitePartnerRequestActionT,
} from './types';
import {
    createPartnerRequestBegin,
    createPartnerRequestError,
    createPartnerRequestSuccess,
    fetchPartnersPageBegin,
    fetchPartnersPageError,
    fetchPartnersPageSuccess,
    resetPartners,
} from './actions';
import { selectPartnersPages, selectPartnersQuery } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import { partnersPaginationChannel, partnersRefreshChannel } from 'broker-admin/store/partners/channels';

function* fetchPartnersSaga(action: FetchPartnersPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options } = action;

    const prevQuery: ReturnType<typeof selectPartnersQuery> = yield select(selectPartnersQuery);
    const pages: ReturnType<typeof selectPartnersPages> = yield select(selectPartnersPages);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetPartners({ savingPageNumber: pageNumber }));
    }

    yield put(fetchPartnersPageBegin(query, pageNumber));

    const [error, response]: ReturnApiT<typeof brokerTranziitApi.fetchPartnersPage> =
        yield brokerTranziitApi.fetchPartnersPage({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchPartnersPageError(query, pageNumber, error));
        return;
    }

    yield put(fetchPartnersPageSuccess(query, pageNumber, response));

    checkShouldEmitChangePage(pageNumber, response, partnersPaginationChannel);
}

function* invitePartnerSaga(action: InvitePartnerRequestActionT): WrapGeneratorT<void> {
    const { data } = action;

    yield put(createPartnerRequestBegin());

    const [error]: ReturnApiT<typeof brokerTranziitApi.invitePartner> = yield brokerTranziitApi.invitePartner(data);
    if (error) {
        yield put(createPartnerRequestError(error));
        return;
    }

    yield put(createPartnerRequestSuccess());

    partnersRefreshChannel.emit({});
}

function* createPartnerSaga(action: CreatePartnerRequestActionT): WrapGeneratorT<void> {
    const { data } = action;

    yield put(createPartnerRequestBegin());

    const [error]: ReturnApiT<typeof brokerTranziitApi.createPartner> = yield brokerTranziitApi.createPartner(data);
    if (error) {
        yield put(createPartnerRequestError(error));
        return;
    }

    yield put(createPartnerRequestSuccess());

    partnersRefreshChannel.emit({});
}

function* partnersSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_PARTNERS_PAGE_REQUEST, fetchPartnersSaga);
    yield takeEvery(INVITE_PARTNER_REQUEST, invitePartnerSaga);
    yield takeEvery(CREATE_PARTNER_REQUEST, createPartnerSaga);
}

export default partnersSaga;
