import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './SpotSettingsForm.scss';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import prepareApiSystemSettings from './prepare-system-settings';
import NumberInput from 'common/components/NumberInput/NumberInput';
import withWidgetChangesDetector from 'common/components/SaveChangesNotification/withWidgetChangesDetector';
import { UnitTypeEnum } from 'common/constants';
import Widget from 'common/components/Widget/Widget';
import values from 'lodash/values';
import { getMaskByType } from '../utils';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import HiddenSubmitButtonForKeyboardEnter from 'common/components/HiddenEnterSubmitButton/HiddenSubmitButtonForKeyboardEnter';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { SystemSettingsFormCommonPropsT } from 'broker-admin/layouts/SettingsPage/SystemSettings/models';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';

const cx = classNames.bind(styles);

const UNIT_TYPE_MAP = {
    [FieldsEnum.spotRequestLimit]: UnitTypeEnum.hoursAbbreviation,
    [FieldsEnum.expiresSoonAlert]: UnitTypeEnum.hoursAbbreviation,
};

const STEP_MAP = {
    [FieldsEnum.spotRequestLimit]: 1,
    [FieldsEnum.expiresSoonAlert]: 1,
};

const FIELD_LABEL_T_MAP = {
    [FieldsEnum.spotRequestLimit]: 'price-settings.spot-form.fields.spot-request-limit.label',
    [FieldsEnum.expiresSoonAlert]: 'price-settings.spot-form.fields.expires-soon-alert.label',
};

const FIELD_DESCRIPTION_T_MAP = {
    [FieldsEnum.spotRequestLimit]: 'price-settings.spot-form.fields.spot-request-limit.description',
    [FieldsEnum.expiresSoonAlert]: 'price-settings.spot-form.fields.expires-soon-alert.description',
};

type PropsT = SystemSettingsFormCommonPropsT;

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

type NumberFieldsT = FieldsEnum.spotRequestLimit | FieldsEnum.expiresSoonAlert;

const SpotSettingsForm: React.FC<PropsT> = (props) => {
    const { systemSettings, title, onUpdate, isLoading } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(systemSettings);
        const errors = validateForm(t, values);

        return [values, errors];
    }, [systemSettings]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const apiSystemSettings = prepareApiSystemSettings(values);
            onUpdate(apiSystemSettings);

            formikHelpers.setTouched({});
        },
    });

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);
    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormCallbacks) {
            remoteFormActionsContext.setRemoteFormCallbacks({
                submit: formik.submitForm,
                reset: formik.resetForm,
            });
        }
    }, [formik.submitForm, formik.resetForm]);

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);
    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormState) {
            remoteFormActionsContext.setRemoteFormState({
                hasChanges: hasAnyFieldValueChanges,
                hasErrors: hasFormAnyErros,
            });
        }
    }, [hasFormAnyErros, hasAnyFieldValueChanges]);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormRequest) {
            remoteFormActionsContext.setRemoteFormRequest({
                isLoading,
            });
        }
    }, [isLoading]);

    const WidgetChangesDetector = withWidgetChangesDetector(formik.values, initialValues);

    const renderNumberField = (name: NumberFieldsT): React.ReactNode => {
        return (
            <FormikField
                className={cx('field')}
                name={name}
                error={formik.errors[name]}
                meta={formik.getFieldMeta(name)}
                label={t(FIELD_LABEL_T_MAP[name])}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                tooltipNode={
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t(FIELD_DESCRIPTION_T_MAP[name])}
                    </TooltipContent>
                }
            >
                {(props) => (
                    <NumberInput
                        name={name}
                        unitType={UNIT_TYPE_MAP[name]}
                        mask={getMaskByType(UNIT_TYPE_MAP[name])}
                        step={STEP_MAP[name]}
                        value={formik.values[name]}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        hasChanges={props.hasChanges}
                    />
                )}
            </FormikField>
        );
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <Widget className={cx('widget')} title={title} rightNode={<WidgetChangesDetector fields={ALL_FIELDS} />}>
                <div className={cx('widget__inner')}>
                    <FieldGroup>
                        {renderNumberField(FieldsEnum.spotRequestLimit)}
                        {renderNumberField(FieldsEnum.expiresSoonAlert)}
                        {/* <FieldGroupEmptyItem className={cx('field')} /> */}
                    </FieldGroup>
                    <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
                    <HiddenSubmitButtonForKeyboardEnter />
                </div>
            </Widget>
        </form>
    );
};

export default React.memo(SpotSettingsForm) as typeof SpotSettingsForm;
