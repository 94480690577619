import * as React from 'react';

import { Route, Switch } from 'react-router-dom';

import { DispatchesRoutesEnum } from 'broker-admin/constants';

import DispatchDetailsPage from './DispatchDetailsPage/DispatchDetailsPage';
import DispatchListPage from './DispatchListPage/DispatchListPage';

type PropsT = {};

const DispatchesPage: React.FC<PropsT> = React.memo((props) => {
    return (
        <Switch>
            <Route path={DispatchesRoutesEnum.dispatchDetails}>
                <DispatchDetailsPage />
            </Route>
            <Route path="*">
                <DispatchListPage />
            </Route>
        </Switch>
    );
});

export default DispatchesPage;
