import * as React from 'react';
import { useCallback } from 'react';

import classNames from 'classnames/bind';
import styles from './PartnersPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import { useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import { ApiInvitePartnerRequestT, PartnersSortEnum, PartnerTypeEnum } from 'common/utils/api/models';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';

import Pagination from 'common/components/Table/Pagination/Pagination';
import {
    selectPartnerCreationRequest,
    selectPartnersByIds,
    selectPartnersPages,
    selectPartnersTotal,
} from 'broker-admin/store/partners/selectors';
import { PartnerT } from 'broker-admin/store/partners/models';
import { fetchPartnersPage, invitePartnerRequest } from 'broker-admin/store/partners/actions';
import PartnersTable from './PartnersTable/PartnersTable';

import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import ExpandIcon from 'common/icons/ExpandIcon';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import { selectPermissions } from 'common/store/auth/selectors';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import PartnerTypeControl from 'broker-admin/layouts/PartnersPage/PartnerTypeControl/PartnerTypeControl';
import SearchControl from 'common/components/Table/SearchControl/SearchControl';
import Switcher from 'common/components/Switcher/Switcher';
import isEmpty from 'lodash/isEmpty';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import TableError from 'common/components/Table/TableError/TableError';
import { prepareFetchPageQuery } from 'broker-admin/layouts/PartnersPage/prepare-fetch-page-query';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { useHistoryPush } from 'common/utils/hooks/useHistoryPush';
import { partnersPaginationChannel, partnersRefreshChannel } from 'broker-admin/store/partners/channels';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import { StyleGuideColorsEnum } from 'common/constants';
import { useQueryParams } from 'use-query-params';
import { useCheckOpenedSidebar, useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { BrokerSidebarsTypeEnum } from 'broker-admin/layouts/SideBars/constants';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import InvitePartnerDialog, {
    InvitePartnerModalDataT,
} from 'broker-admin/layouts/PartnersPage/dialogs/InvitePartnerDialog/InvitePartnerDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { urlFactory } from 'broker-admin/utils/urls';
import { CountryCodeT } from 'common/store/countries-dict/models';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { checkNeedRenderEmptyState } from 'common/components/Table/utils/check-need-render-empty-state';
import { checkFilters } from 'common/components/Table/utils/check-filters';

const cx = classNames.bind(styles);

type PropsT = {};

const PartnersPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { historyPush } = useHistoryPush();
    const permissions = useSelector(selectPermissions);

    const total = useSelector(selectPartnersTotal);
    const partnersPages = useSelector(selectPartnersPages);
    const partnersById = useSelector(selectPartnersByIds);

    const openLeftSidebar = useOpenLeftSidebar();

    const isOpenedFilters = useCheckOpenedSidebar(BrokerSidebarsTypeEnum.partnerFilter);
    const showFilters = React.useCallback(() => {
        openLeftSidebar({
            type: BrokerSidebarsTypeEnum.partnerFilter,
        });
    }, [openLeftSidebar]);

    const creationRequestStatus = useSelector(selectPartnerCreationRequest);

    const invitePartnerModalDialog = useModalDialog<InvitePartnerModalDataT>();
    useCloseModalDialogAfterRequest(creationRequestStatus, [invitePartnerModalDialog]);

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.partnersPage]: createPageNumberParam(),
        [QueryKeysEnum.partnersSort]: createSortParams<PartnersSortEnum>({
            value: PartnersSortEnum.name,
            direction: SortDirectionEnum.DESC,
        }),
        [QueryKeysEnum.partnersFilters]: createJsonParams<QueryFiltersT>({}),
    });

    const selectedSort = query[QueryKeysEnum.partnersSort];
    const pageNumber = query[QueryKeysEnum.partnersPage];
    const queryFilters = query[QueryKeysEnum.partnersFilters];

    const page = partnersPages[pageNumber];
    const { ids, requestStatus } = page || {};

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchPartnersPage(pageNumber, query));

        return (): void => {
            // TODO reset
        };
    }, [pageNumber, selectedSort, queryFilters]);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchPartnersPage(pageNumber, query, { isForceUpdate: true }));
    }, [pageNumber, selectedSort, queryFilters]);
    useChannelSubscribe(partnersRefreshChannel, refreshPageHandler);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchPartnersPage(pageNumber, query, { isForceUpdate: false }));
    }, [pageNumber, selectedSort, queryFilters]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const partners = React.useMemo(() => {
        return (ids || []).map((id): PartnerT => partnersById[id]);
    }, [ids, partnersById]);

    const goToPage = useCallback(
        (pageNumber: PageNumberT) => {
            changeQuery({
                [QueryKeysEnum.partnersPage]: pageNumber,
            });
        },
        [query],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof partnersPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(partnersPaginationChannel, setPageHandler);

    const goToPartnerDetails = (event: React.MouseEvent, partnerType: PartnerTypeEnum, partnerId: PartnerIdT) => {
        historyPush(event, urlFactory.partnerDetails(partnerType, partnerId));
    };

    const sortOptions: Array<SortDropdownOptionT<PartnersSortEnum>> = React.useMemo(
        () => [
            {
                label: t('partners-table.sorts.triggers.name'),
                value: PartnersSortEnum.name,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<PartnersSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.partnersPage]: 0,
            [QueryKeysEnum.partnersSort]: sort,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        const queryFilters = {
            ...selectedQueryFilters,
        };

        changeQuery({
            [QueryKeysEnum.partnersPage]: 0,
            [QueryKeysEnum.partnersFilters]: queryFilters,
        });
    };

    const partnerCreationOptions: Array<DropdownControlOptionT> = [
        {
            label: <DropdownControlOptionLabel label={t('partners-table.add-business-partner.create-manually')} />,
            onSelect: () => {
                openLeftSidebar({
                    type: BrokerSidebarsTypeEnum.createPartner,
                });
            },
        },
        {
            label: <DropdownControlOptionLabel label={t('partners-table.add-business-partner.invite-email')} />,
            onSelect: () => {
                invitePartnerModalDialog.setData({});
            },
        },
    ];

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const countriesByCode = useSelector(selectCountriesByCode);

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                render: (queryFilters) => {
                    return t('partner.filters.filters-view.key-account-manager', {
                        value: queryFilters[QueryFiltersKeysEnum.keyAccountManagerName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.keyAccountManagerId, QueryFiltersKeysEnum.keyAccountManagerName],
            },
            {
                render: (queryFilters) => {
                    return t('partner.filters.filters-view.dispatcher', {
                        value: queryFilters[QueryFiltersKeysEnum.dispatcherName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dispatcherId, QueryFiltersKeysEnum.dispatcherName],
            },
            {
                render: (queryFilters) => {
                    const countryCode = queryFilters[QueryFiltersKeysEnum.countryCode];
                    const country = countriesByCode[countryCode as CountryCodeT];

                    return t('partner.filters.filters-view.country', {
                        value: country?.userLangDisplayName || countryCode,
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.countryCode],
            },
        ],
        [countriesByCode],
    );

    const updateQueryFilters = (queryFiltersChanges: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.partnersFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            ...queryFiltersChanges,
        };

        changeQuery({
            [QueryKeysEnum.partnersPage]: 0,
            [QueryKeysEnum.partnersFilters]: queryFilters,
        });
    };

    const handleSelectPartnerType = (partnerType: PartnerTypeEnum | null) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.partnerType]: partnerType || undefined,
        });
    };

    const handleSearch = (searchText: string) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.searchText]: searchText || undefined,
        });
    };

    const toggleWithUpdates = (value: boolean) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.withUpdates]: value,
        });
    };

    const hasAppliedFilters = !isEmpty(queryFilters);

    const resetQueryFilters = () => {
        changeQuery({
            [QueryKeysEnum.partnersPage]: 0,
            [QueryKeysEnum.partnersFilters]: {},
        });
    };

    const handlePatrnerInvite = (data: ApiInvitePartnerRequestT) => {
        dispatch(invitePartnerRequest(data));
    };

    const renderTableMessage = () => {
        if (!checkNeedRenderEmptyState(partners, requestStatus)) {
            return null;
        }

        if (requestStatus?.error) {
            return <TableError />;
        }

        const { hasPrimaryQueryFilters, hasSecondaryQueryFilters } = checkFilters([], [queryFilters]);

        if (requestStatus?.ok && hasSecondaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('partners-table.messages.not-found.title')}
                    description={t('partners-table.messages.not-found.description')}
                    isShowAction
                    actionTitle={t('partners-table.messages.not-found.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={() => {
                        resetQueryFilters();
                    }}
                />
            );
        }

        if (requestStatus?.ok && hasPrimaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.empty}
                    title={t('partners-table.messages.empty-quick-filters.title')}
                    testSelector="empty-state-has-primary-filters"
                    isShowAction={false}
                />
            );
        }

        return (
            <TableMessage
                iconType={TableMessageIconsEnum.empty}
                title={t('partners-table.messages.empty.title')}
                description={t('partners-table.messages.empty.description')}
                isShowAction={permissions.canAddPartner}
                actionTitle={t('partners-table.messages.empty.action')}
                onActionClick={() => {
                    openLeftSidebar({
                        type: BrokerSidebarsTypeEnum.createPartner,
                    });
                }}
            />
        );
    };

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.partners')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent title={t('partners-table.title')} rightNode={<NotificationsBarTrigger />} />
                </TopBar>
                <ListPageLayout>
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                <FiltersTrigger
                                    title={t('partners-table.filters.trigger')}
                                    isActive={isOpenedFilters}
                                    onClick={showFilters}
                                    className={cx('control')}
                                />
                                <SortDropdown
                                    overlayPosition={SortDropdownOverlayPositionEnum.left}
                                    selectedValue={selectedSort}
                                    options={sortOptions}
                                    onSelect={handleSelectSort}
                                    className={cx('control')}
                                />
                                <PartnerTypeControl
                                    className={cx('control')}
                                    selectedType={queryFilters[QueryFiltersKeysEnum.partnerType] || null}
                                    onSelect={handleSelectPartnerType}
                                />
                                <div className={cx('control')}>
                                    <SearchControl
                                        placeholder={t('partners-table.search-placeholder')}
                                        searchText={queryFilters[QueryFiltersKeysEnum.searchText]}
                                        onChangeSearchText={handleSearch}
                                    />
                                </div>
                                <Switcher
                                    checked={!!queryFilters[QueryFiltersKeysEnum.withUpdates]}
                                    onToggle={toggleWithUpdates}
                                    className={cx('control')}
                                    label={t('partners-table.with-updates-only')}
                                />
                            </>
                        }
                        rightToolsNode={
                            <>
                                {permissions.canAddPartner && (
                                    <DropdownControl
                                        className={cx('control', 'control--new')}
                                        options={partnerCreationOptions}
                                        renderTrigger={(isActive, onClick) => (
                                            <Button
                                                theme={ButtonThemeEnum.primary}
                                                onClick={onClick}
                                                rightIcon={<ExpandIcon fillColor={StyleGuideColorsEnum.white} />}
                                                isPressed={isActive}
                                            >
                                                {t('partners-table.add-business-partner.trigger')}
                                            </Button>
                                        )}
                                        overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                    />
                                )}
                            </>
                        }
                        filterTagsNode={
                            <FilterPills<QueryFiltersT>
                                isCompact
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                                config={filterPillsConfig}
                            />
                        }
                    />
                    {renderTableMessage()}
                    <PartnersTable
                        isLoading={requestStatus?.loading}
                        partners={partners}
                        goToPartnerDetails={goToPartnerDetails}
                        canDeletePartner={permissions.canDeletePartner}
                    />
                </ListPageLayout>
            </ContentMargins>
            <StickyFooter>
                <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
            </StickyFooter>
            <InvitePartnerDialog
                data={invitePartnerModalDialog.data}
                onClose={invitePartnerModalDialog.onClose}
                requestStatus={creationRequestStatus}
                onInvitePartner={handlePatrnerInvite}
            />
            <SideBars />
        </ScrollableContent>
    );
});

export default PartnersPage;
