import { AnyAlert, AnyAlertPayloadT } from 'common/store/alerts/models';

export enum BrokerAlertTypeEnum {
    unknown = 'unknown',
    transportOrderAssigned = 'transport-order-assigned',
    spotRequestCancelled = 'spot-request-cancelled',
}

export type BrokerAnyAlertPayloadT =
    | AnyAlertPayloadT<
          BrokerAlertTypeEnum.unknown,
          {
              // TODO
          }
      >
    | AnyAlertPayloadT<BrokerAlertTypeEnum.spotRequestCancelled, {}>
    | AnyAlertPayloadT<BrokerAlertTypeEnum.transportOrderAssigned, {}>;

export class BrokerAnyAlert extends AnyAlert<BrokerAnyAlertPayloadT> {}
