import { RequestStatusT } from 'common/utils/request-status';

import { SystemSettingsChangesT, SystemSettingsT } from './models';

export const FETCH_SYSTEM_SETTINGS_REQUEST =
    'broker-admin/price-settings/system-settings/FETCH_SYSTEM_SETTINGS_REQUEST';
export const FETCH_SYSTEM_SETTINGS_REQUEST_BEGIN =
    'broker-admin/price-settings/system-settings/FETCH_SYSTEM_SETTINGS_REQUEST_BEGIN';
export const FETCH_SYSTEM_SETTINGS_REQUEST_ERROR =
    'broker-admin/price-settings/system-settings/FETCH_SYSTEM_SETTINGS_REQUEST_ERROR';
export const FETCH_SYSTEM_SETTINGS_REQUEST_SUCCESS =
    'broker-admin/price-settings/system-settings/FETCH_SYSTEM_SETTINGS_REQUEST_SUCCESS';

export const UPDATE_SYSTEM_SETTINGS_REQUEST =
    'broker-admin/price-settings/system-settings/UPDATE_SYSTEM_SETTINGS_REQUEST';
export const UPDATE_SYSTEM_SETTINGS_REQUEST_BEGIN =
    'broker-admin/price-settings/system-settings/UPDATE_SYSTEM_SETTINGS_REQUEST_BEGIN';
export const UPDATE_SYSTEM_SETTINGS_REQUEST_ERROR =
    'broker-admin/price-settings/system-settings/UPDATE_SYSTEM_SETTINGS_REQUEST_ERROR';
export const UPDATE_SYSTEM_SETTINGS_REQUEST_SUCCESS =
    'broker-admin/price-settings/system-settings/UPDATE_SYSTEM_SETTINGS_REQUEST_SUCCESS';

export type SystemSettingsStateT = {
    data: SystemSettingsT | null;
    fetchRequest: RequestStatusT;
    updateRequest: RequestStatusT;
};

export type FetchSystemSettingsActionT = {
    type: typeof FETCH_SYSTEM_SETTINGS_REQUEST;
    options?: FetchOptionsT;
};

export type FetchSystemSettingsBeginActionT = {
    type: typeof FETCH_SYSTEM_SETTINGS_REQUEST_BEGIN;
};

export type FetchSystemSettingsSuccessActionT = {
    type: typeof FETCH_SYSTEM_SETTINGS_REQUEST_SUCCESS;
    data: SystemSettingsT | null;
};

export type FetchSystemSettingsErrorActionT = {
    type: typeof FETCH_SYSTEM_SETTINGS_REQUEST_ERROR;
    error: Error;
};

export type UpdateSystemSettingsActionT = {
    type: typeof UPDATE_SYSTEM_SETTINGS_REQUEST;
    settingsChanges: SystemSettingsChangesT;
};

export type UpdateSystemSettingsBeginActionT = {
    type: typeof UPDATE_SYSTEM_SETTINGS_REQUEST_BEGIN;
};

export type UpdateSystemSettingsSuccessActionT = {
    type: typeof UPDATE_SYSTEM_SETTINGS_REQUEST_SUCCESS;
};

export type UpdateSystemSettingsErrorActionT = {
    type: typeof UPDATE_SYSTEM_SETTINGS_REQUEST_ERROR;
    error: Error;
};

export type SystemSettingsActionTypesT =
    | FetchSystemSettingsActionT
    | FetchSystemSettingsBeginActionT
    | FetchSystemSettingsSuccessActionT
    | FetchSystemSettingsErrorActionT
    | UpdateSystemSettingsActionT
    | UpdateSystemSettingsBeginActionT
    | UpdateSystemSettingsSuccessActionT
    | UpdateSystemSettingsErrorActionT;
