import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT, MAX_RADIUS_KM, MIN_VALID_FROM_VALID_TILL_DAYS } from './constants';
import {
    checkDateIsBeforeDay,
    checkDateIsAfterDay,
    checkIsPositiveNumberValue,
    checkMaxValue,
    checkMinValue,
    checkNotEmpty,
    checkRoundValue,
} from 'common/utils/form-validators';
import { CountriesDictStateT } from 'common/store/countries-dict/types';
import { checkIsExistCountry } from 'common/store/order-creation/utils/check-location';

import { ShipperContractDetailsT } from 'common/store/shipper-contract-details/models';
import { getDayDiff } from 'common/utils/time';

type DataT = {
    countriesByCode: CountriesDictStateT['byCode'];
    contractDetails: ShipperContractDetailsT | null;
};

const validate = (t: TFunction, values: FormValuesT, data: DataT): FormErrorsT => {
    const { countriesByCode, contractDetails } = data;

    const errors: FormErrorsT = {
        ...checkNotEmpty(
            t,
            [
                FieldsEnum.trailerDictId,
                FieldsEnum.emissionClass,

                FieldsEnum.pickUpLocation,
                FieldsEnum.pickUpLocationRadius,

                FieldsEnum.dropOffLocation,
                FieldsEnum.dropOffLocationRadius,

                FieldsEnum.validFrom,
                FieldsEnum.validTill,

                FieldsEnum.layoverLimit,
                FieldsEnum.ordersLimit,
            ],
            values,
        ),
        ...checkIsPositiveNumberValue(
            t,
            [
                FieldsEnum.pickUpLocationRadius,
                FieldsEnum.dropOffLocationRadius,
                FieldsEnum.layoverLimit,
                FieldsEnum.ordersLimit,
            ],
            values,
        ),
        ...checkMaxValue(t, [FieldsEnum.pickUpLocationRadius, FieldsEnum.dropOffLocationRadius], values, MAX_RADIUS_KM),
        ...checkMaxValue(t, [FieldsEnum.layoverLimit], values, 48),
        ...checkMinValue(t, [FieldsEnum.ordersLimit], values, 1),
        ...checkMaxValue(t, [FieldsEnum.ordersLimit], values, 100000),
        ...checkRoundValue(t, [FieldsEnum.layoverLimit, FieldsEnum.ordersLimit], values),

        ...checkDateIsBeforeDay(t, [FieldsEnum.validFrom], values, values[FieldsEnum.validTill]),
        ...checkDateIsAfterDay(t, [FieldsEnum.validFrom, FieldsEnum.validTill], values, contractDetails?.validFrom),
        ...checkDateIsBeforeDay(t, [FieldsEnum.validFrom, FieldsEnum.validTill], values, contractDetails?.validTill),
    };

    const validFrom = values[FieldsEnum.validFrom];
    const validTill = values[FieldsEnum.validTill];

    if (validFrom && validTill && getDayDiff(validFrom, validTill) < MIN_VALID_FROM_VALID_TILL_DAYS) {
        errors[FieldsEnum.validTill] = t('common:shipper-lane-details.errors.valid-till-inteval-valid-from', {
            count: MIN_VALID_FROM_VALID_TILL_DAYS,
        });
    }

    if (!checkIsExistCountry(values[FieldsEnum.dropOffLocation], countriesByCode)) {
        errors[FieldsEnum.dropOffLocation] = t('common:new-order-form.errors.unsupported-country');
    }

    if (!checkIsExistCountry(values[FieldsEnum.pickUpLocation], countriesByCode)) {
        errors[FieldsEnum.pickUpLocation] = t('common:new-order-form.errors.unsupported-country');
    }

    return errors;
};

export default validate;
