import { all, put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_STATISTICS_REQUEST, FetchStatisticsRequestActionT } from './types';
import {
    fetchDoneStatisticsBegin,
    fetchDoneStatisticsError,
    fetchDoneStatisticsSuccess,
    fetchRejectStatisticsBegin,
    fetchRejectStatisticsError,
    fetchRejectStatisticsSuccess,
    fetchStatisticsBegin,
} from './actions';
import { selectStatisticsFetchQuery } from './selectors';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsSameQuery } from 'common/utils/pagination/utils';
import { FetchStatisticsQueryT } from 'broker-admin/store/statistics/models';

function* fetchDoneStatisticsSaga(query: FetchStatisticsQueryT): WrapGeneratorT<void> {
    yield put(fetchDoneStatisticsBegin(query));

    const [error, statistic]: ReturnApiT<typeof brokerTranziitApi.getDoneStatistics> =
        yield brokerTranziitApi.getDoneStatistics(query);
    if (error) {
        yield put(fetchDoneStatisticsError(query, error));
    } else {
        yield put(fetchDoneStatisticsSuccess(query, statistic));
    }
}

function* fetchRejectStatisticsSaga(query: FetchStatisticsQueryT): WrapGeneratorT<void> {
    yield put(fetchRejectStatisticsBegin(query));

    const [error, statistic]: ReturnApiT<typeof brokerTranziitApi.getRejectStatistics> =
        yield brokerTranziitApi.getRejectStatistics(query);
    if (error) {
        yield put(fetchRejectStatisticsError(query, error));
    } else {
        yield put(fetchRejectStatisticsSuccess(query, statistic));
    }
}

function* fetchStatisticsSaga(action: FetchStatisticsRequestActionT): WrapGeneratorT<void> {
    const { query, options } = action;

    if (!query) {
        return;
    }

    const prevQuery: ReturnType<typeof selectStatisticsFetchQuery> = yield select(selectStatisticsFetchQuery);
    const isSameRequest = checkIsSameQuery(prevQuery, query);
    if (isSameRequest && !options?.isForceUpdate) {
        return;
    }

    yield put(fetchStatisticsBegin(query, options));

    yield all([fetchDoneStatisticsSaga(query), fetchRejectStatisticsSaga(query)]);
}

function* statisticsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_STATISTICS_REQUEST, fetchStatisticsSaga);
}

export default statisticsSaga;
