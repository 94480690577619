export type AssignmentVehiclesSuggestItemT = Api2.Definitions.AssetWithAvailableHooks;

export type LinksT = {
    byTruckId: Record<TruckIdT, TrailerIdT>;
    byTrailerId: Record<TrailerIdT, TruckIdT>;
};

export type BrokerAssetsSearchRequestInvalidReasonT = Api2.Definitions.BrokerAssetsSearchRequestInvalidReason;

export type AssetAssignmentInfoT = OverwriteT<Api2.Definitions.Asset, { id: TruckIdT | TrailerIdT }> &
    Omit<Api2.Definitions.AssetWithAvailableHooks, 'truck' | 'trailer'>;

export const getAssignmentVehicleLinks = (vehicles: AssignmentVehiclesSuggestItemT[]): LinksT => {
    return vehicles.reduce<LinksT>(
        (acc, vehicle) => {
            const truckId = vehicle?.truck?.id;
            const trailerId = vehicle?.trailer?.id;
            if (!truckId || !trailerId) {
                return acc;
            }

            return {
                byTruckId: {
                    ...acc.byTruckId,
                    [truckId]: trailerId,
                },
                byTrailerId: {
                    ...acc.byTrailerId,
                    [trailerId]: truckId,
                },
            };
        },
        {
            byTruckId: {},
            byTrailerId: {},
        },
    );
};

export const getTrailerById = (
    vehicles: AssignmentVehiclesSuggestItemT[],
    prevTrailerById: Record<TrailerIdT, AssetAssignmentInfoT>,
): Record<TrailerIdT, AssetAssignmentInfoT> => {
    return vehicles.reduce<Record<TrailerIdT, AssetAssignmentInfoT>>((acc, vehicle) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { trailer, truck, ...meta } = vehicle;

        const trailerId = trailer?.id;
        if (!trailerId) {
            return acc;
        }

        return {
            ...acc,
            [trailerId]: {
                assetsToHook: prevTrailerById[trailerId]?.assetsToHook,
                ...meta,
                ...trailer,
                id: trailerId,
            },
        };
    }, {});
};

export const getTruckById = (
    vehicles: AssignmentVehiclesSuggestItemT[],
    prevTruckById: Record<TruckIdT, AssetAssignmentInfoT>,
): Record<TruckIdT, AssetAssignmentInfoT> => {
    return vehicles.reduce<Record<TruckIdT, AssetAssignmentInfoT>>((acc, vehicle) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { trailer, truck, ...meta } = vehicle;

        const truckId = truck?.id;
        if (!truckId) {
            return acc;
        }

        return {
            ...acc,
            [truckId]: {
                assetsToHook: prevTruckById[truckId]?.assetsToHook,
                ...meta,
                ...truck,
                id: truckId,
            },
        };
    }, {});
};
