export enum FieldsEnum {
    spotRequestLimit = 'SPOT_REQUEST_LIMIT',
    expiresSoonAlert = 'EXPIRES_SOON_ALERT',
}

export type FormValuesT = {
    [FieldsEnum.spotRequestLimit]: string;
    [FieldsEnum.expiresSoonAlert]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
