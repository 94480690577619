import { StateT } from 'broker-admin/store/models';
import { DispatchDetailsStateT } from './types';
import { RequestStatusT } from 'common/utils/request-status';
import { initialDispatchDetailsState } from './reducer';

export const selectDispatchDetails =
    (id: DispatchIdT | null | undefined) =>
    (state: StateT): DispatchDetailsStateT['dispatchDetails'] =>
        state.dispatchDetails[id as string]?.dispatchDetails || initialDispatchDetailsState.dispatchDetails;

export const selectDispatchDetailsRequestStatus =
    (id?: DispatchIdT | null | undefined) =>
    (state: StateT): RequestStatusT =>
        state.dispatchDetails[id as string]?.requestStatus || initialDispatchDetailsState.requestStatus;
