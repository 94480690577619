import { DispatchesQuickFilterEnum } from 'common/utils/api/models';

export enum QueryFiltersKeysEnum {
    originAddress = 'originAddress',
    dropoffAddress = 'dropoffAddress',

    pickupDateFrom = 'pickupDateFrom',
    pickupDateTo = 'pickupDateTo',
    dropOffDateFrom = 'dropOffDateFrom',
    dropOffDateTo = 'dropOffDateTo',

    createdDateFrom = 'createdDateFrom',
    createdDateTo = 'createdDateTo',

    hasUnpaidOrders = 'hasUnpaidOrders',

    dictTrailerId = 'dictTrailerId',

    shipperId = 'shipperId',
    shipperName = 'shipperName',
    carrierId = 'carrierId',
    carrierName = 'carrierName',

    spotTransportOrder = 'spotTransportOrder',
    contractedTransportOrder = 'contractedTransportOrder',

    spotOrder = 'spotOrder',
    contractedOrder = 'contractedOrder',

    dispatcherId = 'dispatcherId',
    dispatcherName = 'dispatcherName',

    keyAccountManagerId = 'keyAccountManagerId',
    keyAccountManagerName = 'keyAccountManagerName',

    quickFilter = 'quickFilter',

    searchText = 'searchText',

    runningOutOfTimeToAssignCarrier = 'running-out-of-time-to-assign-carrier',
    runOutOfTimeToAssignCarrier = 'run-out-of-time-to-assign-carrier',
    runningOutOfTimeToAssignAssets = 'running-out-of-time-to-assign-assets',
    runOutOfTimeToAssignAssets = 'run-out-of-time-to-assign-assets',
    runningOutOfTimeToAssignDriver = 'running-out-of-time-to-assign-driver',
    runOutOfTimeToAssignDriver = 'run-out-of-time-to-assign-driver',
    tourDelaying = 'tour-delaying',
    noShow = 'no-show',
    shipmentMissed = 'shipment-missed',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.dropoffAddress]: QueryParam.StringT;
    [QueryFiltersKeysEnum.originAddress]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dispatcherId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dispatcherName]: QueryParam.StringT;
    [QueryFiltersKeysEnum.keyAccountManagerId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.keyAccountManagerName]: QueryParam.StringT;
    [QueryFiltersKeysEnum.shipperId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.shipperName]: QueryParam.StringT;
    [QueryFiltersKeysEnum.carrierId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.carrierName]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dictTrailerId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.hasUnpaidOrders]: QueryParam.BooleanT;

    [QueryFiltersKeysEnum.spotTransportOrder]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.contractedTransportOrder]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.spotOrder]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.contractedOrder]: QueryParam.BooleanT;

    [QueryFiltersKeysEnum.searchText]: QueryParam.StringT;
    [QueryFiltersKeysEnum.quickFilter]: DispatchesQuickFilterEnum;

    [QueryFiltersKeysEnum.runningOutOfTimeToAssignCarrier]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.runOutOfTimeToAssignCarrier]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.runningOutOfTimeToAssignAssets]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.runOutOfTimeToAssignAssets]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.runningOutOfTimeToAssignDriver]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.runOutOfTimeToAssignDriver]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.tourDelaying]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.noShow]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.shipmentMissed]: QueryParam.BooleanT;
}>;

export enum QueryKeysEnum {
    dispatchesPage = 'dispatches-page',
    dispatchesSort = 'dispatches-sort',
    dispatchesFilters = 'dispatches-filters',
}
