import { FieldsEnum, FormValuesT } from './constants';
import { SystemSettingsT } from 'broker-admin/store/price-settings/system-settings/models';

const getInitialValues = (systemSettings: SystemSettingsT | null | undefined): FormValuesT => {
    return {
        [FieldsEnum.transportationOrderHasNoDriverAlertTime]:
            systemSettings?.TRANSPORTATION_ORDER_HAS_NO_DRIVER_ALERT_TIME || '',
        [FieldsEnum.assignDriverLimitAlert]: systemSettings?.RUN_OUT_OF_TIME_TO_ASSIGN_DRIVER_LIMIT || '',
        [FieldsEnum.allowedGAPToBeDelayed]: systemSettings?.ALLOWED_GAP_TO_BE_DELAYED || '',
        [FieldsEnum.deleteNotificationsOlderDays]: systemSettings?.DELETE_NOTIFICATIONS_OLDER_DAYS || '',
        [FieldsEnum.docExpiresSoonAlert]: systemSettings?.DOC_EXPIRES_SOON_ALERT || '',
        [FieldsEnum.assignAssetLimitAlert]: systemSettings?.RUN_OUT_OF_TIME_TO_ASSIGN_ASSETS_LIMIT || '',
        [FieldsEnum.transportationOrderHasNoAssetsAlertTime]:
            systemSettings?.TRANSPORTATION_ORDER_HAS_NO_ASSETS_ALERT_TIME || '',
        [FieldsEnum.radiusAroundWaypointM]: systemSettings?.RADIUS_AROUND_WAYPOINT_METERS || '',
        [FieldsEnum.timeLimitToCancelOrderHours]: systemSettings?.TIME_LIMIT_TO_CANCEL_ORDER_HOURS || '',
        [FieldsEnum.timeLimitToCancelTransportationOrderHours]:
            systemSettings?.TIME_LIMIT_TO_CANCEL_TRANSPORTATION_ORDER_HOURS || '',
    };
};

export default getInitialValues;
