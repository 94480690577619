import { FieldsEnum, FormValuesT } from './constants';
import { PartnerTypeEnum } from 'common/utils/api/models';

const DEFAULT_VALUES: FormValuesT = {
    [FieldsEnum.name]: '',
    [FieldsEnum.partnerType]: PartnerTypeEnum.shipper,

    [FieldsEnum.street1]: '',
    [FieldsEnum.street2]: '',
    [FieldsEnum.countryCode]: null,
    [FieldsEnum.zipCode]: '',
    [FieldsEnum.city]: '',

    [FieldsEnum.legalFormId]: null,
};

const getInitialValues = (): FormValuesT => {
    const values: FormValuesT = { ...DEFAULT_VALUES };

    return values;
};

export default getInitialValues;
