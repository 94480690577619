import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './StatusCell.scss';
import ColoredStatusLabel from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import { useTranslation } from 'react-i18next';
import { RuleT } from 'broker-admin/store/price-settings/rules/models';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    rule: RuleT;
};

const cx = classNames.bind(styles);

const StatusCell: React.FC<PropsT> = React.memo((props) => {
    const { rule } = props;
    const { t } = useTranslation();

    const isActive = rule.active;

    return (
        <ColoredStatusLabel
            label={
                isActive
                    ? t('price-settings.rules.table.status.active')
                    : t('price-settings.rules.table.status.not-active')
            }
            color={isActive ? StyleGuideColorsEnum.mediumGreen : StyleGuideColorsEnum.light}
            className={cx('status')}
        />
    );
});

export default StatusCell;
