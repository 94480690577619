import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PricingSettingsForm.scss';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup, { FieldGroupEmptyItem } from 'common/components/FieldGroup/FieldGroup';
import prepareApiSystemSettings from './prepare-system-settings';
import NumberInput from 'common/components/NumberInput/NumberInput';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { PricingTypeEnum, ServiceFeeTypeEnum } from 'broker-admin/store/price-settings/system-settings/models';
import withWidgetChangesDetector from 'common/components/SaveChangesNotification/withWidgetChangesDetector';
import { UnitTypeEnum } from 'common/constants';
import Widget from 'common/components/Widget/Widget';
import values from 'lodash/values';
import { getMaskByType } from '../utils';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import HiddenSubmitButtonForKeyboardEnter from 'common/components/HiddenEnterSubmitButton/HiddenSubmitButtonForKeyboardEnter';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { SystemSettingsFormCommonPropsT } from 'broker-admin/layouts/SettingsPage/SystemSettings/models';
import times from 'lodash/times';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';

const cx = classNames.bind(styles);

type ServiceFeeTypeOptionT = {
    label: string;
    value: ServiceFeeTypeEnum;
};

type PricingTypeOptionT = {
    label: string;
    value: PricingTypeEnum;
};

const UNIT_TYPE_MAP = {
    [FieldsEnum.minimalShipmentCost]: UnitTypeEnum.euroAbbreviation,
    [FieldsEnum.driverSalaryPerHour]: UnitTypeEnum.euroAbbreviation,
    [FieldsEnum.minimalCostForTruck]: UnitTypeEnum.euroAbbreviation,
    [FieldsEnum.minimalCostForTrailer]: UnitTypeEnum.euroAbbreviation,
    [FieldsEnum.layoverCostPerHour]: UnitTypeEnum.euroAbbreviation,
    [FieldsEnum.layoverCostPerDay]: UnitTypeEnum.euroAbbreviation,
    [FieldsEnum.layoverFreeHours]: UnitTypeEnum.hoursAbbreviation,
    [FieldsEnum.urgentOverPrice]: UnitTypeEnum.euroAbbreviation,
    [FieldsEnum.overPriceForEveryAdditionalStop]: UnitTypeEnum.euroAbbreviation,
    [FieldsEnum.costPerGreenShipment]: UnitTypeEnum.euroAbbreviation,
    [FieldsEnum.tranziitServiceFeePercent]: UnitTypeEnum.percentsAbbreviation,
    [FieldsEnum.tranziitServiceFeeFixed]: UnitTypeEnum.euroAbbreviation,
    [FieldsEnum.truckConsumption]: UnitTypeEnum.litersAbbreviation,
    [FieldsEnum.reeferConsumption]: UnitTypeEnum.litersAbbreviation,
    [FieldsEnum.rateMatrixSpread]: UnitTypeEnum.percentsAbbreviation,
};

const STEP_MAP = {
    [FieldsEnum.minimalShipmentCost]: 10,
    [FieldsEnum.driverSalaryPerHour]: 1,
    [FieldsEnum.minimalCostForTruck]: 10,
    [FieldsEnum.minimalCostForTrailer]: 10,
    [FieldsEnum.layoverCostPerHour]: 10,
    [FieldsEnum.layoverCostPerDay]: 10,
    [FieldsEnum.layoverFreeHours]: 1,
    [FieldsEnum.urgentOverPrice]: 10,
    [FieldsEnum.overPriceForEveryAdditionalStop]: 10,
    [FieldsEnum.costPerGreenShipment]: 10,
    [FieldsEnum.truckConsumption]: 1,
    [FieldsEnum.reeferConsumption]: 1,
    [FieldsEnum.rateMatrixSpread]: 1,
};

const FIELD_LABEL_T_MAP = {
    [FieldsEnum.minimalShipmentCost]: 'price-settings.pricing-form.fields.minimal-cost-for-shipment.label',
    [FieldsEnum.driverSalaryPerHour]: 'price-settings.pricing-form.fields.team-driver-rate-per-hour.label',
    [FieldsEnum.layoverCostPerHour]: 'price-settings.pricing-form.fields.layover-cost-per-hour.label',
    [FieldsEnum.layoverCostPerDay]: 'price-settings.pricing-form.fields.layover-cost-per-day.label',
    [FieldsEnum.layoverFreeHours]: 'price-settings.pricing-form.fields.layover-free-hours.label',
    [FieldsEnum.urgentOverPrice]: 'price-settings.pricing-form.fields.urgentOverPrice.label',
    [FieldsEnum.overPriceForEveryAdditionalStop]:
        'price-settings.pricing-form.fields.overPriceForEveryAdditionalStop.label',
    [FieldsEnum.costPerGreenShipment]: 'price-settings.pricing-form.fields.green-shipment-cost.label',
    [FieldsEnum.tranziitServiceFeeFixedMode]: 'price-settings.pricing-form.fields.tranziit-service-fee.label',
    [FieldsEnum.pricingStrategy]: 'price-settings.pricing-form.fields.pricing.label',
    [FieldsEnum.truckConsumption]: 'price-settings.pricing-form.fields.truck-consumption.label',
    [FieldsEnum.reeferConsumption]: 'price-settings.pricing-form.fields.reef-consumption.label',
    [FieldsEnum.usePeriodicRulesForWeekdays]:
        'price-settings.pricing-form.fields.use-periodic-rules-for-weekdays.label',
    [FieldsEnum.usePeriodicRulesForMonths]: 'price-settings.pricing-form.fields.use-periodic-rules-for-months.label',
    [FieldsEnum.rateMatrixSpread]: 'price-settings.pricing-form.fields.rate-matrix-spread.label',
    [FieldsEnum.minimalCostForTruck]: 'price-settings.pricing-form.fields.minimalCostForTruck.label',
    [FieldsEnum.minimalCostForTrailer]: 'price-settings.pricing-form.fields.minimalCostForTrailer.label',
};

const FIELD_DESCRIPTION_T_MAP = {
    [FieldsEnum.minimalShipmentCost]: 'price-settings.pricing-form.fields.minimal-cost-for-shipment.description',
    [FieldsEnum.driverSalaryPerHour]: 'price-settings.pricing-form.fields.team-driver-rate-per-hour.description',
    [FieldsEnum.layoverCostPerHour]: 'price-settings.pricing-form.fields.layover-cost-per-hour.description',
    [FieldsEnum.layoverCostPerDay]: 'price-settings.pricing-form.fields.layover-cost-per-day.description',
    [FieldsEnum.layoverFreeHours]: 'price-settings.pricing-form.fields.layover-free-hours.description',
    [FieldsEnum.urgentOverPrice]: 'price-settings.pricing-form.fields.urgentOverPrice.description',
    [FieldsEnum.overPriceForEveryAdditionalStop]:
        'price-settings.pricing-form.fields.overPriceForEveryAdditionalStop.description',
    [FieldsEnum.costPerGreenShipment]: 'price-settings.pricing-form.fields.green-shipment-cost.description',
    [FieldsEnum.pricingStrategy]: 'price-settings.pricing-form.fields.pricing.description',
    [FieldsEnum.truckConsumption]: 'price-settings.pricing-form.fields.truck-consumption.description',
    [FieldsEnum.reeferConsumption]: 'price-settings.pricing-form.fields.reef-consumption.description',
    [FieldsEnum.rateMatrixSpread]: 'price-settings.pricing-form.fields.rate-matrix-spread.description',
    [FieldsEnum.minimalCostForTruck]: 'price-settings.pricing-form.fields.minimalCostForTruck.description',
    [FieldsEnum.minimalCostForTrailer]: 'price-settings.pricing-form.fields.minimalCostForTrailer.description',
};

type PropsT = SystemSettingsFormCommonPropsT;

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

type NumberFieldsT =
    | FieldsEnum.minimalShipmentCost
    | FieldsEnum.driverSalaryPerHour
    | FieldsEnum.layoverCostPerHour
    | FieldsEnum.layoverCostPerDay
    | FieldsEnum.layoverFreeHours
    | FieldsEnum.truckConsumption
    | FieldsEnum.reeferConsumption
    | FieldsEnum.urgentOverPrice
    | FieldsEnum.costPerGreenShipment
    | FieldsEnum.rateMatrixSpread
    | FieldsEnum.minimalCostForTruck
    | FieldsEnum.minimalCostForTrailer
    | FieldsEnum.overPriceForEveryAdditionalStop;

const PricingSettingsForm: React.FC<PropsT> = (props) => {
    const { systemSettings, title, onUpdate, isLoading } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(systemSettings);
        const errors = validateForm(t, values);

        return [values, errors];
    }, [systemSettings]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const apiSystemSettings = prepareApiSystemSettings(values);
            onUpdate(apiSystemSettings);

            formikHelpers.setTouched({});
        },
    });

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);
    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormCallbacks) {
            remoteFormActionsContext.setRemoteFormCallbacks({
                submit: formik.submitForm,
                reset: formik.resetForm,
            });
        }
    }, [formik.submitForm, formik.resetForm]);

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);
    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormState) {
            remoteFormActionsContext.setRemoteFormState({
                hasChanges: hasAnyFieldValueChanges,
                hasErrors: hasFormAnyErros,
            });
        }
    }, [hasFormAnyErros, hasAnyFieldValueChanges]);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormRequest) {
            remoteFormActionsContext.setRemoteFormRequest({
                isLoading,
            });
        }
    }, [isLoading]);

    const serviceFeeTypeOptions: ServiceFeeTypeOptionT[] = [
        {
            label: t('price-settings.pricing-form.tranziit-service-fee-type.percentage'),
            value: ServiceFeeTypeEnum.percent,
        },
        {
            label: t('price-settings.pricing-form.tranziit-service-fee-type.fixed'),
            value: ServiceFeeTypeEnum.fixed,
        },
    ];

    const priceTypeOptions: PricingTypeOptionT[] = [
        {
            label: t('price-settings.pricing-form.pricing-type.rate-matrix'),
            value: PricingTypeEnum.rateMatrix,
        },
        {
            label: t('price-settings.pricing-form.pricing-type.ml-based'),
            value: PricingTypeEnum.mlBased,
        },
        {
            label: t('price-settings.pricing-form.pricing-type.combined'),
            value: PricingTypeEnum.combined,
        },
    ];

    const WidgetChangesDetector = withWidgetChangesDetector(formik.values, initialValues);

    const tranziitServiceFeeType = formik.values[FieldsEnum.tranziitServiceFeeFixedMode] as ServiceFeeTypeEnum;

    const isDisabledPeriodicRulesForMonths = formik.values[FieldsEnum.pricingStrategy] !== PricingTypeEnum.rateMatrix;

    const renderNumberField = (name: NumberFieldsT): React.ReactNode => {
        return (
            <FormikField
                className={cx('field')}
                name={name}
                error={formik.errors[name]}
                meta={formik.getFieldMeta(name)}
                label={t(FIELD_LABEL_T_MAP[name])}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                tooltipNode={
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t(FIELD_DESCRIPTION_T_MAP[name])}
                    </TooltipContent>
                }
            >
                {(props) => (
                    <NumberInput
                        name={name}
                        unitType={UNIT_TYPE_MAP[name]}
                        mask={getMaskByType(UNIT_TYPE_MAP[name])}
                        step={STEP_MAP[name]}
                        value={formik.values[name]}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        hasChanges={props.hasChanges}
                    />
                )}
            </FormikField>
        );
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <Widget className={cx('widget')} title={title} rightNode={<WidgetChangesDetector fields={ALL_FIELDS} />}>
                <div className={cx('widget__inner')}>
                    <FieldGroup className={cx('field-group')}>
                        <FormikField
                            className={cx('field')}
                            label={t(FIELD_LABEL_T_MAP[FieldsEnum.pricingStrategy])}
                            name={FieldsEnum.pricingStrategy}
                            error={formik.errors[FieldsEnum.pricingStrategy]}
                            meta={formik.getFieldMeta(FieldsEnum.pricingStrategy)}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DropdownInput<PricingTypeOptionT, PricingTypeEnum>
                                    selectedValue={formik.values[FieldsEnum.pricingStrategy] as PricingTypeEnum}
                                    options={priceTypeOptions}
                                    onSelect={(value) => {
                                        if (value !== PricingTypeEnum.rateMatrix) {
                                            formik.setFieldValue(FieldsEnum.usePeriodicRulesForMonths, false);
                                        }

                                        props.onChange(value);
                                    }}
                                    renderOption={(option) => (option ? <span>{option.label}</span> : null)}
                                    getOptionValue={(option) => option.value}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                        <FieldGroup isFullWidth className={cx('field')}>
                            {tranziitServiceFeeType === ServiceFeeTypeEnum.fixed && (
                                <FormikField
                                    name={FieldsEnum.tranziitServiceFeeFixed}
                                    error={formik.errors[FieldsEnum.tranziitServiceFeeFixed]}
                                    meta={formik.getFieldMeta(FieldsEnum.tranziitServiceFeeFixed)}
                                    label={t(FIELD_LABEL_T_MAP[FieldsEnum.tranziitServiceFeeFixedMode])}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <NumberInput
                                            inputClassName={cx('combined-field--fee-value')}
                                            name={FieldsEnum.tranziitServiceFeeFixed}
                                            unitType={UNIT_TYPE_MAP[FieldsEnum.tranziitServiceFeeFixed]}
                                            mask={getMaskByType(UNIT_TYPE_MAP[FieldsEnum.tranziitServiceFeeFixed])}
                                            value={formik.values[FieldsEnum.tranziitServiceFeeFixed]}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                            )}
                            {tranziitServiceFeeType === ServiceFeeTypeEnum.percent && (
                                <FormikField
                                    name={FieldsEnum.tranziitServiceFeePercent}
                                    error={formik.errors[FieldsEnum.tranziitServiceFeePercent]}
                                    meta={formik.getFieldMeta(FieldsEnum.tranziitServiceFeePercent)}
                                    label={t(FIELD_LABEL_T_MAP[FieldsEnum.tranziitServiceFeeFixedMode])}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <NumberInput
                                            inputClassName={cx('combined-field--fee-value')}
                                            name={FieldsEnum.tranziitServiceFeePercent}
                                            unitType={UNIT_TYPE_MAP[FieldsEnum.tranziitServiceFeePercent]}
                                            mask={getMaskByType(UNIT_TYPE_MAP[FieldsEnum.tranziitServiceFeePercent])}
                                            value={formik.values[FieldsEnum.tranziitServiceFeePercent]}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                            )}
                            <FormikField
                                name={FieldsEnum.tranziitServiceFeeFixedMode}
                                error={formik.errors[FieldsEnum.tranziitServiceFeeFixedMode]}
                                meta={formik.getFieldMeta(FieldsEnum.tranziitServiceFeeFixedMode)}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <DropdownInput<ServiceFeeTypeOptionT, ServiceFeeTypeEnum>
                                        triggerClassName={cx('combined-field--fee-type')}
                                        selectedValue={tranziitServiceFeeType}
                                        options={serviceFeeTypeOptions}
                                        onSelect={props.onChange}
                                        renderOption={(option) => (option ? <span>{option.label}</span> : null)}
                                        getOptionValue={(option) => option.value}
                                        overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                    </FieldGroup>
                    <FieldGroup className={cx('field-group')}>
                        {renderNumberField(FieldsEnum.minimalShipmentCost)}
                        {renderNumberField(FieldsEnum.driverSalaryPerHour)}
                    </FieldGroup>
                    <FieldGroup className={cx('field-group')}>
                        {renderNumberField(FieldsEnum.minimalCostForTruck)}
                        {renderNumberField(FieldsEnum.minimalCostForTrailer)}
                    </FieldGroup>
                    <FieldGroup className={cx('field-group')}>
                        {renderNumberField(FieldsEnum.layoverCostPerHour)}
                        {renderNumberField(FieldsEnum.layoverCostPerDay)}
                    </FieldGroup>
                    <FieldGroup className={cx('field-group')}>
                        {renderNumberField(FieldsEnum.layoverFreeHours)}
                        <FieldGroupEmptyItem className={cx('field')} />
                    </FieldGroup>
                    <FieldGroup className={cx('field-group')}>
                        {renderNumberField(FieldsEnum.truckConsumption)}
                        {renderNumberField(FieldsEnum.reeferConsumption)}
                    </FieldGroup>
                    <FieldGroup className={cx('field-group')}>
                        {renderNumberField(FieldsEnum.urgentOverPrice)}
                        {renderNumberField(FieldsEnum.costPerGreenShipment)}
                    </FieldGroup>
                    <FieldGroup className={cx('field-group')}>
                        <FormikField
                            className={cx('field')}
                            name={FieldsEnum.rateMatrixSpread}
                            error={formik.errors[FieldsEnum.rateMatrixSpread]}
                            meta={formik.getFieldMeta(FieldsEnum.rateMatrixSpread)}
                            label={t(FIELD_LABEL_T_MAP[FieldsEnum.rateMatrixSpread])}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                            tooltipNode={
                                <TooltipContent theme={TooltipContentThemeEnum.black} width={300}>
                                    <div className={cx('rate-matrix-spread-description')}>
                                        <div className={cx('rate-matrix-spread-description__text')}>
                                            {t(FIELD_DESCRIPTION_T_MAP[FieldsEnum.rateMatrixSpread])}
                                        </div>
                                        <table>
                                            <thead>
                                                {times(3).map((columnIndex) => (
                                                    <th key={columnIndex}>
                                                        {t(
                                                            `price-settings.pricing-form.fields.rate-matrix-spread.example-table.head.column-${columnIndex}`,
                                                        )}
                                                    </th>
                                                ))}
                                            </thead>
                                            <tbody>
                                                {times(4).map((rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {times(3).map((columnIndex) => (
                                                            <th key={columnIndex}>
                                                                {t(
                                                                    `price-settings.pricing-form.fields.rate-matrix-spread.example-table.row-${rowIndex}.column-${columnIndex}`,
                                                                )}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </TooltipContent>
                            }
                        >
                            {(props) => (
                                <NumberInput
                                    name={FieldsEnum.rateMatrixSpread}
                                    unitType={UNIT_TYPE_MAP[FieldsEnum.rateMatrixSpread]}
                                    mask={getMaskByType(UNIT_TYPE_MAP[FieldsEnum.rateMatrixSpread])}
                                    step={STEP_MAP[FieldsEnum.rateMatrixSpread]}
                                    value={formik.values[FieldsEnum.rateMatrixSpread]}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                        <FieldGroupEmptyItem className={cx('field')} />
                    </FieldGroup>
                    {/* <FieldGroup className={cx('field-group')}> */}
                    {/*    {renderNumberField(FieldsEnum.overPriceForEveryAdditionalStop)} */}
                    {/*    <FieldGroupEmptyItem /> */}
                    {/* </FieldGroup> */}
                    <FieldGroup className={cx('checkbox-group')}>
                        <div className={cx('checkbox')}>
                            <FormikField
                                withoutLabel
                                name={FieldsEnum.usePeriodicRulesForWeekdays}
                                error={formik.errors[FieldsEnum.usePeriodicRulesForWeekdays]}
                                meta={formik.getFieldMeta(FieldsEnum.usePeriodicRulesForWeekdays)}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                isCheckbox
                            >
                                {(props) => (
                                    <Checkbox
                                        checked={formik.values[FieldsEnum.usePeriodicRulesForWeekdays]}
                                        label={t(FIELD_LABEL_T_MAP[FieldsEnum.usePeriodicRulesForWeekdays])}
                                        onChange={props.onChange}
                                    />
                                )}
                            </FormikField>
                        </div>
                        <FormikField
                            withoutLabel
                            className={cx('checkbox')}
                            name={FieldsEnum.usePeriodicRulesForMonths}
                            error={formik.errors[FieldsEnum.usePeriodicRulesForMonths]}
                            meta={formik.getFieldMeta(FieldsEnum.usePeriodicRulesForMonths)}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                            isCheckbox
                        >
                            {(props) => (
                                <Checkbox
                                    isDisabled={isDisabledPeriodicRulesForMonths}
                                    checked={formik.values[FieldsEnum.usePeriodicRulesForMonths]}
                                    label={t(FIELD_LABEL_T_MAP[FieldsEnum.usePeriodicRulesForMonths])}
                                    onChange={props.onChange}
                                />
                            )}
                        </FormikField>
                    </FieldGroup>
                    <HiddenSubmitButtonForKeyboardEnter />
                    <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
                </div>
            </Widget>
        </form>
    );
};

export default React.memo(PricingSettingsForm) as typeof PricingSettingsForm;
