import {
    FETCH_PARTNERS_STATS_REQUEST_BEGIN,
    FETCH_PARTNERS_STATS_REQUEST_ERROR,
    FETCH_PARTNERS_STATS_REQUEST_SUCCESS,
    PartnersStatsActionT,
    PartnersStatsStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: PartnersStatsStateT = {
    fetchRequest: requestStatus.initial,
    stats: null,
};

export default (state = initialState, action: PartnersStatsActionT | DestroySessionActionT): PartnersStatsStateT => {
    switch (action.type) {
        case FETCH_PARTNERS_STATS_REQUEST_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }

        case FETCH_PARTNERS_STATS_REQUEST_SUCCESS: {
            const { stats } = action;

            return {
                ...state,
                stats,
                fetchRequest: requestStatus.ok,
            };
        }

        case FETCH_PARTNERS_STATS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                stats: null,
                fetchRequest: requestStatus.setError(error),
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
