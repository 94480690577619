import { FieldsEnum, FormValuesT } from './constants';
import { CountryCodeUnionT } from 'common/utils/api/models';
import isNumber from 'lodash/isNumber';
import {
    ApiEmissionStandardT,
    CalculateCostRequestQueryT,
    CreateRequestQueryDraftT,
} from 'common/store/shipper-contract-lane-creation/models';
import { getDateFromDate } from 'common/utils/time';
import { EmissionStandartEnum } from 'common/constants';
import { parseSimpleNumber, parseStringFormatter } from 'common/utils/input-parsers';

const prepareLocation = (
    value: FormValuesT[FieldsEnum.pickUpLocation] | FormValuesT[FieldsEnum.dropOffLocation],
): CalculateCostRequestQueryT['origin'] | CalculateCostRequestQueryT['destination'] | null => {
    if (!value) {
        return null;
    }

    const longitude = value.point?.lng;
    const latitude = value.point?.lat;

    if (!isNumber(longitude) || !isNumber(latitude)) {
        return null;
    }

    return {
        countryCode: (value.addressComponents?.countryCode || '') as CountryCodeUnionT,
        street1: value.addressComponents?.street1 || '',
        street2: value.addressComponents?.street2 || '',
        zipCode: value.addressComponents?.zipCode || '',
        city: value.addressComponents?.city || '',
        longitude,
        latitude,
    };
};

export const EMISSION_STANDART_MAP: Record<EmissionStandartEnum, ApiEmissionStandardT> = {
    [EmissionStandartEnum.euro5]: 'EURO_V',
    [EmissionStandartEnum.euro6]: 'EURO_VI',
};

const prepareApiCalculateCostRequestQuery = (values: FormValuesT): CalculateCostRequestQueryT | null => {
    const dictTrailerId = values[FieldsEnum.trailerDictId];
    const emissionClass = values[FieldsEnum.emissionClass];
    const pickUpLocation = values[FieldsEnum.pickUpLocation];
    const dropOffLocation = values[FieldsEnum.dropOffLocation];
    const validFrom = values[FieldsEnum.validFrom];
    const validTill = values[FieldsEnum.validTill];
    const origin = prepareLocation(values[FieldsEnum.pickUpLocation]);
    const destination = prepareLocation(values[FieldsEnum.dropOffLocation]);

    if (
        !dictTrailerId ||
        !emissionClass ||
        !pickUpLocation ||
        !dropOffLocation ||
        !validFrom ||
        !validTill ||
        !origin ||
        !destination
    ) {
        return null;
    }

    return {
        dictTrailerId: +dictTrailerId,
        emissionClass: EMISSION_STANDART_MAP[emissionClass],
        origin,
        destination,
        validFrom: getDateFromDate(validFrom),
        validTill: getDateFromDate(validTill),
        teamDrive: !!values[FieldsEnum.includeTeamDrive],
        skipCommission: !values[FieldsEnum.includeServiceFee],
        skipRules: !values[FieldsEnum.useApplyRules],
    };
};

const prepareApiCreateRequestQueryDraft = (values: FormValuesT): CreateRequestQueryDraftT | null => {
    const dictTrailerId = values[FieldsEnum.trailerDictId];
    const emissionClass = values[FieldsEnum.emissionClass];
    const pickUpLocation = values[FieldsEnum.pickUpLocation];
    const dropOffLocation = values[FieldsEnum.dropOffLocation];
    const validFrom = values[FieldsEnum.validFrom];
    const validTill = values[FieldsEnum.validTill];
    const origin = prepareLocation(values[FieldsEnum.pickUpLocation]);
    const destination = prepareLocation(values[FieldsEnum.dropOffLocation]);
    const maxNumberOfOrders = parseSimpleNumber(values[FieldsEnum.ordersLimit]);
    const originRadius = parseSimpleNumber(values[FieldsEnum.pickUpLocationRadius]);
    const destinationRadius = parseSimpleNumber(values[FieldsEnum.dropOffLocationRadius]);
    const maximumLayover = parseSimpleNumber(values[FieldsEnum.layoverLimit]);

    if (
        !dictTrailerId ||
        !emissionClass ||
        !pickUpLocation ||
        !dropOffLocation ||
        !validFrom ||
        !validTill ||
        !origin ||
        !destination ||
        !maxNumberOfOrders
    ) {
        return null;
    }

    return {
        externalLaneId: parseStringFormatter(values[FieldsEnum.externalLaneId]),
        dictTrailerId: +dictTrailerId,
        emissionClass: EMISSION_STANDART_MAP[emissionClass],
        origin,
        destination,
        originRadius: originRadius || 0,
        destinationRadius: destinationRadius || 0,
        validFrom: getDateFromDate(validFrom),
        validTill: getDateFromDate(validTill),
        maximumLayover: maximumLayover || 0,
        serviceFee: !!values[FieldsEnum.includeServiceFee],
        teamDrive: !!values[FieldsEnum.includeTeamDrive],
        maxNumberOfOrders,
    };
};

export { prepareApiCalculateCostRequestQuery, prepareApiCreateRequestQueryDraft };
