import { RepeatStrategyTypeEnum, PriceComponentTypeEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import { CountryCodeT } from 'common/store/countries-dict/models';
import { PriceSettingsRuleGroupEnum } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/constants';

export enum FieldsEnum {
    name = 'name',
    group = 'group',

    trailerTypeIds = 'trailer-type-ids',

    shipperId = 'shipper-id',

    fromCountryCodes = 'from-country-codes',
    fromCountryZipCodes = 'from-country-zip-codes',

    toCountryCodes = 'to-country-codes',
    toCountryZipCodes = 'to-country-zip-codes',

    repeatStrategy = 'repeat-strategy',

    validFrom = 'valid-from',
    validTill = 'valid-till',

    impactPriceComponent = 'impact-price-component',
}

export type FormValuesT = {
    [FieldsEnum.name]: string;
    [FieldsEnum.group]: PriceSettingsRuleGroupEnum | null;

    [FieldsEnum.trailerTypeIds]: Array<TrailerDictIdT>;

    [FieldsEnum.shipperId]: ShipperIdT | null;

    [FieldsEnum.fromCountryCodes]: Array<CountryCodeT>;
    [FieldsEnum.fromCountryZipCodes]: string;

    [FieldsEnum.toCountryCodes]: Array<CountryCodeT>;
    [FieldsEnum.toCountryZipCodes]: string;

    [FieldsEnum.repeatStrategy]: RepeatStrategyTypeEnum | null;

    [FieldsEnum.validFrom]: Date | null;
    [FieldsEnum.validTill]: Date | null;

    [FieldsEnum.impactPriceComponent]: PriceComponentTypeEnum | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;

export const QUERY_COUNTRY_CODES_SEPARATOR = ',';
export const TRAILER_TYPES_SEPARATOR = ',';
