import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ControlsCell.scss';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import MoreIcon from 'common/icons/MoreIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import CircleIcon from 'common/icons/CircleIcon';
import DeleteIcon from 'common/icons/DeleteIcon';
import { useTranslation } from 'react-i18next';
import { StyleGuideColorsEnum } from 'common/constants';

export type ActionsPropsT = {
    onActiveRule: (ruleId: RuleIdT) => void;
    onDeactiveRule: (ruleId: RuleIdT) => void;
    onDeleteRule: (ruleId: RuleIdT) => void;
};

type PropsT = {
    className?: string;
    id: RuleIdT | null | undefined;
    isActive: boolean;
} & ActionsPropsT;

const cx = classNames.bind(styles);

const ControlsCell: React.FC<PropsT> = React.memo((props) => {
    const { className, onActiveRule, onDeactiveRule, onDeleteRule, id, isActive } = props;

    const { t } = useTranslation();

    const activateOption: DropdownControlOptionT = {
        label: (
            <DropdownControlOptionLabel
                icon={<CircleIcon borderColor={StyleGuideColorsEnum.brandAccent} />}
                label={t('price-settings.rules.actions.activate')}
            />
        ),
        testSelector: 'activate',
        onSelect: () => {
            if (!id) {
                return;
            }
            onActiveRule(id);
        },
    };

    const deactivateOption: DropdownControlOptionT = {
        label: (
            <DropdownControlOptionLabel
                icon={<CircleIcon borderColor={StyleGuideColorsEnum.tomatoRed} />}
                label={t('price-settings.rules.actions.deactivate')}
            />
        ),
        testSelector: 'deactivate',
        onSelect: () => {
            if (!id) {
                return;
            }
            onDeactiveRule(id);
        },
    };

    const deleteOption: DropdownControlOptionT = {
        label: (
            <DropdownControlOptionLabel
                icon={<DeleteIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                label={t('price-settings.rules.actions.delete')}
            />
        ),
        testSelector: 'delete',
        onSelect: () => {
            if (!id) {
                return;
            }
            onDeleteRule(id);
        },
    };

    const options: Array<DropdownControlOptionT> = [];

    if (isActive) {
        options.push(deactivateOption);
    } else {
        options.push(activateOption);
    }

    options.push(deleteOption);

    const fullTestSelector = 'rule-row-controls';

    return (
        <DropdownControl
            className={className}
            options={options}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isPressed={isActive}
                    onClick={onClick}
                    leftIcon={<MoreIcon />}
                    reflectionTheme={ReflectionThemeEnum.light}
                    testSelector={`${fullTestSelector}-trigger`}
                />
            )}
            testSelector={fullTestSelector}
            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
        />
    );
});

export default ControlsCell;
