import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './Receipt.scss';
import Money from 'common/components/Money/Money';
import { CurrencyEnum, UnitTypeEnum } from 'common/constants';
import { useSelector } from 'react-redux';
import {
    selectSelfCostPrediction,
    selectSelfCostPredictionRequestStatus,
} from 'broker-admin/store/dispatch-assigment/selectors';
import { useTranslation } from 'react-i18next';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import ControlLoaderLabel from 'common/components/ControlLoaderLabel/ControlLoaderLabel';
import NextToursDiff from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/AssignmentForm/NextToursDiff/NextToursDiff';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    dispatchDetails: DispatchDetailsT | null;
    isNotActual: boolean;
    selectedTruckId: TruckIdT | null;
    selectedTrailerId: TrailerIdT | null;
};

type FormatterT = (value: number | null | undefined) => React.ReactNode;

type RowsT = Array<{
    title: React.ReactNode;
    value: number | null | undefined;
    formatter: FormatterT;
}>;

const Receipt: React.FC<PropsT> = React.memo((props) => {
    const { className, dispatchDetails, selectedTruckId, selectedTrailerId } = props;

    const { t } = useTranslation();

    const selfCostPrediction = useSelector(selectSelfCostPrediction);

    const [prevSelfCostPrediction, setPrevSelfCostPrediction] = React.useState<typeof selfCostPrediction>(() => null);

    const selfCostPredictionRequestStatus = useSelector(selectSelfCostPredictionRequestStatus);
    const isLoading = selfCostPredictionRequestStatus.loading;
    const isNotActual = isLoading || props.isNotActual;

    React.useEffect(() => {
        setPrevSelfCostPrediction(selfCostPrediction);
    }, [selfCostPrediction]);

    const viewSelfCostPrediction = isLoading ? prevSelfCostPrediction : selfCostPrediction;

    const renderRows = (rows: RowsT): Array<React.ReactElement> => {
        return rows.map((row, rowIndex) => (
            <div
                className={cx('row', {
                    'row--is-loading': isLoading,
                })}
                key={rowIndex}
            >
                <div className={cx('row__title')}>{row.title}</div>
                <div
                    className={cx('row__value', {
                        'row__value--isNotAvailable': typeof row.value !== 'number',
                        'row__value--isPrevValue': isNotActual,
                    })}
                >
                    {row.formatter(row.value)}
                </div>
            </div>
        ));
    };

    const formatMoney: FormatterT = (value) => {
        if (typeof value !== 'number') {
            return t('common:not-available');
        }

        return <Money amount={value} currency={CurrencyEnum.EUR} />;
    };

    const formatDistance: FormatterT = (value) => {
        if (typeof value !== 'number') {
            return t('common:not-available');
        }

        return <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={value} />;
    };

    const formatPercentage: FormatterT = (value) => {
        if (typeof value !== 'number') {
            return t('common:not-available');
        }

        return <UnitTypeCount type={UnitTypeEnum.percentsAbbreviation} count={value} />;
    };

    const leftMetricsRows: RowsT = [
        {
            title: t('assignment.receipt.total-rate-per-km'),
            value: viewSelfCostPrediction?.tourTotalCost?.totalRateKm,
            formatter: formatMoney,
        },
    ];

    const rightMetricsRows: RowsT = [
        {
            title: t('assignment.receipt.payload-rate-per-km'),
            value: viewSelfCostPrediction?.tourTotalCost?.payloadRateKm,
            formatter: formatMoney,
        },
    ];

    const leftDetailsRows: RowsT = [
        {
            title: t('assignment.receipt.line-haul'),
            value: viewSelfCostPrediction?.tourTotalCost?.lineHaul,
            formatter: formatMoney,
        },
        {
            title: t('assignment.receipt.fuel-costs'),
            value: viewSelfCostPrediction?.tourTotalCost?.fuelCosts,
            formatter: formatMoney,
        },
        {
            title: t('assignment.receipt.road-taxes'),
            value: viewSelfCostPrediction?.tourTotalCost?.roadTaxes,
            formatter: formatMoney,
        },
        {
            title: t('assignment.receipt.other-costs'),
            value: viewSelfCostPrediction?.tourTotalCost?.otherCosts,
            formatter: formatMoney,
        },
    ];

    const rightDetailsRows: RowsT = [
        {
            title: t('assignment.receipt.total-mileage'),
            value: viewSelfCostPrediction?.tourTotalCost?.totalDistance,
            formatter: formatDistance,
        },
        {
            title: t('assignment.receipt.payload-mileage'),
            value: viewSelfCostPrediction?.tourTotalCost?.payloadDistance,
            formatter: formatDistance,
        },
        {
            title: t('assignment.receipt.deadhead-mileage'),
            value: viewSelfCostPrediction?.tourTotalCost?.deadheadDistance,
            formatter: formatDistance,
        },
        {
            title: t('assignment.receipt.deadhead-percentage'),
            value: viewSelfCostPrediction?.tourTotalCost?.deadheadPercent,
            formatter: formatPercentage,
        },
    ];

    const priceOffer = dispatchDetails?.priceOffer;

    const priceOfferTotalPrice = priceOffer?.totalPrice || 0;
    const priceOfferTranziitServiceFee = priceOffer?.serviceFee || 0;
    const priceOfferPrice = priceOfferTotalPrice - priceOfferTranziitServiceFee;

    const selfCost = viewSelfCostPrediction?.tourTotalCost?.totalCarrierCost;

    const isNegativeSelfPrice = typeof selfCost === 'number' && selfCost > 0 && priceOfferPrice < selfCost;
    const isPositiveSelfPrice = typeof selfCost === 'number' && selfCost > 0 && priceOfferPrice > selfCost;

    return (
        <div className={cs(cx('wrap'), className)}>
            <div className={cx('title')}>{t('assignment.receipt.title')}</div>
            <div className={cx('columns', 'columns--head')}>
                <div className={cx('column', 'column--left')}>
                    <div className={cx('offer-price')}>
                        {t('assignment.receipt.shipper-price')}{' '}
                        <Money amount={priceOfferPrice} currency={CurrencyEnum.EUR} />
                    </div>
                    <div
                        className={cx('sell-price', {
                            'sell-price--isNotActual': isNotActual,
                            'sell-price--isNotAvailable': typeof selfCost !== 'number',
                            'sell-price--positive': !isNotActual && isPositiveSelfPrice,
                            'sell-price--negative': !isNotActual && isNegativeSelfPrice,
                        })}
                    >
                        {t('assignment.receipt.carrier-cost')}{' '}
                        {typeof selfCost === 'number' ? formatMoney(selfCost) : null}
                    </div>
                </div>
                <div className={cx('column', 'column--right', 'prices')}>
                    <div className={cx('diff')}>
                        {!isNotActual && (
                            <NextToursDiff selectedTruckId={selectedTruckId} selectedTrailerId={selectedTrailerId} />
                        )}
                    </div>
                </div>
            </div>
            <div className={cx('details')}>
                <div className={cx('columns', 'columns--metrics')}>
                    <div className={cx('column', 'column--left')}>{renderRows(leftMetricsRows)}</div>
                    <div className={cx('column', 'column--right')}>{renderRows(rightMetricsRows)}</div>
                </div>
                <div className={cx('columns')}>
                    <div className={cx('column', 'column--left')}>{renderRows(leftDetailsRows)}</div>
                    <div className={cx('column', 'column--right')}>{renderRows(rightDetailsRows)}</div>
                </div>
                {isLoading && (
                    <div className={cx('loader__container')}>
                        <div className={cx('loader')}>
                            <ControlLoaderLabel>{t('assignment.receipt.loader')}</ControlLoaderLabel>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default Receipt;
