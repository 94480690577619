import values from 'lodash/values';
import {
    PRICE_COMPONENT_T_MAP,
    PriceSettingsRuleGroupEnum,
    RULE_GROUP_T_MAP,
} from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/constants';
import { TFunction } from 'i18next';
import { GroupOptionT, PriceComponentOptionT } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/models';
import { PriceComponentTypeEnum, RepeatStrategyTypeEnum } from 'broker-admin/utils/api/broker-tranziit/models';

export const checkIsRepeatStrategy = (
    repeatStrategy: string | null | undefined,
): repeatStrategy is RepeatStrategyTypeEnum => {
    return values(RepeatStrategyTypeEnum).includes(repeatStrategy as RepeatStrategyTypeEnum);
};

export const checkIsPriceSettingsRuleGroup = (
    group: string | null | undefined,
): group is PriceSettingsRuleGroupEnum => {
    return !!RULE_GROUP_T_MAP[group as PriceSettingsRuleGroupEnum];
};

export const getGroupOptions = (t: TFunction): GroupOptionT[] =>
    values(PriceSettingsRuleGroupEnum).map((group) => ({
        label: t(RULE_GROUP_T_MAP[group]),
        value: group,
    }));

export const checkIsPriceComponent = (
    priceComponent: string | null | undefined,
): priceComponent is PriceComponentTypeEnum => {
    return !!PRICE_COMPONENT_T_MAP[priceComponent as PriceComponentTypeEnum];
};

export const getPriceComponentsOptions = (t: TFunction): PriceComponentOptionT[] =>
    [
        // render not all components
        PriceComponentTypeEnum.costOfOperation,
        PriceComponentTypeEnum.lineHaul,
        PriceComponentTypeEnum.fuel,
        PriceComponentTypeEnum.roadTaxes,
        PriceComponentTypeEnum.serviceFee,
    ].map((status) => ({
        label: t(PRICE_COMPONENT_T_MAP[status]),
        value: status,
    }));
