import { PartnerDetailsStateT } from './types';
import { StateT } from 'broker-admin/store/models';

export const selectFetchPartnerDetailsRequest = (state: StateT): PartnerDetailsStateT['fetchRequest'] =>
    state.partner.details.fetchRequest;

export const selectUpdatePartnerDetailsRequest = (state: StateT): PartnerDetailsStateT['updateRequest'] =>
    state.partner.details.updateRequest;

export const selectLockPartnerDetailsRequest = (state: StateT): PartnerDetailsStateT['updateRequest'] =>
    state.partner.details.lockRequest;

export const selectVerifyPartnerDetailsRequest = (state: StateT): PartnerDetailsStateT['updateRequest'] =>
    state.partner.details.verifyRequest;

export const selectPartnerDetailsById = (state: StateT): PartnerDetailsStateT['byId'] => state.partner.details.byId;
