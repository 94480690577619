export enum FieldsEnum {
    urgentOrderProcessingDelay = 'URGENT_ORDER_PROCESSING_DELAY',
    regularOrderProcessingDelay = 'REGULAR_ORDER_PROCESSING_DELAY',
    minWindowSize = 'MIN_WINDOW_SIZE',
    maxWindowSize = 'MAX_WINDOW_SIZE',
    brokerWorkingHours = 'brokerWorkingHours',
    timeSlotsDelegationLimit = 'TIME_SLOTS_DELEGATION_LIMIT',
    orderCreationSeconds = 'ORDER_CREATION_SECONDS',
    quantumMinutes = 'QUANTUM_MINUTES',
    mapPrecisionDelta = 'MAP_PRECISION_DELTA',
}

export type FormValuesT = {
    [FieldsEnum.urgentOrderProcessingDelay]: string;
    [FieldsEnum.regularOrderProcessingDelay]: string;
    [FieldsEnum.minWindowSize]: string;
    [FieldsEnum.maxWindowSize]: string;
    [FieldsEnum.brokerWorkingHours]: [number, number];
    [FieldsEnum.timeSlotsDelegationLimit]: string;
    [FieldsEnum.orderCreationSeconds]: string;
    [FieldsEnum.quantumMinutes]: string;
    [FieldsEnum.mapPrecisionDelta]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
