import {
    BrokerOrdersActionT,
    BrokerOrdersStateT,
    FETCH_BROKER_ORDER_DETAILS_REQUEST_BEGIN,
    FETCH_BROKER_ORDER_DETAILS_REQUEST_ERROR,
    FETCH_BROKER_ORDER_DETAILS_REQUEST_SUCCESS,
    FETCH_BROKER_ORDERS_PAGE_REQUEST_BEGIN,
    FETCH_BROKER_ORDERS_PAGE_REQUEST_ERROR,
    FETCH_BROKER_ORDERS_PAGE_REQUEST_SUCCESS,
    RESET_BROKER_ORDERS,
} from './types';
import requestStatus from 'common/utils/request-status';
import { pageBeginReducer, pageErrorReducer, pageSuccessReducer } from 'common/utils/pagination/reducers';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: BrokerOrdersStateT = {
    pages: [],
    query: null,
    total: null,
    byId: {},
    fetchDetailsRequest: requestStatus.initial,
    detailsById: {},
};

export default (state = initialState, action: BrokerOrdersActionT | DestroySessionActionT): BrokerOrdersStateT => {
    switch (action.type) {
        case FETCH_BROKER_ORDERS_PAGE_REQUEST_BEGIN: {
            const { pageNumber, query } = action;

            return {
                ...state,
                ...pageBeginReducer(state, pageNumber, query),
            };
        }
        case FETCH_BROKER_ORDERS_PAGE_REQUEST_SUCCESS: {
            const { query, pageNumber, pageResponse } = action;

            return {
                ...state,
                ...pageSuccessReducer(state, pageNumber, query, pageResponse),
            };
        }
        case FETCH_BROKER_ORDERS_PAGE_REQUEST_ERROR: {
            const { query, error, pageNumber } = action;

            return {
                ...state,
                ...pageErrorReducer(state, pageNumber, query, error),
            };
        }

        case FETCH_BROKER_ORDER_DETAILS_REQUEST_BEGIN: {
            return {
                ...state,
                fetchDetailsRequest: requestStatus.loading,
            };
        }

        case FETCH_BROKER_ORDER_DETAILS_REQUEST_SUCCESS: {
            const { id, details } = action;

            return {
                ...state,
                fetchDetailsRequest: requestStatus.ok,
                detailsById: {
                    ...state.detailsById,
                    [id as string]: details,
                },
            };
        }

        case FETCH_BROKER_ORDER_DETAILS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                fetchDetailsRequest: requestStatus.setError(error),
            };
        }

        case DESTROY_SESSION:
        case RESET_BROKER_ORDERS: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};
