import * as React from 'react';

type PropsT = {
    // eslint-disable-next-line react/no-unused-prop-types
    key?: string;
    // eslint-disable-next-line react/no-unused-prop-types
    lat?: number;
    // eslint-disable-next-line react/no-unused-prop-types
    lng?: number;
    className?: string;
};

const PickedPinIcon: React.FC<PropsT> = (props) => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="45" viewBox="0 0 40 45" className={className}>
            <defs>
                <filter
                    id="ynerf12kfa"
                    width="390%"
                    height="357.8%"
                    x="-145%"
                    y="-128.9%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="15" />
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd" filter="url(#ynerf12kfa)">
                <path
                    fill="#4B6518"
                    d="M25 0c8.284 0 15 6.716 15 15v10c0 8.284-6.716 15-15 15l-5 5-5-5C6.716 40 0 33.284 0 25V15C0 6.716 6.716 0 15 0h10z"
                />
                <path d="M11 11h18v18H11z" />
                <path fill="#FFF" d="M17.75 23.15L14.6 20l-1.05 1.05 4.2 4.2 9-9-1.05-1.05z" />
            </g>
        </svg>
    );
};

PickedPinIcon.displayName = 'PickedPinIcon';

const storyProps: PropsT = {};

export { storyProps };
export default PickedPinIcon;
