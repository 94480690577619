import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './CreateCell.scss';
import { DispatchT } from 'broker-admin/store/dispatches/models';
import { CompanyTypeEnum } from 'common/constants';
import EventCell from 'common/components/Table/cell-renderers/EventCell/EventCell';

type PropsT = {
    dispatch: DispatchT;
    onOpenPartnerDetails: (companyId: string, companyType: CompanyTypeEnum) => void;
};

const cx = classNames.bind(styles);

const CreateCell: React.FC<PropsT> = React.memo((props) => {
    const { dispatch, onOpenPartnerDetails } = props;

    return (
        <div className={cx('wrap')}>
            <EventCell
                userFullName={dispatch.shipperName}
                userId={dispatch.shipperId}
                date={dispatch.createdDate}
                byBroker
                openUserDetails={(serId: UserIdT) => {
                    onOpenPartnerDetails(serId, CompanyTypeEnum.shipper);
                }}
            />
        </div>
    );
});

export default CreateCell;
