import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerDriversPage.scss';
import DriversPageLayout from 'common/layouts/DriversPageLayout/DriversPageLayout';

const cx = classNames.bind(styles);

type PropsT = {};

const PartnerDriversPage: React.FC<PropsT> = React.memo((props) => {
    return <DriversPageLayout />;
});

export default PartnerDriversPage;
