import * as React from 'react';
import { useMoneyDiff } from 'common/utils/hooks/useMoneyDiff';

type PropsT = {
    value: number | null | undefined;
    baseValue: number | null | undefined;
    children: (diff: number | null) => React.ReactNode;
};

const MoneyDiff: React.FC<PropsT> = (props) => {
    const { value, baseValue, children } = props;

    const diff = useMoneyDiff(baseValue, value);

    return <>{children(diff)}</>;
};

export default MoneyDiff;
