import { StatisticSourceEnum } from 'broker-admin/utils/api/broker-tranziit/models';

export const DEFAULT_DATA_SOURCE = StatisticSourceEnum.import;

export enum AggregateEnum {
    rejectedShipperRate = 'rejected-shipper-rate',
    acceptedShipperRate = 'accepted-shipper-rate',
    estimatedCarrierRate = 'estimated-carrier-rate',
    realCarrierRate = 'real-carrier-rate',
    margin = 'margin',
}
