import { FieldsEnum, FormValuesT } from './constants';
import { BooleanSettingValueEnum, SystemSettingsT } from 'broker-admin/store/price-settings/system-settings/models';

const getInitialValues = (systemSettings: SystemSettingsT | null | undefined): FormValuesT => {
    return {
        [FieldsEnum.minimalShipmentCost]: systemSettings?.MINIMAL_COST_FOR_SHIPMENT || '',
        [FieldsEnum.driverSalaryPerHour]: systemSettings?.DRIVER_SALARY_PER_HOUR || '',
        [FieldsEnum.minimalCostForTruck]: systemSettings?.MINIMAL_COST_FOR_TRUCK || '',
        [FieldsEnum.minimalCostForTrailer]: systemSettings?.MINIMAL_COST_FOR_TRAILER || '',
        [FieldsEnum.layoverCostPerHour]: systemSettings?.COST_PER_ONE_HOUR_LAYOVER || '',
        [FieldsEnum.layoverCostPerDay]: systemSettings?.COST_PER_ONE_DAY_LAYOVER || '',
        [FieldsEnum.layoverFreeHours]: systemSettings?.LAYOVER_FREE_HOURS || '',
        [FieldsEnum.urgentOverPrice]: systemSettings?.URGENT_OVERPRICE || '',
        [FieldsEnum.overPriceForEveryAdditionalStop]: systemSettings?.OVERPRICE_FOR_EVERY_ADDITIONAL_STOP || '',
        [FieldsEnum.costPerGreenShipment]: systemSettings?.COST_PER_GREEN_SHIPMENT || '',
        [FieldsEnum.tranziitServiceFeeFixedMode]: systemSettings?.TRANZIIT_SERVICE_FEE_FIXED_MODE || '',
        [FieldsEnum.tranziitServiceFeePercent]: systemSettings?.TRANZIIT_SERVICE_FEE_PERCENT || '',
        [FieldsEnum.tranziitServiceFeeFixed]: systemSettings?.TRANZIIT_SERVICE_FEE_FIXED || '',
        [FieldsEnum.pricingStrategy]: systemSettings?.PRICING_STRATEGY || '',
        [FieldsEnum.truckConsumption]: systemSettings?.TRUCK_CONSUMPTION || '',
        [FieldsEnum.reeferConsumption]: systemSettings?.REEFER_CONSUMPTION || '',
        [FieldsEnum.rateMatrixSpread]: systemSettings?.RATE_MATRIX_SPREAD || '',
        [FieldsEnum.usePeriodicRulesForMonths]:
            systemSettings?.YEAR_PERIODIC_AUTO_RULES === BooleanSettingValueEnum.true,
        [FieldsEnum.usePeriodicRulesForWeekdays]: systemSettings?.USE_PERIODIC_RULES === BooleanSettingValueEnum.true,
    };
};

export default getInitialValues;
