import { DispatchesStateT } from './types';
import { StateT } from '../models';

export const selectDispatchesPages = (state: StateT): DispatchesStateT['pages'] => state.dispatches.pages;

export const selectDispatchesQuery = (state: StateT): DispatchesStateT['query'] => state.dispatches.query;

export const selectDispatchesTotal = (state: StateT): DispatchesStateT['total'] => state.dispatches.total;

export const selectDispatchesByIds = (state: StateT): DispatchesStateT['byId'] => state.dispatches.byId;
