import { disableCompaniesDataSourceSet, FieldsEnum, FormValuesT } from './constants';
import { StatisticSourceEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import { QueryFiltersT, QueryFiltersKeysEnum } from 'broker-admin/layouts/StatisticsPage/query-models';
import { DEFAULT_DATA_SOURCE } from 'broker-admin/layouts/StatisticsPage/constants';

const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const dataSource = (queryFilters[QueryFiltersKeysEnum.dataSource] as StatisticSourceEnum) || DEFAULT_DATA_SOURCE;

    let companiesValues = {
        [FieldsEnum.shipperId]: queryFilters[QueryFiltersKeysEnum.shipperId] || null,
        [FieldsEnum.shipperName]: queryFilters[QueryFiltersKeysEnum.shipperName] || null,
        [FieldsEnum.carrierId]: queryFilters[QueryFiltersKeysEnum.carrierId] || null,
        [FieldsEnum.carrierName]: queryFilters[QueryFiltersKeysEnum.carrierName] || null,
    };

    if (disableCompaniesDataSourceSet.has(dataSource)) {
        companiesValues = {
            [FieldsEnum.shipperId]: null,
            [FieldsEnum.shipperName]: null,
            [FieldsEnum.carrierId]: null,
            [FieldsEnum.carrierName]: null,
        };
    }

    const values: FormValuesT = {
        [FieldsEnum.dataSource]: dataSource,
        [FieldsEnum.fromCountryCode]: queryFilters[QueryFiltersKeysEnum.fromCountryCode] || null,
        [FieldsEnum.fromZipCode]: queryFilters[QueryFiltersKeysEnum.fromZipCode] || '',
        [FieldsEnum.toCountryCode]: queryFilters[QueryFiltersKeysEnum.toCountryCode] || null,
        [FieldsEnum.toZipCode]: queryFilters[QueryFiltersKeysEnum.toZipCode] || '',
        ...companiesValues,
    };

    return values;
};

export default getInitialValues;
