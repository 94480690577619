import { DispatchStatsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_DISPATCHES_STATS_REQUEST = 'broker/dispatches-stats/FETCH_DISPATCHES_STATS_REQUEST';
export const FETCH_DISPATCHES_STATS_REQUEST_BEGIN = 'broker/dispatches-stats/FETCH_DISPATCHES_STATS_REQUEST_BEGIN';
export const FETCH_DISPATCHES_STATS_REQUEST_ERROR = 'broker/dispatches-stats/FETCH_DISPATCHES_STATS_REQUEST_ERROR';
export const FETCH_DISPATCHES_STATS_REQUEST_SUCCESS = 'broker/dispatches-stats/FETCH_DISPATCHES_STATS_REQUEST_SUCCESS';

export type DispatchStatsStateT = {
    stats: DispatchStatsT | null;
    fetchRequest: RequestStatusT;
};

export type FetchDispatchesStatsActionT = {
    type: typeof FETCH_DISPATCHES_STATS_REQUEST;
};

export type FetchDispatchesStatsBeginActionT = {
    type: typeof FETCH_DISPATCHES_STATS_REQUEST_BEGIN;
};

export type FetchDispatchesStatsSuccessActionT = {
    type: typeof FETCH_DISPATCHES_STATS_REQUEST_SUCCESS;
    statistics: DispatchStatsT | null;
};

export type FetchDispatchesStatsErrorActionT = {
    type: typeof FETCH_DISPATCHES_STATS_REQUEST_ERROR;
    error: Error;
};

export type DispatchesStatsActionT =
    | FetchDispatchesStatsActionT
    | FetchDispatchesStatsBeginActionT
    | FetchDispatchesStatsSuccessActionT
    | FetchDispatchesStatsErrorActionT;
