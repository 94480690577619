import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ShipperContractLaneId.scss';

const cx = classNames.bind(styles);

type PropsT = {
    id: string;
};

const ShipperContractLaneId: React.FC<PropsT> = React.memo((props) => {
    const { id } = props;

    return <span className={cx('id')}>{id}</span>;
});

export default ShipperContractLaneId;
