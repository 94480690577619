export enum FieldsEnum {
    totalPrice = 'totalPrice',
}

export type FormTouchedT = Partial<Record<FieldsEnum, boolean>>;

export type FormValuesT = {
    [FieldsEnum.totalPrice]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
