import { RequestStatusT } from 'common/utils/request-status';

import { FetchRulePageQueryT, RuleDetailsT, RuleDraftT, RuleT } from './models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_RULE_DETAILS_REQUEST = 'broker-admin/price-settings/rules/FETCH_RULE_DETAILS_REQUEST';
export const FETCH_RULE_DETAILS_REQUEST_BEGIN = 'broker-admin/price-settings/rules/FETCH_RULE_DETAILS_REQUEST_BEGIN';
export const FETCH_RULE_DETAILS_REQUEST_ERROR = 'broker-admin/price-settings/rules/FETCH_RULE_DETAILS_REQUEST_ERROR';
export const FETCH_RULE_DETAILS_REQUEST_SUCCESS =
    'broker-admin/price-settings/rules/FETCH_RULE_DETAILS_REQUEST_SUCCESS';

export const FETCH_RULES_PAGE_REQUEST = 'broker-admin/price-settings/rules/FETCH_RULES_PAGE_REQUEST';
export const FETCH_RULES_PAGE_REQUEST_BEGIN = 'broker-admin/price-settings/rules/FETCH_RULES_PAGE_REQUEST_BEGIN';
export const FETCH_RULES_PAGE_REQUEST_ERROR = 'broker-admin/price-settings/rules/FETCH_RULES_PAGE_REQUEST_ERROR';
export const FETCH_RULES_PAGE_REQUEST_SUCCESS = 'broker-admin/price-settings/rules/FETCH_RULES_PAGE_REQUEST_SUCCESS';

export const RESET_RULE_PAGES = 'broker-admin/price-settings/rules/RESET_RULE_PAGES';

export const ACTIVATE_RULES_REQUEST = 'broker-admin/price-settings/rules/ACTIVATE_RULES_REQUEST';
export const DEACTIVATE_RULES_REQUEST = 'broker-admin/price-settings/rules/DEACTIVATE_RULES_REQUEST';

export const UPDATE_RULE_REQUEST = 'broker-admin/price-settings/rules/UPDATE_RULE_REQUEST';
export const UPDATE_RULE_REQUEST_BEGIN = 'broker-admin/price-settings/rules/UPDATE_RULE_REQUEST_BEGIN';
export const UPDATE_RULE_REQUEST_ERROR = 'broker-admin/price-settings/rules/UPDATE_RULE_REQUEST_ERROR';
export const UPDATE_RULE_REQUEST_SUCCESS = 'broker-admin/price-settings/rules/UPDATE_RULE_REQUEST_SUCCESS';

export const CREATE_RULE_REQUEST = 'broker-admin/price-settings/rules/CREATE_RULE_REQUEST';
export const CREATE_RULE_REQUEST_BEGIN = 'broker-admin/price-settings/rules/CREATE_RULE_REQUEST_BEGIN';
export const CREATE_RULE_REQUEST_ERROR = 'broker-admin/price-settings/rules/CREATE_RULE_REQUEST_ERROR';
export const CREATE_RULE_REQUEST_SUCCESS = 'broker-admin/price-settings/rules/CREATE_RULE_REQUEST_SUCCESS';

export const DELETE_RULES_REQUEST = 'broker-admin/price-settings/rules/DELETE_RULES_REQUEST';
export const DELETE_RULES_REQUEST_BEGIN = 'broker-admin/price-settings/rules/DELETE_RULES_REQUEST_BEGIN';
export const DELETE_RULES_REQUEST_ERROR = 'broker-admin/price-settings/rules/DELETE_RULES_REQUEST_ERROR';
export const DELETE_RULES_REQUEST_SUCCESS = 'broker-admin/price-settings/rules/DELETE_RULES_REQUEST_SUCCESS';

export type RulesStateT = PaginationStateT<RuleT, FetchRulePageQueryT> & {
    detailsById: Record<string, RuleDetailsT | null>;
    fetchDetailsRequest: RequestStatusT;
    updateRequest: RequestStatusT;
    creationRequest: RequestStatusT;
    deleteRequest: RequestStatusT;
};

export type FetchRuleDetailsActionT = {
    type: typeof FETCH_RULE_DETAILS_REQUEST;
    ruleId: RuleIdT;
};

export type FetchRuleDetailsBeginActionT = {
    type: typeof FETCH_RULE_DETAILS_REQUEST_BEGIN;
    ruleId: RuleIdT;
};

export type FetchRuleDetailsSuccessActionT = {
    type: typeof FETCH_RULE_DETAILS_REQUEST_SUCCESS;
    ruleId: RuleIdT;
    ruleDetails: RuleDetailsT | null;
};

export type FetchRuleDetailsErrorActionT = {
    type: typeof FETCH_RULE_DETAILS_REQUEST_ERROR;
    ruleId: RuleIdT;
    error: Error;
};

export type FetchRulesPageActionT = {
    type: typeof FETCH_RULES_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchRulePageQueryT;
    options?: FetchOptionsT;
};

export type FetchRulesPageBeginActionT = {
    type: typeof FETCH_RULES_PAGE_REQUEST_BEGIN;
    pageNumber: PageNumberT;
    query: FetchRulePageQueryT;
};

export type FetchRulesPageSuccessActionT = {
    type: typeof FETCH_RULES_PAGE_REQUEST_SUCCESS;
    pageNumber: PageNumberT;
    query: FetchRulePageQueryT;
    pageResponse: PageResponseT<RuleT> | null;
};

export type FetchRulesPageErrorActionT = {
    type: typeof FETCH_RULES_PAGE_REQUEST_ERROR;
    pageNumber: PageNumberT;
    error: Error;
    query: FetchRulePageQueryT;
};

export type ResetRulePagesActionT = {
    type: typeof RESET_RULE_PAGES;
    mutationListOptions: MutationListOptionsT;
};

export type UpdateRuleActionT = {
    type: typeof UPDATE_RULE_REQUEST;
    ruleId: RuleIdT;
    ruleDraft: RuleDraftT;
};

export type UpdateRuleBeginActionT = {
    type: typeof UPDATE_RULE_REQUEST_BEGIN;
};

export type UpdateRuleSuccessActionT = {
    type: typeof UPDATE_RULE_REQUEST_SUCCESS;
};

export type UpdateRuleErrorActionT = {
    type: typeof UPDATE_RULE_REQUEST_ERROR;
    error: Error;
};

export type ActivationRulesActionT = {
    type: typeof ACTIVATE_RULES_REQUEST;
    ids: Array<RuleIdT>;
};

export type DeactivationRulesActionT = {
    type: typeof DEACTIVATE_RULES_REQUEST;
    ids: Array<RuleIdT>;
};

export type CreateRuleActionT = {
    type: typeof CREATE_RULE_REQUEST;
    ruleDraft: RuleDraftT;
};

export type CreateRuleBeginActionT = {
    type: typeof CREATE_RULE_REQUEST_BEGIN;
};

export type CreateRuleSuccessActionT = {
    type: typeof CREATE_RULE_REQUEST_SUCCESS;
};

export type CreateRuleErrorActionT = {
    type: typeof CREATE_RULE_REQUEST_ERROR;
    error: Error;
};

export type DeleteRulesActionT = {
    type: typeof DELETE_RULES_REQUEST;
    ids: Array<RuleIdT>;
};

export type DeleteRulesBeginActionT = {
    type: typeof DELETE_RULES_REQUEST_BEGIN;
};

export type DeleteRulesSuccessActionT = {
    type: typeof DELETE_RULES_REQUEST_SUCCESS;
};

export type DeleteRulesErrorActionT = {
    type: typeof DELETE_RULES_REQUEST_ERROR;
    error: Error;
};

export type RulesActionT =
    | FetchRulesPageActionT
    | FetchRulesPageBeginActionT
    | FetchRulesPageSuccessActionT
    | FetchRulesPageErrorActionT
    | UpdateRuleActionT
    | UpdateRuleBeginActionT
    | UpdateRuleSuccessActionT
    | UpdateRuleErrorActionT
    | ResetRulePagesActionT
    | ActivationRulesActionT
    | DeactivationRulesActionT
    | CreateRuleActionT
    | CreateRuleBeginActionT
    | CreateRuleSuccessActionT
    | CreateRuleErrorActionT
    | DeleteRulesActionT
    | DeleteRulesBeginActionT
    | DeleteRulesSuccessActionT
    | DeleteRulesErrorActionT
    | FetchRuleDetailsActionT
    | FetchRuleDetailsBeginActionT
    | FetchRuleDetailsSuccessActionT
    | FetchRuleDetailsErrorActionT;
