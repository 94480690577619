import {
    RESET,
    ResetActionT,
    UPDATE_DISPATCH_REQUEST_BEGIN,
    UPDATE_DISPATCH_REQUEST_ERROR,
    UPDATE_DISPATCH_REQUEST_SUCCESS,
    UPDATE_DOCKING_HOURS,
    UpdateDispatchRequestBeginActionT,
    UpdateDispatchRequestErrorActionT,
    UpdateDispatchRequestSuccessActionT,
    UpdateDockingHoursActionT,
} from './types';

import { ApiUpdateDockingHoursQueryT } from 'common/utils/api/models';

export const updateDockingHours = (
    dispatchId: DispatchIdT,
    query: ApiUpdateDockingHoursQueryT,
): UpdateDockingHoursActionT => ({
    type: UPDATE_DOCKING_HOURS,
    dispatchId,
    query,
});

export const updateDispatchRequestBegin = (dispatchId: DispatchIdT): UpdateDispatchRequestBeginActionT => ({
    type: UPDATE_DISPATCH_REQUEST_BEGIN,
    dispatchId,
});

export const updateDispatchRequestSuccess = (dispatchId: DispatchIdT): UpdateDispatchRequestSuccessActionT => ({
    type: UPDATE_DISPATCH_REQUEST_SUCCESS,
    dispatchId,
});

export const updateDispatchRequestError = (
    dispatchId: DispatchIdT,
    error: Error,
): UpdateDispatchRequestErrorActionT => ({
    type: UPDATE_DISPATCH_REQUEST_ERROR,
    error,
    dispatchId,
});

export const resetDispatchEdit = (): ResetActionT => ({
    type: RESET,
});
