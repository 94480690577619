import React from 'react';
import classNames from 'classnames/bind';

import styles from './UploadShipperContractSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT, MIN_VALID_FROM_VALID_TILL_DAYS } from './constants';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import { useDispatch, useSelector } from 'react-redux';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import { SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { BrokerSidebarDataT } from 'broker-admin/layouts/SideBars/models';
import useCloseSidebarAfterRequest from 'common/utils/hooks/useCloseSidebarAfterRequest';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import { UploadShipperContractSidebarDataT } from './models';
import FilePickerInput from 'design-system/components/FilePickerInput/FilePickerInput';
import DatePicker, {
    DatePickerOverlayPositionEnum,
    datePicketRangePresets,
} from 'design-system/components/date-pickers/DatePicker/DatePicker';
import { uploadShipperContract } from 'common/store/shipper-contract-details/slice';
import { UploadShipperContractDetailsT } from 'common/store/shipper-contract-details/models';
import { logWarning } from 'common/utils/logger';
import { selectUpdateShipperContractRequest } from 'common/store/shipper-contract-details/selectors';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import moment from 'moment';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import PdfFileIcon from 'common/icons/PdfFileIcon';
import { StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(Object.values(FieldsEnum));

type PropsT = SidebarContentPropsT<UploadShipperContractSidebarDataT, BrokerSidebarDataT>;

const VALID_TILL_FORMAT = 'YYYY-MM-DD';

const UploadShipperContractSidebarContent: React.FC<PropsT> = (props) => {
    const { data, onGoBack, onClose, setNeedCloseConfirmation } = props;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const uploadRequestStatus = useSelector(selectUpdateShipperContractRequest);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues();
        const errors = validateForm(t, values);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const file = values[FieldsEnum.file];
            if (!file) {
                logWarning('failed to upload shipper contract, empty file');
                return;
            }

            const details: UploadShipperContractDetailsT = {
                name: simpleStringFormatter(values[FieldsEnum.name]),
                number: simpleStringFormatter(values[FieldsEnum.number]),
                validFrom: moment(values[FieldsEnum.activateDate]).format(VALID_TILL_FORMAT),
                validTill: moment(values[FieldsEnum.validTillDate]).format(VALID_TILL_FORMAT),
            };

            dispatch(
                uploadShipperContract({
                    partnerId: data.partnerId,
                    file,
                    details,
                }),
            );

            formikHelpers.setTouched({});
        },
    });

    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);
    React.useEffect(() => {
        if (hasAnyFieldValueChanges) {
            setNeedCloseConfirmation(hasAnyFieldValueChanges);
        }
    }, [hasAnyFieldValueChanges]);

    useCloseSidebarAfterRequest(uploadRequestStatus, onGoBack || onClose);

    const activateDate = formik.values[FieldsEnum.activateDate];
    const minValidTillDate = React.useMemo(() => {
        if (!activateDate) {
            return datePicketRangePresets.documentValidTill.minDate;
        }

        return moment(activateDate).add(MIN_VALID_FROM_VALID_TILL_DAYS, 'day').format();
    }, [activateDate]);

    const validTillDate = formik.values[FieldsEnum.validTillDate];
    const maxActivateDate = React.useMemo(() => {
        if (!validTillDate) {
            return datePicketRangePresets.documentValidTill.maxDate;
        }

        return moment(validTillDate).subtract(1, 'day').format();
    }, [validTillDate]);

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('sidebars.upload-shipper-contract.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--name')}
                        name={FieldsEnum.name}
                        error={formik.errors[FieldsEnum.name]}
                        meta={formik.getFieldMeta(FieldsEnum.name)}
                        label={t('sidebars.upload-shipper-contract.fields.name.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.name}
                                value={formik.values[FieldsEnum.name]}
                                placeholder={t('sidebars.upload-shipper-contract.fields.name.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--number')}
                        name={FieldsEnum.number}
                        error={formik.errors[FieldsEnum.number]}
                        meta={formik.getFieldMeta(FieldsEnum.number)}
                        tooltipNode={
                            <TooltipContent isCenter theme={TooltipContentThemeEnum.black} width={150}>
                                {t('sidebars.upload-shipper-contract.fields.number.tooltip')}
                            </TooltipContent>
                        }
                        tooltipPosition={TooltipPositionEnum.centerLeft}
                        label={t('sidebars.upload-shipper-contract.fields.number.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.number}
                                value={formik.values[FieldsEnum.number]}
                                placeholder={t('sidebars.upload-shipper-contract.fields.number.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--activate-date')}
                        name={FieldsEnum.activateDate}
                        error={formik.errors[FieldsEnum.activateDate]}
                        meta={formik.getFieldMeta(FieldsEnum.activateDate)}
                        label={t('sidebars.upload-shipper-contract.fields.activate-date.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                value={formik.values[FieldsEnum.activateDate]}
                                placeholder={t('sidebars.upload-shipper-contract.fields.activate-date.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                minDate={datePicketRangePresets.documentValidTill.minDate}
                                maxDate={maxActivateDate}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                                hasYearMonthForm
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--valid-till')}
                        name={FieldsEnum.validTillDate}
                        error={formik.errors[FieldsEnum.validTillDate]}
                        meta={formik.getFieldMeta(FieldsEnum.validTillDate)}
                        label={t('sidebars.upload-shipper-contract.fields.valid-till-date.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                value={formik.values[FieldsEnum.validTillDate]}
                                placeholder={t('sidebars.upload-shipper-contract.fields.valid-till-date.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                minDate={minValidTillDate}
                                maxDate={datePicketRangePresets.documentValidTill.maxDate}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                hasClearControl
                                hasYearMonthForm
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FormikField
                    className={cx('field', 'field--file')}
                    name={FieldsEnum.file}
                    error={formik.errors[FieldsEnum.file]}
                    meta={formik.getFieldMeta(FieldsEnum.file)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    withoutLabel
                >
                    {(props) => (
                        <FilePickerInput
                            accept="application/pdf"
                            name={FieldsEnum.file}
                            value={formik.values[FieldsEnum.file]}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasClearControl
                            renderLeftIcon={({ isEmpty }) => (
                                <PdfFileIcon
                                    fillColor={isEmpty ? StyleGuideColorsEnum.charcoal : StyleGuideColorsEnum.brandDark}
                                />
                            )}
                        />
                    )}
                </FormikField>
                <FooterSideBarLayout hasPaddings>
                    <Button
                        theme={ButtonThemeEnum.primary}
                        isLoading={uploadRequestStatus.loading}
                        type="submit"
                        className={cx('button')}
                    >
                        {t('sidebars.upload-shipper-contract.actions.submit')}
                    </Button>
                </FooterSideBarLayout>
            </SideBarLayout>
        </form>
    );
};

export default UploadShipperContractSidebarContent;
