import {
    CarrierUtilizationResetActionT,
    FETCH_UTILIZATION_REQUEST,
    FETCH_UTILIZATION_REQUEST_BEGIN,
    FETCH_UTILIZATION_REQUEST_ERROR,
    FETCH_UTILIZATION_REQUEST_SUCCESS,
    FetchCarrierUtilizationActionT,
    FetchCarrierUtilizationBeginActionT,
    FetchCarrierUtilizationErrorActionT,
    FetchCarrierUtilizationSuccessActionT,
    RESET,
} from './types';
import { CarrierUtilizationT } from './models';

export const fetchCarrierUtilization = (ids: CarrierIdT[], date: string): FetchCarrierUtilizationActionT => ({
    type: FETCH_UTILIZATION_REQUEST,
    ids,
    date,
});

export const fetchCarrierUtilizationBegin = (): FetchCarrierUtilizationBeginActionT => ({
    type: FETCH_UTILIZATION_REQUEST_BEGIN,
});

export const fetchCarrierUtilizationError = (error: Error): FetchCarrierUtilizationErrorActionT => ({
    type: FETCH_UTILIZATION_REQUEST_ERROR,
    error,
});

export const fetchCarrierUtilizationSuccess = (
    ids: CarrierIdT[],
    date: string,
    utilizations: Array<CarrierUtilizationT>,
): FetchCarrierUtilizationSuccessActionT => ({
    type: FETCH_UTILIZATION_REQUEST_SUCCESS,
    ids,
    date,
    utilizations,
});

export const resetCarrierUtilization = (): CarrierUtilizationResetActionT => ({
    type: RESET,
});
