import {
    FETCH_PARTNER_DETAILS_REQUEST,
    FETCH_PARTNER_DETAILS_REQUEST_BEGIN,
    FETCH_PARTNER_DETAILS_REQUEST_ERROR,
    FETCH_PARTNER_DETAILS_REQUEST_SUCCESS,
    FetchPartnerDetailsActionT,
    FetchPartnerDetailsBeginActionT,
    FetchPartnerDetailsErrorActionT,
    FetchPartnerDetailsSuccessActionT,
    LOCK_PARTNER_REQUEST,
    LOCK_PARTNER_REQUEST_BEGIN,
    LOCK_PARTNER_REQUEST_ERROR,
    LOCK_PARTNER_REQUEST_SUCCESS,
    UPDATE_PARTNER_DETAILS_REQUEST,
    UPDATE_PARTNER_DETAILS_REQUEST_BEGIN,
    UPDATE_PARTNER_DETAILS_REQUEST_ERROR,
    UPDATE_PARTNER_DETAILS_REQUEST_SUCCESS,
    UpdatePartnerDetailsActionT,
    UpdatePartnerDetailsBeginActionT,
    UpdatePartnerDetailsErrorActionT,
    UpdatePartnerDetailsSuccessActionT,
    VERIFY_PARTNER_DETAILS_REQUEST,
    VERIFY_PARTNER_DETAILS_REQUEST_BEGIN,
    VERIFY_PARTNER_DETAILS_REQUEST_ERROR,
    VERIFY_PARTNER_DETAILS_REQUEST_SUCCESS,
    VerifyPartnerDetailsActionT,
    VerifyPartnerDetailsBeginActionT,
    VerifyPartnerDetailsErrorActionT,
    VerifyPartnerDetailsSuccessActionT,
    LockPartnerBeginActionT,
    LockPartnerSuccessActionT,
    LockPartnerErrorActionT,
    LockPartnerActionT,
} from './types';
import { PartnerDetailsT } from './models';

import { AddressTypeEnum, PartnerTypeEnum } from 'common/utils/api/models';

export const fetchPartnerDetails = (companyId: CompanyIdT): FetchPartnerDetailsActionT => ({
    type: FETCH_PARTNER_DETAILS_REQUEST,
    companyId,
});

export const fetchPartnerDetailsBegin = (): FetchPartnerDetailsBeginActionT => ({
    type: FETCH_PARTNER_DETAILS_REQUEST_BEGIN,
});

export const fetchPartnerDetailsSuccess = (
    partnerDetails: PartnerDetailsT | null,
): FetchPartnerDetailsSuccessActionT => ({
    type: FETCH_PARTNER_DETAILS_REQUEST_SUCCESS,
    partnerDetails,
});

export const fetchPartnerDetailsError = (error: Error): FetchPartnerDetailsErrorActionT => ({
    type: FETCH_PARTNER_DETAILS_REQUEST_ERROR,
    error,
});

export const updatePartnerDetails = (
    companyId: CompanyIdT,
    partnerType: PartnerTypeEnum,
    partnerDetailsChanges: Partial<PartnerDetailsT>,
): UpdatePartnerDetailsActionT => ({
    type: UPDATE_PARTNER_DETAILS_REQUEST,
    partnerDetailsChanges,
    partnerType,
    companyId,
});

export const updatePartnerDetailsBegin = (): UpdatePartnerDetailsBeginActionT => ({
    type: UPDATE_PARTNER_DETAILS_REQUEST_BEGIN,
});

export const updatePartnerDetailsSuccess = (partnerDetails: PartnerDetailsT): UpdatePartnerDetailsSuccessActionT => ({
    type: UPDATE_PARTNER_DETAILS_REQUEST_SUCCESS,
    partnerDetails,
});

export const updatePartnerDetailsError = (error: Error): UpdatePartnerDetailsErrorActionT => ({
    type: UPDATE_PARTNER_DETAILS_REQUEST_ERROR,
    error,
});

export const verifyPartnerDetails = (
    companyId: CompanyIdT,
    addressType: AddressTypeEnum,
): VerifyPartnerDetailsActionT => ({
    type: VERIFY_PARTNER_DETAILS_REQUEST,
    isVerify: true,
    companyId,
    addressType,
});

export const unVerifyPartnerDetails = (
    companyId: CompanyIdT,
    addressType: AddressTypeEnum,
): VerifyPartnerDetailsActionT => ({
    type: VERIFY_PARTNER_DETAILS_REQUEST,
    isVerify: false,
    companyId,
    addressType,
});

export const verifyPartnerDetailsBegin = (): VerifyPartnerDetailsBeginActionT => ({
    type: VERIFY_PARTNER_DETAILS_REQUEST_BEGIN,
});

export const verifyPartnerDetailsSuccess = (): VerifyPartnerDetailsSuccessActionT => ({
    type: VERIFY_PARTNER_DETAILS_REQUEST_SUCCESS,
});

export const verifyPartnerDetailsError = (error: Error): VerifyPartnerDetailsErrorActionT => ({
    type: VERIFY_PARTNER_DETAILS_REQUEST_ERROR,
    error,
});

export const unlockPartner = (companyId: CompanyIdT): LockPartnerActionT => ({
    type: LOCK_PARTNER_REQUEST,
    isLock: false,
    companyId,
});

export const lockPartner = (companyId: CompanyIdT): LockPartnerActionT => ({
    type: LOCK_PARTNER_REQUEST,
    isLock: true,
    companyId,
});

export const lockPartnerBegin = (): LockPartnerBeginActionT => ({
    type: LOCK_PARTNER_REQUEST_BEGIN,
});

export const lockPartnerSuccess = (): LockPartnerSuccessActionT => ({
    type: LOCK_PARTNER_REQUEST_SUCCESS,
});

export const lockPartnerError = (error: Error): LockPartnerErrorActionT => ({
    type: LOCK_PARTNER_REQUEST_ERROR,
    error,
});
