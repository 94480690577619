import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AdditionalServicesSettings.scss';
import { useDispatch, useSelector } from 'react-redux';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import { useTranslation } from 'react-i18next';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import TableError from 'common/components/Table/TableError/TableError';
import {
    fetchAdditionalServices,
    updateAdditionalService,
} from 'broker-admin/store/price-settings/additional-services/actions';
import {
    selectAdditionalServices,
    selectFetchAdditionalServicesRequest,
    selectUpdateAdditionalServiceRequest,
} from 'broker-admin/store/price-settings/additional-services/selectors';
import ServicesTable from 'broker-admin/layouts/SettingsPage/AdditionalServicesSettings/ServicesTable/ServicesTable';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';

const cx = classNames.bind(styles);

type PropsT = {};

const AdditionalServicesSettings: React.FC<PropsT> = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const list = useSelector(selectAdditionalServices);

    const updateAdditionalServiceRequestStatus = useSelector(selectUpdateAdditionalServiceRequest);
    const fetchAdditionalServiceRequestStatus = useSelector(selectFetchAdditionalServicesRequest);

    React.useEffect(() => {
        dispatch(
            fetchAdditionalServices({
                isForceUpdate: false,
            }),
        );

        return (): void => {
            // TODO reset
        };
    }, []);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(
            fetchAdditionalServices({
                isForceUpdate: false,
            }),
        );
    }, []);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const handleUpdateService = (id: AdditionalServiceIdT, cost: number) => {
        dispatch(updateAdditionalService(id, cost));
    };

    const isLoading = fetchAdditionalServiceRequestStatus.loading || updateAdditionalServiceRequestStatus.loading;

    return (
        <ContentMargins>
            {!list.length && fetchAdditionalServiceRequestStatus?.ok && (
                <TableMessage
                    iconType={TableMessageIconsEnum.empty}
                    title={t('price-settings.rate-matrix.empty.title')}
                    description=""
                    isShowAction={false}
                />
            )}
            {!list.length && fetchAdditionalServiceRequestStatus?.error && <TableError />}
            <ServicesTable
                onUpdateService={handleUpdateService}
                list={list}
                isLoading={isLoading}
                isDisabled={updateAdditionalServiceRequestStatus.loading}
                className={cx('table')}
            />
        </ContentMargins>
    );
};

export default AdditionalServicesSettings;
