import * as React from 'react';

import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import { Trans, useTranslation } from 'react-i18next';
import { DATA_SOURCE_LABELS_T } from 'broker-admin/layouts/StatisticsPage/DataSourceDropdown/DataSourceDropdown';
import CountriesFilterPill from 'common/components/filter-pills/CountriesFilterPill/CountriesFilterPill';
import { StatisticSourceEnum } from 'broker-admin/utils/api/broker-tranziit/models';

type PropsT = {
    isCompact?: boolean;
    className?: string;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const SelectedFilters: React.FC<PropsT> = React.memo((props) => {
    const { isCompact, queryFilters, setQueryFilters } = props;

    const { t } = useTranslation();

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    return t('statistics.filters.pills.data-source', {
                        value: t(
                            DATA_SOURCE_LABELS_T[queryFilters[QueryFiltersKeysEnum.dataSource] as StatisticSourceEnum],
                        ),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dataSource],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const countryCode = queryFilters[QueryFiltersKeysEnum.fromCountryCode];
                    return (
                        <Trans
                            i18nKey="statistics.filters.pills.from-country-code"
                            components={{
                                value: <CountriesFilterPill countryCodes={countryCode ? [countryCode] : null} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.fromCountryCode],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('statistics.filters.pills.from-zip-code', {
                        value: queryFilters[QueryFiltersKeysEnum.fromZipCode],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.fromZipCode],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const countryCode = queryFilters[QueryFiltersKeysEnum.toCountryCode];
                    return (
                        <Trans
                            i18nKey="statistics.filters.pills.to-country-code"
                            components={{
                                value: <CountriesFilterPill countryCodes={countryCode ? [countryCode] : null} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.toCountryCode],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('statistics.filters.pills.to-zip-code', {
                        value: queryFilters[QueryFiltersKeysEnum.toZipCode],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.toZipCode],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    return t('statistics.filters.pills.shipper', {
                        value: queryFilters[QueryFiltersKeysEnum.shipperName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.shipperName, QueryFiltersKeysEnum.shipperId],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    return t('statistics.filters.pills.carrier', {
                        value: queryFilters[QueryFiltersKeysEnum.carrierName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.carrierName, QueryFiltersKeysEnum.carrierId],
            },
        ],
        [t],
    );

    return (
        <FilterPills<QueryFiltersT>
            isCompact={isCompact}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            config={filterPillsConfig}
        />
    );
});

export default SelectedFilters;
