import React from 'react';
import { useTranslation } from 'react-i18next';
import { RULE_GROUP_T_MAP } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/constants';
import { checkIsPriceSettingsRuleGroup } from '../../utils';

type PropsT = {
    group: string | null | undefined;
};

const GroupFilterPill: React.FC<PropsT> = React.memo((props) => {
    const { group } = props;

    const { t } = useTranslation();

    if (checkIsPriceSettingsRuleGroup(group)) {
        return <span>{t(RULE_GROUP_T_MAP[group])}</span>;
    }

    return <span>{group}</span>;
});

export default GroupFilterPill;
