import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './LocationCell.scss';
import { PartnerT } from 'broker-admin/store/partners/models';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';

type PropsT = {
    partner: PartnerT;
};

const cx = classNames.bind(styles);

const LocationCell: React.FC<PropsT> = React.memo((props) => {
    const { partner } = props;

    const address = [partner.city, partner.zipCode].filter(Boolean).join(', ');

    return (
        <div>
            <div className={cx('wrap')}>
                {!!partner.countryCode && <FlagIcon className={cx('flag')} countryCode={partner.countryCode} />}
                <span className={cx('country')}>{partner.country}</span>
            </div>
            <div className={cx('address')}>{address}</div>
        </div>
    );
});

export default LocationCell;
