import { QueryFiltersT } from './query-models';
import { formatPageQuerySort } from 'common/utils/page-sort';
import { QueryFiltersKeysEnum } from 'common/constants';
import { FetchPartnersPageQueryT } from 'broker-admin/store/partners/models';
import { CountryCodeUnionT, PartnersSortEnum } from 'common/utils/api/models';
import { PageSortT } from 'common/utils/query';

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<PartnersSortEnum> | null,
): FetchPartnersPageQueryT => {
    return {
        includeProfileTypes: queryFilters.partnerType ? [queryFilters.partnerType] : undefined,
        defaultKeyAccountManagerId: queryFilters.keyAccountManagerId || undefined,
        defaultDispatcherId: queryFilters.dispatcherId || undefined,
        country: queryFilters.countryCode as CountryCodeUnionT,
        name: queryFilters.searchText || undefined,
        notVerified: !!queryFilters[QueryFiltersKeysEnum.withUpdates],
        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
