import qs from 'qs';
import { CompanyTypeEnum } from 'common/constants';
import {
    AssetRoutesEnum,
    DispatchesRoutesEnum,
    OMSRoutesEnum,
    PartnersDocumentsRoutesEnum,
    PartnersRoutesEnum,
    ShipperContractDetailsEnum,
    ShipperContractLaneRoutesEnum,
    SpotRoutesEnum,
} from 'broker-admin/constants';
import { generatePath } from 'react-router';
import { DocumentTypeT, PartnerTypeEnum } from 'common/utils/api/models';
import { getRePath } from 'common/utils/urls';
import { CommonSidebarsTypeEnum, SidebarQueryKeysEnum } from 'common/layouts/SideBars/models';
import { JSONArrayParam } from 'common/utils/query';
import { BrokerSidebarDataT } from 'broker-admin/layouts/SideBars/models';
import { BrokerSidebarsTypeEnum } from 'broker-admin/layouts/SideBars/constants';
import { DocumentDetailsSidebarDataT } from 'common/layouts/SideBars/contents/DocumentDetailsSidebarContent/models';
import { ContractDetailsSidebarDataT } from 'common/layouts/SideBars/contents/ContractDetailsSidebarContent/models';

export const urlFactory = {
    orderDetails: (orderId: OrderIdT) => {
        return generatePath(OMSRoutesEnum.orderDetails, {
            orderId,
        });
    },
    dispatchDetails: (dispatchId: DispatchIdT) => {
        return {
            state: {
                rePath: getRePath(),
            },
            pathname: generatePath(DispatchesRoutesEnum.dispatchDetails, {
                dispatchId,
            }),
        };
    },
    partnerDetails: (partnerType: PartnerTypeEnum | CompanyTypeEnum, partnerId: CompanyIdT | undefined) => {
        return {
            pathname: generatePath(PartnersRoutesEnum.partnerDetails, {
                partnerType,
                partnerId: partnerId || 'null',
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    truckDetails: (partnerId: CompanyIdT | undefined, truckId: TruckIdT | undefined) => {
        return {
            pathname: generatePath(AssetRoutesEnum.truckDetails, {
                partnerType: PartnerTypeEnum.carrier,
                partnerId: partnerId || 'null',
                truckId: truckId || 'null',
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    trailerDetails: (partnerId: CompanyIdT | undefined, trailerId: TrailerIdT | undefined) => {
        return {
            pathname: generatePath(AssetRoutesEnum.trailerDetails, {
                partnerType: PartnerTypeEnum.carrier,
                partnerId: partnerId || 'null',
                trailerId: trailerId || 'null',
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    partnerTransportOrderDetails: (
        partnerType: PartnerTypeEnum | CompanyTypeEnum,
        partnerId: CompanyIdT | undefined,
        transportOrderId: TransportOrderIdT | undefined,
    ) => {
        return {
            pathname: generatePath(PartnersRoutesEnum.partnerTransportOrderDetails, {
                partnerType,
                partnerId: partnerId || 'null',
                transportOrderId: transportOrderId || 'null',
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    partnerTransportOrders: (partnerType: PartnerTypeEnum | CompanyTypeEnum, partnerId: CompanyIdT | undefined) => {
        return {
            pathname: generatePath(PartnersRoutesEnum.partnerTransportOrders, {
                partnerType,
                partnerId: partnerId || 'null',
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    spotRequestDetails: (spotRequestId: SpotRequestIdT) => {
        return {
            pathname: generatePath(SpotRoutesEnum.spotRequestDetails, {
                spotRequestId,
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    spotRequestsListWithDetails: (spotRequestId: SpotRequestIdT) => {
        return `${SpotRoutesEnum.list}?${qs.stringify({
            [SidebarQueryKeysEnum.leftSidebar]: JSONArrayParam.encode<BrokerSidebarDataT>([
                {
                    type: BrokerSidebarsTypeEnum.spotRequestDetails,
                    id: spotRequestId,
                },
            ]),
        })}`;

        return {
            pathname: generatePath(SpotRoutesEnum.spotRequestDetails, {
                spotRequestId,
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    requiredPartnerDocuments: (partnerId: PartnerIdT, partnerType: PartnerTypeEnum) => {
        return generatePath(PartnersDocumentsRoutesEnum.requiredPartnerDocuments, {
            partnerType,
            partnerId,
        });
    },
    optionalPartnerDocuments: (partnerId: PartnerIdT, partnerType: PartnerTypeEnum) => {
        return generatePath(PartnersDocumentsRoutesEnum.optionalPartnerDocuments, {
            partnerType,
            partnerId,
        });
    },
    partnerDocumentDetails: (partnerId: PartnerIdT, partnerType: PartnerTypeEnum, documentType: DocumentTypeT) => {
        const partnerDocuments = generatePath(PartnersDocumentsRoutesEnum.requiredPartnerDocuments, {
            partnerType,
            partnerId,
        });

        return `${partnerDocuments}?${qs.stringify({
            [SidebarQueryKeysEnum.leftSidebar]: JSONArrayParam.encode<DocumentDetailsSidebarDataT>([
                {
                    type: CommonSidebarsTypeEnum.document,
                    documentType,
                    partnerId,
                    partnerType,
                    initIsEditing: false,
                },
            ]),
        })}`;
    },
    partnerCarrierContacts: (partnerId: PartnerIdT, partnerType: PartnerTypeEnum) => {
        return generatePath(PartnersDocumentsRoutesEnum.partnerCarrierContracts, {
            partnerType,
            partnerId,
        });
    },
    partnerShipperContacts: (partnerId: PartnerIdT, partnerType: PartnerTypeEnum) => {
        return generatePath(PartnersDocumentsRoutesEnum.partnerShipperContracts, {
            partnerType,
            partnerId,
        });
    },
    partnerCarrierContactDetails: (
        partnerId: PartnerIdT,
        partnerType: PartnerTypeEnum,
        contractId: CarrierContractIdT,
    ) => {
        const partnerContracts = generatePath(PartnersDocumentsRoutesEnum.partnerCarrierContracts, {
            partnerType,
            partnerId,
        });

        return `${partnerContracts}?${qs.stringify({
            [SidebarQueryKeysEnum.leftSidebar]: JSONArrayParam.encode<ContractDetailsSidebarDataT>([
                {
                    type: CommonSidebarsTypeEnum.carrierContract,
                    contractId,
                    initIsEditing: false,
                },
            ]),
        })}`;
    },
    partnerShipperContactDetails: ({
        partnerId,
        partnerType,
        shipperContractId,
    }: {
        partnerId: PartnerIdT;
        partnerType: PartnerTypeEnum;
        shipperContractId: ShipperContractIdT;
    }) => {
        const pathname = generatePath(ShipperContractDetailsEnum.details, {
            partnerType,
            partnerId,
            contractId: shipperContractId,
        });

        return {
            pathname,
            state: {
                rePath: getRePath(),
            },
        };
    },
    partnerShipperContactLanes: ({
        partnerId,
        partnerType,
        contractId,
    }: {
        partnerId: PartnerIdT;
        partnerType: PartnerTypeEnum;
        contractId: ShipperContractIdT;
    }) => {
        const pathname = generatePath(ShipperContractDetailsEnum.lanes, {
            partnerType,
            partnerId,
            contractId,
        });

        return {
            pathname,
            state: {
                rePath: getRePath(),
            },
        };
    },
    newPartnerShipperContractLane: ({
        partnerId,
        partnerType,
        contractId,
    }: {
        partnerId: PartnerIdT;
        partnerType: PartnerTypeEnum;
        contractId: ShipperContractIdT;
    }) => {
        const pathname = generatePath(ShipperContractLaneRoutesEnum.newLane, {
            partnerId,
            partnerType,
            contractId,
        });

        return {
            pathname,
            state: {
                rePath: getRePath(),
            },
        };
    },
    partnerShipperContractLane: ({
        partnerId,
        partnerType,
        contractId,
        laneId,
    }: {
        partnerId: PartnerIdT;
        partnerType: PartnerTypeEnum;
        contractId: ShipperContractIdT;
        laneId: ShipperContractLaneIdT;
    }) => {
        const pathname = generatePath(ShipperContractLaneRoutesEnum.laneDetails, {
            partnerId,
            partnerType,
            contractId,
            laneId,
        });

        return {
            pathname,
            state: {
                rePath: getRePath(),
            },
        };
    },
};
