import { FieldsEnum, FormValuesT } from './constants';
import { SystemSettingsT } from 'broker-admin/store/price-settings/system-settings/models';

const getInitialValues = (systemSettings: SystemSettingsT | null | undefined): FormValuesT => {
    return {
        [FieldsEnum.driverWorkingDay]: systemSettings?.DRIVER_WORKING_DAY || '',
        [FieldsEnum.driverRestTime]: systemSettings?.DRIVER_REST_TIME || '',
    };
};

export default getInitialValues;
