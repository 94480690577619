import React from 'react';
import classNames from 'classnames/bind';

import styles from './ChartTooltip.scss';

import { DataItemT, TooltipOffsetT } from '../models';
import CircleIcon from 'common/icons/CircleIcon';
import { CurrencyEnum, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import Money from 'common/components/Money/Money';
import { Trans, useTranslation } from 'react-i18next';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import isNumber from 'lodash/isNumber';
import { GraphSettingsT } from 'broker-admin/layouts/StatisticsPage/models';
import { AggregateEnum } from 'broker-admin/layouts/StatisticsPage/constants';

const cx = classNames.bind(styles);

type PropsT = {
    data: DataItemT | null;
    offset: TooltipOffsetT | null;
    settings: GraphSettingsT;
};

const ChartTooltip: React.FC<PropsT> = React.memo((props) => {
    const { data, offset, settings } = props;

    const { t } = useTranslation();

    if (!offset) {
        return null;
    }

    const deadheadValue = data?.tooltipData?.deadhead;
    const deadhead = isNumber(deadheadValue) ? +deadheadValue.toFixed(2) : null;

    const marginValue = data?.tooltipData?.margin;
    const margin = isNumber(marginValue) ? +marginValue.toFixed(2) : null;

    const notNumberMessage = t('common:not-number');

    return (
        <div
            className={cx('wrap')}
            style={{
                top: `${offset?.top || 0}px`,
                left: `${offset?.left || 0}px`,
            }}
        >
            <div className={cx('content')}>
                <div className={cx('row')}>
                    <CircleIcon borderColor={StyleGuideColorsEnum.tomatoRed} className={cx('row__icon')} />
                    <div className={cx('row__text')}>
                        <Trans
                            i18nKey="statistics.chart.tooltip.rejected"
                            components={{
                                value: settings.available[AggregateEnum.rejectedShipperRate] ? (
                                    <Money
                                        amount={data?.tooltipData?.rejectedShipperRate}
                                        currency={CurrencyEnum.EUR}
                                    />
                                ) : (
                                    notNumberMessage
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className={cx('row')}>
                    <CircleIcon borderColor={StyleGuideColorsEnum.brandAccent} className={cx('row__icon')} />
                    <div className={cx('row__text')}>
                        <Trans
                            i18nKey="statistics.chart.tooltip.accepted"
                            components={{
                                value: settings.available[AggregateEnum.acceptedShipperRate] ? (
                                    <Money
                                        amount={data?.tooltipData?.acceptedShipperRate}
                                        currency={CurrencyEnum.EUR}
                                    />
                                ) : (
                                    notNumberMessage
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className={cx('row')}>
                    <CircleIcon borderColor={StyleGuideColorsEnum.gray} className={cx('row__icon')} />
                    <div className={cx('row__text')}>
                        <Trans
                            i18nKey="statistics.chart.tooltip.estimated"
                            components={{
                                value: settings.available[AggregateEnum.estimatedCarrierRate] ? (
                                    <Money
                                        amount={data?.tooltipData?.estimatedCarrierRate}
                                        currency={CurrencyEnum.EUR}
                                    />
                                ) : (
                                    notNumberMessage
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className={cx('row')}>
                    <CircleIcon borderColor={StyleGuideColorsEnum.orange} className={cx('row__icon')} />
                    <div className={cx('row__text')}>
                        <Trans
                            i18nKey="statistics.chart.tooltip.real"
                            components={{
                                value: settings.available[AggregateEnum.realCarrierRate] ? (
                                    <Money amount={data?.tooltipData?.realCarrierRate} currency={CurrencyEnum.EUR} />
                                ) : (
                                    notNumberMessage
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className={cx('row')}>
                    <CircleIcon borderColor={StyleGuideColorsEnum.light} className={cx('row__icon')} />
                    <div className={cx('row__text')}>
                        <Trans
                            i18nKey="statistics.chart.tooltip.margin"
                            components={{
                                value: settings.available[AggregateEnum.margin] ? (
                                    <UnitTypeCount count={margin} type={UnitTypeEnum.percentsAbbreviation} />
                                ) : (
                                    notNumberMessage
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className={cx('row')}>
                    <CircleIcon borderColor={StyleGuideColorsEnum.light} className={cx('row__icon')} />
                    <div className={cx('row__text')}>
                        <Trans
                            i18nKey="statistics.chart.tooltip.deadhead"
                            components={{
                                value: <UnitTypeCount count={deadhead} type={UnitTypeEnum.percentsAbbreviation} />,
                            }}
                        />
                    </div>
                </div>
                <div className={cx('row')}>
                    <CircleIcon borderColor={StyleGuideColorsEnum.light} className={cx('row__icon')} />
                    <div className={cx('row__text')}>
                        <Trans
                            i18nKey="statistics.chart.tooltip.avg-distance"
                            components={{
                                value: (
                                    <UnitTypeCount
                                        count={data?.tooltipData?.avgDistance}
                                        type={UnitTypeEnum.kilometersAbbreviation}
                                    />
                                ),
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ChartTooltip;
