import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ShipperContractLaneCreationForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { FieldsEnum, FormValuesT, MAX_RADIUS_KM, MIN_VALID_FROM_VALID_TILL_DAYS } from './constants';
import { UnitTypeEnum } from 'common/constants';
import { useDispatch, useSelector } from 'react-redux';
import DictTrailerDropdown from 'common/components/dropdowns/DictTrailerDropdown/DictTrailerDropdown';
import OriginIcon from 'common/icons/OriginIcon';
import GeoSuggest from 'common/components/GeoSuggest/GeoSuggest';
import DestinationIcon from 'common/icons/DestinationIcon';
import validateForm from './validate-form';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { DEFAULT_POINT_ZOOM } from 'common/store/constants';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import isEqual from 'lodash/isEqual';
import EmissionClassDropdown from 'common/components/dropdowns/EmissionClassDropdown/EmissionClassDropdown';
import NumberInput from 'common/components/NumberInput/NumberInput';
import DatePicker, {
    DatePickerOverlayPositionEnum,
    datePicketRangePresets,
} from 'design-system/components/date-pickers/DatePicker/DatePicker';
import Input from 'common/components/Input/Input';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import {
    selectShipperLaneDestination,
    selectShipperLaneOrigin,
} from 'common/store/shipper-contract-lane-creation/selectors';
import FieldGroup, { FieldGroupEmptyItem } from 'common/components/FieldGroup/FieldGroup';
import {
    setDestinationLocation,
    setDestinationLocationRadius,
    setMapSettings,
    setOriginLocation,
    setOriginLocationRadius,
} from 'common/store/shipper-contract-lane-creation/slice';
import getBoundingBox, { createGeoPoint } from 'common/utils/get-bounding-box';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import {
    CalculateCostRequestQueryT,
    CreateRequestQueryDraftT,
} from 'common/store/shipper-contract-lane-creation/models';
import { useMemo } from 'react';
import moment from 'moment/moment';
import { prepareApiCalculateCostRequestQuery, prepareApiCreateRequestQueryDraft } from './prepare-api-requests';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { fetchShipperContractDetails } from 'common/store/shipper-contract-details/slice';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { selectShipperContractDetailsState } from 'common/store/shipper-contract-details/selectors';
import { useEqualPreviousValue } from 'common/utils/hooks/useEqualPreviousValue';
import { getDateFromISO, getMaxISODate, getMinISODate } from 'common/utils/time';

const cx = classNames.bind(styles);

type PropsT = {
    contractId: ShipperContractIdT | null;
    onSubmit: (data: CalculateCostRequestQueryT, createQueryDraft: CreateRequestQueryDraftT) => void;
};

export const DEFAULT_INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.trailerDictId]: null,
    [FieldsEnum.emissionClass]: null,

    [FieldsEnum.pickUpLocation]: null,
    [FieldsEnum.pickUpLocationRadius]: '',

    [FieldsEnum.dropOffLocation]: null,
    [FieldsEnum.dropOffLocationRadius]: '',

    [FieldsEnum.validFrom]: null,
    [FieldsEnum.validTill]: null,

    [FieldsEnum.layoverLimit]: '',
    [FieldsEnum.ordersLimit]: '',

    [FieldsEnum.externalLaneId]: '',

    [FieldsEnum.includeServiceFee]: false,
    [FieldsEnum.includeTeamDrive]: false,
    [FieldsEnum.useApplyRules]: false,
};

const ShipperContractLaneCreationForm: React.FC<PropsT> = React.memo((props) => {
    const { contractId, onSubmit } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { partnerId } = usePartnerContext();

    const countriesByCode = useSelector(selectCountriesByCode);
    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const contractDetailsState = useSelector(selectShipperContractDetailsState(contractId as ShipperContractIdT));
    React.useEffect(() => {
        if (!contractId) {
            return;
        }

        dispatch(
            fetchShipperContractDetails({
                partnerId,
                shipperContractId: contractId,
            }),
        );
    }, [partnerId, contractId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        if (!contractId) {
            return;
        }

        dispatch(
            fetchShipperContractDetails({
                partnerId,
                shipperContractId: contractId,
            }),
        );
    }, [partnerId, contractId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const protectedContractDetails = useEqualPreviousValue(contractDetailsState?.data || null);
    const validate = React.useCallback(
        (values: FormValuesT) => {
            return validateForm(t, values, {
                countriesByCode,
                contractDetails: protectedContractDetails,
            });
        },
        [t, countriesByCode, protectedContractDetails],
    );

    const [initialValues, initialErrors] = React.useMemo(() => {
        const contractValidFrom = protectedContractDetails?.validFrom
            ? new Date(protectedContractDetails.validFrom)
            : null;
        const now = new Date();

        const values = {
            ...DEFAULT_INITIAL_VALUES,
            [FieldsEnum.validFrom]: contractValidFrom && now < contractValidFrom ? contractValidFrom : now,
        };

        const errors = validateForm(t, values, {
            countriesByCode,
            contractDetails: protectedContractDetails || null,
        });

        return [values, errors];
    }, [countriesByCode, protectedContractDetails]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const query = prepareApiCalculateCostRequestQuery(values);
            if (!query) {
                return;
            }

            const createQueryDraft = prepareApiCreateRequestQueryDraft(values);
            if (!createQueryDraft) {
                return;
            }

            onSubmit(query, createQueryDraft);

            formikHelpers.setTouched({});
        },
    });

    const handleRadiusSetValue = (field: string, value: string): void => {
        formik.setFieldValue(field, value);

        const parsedValue = parseSimpleNumber(value);
        const limitedValue = parsedValue ? Math.min(parsedValue, MAX_RADIUS_KM) : 0;

        if (field === FieldsEnum.dropOffLocationRadius) {
            dispatch(
                setDestinationLocationRadius({
                    radiusKm: limitedValue,
                }),
            );
        }

        if (field === FieldsEnum.pickUpLocationRadius) {
            dispatch(
                setOriginLocationRadius({
                    radiusKm: limitedValue,
                }),
            );
        }
    };

    const handleGeoSuggestSetValue = (field: string, value: LocationT | null): void => {
        formik.setFieldValue(field, value);

        const points: Array<GeoPointT | null> = [];

        points.push(createGeoPoint(value?.point?.lat, value?.point?.lng));

        if (field === FieldsEnum.dropOffLocation) {
            dispatch(setDestinationLocation({ location: value }));

            points.push(
                createGeoPoint(
                    formik.values[FieldsEnum.pickUpLocation]?.point?.lat,
                    formik.values[FieldsEnum.pickUpLocation]?.point?.lng,
                ),
            );
        }

        if (field === FieldsEnum.pickUpLocation) {
            dispatch(setOriginLocation({ location: value }));

            points.push(
                createGeoPoint(
                    formik.values[FieldsEnum.dropOffLocation]?.point?.lat,
                    formik.values[FieldsEnum.dropOffLocation]?.point?.lng,
                ),
            );
        }

        const boundingBox = getBoundingBox(points);
        dispatch(
            setMapSettings({
                settings: {
                    center: null,
                    zoom: points?.length === 1 ? DEFAULT_POINT_ZOOM : null,
                    boundingBox,
                },
            }),
        );
    };

    // sync destiation location, redux => form
    const destinationLocation = useSelector(selectShipperLaneDestination);
    React.useEffect(() => {
        const isSame = isEqual(destinationLocation?.location, formik.values[FieldsEnum.dropOffLocation]);
        if (isSame) {
            return;
        }

        if (destinationLocation?.location) {
            formik.setFieldValue(FieldsEnum.dropOffLocation, destinationLocation.location);
            formik.setFieldTouched(FieldsEnum.dropOffLocation);
        }
    }, [destinationLocation]);

    // sync origin location, redux => form
    const originLocation = useSelector(selectShipperLaneOrigin);
    React.useEffect(() => {
        const isSame = isEqual(originLocation?.location, formik.values[FieldsEnum.pickUpLocation]);
        if (isSame) {
            return;
        }

        if (originLocation?.location) {
            formik.setFieldValue(FieldsEnum.pickUpLocation, originLocation.location);
            formik.setFieldTouched(FieldsEnum.pickUpLocation);
        }
    }, [originLocation]);

    const validFrom = formik.values[FieldsEnum.validFrom];
    const minValidTillDate = useMemo((): string | undefined => {
        return (
            getMaxISODate(
                moment().add(1, 'day').format(),
                moment(validFrom).add(MIN_VALID_FROM_VALID_TILL_DAYS, 'day').format(),
                protectedContractDetails?.validFrom,
            ) || datePicketRangePresets.anyFutureDate.minDate
        );
    }, [validFrom, protectedContractDetails]);
    const maxValidTillDate = useMemo((): string | undefined => {
        return getMinISODate(protectedContractDetails?.validTill) || datePicketRangePresets.anyFutureDate.maxDate;
    }, [validFrom, protectedContractDetails]);
    const isDisabledValidTillDate = useMemo(() => {
        if (!maxValidTillDate) {
            return false;
        }

        return (
            maxValidTillDate < moment().add(1, 'day').format() ||
            (!!minValidTillDate &&
                !!maxValidTillDate &&
                getDateFromISO(minValidTillDate) > getDateFromISO(maxValidTillDate))
        );
    }, [minValidTillDate, maxValidTillDate]);

    const validTill = formik.values[FieldsEnum.validTill];
    const minValidFromDate = useMemo((): string | undefined => {
        return (
            getMaxISODate(moment().format(), protectedContractDetails?.validFrom) ||
            datePicketRangePresets.anyFutureDate.minDate
        );
    }, [validTill, protectedContractDetails]);
    const maxValidFromDate = useMemo((): string | undefined => {
        return (
            getMinISODate(
                validTill ? moment(validTill).format() : undefined,
                protectedContractDetails?.validTill
                    ? moment(protectedContractDetails?.validTill)
                          .subtract(MIN_VALID_FROM_VALID_TILL_DAYS, 'days')
                          .format()
                    : undefined,
            ) || datePicketRangePresets.anyFutureDate.maxDate
        );
    }, [validTill, protectedContractDetails]);
    const isDisabledValidFromDate = useMemo(() => {
        if (!maxValidFromDate) {
            return false;
        }

        return (
            maxValidFromDate < moment().format() ||
            (!!minValidFromDate &&
                !!maxValidFromDate &&
                getDateFromISO(minValidFromDate) > getDateFromISO(maxValidFromDate))
        );
    }, [maxValidFromDate, minValidFromDate]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <FieldGroup>
                <FormikField
                    className={cx('field', 'field--trailer-type')}
                    name={FieldsEnum.trailerDictId}
                    error={formik.errors[FieldsEnum.trailerDictId]}
                    meta={formik.getFieldMeta(FieldsEnum.trailerDictId)}
                    label={t(`common:shipper-lane-details.fields.trailer-type.label`)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DictTrailerDropdown
                            placeholder={t(`common:shipper-lane-details.fields.trailer-type.placeholder`)}
                            value={formik.values[FieldsEnum.trailerDictId]}
                            onChange={props.onChange}
                            hasWarning={props.hasWarning}
                            hasError={props.hasError}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field', 'field--emission-class')}
                    name={FieldsEnum.emissionClass}
                    error={formik.errors[FieldsEnum.emissionClass]}
                    meta={formik.getFieldMeta(FieldsEnum.emissionClass)}
                    label={t('common:shipper-lane-details.fields.emission-type.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <EmissionClassDropdown
                            value={formik.values[FieldsEnum.emissionClass]}
                            onChange={props.onChange}
                            hasWarning={props.hasWarning}
                            hasError={props.hasError}
                            placeholder={t('common:shipper-lane-details.fields.emission-type.placeholder')}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                        />
                    )}
                </FormikField>
            </FieldGroup>
            <FieldGroup>
                <FormikField
                    className={cx('field', 'field--location')}
                    name={FieldsEnum.pickUpLocation}
                    error={formik.errors[FieldsEnum.pickUpLocation]}
                    meta={formik.getFieldMeta(FieldsEnum.pickUpLocation)}
                    label={t('common:shipper-lane-details.fields.origin.label')}
                    setFieldValue={handleGeoSuggestSetValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <GeoSuggest
                            name={FieldsEnum.pickUpLocation}
                            value={formik.values[FieldsEnum.pickUpLocation]}
                            placeholder={t('common:shipper-lane-details.fields.origin.placeholder')}
                            renderLeftIcon={() => <OriginIcon />}
                            onChange={props.onChange}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            testSelector="pickup-location"
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field', 'field--radius')}
                    name={FieldsEnum.pickUpLocationRadius}
                    error={formik.errors[FieldsEnum.pickUpLocationRadius]}
                    meta={formik.getFieldMeta(FieldsEnum.pickUpLocationRadius)}
                    label={t('common:shipper-lane-details.fields.radius.label')}
                    setFieldValue={handleRadiusSetValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <NumberInput
                            name={FieldsEnum.pickUpLocationRadius}
                            unitType={UnitTypeEnum.kilometersAbbreviation}
                            value={formik.values[FieldsEnum.pickUpLocationRadius]}
                            step={1}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
            </FieldGroup>
            <FieldGroup>
                <FormikField
                    className={cx('field', 'field--location')}
                    name={FieldsEnum.dropOffLocation}
                    error={formik.errors[FieldsEnum.dropOffLocation]}
                    meta={formik.getFieldMeta(FieldsEnum.dropOffLocation)}
                    label={t('common:shipper-lane-details.fields.destination.label')}
                    setFieldValue={handleGeoSuggestSetValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <GeoSuggest
                            name={FieldsEnum.dropOffLocation}
                            value={formik.values[FieldsEnum.dropOffLocation]}
                            placeholder={t('common:shipper-lane-details.fields.destination.placeholder')}
                            renderLeftIcon={() => <DestinationIcon />}
                            onChange={props.onChange}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            testSelector="dropoff-location"
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field', 'field--radius')}
                    name={FieldsEnum.dropOffLocationRadius}
                    error={formik.errors[FieldsEnum.dropOffLocationRadius]}
                    meta={formik.getFieldMeta(FieldsEnum.dropOffLocationRadius)}
                    label={t('common:shipper-lane-details.fields.radius.label')}
                    setFieldValue={handleRadiusSetValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <NumberInput
                            name={FieldsEnum.dropOffLocationRadius}
                            unitType={UnitTypeEnum.kilometersAbbreviation}
                            value={formik.values[FieldsEnum.dropOffLocationRadius]}
                            step={1}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
            </FieldGroup>
            <FieldGroup>
                <FormikField
                    className={cx('field', 'field--date')}
                    name={FieldsEnum.validFrom}
                    error={formik.errors[FieldsEnum.validFrom]}
                    meta={formik.getFieldMeta(FieldsEnum.validFrom)}
                    label={t('common:shipper-lane-details.fields.valid-from.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DatePicker
                            value={formik.values[FieldsEnum.validFrom]}
                            placeholder={t('common:shipper-lane-details.fields.valid-from.placeholder')}
                            onChange={props.onChange}
                            overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasYearMonthForm
                            minDate={minValidFromDate}
                            maxDate={maxValidFromDate}
                            isDisabled={isDisabledValidFromDate}
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field', 'field--date')}
                    name={FieldsEnum.validTill}
                    error={formik.errors[FieldsEnum.validTill]}
                    meta={formik.getFieldMeta(FieldsEnum.validTill)}
                    label={t('common:shipper-lane-details.fields.valid-till.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DatePicker
                            value={formik.values[FieldsEnum.validTill]}
                            placeholder={t('common:shipper-lane-details.fields.valid-till.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasYearMonthForm
                            minDate={minValidTillDate}
                            maxDate={maxValidTillDate}
                            overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                            isDisabled={isDisabledValidTillDate}
                        />
                    )}
                </FormikField>
            </FieldGroup>
            <FieldGroup>
                <FormikField
                    className={cx('field', 'field--limit')}
                    name={FieldsEnum.layoverLimit}
                    error={formik.errors[FieldsEnum.layoverLimit]}
                    meta={formik.getFieldMeta(FieldsEnum.layoverLimit)}
                    label={t('common:shipper-lane-details.fields.layover-limit.label')}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} width={300}>
                            {t('common:shipper-lane-details.fields.layover-limit.tooltip')}
                        </TooltipContent>
                    }
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <NumberInput
                            name={FieldsEnum.layoverLimit}
                            unitType={UnitTypeEnum.hoursAbbreviation}
                            value={formik.values[FieldsEnum.layoverLimit]}
                            step={1}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field', 'field--limit')}
                    name={FieldsEnum.ordersLimit}
                    error={formik.errors[FieldsEnum.ordersLimit]}
                    meta={formik.getFieldMeta(FieldsEnum.ordersLimit)}
                    label={t('common:shipper-lane-details.fields.orders-limit.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <NumberInput
                            name={FieldsEnum.ordersLimit}
                            value={formik.values[FieldsEnum.ordersLimit]}
                            step={1}
                            placeholder={t('common:shipper-lane-details.fields.orders-limit.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
            </FieldGroup>
            <FieldGroup>
                <FormikField
                    className={cx('field', 'field--external-lane-id')}
                    name={FieldsEnum.externalLaneId}
                    error={formik.errors[FieldsEnum.externalLaneId]}
                    meta={formik.getFieldMeta(FieldsEnum.externalLaneId)}
                    label={t('common:shipper-lane-details.fields.external-lane-id.label')}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                            {t('common:shipper-lane-details.fields.external-lane-id.tooltip')}
                        </TooltipContent>
                    }
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.externalLaneId}
                            value={formik.values[FieldsEnum.externalLaneId]}
                            placeholder={t('common:shipper-lane-details.fields.external-lane-id.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FieldGroupEmptyItem className={cx('field', 'field--limit')} />
            </FieldGroup>
            <FormikField
                className={cx('checkbox')}
                name={FieldsEnum.includeServiceFee}
                error={formik.errors[FieldsEnum.includeServiceFee]}
                meta={formik.getFieldMeta(FieldsEnum.includeServiceFee)}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                isCheckbox
                withoutLabel
            >
                {(props) => (
                    <Checkbox
                        checked={formik.values[FieldsEnum.includeServiceFee]}
                        onChange={props.onChange}
                        label={t('common:shipper-lane-details.fields.include-service-fee.label')}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('checkbox')}
                name={FieldsEnum.includeTeamDrive}
                error={formik.errors[FieldsEnum.includeTeamDrive]}
                meta={formik.getFieldMeta(FieldsEnum.includeTeamDrive)}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                isCheckbox
                withoutLabel
            >
                {(props) => (
                    <Checkbox
                        checked={formik.values[FieldsEnum.includeTeamDrive]}
                        onChange={props.onChange}
                        label={t('common:shipper-lane-details.fields.include-team-drive.label')}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('checkbox')}
                name={FieldsEnum.useApplyRules}
                error={formik.errors[FieldsEnum.useApplyRules]}
                meta={formik.getFieldMeta(FieldsEnum.useApplyRules)}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                isCheckbox
                withoutLabel
            >
                {(props) => (
                    <Checkbox
                        checked={formik.values[FieldsEnum.useApplyRules]}
                        onChange={props.onChange}
                        label={t('common:shipper-lane-details.fields.use-apply-rules.label')}
                    />
                )}
            </FormikField>
            <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            <FooterSideBarLayout isAbsolute hasPaddings>
                <Button
                    className={cx('calculate')}
                    theme={ButtonThemeEnum.primary}
                    type="submit"
                    testSelector="next-step"
                >
                    {t('shipper-lane-creation.actions.calculate')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
});

export default ShipperContractLaneCreationForm;
