import { call, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import {
    calculateCostIncomingPriceOffer,
    calculateCostRequest,
    calculateCostRequestBegin,
    calculateCostRequestError,
    calculateCostRequestFetchRouteError,
    calculateCostRequestSetRoute,
    calculateCostRequestSuccess,
    createLaneRequest,
    createLaneRequestBegin,
    createLaneRequestError,
    createLaneRequestSuccess,
} from './slice';
import { CalculateCostRequestActionT, CreateLaneRequestActionT } from './types';
import { selectCalculateCostPriceOfferCounts, selectCalculateCostRequest } from './selectors';
import { CompatClient, Stomp } from '@stomp/stompjs';
import { EventChannel } from '@redux-saga/core';
import { logDebug, logWarning } from 'common/utils/logger';
import { eventChannel } from 'redux-saga';
import { selectCurrentUser } from 'common/store/user/selectors';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import history from 'common/utils/history';
import { urlFactory } from 'broker-admin/utils/urls';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { clientConfig } from 'common/utils/client-config';
import { CalculateCostRequestQueryT } from 'common/store/shipper-contract-lane-creation/models';

const BACKEND_PATH = `wss://${window.location.host}${clientConfig.soketBasepath}`;

type EventChannelEventT =
    | Error
    | {
          taskCount: number;
      };

let stompClient: CompatClient | null = null;

function createWaitCalculationChannel(params: { token: string | null; userId: string }): EventChannel<TODO> {
    const { token, userId } = params;

    if (stompClient) {
        logWarning('stompClient already exist. disconnect');
        stompClient.disconnect();
    }

    let taskCount = 0;
    return eventChannel((emitter) => {
        const webSocket = new WebSocket(BACKEND_PATH);

        stompClient = Stomp.over(webSocket);

        stompClient.connect(
            {
                login: token,
                passcode: 'passcode',
            },
            () => {
                if (!stompClient) {
                    return;
                }

                stompClient.subscribe(`/topic/prices/${userId}`, (message) => {
                    try {
                        /* start debug logs */
                        logDebug(`[${taskCount}] fetch offer: `, message.body);
                        /* end debug logs */

                        taskCount += 1;

                        const channelEvent: EventChannelEventT = {
                            taskCount,
                        };
                        emitter(channelEvent);
                    } catch (error) {
                        const apiError = new Error(error?.message);
                        const channelEvent: EventChannelEventT = apiError;
                        emitter(channelEvent);
                    }
                });
            },
            (error: TODO) => {
                const message = error?.headers?.message || '';
                if (message.include('Invalid token')) {
                    logWarning('Invalid token');
                    return;
                }

                const apiError = new Error(error?.message);
                const channelEvent: EventChannelEventT = apiError;
                emitter(channelEvent);
            },
            (event: TODO) => {
                // event.reason === "Cannot connect to server"
                if (event?.code === 1002) {
                    const apiError = new Error(event?.message);
                    const channelEvent: EventChannelEventT = apiError;
                    emitter(channelEvent);
                } else {
                    logWarning(`unknown socket event: ${JSON.stringify(event)}`);
                }
            },
        );

        return () => {
            // TODO exit
        };
    });
}

function* createWaitCalculationSaga({ query }: { query: CalculateCostRequestQueryT }): WrapGeneratorT<void> {
    const currentUser: ReturnType<typeof selectCurrentUser> = yield select(selectCurrentUser);
    const token: ReturnApiT<typeof commonTranziitApi.getAuthToken> = yield commonTranziitApi.getAuthToken();

    const userId = currentUser?.id || null;
    if (!userId) {
        logWarning(`empty userId`);
        return;
    }

    const waitCalculationChannel = yield call(createWaitCalculationChannel, {
        userId: currentUser?.id || '',
        token,
    });

    while (true) {
        yield take(waitCalculationChannel);
        yield put(calculateCostIncomingPriceOffer({ query }));
    }
}

function* calculateCostSaga(action: CalculateCostRequestActionT): WrapGeneratorT<void> {
    const { partnerId, query } = action.payload;

    const requestStatus: ReReturnT<typeof selectCalculateCostRequest> = yield select(selectCalculateCostRequest);
    if (requestStatus.loading) {
        return;
    }

    yield put(calculateCostRequestBegin({ query }));

    yield fork(createWaitCalculationSaga, { query });

    const { skipRules, skipCommission, ...createShipperContractLaneRFQQuery } = query;

    const [error, data]: ReturnApiT<typeof brokerTranziitApi.createShipperContractLaneRFQ> =
        yield brokerTranziitApi.createShipperContractLaneRFQ(partnerId, createShipperContractLaneRFQQuery);
    if (error) {
        yield put(calculateCostRequestError({ query, error }));
        return;
    }

    const polylineId = data?.polylineId || null;
    if (!polylineId) {
        yield put(calculateCostRequestError({ query, error: new Error('empty polylineId!') }));
        return;
    }

    const [error2, data2]: ReturnApiT<typeof commonTranziitApi.fetchRouteGeometryGoogle> =
        yield commonTranziitApi.fetchRouteGeometryGoogle(polylineId);

    if (data2) {
        const polylines = data2 || [];

        yield put(calculateCostRequestSetRoute({ query, polylines }));
    }

    if (error2) {
        yield put(calculateCostRequestFetchRouteError({ query, error: error2 }));
    }

    const expectedOffersCount = data?.expectedOffersCount || null;
    if (!expectedOffersCount) {
        yield put(calculateCostRequestError({ query, error: new Error('empty expectedOffersCount!') }));
        return;
    }

    // @ts-ignore TS7057: 'yield' expression implicitly results in an 'any' type because its containing generator lacks a return-type annotation.
    try {
        let isFetchedAllOffers = false;
        while (!isFetchedAllOffers) {
            const calculateCostPriceOfferCounts: ReturnType<typeof selectCalculateCostPriceOfferCounts> = yield select(
                selectCalculateCostPriceOfferCounts,
            );

            isFetchedAllOffers = calculateCostPriceOfferCounts >= expectedOffersCount;
            if (!isFetchedAllOffers) {
                yield delay(500);
            }
        }
    } catch (error) {
        let apiError = null;
        if (error instanceof Error) {
            apiError = error;
        } else {
            apiError = new Error(error?.message);
        }

        yield put(calculateCostRequestError({ query, error: apiError }));
        return;
    }

    // yield delay(5000);

    const rfqId = data?.rfqId || null;
    if (!rfqId) {
        yield put(calculateCostRequestError({ query, error: new Error('empty rfqId!') }));
        return;
    }

    const [error3, data3]: ReturnApiT<typeof brokerTranziitApi.fetchShipperContractLaneRFQOffersAverage> =
        yield brokerTranziitApi.fetchShipperContractLaneRFQOffersAverage(rfqId, {
            skipRules,
            skipCommission,
        });
    if (error3) {
        yield put(calculateCostRequestError({ query, error: error3 }));
        return;
    }

    if (!data3) {
        yield put(calculateCostRequestError({ query, error: new Error('empty data3!') }));
        return;
    }

    yield put(calculateCostRequestSuccess({ query, averagePriceOffer: data3 }));
}

function* createLaneSaga(action: CreateLaneRequestActionT): WrapGeneratorT<void> {
    const { partnerId, contractId, query } = action.payload;

    const requestStatus: ReReturnT<typeof selectCalculateCostRequest> = yield select(selectCalculateCostRequest);
    if (requestStatus.loading) {
        return;
    }

    yield put(createLaneRequestBegin({ query }));

    const [error]: ReturnApiT<typeof brokerTranziitApi.createShipperContractLane> =
        yield brokerTranziitApi.createShipperContractLane(partnerId, contractId, query);
    if (error) {
        yield put(createLaneRequestError({ query, error }));
    }

    yield put(createLaneRequestSuccess({ query }));

    yield put(
        addAlert(
            new CommonAnyAlert({
                type: CommonAlertTypeEnum.shipperContractLaneAdded,
                data: {},
            }),
        ),
    );

    history.push(
        urlFactory.partnerShipperContactLanes({
            partnerId,
            partnerType: PartnerTypeEnum.shipper,
            contractId,
        }),
    );
}

function* shipperContractLaneCreationSaga(): WrapGeneratorT<void> {
    yield takeEvery(calculateCostRequest.type, calculateCostSaga);
    yield takeEvery(createLaneRequest.type, createLaneSaga);
}

export { shipperContractLaneCreationSaga };
