import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './Loader.scss';

type PropsT = {
    size?: number;
    className?: string;
};

const cx = classNames.bind(styles);

const Loader: React.FC<PropsT> = React.memo((props) => {
    const { size = 20, className } = props;

    return (
        <div
            className={cs(cx('loader'), className)}
            style={{
                width: `${size}px`,
                height: `${size}px`,
            }}
        />
    );
});

export default Loader;
