import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ShipperContractLaneDetailsPage.scss';
import BaseShipperContractLaneDetailsLayout from 'common/layouts/BaseShipperContractLaneDetailsLayout/BaseShipperContractLaneDetailsLayout';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { useParams } from 'react-router-dom';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { useOpenLeftSidebar } from 'common/layouts/SideBars/hooks';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectShipperContractLaneDetailsState } from 'common/store/shipper-contract-lane-details/selectors';
import { selectPermissions } from 'common/store/auth/selectors';

const cx = classNames.bind(styles);

type PropsT = {
    onClose: () => void;
};

const ShipperContractLaneDetailsPage: React.FC<PropsT> = React.memo((props) => {
    const { onClose } = props;

    const permissions = useSelector(selectPermissions);

    const { t } = useTranslation();

    const params = useParams<{ contractId: string; laneId: string }>();
    const { laneId, contractId } = params;

    const { partnerId } = usePartnerContext();

    const openLeftSidebar = useOpenLeftSidebar();

    const handleOpenUserDetails = (userId: UserIdT) => {
        openLeftSidebar(
            {
                type: CommonSidebarsTypeEnum.contact,
                partnerId,
                partnerType: PartnerTypeEnum.broker,
                userId,
            },
            {
                isForceShowBackAction: true,
            },
        );
    };

    const { data } = useSelector(selectShipperContractLaneDetailsState(laneId));

    return (
        <>
            <PageTitle
                title={t('page-titles.shipper-lane-details', {
                    laneName: data?.tztLaneId || '',
                })}
            />
            <BaseShipperContractLaneDetailsLayout
                laneId={laneId}
                contractId={contractId}
                partnerId={partnerId}
                onClose={onClose}
                onOpenUserDetails={handleOpenUserDetails}
                isReadOnly={!permissions.canEditShipperContracts}
            />
        </>
    );
});

export default ShipperContractLaneDetailsPage;
