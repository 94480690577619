import { put, select, takeEvery } from 'redux-saga/effects';
import { FIND_CARRIERS_REQUEST, FindCarriersActionT } from './types';
import { findCarriersBegin, findCarriersError, findCarriersSuccess } from './actions';
import { selectFindCarriersQuery } from './selectors';
import isEqual from 'lodash/isEqual';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';

function* findCarriersSaga(action: FindCarriersActionT): WrapGeneratorT<void> {
    const { query: rawQuery } = action;

    const query = rawQuery.trim();
    if (!query) {
        return;
    }

    const prevQuery: ReturnType<typeof selectFindCarriersQuery> = yield select(selectFindCarriersQuery);
    const isSameQuery = isEqual(query, prevQuery);
    if (isSameQuery) {
        return;
    }

    yield put(findCarriersBegin(query));

    const [error, partners]: ReturnApiT<typeof brokerTranziitApi.findCarriersByName> =
        yield brokerTranziitApi.findCarriersByName(query);

    if (error) {
        yield put(findCarriersError(error));
        return;
    }

    yield put(findCarriersSuccess(partners?.content || []));
}

function* carrierSuggestSaga(): WrapGeneratorT<void> {
    yield takeEvery(FIND_CARRIERS_REQUEST, findCarriersSaga);
}

export default carrierSuggestSaga;
