import * as React from 'react';

import { CurrencyEnum } from 'common/constants';
import Money from 'common/components/Money/Money';
import isNumber from 'lodash/isNumber';

type PropsT = {
    amount: number | null | undefined;
    currency: CurrencyEnum;
    isRound?: boolean;
    className?: string;
};

const MoneySigned: React.FC<PropsT> = React.memo((props) => {
    const { amount, currency, isRound, className } = props;

    if (!isNumber(amount)) {
        return null;
    }

    return (
        <>
            {amount > 0 ? '+ ' : '- '}
            <Money amount={Math.abs(amount)} currency={currency} isRound={isRound} className={className} />
        </>
    );
});

export default MoneySigned;
