import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './ChangeRatesForm.scss';
import { ChangeCostT } from 'broker-admin/store/price-settings/rates/models';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import Input from 'common/components/Input/Input';
import { getMoneyDiffMask, getMoneyMask, getPercentDiffMask } from 'common/utils/input-masks';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import { UpdateRateStrategyEnum } from 'common/utils/api/models';
import { CURRENCY_SYMBOLS } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    onSubmit: (changeCost: ChangeCostT) => void;
};

type OptionT = {
    label: string;
    value: UpdateRateStrategyEnum;
};

const getMaskByType = (type: UpdateRateStrategyEnum) => {
    switch (type) {
        case UpdateRateStrategyEnum.percent:
            return getPercentDiffMask();
        case UpdateRateStrategyEnum.diff:
            return getMoneyDiffMask();
        case UpdateRateStrategyEnum.exactValue:
            return getMoneyMask();
        default:
            return null;
    }
};

const UNIT_BY_TYPE = {
    [UpdateRateStrategyEnum.percent]: '%',
    [UpdateRateStrategyEnum.diff]: '+/-',
    [UpdateRateStrategyEnum.exactValue]: CURRENCY_SYMBOLS.EUR,
};

const ENTER_KEY = 'Enter';

const DEFAULT_DIFF_VALUE = '+';
const DEFAULT_VALUE = '';

const ChangeRatesForm: React.FC<PropsT> = (props) => {
    const { className, onSubmit } = props;

    const { t } = useTranslation();

    const [inputValue, setInputValue] = React.useState<string>(DEFAULT_DIFF_VALUE);
    const [dropdownValue, setDropdownValue] = React.useState<UpdateRateStrategyEnum>(UpdateRateStrategyEnum.percent);

    const options: OptionT[] = [
        {
            label: t('price-settings.rate-matrix.change-cost.type.percent'),
            value: UpdateRateStrategyEnum.percent,
        },
        {
            label: t('price-settings.rate-matrix.change-cost.type.diff'),
            value: UpdateRateStrategyEnum.diff,
        },
        {
            label: t('price-settings.rate-matrix.change-cost.type.exact'),
            value: UpdateRateStrategyEnum.exactValue,
        },
    ];

    const renderOption = (option: OptionT | undefined | null) => <span>{option?.label}</span>;

    const getOptionValue = (option: OptionT) => option.value;

    const handleSubmit = () => {
        const parsedValue = parseSimpleNumber(inputValue) || 0;

        const isValid = parsedValue && !Number.isNaN(parsedValue);
        if (!isValid) {
            return;
        }

        const changeCost: ChangeCostT = {
            value: parsedValue,
            strategy: dropdownValue,
        };

        onSubmit(changeCost);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ENTER_KEY) {
            handleSubmit();
        }
    };

    const handleSelect = (value: UpdateRateStrategyEnum) => {
        setDropdownValue(value);

        if (value === UpdateRateStrategyEnum.exactValue) {
            setInputValue(DEFAULT_VALUE);
        }

        if (value === UpdateRateStrategyEnum.diff) {
            setInputValue(DEFAULT_DIFF_VALUE);
        }

        if (value === UpdateRateStrategyEnum.percent) {
            setInputValue(DEFAULT_DIFF_VALUE);
        }
    };

    return (
        <div className={cs(cx('wrap'), className)}>
            <Input
                name="cost"
                className={cx('field', 'field--value')}
                value={inputValue}
                mask={getMaskByType(dropdownValue)}
                rightNode={UNIT_BY_TYPE[dropdownValue]}
                onChange={setInputValue}
                onKeyUp={handleKeyUp}
                guide={false}
            />
            <DropdownInput<OptionT, UpdateRateStrategyEnum>
                className={cx('field', 'field--type')}
                selectedValue={dropdownValue}
                options={options}
                onSelect={handleSelect}
                renderOption={renderOption}
                getOptionValue={getOptionValue}
                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
            />
            <Button theme={ButtonThemeEnum.primary} onClick={handleSubmit}>
                {t('price-settings.rate-matrix.change-cost.apply')}
            </Button>
        </div>
    );
};

export default ChangeRatesForm;
