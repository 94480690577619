import { getDistanceMask, getMoneyMask } from 'common/utils/input-masks';
import { UnitTypeEnum } from 'common/constants';

export const getMaskByType = (unitType: UnitTypeEnum | null) => {
    switch (unitType) {
        case UnitTypeEnum.euroAbbreviation:
            return getMoneyMask();
        case UnitTypeEnum.kilometersAbbreviation:
            return getDistanceMask();
        default:
            return null;
    }
};
