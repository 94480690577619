import * as React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import { useTranslation } from 'react-i18next';
import { StyleGuideColorsEnum } from 'common/constants';
import RouteIcon from 'common/icons/RouteIcon';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { isNonNil } from 'common/utils';

export type RouteSwitchValueT = RouteTypeEnum | null;

export enum RouteTypeEnum {
    shipperRoute = 'shipper-route',
    estimateCarrierRoute = 'estimate-carrier-route',
    carrierRoute = 'carrier-route',
}

type RouteSwitchDropdownOptionT = {
    label: React.ReactNode;
    value: RouteSwitchValueT;
};

type PropsT = {
    className?: string;
    value: RouteSwitchValueT;
    onChange: (value: RouteSwitchValueT) => void;

    isDisabledEstimateCarrierRoute: boolean;
    isDisabledShipperRoute: boolean;
    isDisabledCarrierRoute: boolean;
};

const RouteSwitchDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        value,
        onChange,
        isDisabledEstimateCarrierRoute,
        isDisabledCarrierRoute,
        isDisabledShipperRoute,
    } = props;

    const { t } = useTranslation();

    const routeTypeOptions: Array<RouteSwitchDropdownOptionT> = React.useMemo(
        () =>
            [
                isDisabledShipperRoute
                    ? null
                    : {
                          label: (
                              <DropdownControlOptionLabel
                                  withoutPaddings
                                  label={t('assignment.route-type-switcher.shipper-route')}
                              />
                          ),
                          value: RouteTypeEnum.shipperRoute,
                      },
                isDisabledEstimateCarrierRoute
                    ? null
                    : {
                          label: (
                              <DropdownControlOptionLabel
                                  withoutPaddings
                                  label={t('assignment.route-type-switcher.estimate-carrier-route')}
                              />
                          ),
                          value: RouteTypeEnum.estimateCarrierRoute,
                      },
                isDisabledCarrierRoute
                    ? null
                    : {
                          label: (
                              <DropdownControlOptionLabel
                                  withoutPaddings
                                  label={t('assignment.route-type-switcher.carrier-route')}
                              />
                          ),
                          value: RouteTypeEnum.carrierRoute,
                      },
            ].filter(isNonNil),
        [t, value, isDisabledEstimateCarrierRoute, isDisabledCarrierRoute, isDisabledShipperRoute],
    );

    return (
        <DropdownInput<RouteSwitchDropdownOptionT, RouteSwitchValueT>
            className={className}
            selectedValue={value}
            options={routeTypeOptions}
            onSelect={onChange}
            renderOption={(option) => {
                if (!option) {
                    return null;
                }

                return <DropdownControlOptionLabel withoutPaddings label={option.label} />;
            }}
            renderTriggerContent={(option) => {
                if (!option) {
                    return null;
                }

                return (
                    <DropdownControlOptionLabel
                        withoutPaddings
                        icon={<RouteIcon fillColor={StyleGuideColorsEnum.slate} />}
                        label={option.label}
                    />
                );
            }}
            getOptionValue={(option) => option?.value}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
        />
    );
});

export default RouteSwitchDropdown;
