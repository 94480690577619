import { RequestStatusT } from 'common/utils/request-status';

import { PartnersStatsT } from './models';

export const FETCH_PARTNERS_STATS_REQUEST = 'broker-admin/partners-stats/FETCH_PARTNERS_STATS_REQUEST';
export const FETCH_PARTNERS_STATS_REQUEST_BEGIN = 'broker-admin/partners-stats/FETCH_PARTNERS_STATS_REQUEST_BEGIN';
export const FETCH_PARTNERS_STATS_REQUEST_ERROR = 'broker-admin/partners-stats/FETCH_PARTNERS_STATS_REQUEST_ERROR';
export const FETCH_PARTNERS_STATS_REQUEST_SUCCESS = 'broker-admin/partners-stats/FETCH_PARTNERS_STATS_REQUEST_SUCCESS';

export type PartnersStatsStateT = {
    stats: PartnersStatsT | null;
    fetchRequest: RequestStatusT;
};

export type FetchPartnersStatsActionT = {
    type: typeof FETCH_PARTNERS_STATS_REQUEST;
    options?: FetchOptionsT;
};

export type FetchPartnersStatsBeginActionT = {
    type: typeof FETCH_PARTNERS_STATS_REQUEST_BEGIN;
};

export type FetchPartnersStatsSuccessActionT = {
    type: typeof FETCH_PARTNERS_STATS_REQUEST_SUCCESS;
    stats: PartnersStatsT;
};

export type FetchPartnersStatsErrorActionT = {
    type: typeof FETCH_PARTNERS_STATS_REQUEST_ERROR;
    error: Error;
};

export type PartnersStatsActionT =
    | FetchPartnersStatsActionT
    | FetchPartnersStatsBeginActionT
    | FetchPartnersStatsSuccessActionT
    | FetchPartnersStatsErrorActionT;
