import {
    FETCH_SPOT_REQUESTS_STATS_REQUEST,
    FETCH_SPOT_REQUESTS_STATS_REQUEST_BEGIN,
    FETCH_SPOT_REQUESTS_STATS_REQUEST_ERROR,
    FETCH_SPOT_REQUESTS_STATS_REQUEST_SUCCESS,
    FetchSpotRequestsStatsActionT,
    FetchSpotRequestsStatsBeginActionT,
    FetchSpotRequestsStatsErrorActionT,
    FetchSpotRequestsStatsSuccessActionT,
} from './types';
import { ApiSpotRequestsStatsT } from 'broker-admin/utils/api/spot-broker-tranziit/models';

export const fetchSpotRequestsStats = (options?: FetchOptionsT): FetchSpotRequestsStatsActionT => ({
    type: FETCH_SPOT_REQUESTS_STATS_REQUEST,
    options,
});

export const fetchSpotRequestsBegin = (): FetchSpotRequestsStatsBeginActionT => ({
    type: FETCH_SPOT_REQUESTS_STATS_REQUEST_BEGIN,
});

export const fetchSpotRequestsStatsSuccess = (
    statistics: ApiSpotRequestsStatsT | null,
): FetchSpotRequestsStatsSuccessActionT => ({
    type: FETCH_SPOT_REQUESTS_STATS_REQUEST_SUCCESS,
    statistics,
});

export const fetchSpotRequestsStatsError = (error: Error): FetchSpotRequestsStatsErrorActionT => ({
    type: FETCH_SPOT_REQUESTS_STATS_REQUEST_ERROR,
    error,
});
