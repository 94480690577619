import { put, select, takeEvery } from 'redux-saga/effects';
import {
    ACTIVATE_RULES_REQUEST,
    ActivationRulesActionT,
    CREATE_RULE_REQUEST,
    CreateRuleActionT,
    DEACTIVATE_RULES_REQUEST,
    DeactivationRulesActionT,
    DELETE_RULES_REQUEST,
    DeleteRulesActionT,
    FETCH_RULE_DETAILS_REQUEST,
    FETCH_RULES_PAGE_REQUEST,
    FetchRuleDetailsActionT,
    FetchRulesPageActionT,
    UPDATE_RULE_REQUEST,
    UpdateRuleActionT,
} from './types';
import {
    createRuleBegin,
    createRuleError,
    createRuleSuccess,
    deleteRulesBegin,
    deleteRulesError,
    deleteRulesSuccess,
    fetchRuleDetails,
    fetchRuleDetailsBegin,
    fetchRuleDetailsError,
    fetchRuleDetailsSuccess,
    fetchRulesPageBegin,
    fetchRulesPageError,
    fetchRulesPageSuccess,
    resetRulePages,
    updateRuleBegin,
    updateRuleError,
    updateRuleSuccess,
} from './actions';

import isEqual from 'lodash/isEqual';
import { selectRulesPages, selectRulesQuery, selectUpdateRulesRequest } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import { rulesPaginationChannel, rulesRefreshChannel } from 'broker-admin/store/price-settings/rules/channels';

function* fetchRulesSaga(action: FetchRulesPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options } = action;

    const prevQuery: ReturnType<typeof selectRulesQuery> = yield select(selectRulesQuery);
    const pages: ReturnType<typeof selectRulesPages> = yield select(selectRulesPages);
    const isSameQuery = isEqual(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus);
    if (isSameQuery && !isNeedRequest && !options?.isForceUpdate) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetRulePages({ savingPageNumber: pageNumber }));
    }

    yield put(fetchRulesPageBegin(query, pageNumber));
    const [error, response]: ReturnApiT<typeof brokerTranziitApi.fetchRulesPage> =
        yield brokerTranziitApi.fetchRulesPage({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchRulesPageError(query, pageNumber, error));
        return;
    }

    yield put(fetchRulesPageSuccess(query, pageNumber, response));

    checkShouldEmitChangePage(pageNumber, response, rulesPaginationChannel);
}

function* updateRuleSaga(action: UpdateRuleActionT): WrapGeneratorT<void> {
    const request: ReturnType<typeof selectUpdateRulesRequest> = yield select(selectUpdateRulesRequest);
    if (request.loading) {
        return;
    }

    yield put(updateRuleBegin());

    const { ruleId, ruleDraft } = action;

    const [error]: ReturnApiT<typeof brokerTranziitApi.updateRule> = yield brokerTranziitApi.updateRule(
        +ruleId,
        ruleDraft,
    );
    if (error) {
        yield put(updateRuleError(error));
        return;
    }

    yield put(updateRuleSuccess());

    yield put(fetchRuleDetails(ruleId));

    rulesRefreshChannel.emit({});
}

function* deactivateRulesSaga(action: DeactivationRulesActionT): WrapGeneratorT<void> {
    const request: ReturnType<typeof selectUpdateRulesRequest> = yield select(selectUpdateRulesRequest);
    if (request.loading) {
        return;
    }

    yield put(updateRuleBegin());

    const { ids } = action;

    let error: ReturnApiT<typeof brokerTranziitApi.deactivateRule>[0] | null = null;

    for (const ruleId of ids) {
        const result: ReturnApiT<typeof brokerTranziitApi.deactivateRule> = yield brokerTranziitApi.deactivateRule(
            +ruleId,
        );
        [error] = result;
    }

    if (error) {
        yield put(updateRuleError(error));
        return;
    }

    yield put(updateRuleSuccess());

    for (const ruleId of ids) {
        yield put(fetchRuleDetails(ruleId));
    }

    rulesRefreshChannel.emit({});
}

function* activateRulesSaga(action: ActivationRulesActionT): WrapGeneratorT<void> {
    const request: ReturnType<typeof selectUpdateRulesRequest> = yield select(selectUpdateRulesRequest);
    if (request.loading) {
        return;
    }

    yield put(updateRuleBegin());

    const { ids } = action;

    let error: ReturnApiT<typeof brokerTranziitApi.activateRule>[0] | null = null;

    for (const ruleId of ids) {
        const result: ReturnApiT<typeof brokerTranziitApi.activateRule> = yield brokerTranziitApi.activateRule(+ruleId);
        [error] = result;
    }

    if (error) {
        yield put(updateRuleError(error));
        return;
    }

    yield put(updateRuleSuccess());

    for (const ruleId of ids) {
        yield put(fetchRuleDetails(ruleId));
    }

    rulesRefreshChannel.emit({});
}

function* deleteRulesSaga(action: DeleteRulesActionT): WrapGeneratorT<void> {
    const request: ReturnType<typeof selectUpdateRulesRequest> = yield select(selectUpdateRulesRequest);
    if (request.loading) {
        return;
    }

    yield put(deleteRulesBegin());

    const { ids } = action;

    let error: ReturnApiT<typeof brokerTranziitApi.deleteRule>[0] | null = null;

    for (const ruleId of ids) {
        const result: ReturnApiT<typeof brokerTranziitApi.deleteRule> = yield brokerTranziitApi.deleteRule(+ruleId);
        [error] = result;
    }

    if (error) {
        yield put(deleteRulesError(error));
        return;
    }

    yield put(deleteRulesSuccess());

    rulesRefreshChannel.emit({});
}

function* createRuleSaga(action: CreateRuleActionT): WrapGeneratorT<void> {
    const request: ReturnType<typeof selectUpdateRulesRequest> = yield select(selectUpdateRulesRequest);
    if (request.loading) {
        return;
    }

    yield put(createRuleBegin());

    const { ruleDraft } = action;

    const [error]: ReturnApiT<typeof brokerTranziitApi.createRule> = yield brokerTranziitApi.createRule(ruleDraft);
    if (error) {
        yield put(createRuleError(error));
        return;
    }

    yield put(createRuleSuccess());

    rulesRefreshChannel.emit({});
}

function* fetchRuleDetailsSaga(action: FetchRuleDetailsActionT): WrapGeneratorT<void> {
    const { ruleId } = action;
    yield put(fetchRuleDetailsBegin(ruleId));

    const [error, ruleDetails]: ReturnApiT<typeof brokerTranziitApi.fetchRuleDetails> =
        yield brokerTranziitApi.fetchRuleDetails(+ruleId);
    if (error) {
        yield put(fetchRuleDetailsError(ruleId, error));
        return;
    }

    yield put(fetchRuleDetailsSuccess(ruleId, ruleDetails));
}

function* ratesSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_RULES_PAGE_REQUEST, fetchRulesSaga);
    yield takeEvery(UPDATE_RULE_REQUEST, updateRuleSaga);
    yield takeEvery(DEACTIVATE_RULES_REQUEST, deactivateRulesSaga);
    yield takeEvery(ACTIVATE_RULES_REQUEST, activateRulesSaga);
    yield takeEvery(DELETE_RULES_REQUEST, deleteRulesSaga);
    yield takeEvery(CREATE_RULE_REQUEST, createRuleSaga);
    yield takeEvery(FETCH_RULE_DETAILS_REQUEST, fetchRuleDetailsSaga);
}

export default ratesSaga;
