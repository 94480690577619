import { put, select, takeEvery } from 'redux-saga/effects';
import { fetchCarrierUtilizationBegin, fetchCarrierUtilizationError, fetchCarrierUtilizationSuccess } from './actions';
import { FETCH_UTILIZATION_REQUEST, FetchCarrierUtilizationActionT } from './types';
import { selectCarriersUtilizationByHash, selectFetchRequestStatus } from './selectors';
import { getHash } from './utils';
import isEmpty from 'lodash/isEmpty';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';

function* fetchCarriersUtilizationSaga(action: FetchCarrierUtilizationActionT): WrapGeneratorT<void> {
    const { ids, date } = action;

    const requestStatus: ReturnType<typeof selectFetchRequestStatus> = yield select(selectFetchRequestStatus);
    if (requestStatus.loading) {
        return;
    }

    const carriersUtilizationByHash: ReturnType<typeof selectCarriersUtilizationByHash> = yield select(
        selectCarriersUtilizationByHash,
    );
    const needIds = ids.filter((id) => {
        const hash = getHash(id, date);

        return !carriersUtilizationByHash[hash];
    });

    if (isEmpty(needIds)) {
        return;
    }

    yield put(fetchCarrierUtilizationBegin());

    const [error, utilizations]: ReturnApiT<typeof commonTranziitApi.fetchCarriersUtilization> =
        yield commonTranziitApi.fetchCarriersUtilization(needIds, date);

    if (error) {
        yield put(fetchCarrierUtilizationError(error));
        return;
    }

    yield put(fetchCarrierUtilizationSuccess(needIds, date, utilizations || []));
}

function* carrierUtilizationSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_UTILIZATION_REQUEST, fetchCarriersUtilizationSaga);
}

export default carrierUtilizationSaga;
