import {
    FETCH_DONE_STATISTICS_REQUEST_BEGIN,
    FETCH_DONE_STATISTICS_REQUEST_ERROR,
    FETCH_DONE_STATISTICS_REQUEST_SUCCESS,
    FETCH_REJECT_STATISTICS_REQUEST_BEGIN,
    FETCH_REJECT_STATISTICS_REQUEST_ERROR,
    FETCH_REJECT_STATISTICS_REQUEST_SUCCESS,
    FETCH_STATISTICS_REQUEST_BEGIN,
    StatisticsActionT,
    StatisticsStateT,
} from './types';
import { checkIsSameQuery } from 'common/utils/pagination/utils';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: StatisticsStateT = {
    fetchQuery: null,
    rejectData: null,
    rejectRequestStatus: requestStatus.initial,
    doneData: null,
    doneRequestStatus: requestStatus.initial,
};

export default (state = initialState, action: StatisticsActionT | DestroySessionActionT): StatisticsStateT => {
    switch (action.type) {
        case FETCH_STATISTICS_REQUEST_BEGIN: {
            const { query } = action;

            return {
                ...state,
                fetchQuery: query,
            };
        }

        case FETCH_DONE_STATISTICS_REQUEST_BEGIN: {
            const { query } = action;

            const isSameQuery = checkIsSameQuery(query, state.fetchQuery);
            if (!isSameQuery) {
                return state;
            }

            return {
                ...state,
                doneRequestStatus: requestStatus.loading,
            };
        }

        case FETCH_DONE_STATISTICS_REQUEST_ERROR: {
            const { error, query } = action;

            const isSameQuery = checkIsSameQuery(query, state.fetchQuery);
            if (!isSameQuery) {
                return state;
            }

            return {
                ...state,
                doneData: null,
                doneRequestStatus: requestStatus.setError(error),
            };
        }

        case FETCH_DONE_STATISTICS_REQUEST_SUCCESS: {
            const { statistic, query } = action;

            const isSameQuery = checkIsSameQuery(query, state.fetchQuery);
            if (!isSameQuery) {
                return state;
            }

            return {
                ...state,
                doneData: statistic,
                doneRequestStatus: requestStatus.ok,
            };
        }

        case FETCH_REJECT_STATISTICS_REQUEST_BEGIN: {
            const { query } = action;

            const isSameQuery = checkIsSameQuery(query, state.fetchQuery);
            if (!isSameQuery) {
                return state;
            }

            return {
                ...state,
                rejectRequestStatus: requestStatus.loading,
            };
        }

        case FETCH_REJECT_STATISTICS_REQUEST_ERROR: {
            const { error, query } = action;

            const isSameQuery = checkIsSameQuery(query, state.fetchQuery);
            if (!isSameQuery) {
                return state;
            }

            return {
                ...state,
                rejectData: null,
                rejectRequestStatus: requestStatus.setError(error),
            };
        }

        case FETCH_REJECT_STATISTICS_REQUEST_SUCCESS: {
            const { statistic, query } = action;

            const isSameQuery = checkIsSameQuery(query, state.fetchQuery);
            if (!isSameQuery) {
                return state;
            }

            return {
                ...state,
                rejectData: statistic,
                rejectRequestStatus: requestStatus.ok,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
