import {
    FETCH_SYSTEM_SETTINGS_REQUEST,
    FETCH_SYSTEM_SETTINGS_REQUEST_BEGIN,
    FETCH_SYSTEM_SETTINGS_REQUEST_ERROR,
    FETCH_SYSTEM_SETTINGS_REQUEST_SUCCESS,
    FetchSystemSettingsActionT,
    FetchSystemSettingsBeginActionT,
    FetchSystemSettingsErrorActionT,
    FetchSystemSettingsSuccessActionT,
    UPDATE_SYSTEM_SETTINGS_REQUEST,
    UPDATE_SYSTEM_SETTINGS_REQUEST_BEGIN,
    UPDATE_SYSTEM_SETTINGS_REQUEST_ERROR,
    UPDATE_SYSTEM_SETTINGS_REQUEST_SUCCESS,
    UpdateSystemSettingsActionT,
    UpdateSystemSettingsBeginActionT,
    UpdateSystemSettingsErrorActionT,
    UpdateSystemSettingsSuccessActionT,
} from './types';
import { SystemSettingsChangesT, SystemSettingsT } from './models';

export const fetchSystemSettings = (options?: FetchOptionsT): FetchSystemSettingsActionT => ({
    type: FETCH_SYSTEM_SETTINGS_REQUEST,
    options,
});

export const fetchSystemSettingsBegin = (): FetchSystemSettingsBeginActionT => ({
    type: FETCH_SYSTEM_SETTINGS_REQUEST_BEGIN,
});

export const fetchSystemSettingsSuccess = (data: SystemSettingsT | null): FetchSystemSettingsSuccessActionT => ({
    type: FETCH_SYSTEM_SETTINGS_REQUEST_SUCCESS,
    data,
});

export const fetchSystemSettingsError = (error: Error): FetchSystemSettingsErrorActionT => ({
    type: FETCH_SYSTEM_SETTINGS_REQUEST_ERROR,
    error,
});

export const updateSystemSettings = (settingsChanges: SystemSettingsChangesT): UpdateSystemSettingsActionT => ({
    type: UPDATE_SYSTEM_SETTINGS_REQUEST,
    settingsChanges,
});

export const updateSystemSettingsBegin = (): UpdateSystemSettingsBeginActionT => ({
    type: UPDATE_SYSTEM_SETTINGS_REQUEST_BEGIN,
});

export const updateSystemSettingsSuccess = (): UpdateSystemSettingsSuccessActionT => ({
    type: UPDATE_SYSTEM_SETTINGS_REQUEST_SUCCESS,
});

export const updateSystemSettingsError = (error: Error): UpdateSystemSettingsErrorActionT => ({
    type: UPDATE_SYSTEM_SETTINGS_REQUEST_ERROR,
    error,
});
