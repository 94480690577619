import moment from 'moment';

import { QueryFiltersT } from 'common/models';
import { ALL_DAY_RANGE, EmissionStandartEnum, QueryFiltersKeysEnum } from 'common/constants';
import { FieldsEnum, FormValuesT } from './constants';

const utcOffset = moment().utcOffset();

const parseDateFromQuery = (queryDate: string | null | undefined) => {
    const momentDate = moment(queryDate).utcOffset(utcOffset);

    return new Date(momentDate.format('YYYY-MM-DD'));
};

const parseRelativeTimeFromQuery = (queryDate: string | null | undefined) => {
    const momentDate = moment(queryDate).utcOffset(utcOffset);

    return momentDate.valueOf() - momentDate.clone().startOf('day').valueOf();
};

const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.keyAccountManagerId]: queryFilters[QueryFiltersKeysEnum.keyAccountManagerId] || null,
        [FieldsEnum.keyAccountManagerName]: queryFilters[QueryFiltersKeysEnum.keyAccountManagerName] || null,
        [FieldsEnum.dispatcherId]: queryFilters[QueryFiltersKeysEnum.dispatcherId] || null,
        [FieldsEnum.dispatcherName]: queryFilters[QueryFiltersKeysEnum.dispatcherName] || null,

        [FieldsEnum.shipperId]: queryFilters[QueryFiltersKeysEnum.shipperId] || null,
        [FieldsEnum.shipperName]: queryFilters[QueryFiltersKeysEnum.shipperName] || null,
        [FieldsEnum.carrierId]: queryFilters[QueryFiltersKeysEnum.carrierId] || null,
        [FieldsEnum.carrierName]: queryFilters[QueryFiltersKeysEnum.carrierName] || null,

        [FieldsEnum.emissionClass]: null,
        [FieldsEnum.dictTrailerId]: queryFilters[QueryFiltersKeysEnum.trailerType] || null,

        [FieldsEnum.pickupAddress]: queryFilters[QueryFiltersKeysEnum.pickupAddress] || null,
        [FieldsEnum.dropoffAddress]: queryFilters[QueryFiltersKeysEnum.dropoffAddress] || null,

        [FieldsEnum.createDates]: null,

        [FieldsEnum.pickupDates]: null,
        [FieldsEnum.pickupTimeWindow]: ALL_DAY_RANGE,
        [FieldsEnum.dropoffDates]: null,
        [FieldsEnum.dropoffTimeWindow]: ALL_DAY_RANGE,
    };

    const emissionStandard = queryFilters[QueryFiltersKeysEnum.emissionStandard];
    if (emissionStandard) {
        values[FieldsEnum.emissionClass] = emissionStandard as EmissionStandartEnum;
    }

    if (queryFilters[QueryFiltersKeysEnum.pickupDateFrom]) {
        values[FieldsEnum.pickupDates] = {
            from: parseDateFromQuery(queryFilters[QueryFiltersKeysEnum.pickupDateFrom]),
            to: null,
        };

        const relativeTime = parseRelativeTimeFromQuery(queryFilters[QueryFiltersKeysEnum.pickupDateFrom]);
        values[FieldsEnum.pickupTimeWindow] = [relativeTime, 0];
    }

    if (queryFilters[QueryFiltersKeysEnum.pickupDateTo]) {
        const momentDate = moment(queryFilters[QueryFiltersKeysEnum.pickupDateTo]).utcOffset(utcOffset);

        const date = new Date(momentDate.format('YYYY-MM-DD'));
        values[FieldsEnum.pickupDates] = {
            from: values[FieldsEnum.pickupDates]?.from,
            to: date,
        };

        const relativeTime = parseRelativeTimeFromQuery(queryFilters[QueryFiltersKeysEnum.pickupDateTo]);
        values[FieldsEnum.pickupTimeWindow] = [values[FieldsEnum.pickupTimeWindow]?.[0] || 0, relativeTime];
    }

    if (queryFilters[QueryFiltersKeysEnum.dropOffDateFrom]) {
        values[FieldsEnum.dropoffDates] = {
            from: parseDateFromQuery(queryFilters[QueryFiltersKeysEnum.dropOffDateFrom]),
            to: null,
        };

        const relativeTime = parseRelativeTimeFromQuery(queryFilters[QueryFiltersKeysEnum.dropOffDateFrom]);
        values[FieldsEnum.dropoffTimeWindow] = [relativeTime, 0];
    }

    if (queryFilters[QueryFiltersKeysEnum.dropOffDateTo]) {
        values[FieldsEnum.dropoffDates] = {
            from: values[FieldsEnum.dropoffDates]?.from,
            to: parseDateFromQuery(queryFilters[QueryFiltersKeysEnum.dropOffDateTo]),
        };

        const relativeTime = parseRelativeTimeFromQuery(queryFilters[QueryFiltersKeysEnum.dropOffDateTo]);
        values[FieldsEnum.dropoffTimeWindow] = [values[FieldsEnum.dropoffTimeWindow]?.[0] || 0, relativeTime];
    }

    if (queryFilters[QueryFiltersKeysEnum.createdDateFrom]) {
        values[FieldsEnum.createDates] = {
            from: parseDateFromQuery(queryFilters[QueryFiltersKeysEnum.createdDateFrom]),
            to: null,
        };
    }

    if (queryFilters[QueryFiltersKeysEnum.createdDateTo]) {
        values[FieldsEnum.createDates] = {
            from: values[FieldsEnum.createDates]?.from,
            to: parseDateFromQuery(queryFilters[QueryFiltersKeysEnum.createdDateTo]),
        };
    }

    return values;
};

export default getInitialValues;
