import {
    SpotRequestsStatsActionT,
    SpotRequestsStatsStateT,
    FETCH_SPOT_REQUESTS_STATS_REQUEST_BEGIN,
    FETCH_SPOT_REQUESTS_STATS_REQUEST_ERROR,
    FETCH_SPOT_REQUESTS_STATS_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import { SpotRequestStatStatusEnum } from 'broker-admin/utils/api/spot-broker-tranziit/models';
import isNumber from 'lodash/isNumber';

const initialState: SpotRequestsStatsStateT = {
    stats: null,
    fetchRequest: requestStatus.initial,
};

export default (
    state = initialState,
    action: SpotRequestsStatsActionT | DestroySessionActionT,
): SpotRequestsStatsStateT => {
    switch (action.type) {
        case FETCH_SPOT_REQUESTS_STATS_REQUEST_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }

        case FETCH_SPOT_REQUESTS_STATS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                fetchRequest: requestStatus.setError(error),
            };
        }

        case FETCH_SPOT_REQUESTS_STATS_REQUEST_SUCCESS: {
            const { statistics } = action;

            const stats = (statistics || []).reduce(
                (acc, statistic) => {
                    const { status, count } = statistic;

                    if (status && isNumber(count)) {
                        acc[status as SpotRequestStatStatusEnum] = count;
                    }

                    return acc;
                },
                {
                    [SpotRequestStatStatusEnum.open]: 0,
                    [SpotRequestStatStatusEnum.accepted]: 0,
                    [SpotRequestStatStatusEnum.expireSoon]: 0,
                    [SpotRequestStatStatusEnum.newBids]: 0,
                    [SpotRequestStatStatusEnum.assigned]: 0,
                    [SpotRequestStatStatusEnum.expired]: 0,
                    [SpotRequestStatStatusEnum.canceled]: 0,
                    [SpotRequestStatStatusEnum.unknown]: 0,
                },
            );
            return {
                ...state,
                stats,
                fetchRequest: requestStatus.ok,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
