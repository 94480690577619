import {
    FETCH_RATES_PAGE_REQUEST_BEGIN,
    FETCH_RATES_PAGE_REQUEST_ERROR,
    FETCH_RATES_PAGE_REQUEST_SUCCESS,
    RatesActionT,
    RatesStateT,
    RESET_RATES,
    UPDATE_RATES_REQUEST_BEGIN,
    UPDATE_RATES_REQUEST_ERROR,
    UPDATE_RATES_REQUEST_SUCCESS,
    UPLOAD_CSV_FILE_REQUEST_BEGIN,
    UPLOAD_CSV_FILE_REQUEST_FAIL,
    UPLOAD_CSV_FILE_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import {
    pageBeginReducer,
    pageErrorReducer,
    pageSuccessReducer,
    resetPagesCacheReducer,
} from 'common/utils/pagination/reducers';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: RatesStateT = {
    pages: [],
    query: null,
    total: null,
    byId: {},
    updateRequest: requestStatus.initial,
    uploadCSVRequest: requestStatus.initial,
};

export default (state = initialState, action: RatesActionT | DestroySessionActionT): RatesStateT => {
    switch (action.type) {
        case FETCH_RATES_PAGE_REQUEST_BEGIN: {
            const { pageNumber, query } = action;

            const newPages = [...state.pages];
            newPages.splice(pageNumber, 1, {
                ...state.pages[pageNumber],
                requestStatus: requestStatus.loading,
            });

            return {
                ...state,
                ...pageBeginReducer(state, pageNumber, query),
            };
        }
        case FETCH_RATES_PAGE_REQUEST_SUCCESS: {
            const { query, pageNumber, pageResponse } = action;

            return {
                ...state,
                ...pageSuccessReducer(state, pageNumber, query, pageResponse),
            };
        }
        case FETCH_RATES_PAGE_REQUEST_ERROR: {
            const { query, error, pageNumber } = action;

            return {
                ...state,
                ...pageErrorReducer(state, pageNumber, query, error),
            };
        }
        case UPLOAD_CSV_FILE_REQUEST_BEGIN: {
            return {
                ...state,
                uploadCSVRequest: requestStatus.loading,
            };
        }
        case UPLOAD_CSV_FILE_REQUEST_SUCCESS: {
            return {
                ...state,
                uploadCSVRequest: requestStatus.ok,
            };
        }
        case UPLOAD_CSV_FILE_REQUEST_FAIL: {
            const { error } = action;

            return {
                ...state,
                uploadCSVRequest: requestStatus.setError(error),
            };
        }
        case UPDATE_RATES_REQUEST_BEGIN: {
            return {
                ...state,
                updateRequest: requestStatus.loading,
            };
        }
        case UPDATE_RATES_REQUEST_SUCCESS: {
            return {
                ...state,
                updateRequest: requestStatus.ok,
            };
        }
        case UPDATE_RATES_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                updateRequest: requestStatus.setError(error),
            };
        }
        case RESET_RATES: {
            const { mutationListOptions } = action;

            return {
                ...initialState,
                ...resetPagesCacheReducer(initialState, state, mutationListOptions),
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
