import {
    CarriersUtilizationActionT,
    CarriersUtilizationStateT,
    FETCH_UTILIZATION_REQUEST_BEGIN,
    FETCH_UTILIZATION_REQUEST_ERROR,
    FETCH_UTILIZATION_REQUEST_SUCCESS,
    RESET,
} from './types';
import requestStatus from 'common/utils/request-status';
import { getHash } from './utils';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: CarriersUtilizationStateT = {
    fetchRequest: requestStatus.initial,
    byHash: {},
};

export default (
    state = initialState,
    action: CarriersUtilizationActionT | DestroySessionActionT,
): CarriersUtilizationStateT => {
    switch (action.type) {
        case FETCH_UTILIZATION_REQUEST_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }

        case FETCH_UTILIZATION_REQUEST_SUCCESS: {
            const { ids, date, utilizations } = action;

            const byHash = utilizations.reduce<CarriersUtilizationStateT['byHash']>((acc, utilization, index) => {
                const id = ids[index];
                const hash = getHash(id, date);

                acc[hash] = utilization;

                return acc;
            }, {});

            return {
                ...state,
                byHash: {
                    ...state.byHash,
                    ...byHash,
                },
                fetchRequest: requestStatus.ok,
            };
        }

        case FETCH_UTILIZATION_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                fetchRequest: requestStatus.setError(error),
            };
        }

        case DESTROY_SESSION:
        case RESET: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
};
