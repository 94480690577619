import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import requestStatus, { RequestStatusT } from 'common/utils/request-status';
import { CalculateCostRequestQueryT, CreateRequestQueryT } from './models';
import { checkIsSameQuery } from 'common/utils/pagination/utils';
import { ApiPriceOfferT, MapSettingsT } from './types';

export type ShipperContractLaneCreationStateT = {
    mapSettings: MapSettingsT | null;

    origin: {
        location: LocationT | null;
        radiusKm: number | null;
    };
    destination: {
        location: LocationT | null;
        radiusKm: number | null;
    };

    polylines: Array<GooglePolylineT> | null;
    fetchRouteRequest: RequestStatusT;

    calculateCostRequestQuery: CalculateCostRequestQueryT | null;
    calculateCostRequest: RequestStatusT;
    priceOfferCounts: number;
    averagePriceOffer: ApiPriceOfferT | null;

    creationRequestQuery: CreateRequestQueryT | null;
    creationRequest: RequestStatusT;
};

const initialState: ShipperContractLaneCreationStateT = {
    mapSettings: null,

    origin: {
        location: null,
        radiusKm: null,
    },
    destination: {
        location: null,
        radiusKm: null,
    },

    polylines: null,
    fetchRouteRequest: requestStatus.initial,

    calculateCostRequestQuery: null,
    calculateCostRequest: requestStatus.initial,
    priceOfferCounts: 0,
    averagePriceOffer: null,

    creationRequestQuery: null,
    creationRequest: requestStatus.initial,
};

export const shipperContractLaneCreationSlice = createSlice({
    name: 'shipperContractLaneCreation',
    initialState,
    reducers: {
        setMapSettings: (
            state,
            action: PayloadAction<{
                settings: MapSettingsT | null;
            }>,
        ) => {
            state.mapSettings = action.payload.settings;
        },
        setOriginLocation: (
            state,
            action: PayloadAction<{
                location: LocationT | null;
            }>,
        ) => {
            state.origin.location = action.payload.location;
            state.polylines = null;
        },
        setOriginLocationRadius: (
            state,
            action: PayloadAction<{
                radiusKm: number | null;
            }>,
        ) => {
            state.origin.radiusKm = action.payload.radiusKm;
        },
        setDestinationLocation: (
            state,
            action: PayloadAction<{
                location: LocationT | null;
            }>,
        ) => {
            state.destination.location = action.payload.location;
            state.polylines = null;
        },
        setDestinationLocationRadius: (
            state,
            action: PayloadAction<{
                radiusKm: number | null;
            }>,
        ) => {
            state.destination.radiusKm = action.payload.radiusKm;
        },
        resetShipperLaneCreation: () => {
            return initialState;
        },
        calculateCostRequest: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                query: CalculateCostRequestQueryT;
                partnerId: PartnerIdT;
                contractId: ShipperContractIdT;
            }>,
        ) => {
            // nothing
        },
        calculateCostRequestSuccess: (
            state,
            action: PayloadAction<{
                query: CalculateCostRequestQueryT;
                averagePriceOffer: ApiPriceOfferT | null;
            }>,
        ) => {
            const { query, averagePriceOffer } = action.payload;

            if (!checkIsSameQuery(query, state.calculateCostRequestQuery)) {
                return;
            }

            state.calculateCostRequest = requestStatus.ok;
            state.averagePriceOffer = averagePriceOffer;
        },
        calculateCostIncomingPriceOffer: (
            state,
            action: PayloadAction<{
                query: CalculateCostRequestQueryT;
            }>,
        ) => {
            const { query } = action.payload;

            if (!checkIsSameQuery(query, state.calculateCostRequestQuery)) {
                return;
            }

            state.priceOfferCounts += 1;
        },
        calculateCostRequestSetRoute: (
            state,
            action: PayloadAction<{
                query: CalculateCostRequestQueryT;
                polylines: Array<GooglePolylineT>;
            }>,
        ) => {
            const { query, polylines } = action.payload;

            if (!checkIsSameQuery(query, state.calculateCostRequestQuery)) {
                return;
            }

            state.fetchRouteRequest = requestStatus.ok;
            state.polylines = polylines;
        },
        calculateCostRequestFetchRouteError: (
            state,
            action: PayloadAction<{
                query: CalculateCostRequestQueryT;
                error: Error;
            }>,
        ) => {
            const { query, error } = action.payload;

            if (!checkIsSameQuery(query, state.calculateCostRequestQuery)) {
                return;
            }

            state.fetchRouteRequest = requestStatus.setError(error);
            state.polylines = null;
        },
        calculateCostRequestError: (
            state,
            action: PayloadAction<{
                query: CalculateCostRequestQueryT;
                error: Error;
            }>,
        ) => {
            const { query, error } = action.payload;

            if (!checkIsSameQuery(query, state.calculateCostRequestQuery)) {
                return;
            }

            state.calculateCostRequest = requestStatus.setError(error);
        },
        calculateCostRequestBegin: (
            state,
            action: PayloadAction<{
                query: CalculateCostRequestQueryT;
            }>,
        ) => {
            state.calculateCostRequestQuery = action.payload.query;
            state.fetchRouteRequest = requestStatus.loading;
            state.calculateCostRequest = requestStatus.loading;
            state.averagePriceOffer = null;
            state.priceOfferCounts = 0;
        },
        createLaneRequest: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                query: CreateRequestQueryT;
                partnerId: PartnerIdT;
                contractId: ShipperContractIdT;
            }>,
        ) => {
            // nothing
        },
        createLaneRequestBegin: (
            state,
            action: PayloadAction<{
                query: CreateRequestQueryT;
            }>,
        ) => {
            state.creationRequestQuery = action.payload.query;
            state.creationRequest = requestStatus.loading;
        },
        createLaneRequestSuccess: (
            state,
            action: PayloadAction<{
                query: CreateRequestQueryT;
            }>,
        ) => {
            const { query } = action.payload;

            if (!checkIsSameQuery(query, state.creationRequestQuery)) {
                return;
            }

            state.creationRequest = requestStatus.ok;
        },
        createLaneRequestError: (
            state,
            action: PayloadAction<{
                query: CreateRequestQueryT;
                error: Error;
            }>,
        ) => {
            const { query, error } = action.payload;

            if (!checkIsSameQuery(query, state.creationRequestQuery)) {
                return;
            }

            state.creationRequest = requestStatus.setError(error);
        },
    },
});

export const {
    setMapSettings,

    resetShipperLaneCreation,

    setDestinationLocation,
    setDestinationLocationRadius,

    setOriginLocation,
    setOriginLocationRadius,

    calculateCostRequest,
    calculateCostRequestBegin,
    calculateCostRequestError,
    calculateCostRequestSuccess,
    calculateCostIncomingPriceOffer,
    calculateCostRequestSetRoute,
    calculateCostRequestFetchRouteError,

    createLaneRequest,
    createLaneRequestBegin,
    createLaneRequestError,
    createLaneRequestSuccess,
} = shipperContractLaneCreationSlice.actions;

export default shipperContractLaneCreationSlice.reducer;
