import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './DatesCell.scss';
import { BrokerOrderT } from 'broker-admin/store/orders/models';
import { clearDateTimezone, formatDate } from 'common/utils/time';

type PropsT = {
    order: BrokerOrderT;
};

const DATE_FORMAT = 'DD MMM';

const cx = classNames.bind(styles);

const DatesCell: React.FC<PropsT> = React.memo((props) => {
    const { order } = props;

    return (
        <div>
            <div className={cx('date')}>{formatDate(DATE_FORMAT, clearDateTimezone(order.pickupDockingHours))}</div>
            <div className={cx('date')}>{formatDate(DATE_FORMAT, clearDateTimezone(order.dropOffDockingHours))}</div>
        </div>
    );
});

export default DatesCell;
