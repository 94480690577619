import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkMaxValue, checkMinValue, checkNotEmpty } from 'common/utils/form-validators';
import values from 'lodash/values';

const REQUIRED_FIELDS = values<FieldsEnum>(FieldsEnum);

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkMinValue(t, [FieldsEnum.driverWorkingDay, FieldsEnum.driverRestTime], values, 1),
        ...checkMaxValue(t, [FieldsEnum.driverWorkingDay, FieldsEnum.driverRestTime], values, 12),
    };

    return errors;
};

export default validate;
