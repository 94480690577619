import {
    CREATE_RULE_REQUEST_BEGIN,
    CREATE_RULE_REQUEST_ERROR,
    CREATE_RULE_REQUEST_SUCCESS,
    DELETE_RULES_REQUEST_BEGIN,
    DELETE_RULES_REQUEST_ERROR,
    DELETE_RULES_REQUEST_SUCCESS,
    FETCH_RULE_DETAILS_REQUEST_BEGIN,
    FETCH_RULE_DETAILS_REQUEST_ERROR,
    FETCH_RULE_DETAILS_REQUEST_SUCCESS,
    FETCH_RULES_PAGE_REQUEST_BEGIN,
    FETCH_RULES_PAGE_REQUEST_ERROR,
    FETCH_RULES_PAGE_REQUEST_SUCCESS,
    RESET_RULE_PAGES,
    RulesActionT,
    RulesStateT,
    UPDATE_RULE_REQUEST_BEGIN,
    UPDATE_RULE_REQUEST_ERROR,
    UPDATE_RULE_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import {
    pageBeginReducer,
    pageErrorReducer,
    pageSuccessReducer,
    resetPagesCacheReducer,
} from 'common/utils/pagination/reducers';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import isEqual from 'lodash/isEqual';

const initialState: RulesStateT = {
    pages: [],
    query: null,
    total: null,
    byId: {},
    detailsById: {},
    fetchDetailsRequest: requestStatus.initial,
    updateRequest: requestStatus.initial,
    creationRequest: requestStatus.initial,
    deleteRequest: requestStatus.initial,
};

export default (state = initialState, action: RulesActionT | DestroySessionActionT): RulesStateT => {
    switch (action.type) {
        case FETCH_RULES_PAGE_REQUEST_BEGIN: {
            const { pageNumber, query } = action;

            return {
                ...state,
                ...pageBeginReducer(state, pageNumber, query),
            };
        }
        case FETCH_RULES_PAGE_REQUEST_SUCCESS: {
            const { query, pageResponse, pageNumber } = action;

            return {
                ...state,
                ...pageSuccessReducer(state, pageNumber, query, pageResponse),
            };
        }
        case FETCH_RULES_PAGE_REQUEST_ERROR: {
            const { error, pageNumber, query } = action;

            return {
                ...state,
                ...pageErrorReducer(state, pageNumber, query, error),
            };
        }
        case UPDATE_RULE_REQUEST_BEGIN: {
            return {
                ...state,
                updateRequest: requestStatus.loading,
            };
        }
        case UPDATE_RULE_REQUEST_SUCCESS: {
            return {
                ...state,
                updateRequest: requestStatus.ok,
            };
        }
        case UPDATE_RULE_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                updateRequest: requestStatus.setError(error),
            };
        }

        case RESET_RULE_PAGES: {
            const { mutationListOptions } = action;

            return {
                ...initialState,
                ...resetPagesCacheReducer(initialState, state, mutationListOptions),
            };
        }

        case CREATE_RULE_REQUEST_BEGIN: {
            return {
                ...state,
                creationRequest: requestStatus.loading,
            };
        }
        case CREATE_RULE_REQUEST_SUCCESS: {
            return {
                ...state,
                creationRequest: requestStatus.ok,
            };
        }
        case CREATE_RULE_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                creationRequest: requestStatus.setError(error),
            };
        }
        case DELETE_RULES_REQUEST_BEGIN: {
            return {
                ...state,
                deleteRequest: requestStatus.loading,
            };
        }
        case DELETE_RULES_REQUEST_SUCCESS: {
            return {
                ...state,
                deleteRequest: requestStatus.ok,
            };
        }
        case DELETE_RULES_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                deleteRequest: requestStatus.setError(error),
            };
        }
        case FETCH_RULE_DETAILS_REQUEST_BEGIN: {
            return {
                ...state,
                fetchDetailsRequest: requestStatus.loading,
            };
        }
        case FETCH_RULE_DETAILS_REQUEST_SUCCESS: {
            const { ruleId, ruleDetails } = action;

            return {
                ...state,
                detailsById: {
                    ...state.detailsById,
                    [ruleId]: isEqual(state.detailsById[ruleId], ruleDetails) ? state.detailsById[ruleId] : ruleDetails,
                },
                fetchDetailsRequest: requestStatus.ok,
            };
        }
        case FETCH_RULE_DETAILS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                fetchDetailsRequest: requestStatus.setError(error),
            };
        }
        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
