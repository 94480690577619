import * as React from 'react';
import { DispatchT } from 'broker-admin/store/dispatches/models';
import { formatTimeInterval } from 'common/utils/time';
import LocationCell from 'common/components/Table/cell-renderers/LocationCell/LocationCell';
import { CountryCodeT } from 'common/utils/api/models';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';

type PropsT = {
    dispatch: DispatchT;
};

const DropOffCell: React.FC<PropsT> = React.memo((props) => {
    const { dispatch } = props;

    const timeWindow = formatTimeInterval(dispatch.dropOffDateFrom, dispatch.dropOffDateTo);

    return (
        <LocationCell
            address={
                <LocationLabel
                    location={{
                        zipCode: dispatch.zipCodeTo,
                        city: dispatch.cityTo,
                        country: dispatch.countryCodeTo as CountryCodeT,
                    }}
                    format="zip_city_country"
                />
            }
            time={timeWindow}
        />
    );
});

export default DropOffCell;
