import { put, select, takeEvery } from 'redux-saga/effects';
import {
    ASSIGN_BID_TO_SPOT_REQUEST,
    AssignBidToSpotRequestActionT,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST,
    FetchSpotRequestDetailsRequestActionT,
} from './types';
import {
    fetchSpotRequestDetails,
    fetchSpotRequestDetailsBegin,
    fetchSpotRequestDetailsError,
    fetchSpotRequestDetailsSuccess,
    updateSpotRequestRequestBegin,
    updateSpotRequestRequestError,
    updateSpotRequestRequestSuccess,
} from './actions';
import { selectSpotRequestDetails } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import spotBrokerTranziitApi from 'broker-admin/utils/api/spot-broker-tranziit/api';
import { BrokerAlertTypeEnum, BrokerAnyAlert } from 'broker-admin/components/toasts/models';
import { addAlert } from 'common/store/alerts/actions';

function* assignBidToSpotRequestSaga(action: AssignBidToSpotRequestActionT): WrapGeneratorT<void> {
    const { id, bidId } = action;
    yield put(updateSpotRequestRequestBegin(id));

    const [error]: ReturnApiT<typeof spotBrokerTranziitApi.assignBidToSpotRequest> =
        yield spotBrokerTranziitApi.assignBidToSpotRequest(id, { bidId });
    if (error) {
        yield put(updateSpotRequestRequestError(id, error));
    } else {
        yield put(updateSpotRequestRequestSuccess(id));

        yield put(
            addAlert(
                new BrokerAnyAlert({
                    type: BrokerAlertTypeEnum.transportOrderAssigned,
                    data: {},
                }),
            ),
        );
    }

    yield put(fetchSpotRequestDetails(id, { isForceUpdate: true }));
}

function* fetchSpotRequestDetailsSaga(action: FetchSpotRequestDetailsRequestActionT): WrapGeneratorT<void> {
    const { id, options } = action;

    const { fetchDetailsRequestStatus }: ReReturnT<typeof selectSpotRequestDetails> = yield select(
        selectSpotRequestDetails(id),
    );
    if (!checkNeedRequest(fetchDetailsRequestStatus, options)) {
        return;
    }

    yield put(fetchSpotRequestDetailsBegin(id));

    const [error, details]: ReturnApiT<typeof spotBrokerTranziitApi.fetchSpotRequestDetails> =
        yield spotBrokerTranziitApi.fetchSpotRequestDetails(id);
    if (error) {
        yield put(fetchSpotRequestDetailsError(id, error));
    } else {
        yield put(fetchSpotRequestDetailsSuccess(id, details));
    }
}

function* spotRequestDetailsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_SPOT_REQUEST_DETAILS_REQUEST, fetchSpotRequestDetailsSaga);
    yield takeEvery(ASSIGN_BID_TO_SPOT_REQUEST, assignBidToSpotRequestSaga);
}

export default spotRequestDetailsSaga;
