import * as React from 'react';
import { BrokerInvoiceT } from 'broker-admin/store/invoices/models';
import Link, { LinkSizeEnum, LinkThemeEnum, LinkToT } from 'common/components/Link/Link';
import { PartnerTypeEnum } from 'common/utils/api/models';

type PropsT = {
    invoice: BrokerInvoiceT | null;
    getPartnerLink: (partnerType: PartnerTypeEnum, partnerId: CompanyIdT) => LinkToT;
};

const CustomerCell: React.FC<PropsT> = React.memo((props) => {
    const { getPartnerLink, invoice } = props;

    if (!invoice?.customerId) {
        return null;
    }

    return (
        <Link
            to={getPartnerLink(PartnerTypeEnum.shipper, invoice.customerId)}
            theme={LinkThemeEnum.boldBrandDark}
            size={LinkSizeEnum.medium}
        >
            {invoice.customerName || '-'}
        </Link>
    );
});

export default CustomerCell;
