import { CountryCodeT } from 'common/utils/api/models';

export enum QueryFiltersKeysEnum {
    dataSource = 'data-source',
    fromCountryCode = 'from-country-code',
    fromZipCode = 'from-zip-code',
    toCountryCode = 'to-country-code',
    toZipCode = 'to-zip-code',
    shipperId = 'shipper-id',
    shipperName = 'shipper-name',
    carrierId = 'carrier-id',
    carrierName = 'carrier-name',
}

export enum QueryKeysEnum {
    statsFilters = 'stats-filters',
    statsGranularity = 'stats-granularity',
    statsFrom = 'stats-from',
    statsTo = 'stats-to',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.dataSource]: string;
    [QueryFiltersKeysEnum.fromCountryCode]: CountryCodeT | null;
    [QueryFiltersKeysEnum.fromZipCode]: string;
    [QueryFiltersKeysEnum.toCountryCode]: CountryCodeT | null;
    [QueryFiltersKeysEnum.toZipCode]: string;
    [QueryFiltersKeysEnum.shipperId]: CompanyIdT | null;
    [QueryFiltersKeysEnum.shipperName]: CompanyIdT | null;
    [QueryFiltersKeysEnum.carrierId]: CompanyIdT | null;
    [QueryFiltersKeysEnum.carrierName]: CompanyIdT | null;
}>;

export type QueryT = Partial<{
    [QueryKeysEnum.statsFilters]: string;
    [QueryKeysEnum.statsGranularity]: string;
    [QueryKeysEnum.statsFrom]: string;
    [QueryKeysEnum.statsTo]: string;
}>;
