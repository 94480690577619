import { PartnerRFQStatsStateT, RFQStatsStateT } from './types';
import { StateT } from 'broker-admin/store/models';
import { initialPartnerState } from 'broker-admin/store/partner/rfq-stats/reducer';

export const selectRFQStatsRequest = (state: StateT): RFQStatsStateT['fetchRequest'] =>
    state.partner.rfqStats.fetchRequest;

export const selectCompanyDocumentsGroup =
    (companyId: CompanyIdT) =>
    (state: StateT): PartnerRFQStatsStateT => {
        return state.partner.rfqStats.byId[companyId] || initialPartnerState;
    };
