import { StyleGuideColorsEnum } from 'common/constants';

export default (utilizationPercent: number | null): StyleGuideColorsEnum => {
    if (utilizationPercent === null) {
        return StyleGuideColorsEnum.tomatoRed;
    }

    if (utilizationPercent > 80) {
        return StyleGuideColorsEnum.brandAccent;
    }

    if (utilizationPercent > 50) {
        return StyleGuideColorsEnum.orange;
    }

    return StyleGuideColorsEnum.tomatoRed;
};
