import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './ValidPeriodCell.scss';
import { RuleT } from 'broker-admin/store/price-settings/rules/models';
import { clearDateTimezone, formatDate } from 'common/utils/time';

type PropsT = {
    rule: RuleT;
};

const cx = classNames.bind(styles);

const DATE_FORMAT = 'DD MMM YYYY';

const ValidPeriodCell: React.FC<PropsT> = React.memo((props) => {
    const { rule } = props;

    return (
        <div>
            <div className={cx('date')}>{formatDate(DATE_FORMAT, clearDateTimezone(rule.validFrom))}</div>
            {rule.validTill && (
                <div className={cx('date')}>{formatDate(DATE_FORMAT, clearDateTimezone(rule.validTill))}</div>
            )}
        </div>
    );
});

export default ValidPeriodCell;
