import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonFormatter from 'design-system/components/InfoTable/formatters/SkeletonFormatter/SkeletonFormatter';
import { fetchSpotRequestDetails } from 'broker-admin/store/spot-request-details/actions';
import { selectSpotRequestDetails } from 'broker-admin/store/spot-request-details/selectors';
import { logWarning } from 'common/utils/logger';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';

type PropsT = {
    spotRequestId: SpotRequestIdT | null;
    onOpenSpotRequestDetails: (spotRequestId: SpotRequestIdT | null) => void;
};

const AsyncSpotRequestDetailsLinkFormatter: React.FC<PropsT> = React.memo((props) => {
    const { spotRequestId, onOpenSpotRequestDetails } = props;

    const dispatch = useDispatch();

    const { fetchDetailsRequestStatus, details } = useSelector(selectSpotRequestDetails(spotRequestId));

    useEffect(() => {
        if (!spotRequestId) {
            return;
        }

        dispatch(fetchSpotRequestDetails(spotRequestId));
    }, [spotRequestId]);

    if (fetchDetailsRequestStatus.loading || !details) {
        return <SkeletonFormatter />;
    }

    return (
        <Link
            onClick={() => {
                const spotRequestId = details?.id;
                if (!spotRequestId) {
                    logWarning('Empty spotRequestId');
                    return;
                }

                onOpenSpotRequestDetails(spotRequestId);
            }}
            theme={LinkThemeEnum.boldBrandDark}
        >
            {details?.number}
        </Link>
    );
});

export default AsyncSpotRequestDetailsLinkFormatter;
