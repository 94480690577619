import {
    FETCH_ADDITIONAL_SERVICES_REQUEST,
    FETCH_ADDITIONAL_SERVICES_REQUEST_BEGIN,
    FETCH_ADDITIONAL_SERVICES_REQUEST_ERROR,
    FETCH_ADDITIONAL_SERVICES_REQUEST_SUCCESS,
    FetchAdditionalServicesActionT,
    FetchAdditionalServicesBeginActionT,
    FetchAdditionalServicesErrorActionT,
    FetchAdditionalServicesSuccessActionT,
    UPDATE_ADDITIONAL_SERVICE_REQUEST,
    UPDATE_ADDITIONAL_SERVICE_REQUEST_BEGIN,
    UPDATE_ADDITIONAL_SERVICE_REQUEST_ERROR,
    UPDATE_ADDITIONAL_SERVICE_REQUEST_SUCCESS,
    UpdateAdditionalServiceActionT,
    UpdateAdditionalServiceBeginActionT,
    UpdateAdditionalServiceErrorActionT,
    UpdateAdditionalServiceSuccessActionT,
} from './types';
import { AdditionalServiceT } from './models';

export const fetchAdditionalServices = (options: FetchOptionsT): FetchAdditionalServicesActionT => ({
    type: FETCH_ADDITIONAL_SERVICES_REQUEST,
    options,
});

export const fetchAdditionalServicesBegin = (): FetchAdditionalServicesBeginActionT => ({
    type: FETCH_ADDITIONAL_SERVICES_REQUEST_BEGIN,
});

export const fetchAdditionalServicesSuccess = (
    list: Array<AdditionalServiceT>,
): FetchAdditionalServicesSuccessActionT => ({
    type: FETCH_ADDITIONAL_SERVICES_REQUEST_SUCCESS,
    list,
});

export const fetchAdditionalServicesError = (error: Error): FetchAdditionalServicesErrorActionT => ({
    type: FETCH_ADDITIONAL_SERVICES_REQUEST_ERROR,
    error,
});

export const updateAdditionalService = (
    serviceId: AdditionalServiceIdT,
    cost: number,
): UpdateAdditionalServiceActionT => ({
    type: UPDATE_ADDITIONAL_SERVICE_REQUEST,
    serviceId,
    cost,
});

export const updateAdditionalServiceBegin = (): UpdateAdditionalServiceBeginActionT => ({
    type: UPDATE_ADDITIONAL_SERVICE_REQUEST_BEGIN,
});

export const updateAdditionalServiceSuccess = (
    serviceId: AdditionalServiceIdT,
    cost: number,
): UpdateAdditionalServiceSuccessActionT => ({
    type: UPDATE_ADDITIONAL_SERVICE_REQUEST_SUCCESS,
    serviceId,
    cost,
});

export const updateAdditionalServiceError = (error: Error): UpdateAdditionalServiceErrorActionT => ({
    type: UPDATE_ADDITIONAL_SERVICE_REQUEST_ERROR,
    error,
});
