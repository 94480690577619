import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { selectFindShippersRequest, selectShippersById } from 'common/store/shipper-suggest/selectors';
import { findShippersById } from 'common/store/shipper-suggest/actions';
import isNil from 'lodash/isNil';

type PropsT = {
    shipperId: ShipperIdT | null | undefined;
};

const ShipperFilterPill: React.FC<PropsT> = React.memo((props) => {
    const { shipperId } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const byId = useSelector(selectShippersById);
    const requestStatus = useSelector(selectFindShippersRequest);

    const shipper = !isNil(shipperId) ? byId[shipperId] : null;

    React.useEffect(() => {
        if (!isNil(shipperId) && !shipper) {
            dispatch(findShippersById(shipperId, ''));
        }
    }, [shipperId, shipper]);

    if (shipperId === null) {
        return null;
    }

    if (requestStatus.loading) {
        return <ControlLoaderIcon fillColor={StyleGuideColorsEnum.white} size={DEFAULT_ICON_SIZE} />;
    }

    return <span>{shipper?.name}</span>;
});

export default ShipperFilterPill;
