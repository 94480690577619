import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './InlineLoader.scss';

type PropsT = {
    lineHeight?: number;
};

const cx = classNames.bind(styles);

const RATIO = 4;

const InlineLoader: React.FC<PropsT> = React.memo((props) => {
    const { lineHeight = 20 } = props;

    const verticalPadding = lineHeight / 4;
    const size = lineHeight - verticalPadding;
    return (
        <div
            className={cx('inner')}
            style={{
                width: `${size * RATIO}px`,
                height: `${lineHeight}px`,
                padding: `${verticalPadding}px 0`,
            }}
        >
            <div className={cx('inline-loader')} />
        </div>
    );
});

export default InlineLoader;
