import { useState, useCallback, useLayoutEffect } from 'react';

type DimensionT = {
    width: number;
    height: number;
    top: number;
    left: number;
    x: number;
    y: number;
    right: number;
    bottom: number;
} | null;

type UseDimensionsHookT = {
    ref: (node: HTMLElement | null) => void;
    dimensions: DimensionT;
    node: HTMLElement | null;
};

type UseDimensionsArgsT = {
    liveMeasure?: boolean;
};

const getDimensionObject = (node: HTMLElement): DimensionT => {
    const rect = node.getBoundingClientRect();

    return {
        width: rect.width,
        height: rect.height,
        top: 'x' in rect ? rect.x : (rect as DOMRect).top,
        left: 'y' in rect ? rect.y : (rect as DOMRect).left,
        x: 'x' in rect ? rect.x : (rect as DOMRect).left,
        y: 'y' in rect ? rect.y : (rect as DOMRect).top,
        right: rect.right,
        bottom: rect.bottom,
    };
};

const useDimensions = (args?: UseDimensionsArgsT): UseDimensionsHookT => {
    const [dimensions, setDimensions] = useState<DimensionT>(null);
    const [node, setNode] = useState<HTMLElement | null>(null);

    const ref = useCallback((node): void => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () => {
                window.requestAnimationFrame(() => {
                    setDimensions(getDimensionObject(node));
                });
            };

            measure();

            if (args?.liveMeasure) {
                window.addEventListener('resize', measure);
                window.addEventListener('scroll', measure);

                return () => {
                    window.removeEventListener('resize', measure);
                    window.removeEventListener('scroll', measure);
                };
            }
            return () => {
                // nothing
            };
        }
        return () => {
            // nothing
        };
    }, [node]);

    return {
        ref,
        dimensions,
        node,
    };
};

export default useDimensions;
