import * as React from 'react';
import { useState } from 'react';

import classNames from 'classnames/bind';
import styles from './ShipperContractLaneCreationConfirmForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import FormikField from 'common/components/forms/FormikField/FormikField';
import NumberInput from 'common/components/NumberInput/NumberInput';
import { CurrencyEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { CreationLaneConfirmDataT } from './models';
import CloseIcon from 'common/icons/CloseIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DoneIcon from 'common/icons/DoneIcon';
import EditIcon from 'common/icons/EditIcon';
import { simpleNumberFormatter } from 'common/utils/form-formatters';
import Money from 'common/components/Money/Money';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import ControlLoaderLabel from 'common/components/ControlLoaderLabel/ControlLoaderLabel';
import { useSelector } from 'react-redux';
import {
    selectAveragePriceOffer,
    selectCalculateCostRequest,
    selectCreationRequest,
} from 'common/store/shipper-contract-lane-creation/selectors';
import AveragePriceOfferDetails from './AveragePriceOfferDetails/AveragePriceOfferDetails';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    onConfirm: (data: CreationLaneConfirmDataT) => void;
    onCancel: () => void;
};

export const DEFAULT_INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.totalPrice]: '',
};

const ShipperContractLaneCreationConfirmForm: React.FC<PropsT> = React.memo((props) => {
    const { onConfirm, onCancel, className } = props;

    const { t } = useTranslation();

    const [prevTotalPrice, setPrevTotalPrice] = useState<string | null>(null);

    const creationRequest = useSelector(selectCreationRequest);
    const calculateCostRequest = useSelector(selectCalculateCostRequest);

    const averagePriceOffer = useSelector(selectAveragePriceOffer);

    const isShowLoader = calculateCostRequest.loading;
    const initTotalPrice = averagePriceOffer?.totalPrice;

    const validate = React.useCallback(
        (values: FormValuesT) => {
            return validateForm(t, values);
        },
        [t],
    );

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...DEFAULT_INITIAL_VALUES,
            [FieldsEnum.totalPrice]: simpleNumberFormatter(initTotalPrice) || '',
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, [initTotalPrice]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        initialTouched: {
            [FieldsEnum.totalPrice]: true,
        },
        validate,
        onSubmit: (values, formikHelpers): void => {
            onConfirm({
                totalPrice: parseSimpleNumber(values[FieldsEnum.totalPrice]) || 0,
            });

            formikHelpers.setTouched({});
        },
    });

    const isLoading = calculateCostRequest.loading;

    return (
        <form onSubmit={formik.handleSubmit} className={className}>
            <div className={cx('head')}>
                {isShowLoader ? (
                    <div className={cx('total-price-skeleton')} />
                ) : (
                    <>
                        {prevTotalPrice ? (
                            <>
                                <FormikField
                                    className={cx('field', 'field--total-price')}
                                    name={FieldsEnum.totalPrice}
                                    error={formik.errors[FieldsEnum.totalPrice]}
                                    meta={formik.getFieldMeta(FieldsEnum.totalPrice)}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                    withoutLabel
                                >
                                    {(props) => (
                                        <NumberInput
                                            name={FieldsEnum.totalPrice}
                                            value={formik.values[FieldsEnum.totalPrice]}
                                            step={1}
                                            unitType={UnitTypeEnum.euroAbbreviation}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                        />
                                    )}
                                </FormikField>
                                <div className={cx('mini-actions')}>
                                    <TransparentTrigger
                                        onClick={() => {
                                            formik.setFieldValue(FieldsEnum.totalPrice, prevTotalPrice);
                                            setPrevTotalPrice(null);
                                        }}
                                        leftIcon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                                        reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                                    />
                                    <TransparentTrigger
                                        onClick={() => {
                                            if (formik.errors[FieldsEnum.totalPrice]) {
                                                return;
                                            }

                                            setPrevTotalPrice(null);
                                        }}
                                        leftIcon={
                                            <DoneIcon
                                                size={DEFAULT_ICON_SIZE}
                                                fillColor={StyleGuideColorsEnum.brandAccent}
                                            />
                                        }
                                        reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={cx('total-price')}>
                                    <Money
                                        amount={parseSimpleNumber(formik.values[FieldsEnum.totalPrice])}
                                        currency={CurrencyEnum.EUR}
                                    />
                                </div>
                                <div className={cx('mini-actions')}>
                                    <TransparentTrigger
                                        onClick={() => {
                                            setPrevTotalPrice(formik.values[FieldsEnum.totalPrice]);
                                        }}
                                        leftIcon={<EditIcon />}
                                        reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
            <div className={cx('body')}>
                <div className={cx('receipt')}>
                    <AveragePriceOfferDetails priceOffer={averagePriceOffer} />
                </div>
                {isLoading && (
                    <div className={cx('loader__container')}>
                        <div className={cx('loader__paranja')} />
                        <div className={cx('loader')}>
                            <ControlLoaderLabel isInvert>{t('shipper-lane-creation.calculating')}</ControlLoaderLabel>
                        </div>
                    </div>
                )}
            </div>

            <div className={cx('actions')}>
                <Button
                    className={cx('action')}
                    theme={ButtonThemeEnum.secondary}
                    type="button"
                    onClick={onCancel}
                    testSelector="cancel"
                >
                    {t('shipper-lane-creation.actions.cancel')}
                </Button>
                <Button
                    isDisabled={calculateCostRequest.loading || creationRequest.loading}
                    className={cx('action')}
                    theme={ButtonThemeEnum.primary}
                    type="submit"
                    isLoading={creationRequest.loading}
                    testSelector="add"
                >
                    {t('shipper-lane-creation.actions.add')}
                </Button>
            </div>
        </form>
    );
});

export default ShipperContractLaneCreationConfirmForm;
