import { FetchSpotRequestsPageQueryT, SpotRequestT } from './models';

import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_SPOT_REQUESTS_PAGE_REQUEST = 'broker-admin/spot-requests/FETCH_SPOT_REQUESTS_PAGE_REQUEST';
export const FETCH_SPOT_REQUESTS_PAGE_REQUEST_BEGIN =
    'broker-admin/spot-requests/FETCH_SPOT_REQUESTS_PAGE_REQUEST_BEGIN';
export const FETCH_SPOT_REQUESTS_PAGE_REQUEST_ERROR =
    'broker-admin/spot-requests/FETCH_SPOT_REQUESTS_PAGE_REQUEST_ERROR';
export const FETCH_SPOT_REQUESTS_PAGE_REQUEST_SUCCESS =
    'broker-admin/spot-requests/FETCH_SPOT_REQUESTS_PAGE_REQUEST_SUCCESS';

export const RESET_SPOT_REQUESTS = 'broker-admin/spot-requests/RESET_SPOT_REQUESTS';

export type SpotRequestsStateT = PaginationStateT<SpotRequestT, FetchSpotRequestsPageQueryT>;

export type FetchSpotRequestsPageActionT = {
    type: typeof FETCH_SPOT_REQUESTS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchSpotRequestsPageQueryT;
    options?: FetchOptionsT;
};

export type FetchSpotRequestsPageBeginActionT = {
    type: typeof FETCH_SPOT_REQUESTS_PAGE_REQUEST_BEGIN;
    pageNumber: PageNumberT;
    query: FetchSpotRequestsPageQueryT;
};

export type FetchSpotRequestsPageSuccessActionT = {
    type: typeof FETCH_SPOT_REQUESTS_PAGE_REQUEST_SUCCESS;
    pageNumber: PageNumberT;
    pageResponse: PageResponseT<SpotRequestT> | null;
    query: FetchSpotRequestsPageQueryT;
};

export type FetchSpotRequestsPageErrorActionT = {
    type: typeof FETCH_SPOT_REQUESTS_PAGE_REQUEST_ERROR;
    query: FetchSpotRequestsPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
};

export type ResetSpotRequestsActionT = {
    type: typeof RESET_SPOT_REQUESTS;
    mutationListOptions: MutationListOptionsT;
};

export type SpotRequestsActionT =
    | FetchSpotRequestsPageActionT
    | FetchSpotRequestsPageBeginActionT
    | FetchSpotRequestsPageSuccessActionT
    | FetchSpotRequestsPageErrorActionT
    | ResetSpotRequestsActionT;
