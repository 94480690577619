import {
    ASSIGN_BID_TO_SPOT_REQUEST,
    AssignBidToSpotRequestActionT,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS,
    FetchSpotRequestDetailsRequestActionT,
    FetchSpotRequestDetailsRequestBeginActionT,
    FetchSpotRequestDetailsRequestErrorActionT,
    FetchSpotRequestDetailsRequestSuccessActionT,
    UPDATE_SPOT_REQUEST_REQUEST_BEGIN,
    UPDATE_SPOT_REQUEST_REQUEST_ERROR,
    UPDATE_SPOT_REQUEST_REQUEST_SUCCESS,
    UpdateSpotRequestRequestBeginActionT,
    UpdateSpotRequestRequestErrorActionT,
    UpdateSpotRequestRequestSuccessActionT,
} from './types';
import { SpotRequestDetailsT } from './models';

export const fetchSpotRequestDetails = (
    id: SpotRequestIdT,
    options?: FetchOptionsT,
): FetchSpotRequestDetailsRequestActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST,
    id,
    options,
});

export const fetchSpotRequestDetailsBegin = (id: SpotRequestIdT): FetchSpotRequestDetailsRequestBeginActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN,
    id,
});

export const fetchSpotRequestDetailsSuccess = (
    id: SpotRequestIdT,
    details: SpotRequestDetailsT | null,
): FetchSpotRequestDetailsRequestSuccessActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS,
    details,
    id,
});

export const fetchSpotRequestDetailsError = (
    id: SpotRequestIdT,
    error: Error,
): FetchSpotRequestDetailsRequestErrorActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR,
    error,
    id,
});

export const assignBidToSpotRequest = (id: SpotRequestIdT, bidId: BidIdT): AssignBidToSpotRequestActionT => ({
    type: ASSIGN_BID_TO_SPOT_REQUEST,
    id,
    bidId,
});

export const updateSpotRequestRequestBegin = (id: SpotRequestIdT): UpdateSpotRequestRequestBeginActionT => ({
    type: UPDATE_SPOT_REQUEST_REQUEST_BEGIN,
    id,
});

export const updateSpotRequestRequestSuccess = (id: SpotRequestIdT): UpdateSpotRequestRequestSuccessActionT => ({
    type: UPDATE_SPOT_REQUEST_REQUEST_SUCCESS,
    id,
});

export const updateSpotRequestRequestError = (
    id: SpotRequestIdT,
    error: Error,
): UpdateSpotRequestRequestErrorActionT => ({
    type: UPDATE_SPOT_REQUEST_REQUEST_ERROR,
    error,
    id,
});
