import { ApiSystemSettingsT } from 'common/utils/api/models';
import { ApiSpotSystemSettingsT } from 'broker-admin/utils/api/spot-broker-tranziit/models';

export type SystemSettingsT = ApiSystemSettingsT & ApiSpotSystemSettingsT;

export type SystemSettingsChangesT = Partial<SystemSettingsT>;

export enum ServiceFeeTypeEnum {
    percent = 'false',
    fixed = 'true',
}

export enum PricingTypeEnum {
    rateMatrix = 'RATE_MATRIX',
    mlBased = 'ML_BASED',
    combined = 'COMBINED',
}

export enum AssigmentTypeEnum {
    basic = 'BASIC',
}

export enum BooleanSettingValueEnum {
    true = 'true',
    false = 'false',
}
