import { combineReducers } from 'redux';

import detailsReducer from './details/reducer';
import RFQStatsReducer from './rfq-stats/reducer';

import { PartnerDetailsStateT } from './details/types';
import { RFQStatsStateT } from './rfq-stats/types';

export default combineReducers({
    details: detailsReducer,
    rfqStats: RFQStatsReducer,
});

export type PartnerStateT = {
    details: PartnerDetailsStateT;
    rfqStats: RFQStatsStateT;
};
