import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './AssetSchedulePin.scss';
import { AssetTypeEnum } from 'common/constants';
import TrailerIcon, { getTrailerIconProps } from 'common/icons/TrailerIcon';
import TruckIcon, { getTruckIconProps } from 'common/icons/TruckIcon';
import MapTooltip from 'common/components/maps/MapTooltip/MapTooltip';
import { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';

const cx = classNames.bind(styles);

type PropsT = {
    lat: number;
    lng: number;
    className?: string;

    assetType: AssetTypeEnum;
    isSelected: boolean;
    number: number;
};

const AssetSchedulePin: React.FC<PropsT> = React.memo((props) => {
    const {
        lat,
        lng,
        className,

        assetType,
        isSelected,
        number,
    } = props;

    return (
        <MapTooltip
            className={cs(cx('asset-tooltip'), className)}
            lat={lat}
            lng={lng}
            theme={isSelected ? MapPinThemeEnum.charcoal : MapPinThemeEnum.gray}
        >
            {() => (
                <div className={cx('row')}>
                    <div className={cx('icon')}>
                        {assetType === AssetTypeEnum.truck && <TruckIcon {...getTruckIconProps('assignment-events')} />}
                        {assetType === AssetTypeEnum.trailer && (
                            <TrailerIcon {...getTrailerIconProps('assignment-events')} />
                        )}
                    </div>
                    <div className={cx('number')}>{number}</div>
                </div>
            )}
        </MapTooltip>
    );
});

export default AssetSchedulePin;
