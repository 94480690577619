import { StatisticSourceEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import { CountryCodeT } from 'common/utils/api/models';

export enum FieldsEnum {
    dataSource = 'data-source',
    fromCountryCode = 'from-country-code',
    fromZipCode = 'from-zip-code',
    toCountryCode = 'to-country-code',
    toZipCode = 'to-zip-code',
    shipperId = 'shipper-id',
    shipperName = 'shipper-name',
    carrierId = 'carrier-id',
    carrierName = 'carrier-name',
}

export type FormValuesT = {
    [FieldsEnum.dataSource]: StatisticSourceEnum;
    [FieldsEnum.fromCountryCode]: CountryCodeT | null;
    [FieldsEnum.fromZipCode]: string;
    [FieldsEnum.toCountryCode]: CountryCodeT | null;
    [FieldsEnum.toZipCode]: string;
    [FieldsEnum.shipperId]: CompanyIdT | null;
    [FieldsEnum.shipperName]: string | null;
    [FieldsEnum.carrierId]: CompanyIdT | null;
    [FieldsEnum.carrierName]: string | null;
};

export const disableCompaniesDataSourceSet = new Set([StatisticSourceEnum.import]);
