import React, { useEffect } from 'react';
import * as d3 from 'd3';
import classNames from 'classnames/bind';

import styles from './Chart.scss';

import { ChartMarginT, DataItemT, TooltipOffsetT } from '../models';
import ChartTooltip from 'broker-admin/layouts/StatisticsPage/StatisticsChart/ChartTooltip/ChartTooltip';
import { GraphSettingsT } from 'broker-admin/layouts/StatisticsPage/models';
import { AggregateEnum } from 'broker-admin/layouts/StatisticsPage/constants';

const cx = classNames.bind(styles);

type HoverStateT = {
    offset: TooltipOffsetT;
    index: number;
} | null;

type DrawLineChartArgsT = {
    data: DataItemT[];
    settings: GraphSettingsT;
    width: number;
    height: number;
    xScale: d3.ScaleLinear<number, number>;
    yScale: d3.ScaleLinear<number, number>;
    svgRef: React.RefObject<SVGSVGElement>;
    onHoverState: (state: HoverStateT) => void;
    margin: ChartMarginT;
};

const drawLineChart = (props: DrawLineChartArgsT) => {
    const { svgRef, data, settings, xScale, yScale, height, onHoverState, margin } = props;

    const svg = d3.select(svgRef.current).select('g');

    if (
        settings.show[AggregateEnum.acceptedShipperRate] &&
        settings.show[AggregateEnum.realCarrierRate] &&
        settings.available[AggregateEnum.acceptedShipperRate] &&
        settings.available[AggregateEnum.realCarrierRate]
    ) {
        const X = d3.map(data, (d) => d.timestamp);
        const acceptedY = d3.map(data, (d) => d.acceptedShipperRate);
        const realY = d3.map(data, (d) => d.realCarrierRate);
        const I = d3.range(data.length);

        const area = (y0: number, y1: number) =>
            d3
                .area()
                // @ts-expect-error
                .x((i) => xScale(X[i]))
                .y0(y0)
                // @ts-expect-error
                .y1(y1)(I);

        const diffId = 'accepted-real-diff';

        svg.append('clipPath')
            .attr('id', `${diffId}-above`)
            .append('path')
            .attr(
                'd',
                // @ts-expect-error
                area(0, (i) => yScale(acceptedY[i])),
            );

        svg.append('clipPath')
            .attr('id', `${diffId}-below`)
            .append('path')
            .attr(
                'd',
                // @ts-expect-error
                area(height, (i) => yScale(acceptedY[i])),
            );

        svg.append('path')
            .attr('clip-path', `url('#${diffId}-above')`)
            .attr('fill', `url(#pattern_above)`)
            .attr(
                'd',
                // @ts-expect-error
                area(height, (i) => yScale(realY[i])),
            );

        svg.append('path')
            .attr('clip-path', `url('#${diffId}-below')`)
            .attr('fill', `url(#pattern_below)`)
            .attr(
                'd',
                // @ts-expect-error
                area(0, (i) => yScale(realY[i])),
            );
    }

    const handleMouseOver = (event: TODO): void => {
        const eventIndex = event.target.getAttribute('data-index');

        const rect = event.target.getBBox();
        if (!rect) {
            return;
        }

        onHoverState({
            index: eventIndex,
            offset: {
                left: margin.left + rect.x + rect.width / 2,
                top: margin.top + rect.y + rect.height / 2,
            },
        });
    };

    const handleMouseOut = (): void => {
        onHoverState(null);
    };

    const dotsRadius = 6;

    if (settings.show[AggregateEnum.acceptedShipperRate] && settings.available[AggregateEnum.acceptedShipperRate]) {
        const acceptedLine = d3
            .line<DataItemT>()
            .x((d) => xScale(d.timestamp))
            .y((d) => yScale(d.acceptedShipperRate))
            .curve(d3.curveLinear);

        svg.append('path')
            .datum(data)
            .attr('d', acceptedLine)
            .attr('class', cx(['line-chart', 'line-chart--accepted']));

        svg.selectAll('dot')
            .data(data)
            .enter()
            .append('circle')
            .attr('r', dotsRadius)
            .attr('class', cx('bubbles', 'bubbles--accepted'))
            .attr('cx', (d) => {
                return xScale(d.timestamp);
            })
            .attr('cy', (d) => {
                return yScale(d.acceptedShipperRate);
            })
            .attr('data-index', (_, i) => i)
            .on('mouseover', handleMouseOver)
            .on('mouseout', handleMouseOut);
    }

    if (settings.show[AggregateEnum.rejectedShipperRate] && settings.available[AggregateEnum.rejectedShipperRate]) {
        const rejectedLine = d3
            .line<DataItemT>()
            .x((d) => xScale(d.timestamp))
            .y((d) => yScale(d.rejectedShipperRate))
            .curve(d3.curveLinear);

        svg.append('path')
            .datum(data)
            .attr('d', rejectedLine)
            .attr('class', cx(['line-chart', 'line-chart--rejected']));

        svg.selectAll('dot')
            .data(data)
            .enter()
            .append('circle')
            .attr('r', dotsRadius)
            .attr('class', cx('bubbles', 'bubbles--rejected'))
            .attr('cx', (d) => {
                return xScale(d.timestamp);
            })
            .attr('cy', (d) => {
                return yScale(d.rejectedShipperRate);
            })
            .attr('data-index', (_, i) => i)
            .on('mouseover', handleMouseOver)
            .on('mouseout', handleMouseOut);
    }

    if (settings.show[AggregateEnum.estimatedCarrierRate] && settings.available[AggregateEnum.estimatedCarrierRate]) {
        const estimatedLine = d3
            .line<DataItemT>()
            .x((d) => xScale(d.timestamp))
            .y((d) => yScale(d.estimatedCarrierRate))
            .curve(d3.curveLinear);

        svg.append('path')
            .datum(data)
            .attr('d', estimatedLine)
            .attr('class', cx(['line-chart', 'line-chart--estimated']));

        svg.selectAll('dot')
            .data(data)
            .enter()
            .append('circle')
            .attr('r', dotsRadius)
            .attr('class', cx('bubbles', 'bubbles--estimated'))
            .attr('cx', (d) => {
                return xScale(d.timestamp);
            })
            .attr('cy', (d) => {
                return yScale(d.estimatedCarrierRate);
            })
            .attr('data-index', (_, i) => i)
            .on('mouseover', handleMouseOver)
            .on('mouseout', handleMouseOut);
    }

    if (settings.show[AggregateEnum.realCarrierRate] && settings.available[AggregateEnum.realCarrierRate]) {
        const realLine = d3
            .line<DataItemT>()
            .x((d) => xScale(d.timestamp))
            .y((d) => yScale(d.realCarrierRate))
            .curve(d3.curveLinear);

        svg.append('path')
            .datum(data)
            .attr('d', realLine)
            .attr('class', cx(['line-chart', 'line-chart--real']));

        svg.selectAll('dot')
            .data(data)
            .enter()
            .append('circle')
            .attr('r', dotsRadius)
            .attr('class', cx('bubbles', 'bubbles--real'))
            .attr('cx', (d) => {
                return xScale(d.timestamp);
            })
            .attr('cy', (d) => {
                return yScale(d.realCarrierRate);
            })
            .attr('data-index', (_, i) => i)
            .on('mouseover', handleMouseOver)
            .on('mouseout', handleMouseOut);
    }
};

type AddDiffPatternDefT = {
    svgRef: React.RefObject<SVGSVGElement>;
    name: string;
    color: string;
};

const addDiffPatternDef = (args: AddDiffPatternDefT) => {
    const { svgRef, name, color } = args;
    const svgDefs = d3.select(svgRef.current).append('defs');

    svgDefs
        .append('pattern')
        .attr('id', name)
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('width', '6')
        .attr('height', '6')
        .attr('patternTransform', 'rotate(0)')
        .append('line')
        .attr('x1', '0')
        .attr('y', '0')
        .attr('x2', '0')
        .attr('y2', '6')
        .attr('stroke', color)
        .attr('stroke-width', '10');
};

type AddDefsArgsT = {
    svgRef: React.RefObject<SVGSVGElement>;
};

const addDefs = (args: AddDefsArgsT): void => {
    const { svgRef } = args;

    addDiffPatternDef({
        svgRef,
        name: 'pattern_above',
        color: '#fdd0b2',
    });
    addDiffPatternDef({
        svgRef,
        name: 'pattern_below',
        color: '#c1edff',
    });
};

type DrawAxisArgsT = {
    width: number;
    height: number;
    xScale: d3.ScaleLinear<number, number>;
    xValues: Array<number>;
    yScale: d3.ScaleLinear<number, number>;
    svgRef: React.RefObject<SVGSVGElement>;
    xTickFormatter: (value: number) => string;
    yTickFormatter: (value: number) => string;
};

const drawAxis = (args: DrawAxisArgsT): void => {
    const { width, height, svgRef, xScale, xValues, yScale, xTickFormatter, yTickFormatter } = args;

    const svg = d3.select(svgRef.current).select('g');

    svg.append('g')
        .attr('class', cx('axis'))
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom<number>(xScale).tickFormat(xTickFormatter).tickValues(xValues))
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line:first-child').remove());

    svg.append('g')
        .attr('class', cx('axis'))
        .call(d3.axisLeft<number>(yScale).tickFormat(yTickFormatter))
        .call((g) => g.select('.domain').remove())
        .call((g) =>
            g
                .selectAll('.tick line')
                .clone()
                .attr('x2', width)
                .attr('class', cx(['axis', 'axis--line'])),
        )
        .call((g) => g.selectAll('.tick line:first-child').remove());

    // TODO right axis
    // svg.append('g')
    //     .attr('class', cx('axis'))
    //     .attr('transform', `translate(${width} ,0)`)
    //     .call(d3.axisRight(yScale))
    //     .call((g) => g.select('.domain').remove());
};

type PropsT = {
    margin: ChartMarginT;
    width: number;
    height: number;
    settings: GraphSettingsT;
    data: DataItemT[];
    xTickFormatter: (value: number) => string;
    yTickFormatter: (value: number) => string;
};

const Chart: React.FC<PropsT> = React.memo((props) => {
    const { margin, width, height, data, settings, xTickFormatter, yTickFormatter } = props;

    const [hoverState, setHoverState] = React.useState<HoverStateT>(null);

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const svgRef = React.createRef<SVGSVGElement>();

    const xMinValue = d3.min(data, (d) => d.timestamp) || 0;
    const xMaxValue = d3.max(data, (d) => d.timestamp) || 0;
    const xValues = data.map((d) => d.timestamp);
    const xScale = d3.scaleLinear().range([0, innerWidth]).domain([xMinValue, xMaxValue]);

    const yMinValue =
        d3.min(data, (d) => {
            return Math.min(d.rejectedShipperRate, d.acceptedShipperRate, d.estimatedCarrierRate, d.realCarrierRate);
        }) || 0;

    const yMaxValue =
        d3.max(data, (d) => {
            return Math.max(d.rejectedShipperRate, d.acceptedShipperRate, d.estimatedCarrierRate, d.realCarrierRate);
        }) || 0;

    const yScale = d3.scaleLinear().range([innerHeight, 0]).domain([yMinValue, yMaxValue]);

    const flushChart = () => {
        d3.select(svgRef.current).selectAll('*').remove();
    };

    const draw = () => {
        d3.select(svgRef.current)
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        addDefs({
            svgRef,
        });

        drawAxis({
            width: innerWidth,
            height: innerHeight,
            svgRef,
            xScale,
            xValues,
            yScale,
            xTickFormatter,
            yTickFormatter,
        });

        drawLineChart({
            settings,
            width: innerWidth,
            height: innerHeight,
            svgRef,
            data,
            xScale,
            yScale,
            onHoverState: setHoverState,
            margin,
        });
    };

    useEffect(() => {
        flushChart();
        draw();
    }, [width, height, data, settings]);

    return (
        <>
            <svg ref={svgRef} />
            <ChartTooltip
                settings={settings}
                data={data[hoverState?.index as number]}
                offset={hoverState?.offset || null}
            />
        </>
    );
});

export default Chart;
