import { PartnerTypeEnum } from 'common/utils/api/models';

export enum FieldsEnum {
    email = 'email',
    partnerType = 'partnerType',
}

export type FormValuesT = {
    [FieldsEnum.email]: string;
    [FieldsEnum.partnerType]: PartnerTypeEnum;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
