import * as React from 'react';
import { BrokerSidebarDataT } from './models';
import { BrokerSidebarsTypeEnum } from './constants';
import { GetSidebarCloseConfirmationT, RenderSidebarContentT } from 'common/layouts/SideBars/models';
import SpotRequestDetailsSidebarContent from 'broker-admin/layouts/SideBars/SpotRequestDetailsSidebarContent/SpotRequestDetailsSidebarContent';
import SpotRequestsFiltersSidebarContent from 'broker-admin/layouts/SideBars/SpotRequestsFiltersSidebarContent/SpotRequestsFiltersSidebarContent';
import PartnerFiltersSidebarContent from 'broker-admin/layouts/SideBars/PartnerFiltersSidebarContent/PartnerFiltersSidebarContent';
import CreatePartnerSidebarContent from 'broker-admin/layouts/SideBars/CreatePartnerSidebarContent/CreatePartnerSidebarContent';
import PriceSettingsRuleFiltersSidebarContent from 'broker-admin/layouts/SideBars/PriceSettingsRuleFiltersSidebarContent/PriceSettingsRuleFiltersSidebarContent';
import PriceSettingsEditRuleSidebarContent from 'broker-admin/layouts/SideBars/PriceSettingsEditRuleSidebarContent/PriceSettingsEditRuleSidebarContent';
import StatisticFiltersSidebarContent from 'broker-admin/layouts/SideBars/StatisticFiltersSidebarContent/StatisticFiltersSidebarContent';
import UploadShipperContractSidebarContent from 'broker-admin/layouts/SideBars/UploadShipperContractSidebarContent/UploadShipperContractSidebarContent';
import EditableShipperContractLaneDetailsSidebarContent from 'broker-admin/layouts/SideBars/EditableShipperContractLaneDetailsSidebarContent/EditableShipperContractLaneDetailsSidebarContent';

export const renderSidebarContent: RenderSidebarContentT<BrokerSidebarDataT> = (props) => {
    const { data, ...restProps } = props;

    switch (data.type) {
        case BrokerSidebarsTypeEnum.spotRequestDetails: {
            return <SpotRequestDetailsSidebarContent data={data} {...restProps} />;
        }

        case BrokerSidebarsTypeEnum.spotRequestsFilter: {
            return <SpotRequestsFiltersSidebarContent data={data} {...restProps} />;
        }

        case BrokerSidebarsTypeEnum.partnerFilter: {
            return <PartnerFiltersSidebarContent data={data} {...restProps} />;
        }

        case BrokerSidebarsTypeEnum.createPartner: {
            return <CreatePartnerSidebarContent data={data} {...restProps} />;
        }

        case BrokerSidebarsTypeEnum.priceSettingsRuleFilter: {
            return <PriceSettingsRuleFiltersSidebarContent data={data} {...restProps} />;
        }

        case BrokerSidebarsTypeEnum.editPriceSettingsRuleSidebar: {
            return <PriceSettingsEditRuleSidebarContent data={data} {...restProps} />;
        }

        case BrokerSidebarsTypeEnum.statisticsFilter: {
            return <StatisticFiltersSidebarContent data={data} {...restProps} />;
        }

        case BrokerSidebarsTypeEnum.uploadShipperContract: {
            return <UploadShipperContractSidebarContent data={data} {...restProps} />;
        }

        case BrokerSidebarsTypeEnum.shipperContractLaneDetails: {
            return <EditableShipperContractLaneDetailsSidebarContent data={data} {...restProps} />;
        }

        default: {
            return null;
        }
    }
};

export const getSidebarCloseConfirmation: GetSidebarCloseConfirmationT<BrokerSidebarDataT> = (t, data) => {
    return null;
};
