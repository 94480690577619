import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [
    FieldsEnum.name,
    FieldsEnum.partnerType,
    FieldsEnum.street1,
    FieldsEnum.countryCode,
    FieldsEnum.zipCode,
    FieldsEnum.city,
];

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
    };

    if (values[FieldsEnum.legalFormId] === null) {
        errors[FieldsEnum.legalFormId] = t('common:form-errors.required');
    }

    return errors;
};

export default validate;
