import { SharableStateT } from '../models';
import { ShipperContractLaneCreationStateT } from './slice';

export const selectShipperLaneOrigin = (state: SharableStateT): ShipperContractLaneCreationStateT['origin'] =>
    state.shipperContractLaneCreation.origin;

export const selectShipperLaneDestination = (state: SharableStateT): ShipperContractLaneCreationStateT['destination'] =>
    state.shipperContractLaneCreation.destination;

export const selectPolylines = (state: SharableStateT): ShipperContractLaneCreationStateT['polylines'] =>
    state.shipperContractLaneCreation.polylines;

export const selectFetchRouteRequest = (
    state: SharableStateT,
): ShipperContractLaneCreationStateT['fetchRouteRequest'] => state.shipperContractLaneCreation.fetchRouteRequest;

export const selectCalculateCostRequest = (
    state: SharableStateT,
): ShipperContractLaneCreationStateT['calculateCostRequest'] => state.shipperContractLaneCreation.calculateCostRequest;

export const selectCalculateCostPriceOfferCounts = (
    state: SharableStateT,
): ShipperContractLaneCreationStateT['priceOfferCounts'] => state.shipperContractLaneCreation.priceOfferCounts;

export const selectAveragePriceOffer = (
    state: SharableStateT,
): ShipperContractLaneCreationStateT['averagePriceOffer'] => state.shipperContractLaneCreation.averagePriceOffer;

export const selectCreationRequest = (state: SharableStateT): ShipperContractLaneCreationStateT['creationRequest'] =>
    state.shipperContractLaneCreation.creationRequest;

export const selectMapSettings = (state: SharableStateT): ShipperContractLaneCreationStateT['mapSettings'] =>
    state.shipperContractLaneCreation.mapSettings;
