import { FieldsEnum, FormValuesT } from './constants';
import { QueryFiltersT, QueryFiltersKeysEnum } from 'broker-admin/layouts/PartnersPage/query-models';
import { CountryCodeT } from 'common/utils/api/models';

const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    return {
        [FieldsEnum.keyAccountManagerId]: queryFilters[QueryFiltersKeysEnum.keyAccountManagerId] || null,
        [FieldsEnum.keyAccountManagerName]: queryFilters[QueryFiltersKeysEnum.keyAccountManagerName] || null,
        [FieldsEnum.dispatcherId]: queryFilters[QueryFiltersKeysEnum.dispatcherId] || null,
        [FieldsEnum.dispatcherName]: queryFilters[QueryFiltersKeysEnum.dispatcherName] || null,
        [FieldsEnum.countryCode]: (queryFilters[QueryFiltersKeysEnum.countryCode] as CountryCodeT) || null,
    };
};

export default getInitialValues;
