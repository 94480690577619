import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    strokeColor: StyleGuideColorsEnum;
};

const LinearScaleIcon: React.FC<PropsT> = (props) => {
    const { strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M15.625 8.125c-.773 0-1.425.465-1.717 1.125h-2.19A1.876 1.876 0 0010 8.125c-.773 0-1.425.465-1.718 1.125h-2.19a1.876 1.876 0 00-1.717-1.125 1.876 1.876 0 000 3.75c.772 0 1.425-.465 1.718-1.125h2.19c.292.66.944 1.125 1.717 1.125.773 0 1.425-.465 1.717-1.125h2.19c.293.66.945 1.125 1.718 1.125a1.876 1.876 0 000-3.75z"
                />
            </g>
        </svg>
    );
};

LinearScaleIcon.displayName = 'LinearScaleIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default LinearScaleIcon;
