import React from 'react';
import classNames from 'classnames/bind';

import styles from './InvitePartnerForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { ApiInvitePartnerRequestT, PartnerTypeEnum } from 'common/utils/api/models';
import FormikField from 'common/components/forms/FormikField/FormikField';
import Input from 'common/components/Input/Input';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { RequestStatusT } from 'common/utils/request-status';

const cx = classNames.bind(styles);

type PropsT = {
    addPartnerRequestStatus: RequestStatusT;
    onInvitePartner: (invitePartnerRequest: ApiInvitePartnerRequestT) => void;
};

export const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.email]: '',
    [FieldsEnum.partnerType]: PartnerTypeEnum.shipper,
};

type PartnerOptionT = {
    label: string;
    value: PartnerTypeEnum;
};

const InvitePartnerForm: React.FC<PropsT> = (props) => {
    const { onInvitePartner, addPartnerRequestStatus } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            onInvitePartner({
                email: values[FieldsEnum.email],
                partnerType: values[FieldsEnum.partnerType],
            });

            formikHelpers.setTouched({});
        },
    });

    const renderOption = (option: PartnerOptionT | null | undefined): React.ReactElement => (
        <span>{option?.label}</span>
    );

    const getOptionValue = (option: PartnerOptionT) => option?.value;

    const options = React.useMemo(
        (): Array<PartnerOptionT> => [
            {
                label: t('common:company-type.SHIPPER'),
                value: PartnerTypeEnum.shipper,
            },
            {
                label: t('common:company-type.CARRIER'),
                value: PartnerTypeEnum.carrier,
            },
        ],
        [],
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('wrap')}>
                <div className={cx('image')} />
                <div className={cx('title')}>{t('partners-table.add-business-partner.invite-form.title')}</div>
                <div className={cx('form')}>
                    <FieldGroup spaces={1}>
                        <FormikField
                            className={cx('field--email')}
                            name={FieldsEnum.email}
                            error={formik.errors[FieldsEnum.email]}
                            meta={formik.getFieldMeta(FieldsEnum.email)}
                            label={t('partners-table.add-business-partner.invite-form.fields.email.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.email}
                                    value={formik.values[FieldsEnum.email]}
                                    placeholder=""
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            className={cx('field--partner-type')}
                            name={FieldsEnum.partnerType}
                            error={formik.errors[FieldsEnum.partnerType]}
                            meta={formik.getFieldMeta(FieldsEnum.partnerType)}
                            label={t('partners-table.add-business-partner.invite-form.fields.profile.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DropdownInput<PartnerOptionT, PartnerTypeEnum>
                                    selectedValue={formik.values[FieldsEnum.partnerType]}
                                    options={options}
                                    onSelect={props.onChange}
                                    renderOption={renderOption}
                                    getOptionValue={getOptionValue}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                />
                            )}
                        </FormikField>
                    </FieldGroup>
                </div>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isLoading={addPartnerRequestStatus.loading}
                    className={cx('button')}
                    type="submit"
                >
                    {t('partners-table.add-business-partner.invite-form.submit')}
                </Button>
            </div>
        </form>
    );
};

export default InvitePartnerForm;
