import { put, select, takeEvery } from 'redux-saga/effects';

import { FETCH_DISPATCHERS_REQUEST, FetchDispatchersActionT } from './types';
import { fetchDispatchersBegin, fetchDispatchersError, fetchDispatchersSuccess } from './actions';
import { selectFetchDispatchersRequest } from './selectors';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';

function* fetchDispatchersSaga(action: FetchDispatchersActionT): WrapGeneratorT<void> {
    const request: ReturnType<typeof selectFetchDispatchersRequest> = yield select(selectFetchDispatchersRequest);
    if (request.ok) {
        return;
    }

    yield put(fetchDispatchersBegin());

    const [error, dispatchers]: ReturnApiT<typeof brokerTranziitApi.fetchPartnerDispatchers> =
        yield brokerTranziitApi.fetchPartnerDispatchers();
    if (error) {
        yield put(fetchDispatchersError(error));
        return;
    }

    yield put(fetchDispatchersSuccess(dispatchers || []));
}

function* userSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_DISPATCHERS_REQUEST, fetchDispatchersSaga);
}

export default userSaga;
