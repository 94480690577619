import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ShipmentDetailsCard.scss';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import Card from 'design-system/components/Card/Card';
import { useTranslation } from 'react-i18next';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { formatTimeInterval } from 'common/utils/time';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import NumberIcon from 'common/icons/NumberIcon';
import ShipmentStatusPill from 'common/components/status-pill/ShipmentStatusPill/ShipmentStatusPill';
import CargoUnitIcon from 'common/icons/CargoUnitIcon';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { getLoadUnitType } from 'common/utils/units';
import WeightIcon from 'common/icons/WeightIcon';
import SnowflakeIcon from 'common/icons/SnowflakeIcon';
import TemperatureModeFormatter from 'design-system/components/InfoTable/formatters/TemperatureModeFormatter/TemperatureModeFormatter';
import CommentIcon from 'common/icons/CommentIcon';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import ChangeShipmentStatusControl from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchDetails/ShipmentDetailsCard/ChangeShipmentStatusControl/ChangeShipmentStatusControl';
import {
    ApiDispatchWaypointDetailsT,
    ApiShipmentDetailsT,
    ApiShipmentStatusT,
    ShipmentStatusEnum,
} from 'common/utils/api/models';
import AttentionTextFormatter from 'design-system/components/InfoTable/formatters/AttentionTextFormatter/AttentionTextFormatter';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';

export type PropsT = {
    isCurrentLastNotCanceledShipment: boolean;
    isReeferTrailer: boolean;
    shipmentNumber: number;
    waypointById: Record<WaypointIdT, ApiDispatchWaypointDetailsT>;
    dispatchId: DispatchIdT | null;
    shipment: ApiShipmentDetailsT | null | undefined;
    keyboardShortcut?: string;
    className?: string;
};

const cx = classNames.bind(styles);

const hideLoaderStatusSet = new Set<ApiShipmentStatusT | null | undefined>([
    ShipmentStatusEnum.delivered,
    ShipmentStatusEnum.canceled,
]);

const ShipmentDetailsCard: React.FC<PropsT> = React.memo((props) => {
    const {
        isCurrentLastNotCanceledShipment,
        isReeferTrailer,
        shipmentNumber,
        dispatchId,
        waypointById,
        shipment,
        className,
        keyboardShortcut,
    } = props;

    const { t } = useTranslation();

    const idDetails: Array<InfoTableRowT> = [
        {
            icon: null,
            name: t('dispatch-details.shipment-details.columns.tour-number'),
            value: shipment?.number,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    const poDetails: Array<InfoTableRowT> = [
        {
            icon: null,
            name: t('common:order-details.columns.po-number'),
            value: shipment?.purchaseOrderNumber,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'po-number',
        },
    ];

    const brokerWindowAppointmentWarning = (
        <Tooltip
            position={TooltipPositionEnum.topLeft}
            theme={TooltipThemeEnum.black}
            tooltipNode={
                <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                    {t('dispatches.details.broke-window-appointment-warning')}
                </TooltipContent>
            }
        >
            {(isShow, childrenClassName) => (
                <TimeWindowIcon
                    className={childrenClassName}
                    fillColor={StyleGuideColorsEnum.transparent}
                    strokeColor={StyleGuideColorsEnum.orange}
                />
            )}
        </Tooltip>
    );

    const pickupWaypoint = waypointById?.[shipment?.pickupPointId as RoutePointIdT] || null;
    const originPointDetails: Array<InfoTableRowT> = [
        {
            icon: <NumberIcon number={(pickupWaypoint?.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />,
            name: t('dispatch-details.point-details.by-type.pickup'),
            value: <LocationLabel format="s1_s2_zip_city_country" location={pickupWaypoint?.address} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        ...(pickupWaypoint?.appointmentByBroker
            ? [
                  {
                      icon: null,
                      name: t('dispatch-details.point-details.columns.original-time-slot'),
                      value: formatTimeInterval(
                          pickupWaypoint?.originalDateTimeFrom,
                          pickupWaypoint?.originalDateTimeTo,
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      hasBottomBorder: true,
                  },
                  {
                      icon: pickupWaypoint?.appointmentByBroker ? brokerWindowAppointmentWarning : null,
                      name: t('dispatch-details.point-details.columns.corrected-time-slot'),
                      value: (
                          <AttentionTextFormatter isActive={pickupWaypoint?.appointmentByBroker}>
                              {formatTimeInterval(
                                  pickupWaypoint?.correctedDateTimeFrom,
                                  pickupWaypoint?.correctedDateTimeTo,
                              )}
                          </AttentionTextFormatter>
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      hasBottomBorder: true,
                  },
              ]
            : [
                  {
                      icon: null,
                      name: t('dispatch-details.point-details.columns.time-slot'),
                      value: formatTimeInterval(
                          pickupWaypoint?.correctedDateTimeFrom || pickupWaypoint?.originalDateTimeFrom,
                          pickupWaypoint?.correctedDateTimeTo || pickupWaypoint?.originalDateTimeTo,
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      hasBottomBorder: true,
                  },
              ]),
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.company-name'),
            value: pickupWaypoint?.contact?.companyName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-company-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.contact-name'),
            value: pickupWaypoint?.contact?.fullName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.contact-phone'),
            value: pickupWaypoint?.contact?.phone,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.contact-email'),
            value: pickupWaypoint?.contact?.email,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.reference-number'),
            value: 'TODO',
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.comments'),
            value: pickupWaypoint?.contact?.comment,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    const dropOffWaypoint = waypointById?.[shipment?.dropOffPointId as RoutePointIdT] || null;
    const destinationPointDetails: Array<InfoTableRowT> = [
        {
            icon: <NumberIcon number={(dropOffWaypoint?.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />,
            name: t('dispatch-details.point-details.by-type.delivery'),
            value: <LocationLabel format="s1_s2_zip_city_country" location={dropOffWaypoint?.address} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        ...(dropOffWaypoint?.appointmentByBroker
            ? [
                  {
                      icon: null,
                      name: t('dispatch-details.point-details.columns.original-time-slot'),
                      value: formatTimeInterval(
                          dropOffWaypoint?.originalDateTimeFrom,
                          dropOffWaypoint?.originalDateTimeTo,
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      hasBottomBorder: true,
                  },
                  {
                      icon: dropOffWaypoint?.appointmentByBroker ? brokerWindowAppointmentWarning : null,
                      name: t('dispatch-details.point-details.columns.corrected-time-slot'),
                      value: (
                          <AttentionTextFormatter isActive={dropOffWaypoint?.appointmentByBroker}>
                              {formatTimeInterval(
                                  dropOffWaypoint?.correctedDateTimeFrom,
                                  dropOffWaypoint?.correctedDateTimeTo,
                              )}
                          </AttentionTextFormatter>
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      hasBottomBorder: true,
                  },
              ]
            : [
                  {
                      icon: null,
                      name: t('dispatch-details.point-details.columns.time-slot'),
                      value: formatTimeInterval(
                          dropOffWaypoint?.correctedDateTimeFrom || dropOffWaypoint?.originalDateTimeFrom,
                          dropOffWaypoint?.correctedDateTimeTo || dropOffWaypoint?.originalDateTimeTo,
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      hasBottomBorder: true,
                  },
              ]),
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.company-name'),
            value: dropOffWaypoint?.contact?.companyName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-company-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.contact-name'),
            value: dropOffWaypoint?.contact?.fullName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.contact-phone'),
            value: dropOffWaypoint?.contact?.phone,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.contact-email'),
            value: dropOffWaypoint?.contact?.email,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.reference-number'),
            value: 'TODO',
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('dispatch-details.point-details.columns.comments'),
            value: dropOffWaypoint?.contact?.comment,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    const shipmentDetails: Array<InfoTableRowT | null> = [
        {
            icon: (
                <CargoUnitIcon
                    size={DEFAULT_ICON_SIZE}
                    fillColor={StyleGuideColorsEnum.light}
                    strokeColor={StyleGuideColorsEnum.gray}
                />
            ),
            name: t('common:order-details.columns.load-units'),
            value: <UnitTypeCount type={getLoadUnitType(shipment?.unitsType)} count={shipment?.maxNumberOfUnits} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: <WeightIcon fillColor={StyleGuideColorsEnum.gray} />,
            name: t('common:order-details.columns.cargo-weight'),
            value: <UnitTypeCount count={shipment?.weight} type={UnitTypeEnum.kilogramsAbbreviation} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        isReeferTrailer
            ? {
                  icon: <SnowflakeIcon fillColor={StyleGuideColorsEnum.gray} />,
                  name: t('common:order-details.columns.temperature'),
                  value: (
                      <TemperatureModeFormatter
                          lowestTemperature={shipment?.lowestTemperature}
                          highestTemperature={shipment?.highestTemperature}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
              }
            : null,
        {
            icon: <CommentIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('common:order-details.columns.description'),
            value: shipment?.description,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    return (
        <>
            <Card
                keyboardShortcut={keyboardShortcut}
                titleNode={t('dispatch-details.shipment-details.title', {
                    number: shipmentNumber,
                })}
                rightNode={
                    <>
                        <ShipmentStatusPill isSymmetrical status={shipment?.status} />
                        <ChangeShipmentStatusControl
                            isCurrentLastNotCanceledShipment={isCurrentLastNotCanceledShipment}
                            isShowLoading={!!shipment && !hideLoaderStatusSet.has(shipment?.status)}
                            dispatchId={dispatchId}
                            shipmentId={shipment?.id || null}
                        />
                    </>
                }
                className={className}
                hasHeaderBottomBorder
            >
                <div className={cx('content')}>
                    <InfoTable shouldRenderIcons rows={idDetails} className={cx('table', 'table--id')} />
                    <InfoTable shouldRenderIcons rows={poDetails} className={cx('table', 'table--po')} />
                    <InfoTable
                        shouldRenderIcons
                        isCollapsable
                        rows={originPointDetails}
                        className={cx('table', 'table--point')}
                    />
                    <InfoTable
                        shouldRenderIcons
                        isCollapsable
                        rows={destinationPointDetails}
                        className={cx('table', 'table--point')}
                    />
                    <InfoTable shouldRenderIcons rows={shipmentDetails} className={cx('table', 'table--shipment')} />
                </div>
            </Card>
        </>
    );
});

export default ShipmentDetailsCard;
