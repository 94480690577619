import { CountryCodeT, ApiPartnerDetailsT } from 'common/utils/api/models';
import { FieldsEnum, FormValuesT } from './constants';
import { simpleStringFormatter } from 'common/utils/form-formatters';

const prepareApiPartnerDetails = (formValues: FormValuesT): Partial<ApiPartnerDetailsT> => {
    const partnerDetails: Partial<ApiPartnerDetailsT> = {
        name: simpleStringFormatter(formValues[FieldsEnum.name]),
        keyAccountManagerId: formValues[FieldsEnum.keyAccountManagerId] || undefined,
        defaultDispatcherId: formValues[FieldsEnum.dispatcherId] || undefined,
        legalFormId: formValues[FieldsEnum.legalFormId] || undefined,

        legalAddress: {
            country: (formValues[FieldsEnum.legalAddressCountryCode] as CountryCodeT) || undefined,
            zipCode: simpleStringFormatter(formValues[FieldsEnum.legalAddressZipCode]),
            city: simpleStringFormatter(formValues[FieldsEnum.legalAddressCity]),
            street1: simpleStringFormatter(formValues[FieldsEnum.legalAddressStreet1]),
            street2: simpleStringFormatter(formValues[FieldsEnum.legalAddressStreet2]),
        },

        postalAddress: {
            country: (formValues[FieldsEnum.postalAddressCountryCode] as CountryCodeT) || undefined,
            zipCode: simpleStringFormatter(formValues[FieldsEnum.postalAddressZipCode]),
            city: simpleStringFormatter(formValues[FieldsEnum.postalAddressCity]),
            street1: simpleStringFormatter(formValues[FieldsEnum.postalAddressStreet1]),
            street2: simpleStringFormatter(formValues[FieldsEnum.postalAddressStreet2]),
        },

        billingAddress: {
            country: (formValues[FieldsEnum.billingAddressCountryCode] as CountryCodeT) || undefined,
            zipCode: simpleStringFormatter(formValues[FieldsEnum.billingAddressZipCode]),
            city: simpleStringFormatter(formValues[FieldsEnum.billingAddressCity]),
            street1: simpleStringFormatter(formValues[FieldsEnum.billingAddressStreet1]),
            street2: simpleStringFormatter(formValues[FieldsEnum.billingAddressStreet2]),
        },

        billingEmail: formValues[FieldsEnum.billingEmail],
    };

    return partnerDetails;
};

export default prepareApiPartnerDetails;
