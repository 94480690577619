import React from 'react';
import classNames from 'classnames/bind';

import styles from './PartnerFiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import KeyAccountManagerDropdown from 'broker-admin/components/dropdowns/KeyAccountManagerDropdown/KeyAccountManagerDropdown';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';
import DispatcherDropdown from 'broker-admin/components/dropdowns/DispatcherDropdown/DispatcherDropdown';
import { SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { BrokerSidebarDataT } from 'broker-admin/layouts/SideBars/models';
import { PartherFilterSidebarDataT } from 'broker-admin/layouts/SideBars/PartnerFiltersSidebarContent/models';
import { useQueryParam } from 'use-query-params';
import { createJsonParams } from 'common/utils/query';
import { QueryFiltersT, QueryKeysEnum } from 'broker-admin/layouts/PartnersPage/query-models';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<PartherFilterSidebarDataT, BrokerSidebarDataT>;

const PartnerFiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose } = props;
    const { t } = useTranslation();

    const [queryFilters, setQueryFilters] = useQueryParam<QueryFiltersT>(
        QueryKeysEnum.partnersFilters,
        createJsonParams<QueryFiltersT>({}),
    );

    const initialValues = React.useMemo(() => getInitialValues(queryFilters), [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const newQueryFilters = getQueryFilters(values);

            setQueryFilters({
                ...queryFilters,
                ...newQueryFilters,
            });

            formikHelpers.setTouched({});

            if (onClose) {
                onClose();
            }
        },
    });

    const isSameValues = React.useMemo(() => isDeepEqual(initialValues, formik.values), [initialValues, formik.values]);

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout hasBottomShadow>
                <HeaderSideBarContent title={t('partner.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FormikField
                    label={t('partner.filters.fields-labels.key-account-manager')}
                    name={FieldsEnum.keyAccountManagerId}
                    error={formik.errors[FieldsEnum.keyAccountManagerId]}
                    meta={formik.getFieldMeta(FieldsEnum.keyAccountManagerId)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <KeyAccountManagerDropdown
                            selectedValue={formik.values[FieldsEnum.keyAccountManagerId]}
                            initialLabel={initialValues[FieldsEnum.keyAccountManagerName]}
                            placeholder={t('partner.filters.fields-placeholder.key-account-manager')}
                            onSelect={(memberId, memberName) => {
                                formik.setFieldValue(FieldsEnum.keyAccountManagerId, memberId);
                                formik.setFieldValue(FieldsEnum.keyAccountManagerName, memberName);
                            }}
                            hasWarning={props.hasWarning}
                            hasError={props.hasError}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasChanges={props.hasChanges}
                            onReset={() => {
                                formik.setFieldValue(FieldsEnum.keyAccountManagerId, null);
                                formik.setFieldValue(FieldsEnum.keyAccountManagerName, null);
                            }}
                        />
                    )}
                </FormikField>
                <FormikField
                    label={t('partner.filters.fields-labels.dispatcher')}
                    name={FieldsEnum.dispatcherId}
                    error={formik.errors[FieldsEnum.dispatcherId]}
                    meta={formik.getFieldMeta(FieldsEnum.dispatcherId)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DispatcherDropdown
                            selectedValue={formik.values[FieldsEnum.dispatcherId]}
                            initialLabel={initialValues[FieldsEnum.dispatcherName]}
                            placeholder={t('partner.filters.fields-placeholder.dispatcher')}
                            onSelect={(memberId, memberName) => {
                                formik.setFieldValue(FieldsEnum.dispatcherId, memberId);
                                formik.setFieldValue(FieldsEnum.dispatcherName, memberName);
                            }}
                            hasWarning={props.hasWarning}
                            hasError={props.hasError}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasChanges={props.hasChanges}
                            onReset={() => {
                                formik.setFieldValue(FieldsEnum.dispatcherId, null);
                                formik.setFieldValue(FieldsEnum.dispatcherName, null);
                            }}
                        />
                    )}
                </FormikField>
                <FormikField
                    label={t('partner.filters.fields-labels.country')}
                    name={FieldsEnum.countryCode}
                    error={formik.errors[FieldsEnum.countryCode]}
                    meta={formik.getFieldMeta(FieldsEnum.countryCode)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <CountryDropdown
                            value={formik.values[FieldsEnum.countryCode]}
                            onChange={props.onChange}
                            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasChanges={props.hasChanges}
                            onReset={() => {
                                props.onChange(null);
                            }}
                        />
                    )}
                </FormikField>
            </SideBarLayout>
            <FooterSideBarLayout isTransparent hasPaddings>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('partner.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default PartnerFiltersSidebarContent;
