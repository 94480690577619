import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_DISPATCH_DETAILS_REQUEST, FetchDispatchDetailsRequestActionT } from './types';
import { fetchDispatchDetailsBegin, fetchDispatchDetailsError, fetchDispatchDetailsSuccess } from './actions';
import { selectDispatchDetailsRequestStatus } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';

function* fetchDispatchDetailsSaga(action: FetchDispatchDetailsRequestActionT): WrapGeneratorT<void> {
    const { id, options } = action;

    const requestStatus: ReReturnT<typeof selectDispatchDetailsRequestStatus> = yield select(
        selectDispatchDetailsRequestStatus(id),
    );
    if (!checkNeedRequest(requestStatus, options)) {
        return;
    }

    yield put(fetchDispatchDetailsBegin(id));

    const [error, dispatchDetails]: ReturnApiT<typeof brokerTranziitApi.getDispatchDetails> =
        yield brokerTranziitApi.getDispatchDetails(id);
    if (error) {
        yield put(fetchDispatchDetailsError(id, error));
    } else {
        yield put(fetchDispatchDetailsSuccess(id, dispatchDetails));
    }
}

function* dispatchDetailsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_DISPATCH_DETAILS_REQUEST, fetchDispatchDetailsSaga);
}

export default dispatchDetailsSaga;
