import {
    CREATE_PARTNER_REQUEST,
    CREATE_PARTNER_REQUEST_BEGIN,
    CREATE_PARTNER_REQUEST_ERROR,
    CREATE_PARTNER_REQUEST_SUCCESS,
    CreatePartnerRequestActionT,
    CreatePartnerRequestBeginActionT,
    CreatePartnerRequestErrorActionT,
    CreatePartnerRequestSuccessActionT,
    FETCH_PARTNERS_PAGE_REQUEST,
    FETCH_PARTNERS_PAGE_REQUEST_BEGIN,
    FETCH_PARTNERS_PAGE_REQUEST_ERROR,
    FETCH_PARTNERS_PAGE_REQUEST_SUCCESS,
    FetchPartnersPageActionT,
    FetchPartnersPageBeginActionT,
    FetchPartnersPageErrorActionT,
    FetchPartnersPageSuccessActionT,
    INVITE_PARTNER_REQUEST,
    InvitePartnerRequestActionT,
    RESET_PARTNERS,
    ResetPartnersActionT,
} from './types';
import { FetchPartnersPageQueryT, PartnerT } from './models';

import { ApiInvitePartnerRequestT, ApiPartnerCreationRequestT } from 'common/utils/api/models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchPartnersPage = (
    pageNumber: PageNumberT,
    query: FetchPartnersPageQueryT,
    options?: FetchOptionsT,
): FetchPartnersPageActionT => {
    return {
        type: FETCH_PARTNERS_PAGE_REQUEST,
        query,
        pageNumber,
        options,
    };
};

export const fetchPartnersPageBegin = (
    query: FetchPartnersPageQueryT,
    pageNumber: PageNumberT,
): FetchPartnersPageBeginActionT => ({
    type: FETCH_PARTNERS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
});

export const fetchPartnersPageSuccess = (
    query: FetchPartnersPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<PartnerT> | null,
): FetchPartnersPageSuccessActionT => ({
    type: FETCH_PARTNERS_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
});

export const fetchPartnersPageError = (
    query: FetchPartnersPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchPartnersPageErrorActionT => ({
    type: FETCH_PARTNERS_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
});

export const resetPartners = (mutationListOptions: MutationListOptionsT): ResetPartnersActionT => ({
    type: RESET_PARTNERS,
    mutationListOptions,
});

export const invitePartnerRequest = (data: ApiInvitePartnerRequestT): InvitePartnerRequestActionT => ({
    type: INVITE_PARTNER_REQUEST,
    data,
});

export const createPartnerRequest = (data: ApiPartnerCreationRequestT): CreatePartnerRequestActionT => ({
    type: CREATE_PARTNER_REQUEST,
    data,
});

export const createPartnerRequestBegin = (): CreatePartnerRequestBeginActionT => ({
    type: CREATE_PARTNER_REQUEST_BEGIN,
});

export const createPartnerRequestError = (error: Error): CreatePartnerRequestErrorActionT => ({
    type: CREATE_PARTNER_REQUEST_ERROR,
    error,
});

export const createPartnerRequestSuccess = (): CreatePartnerRequestSuccessActionT => ({
    type: CREATE_PARTNER_REQUEST_SUCCESS,
});
