import requestStatus from 'common/utils/request-status';

import {
    FETCH_PARTNER_DETAILS_REQUEST_BEGIN,
    FETCH_PARTNER_DETAILS_REQUEST_ERROR,
    FETCH_PARTNER_DETAILS_REQUEST_SUCCESS,
    LOCK_PARTNER_REQUEST_BEGIN,
    LOCK_PARTNER_REQUEST_ERROR,
    LOCK_PARTNER_REQUEST_SUCCESS,
    PartnerDetailsActionT,
    PartnerDetailsStateT,
    UPDATE_PARTNER_DETAILS_REQUEST_BEGIN,
    UPDATE_PARTNER_DETAILS_REQUEST_ERROR,
    UPDATE_PARTNER_DETAILS_REQUEST_SUCCESS,
    VERIFY_PARTNER_DETAILS_REQUEST_BEGIN,
    VERIFY_PARTNER_DETAILS_REQUEST_ERROR,
    VERIFY_PARTNER_DETAILS_REQUEST_SUCCESS,
} from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import isEqual from 'lodash/isEqual';

const initialState: PartnerDetailsStateT = {
    fetchRequest: requestStatus.initial,
    updateRequest: requestStatus.initial,
    verifyRequest: requestStatus.initial,
    lockRequest: requestStatus.initial,
    byId: {},
};

export default (state = initialState, action: PartnerDetailsActionT | DestroySessionActionT): PartnerDetailsStateT => {
    switch (action.type) {
        case FETCH_PARTNER_DETAILS_REQUEST_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }
        case FETCH_PARTNER_DETAILS_REQUEST_SUCCESS: {
            const { partnerDetails } = action;

            const prevPartnerDetails = state?.byId?.[partnerDetails?.id as string] || null;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [partnerDetails?.id as string]: isEqual(prevPartnerDetails, partnerDetails)
                        ? prevPartnerDetails
                        : partnerDetails,
                },
                fetchRequest: requestStatus.ok,
            };
        }
        case FETCH_PARTNER_DETAILS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                fetchRequest: requestStatus.setError(error),
            };
        }
        case UPDATE_PARTNER_DETAILS_REQUEST_BEGIN: {
            return {
                ...state,
                updateRequest: requestStatus.loading,
            };
        }
        case UPDATE_PARTNER_DETAILS_REQUEST_SUCCESS: {
            const { partnerDetails } = action;

            const prevPartnerDetails = state?.byId?.[partnerDetails?.id as string] || null;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [partnerDetails?.id as string]: isEqual(prevPartnerDetails, partnerDetails)
                        ? prevPartnerDetails
                        : partnerDetails,
                },
                updateRequest: requestStatus.ok,
            };
        }
        case UPDATE_PARTNER_DETAILS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                updateRequest: requestStatus.setError(error),
            };
        }
        case LOCK_PARTNER_REQUEST_BEGIN: {
            return {
                ...state,
                lockRequest: requestStatus.loading,
            };
        }
        case LOCK_PARTNER_REQUEST_ERROR: {
            const { error } = action;
            return {
                ...state,
                lockRequest: requestStatus.setError(error),
            };
        }
        case LOCK_PARTNER_REQUEST_SUCCESS: {
            return {
                ...state,
                lockRequest: requestStatus.ok,
            };
        }
        case VERIFY_PARTNER_DETAILS_REQUEST_BEGIN: {
            return {
                ...state,
                verifyRequest: requestStatus.loading,
            };
        }
        case VERIFY_PARTNER_DETAILS_REQUEST_ERROR: {
            const { error } = action;
            return {
                ...state,
                verifyRequest: requestStatus.setError(error),
            };
        }
        case VERIFY_PARTNER_DETAILS_REQUEST_SUCCESS: {
            return {
                ...state,
                verifyRequest: requestStatus.ok,
            };
        }
        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
