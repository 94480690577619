export enum FieldsEnum {
    waypoints = 'waypoints',
}

export enum WaypointsFieldEnum {
    timeWindow = 'timeWindow',
}

export type WaypointsFormValuesT = {
    timeWindow: TimeWindowT | null;
};

export type FormValuesT = {
    waypoints: Array<WaypointsFormValuesT>;
};

export type WaypointsFormErrorsT = Partial<{
    timeWindow: FormErrorMessageT;
}>;

export type FormErrorsT = Partial<{
    waypoints: Array<WaypointsFormErrorsT>;
}>;
