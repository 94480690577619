import { CountryCodeT } from 'common/store/countries-dict/models';
import { PartnerTypeEnum } from 'common/utils/api/models';

export enum FieldsEnum {
    name = 'name',
    partnerType = 'partnerType',

    street1 = 'street1',
    street2 = 'street2',
    countryCode = 'countryCode',
    zipCode = 'zipCode',
    city = 'city',

    legalFormId = 'legalFormId',
}

export type FormValuesT = {
    [FieldsEnum.name]: string;
    [FieldsEnum.partnerType]: PartnerTypeEnum;

    [FieldsEnum.street1]: string;
    [FieldsEnum.street2]: string;
    [FieldsEnum.countryCode]: CountryCodeT | null;
    [FieldsEnum.zipCode]: string;
    [FieldsEnum.city]: string;

    [FieldsEnum.legalFormId]: number | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
