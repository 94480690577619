import { PartnerT } from 'broker-admin/store/partners/models';
import { CompanyTypeEnum } from 'common/constants';

export const checkAllVerified = (partner: PartnerT) => {
    if (!partner.addressVerified) {
        return false;
    }

    if (!partner.documentsVerified) {
        return false;
    }

    if (partner.profileType.includes(CompanyTypeEnum.shipper) && !partner.billingAddressVerified) {
        return false;
    }

    return true;
};
