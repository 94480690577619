import { put, select, takeEvery } from 'redux-saga/effects';
import {
    FETCH_PARTNER_DETAILS_REQUEST,
    FetchPartnerDetailsActionT,
    LOCK_PARTNER_REQUEST,
    LockPartnerActionT,
    UPDATE_PARTNER_DETAILS_REQUEST,
    UpdatePartnerDetailsActionT,
    VERIFY_PARTNER_DETAILS_REQUEST,
    VerifyPartnerDetailsActionT,
} from './types';
import {
    fetchPartnerDetails,
    fetchPartnerDetailsBegin,
    fetchPartnerDetailsError,
    fetchPartnerDetailsSuccess,
    lockPartnerBegin,
    lockPartnerError,
    lockPartnerSuccess,
    updatePartnerDetailsBegin,
    updatePartnerDetailsError,
    updatePartnerDetailsSuccess,
    verifyPartnerDetailsBegin,
    verifyPartnerDetailsError,
    verifyPartnerDetailsSuccess,
} from './actions';
import { selectPartnerDetailsById } from 'broker-admin/store/partner/details/selectors';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { logWarning } from 'common/utils/logger';
import { partnersRefreshChannel } from 'broker-admin/store/partners/channels';
import { partnerDetailsRefreshChannel } from 'broker-admin/store/partner/details/channels';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { companyRefreshChannel } from 'common/store/company/channels';

function* fetchPartnerDetailsSaga(action: FetchPartnerDetailsActionT): WrapGeneratorT<void> {
    const { companyId } = action;

    yield put(fetchPartnerDetailsBegin());

    const [error, partnerDetails]: ReturnApiT<typeof brokerTranziitApi.fetchPartnerDetails> =
        yield brokerTranziitApi.fetchPartnerDetails(companyId);

    if (error) {
        yield put(fetchPartnerDetailsError(error));
        return;
    }

    yield put(fetchPartnerDetailsSuccess(partnerDetails));
}

function* updatePartnerDetailsSaga(action: UpdatePartnerDetailsActionT): WrapGeneratorT<void> {
    const { partnerDetailsChanges, partnerType, companyId } = action;

    const partnerDetailsById: ReturnType<typeof selectPartnerDetailsById> = yield select(selectPartnerDetailsById);
    const partnerDetails = partnerDetailsById[companyId];
    if (!partnerDetails) {
        logWarning('empty partnerDetails, failed update partern details');
        return;
    }

    yield put(updatePartnerDetailsBegin());
    const [error]: ReturnApiT<typeof brokerTranziitApi.updatePartnerDetails> =
        yield brokerTranziitApi.updatePartnerDetails(companyId, partnerDetailsChanges);
    if (error) {
        yield put(updatePartnerDetailsError(error));
        return;
    }

    yield put(
        updatePartnerDetailsSuccess({
            ...partnerDetails,
            ...partnerDetailsChanges,
        }),
    );

    partnersRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});

    // broker company is single
    if (partnerType === PartnerTypeEnum.broker) {
        companyRefreshChannel.emit({});
    }
}

function* verifyPartnerDetailsSaga(action: VerifyPartnerDetailsActionT): WrapGeneratorT<void> {
    const { isVerify, companyId, addressType } = action;

    yield put(verifyPartnerDetailsBegin());

    let response: ReturnApiT<
        typeof brokerTranziitApi.verifyPartnerAddress | typeof brokerTranziitApi.unVerifyPartnerAddress
    >;
    if (isVerify) {
        response = yield brokerTranziitApi.verifyPartnerAddress(companyId, addressType);
    } else {
        response = yield brokerTranziitApi.unVerifyPartnerAddress(companyId, addressType);
    }

    const [error] = response;
    if (error) {
        yield put(verifyPartnerDetailsError(error));
        return;
    }

    yield put(verifyPartnerDetailsSuccess());

    yield put(fetchPartnerDetails(companyId));

    partnersRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});
}

function* lockPartnerDetailsSaga(action: LockPartnerActionT): WrapGeneratorT<void> {
    const { isLock, companyId } = action;

    yield put(lockPartnerBegin());

    let response: ReturnApiT<typeof brokerTranziitApi.lockPartner | typeof brokerTranziitApi.unlockPartner>;
    if (isLock) {
        response = yield brokerTranziitApi.lockPartner(companyId);
    } else {
        response = yield brokerTranziitApi.unlockPartner(companyId);
    }

    const [error] = response;
    if (error) {
        yield put(lockPartnerError(error));
        return;
    }

    yield put(lockPartnerSuccess());

    yield put(fetchPartnerDetails(companyId));

    partnersRefreshChannel.emit({});
    partnerDetailsRefreshChannel.emit({});
}

function* userSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_PARTNER_DETAILS_REQUEST, fetchPartnerDetailsSaga);
    yield takeEvery(UPDATE_PARTNER_DETAILS_REQUEST, updatePartnerDetailsSaga);
    yield takeEvery(VERIFY_PARTNER_DETAILS_REQUEST, verifyPartnerDetailsSaga);
    yield takeEvery(LOCK_PARTNER_REQUEST, lockPartnerDetailsSaga);
}

export default userSaga;
