import * as React from 'react';
import { RuleT } from 'broker-admin/store/price-settings/rules/models';
import { clearDateTimezone } from 'common/utils/time';
import EventCell from 'common/components/Table/cell-renderers/EventCell/EventCell';

type PropsT = {
    rule: RuleT;
    onOpenUserDetails: (userId: UserIdT | null) => void;
};

const UpdateCell: React.FC<PropsT> = React.memo((props) => {
    const { rule, onOpenUserDetails } = props;

    return (
        <EventCell
            userFullName={rule.updatedByName}
            userId={rule.updatedById}
            date={clearDateTimezone(rule.updatedDate)}
            byBroker
            openUserDetails={onOpenUserDetails}
        />
    );
});

export default UpdateCell;
