import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    strokeColor: StyleGuideColorsEnum;
    fillColor: StyleGuideColorsEnum;
    size: number;
};

const UnavailableTrailerIcon: React.FC<PropsT> = (props) => {
    const { size, className, fillColor, strokeColor } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            viewBox="0 0 20 20"
            className={className}
        >
            <g fill="none" fillRule="evenodd">
                <path
                    fill={fillColor}
                    d="M17 3v8l-1 1v2h-2l-3-2V8c0-1-1-2-2-2h-.25v-.5c0-.96-.362-1.837-.957-2.501L17 3z"
                />
                <path
                    fill={strokeColor}
                    d="M11.182 13.462c1.45 0 2.625 1.197 2.625 2.671 0 1.474-1.175 2.67-2.625 2.67a2.602 2.602 0 01-1.913-.84c.428-.112.9-.462 1.243-.876.189.138.42.22.67.22.634 0 1.152-.529 1.152-1.174A1.163 1.163 0 0011 14.978v-1.51c.06-.003.122-.006.183-.006zM3 1.75c2.07 0 3.75 1.68 3.75 3.75V7h.75C8.325 7 9 7.675 9 8.5V16c0 .825-.675 1.5-1.5 1.5h-9c-.825 0-1.5-.675-1.5-1.5V8.5c0-.825.675-1.5 1.5-1.5h.75V5.5C-.75 3.43.93 1.75 3 1.75zm14.448 13.711v.783h-2.93a2.969 2.969 0 00-.027-.783h2.957zM18 2v10.235h-.844v3.004h-2.7l-.13-.084c-.442-1.314-1.669-2.265-3.109-2.265-.073 0-.146.002-.218.007V11H16V4H8.494a5.872 5.872 0 00-1.12-2H18zm-1.585 10.234h-.41v1.662h.41v-1.662zM3 10.75c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5 1.5-.675 1.5-1.5-.675-1.5-1.5-1.5zm0-7.575A2.327 2.327 0 00.675 5.5V7h4.65V5.5A2.327 2.327 0 003 3.175z"
                />
            </g>
        </svg>
    );
};

UnavailableTrailerIcon.displayName = 'UnavailableTrailerIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.light,
    strokeColor: StyleGuideColorsEnum.slate,
};

export { storyProps };
export default UnavailableTrailerIcon;
