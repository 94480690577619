import { CountryCodeT } from 'common/utils/api/models';

export enum FieldsEnum {
    keyAccountManagerId = 'keyAccountManagerId',
    keyAccountManagerName = 'keyAccountManagerName',
    dispatcherId = 'dispatcherId',
    dispatcherName = 'dispatcherName',
    countryCode = 'countryCode',
}

export type FormValuesT = {
    [FieldsEnum.keyAccountManagerId]: string | null;
    [FieldsEnum.keyAccountManagerName]: string | null;
    [FieldsEnum.dispatcherId]: string | null;
    [FieldsEnum.dispatcherName]: string | null;
    [FieldsEnum.countryCode]: CountryCodeT | null;
};
