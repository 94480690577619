import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './StatisticsPage.scss';
import { useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import PageTitle from 'common/components/PageTitle/PageTitle';
import Widget from 'common/components/Widget/Widget';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import SelectedFilters from './SelectedFilters/SelectedFilters';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import { createJsonParams } from 'common/utils/query';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { CurrencyEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import Money from 'common/components/Money/Money';
import CircleIcon from 'common/icons/CircleIcon';
import LinearScaleIcon from 'common/icons/LinearScaleIcon';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import TwinDatePicker from 'design-system/components/date-pickers/TwinDatePicker/TwinDatePicker';
import StatisticsChart from 'broker-admin/layouts/StatisticsPage/StatisticsChart/StatisticsChart';
import { useDispatch, useSelector } from 'react-redux';
import { StatisticGranularityEnum, StatisticSourceEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import { prepareFetchQuery } from 'broker-admin/layouts/StatisticsPage/prepare-fetch-query';
import { fetchStatistics } from 'broker-admin/store/statistics/actions';
import moment from 'moment';
import { getDateFromDate } from 'common/utils/time';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import {
    selectDoneStatistics,
    selectDoneStatisticsStatus,
    selectRejectStatistics,
    selectRejectStatisticsStatus,
} from 'broker-admin/store/statistics/selectors';
import { AggregateEnum, DEFAULT_DATA_SOURCE } from './constants';
import { GraphSettingsT } from './models';
import isNil from 'lodash/isNil';
import InlineLoader from 'common/components/InlineLoader/InlineLoader';
import CalendarIcon from 'common/icons/CalendarIcon';
import { StringParam, useQueryParams } from 'use-query-params';
import { useCheckOpenedSidebar, useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { BrokerSidebarsTypeEnum } from 'broker-admin/layouts/SideBars/constants';
import { Simulate } from 'react-dom/test-utils';
import change = Simulate.change;
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cx = classNames.bind(styles);

type PropsT = {};

const DATE_FORMAT = 'YYYY-MM-DD';

const AGGREGATE_LABEL_T: Record<AggregateEnum, string> = {
    [AggregateEnum.rejectedShipperRate]: 'statistics.aggregate.rejected-shipper-rate',
    [AggregateEnum.acceptedShipperRate]: 'statistics.aggregate.accepted-shipper-rate',
    [AggregateEnum.estimatedCarrierRate]: 'statistics.aggregate.estimated-carrier-rate',
    [AggregateEnum.realCarrierRate]: 'statistics.aggregate.real-carrier-rate',
    [AggregateEnum.margin]: 'statistics.aggregate.margin',
};

const AGGREGATE_COLOR: Record<AggregateEnum, StyleGuideColorsEnum | null> = {
    [AggregateEnum.rejectedShipperRate]: StyleGuideColorsEnum.tomatoRed,
    [AggregateEnum.acceptedShipperRate]: StyleGuideColorsEnum.mediumGreen,
    [AggregateEnum.estimatedCarrierRate]: StyleGuideColorsEnum.light,
    [AggregateEnum.realCarrierRate]: StyleGuideColorsEnum.orange,
    [AggregateEnum.margin]: null,
};

type GranularityOptionT = {
    label: React.ReactNode;
    value: StatisticGranularityEnum;
};

const availablePresetMap: Record<StatisticSourceEnum, GraphSettingsT['available']> = {
    [StatisticSourceEnum.import]: {
        [AggregateEnum.rejectedShipperRate]: false,
        [AggregateEnum.acceptedShipperRate]: false,
        [AggregateEnum.estimatedCarrierRate]: true,
        [AggregateEnum.realCarrierRate]: true,
        [AggregateEnum.margin]: false,
    },
    [StatisticSourceEnum.native]: {
        [AggregateEnum.rejectedShipperRate]: true,
        [AggregateEnum.acceptedShipperRate]: true,
        [AggregateEnum.estimatedCarrierRate]: true,
        [AggregateEnum.realCarrierRate]: true,
        [AggregateEnum.margin]: true,
    },
};

const StatisticsPage: React.FC<PropsT> = React.memo(() => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.statsFilters]: createJsonParams<QueryFiltersT>({}),
        [QueryKeysEnum.statsGranularity]: StringParam,
        [QueryKeysEnum.statsFrom]: StringParam,
        [QueryKeysEnum.statsTo]: StringParam,
    });

    const queryFilters = query[QueryKeysEnum.statsFilters];

    const openLeftSidebar = useOpenLeftSidebar();

    const isOpenedFilters = useCheckOpenedSidebar(BrokerSidebarsTypeEnum.statisticsFilter);
    const showFilters = () => {
        openLeftSidebar({
            type: BrokerSidebarsTypeEnum.statisticsFilter,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        changeQuery({
            [QueryKeysEnum.statsFilters]: selectedQueryFilters,
        });
    };

    const doneStatistics = useSelector(selectDoneStatistics);
    const rejectStatistics = useSelector(selectRejectStatistics);

    const aggregateAmounts = React.useMemo((): Record<AggregateEnum, number | null> => {
        const avgDoneShipperRate = doneStatistics?.aggregate.avgShipperRate;
        const avgRejectShipperRate = rejectStatistics?.aggregate.avgShipperRate;
        const avgCarrierRateEstimate = doneStatistics?.aggregate?.avgCarrierRateEstimate;
        const avgMarginRate = doneStatistics?.aggregate?.avgMarginRate;
        const avgCarrierRate = doneStatistics?.aggregate?.avgCarrierRate;

        const prepareValue = (value: number | undefined) => (isNil(value) ? null : value);

        return {
            [AggregateEnum.rejectedShipperRate]: prepareValue(avgRejectShipperRate),
            [AggregateEnum.acceptedShipperRate]: prepareValue(avgDoneShipperRate),
            [AggregateEnum.estimatedCarrierRate]: prepareValue(avgCarrierRateEstimate),
            [AggregateEnum.realCarrierRate]: prepareValue(avgCarrierRate),
            [AggregateEnum.margin]: prepareValue(avgMarginRate),
        };
    }, [doneStatistics, rejectStatistics]);

    const selecedDataSource =
        (queryFilters?.[QueryFiltersKeysEnum.dataSource] as StatisticSourceEnum) || DEFAULT_DATA_SOURCE;

    const [graphSettings, setGraphSettings] = React.useState<GraphSettingsT>({
        available: availablePresetMap[selecedDataSource],
        show: {
            [AggregateEnum.rejectedShipperRate]: true,
            [AggregateEnum.acceptedShipperRate]: true,
            [AggregateEnum.estimatedCarrierRate]: true,
            [AggregateEnum.realCarrierRate]: true,
            [AggregateEnum.margin]: true,
        },
    });

    React.useEffect(() => {
        setGraphSettings({
            ...graphSettings,
            available: availablePresetMap[selecedDataSource],
        });
    }, [selecedDataSource]);

    const aggregates = React.useMemo(() => {
        return [
            AggregateEnum.rejectedShipperRate,
            AggregateEnum.acceptedShipperRate,
            AggregateEnum.estimatedCarrierRate,
            AggregateEnum.realCarrierRate,
            AggregateEnum.margin,
        ].map((type) => ({
            type,
            label: t(AGGREGATE_LABEL_T[type]),
            color: AGGREGATE_COLOR[type],
            amount: aggregateAmounts[type],
        }));
    }, [aggregateAmounts, t]);

    const [selectedGranularity, setGranularity] = React.useState<StatisticGranularityEnum>(
        (): StatisticGranularityEnum => {
            const queryStatsGranularity = query[QueryKeysEnum.statsGranularity] as StatisticGranularityEnum;
            return queryStatsGranularity || StatisticGranularityEnum.week;
        },
    );
    React.useEffect(() => {
        changeQuery({
            [QueryKeysEnum.statsGranularity]: selectedGranularity,
        });
    }, [selectedGranularity]);

    const options: GranularityOptionT[] = React.useMemo(
        () => [
            {
                label: t('statistics.unit.month'),
                value: StatisticGranularityEnum.month,
            },
            {
                label: t('statistics.unit.week'),
                value: StatisticGranularityEnum.week,
            },
            {
                label: t('statistics.unit.day'),
                value: StatisticGranularityEnum.day,
            },
        ],
        [t],
    );

    const renderOption = (option?: GranularityOptionT, placeholder?: string): React.ReactNode => {
        if (!option) {
            return placeholder;
        }
        return <span className={cx('option')}>{option.label}</span>;
    };

    const [dates, setDates] = React.useState<DateRangeT | null>(() => {
        const queryStatisticsFrom = query[QueryKeysEnum.statsFrom];
        const queryStatisticsTo = query[QueryKeysEnum.statsTo];
        if (queryStatisticsFrom && queryStatisticsTo) {
            const parsedStatisticsFrom = moment(queryStatisticsFrom, DATE_FORMAT);
            const parsedStatisticsTo = moment(queryStatisticsTo, DATE_FORMAT);
            if (
                parsedStatisticsFrom.isValid() &&
                parsedStatisticsTo.isValid() &&
                parsedStatisticsTo.isAfter(parsedStatisticsFrom)
            ) {
                return {
                    from: parsedStatisticsFrom.toDate(),
                    to: moment(queryStatisticsTo, DATE_FORMAT).toDate(),
                };
            }
        }

        const defaultDateFrom = moment().toDate();
        const defaultDateTo = moment().add(1, 'month').toDate();

        return {
            from: defaultDateFrom,
            to: defaultDateTo,
        };
    });

    React.useEffect(() => {
        if (!dates?.from || !dates?.to) {
            return;
        }

        changeQuery({
            [QueryKeysEnum.statsTo]: getDateFromDate(dates.to),
            [QueryKeysEnum.statsFrom]: getDateFromDate(dates.from),
        });
    }, [dates]);

    React.useEffect(() => {
        const query = prepareFetchQuery(queryFilters, selectedGranularity, dates);
        if (!query) {
            return;
        }

        dispatch(fetchStatistics(query));
    }, [queryFilters, selectedGranularity, dates]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchQuery(queryFilters, selectedGranularity, dates);
        if (!query) {
            return;
        }

        dispatch(fetchStatistics(query, { isForceUpdate: false }));
    }, [queryFilters, selectedGranularity, dates]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const doneStatisticsStatus = useSelector(selectDoneStatisticsStatus);
    const rejectStatisticsStatus = useSelector(selectRejectStatisticsStatus);

    const isLoading = doneStatisticsStatus.loading || rejectStatisticsStatus.loading;
    const isError = doneStatisticsStatus.error || rejectStatisticsStatus.error;
    const isEmptyData = !isError && !isLoading && !doneStatistics?.series?.length && !rejectStatistics?.series?.length;

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.statistics')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent title={t('statistics.title')} rightNode={<NotificationsBarTrigger />} />
                </TopBar>
                <div>
                    <div className={cx('actions')}>
                        <FiltersTrigger
                            title={t('statistics.filters.trigger')}
                            isActive={isOpenedFilters}
                            onClick={showFilters}
                        />
                    </div>
                    <div className={cx('filter-tags')}>
                        <SelectedFilters
                            isCompact
                            queryFilters={queryFilters}
                            setQueryFilters={handleSetQueryFilters}
                        />
                    </div>
                    <Widget
                        className={cx('widget')}
                        title={t('statistics.sections.rate-history')}
                        rightNode={
                            <>
                                <DropdownInput<GranularityOptionT, GranularityOptionT['value']>
                                    className={cx('unit')}
                                    selectedValue={selectedGranularity}
                                    options={options}
                                    onSelect={setGranularity}
                                    renderLeftIcon={() => (
                                        <LinearScaleIcon strokeColor={StyleGuideColorsEnum.brandDark} />
                                    )}
                                    renderOption={renderOption}
                                    getOptionValue={(option) => option.value}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                />
                                <TwinDatePicker
                                    className={cx('dates')}
                                    value={dates}
                                    hasYearMonthForm
                                    placeholder={t('statistics.empty-dates')}
                                    onChange={setDates}
                                    overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                    hasClearControl
                                    renderLeftIcon={(isEmpty) => (
                                        <CalendarIcon
                                            size={DEFAULT_ICON_SIZE}
                                            fillColor={
                                                isEmpty ? StyleGuideColorsEnum.white : StyleGuideColorsEnum.brandAccent
                                            }
                                            strokeColor={
                                                isEmpty ? StyleGuideColorsEnum.light : StyleGuideColorsEnum.brandDark
                                            }
                                        />
                                    )}
                                />
                            </>
                        }
                    >
                        {isEmptyData && <div className={cx('message')}>{t('common:no-data')}</div>}
                        {isError && <div className={cx('message')}>{t('common:something-went-wrong')}</div>}
                        {!isError && (
                            <div className={cx('widget__inner')}>
                                <div className={cx('widget__aggregates')}>
                                    {aggregates.map((aggregate) => {
                                        const isAvailable = graphSettings.available[aggregate.type];
                                        const isChecked = graphSettings.show[aggregate.type];
                                        const hasAmount = !isNil(aggregate.amount);

                                        return (
                                            <div className={cx('aggregate')} key={aggregate.type}>
                                                <Checkbox
                                                    className={cx('aggregate__checkbox')}
                                                    isDisabled={!isAvailable}
                                                    checked={isChecked}
                                                    onChange={() => {
                                                        setGraphSettings({
                                                            ...graphSettings,
                                                            show: {
                                                                ...graphSettings.show,
                                                                [aggregate.type]: !isChecked,
                                                            },
                                                        });
                                                    }}
                                                    label={
                                                        <div className={cx('aggregate__label')}>
                                                            {aggregate.color ? (
                                                                <CircleIcon
                                                                    borderColor={aggregate.color}
                                                                    className={cx('aggregate__circle')}
                                                                />
                                                            ) : null}
                                                            <span className={cx('aggregate__text')}>
                                                                {aggregate.label}
                                                            </span>
                                                        </div>
                                                    }
                                                />
                                                <div className={cx('aggregate__price')}>
                                                    {isAvailable && !hasAmount && <InlineLoader lineHeight={20} />}
                                                    {isAvailable && hasAmount && (
                                                        <Money amount={aggregate.amount} currency={CurrencyEnum.EUR} />
                                                    )}
                                                    {!isAvailable && (
                                                        <div className={cx('not-number')}>{t('common:not-number')}</div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div>
                                    <StatisticsChart
                                        settings={graphSettings}
                                        granularity={selectedGranularity}
                                        doneStatistics={doneStatistics}
                                        rejectStatistics={rejectStatistics}
                                    />
                                </div>
                                {isLoading && <LoaderOverlay />}
                            </div>
                        )}
                    </Widget>
                </div>
            </ContentMargins>
            <SideBars />
        </ScrollableContent>
    );
});

export default StatisticsPage;
