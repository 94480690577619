import { FetchInvoicesPageQueryT, BrokerInvoiceT, DepreactedPagingStateT } from './models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_INVOICES_PAGE_REQUEST = 'broker-admin/invoices/FETCH_INVOICES_PAGE_REQUEST';
export const FETCH_INVOICES_PAGE_REQUEST_BEGIN = 'broker-admin/invoices/FETCH_INVOICES_PAGE_REQUEST_BEGIN';
export const FETCH_INVOICES_PAGE_REQUEST_ERROR = 'broker-admin/invoices/FETCH_INVOICES_PAGE_REQUEST_ERROR';
export const FETCH_INVOICES_PAGE_REQUEST_SUCCESS = 'broker-admin/invoices/FETCH_INVOICES_PAGE_REQUEST_SUCCESS';
export const RESET_INVOICES = 'broker-admin/invoices/RESET_INVOICES';

export type InvoicesStateT = PaginationStateT<BrokerInvoiceT, FetchInvoicesPageQueryT> & {
    pagingState: DepreactedPagingStateT | null;
};

export type FetchInvoicesPageActionT = {
    type: typeof FETCH_INVOICES_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchInvoicesPageQueryT;
    options?: FetchOptionsT;
};

export type FetchInvoicesPageBeginActionT = {
    type: typeof FETCH_INVOICES_PAGE_REQUEST_BEGIN;
    query: FetchInvoicesPageQueryT;
    pageNumber: PageNumberT;
};

export type FetchInvoicesPageSuccessActionT = {
    type: typeof FETCH_INVOICES_PAGE_REQUEST_SUCCESS;
    query: FetchInvoicesPageQueryT;
    pageResponse: PageResponseT<BrokerInvoiceT> | null;
    pageNumber: PageNumberT;
    pagingState: DepreactedPagingStateT | null;
};

export type FetchInvoicesPageErrorActionT = {
    type: typeof FETCH_INVOICES_PAGE_REQUEST_ERROR;
    query: FetchInvoicesPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
};

export type ResetInvoicesActionT = {
    type: typeof RESET_INVOICES;
    mutationListOptions: MutationListOptionsT;
};

export type InvoicesActionT =
    | FetchInvoicesPageActionT
    | FetchInvoicesPageBeginActionT
    | FetchInvoicesPageSuccessActionT
    | FetchInvoicesPageErrorActionT
    | ResetInvoicesActionT;
