import * as React from 'react';
import AdditionalServiceLabel from 'common/components/AdditionalServiceLabel/AdditionalServiceLabel';
import { DictAdditionalServiceEnumT } from 'common/store/additional-services-dict/models';

type PropsT = {
    enumTypes: Array<DictAdditionalServiceEnumT | undefined | null>;
};

const AsyncEmissionStandartAdditionalServicesFormatter: React.FC<PropsT> = React.memo((props) => {
    const { enumTypes } = props;

    return (
        <span>
            {enumTypes.map((enumType, index) => {
                if (!enumType) {
                    return null;
                }

                return (
                    <>
                        <AdditionalServiceLabel enumType={enumType} />
                        {!!enumTypes[index + 1] && <span>{', '}</span>}
                    </>
                );
            })}
        </span>
    );
});

export default AsyncEmissionStandartAdditionalServicesFormatter;
