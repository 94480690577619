import { ApiRuleDraftT } from 'common/utils/api/models';
import { FieldsEnum, FormValuesT, RepeatTypeEnum } from './constants';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import { getDateFromDate, MS_IN_DAY } from 'common/utils/time';
import { WEEK_DAYS_ORDER, ZIP_CODES_SEPARATOR } from 'common/constants';
import isNil from 'lodash/isNil';
import { ALL_SHIPPERS_ID } from 'common/store/shipper-suggest/constants';
import { RepeatStrategyTypeEnum } from 'broker-admin/utils/api/broker-tranziit/models';

const prepareApiRule = (formValues: FormValuesT): ApiRuleDraftT | null => {
    let repeatDays: Array<number> = [];

    if (formValues[FieldsEnum.repeatType] === RepeatTypeEnum.days) {
        repeatDays = formValues[FieldsEnum.days].map((day) => WEEK_DAYS_ORDER.indexOf(day) + 1);
    }

    if (formValues[FieldsEnum.repeatType] === RepeatTypeEnum.dates) {
        const dates = formValues[FieldsEnum.dates];

        if (dates?.from && !dates?.to) {
            repeatDays.push(+dates.from);
        }

        if (!dates?.from && dates?.to) {
            repeatDays.push(+dates.to);
        }

        if (dates?.from && dates?.to) {
            const daysCount = (+dates.to - +dates.from) / MS_IN_DAY;

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i <= daysCount; i++) {
                repeatDays.push(+dates.from + MS_IN_DAY * i);
            }
        }
    }

    const repeatMode = formValues[FieldsEnum.repeatStrategy] || RepeatStrategyTypeEnum.none;

    const validTill = formValues[FieldsEnum.validTill];
    const formattedValidTill = validTill ? `${getDateFromDate(validTill)}T23:59:59.000Z` : null;

    const validFrom = formValues[FieldsEnum.validFrom];
    if (!validFrom) {
        return null;
    }

    const parsedImpactValue = parseSimpleNumber(formValues[FieldsEnum.impactValue]);
    if (isNil(parsedImpactValue)) {
        return null;
    }

    const companyIds: string[] = [];
    const shipperId = formValues[FieldsEnum.shipperId];
    if (shipperId && shipperId !== ALL_SHIPPERS_ID) {
        companyIds.push(shipperId);
    }

    return {
        name: formValues[FieldsEnum.name],
        group: formValues[FieldsEnum.group] as string,

        countriesFrom: formValues[FieldsEnum.fromCountryCodes],
        zipsFrom: formValues[FieldsEnum.fromCountryZipCodes].split(ZIP_CODES_SEPARATOR).filter(Boolean),
        countriesTo: formValues[FieldsEnum.toCountryCodes],
        zipsTo: formValues[FieldsEnum.toCountryZipCodes].split(ZIP_CODES_SEPARATOR).filter(Boolean),

        repeatDays,
        repeatMode,

        validFrom: `${getDateFromDate(validFrom)}T00:00:00.000Z`,
        validTill: formattedValidTill || undefined,

        targetPrice: formValues[FieldsEnum.impactPriceComponent],
        value: parsedImpactValue,
        targetPriceValueType: formValues[FieldsEnum.impactStrategy],

        trailerTypeIds: formValues[FieldsEnum.trailerTypeIds].map((trailerTypeId) => +trailerTypeId),

        companyIds,
    };
};

export default prepareApiRule;
