import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerDetailsPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    lockPartner,
    unlockPartner,
    unVerifyPartnerDetails,
    verifyPartnerDetails,
} from 'broker-admin/store/partner/details/actions';
import VerifyPartnerAdressConfirmation from './dialogs/VerifyPartnerAdressConfirmation/VerifyPartnerAdressConfirmation';
import { VerifyPartnerAdressConfirmationDataT } from './dialogs/VerifyPartnerAdressConfirmation/models';
import UnVerifyPartnerAdressConfirmation from './dialogs/UnVerifyPartnerAdressConfirmation/UnVerifyPartnerAdressConfirmation';
import { UnVerifyPartnerAdressConfirmationDataT } from './dialogs/UnVerifyPartnerAdressConfirmation/models';
import LockPartnerConfirmation from './dialogs/LockPartnerConfirmation/LockPartnerConfirmation';
import { LockPartnerConfirmationDataT } from './dialogs/LockPartnerConfirmation/models';
import UnlockPartnerConfirmation from './dialogs/UnlockPartnerConfirmation/UnlockPartnerConfirmation';
import { UnlockPartnerConfirmationDataT } from './dialogs/UnlockPartnerConfirmation/models';
import PartnerDetailsLayout from './PartnerDetailsLayout/PartnerDetailsLayout';
import PartnerDetailsForm from './PartnerDetailsForm/PartnerDetailsForm';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import {
    selectLockPartnerDetailsRequest,
    selectPartnerDetailsById,
    selectUpdatePartnerDetailsRequest,
    selectVerifyPartnerDetailsRequest,
} from 'broker-admin/store/partner/details/selectors';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { selectPermissions } from 'common/store/auth/selectors';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cx = classNames.bind(styles);

type PropsT = {};

const PartnerDetailsPage: React.FC<PropsT> = React.memo((props) => {
    const { partnerId } = usePartnerContext();

    const partnerDetailsById = useSelector(selectPartnerDetailsById);
    const partnerDetails = partnerDetailsById[partnerId];

    const verifyPartnerConfirmation = useModalDialog<VerifyPartnerAdressConfirmationDataT>();
    const unverifyPartnerConfirmation = useModalDialog<UnVerifyPartnerAdressConfirmationDataT>();
    const lockPartnerConfirmation = useModalDialog<LockPartnerConfirmationDataT>();
    const unlockPartnerConfirmation = useModalDialog<UnlockPartnerConfirmationDataT>();

    const dispatch = useDispatch();

    const handleVerifyPartner = React.useCallback(
        (data: VerifyPartnerAdressConfirmationDataT | null): void => {
            if (!data) {
                return;
            }

            const { partnerId, addressType } = data;

            dispatch(verifyPartnerDetails(partnerId, addressType));
        },
        [dispatch],
    );

    const handleUnverifyPartner = React.useCallback(
        (data: UnVerifyPartnerAdressConfirmationDataT | null): void => {
            if (!data) {
                return;
            }

            const { partnerId, addressType } = data;

            dispatch(unVerifyPartnerDetails(partnerId, addressType));
        },
        [dispatch],
    );

    const handleLockPartner = React.useCallback(
        (data: LockPartnerConfirmationDataT | null): void => {
            if (!data) {
                return;
            }

            const { partnerId } = data;

            dispatch(lockPartner(partnerId));
        },
        [dispatch],
    );

    const handleUnlockPartner = React.useCallback(
        (data: UnlockPartnerConfirmationDataT | null): void => {
            if (!data) {
                return;
            }

            const { partnerId } = data;

            dispatch(unlockPartner(partnerId));
        },
        [dispatch],
    );

    const uploadRequest = useSelector(selectUpdatePartnerDetailsRequest);
    const lockRequest = useSelector(selectLockPartnerDetailsRequest);
    const verifyRequest = useSelector(selectVerifyPartnerDetailsRequest);

    useCloseModalDialogAfterRequest(verifyRequest, [verifyPartnerConfirmation, unverifyPartnerConfirmation]);

    useCloseModalDialogAfterRequest(lockRequest, [lockPartnerConfirmation, unlockPartnerConfirmation]);

    const isShowLoaderOverlay = uploadRequest.loading || lockRequest.loading || verifyRequest.loading;

    const permissions = useSelector(selectPermissions);

    return (
        <>
            {permissions?.canEditPartnerDetails ? (
                <PartnerDetailsForm
                    partnerDetails={partnerDetails}
                    onVerifyPartner={verifyPartnerConfirmation.setData}
                    onUnverifyPartner={unverifyPartnerConfirmation.setData}
                    onLockPartner={lockPartnerConfirmation.setData}
                    onUnlockPartner={unlockPartnerConfirmation.setData}
                />
            ) : (
                <PartnerDetailsLayout partnerDetails={partnerDetails} />
            )}
            {isShowLoaderOverlay && <LoaderOverlay />}
            <VerifyPartnerAdressConfirmation
                data={verifyPartnerConfirmation.data}
                onClose={verifyPartnerConfirmation.onClose}
                onCancel={verifyPartnerConfirmation.onCancel}
                onConfirm={handleVerifyPartner}
                requestStatus={verifyRequest}
            />
            <UnVerifyPartnerAdressConfirmation
                data={unverifyPartnerConfirmation.data}
                onClose={unverifyPartnerConfirmation.onClose}
                onCancel={unverifyPartnerConfirmation.onCancel}
                onConfirm={handleUnverifyPartner}
                requestStatus={verifyRequest}
            />
            <LockPartnerConfirmation
                data={lockPartnerConfirmation.data}
                onClose={lockPartnerConfirmation.onClose}
                onCancel={lockPartnerConfirmation.onCancel}
                onConfirm={handleLockPartner}
                requestStatus={lockRequest}
            />
            <UnlockPartnerConfirmation
                data={unlockPartnerConfirmation.data}
                onClose={unlockPartnerConfirmation.onClose}
                onCancel={unlockPartnerConfirmation.onCancel}
                onConfirm={handleUnlockPartner}
                requestStatus={lockRequest}
            />
        </>
    );
});

export default PartnerDetailsPage;
