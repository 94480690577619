import {
    FETCH_SYSTEM_SETTINGS_REQUEST_BEGIN,
    FETCH_SYSTEM_SETTINGS_REQUEST_ERROR,
    FETCH_SYSTEM_SETTINGS_REQUEST_SUCCESS,
    SystemSettingsActionTypesT,
    SystemSettingsStateT,
    UPDATE_SYSTEM_SETTINGS_REQUEST_BEGIN,
    UPDATE_SYSTEM_SETTINGS_REQUEST_ERROR,
    UPDATE_SYSTEM_SETTINGS_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: SystemSettingsStateT = {
    updateRequest: requestStatus.initial,
    fetchRequest: requestStatus.initial,
    data: null,
};

export default (
    state = initialState,
    action: SystemSettingsActionTypesT | DestroySessionActionT,
): SystemSettingsStateT => {
    switch (action.type) {
        case FETCH_SYSTEM_SETTINGS_REQUEST_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }
        case FETCH_SYSTEM_SETTINGS_REQUEST_SUCCESS: {
            const { data } = action;

            return {
                ...state,
                data,
                fetchRequest: requestStatus.ok,
            };
        }

        case FETCH_SYSTEM_SETTINGS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                fetchRequest: requestStatus.setError(error),
            };
        }

        case UPDATE_SYSTEM_SETTINGS_REQUEST_BEGIN: {
            return {
                ...state,
                updateRequest: requestStatus.loading,
            };
        }
        case UPDATE_SYSTEM_SETTINGS_REQUEST_SUCCESS: {
            return {
                ...state,
                updateRequest: requestStatus.ok,
            };
        }

        case UPDATE_SYSTEM_SETTINGS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                updateRequest: requestStatus.setError(error),
            };
        }
        case DESTROY_SESSION: {
            return initialState;
        }
        default:
            return state;
    }
};
