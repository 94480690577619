import React from 'react';

import { PropsT as InputPropsT } from 'common/components/Input/Input';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { useDispatch, useSelector } from 'react-redux';
import { findCarriers } from 'common/store/carrier-suggest/actions';
import {
    selectCarriersById,
    selectCarriersIds,
    selectFindCarriersRequest,
} from 'common/store/carrier-suggest/selectors';
import { PartnerT } from 'common/store/carrier-suggest/models';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import CarrierIcon, { CarrierIconProps } from 'common/icons/CarrierIcon';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import { CountryCodeT } from 'common/utils/api/models';

type PartnerIdT = PartnerT['id'];

type ValueT = PartnerIdT | null;

type OptionT = {
    id: ValueT;
    name: string;
    number: string;
    countryCode: CountryCodeT | null;
};

export type PropsT = {
    className?: string;
    initialValue: ValueT;
    initialLabel: string;
    onSelect: (value: ValueT, name: string) => void;
    value: ValueT;
    overlayPosition: DropdownOverlayPositionEnum;
    hasError: InputPropsT['hasError'];
    hasWarning: InputPropsT['hasWarning'];
    placeholder: InputPropsT['placeholder'];
    onBlur: InputPropsT['onBlur'];
    onFocus: InputPropsT['onFocus'];
    isDisabled?: InputPropsT['isDisabled'];
    hasClearControl?: InputPropsT['hasClearControl'];
    hasChanges?: boolean;
};

const CarrierDropdown: React.FC<PropsT> = (props) => {
    const {
        className,
        initialValue,
        initialLabel,
        value,
        onSelect,
        hasWarning,
        hasError,
        overlayPosition,
        isDisabled,
        placeholder,
        onBlur,
        onFocus,
        hasChanges,
        hasClearControl,
    } = props;

    const [options, setOptions] = React.useState<OptionT[]>([]);

    const dispatch = useDispatch();

    const ids = useSelector(selectCarriersIds);
    const byId = useSelector(selectCarriersById);
    const requestStatus = useSelector(selectFindCarriersRequest);

    React.useEffect(() => {
        const options = ids
            .filter((id) => byId[id])
            .map((id): OptionT => {
                const partner = byId[id];

                return {
                    id: partner.id || null,
                    name: partner.name || '',
                    number: partner.number || '',
                    countryCode: partner.countryCode || null,
                };
            });

        setOptions(options);
    }, [ids, byId]);

    React.useEffect(() => {
        if (!initialValue) {
            return;
        }

        const initialOption: OptionT = {
            id: initialValue,
            name: initialLabel,
            countryCode: null,
            number: '',
        };

        setOptions([initialOption]);
    }, []);

    const handleChangeQuery = React.useCallback((query: string): void => {
        dispatch(findCarriers(query));
    }, []);

    const hasValue = !!value;

    const handleSelect = (value: ValueT) => {
        const partner = byId[value as string];

        onSelect(value || null, partner?.name || '');
    };

    const handleReset = () => {
        onSelect(null, '');
    };

    const renderOption = (option: OptionT | undefined, placeholder?: string): React.ReactNode => {
        if (!option) {
            return placeholder;
        }

        return (
            <DropdownControlOptionLabel
                withoutPaddings
                icon={option.countryCode ? <FlagIcon countryCode={option.countryCode} /> : null}
                label={option.name || ''}
                description={option.number || ''}
            />
        );
    };

    const getOptionValue = (option: OptionT): ValueT => option.id;

    return (
        <DropdownSearchInput<OptionT, ValueT>
            className={className}
            selectedValue={value}
            placeholder={placeholder}
            options={options}
            isDisabled={isDisabled}
            onSelect={handleSelect}
            hasError={hasError}
            hasWarning={hasWarning}
            onChangeQuery={handleChangeQuery}
            renderOption={renderOption}
            renderTrigger={renderOption}
            getOptionValue={getOptionValue}
            hasChanges={hasChanges}
            renderLeftIcon={(iconMeta) => <CarrierIcon {...CarrierIconProps.getControlProps(iconMeta)} />}
            onBlur={onBlur}
            onFocus={onFocus}
            overlayPosition={overlayPosition}
            isLoading={requestStatus.loading}
            onReset={hasClearControl ? handleReset : undefined}
        />
    );
};

export default CarrierDropdown;
