import requestStatus from 'common/utils/request-status';
import {
    CarrierSuggestActionT,
    CarrierSuggestStateT,
    FIND_CARRIERS_REQUEST_BEGIN,
    FIND_CARRIERS_REQUEST_ERROR,
    FIND_CARRIERS_REQUEST_SUCCESS,
    RESET,
} from './types';
import { PartnerT } from 'common/store/carrier-suggest/models';
import { isNonNil } from 'common/utils';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: CarrierSuggestStateT = {
    query: null,
    requestStatus: requestStatus.initial,
    ids: [],
    byId: {},
};

export default (state = initialState, action: CarrierSuggestActionT | DestroySessionActionT): CarrierSuggestStateT => {
    switch (action.type) {
        case FIND_CARRIERS_REQUEST_BEGIN: {
            const { query } = action;

            return {
                ...state,
                query,
                requestStatus: requestStatus.loading,
            };
        }

        case FIND_CARRIERS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                requestStatus: requestStatus.setError(error),
            };
        }

        case FIND_CARRIERS_REQUEST_SUCCESS: {
            const { partners } = action;

            const byId = partners.reduce<Record<string, PartnerT>>((acc, partner) => {
                if (!partner?.id) {
                    return acc;
                }

                return {
                    ...acc,
                    [partner.id]: {
                        ...partner,
                    },
                };
            }, {});

            const ids = partners.map((partner) => partner.id).filter(isNonNil);

            return {
                ...state,
                requestStatus: requestStatus.ok,
                ids,
                byId,
            };
        }

        case DESTROY_SESSION:
        case RESET: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
};
