import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkMaxValue, checkMinValue, checkNotEmpty } from 'common/utils/form-validators';
import values from 'lodash/values';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import isNumber from 'lodash/isNumber';

const REQUIRED_FIELDS = values<FieldsEnum>(FieldsEnum);

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkMinValue(
            t,
            [FieldsEnum.transportationOrderHasNoDriverAlertTime, FieldsEnum.transportationOrderHasNoAssetsAlertTime],
            values,
            1,
        ),
        ...checkMaxValue(
            t,
            [FieldsEnum.transportationOrderHasNoDriverAlertTime, FieldsEnum.transportationOrderHasNoAssetsAlertTime],
            values,
            480,
        ),
        ...checkMinValue(t, [FieldsEnum.assignDriverLimitAlert], values, 0),

        ...checkMinValue(t, [FieldsEnum.allowedGAPToBeDelayed], values, 0),
        ...checkMaxValue(t, [FieldsEnum.allowedGAPToBeDelayed], values, 300),

        ...checkMinValue(t, [FieldsEnum.deleteNotificationsOlderDays], values, 3),
        ...checkMaxValue(t, [FieldsEnum.deleteNotificationsOlderDays], values, 365),

        ...checkMinValue(t, [FieldsEnum.docExpiresSoonAlert], values, 0),
        ...checkMaxValue(t, [FieldsEnum.docExpiresSoonAlert], values, 30),

        ...checkMinValue(t, [FieldsEnum.radiusAroundWaypointM], values, 100),
        ...checkMaxValue(t, [FieldsEnum.radiusAroundWaypointM], values, 50000),

        ...checkMinValue(
            t,
            [FieldsEnum.timeLimitToCancelOrderHours, FieldsEnum.timeLimitToCancelTransportationOrderHours],
            values,
            0,
        ),
        ...checkMaxValue(
            t,
            [FieldsEnum.timeLimitToCancelOrderHours, FieldsEnum.timeLimitToCancelTransportationOrderHours],
            values,
            48,
        ),
    };

    const assignDriverLimitAlert = values[FieldsEnum.assignDriverLimitAlert];
    const noDriverAlert = values[FieldsEnum.transportationOrderHasNoDriverAlertTime];
    if (assignDriverLimitAlert && noDriverAlert) {
        const parsedAssignDriverLimitAlert = parseSimpleNumber(assignDriverLimitAlert);
        const parsedNoDriverAlert = parseSimpleNumber(noDriverAlert);

        if (
            isNumber(parsedNoDriverAlert) &&
            isNumber(parsedAssignDriverLimitAlert) &&
            parsedAssignDriverLimitAlert > parsedNoDriverAlert
        ) {
            errors[FieldsEnum.assignDriverLimitAlert] = t('price-settings.alerts-form.errors.lateNoDriverAlert');
        }
    }

    const assignAssetLimitAlert = values[FieldsEnum.assignAssetLimitAlert];
    const noAssetAlert = values[FieldsEnum.transportationOrderHasNoAssetsAlertTime];
    if (assignAssetLimitAlert && noAssetAlert) {
        const parsedAssignAssetLimitAlert = parseSimpleNumber(assignAssetLimitAlert);
        const parsedNoAssetAlert = parseSimpleNumber(noAssetAlert);

        if (
            isNumber(parsedNoAssetAlert) &&
            isNumber(parsedAssignAssetLimitAlert) &&
            parsedAssignAssetLimitAlert > parsedNoAssetAlert
        ) {
            errors[FieldsEnum.assignAssetLimitAlert] = t('price-settings.alerts-form.errors.lateNoAssetAlert');
        }
    }

    return errors;
};

export default validate;
