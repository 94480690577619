import { AdditionalServicesStateT } from './types';
import { StateT } from 'broker-admin/store/models';

export const selectAdditionalServices = (state: StateT): AdditionalServicesStateT['list'] =>
    state.priceSettings.additionalServices.list;

export const selectUpdateAdditionalServiceRequest = (state: StateT): AdditionalServicesStateT['updateRequest'] =>
    state.priceSettings.additionalServices.updateRequest;

export const selectFetchAdditionalServicesRequest = (state: StateT): AdditionalServicesStateT['fetchRequest'] =>
    state.priceSettings.additionalServices.fetchRequest;
