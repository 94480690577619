import * as React from 'react';

import classNames from 'classnames/bind';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';

import styles from './EditableCostCell.scss';
import Money from 'common/components/Money/Money';
import { CURRENCY_SYMBOLS, CurrencyEnum } from 'common/constants';
import Input from 'common/components/Input/Input';
import { getMoneyMask } from 'common/utils/input-masks';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import { useTranslation } from 'react-i18next';

type PropsT = {
    isHoveredRow: boolean;
    cost: number | null | undefined;
    onUpdate: (cost: number) => void;
};

const cx = classNames.bind(styles);

const moneyMask = getMoneyMask();

const ENTER_KEY = 'Enter';

const costToString = (cost: number | null | undefined) => {
    return isNumber(cost) ? String(cost) : '';
};

const EditableCostCell: React.FC<PropsT> = React.memo((props) => {
    const { isHoveredRow, cost, onUpdate } = props;

    const { t } = useTranslation();

    const [isHoveredCell, setHoveredCell] = React.useState<boolean>(false);

    const [inputValue, setInputValue] = React.useState<string>(costToString(cost));

    React.useEffect(() => {
        if (!isHoveredRow) {
            setHoveredCell(false);
        }
    }, [isHoveredRow]);

    const handleMouseMove = () => {
        setInputValue(costToString(cost));
        setHoveredCell(true);
    };

    const handleMouseLeave = () => {
        setHoveredCell(false);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const parsedInputValue = parseSimpleNumber(inputValue) || 0;
        const isValid = isNumber(parsedInputValue) && !Number.isNaN(parsedInputValue);
        const isSameValue = parsedInputValue === cost;
        if (event.key === ENTER_KEY && isValid && !isSameValue) {
            onUpdate(parsedInputValue);
        }
    };

    const isShowInput = isHoveredCell && isHoveredRow;

    return (
        <div className={cx('cost')} onMouseLeave={handleMouseLeave}>
            {isShowInput && (
                <Input
                    name="cost"
                    value={inputValue}
                    mask={moneyMask}
                    rightNode={CURRENCY_SYMBOLS.EUR}
                    onChange={setInputValue}
                    onKeyUp={handleKeyUp}
                />
            )}
            {!isShowInput && (
                <div onMouseMove={handleMouseMove}>
                    {!isNil(cost) && <Money amount={cost} className={cx('price')} currency={CurrencyEnum.EUR} />}
                    {isNil(cost) && <div className={cx('empty')}>{t('common:null-cost')}</div>}
                </div>
            )}
        </div>
    );
});

export default EditableCostCell;
