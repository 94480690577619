import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonFormatter from 'design-system/components/InfoTable/formatters/SkeletonFormatter/SkeletonFormatter';
import { fetchSpotRequestDetails } from 'broker-admin/store/spot-request-details/actions';
import { selectSpotRequestDetails } from 'broker-admin/store/spot-request-details/selectors';
import SpotRequestStatusPill from 'common/components/status-pill/SpotRequestStatusPill/SpotRequestStatusPill';

type PropsT = {
    spotRequestId: SpotRequestIdT | null;
};

const AsyncSpotRequestDetailsStatusFormatter: React.FC<PropsT> = React.memo((props) => {
    const { spotRequestId } = props;

    const dispatch = useDispatch();

    const { fetchDetailsRequestStatus, details } = useSelector(selectSpotRequestDetails(spotRequestId));

    useEffect(() => {
        if (!spotRequestId) {
            return;
        }

        dispatch(fetchSpotRequestDetails(spotRequestId));
    }, [spotRequestId]);

    if (fetchDetailsRequestStatus.loading || !details) {
        return <SkeletonFormatter />;
    }

    return <SpotRequestStatusPill isSymmetrical status={details.status} />;
});

export default AsyncSpotRequestDetailsStatusFormatter;
