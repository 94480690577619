import { FieldsEnum, FormValuesT, RepeatTypeEnum } from './constants';
import { RuleDetailsT } from 'broker-admin/store/price-settings/rules/models';
import { RuleImpactStrategyEnum, WEEK_DAYS_ORDER, ZIP_CODES_SEPARATOR } from 'common/constants';
import { clearDateTimezone } from 'common/utils/time';
import { ALL_SHIPPERS_ID } from 'common/store/shipper-suggest/constants';
import { RepeatStrategyTypeEnum, PriceComponentTypeEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import { PriceSettingsRuleGroupEnum } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/constants';
import { CountryCodeT } from 'common/store/countries-dict/models';

const prepareZipCodes = (zipCodes?: string[]): string => {
    return (zipCodes || []).join(ZIP_CODES_SEPARATOR);
};

const prepareCodes = (codes: string[]): CountryCodeT[] => {
    return codes.map((code) => code.toUpperCase() as CountryCodeT);
};

const prepareValidDate = (validDate?: string): Date | null => {
    const clearedDate = clearDateTimezone(validDate);
    return clearedDate ? new Date(clearedDate) : null;
};

const getInitialValues = (
    editableRuleDetails: RuleDetailsT | null,
    allTrailerDictTypeIds: Array<TrailerDictIdT>,
): FormValuesT => {
    const isNewRule = !editableRuleDetails;
    const loadedTrailerDict = !!allTrailerDictTypeIds?.length;

    const ruleTrailerTypeIds = (editableRuleDetails?.trailerTypeIds || []).map((value) => String(value));

    const values: FormValuesT = {
        [FieldsEnum.id]: editableRuleDetails?.id || null,
        [FieldsEnum.name]: editableRuleDetails?.name || '',
        [FieldsEnum.group]: (editableRuleDetails?.group as PriceSettingsRuleGroupEnum) || null,

        [FieldsEnum.fromCountryCodes]: prepareCodes(editableRuleDetails?.countriesFrom || []),
        [FieldsEnum.fromCountryZipCodes]: prepareZipCodes(editableRuleDetails?.zipsFrom),

        [FieldsEnum.toCountryCodes]: prepareCodes(editableRuleDetails?.countriesTo || []),
        [FieldsEnum.toCountryZipCodes]: prepareZipCodes(editableRuleDetails?.zipsTo),

        [FieldsEnum.dates]: {
            from: null,
            to: null,
        },
        [FieldsEnum.days]: [],
        [FieldsEnum.repeatType]: RepeatTypeEnum.dates,
        [FieldsEnum.repeatStrategy]: RepeatStrategyTypeEnum.none,

        [FieldsEnum.validFrom]: new Date(),
        [FieldsEnum.validTill]: null,

        [FieldsEnum.impactPriceComponent]:
            (editableRuleDetails?.targetPrice as PriceComponentTypeEnum) || PriceComponentTypeEnum.costOfOperation,
        [FieldsEnum.impactValue]: '+',
        [FieldsEnum.impactStrategy]:
            (editableRuleDetails?.targetPriceValueType as RuleImpactStrategyEnum) || RuleImpactStrategyEnum.percent,

        [FieldsEnum.shipperId]: editableRuleDetails?.companyIds[0] || ALL_SHIPPERS_ID,
        [FieldsEnum.trailerTypeIds]: isNewRule && loadedTrailerDict ? allTrailerDictTypeIds : ruleTrailerTypeIds,
    };

    if (editableRuleDetails?.validFrom) {
        values[FieldsEnum.validFrom] = prepareValidDate(editableRuleDetails.validFrom);
    }

    if (editableRuleDetails?.validTill) {
        values[FieldsEnum.validTill] = prepareValidDate(editableRuleDetails.validTill);
    }

    if (editableRuleDetails?.value) {
        const valueSign = editableRuleDetails.value > 0 ? '+' : '-';
        values[FieldsEnum.impactValue] = `${valueSign}${Math.abs(editableRuleDetails.value)}`;
    }

    if (!editableRuleDetails) {
        return values;
    }

    values[FieldsEnum.repeatStrategy] = (editableRuleDetails.repeatMode ||
        RepeatStrategyTypeEnum.none) as RepeatStrategyTypeEnum;

    const { repeatDays } = editableRuleDetails;
    const isDaysRange = repeatDays.every((value) => value < 10);
    if (isDaysRange) {
        values[FieldsEnum.repeatType] = RepeatTypeEnum.days;
        values[FieldsEnum.days] = repeatDays.map((dayNumber) => WEEK_DAYS_ORDER[dayNumber - 1]);
    }

    const isDatesRange = repeatDays.every((value) => value > 10);
    if (isDatesRange) {
        values[FieldsEnum.repeatType] = RepeatTypeEnum.dates;
        const minValue = repeatDays[0];
        const startDate = new Date(minValue);

        const maxValue = repeatDays[repeatDays.length - 1];
        const endDate = new Date(maxValue);

        if (minValue && !maxValue) {
            values[FieldsEnum.dates] = {
                from: startDate,
                to: null,
            };
        }

        if (!minValue && maxValue) {
            values[FieldsEnum.dates] = {
                from: endDate,
                to: null,
            };
        }

        if (minValue && maxValue) {
            values[FieldsEnum.dates] = {
                from: startDate,
                to: endDate,
            };
        }
    }

    return values;
};

export default getInitialValues;
