import * as React from 'react';
import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent, {
    HeaderSidebarActionT,
} from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import { fetchSpotRequestDetails } from 'broker-admin/store/spot-request-details/actions';
import { selectSpotRequestDetails } from 'broker-admin/store/spot-request-details/selectors';
import SpotRequestDetails from 'broker-admin/components/BaseSpotRequestDetailsSidebarContent/SpotRequestDetails/SpotRequestDetails';
import SpotRequestStatusPill from 'common/components/status-pill/SpotRequestStatusPill/SpotRequestStatusPill';
import EntitySidebarSkeleton from 'common/components/order-details/EntitySidebarSkeleton/EntitySidebarSkeleton';
import HeaderSideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLoader/HeaderSideBarLoader';
import SideBarLayoutContextWrap from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import MapTrigger, { MapTriggerPropsT } from 'common/components/MapTrigger/MapTrigger';
import { fetchDispatchDetails } from 'broker-admin/store/dispatch-details/actions';
import {
    selectDispatchDetails,
    selectDispatchDetailsRequestStatus,
} from 'broker-admin/store/dispatch-details/selectors';
import {
    ApiSpotRequestStatusT,
    SpotBidT,
    SpotRequestStatStatusEnum,
    SpotRequestStatusEnum,
} from 'broker-admin/utils/api/spot-broker-tranziit/models';
import { logWarning } from 'common/utils/logger';
import StartSelectBidFooterContent from './footers/StartSelectBidFooterContent/StartSelectBidFooterContent';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import { BidStatusEnum } from 'carrier/utils/api/spot-carrier-tranziit/models';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { StyleGuideColorsEnum } from 'common/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import DeleteIcon from 'common/icons/DeleteIcon';
import { useTranslation } from 'react-i18next';
import { cancelSpotRequest } from 'broker-admin/store/spot/actions';
import { selectSpotUpdateRequestStatus } from 'broker-admin/store/spot/selectors';

type PropsT = {
    id: SpotRequestIdT;
    onClose: (() => void) | null | undefined;
    onOpenUserDetails: (params: {
        partnerType: PartnerTypeEnum;
        partnerId: PartnerIdT;
        userId: UserIdT | null;
    }) => void;
} & MapTriggerPropsT;

const shouldSelectBidSet = new Set<ApiSpotRequestStatusT>([SpotRequestStatStatusEnum.accepted]);

const BaseSpotRequestDetailsSidebarContent: React.FC<PropsT> = React.memo((props) => {
    const { id, onClose, isActiveMapTrigger, toggleMap, onOpenUserDetails } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [selectedBidId, selectBidId] = React.useState<BidIdT | null>(null);

    React.useEffect(() => {
        dispatch(fetchSpotRequestDetails(id));
    }, [id]);

    const { details, fetchDetailsRequestStatus, updateRequestStatus } = useSelector(selectSpotRequestDetails(id));

    const dispatchId = details?.dispatchId || null;
    React.useEffect(() => {
        if (!dispatchId) {
            return;
        }

        dispatch(fetchDispatchDetails(dispatchId));
    }, [dispatchId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchSpotRequestDetails(id));

        if (!dispatchId) {
            return;
        }

        dispatch(fetchDispatchDetails(dispatchId));
    }, [id, dispatchId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const dispatchDetails = useSelector(selectDispatchDetails(dispatchId || null));
    const dispatchDetailsRequestStatus = useSelector(selectDispatchDetailsRequestStatus(dispatchId || null));
    const spotUpdateRequestStatus = useSelector(selectSpotUpdateRequestStatus);

    const isShowOverlayLoader =
        fetchDetailsRequestStatus.loading || dispatchDetailsRequestStatus.loading || spotUpdateRequestStatus.loading;

    const isShowSkeleton = !details || !dispatchDetails;

    const handleSelectBid = React.useCallback((bid: SpotBidT) => {
        if (!bid?.id) {
            logWarning('empty bid.id!');
            return;
        }

        selectBidId(bid.id);
    }, []);

    const wonBidId = useMemo((): BidIdT | null => {
        const wonBid = (details?.bids || []).find((bid) => {
            return bid.status === BidStatusEnum.won;
        });

        return wonBid?.id || null;
    }, [details]);

    useEffect(() => {
        if (wonBidId !== selectedBidId) {
            selectBidId(wonBidId);
        }
    }, [wonBidId]);

    const isShouldSelectBid = details?.status && shouldSelectBidSet.has(details.status);

    const renderFooterNode = (): React.ReactNode => {
        if (isShouldSelectBid && selectedBidId) {
            return (
                <FooterSideBarLayout hasPaddings>
                    <StartSelectBidFooterContent
                        spotRequestId={id}
                        selectedBidId={selectedBidId}
                        details={details}
                        updateRequestStatus={updateRequestStatus}
                    />
                </FooterSideBarLayout>
            );
        }

        return null;
    };

    const headerDropdownOptions = React.useMemo((): Array<HeaderSidebarActionT> => {
        if (details?.status === SpotRequestStatusEnum.open || details?.status === SpotRequestStatusEnum.accepted) {
            return [
                {
                    label: (
                        <DropdownControlOptionLabel
                            icon={<DeleteIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                            label={t('spot-request-details.actions.cancel')}
                        />
                    ),
                    onSelect: () => {
                        dispatch(cancelSpotRequest(id));
                    },
                },
            ];
        }

        return [];
    }, [details]);

    return (
        <SideBarLayoutContextWrap>
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    title={details?.number || null}
                    afterTitleNode={<SpotRequestStatusPill isSymmetrical status={details?.status} />}
                    beforeDropdownNode={<MapTrigger isActiveMapTrigger={isActiveMapTrigger} toggleMap={toggleMap} />}
                    testSelector="spot-request-details"
                    onClose={onClose}
                    dropdownOptions={headerDropdownOptions}
                />
                <HeaderSideBarLoader isShow={isShowOverlayLoader} />
            </HeaderSideBarLayout>
            <SideBarLayout testSelector="spot-request-details">
                {isShowSkeleton ? (
                    <EntitySidebarSkeleton />
                ) : (
                    <SpotRequestDetails
                        id={id}
                        selectedBidId={selectedBidId}
                        isDisabledSelectBid={!isShouldSelectBid}
                        onSelectBid={handleSelectBid}
                        onOpenUserDetails={onOpenUserDetails}
                    />
                )}
            </SideBarLayout>
            {renderFooterNode()}
        </SideBarLayoutContextWrap>
    );
});

export default BaseSpotRequestDetailsSidebarContent;
