import {
    FETCH_PARTNERS_STATS_REQUEST,
    FETCH_PARTNERS_STATS_REQUEST_BEGIN,
    FETCH_PARTNERS_STATS_REQUEST_ERROR,
    FETCH_PARTNERS_STATS_REQUEST_SUCCESS,
    FetchPartnersStatsActionT,
    FetchPartnersStatsBeginActionT,
    FetchPartnersStatsErrorActionT,
    FetchPartnersStatsSuccessActionT,
} from './types';
import { PartnersStatsT } from './models';

export const fetchPartnersStats = (options?: FetchOptionsT): FetchPartnersStatsActionT => ({
    type: FETCH_PARTNERS_STATS_REQUEST,
    options,
});

export const fetchPartnersStatsBegin = (): FetchPartnersStatsBeginActionT => ({
    type: FETCH_PARTNERS_STATS_REQUEST_BEGIN,
});

export const createPartnerRequestError = (error: Error): FetchPartnersStatsErrorActionT => ({
    type: FETCH_PARTNERS_STATS_REQUEST_ERROR,
    error,
});

export const fetchPartnersStatsSuccess = (stats: PartnersStatsT): FetchPartnersStatsSuccessActionT => ({
    type: FETCH_PARTNERS_STATS_REQUEST_SUCCESS,
    stats,
});
