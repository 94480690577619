import {
    FETCH_DISPATCHES_STATS_REQUEST,
    FETCH_DISPATCHES_STATS_REQUEST_BEGIN,
    FETCH_DISPATCHES_STATS_REQUEST_ERROR,
    FETCH_DISPATCHES_STATS_REQUEST_SUCCESS,
    FetchDispatchesStatsActionT,
    FetchDispatchesStatsBeginActionT,
    FetchDispatchesStatsErrorActionT,
    FetchDispatchesStatsSuccessActionT,
} from './types';
import { DispatchStatsT } from './models';

export const fetchDispatchStats = (): FetchDispatchesStatsActionT => ({
    type: FETCH_DISPATCHES_STATS_REQUEST,
});

export const fetchDispatchStatsBegin = (): FetchDispatchesStatsBeginActionT => ({
    type: FETCH_DISPATCHES_STATS_REQUEST_BEGIN,
});

export const fetchDispatchStatsSuccess = (statistics: DispatchStatsT | null): FetchDispatchesStatsSuccessActionT => ({
    type: FETCH_DISPATCHES_STATS_REQUEST_SUCCESS,
    statistics,
});

export const fetchDispatchStatsError = (error: Error): FetchDispatchesStatsErrorActionT => ({
    type: FETCH_DISPATCHES_STATS_REQUEST_ERROR,
    error,
});
