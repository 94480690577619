import { RequestStatusT } from 'common/utils/request-status';

import { AdditionalServiceT } from './models';

export const FETCH_ADDITIONAL_SERVICES_REQUEST =
    'broker-admin/price-settings/additional-services/FETCH_ADDITIONAL_SERVICES_REQUEST';
export const FETCH_ADDITIONAL_SERVICES_REQUEST_BEGIN =
    'broker-admin/price-settings/additional-services/FETCH_ADDITIONAL_SERVICES_REQUEST_BEGIN';
export const FETCH_ADDITIONAL_SERVICES_REQUEST_ERROR =
    'broker-admin/price-settings/additional-services/FETCH_ADDITIONAL_SERVICES_REQUEST_ERROR';
export const FETCH_ADDITIONAL_SERVICES_REQUEST_SUCCESS =
    'broker-admin/price-settings/additional-services/FETCH_ADDITIONAL_SERVICES_REQUEST_SUCCESS';

export const UPDATE_ADDITIONAL_SERVICE_REQUEST =
    'broker-admin/price-settings/additional-services/UPDATE_ADDITIONAL_SERVICE_REQUEST';
export const UPDATE_ADDITIONAL_SERVICE_REQUEST_BEGIN =
    'broker-admin/price-settings/additional-services/UPDATE_ADDITIONAL_SERVICE_REQUEST_BEGIN';
export const UPDATE_ADDITIONAL_SERVICE_REQUEST_ERROR =
    'broker-admin/price-settings/additional-services/UPDATE_ADDITIONAL_SERVICE_REQUEST_ERROR';
export const UPDATE_ADDITIONAL_SERVICE_REQUEST_SUCCESS =
    'broker-admin/price-settings/additional-services/UPDATE_ADDITIONAL_SERVICE_REQUEST_SUCCESS';

export type AdditionalServicesStateT = {
    list: Array<AdditionalServiceT>;
    updateRequest: RequestStatusT;
    fetchRequest: RequestStatusT;
};

export type FetchAdditionalServicesActionT = {
    type: typeof FETCH_ADDITIONAL_SERVICES_REQUEST;
    options: FetchOptionsT;
};

export type FetchAdditionalServicesBeginActionT = {
    type: typeof FETCH_ADDITIONAL_SERVICES_REQUEST_BEGIN;
};

export type FetchAdditionalServicesSuccessActionT = {
    type: typeof FETCH_ADDITIONAL_SERVICES_REQUEST_SUCCESS;
    list: Array<AdditionalServiceT>;
};

export type FetchAdditionalServicesErrorActionT = {
    type: typeof FETCH_ADDITIONAL_SERVICES_REQUEST_ERROR;
    error: Error;
};

export type UpdateAdditionalServiceActionT = {
    type: typeof UPDATE_ADDITIONAL_SERVICE_REQUEST;
    serviceId: AdditionalServiceIdT;
    cost: number;
};

export type UpdateAdditionalServiceBeginActionT = {
    type: typeof UPDATE_ADDITIONAL_SERVICE_REQUEST_BEGIN;
};

export type UpdateAdditionalServiceSuccessActionT = {
    type: typeof UPDATE_ADDITIONAL_SERVICE_REQUEST_SUCCESS;
    serviceId: AdditionalServiceIdT;
    cost: number;
};

export type UpdateAdditionalServiceErrorActionT = {
    type: typeof UPDATE_ADDITIONAL_SERVICE_REQUEST_ERROR;
    error: Error;
};

export type AdditionalServicesActionT =
    | UpdateAdditionalServiceActionT
    | UpdateAdditionalServiceBeginActionT
    | UpdateAdditionalServiceSuccessActionT
    | UpdateAdditionalServiceErrorActionT
    | FetchAdditionalServicesActionT
    | FetchAdditionalServicesBeginActionT
    | FetchAdditionalServicesSuccessActionT
    | FetchAdditionalServicesErrorActionT;
