import React from 'react';
import classNames from 'classnames/bind';

import styles from './FiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { QueryFiltersT } from 'common/models';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import KeyAccountManagerDropdown from 'broker-admin/components/dropdowns/KeyAccountManagerDropdown/KeyAccountManagerDropdown';
import DispatcherDropdown from 'broker-admin/components/dropdowns/DispatcherDropdown/DispatcherDropdown';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import DictTrailerDropdown from 'common/components/dropdowns/DictTrailerDropdown/DictTrailerDropdown';
import Input from 'common/components/Input/Input';
import OriginIcon from 'common/icons/OriginIcon';
import DestinationIcon from 'common/icons/DestinationIcon';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import EmissionClassDropdown from 'common/components/dropdowns/EmissionClassDropdown/EmissionClassDropdown';
import CarrierDropdown from 'broker-admin/components/dropdowns/CarrierDropdown/CarrierDropdown';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import ShipperDropdown from 'broker-admin/components/dropdowns/ShipperSuggest/ShipperDropdown';

const cx = classNames.bind(styles);

type PropsT = {
    onClose: () => void;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const FiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose, setQueryFilters, queryFilters } = props;
    const { t } = useTranslation();

    const initialValues = React.useMemo(() => getInitialValues(queryFilters), [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const queryFilters = getQueryFilters(values);

            setQueryFilters(queryFilters);

            formikHelpers.setTouched({});
        },
    });

    const isSameValues = React.useMemo(() => isDeepEqual(initialValues, formik.values), [initialValues, formik.values]);

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('partner.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <div className={cx('field-group')}>
                    <div className={cx('field-group__title')}>{t('orders-table.filters.groups.responsible')}</div>
                    <FieldGroup>
                        <FormikField
                            className={cx('field', 'field--key-account-manager')}
                            label={t('orders-table.filters.fields.key-account-manager')}
                            name={FieldsEnum.keyAccountManagerId}
                            error={formik.errors[FieldsEnum.keyAccountManagerId]}
                            meta={formik.getFieldMeta(FieldsEnum.keyAccountManagerId)}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <KeyAccountManagerDropdown
                                    selectedValue={formik.values[FieldsEnum.keyAccountManagerId]}
                                    initialLabel={initialValues[FieldsEnum.keyAccountManagerName]}
                                    placeholder={t('orders-table.filters.placeholders.key-account-manager')}
                                    onSelect={(memberId, memberName) => {
                                        formik.setFieldValue(FieldsEnum.keyAccountManagerId, memberId);
                                        formik.setFieldValue(FieldsEnum.keyAccountManagerName, memberName);
                                    }}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            className={cx('field', 'field--dispatcher')}
                            label={t('orders-table.filters.fields.dispatcher')}
                            name={FieldsEnum.dispatcherId}
                            error={formik.errors[FieldsEnum.dispatcherId]}
                            meta={formik.getFieldMeta(FieldsEnum.dispatcherId)}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DispatcherDropdown
                                    selectedValue={formik.values[FieldsEnum.dispatcherId]}
                                    initialLabel={initialValues[FieldsEnum.dispatcherName]}
                                    placeholder={t('orders-table.filters.placeholders.dispatcher')}
                                    onSelect={(memberId, memberName) => {
                                        formik.setFieldValue(FieldsEnum.dispatcherId, memberId);
                                        formik.setFieldValue(FieldsEnum.dispatcherName, memberName);
                                    }}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                    </FieldGroup>
                </div>
                <div className={cx('field-group')}>
                    <div className={cx('field-group__title')}>{t('orders-table.filters.groups.business-partner')}</div>
                    <FormikField
                        name={FieldsEnum.shipperId}
                        error={formik.errors[FieldsEnum.shipperId]}
                        meta={formik.getFieldMeta(FieldsEnum.shipperId)}
                        label={t('orders-table.filters.fields.shipper')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <ShipperDropdown
                                value={formik.values[FieldsEnum.shipperId]}
                                initialValue={initialValues[FieldsEnum.shipperId]}
                                initialLabel={initialValues[FieldsEnum.shipperName] || ''}
                                hasClearControl
                                onSelect={(shipperId, shipperName) => {
                                    formik.setFieldValue(FieldsEnum.shipperId, shipperId);
                                    formik.setFieldValue(FieldsEnum.shipperName, shipperName);
                                }}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                placeholder={t('orders-table.filters.placeholders.shipper')}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                            />
                        )}
                    </FormikField>
                    <FormikField
                        name={FieldsEnum.carrierId}
                        error={formik.errors[FieldsEnum.carrierId]}
                        meta={formik.getFieldMeta(FieldsEnum.carrierId)}
                        label={t('orders-table.filters.fields.carrier')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <CarrierDropdown
                                value={formik.values[FieldsEnum.carrierId]}
                                initialValue={initialValues[FieldsEnum.carrierId]}
                                initialLabel={initialValues[FieldsEnum.carrierName] || ''}
                                hasClearControl
                                onSelect={(carrierId, carrierName) => {
                                    formik.setFieldValue(FieldsEnum.carrierId, carrierId);
                                    formik.setFieldValue(FieldsEnum.carrierName, carrierName);
                                }}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                placeholder={t('orders-table.filters.placeholders.carrier')}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                            />
                        )}
                    </FormikField>
                </div>
                <div className={cx('field-group')}>
                    <div className={cx('field-group__title')}>{t('orders-table.filters.groups.equipment')}</div>
                    <FieldGroup>
                        <FormikField
                            className={cx('field--emission-standard')}
                            name={FieldsEnum.emissionClass}
                            error={formik.errors[FieldsEnum.emissionClass]}
                            meta={formik.getFieldMeta(FieldsEnum.emissionClass)}
                            label={t('orders-table.filters.fields.truck-emission')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <EmissionClassDropdown
                                    value={formik.values[FieldsEnum.emissionClass]}
                                    onChange={props.onChange}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                    placeholder={t('orders-table.filters.placeholders.truck-emission')}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            className={cx('field--trailer-type')}
                            name={FieldsEnum.dictTrailerId}
                            error={formik.errors[FieldsEnum.dictTrailerId]}
                            meta={formik.getFieldMeta(FieldsEnum.dictTrailerId)}
                            label={t('orders-table.filters.fields.trailer-type')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DictTrailerDropdown
                                    placeholder={t('orders-table.filters.placeholders.trailer-type')}
                                    value={formik.values[FieldsEnum.dictTrailerId]}
                                    onChange={props.onChange}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasChanges={props.hasChanges}
                                />
                            )}
                        </FormikField>
                    </FieldGroup>
                </div>
                <div className={cx('field-group')}>
                    <div className={cx('field-group__title')}>{t('orders-table.filters.groups.addresses')}</div>
                    <FormikField
                        name={FieldsEnum.pickupAddress}
                        error={formik.errors[FieldsEnum.pickupAddress]}
                        meta={formik.getFieldMeta(FieldsEnum.pickupAddress)}
                        label={t('orders-table.filters.fields.pickup-address')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                renderLeftIcon={() => <OriginIcon />}
                                name={FieldsEnum.pickupAddress}
                                value={formik.values[FieldsEnum.pickupAddress] || ''}
                                placeholder={t('orders-table.filters.placeholders.pickup-address')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        name={FieldsEnum.dropoffAddress}
                        error={formik.errors[FieldsEnum.dropoffAddress]}
                        meta={formik.getFieldMeta(FieldsEnum.dropoffAddress)}
                        label={t('orders-table.filters.fields.drop-off-address')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                renderLeftIcon={() => <DestinationIcon />}
                                name={FieldsEnum.dropoffAddress}
                                value={formik.values[FieldsEnum.dropoffAddress] || ''}
                                placeholder={t('orders-table.filters.placeholders.drop-off-address')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </div>
                <div className={cx('field-group')}>
                    <div className={cx('field-group__title')}>
                        {t('orders-table.filters.groups.order-created-dates')}
                    </div>
                    <FormikField
                        className={cx('field--dates')}
                        name={FieldsEnum.createDates}
                        error={formik.errors[FieldsEnum.createDates]}
                        meta={formik.getFieldMeta(FieldsEnum.createDates)}
                        label={t('orders-table.filters.fields.created-dates')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.createDates]}
                                placeholder={t('orders-table.filters.placeholders.created-dates')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.topLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </div>
                <div className={cx('field-group', 'field-group--isLast')}>
                    <div className={cx('field-group__title')}>{t('orders-table.filters.groups.dates')}</div>
                    <FieldGroup>
                        <FormikField
                            className={cx('field--dates')}
                            name={FieldsEnum.pickupDates}
                            error={formik.errors[FieldsEnum.pickupDates]}
                            meta={formik.getFieldMeta(FieldsEnum.pickupDates)}
                            label={t('orders-table.filters.fields.pickup-dates')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DatePicker
                                    isRange
                                    value={formik.values[FieldsEnum.pickupDates]}
                                    placeholder={t('orders-table.filters.placeholders.pickup-dates')}
                                    onChange={props.onChange}
                                    overlayPosition={DatePickerOverlayPositionEnum.topLeft}
                                    hasClearControl
                                />
                            )}
                        </FormikField>
                        <FormikField
                            className={cx('field--dates')}
                            name={FieldsEnum.dropoffDates}
                            error={formik.errors[FieldsEnum.dropoffDates]}
                            meta={formik.getFieldMeta(FieldsEnum.dropoffDates)}
                            label={t('orders-table.filters.fields.drop-off-dates')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DatePicker
                                    isRange
                                    value={formik.values[FieldsEnum.dropoffDates]}
                                    placeholder={t('orders-table.filters.placeholders.drop-off-dates')}
                                    onChange={props.onChange}
                                    overlayPosition={DatePickerOverlayPositionEnum.topRight}
                                    hasClearControl
                                />
                            )}
                        </FormikField>
                    </FieldGroup>
                    {/* <TimeWindowPicker */}
                    {/*    className={cx('time-picker')} */}
                    {/*    range={DAY_RANGE} */}
                    {/*    availableValues={DAY_RANGE} */}
                    {/*    ranges={[]} */}
                    {/*    name={FieldsEnum.pickupTimeWindow} */}
                    {/*    hasStartStep */}
                    {/*    hasEndStep */}
                    {/*    values={formik.values[FieldsEnum.pickupTimeWindow]} */}
                    {/*    setFieldValue={formik.setFieldValue} */}
                    {/*    label={t('orders-table.filters.fields.pickup-time-slot')} */}
                    {/* /> */}
                    {/* <TimeWindowPicker */}
                    {/*    className={cx('time-picker')} */}
                    {/*    range={DAY_RANGE} */}
                    {/*    availableValues={DAY_RANGE} */}
                    {/*    ranges={[]} */}
                    {/*    name={FieldsEnum.dropoffTimeWindow} */}
                    {/*    hasStartStep */}
                    {/*    hasEndStep */}
                    {/*    values={formik.values[FieldsEnum.dropoffTimeWindow]} */}
                    {/*    setFieldValue={formik.setFieldValue} */}
                    {/*    label={t('orders-table.filters.fields.drop-off-time-slot')} */}
                    {/* /> */}
                </div>
            </SideBarLayout>
            <FooterSideBarLayout isTransparent hasPaddings>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('partner.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default FiltersSidebarContent;
