import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerTrucksPage.scss';
import TrucksPageLayout from 'common/layouts/TrucksPageLayout/TrucksPageLayout';

const cx = classNames.bind(styles);

type PropsT = {
    onOpenUserDetails: (user: UserIdT | null) => void;
};

const PartnerTrucksPage: React.FC<PropsT> = React.memo((props) => {
    const { onOpenUserDetails } = props;

    return <TrucksPageLayout onOpenUserDetails={onOpenUserDetails} />;
});

export default PartnerTrucksPage;
