export enum BrokerSidebarsTypeEnum {
    spotRequestDetails = 'spot-request-details',
    spotRequestsFilter = 'spot-requests-filter',
    createPartner = 'create-partner',
    partnerFilter = 'partner-filter',
    editPriceSettingsRuleSidebar = 'edit-price-settings-rule-sidebar',
    priceSettingsRuleFilter = 'price-settings-rule-filter',
    statisticsFilter = 'statistics-filter',
    uploadShipperContract = 'upload-shipper-contract',
    shipperContractLaneDetails = 'shipper-contract-lane-details',
}
