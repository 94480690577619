import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillOpacity?: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
    className?: string;
};

const ChartIcon: React.FC<PropsT> = (props) => {
    const { strokeColor, fillColor, fillOpacity, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    fillOpacity={fillOpacity || 1}
                    d="M8.5 4.75h3v10.5h-3V4.75zM4 9.25h3v6H4v-6zm12 6h-3v-4.5h3v4.5z"
                />
                <path
                    fill={strokeColor}
                    d="M13 9.25v-6H7v4.5H2.5v9h15v-7.5H13zm-4.5-4.5h3v10.5h-3V4.75zM4 9.25h3v6H4v-6zm12 6h-3v-4.5h3v4.5z"
                />
            </g>
        </svg>
    );
};

ChartIcon.displayName = 'ChartIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.brandDark,
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default ChartIcon;
