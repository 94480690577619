import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnersCell.scss';
import { BrokerOrderT } from 'broker-admin/store/orders/models';
import { useTranslation } from 'react-i18next';
import { CompanyTypeEnum } from 'common/constants';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';

type PropsT = {
    order: BrokerOrderT;
    onOpenPartnerDetails: (companyId: string, companyType: CompanyTypeEnum) => void;
};

const cx = classNames.bind(styles);

const PartnersCell: React.FC<PropsT> = React.memo((props) => {
    const { order, onOpenPartnerDetails } = props;

    const { t } = useTranslation();

    return (
        <div className={cx('partners')}>
            <div>
                <ClickInterceptorLabel
                    onClick={() => {
                        if (!order.shipperId) {
                            return;
                        }
                        onOpenPartnerDetails(order.shipperId, CompanyTypeEnum.shipper);
                    }}
                >
                    <span className={cx('partner')}>{order.shipperName}</span>
                </ClickInterceptorLabel>
            </div>
            {order.carrierId && (
                <div>
                    <ClickInterceptorLabel
                        onClick={() => {
                            if (!order.carrierId) {
                                return;
                            }
                            onOpenPartnerDetails(order.carrierId, CompanyTypeEnum.carrier);
                        }}
                    >
                        <span className={cx('partner')}>{order.carrierName}</span>
                    </ClickInterceptorLabel>
                </div>
            )}
            {!order.carrierId && <div className={cx('placeholder')}>{t('orders.list.table.not-assigned')}</div>}
        </div>
    );
});

export default PartnersCell;
