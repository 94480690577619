import { RequestStatusT } from 'common/utils/request-status';

import { BrokerOrderDetailsT, BrokerOrderT, FetchOrdersPageQueryT } from './models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_BROKER_ORDERS_PAGE_REQUEST = 'broker-admin/orders/FETCH_BROKER_ORDERS_PAGE_REQUEST';
export const FETCH_BROKER_ORDERS_PAGE_REQUEST_BEGIN = 'broker-admin/orders/FETCH_BROKER_ORDERS_PAGE_REQUEST_BEGIN';
export const FETCH_BROKER_ORDERS_PAGE_REQUEST_ERROR = 'broker-admin/orders/FETCH_BROKER_ORDERS_PAGE_REQUEST_ERROR';
export const FETCH_BROKER_ORDERS_PAGE_REQUEST_SUCCESS = 'broker-admin/orders/FETCH_BROKER_ORDERS_PAGE_REQUEST_SUCCESS';
export const RESET_BROKER_ORDERS = 'broker-admin/orders/RESET_BROKER_ORDERS';
export const FETCH_BROKER_ORDER_DETAILS_REQUEST = 'broker-admin/orders/FETCH_BROKER_ORDER_DETAILS_REQUEST';
export const FETCH_BROKER_ORDER_DETAILS_REQUEST_BEGIN = 'broker-admin/orders/FETCH_BROKER_ORDER_DETAILS_REQUEST_BEGIN';
export const FETCH_BROKER_ORDER_DETAILS_REQUEST_ERROR = 'broker-admin/orders/FETCH_BROKER_ORDER_DETAILS_REQUEST_ERROR';
export const FETCH_BROKER_ORDER_DETAILS_REQUEST_SUCCESS =
    'broker-admin/orders/FETCH_BROKER_ORDER_DETAILS_REQUEST_SUCCESS';

export type BrokerOrdersStateT = PaginationStateT<BrokerOrderT, FetchOrdersPageQueryT> & {
    fetchDetailsRequest: RequestStatusT;
    detailsById: Record<string, BrokerOrderDetailsT | null>;
};

export type FetchBrokerOrdersPageActionT = {
    type: typeof FETCH_BROKER_ORDERS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchOrdersPageQueryT;
};

export type FetchBrokerOrdersPageBeginActionT = {
    type: typeof FETCH_BROKER_ORDERS_PAGE_REQUEST_BEGIN;
    query: FetchOrdersPageQueryT;
    pageNumber: PageNumberT;
};

export type FetchBrokerOrdersPageSuccessActionT = {
    type: typeof FETCH_BROKER_ORDERS_PAGE_REQUEST_SUCCESS;
    query: FetchOrdersPageQueryT;
    pageNumber: PageNumberT;
    pageResponse: PageResponseT<BrokerOrderT> | null;
};

export type FetchBrokerOrdersPageErrorActionT = {
    type: typeof FETCH_BROKER_ORDERS_PAGE_REQUEST_ERROR;
    query: FetchOrdersPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
};

export type FetchBrokerOrderDetailsActionT = {
    type: typeof FETCH_BROKER_ORDER_DETAILS_REQUEST;
    id: BrokerOrderT['id'];
};

export type FetchBrokerOrderDetailsBeginActionT = {
    type: typeof FETCH_BROKER_ORDER_DETAILS_REQUEST_BEGIN;
    id: BrokerOrderT['id'];
};

export type FetchBrokerOrderDetailsSuccessActionT = {
    type: typeof FETCH_BROKER_ORDER_DETAILS_REQUEST_SUCCESS;
    id: BrokerOrderT['id'];
    details: BrokerOrderDetailsT | null;
};

export type FetchBrokerOrderDetailsErrorActionT = {
    type: typeof FETCH_BROKER_ORDER_DETAILS_REQUEST_ERROR;
    error: Error;
    id: BrokerOrderT['id'];
};

export type ResetBrokerOrdersActionT = {
    type: typeof RESET_BROKER_ORDERS;
};

export type BrokerOrdersActionT =
    | FetchBrokerOrdersPageActionT
    | FetchBrokerOrdersPageBeginActionT
    | FetchBrokerOrdersPageSuccessActionT
    | FetchBrokerOrdersPageErrorActionT
    | ResetBrokerOrdersActionT
    | FetchBrokerOrderDetailsActionT
    | FetchBrokerOrderDetailsBeginActionT
    | FetchBrokerOrderDetailsSuccessActionT
    | FetchBrokerOrderDetailsErrorActionT;
