import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AlertsSettingsForm.scss';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import prepareApiSystemSettings from './prepare-system-settings';
import NumberInput from 'common/components/NumberInput/NumberInput';
import withWidgetChangesDetector from 'common/components/SaveChangesNotification/withWidgetChangesDetector';
import { UnitTypeEnum } from 'common/constants';
import Widget from 'common/components/Widget/Widget';
import values from 'lodash/values';
import { getMaskByType } from '../utils';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import HiddenSubmitButtonForKeyboardEnter from 'common/components/HiddenEnterSubmitButton/HiddenSubmitButtonForKeyboardEnter';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { SystemSettingsFormCommonPropsT } from 'broker-admin/layouts/SettingsPage/SystemSettings/models';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';

const cx = classNames.bind(styles);

type NumberFieldsT =
    | FieldsEnum.transportationOrderHasNoDriverAlertTime
    | FieldsEnum.assignDriverLimitAlert
    | FieldsEnum.assignAssetLimitAlert
    | FieldsEnum.transportationOrderHasNoAssetsAlertTime
    | FieldsEnum.allowedGAPToBeDelayed
    | FieldsEnum.deleteNotificationsOlderDays
    | FieldsEnum.docExpiresSoonAlert
    | FieldsEnum.radiusAroundWaypointM
    | FieldsEnum.timeLimitToCancelOrderHours
    | FieldsEnum.timeLimitToCancelTransportationOrderHours;

const UNIT_TYPE_MAP: Record<NumberFieldsT, UnitTypeEnum> = {
    [FieldsEnum.transportationOrderHasNoDriverAlertTime]: UnitTypeEnum.minutesAbbreviation,
    [FieldsEnum.assignDriverLimitAlert]: UnitTypeEnum.minutesAbbreviation,
    [FieldsEnum.assignAssetLimitAlert]: UnitTypeEnum.minutesAbbreviation,
    [FieldsEnum.transportationOrderHasNoAssetsAlertTime]: UnitTypeEnum.minutesAbbreviation,
    [FieldsEnum.allowedGAPToBeDelayed]: UnitTypeEnum.minutesAbbreviation,
    [FieldsEnum.deleteNotificationsOlderDays]: UnitTypeEnum.days,
    [FieldsEnum.docExpiresSoonAlert]: UnitTypeEnum.days,
    [FieldsEnum.radiusAroundWaypointM]: UnitTypeEnum.metersAbbreviation,
    [FieldsEnum.timeLimitToCancelOrderHours]: UnitTypeEnum.hoursAbbreviation,
    [FieldsEnum.timeLimitToCancelTransportationOrderHours]: UnitTypeEnum.hoursAbbreviation,
};

const STEP_MAP: Record<NumberFieldsT, number> = {
    [FieldsEnum.transportationOrderHasNoDriverAlertTime]: 10,
    [FieldsEnum.assignDriverLimitAlert]: 10,
    [FieldsEnum.assignAssetLimitAlert]: 10,
    [FieldsEnum.transportationOrderHasNoAssetsAlertTime]: 10,
    [FieldsEnum.allowedGAPToBeDelayed]: 10,
    [FieldsEnum.deleteNotificationsOlderDays]: 1,
    [FieldsEnum.docExpiresSoonAlert]: 1,
    [FieldsEnum.radiusAroundWaypointM]: 100,
    [FieldsEnum.timeLimitToCancelOrderHours]: 1,
    [FieldsEnum.timeLimitToCancelTransportationOrderHours]: 1,
};

const FIELD_LABEL_T_MAP: Record<FieldsEnum, string> = {
    [FieldsEnum.transportationOrderHasNoDriverAlertTime]: 'price-settings.alerts-form.fields.noDriverAlert.label',
    [FieldsEnum.assignDriverLimitAlert]: 'price-settings.alerts-form.fields.assignDriverLimitAlert.label',
    [FieldsEnum.assignAssetLimitAlert]: 'price-settings.alerts-form.fields.assignAssetLimitAlert.label',
    [FieldsEnum.transportationOrderHasNoAssetsAlertTime]: 'price-settings.alerts-form.fields.noAssetAlert.label',
    [FieldsEnum.allowedGAPToBeDelayed]: 'price-settings.alerts-form.fields.allowedGAPToBeDelayed.label',
    [FieldsEnum.deleteNotificationsOlderDays]: 'price-settings.alerts-form.fields.deleteNotificationsOlderDays.label',
    [FieldsEnum.docExpiresSoonAlert]: 'price-settings.alerts-form.fields.docExpiresSoonAlert.label',
    [FieldsEnum.radiusAroundWaypointM]: 'price-settings.alerts-form.fields.radiusAroundWaypointM.label',
    [FieldsEnum.timeLimitToCancelOrderHours]: 'price-settings.alerts-form.fields.timeLimitToCancelOrderHours.label',
    [FieldsEnum.timeLimitToCancelTransportationOrderHours]:
        'price-settings.alerts-form.fields.timeLimitToCancelTransportationOrderHours.label',
};

const FIELD_DESCRIPTION_T_MAP: Record<FieldsEnum, string> = {
    [FieldsEnum.transportationOrderHasNoDriverAlertTime]: 'price-settings.alerts-form.fields.noDriverAlert.description',
    [FieldsEnum.assignDriverLimitAlert]: 'price-settings.alerts-form.fields.assignDriverLimitAlert.description',
    [FieldsEnum.assignAssetLimitAlert]: 'price-settings.alerts-form.fields.assignAssetLimitAlert.description',
    [FieldsEnum.transportationOrderHasNoAssetsAlertTime]: 'price-settings.alerts-form.fields.noAssetAlert.description',
    [FieldsEnum.allowedGAPToBeDelayed]: 'price-settings.alerts-form.fields.allowedGAPToBeDelayed.description',
    [FieldsEnum.deleteNotificationsOlderDays]:
        'price-settings.alerts-form.fields.deleteNotificationsOlderDays.description',
    [FieldsEnum.docExpiresSoonAlert]: 'price-settings.alerts-form.fields.docExpiresSoonAlert.description',
    [FieldsEnum.radiusAroundWaypointM]: 'price-settings.alerts-form.fields.radiusAroundWaypointM.description',
    [FieldsEnum.timeLimitToCancelOrderHours]:
        'price-settings.alerts-form.fields.timeLimitToCancelOrderHours.description',
    [FieldsEnum.timeLimitToCancelTransportationOrderHours]:
        'price-settings.alerts-form.fields.timeLimitToCancelTransportationOrderHours.description',
};

type PropsT = SystemSettingsFormCommonPropsT;

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

const AlertsSettingsForm: React.FC<PropsT> = (props) => {
    const { systemSettings, isLoading, title, onUpdate } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(systemSettings);
        const errors = validateForm(t, values);

        return [values, errors];
    }, [systemSettings]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const apiSystemSettings = prepareApiSystemSettings(values);
            onUpdate(apiSystemSettings);

            formikHelpers.setTouched({});
        },
    });

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);
    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormCallbacks) {
            remoteFormActionsContext.setRemoteFormCallbacks({
                submit: formik.submitForm,
                reset: formik.resetForm,
            });
        }
    }, [formik.submitForm, formik.resetForm]);

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);
    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormState) {
            remoteFormActionsContext.setRemoteFormState({
                hasChanges: hasAnyFieldValueChanges,
                hasErrors: hasFormAnyErros,
            });
        }
    }, [hasFormAnyErros, hasAnyFieldValueChanges]);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormRequest) {
            remoteFormActionsContext.setRemoteFormRequest({
                isLoading,
            });
        }
    }, [isLoading]);

    const WidgetChangesDetector = withWidgetChangesDetector(formik.values, initialValues);

    const renderNumberField = (name: NumberFieldsT): React.ReactNode => {
        return (
            <FormikField
                className={cx('field')}
                name={name}
                error={formik.errors[name]}
                meta={formik.getFieldMeta(name)}
                label={t(FIELD_LABEL_T_MAP[name])}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                tooltipNode={
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t(FIELD_DESCRIPTION_T_MAP[name])}
                    </TooltipContent>
                }
            >
                {(props) => (
                    <NumberInput
                        name={name}
                        unitType={UNIT_TYPE_MAP[name]}
                        mask={getMaskByType(UNIT_TYPE_MAP[name])}
                        step={STEP_MAP[name]}
                        value={formik.values[name]}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        hasChanges={props.hasChanges}
                    />
                )}
            </FormikField>
        );
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <Widget className={cx('widget')} title={title} rightNode={<WidgetChangesDetector fields={ALL_FIELDS} />}>
                <div className={cx('widget__inner')}>
                    <FieldGroup>
                        {renderNumberField(FieldsEnum.transportationOrderHasNoDriverAlertTime)}
                        {renderNumberField(FieldsEnum.assignDriverLimitAlert)}
                    </FieldGroup>
                    <FieldGroup>
                        {renderNumberField(FieldsEnum.transportationOrderHasNoAssetsAlertTime)}
                        {renderNumberField(FieldsEnum.assignAssetLimitAlert)}
                    </FieldGroup>
                    <FieldGroup>
                        {renderNumberField(FieldsEnum.allowedGAPToBeDelayed)}
                        {renderNumberField(FieldsEnum.deleteNotificationsOlderDays)}
                    </FieldGroup>
                    <FieldGroup>
                        {renderNumberField(FieldsEnum.docExpiresSoonAlert)}
                        {renderNumberField(FieldsEnum.radiusAroundWaypointM)}
                    </FieldGroup>
                    <FieldGroup>
                        {renderNumberField(FieldsEnum.timeLimitToCancelOrderHours)}
                        {renderNumberField(FieldsEnum.timeLimitToCancelTransportationOrderHours)}
                    </FieldGroup>
                    <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
                    <HiddenSubmitButtonForKeyboardEnter />
                </div>
            </Widget>
        </form>
    );
};

export default React.memo(AlertsSettingsForm) as typeof AlertsSettingsForm;
