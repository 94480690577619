import { put, select, takeEvery } from 'redux-saga/effects';
import { updateBegin, updateError, updateSuccess } from './actions';
import {
    CANCEL_SPOT_REQUEST,
    CANCEL_SPOT_REQUEST_BY_DISPATCH,
    CancelSpotRequestActionT,
    CancelSpotRequestByDispatchActionT,
    SEND_TO_SPOT,
    SendToSpotActionT,
} from './types';
import { selectSpotUpdateRequestStatus } from './selectors';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { selectDispatchDetails } from 'broker-admin/store/dispatch-details/selectors';
import { isNonNil } from 'common/utils';
import { fetchDispatchDetails } from 'broker-admin/store/dispatch-details/actions';
import spotBrokerTranziitApi from 'broker-admin/utils/api/spot-broker-tranziit/api';
import { addAlert } from 'common/store/alerts/actions';
import { BrokerAlertTypeEnum, BrokerAnyAlert } from 'broker-admin/components/toasts/models';
import { selectSpotRequestDetails } from 'broker-admin/store/spot-request-details/selectors';
import { fetchSpotRequestDetails } from 'broker-admin/store/spot-request-details/actions';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';

function* refreshDispatchDetails(dispatchId: DispatchIdT): WrapGeneratorT<void> {
    yield put(fetchDispatchDetails(dispatchId, { isForceUpdate: true }));
}

function* refreshSpotRequestDetails(spotRequestId: SpotRequestIdT): WrapGeneratorT<void> {
    yield put(fetchSpotRequestDetails(spotRequestId, { isForceUpdate: true }));
}

function* refreshSpotRequestsList(): WrapGeneratorT<void> {
    // TODO
}

function* cancelSpotByTour(tourIds: Array<TourIdT>): WrapGeneratorT<void> {
    yield put(updateBegin());

    let error: Error | null = null;
    for (const tourId of tourIds) {
        const response: ReturnApiT<typeof spotBrokerTranziitApi.cancelSpotRequestByTour> =
            yield spotBrokerTranziitApi.cancelSpotRequestByTour(tourId);

        const responseError = response[0] || null;
        if (responseError) {
            error = responseError;
            break;
        }
    }

    if (error) {
        yield put(updateError(error));
        return;
    }

    yield put(updateSuccess());
}

function* cancelSpotSaga(action: CancelSpotRequestActionT): WrapGeneratorT<void> {
    const { spotRequestId } = action;

    const requestStatus: ReturnType<typeof selectSpotUpdateRequestStatus> = yield select(selectSpotUpdateRequestStatus);
    if (requestStatus.loading) {
        return;
    }

    const { details }: ReReturnT<typeof selectSpotRequestDetails> = yield select(
        selectSpotRequestDetails(spotRequestId),
    );
    if (!details) {
        return;
    }

    const tourId = details.tourId || null;
    if (!tourId) {
        return;
    }

    yield cancelSpotByTour([tourId]);

    yield put(
        addAlert(
            new BrokerAnyAlert({
                type: BrokerAlertTypeEnum.spotRequestCancelled,
                data: {},
            }),
        ),
    );

    if (details.dispatchId) {
        yield refreshDispatchDetails(details?.dispatchId);
    }

    yield refreshSpotRequestsList();
    yield refreshSpotRequestDetails(spotRequestId);
}

function* cancelSpotByDispatchSaga(action: CancelSpotRequestByDispatchActionT): WrapGeneratorT<void> {
    const { dispatchId } = action;

    const requestStatus: ReturnType<typeof selectSpotUpdateRequestStatus> = yield select(selectSpotUpdateRequestStatus);
    if (requestStatus.loading) {
        return;
    }

    const dispatchDetails: ReReturnT<typeof selectDispatchDetails> = yield select(selectDispatchDetails(dispatchId));
    if (!dispatchDetails) {
        return;
    }

    const tourIds = dispatchDetails?.tours
        .map((tour) => {
            return tour?.id;
        })
        .filter(isNonNil);

    yield cancelSpotByTour(tourIds);

    yield put(
        addAlert(
            new BrokerAnyAlert({
                type: BrokerAlertTypeEnum.spotRequestCancelled,
                data: {},
            }),
        ),
    );

    yield refreshDispatchDetails(dispatchId);
}

function* sendToSpotSaga(action: SendToSpotActionT): WrapGeneratorT<void> {
    const requestStatus: ReturnType<typeof selectSpotUpdateRequestStatus> = yield select(selectSpotUpdateRequestStatus);
    if (requestStatus.loading) {
        return;
    }

    const { dispatchId, deadline, comment } = action;

    const dispatchDetails: ReReturnT<typeof selectDispatchDetails> = yield select(selectDispatchDetails(dispatchId));
    if (!dispatchDetails) {
        return;
    }

    const tourIds = dispatchDetails?.tours
        .map((tour) => {
            return tour?.id;
        })
        .filter(isNonNil);

    yield put(updateBegin());

    let error: Error | null = null;
    for (const tourId of tourIds) {
        const response: ReturnApiT<typeof brokerTranziitApi.sentTransportOrderToSpot> =
            yield brokerTranziitApi.sentTransportOrderToSpot(tourId, { comment, deadline });

        const responseError = response[0] || null;
        if (responseError) {
            error = responseError;
            break;
        }
    }

    if (error) {
        yield put(updateError(error));
        return;
    }

    yield put(updateSuccess());

    yield refreshDispatchDetails(dispatchId);
    stateMachineRefreshChannel.emit({});
}

function* spotSaga(): WrapGeneratorT<void> {
    yield takeEvery(SEND_TO_SPOT, sendToSpotSaga);
    yield takeEvery(CANCEL_SPOT_REQUEST_BY_DISPATCH, cancelSpotByDispatchSaga);
    yield takeEvery(CANCEL_SPOT_REQUEST, cancelSpotSaga);
}

export default spotSaga;
