import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ControlsCell.scss';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import MoreIcon from 'common/icons/MoreIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    onDelete: () => void;
    canDeletePartner: boolean;
};

const cx = classNames.bind(styles);

const ControlsCell: React.FC<PropsT> = React.memo((props) => {
    const { className, onDelete, canDeletePartner } = props;

    const { t } = useTranslation();

    const options: Array<DropdownControlOptionT | null | undefined> = [
        canDeletePartner
            ? {
                  label: (
                      <div className={cx('option')}>
                          <div className={cx('option__icon', 'option__icon--delete')} />
                          <div className={cx('option__label')}>{t('partners-table.actions.delete')}</div>
                      </div>
                  ),
                  onSelect: onDelete,
              }
            : null,
    ];

    return (
        <div className={cx('wrap')}>
            <div className={cx('controls')}>
                <DropdownControl
                    className={className}
                    options={options}
                    renderTrigger={(isActive, onClick) => (
                        <TransparentTrigger
                            isPressed={isActive}
                            onClick={onClick}
                            leftIcon={<MoreIcon />}
                            reflectionTheme={ReflectionThemeEnum.light}
                        />
                    )}
                    overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                />
            </div>
        </div>
    );
});

export default ControlsCell;
