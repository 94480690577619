import { DoneStatisticT, FetchStatisticsQueryT, RejectStatisticT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_STATISTICS_REQUEST = 'broker-admin/statistics/FETCH_STATISTICS_REQUEST';
export const FETCH_STATISTICS_REQUEST_BEGIN = 'broker-admin/statistics/FETCH_STATISTICS_REQUEST_BEGIN';
export const FETCH_DONE_STATISTICS_REQUEST_BEGIN = 'broker-admin/statistics/FETCH_DONE_STATISTICS_REQUEST_BEGIN';
export const FETCH_DONE_STATISTICS_REQUEST_ERROR = 'broker-admin/statistics/FETCH_DONE_STATISTICS_REQUEST_ERROR';
export const FETCH_DONE_STATISTICS_REQUEST_SUCCESS = 'broker-admin/statistics/FETCH_DONE_STATISTICS_REQUEST_SUCCESS';
export const FETCH_REJECT_STATISTICS_REQUEST_BEGIN = 'broker-admin/statistics/FETCH_REJECT_STATISTICS_REQUEST_BEGIN';
export const FETCH_REJECT_STATISTICS_REQUEST_ERROR = 'broker-admin/statistics/FETCH_REJECT_STATISTICS_REQUEST_ERROR';
export const FETCH_REJECT_STATISTICS_REQUEST_SUCCESS =
    'broker-admin/statistics/FETCH_REJECT_STATISTICS_REQUEST_SUCCESS';

export type StatisticsStateT = {
    fetchQuery: FetchStatisticsQueryT | null;
    rejectData: RejectStatisticT | null;
    rejectRequestStatus: RequestStatusT;
    doneData: DoneStatisticT | null;
    doneRequestStatus: RequestStatusT;
};

export type FetchStatisticsRequestActionT = {
    type: typeof FETCH_STATISTICS_REQUEST;
    query: FetchStatisticsQueryT;
    options?: FetchOptionsT;
};

export type FetchStatisticsRequestBeginActionT = {
    type: typeof FETCH_STATISTICS_REQUEST_BEGIN;
    query: FetchStatisticsQueryT;
    options?: FetchOptionsT;
};

export type FetchDoneStatisticsRequestBeginActionT = {
    type: typeof FETCH_DONE_STATISTICS_REQUEST_BEGIN;
    query: FetchStatisticsQueryT;
};

export type FetchDoneStatisticsRequestErrorActionT = {
    type: typeof FETCH_DONE_STATISTICS_REQUEST_ERROR;
    query: FetchStatisticsQueryT;
    error: Error;
};

export type FetchDoneStatisticsRequestSuccessActionT = {
    type: typeof FETCH_DONE_STATISTICS_REQUEST_SUCCESS;
    query: FetchStatisticsQueryT;
    statistic: DoneStatisticT | null;
};

export type FetchRejectStatisticsRequestBeginActionT = {
    type: typeof FETCH_REJECT_STATISTICS_REQUEST_BEGIN;
    query: FetchStatisticsQueryT;
};

export type FetchRejectStatisticsRequestErrorActionT = {
    type: typeof FETCH_REJECT_STATISTICS_REQUEST_ERROR;
    query: FetchStatisticsQueryT;
    error: Error;
};

export type FetchRejectStatisticsRequestSuccessActionT = {
    type: typeof FETCH_REJECT_STATISTICS_REQUEST_SUCCESS;
    query: FetchStatisticsQueryT;
    statistic: RejectStatisticT | null;
};

export type StatisticsActionT =
    | FetchStatisticsRequestActionT
    | FetchStatisticsRequestBeginActionT
    | FetchDoneStatisticsRequestBeginActionT
    | FetchDoneStatisticsRequestErrorActionT
    | FetchDoneStatisticsRequestSuccessActionT
    | FetchRejectStatisticsRequestBeginActionT
    | FetchRejectStatisticsRequestErrorActionT
    | FetchRejectStatisticsRequestSuccessActionT;
