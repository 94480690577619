import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './EquipmentCell.scss';
import { BrokerOrderT } from 'broker-admin/store/orders/models';
import parseTrailerModel from 'common/utils/parse-trailer-model';
import { useTranslation } from 'react-i18next';

type PropsT = {
    order: BrokerOrderT;
};

const cx = classNames.bind(styles);

const EquipmentCell: React.FC<PropsT> = React.memo((props) => {
    const { order } = props;

    const { t } = useTranslation();

    const { trailerModel } = order;
    const [trailer, trailerType] = parseTrailerModel(trailerModel);

    const defaultLabel = t('orders.list.table.not-assigned');

    const subType = trailerType ? t(`common:trailers.by-type.${trailerType}`) : defaultLabel;

    return (
        <div>
            {trailer && <div className={cx('type')}>{trailer}</div>}
            <div className={cx('sub-type')}>{subType}</div>
        </div>
    );
});

export default EquipmentCell;
