import { FieldsEnum, FormValuesT } from './constants';
import {
    BooleanSettingValueEnum,
    SystemSettingsChangesT,
} from 'broker-admin/store/price-settings/system-settings/models';

const prepareApiSystemSettings = (formValues: FormValuesT): SystemSettingsChangesT => {
    return {
        MINIMAL_COST_FOR_SHIPMENT: formValues[FieldsEnum.minimalShipmentCost],
        DRIVER_SALARY_PER_HOUR: formValues[FieldsEnum.driverSalaryPerHour],
        MINIMAL_COST_FOR_TRUCK: formValues[FieldsEnum.minimalCostForTruck],
        MINIMAL_COST_FOR_TRAILER: formValues[FieldsEnum.minimalCostForTrailer],
        COST_PER_ONE_HOUR_LAYOVER: formValues[FieldsEnum.layoverCostPerHour],
        COST_PER_ONE_DAY_LAYOVER: formValues[FieldsEnum.layoverCostPerDay],
        LAYOVER_FREE_HOURS: formValues[FieldsEnum.layoverFreeHours],
        URGENT_OVERPRICE: formValues[FieldsEnum.urgentOverPrice],
        OVERPRICE_FOR_EVERY_ADDITIONAL_STOP: formValues[FieldsEnum.overPriceForEveryAdditionalStop],
        COST_PER_GREEN_SHIPMENT: formValues[FieldsEnum.costPerGreenShipment],
        TRANZIIT_SERVICE_FEE_FIXED_MODE: formValues[FieldsEnum.tranziitServiceFeeFixedMode],
        TRANZIIT_SERVICE_FEE_PERCENT: formValues[FieldsEnum.tranziitServiceFeePercent],
        TRANZIIT_SERVICE_FEE_FIXED: formValues[FieldsEnum.tranziitServiceFeeFixed],
        PRICING_STRATEGY: formValues[FieldsEnum.pricingStrategy],
        TRUCK_CONSUMPTION: formValues[FieldsEnum.truckConsumption],
        REEFER_CONSUMPTION: formValues[FieldsEnum.reeferConsumption],
        RATE_MATRIX_SPREAD: formValues[FieldsEnum.rateMatrixSpread],
        USE_PERIODIC_RULES: formValues[FieldsEnum.usePeriodicRulesForWeekdays]
            ? BooleanSettingValueEnum.true
            : BooleanSettingValueEnum.false,
        YEAR_PERIODIC_AUTO_RULES: formValues[FieldsEnum.usePeriodicRulesForMonths]
            ? BooleanSettingValueEnum.true
            : BooleanSettingValueEnum.false,
    };
};

export default prepareApiSystemSettings;
