import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './DispatchesTable.scss';
import { useTranslation } from 'react-i18next';
import Table, { TableColumnT, TableRowMetaT } from 'common/components/Table/Table';
import CreateCell from './cell-renderers/CreateCell/CreateCell';
import { CompanyTypeEnum } from 'common/constants';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import { DispatchT } from 'broker-admin/store/dispatches/models';
import TrailerCell from './cell-renderers/TrailerCell/TrailerCell';
import CostCell from 'common/components/Table/cell-renderers/CostCell/CostCell';
import DropOffCell from './cell-renderers/DropOffCell/DropOffCell';
import PickUpCell from './cell-renderers/PickUpCell/PickUpCell';
import IdCell from 'common/components/Table/cell-renderers/IdCell/IdCell';
import DispatchStatusPill from 'common/components/status-pill/DispatchStatusPill/DispatchStatusPill';
import WarningIndicatorCell from 'common/components/Table/cell-renderers/WarningIndicatorCell/WarningIndicatorCell';

const cx = classNames.bind(styles);

type PropsT = {
    dispatches: Array<DispatchT>;
    className: string;
    goToDispatchDetails: (event: React.MouseEvent, dispatchId: DispatchIdT) => void;
    goToPartnerDetails: (companyId: CarrierIdT, companyType: CompanyTypeEnum) => void;
    goToTrailerDetails: (companyId: CarrierIdT, trailerId: TrailerIdT) => void;
    isLoading: boolean;
};

const DispatchesTable: React.FC<PropsT> = React.memo((props) => {
    const { dispatches, className, goToDispatchDetails, goToPartnerDetails, goToTrailerDetails, isLoading } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<DispatchT, void>> = [
        {
            renderHeader: () => <span>{t('dispatches.list.table.columns.id')}</span>,
            headerClassName: cx('table__header', 'table__header--id'),
            render: (dispatch: DispatchT) => <IdCell id={dispatch?.number} />,
            className: cx('table__column', 'table__column--id'),
            testSelector: 'id',
        },
        {
            renderHeader: () => <span>{t('dispatches.list.table.columns.pick-up')}</span>,
            headerClassName: cx('table__header'),
            render: (dispatch: DispatchT) => <PickUpCell dispatch={dispatch} />,
            className: cx('table__column', 'table__column--pickup'),
            testSelector: 'pickup',
        },
        {
            renderHeader: () => <span>{t('dispatches.list.table.columns.drop-off')}</span>,
            headerClassName: cx('table__header'),
            render: (dispatch: DispatchT) => <DropOffCell dispatch={dispatch} />,
            className: cx('table__column', 'table__column--dropoff'),
            testSelector: 'dropoff',
        },
        {
            renderHeader: () => <span>{t('dispatches.list.table.columns.created')}</span>,
            headerClassName: cx('table__header'),
            render: (dispatch: DispatchT) => (
                <CreateCell dispatch={dispatch} onOpenPartnerDetails={goToPartnerDetails} />
            ),
            className: cx('table__column', 'table__column--created'),
            testSelector: 'created',
        },
        {
            renderHeader: () => <span>{t('dispatches.list.table.columns.cost')}</span>,
            headerClassName: cx('table__header'),
            render: (dispatch: DispatchT) => {
                return <CostCell cost={dispatch.shipperPrice} />;
            },
            className: cx('table__column'),
            testSelector: 'cost',
        },
        {
            renderHeader: () => <span>{t('dispatches.list.table.columns.trailer')}</span>,
            headerClassName: cx('table__header'),
            render: (dispatch: DispatchT) => (
                <TrailerCell dispatch={dispatch} onOpenTrailerDetails={goToTrailerDetails} />
            ),
            className: cx('table__column', 'table__column--trailer'),
            testSelector: 'trailer',
        },
        {
            renderHeader: () => null,
            headerClassName: cx('table__header'),
            render: (dispatch: DispatchT) => (
                <WarningIndicatorCell
                    isShow={!!dispatch.hasWarnings}
                    tooltipText={t('dispatches.list.table.has-warnings')}
                />
            ),
            className: cx('table__column', 'table__column--has-warnings'),
            testSelector: 'has-warnings',
        },
        {
            renderHeader: () => <span>{t('dispatches.list.table.columns.status')}</span>,
            headerClassName: cx('table__header'),
            render: (dispatch: DispatchT) => <DispatchStatusPill isSymmetrical status={dispatch.calculatedStatus} />,
            className: cx('table__column', 'table__column--status'),
            testSelector: 'status',
        },
    ];

    const handleSelectRow = (event: React.MouseEvent, dispatch: DispatchT) => {
        if (!dispatch.id) {
            return;
        }

        goToDispatchDetails(event, dispatch.id);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getRowMods = (meta: TableRowMetaT, dispatch: DispatchT) => {
        return {};
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<DispatchT> tableName="dispatches" isLoading={isLoading} rows={dispatches}>
                {(rows, isUsedPrevRows) => (
                    <Table<DispatchT, void>
                        testSelector="dispatches"
                        className={className}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        onSelectRow={handleSelectRow}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default DispatchesTable;
