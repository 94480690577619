import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './AssignTriggerLabel.scss';
import { CurrencyEnum, StyleGuideColorsEnum } from 'common/constants';
import Money from 'common/components/Money/Money';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import CloseIcon from 'common/icons/CloseIcon';
import DetailsIcon from 'common/icons/DetailsIcon';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';

const cx = classNames.bind(styles);

type PropsT = {
    model?: string;
    plateNumber?: string;
    rate?: number | null;
    onReset: () => void;
    onOpenDetails: () => void;
};

const AssignTriggerLabel: React.FC<PropsT> = React.memo((props) => {
    const { model, plateNumber, rate, onReset, onOpenDetails } = props;

    return (
        <div className={cx('option')}>
            <div className={cx('model-and-plate-number')}>
                {plateNumber || ''}
                {' / '}
                {model || ''}
            </div>
            <div className={cx('spacer')} />
            {rate && (
                <div className={cx('price')}>
                    <Money amount={rate} currency={CurrencyEnum.EUR} />
                </div>
            )}
            <ClickInterceptorLabel className={cx('reset')}>
                <TransparentTrigger
                    spaces="xxs"
                    onClick={onReset}
                    leftIcon={<CloseIcon fillColor={StyleGuideColorsEnum.gray} />}
                    reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                />
            </ClickInterceptorLabel>
            <ClickInterceptorLabel className={cx('details')}>
                <TransparentTrigger
                    spaces="xxs"
                    onClick={onOpenDetails}
                    leftIcon={<DetailsIcon strokeColor={StyleGuideColorsEnum.gray} />}
                    reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                />
            </ClickInterceptorLabel>
        </div>
    );
});

export default AssignTriggerLabel;
