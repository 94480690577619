import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    strokeColor: StyleGuideColorsEnum;
};

const DocIcon: React.FC<PropsT> = (props) => {
    const { strokeColor } = props;

    return (
        <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13.979 2H2v16h2V4h8v4h4v10h2V6z" fill={strokeColor} fillRule="evenodd" />
        </svg>
    );
};

DocIcon.displayName = 'DocIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.gray,
};

export { storyProps };
export default DocIcon;
