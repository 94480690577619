import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames/bind';
import styles from './DispatchRouteMap.scss';
import GoogleMapReact from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import GoogleMapContext from 'common/contexts/google-map-context';
import { StopEnum } from 'common/utils/api/models';
import last from 'lodash/last';
import { AssetTypeEnum, StyleGuideColorsEnum } from 'common/constants';
import LastAssetTrackPointPin from 'common/components/maps/pins/LastAssetTrackPointPin/LastAssetTrackPointPin';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import MapBound, { BoundPointT } from 'common/components/maps/MapBound/MapBound';
import { DispatchDetailsT, DispatchWaypointT } from 'broker-admin/store/dispatch-details/models';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoutingGeometryState } from 'common/store/routing-geometry/selectors';
import RouteSwitchDropdown, {
    RouteTypeEnum,
} from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/RouteSwitchDropdown/RouteSwitchDropdown';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import { isNonNil } from 'common/utils';
import MapLoader from 'common/components/maps/MapLoader/MapLoader';
import NumberPinIcon from 'common/icons/NumberPinIcon';
import { getAssetTrackHashByTransportOrderId } from 'common/store/asset-track/utils';
import { selectAssetTrackPoints, selectAssetTrackRequest } from 'common/store/asset-track/selectors';
import AssetDropOffLocationPin from 'common/components/maps/pins/AssetDropOffLocationPin/AssetDropOffLocationPin';
import AssetPickUpLocationPin from 'common/components/maps/pins/AssetPickUpLocationPin/AssetPickUpLocationPin';
import { fetchTracksByTransportOrder } from 'common/store/asset-track/actions';
import { findActualTour } from 'broker-admin/store/dispatch-details/utils/find-actual-tour';
import { findActualTransportOrders } from 'broker-admin/store/dispatch-details/utils/find-actual-transport-order';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';

const cx = classNames.bind(styles);

type PropsT = {
    dispatchDetails: DispatchDetailsT | null;
};

const DispatchRouteMap: React.FC<PropsT> = React.memo((props) => {
    const { dispatchDetails } = props;

    const dispatch = useDispatch();

    const actualTour = findActualTour(dispatchDetails?.tours);

    const { carrierTransportOrder, trailerTransportOrder, truckTransportOrder } = findActualTransportOrders(
        actualTour?.transportationOrders,
    );
    const firstTruck = truckTransportOrder?.truck || null;
    const firstTrailer = trailerTransportOrder?.trailer || null;

    const truckTransportOrderId = truckTransportOrder?.id || null;
    const trailerTransportOrderId = trailerTransportOrder?.id || null;

    React.useEffect(() => {
        if (truckTransportOrderId) {
            dispatch(fetchTracksByTransportOrder(truckTransportOrderId));
        }
        if (truckTransportOrderId !== trailerTransportOrderId && trailerTransportOrderId) {
            dispatch(fetchTracksByTransportOrder(trailerTransportOrderId));
        }
    }, [truckTransportOrderId, trailerTransportOrderId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        if (truckTransportOrderId) {
            dispatch(fetchTracksByTransportOrder(truckTransportOrderId));
        }
        if (truckTransportOrderId !== trailerTransportOrderId && trailerTransportOrderId) {
            dispatch(fetchTracksByTransportOrder(trailerTransportOrderId));
        }
    }, [truckTransportOrderId, trailerTransportOrderId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const trailerHash = getAssetTrackHashByTransportOrderId(truckTransportOrderId, firstTrailer?.id);
    const trailerTrack = useSelector(selectAssetTrackPoints(trailerHash));
    const trailerTrackRequest = useSelector(selectAssetTrackRequest(trailerHash));

    const truckHash = getAssetTrackHashByTransportOrderId(trailerTransportOrderId, firstTruck?.id);
    const truckTrack = useSelector(selectAssetTrackPoints(truckHash));
    const truckTrackRequest = useSelector(selectAssetTrackRequest(truckHash));

    const [routeType, setRouteType] = useState<RouteTypeEnum | null>(
        carrierTransportOrder ? RouteTypeEnum.carrierRoute : RouteTypeEnum.estimateCarrierRoute,
    );
    useEffect(() => {
        setRouteType(carrierTransportOrder ? RouteTypeEnum.carrierRoute : RouteTypeEnum.estimateCarrierRoute);
    }, [carrierTransportOrder]);

    const googleMapContext = React.useContext(GoogleMapContext);

    const routePolylineIds = useMemo((): Array<PolylineIdT> => {
        switch (routeType) {
            case RouteTypeEnum.carrierRoute: {
                return [
                    actualTour?.polylineId,
                    actualTour?.trailerToPickUpPolylineId,
                    actualTour?.truckToTrailerPolylineId,
                    actualTour?.dropTrailerPolylineId,
                    actualTour?.dropTruckPolylineId,
                ].filter(isNonNil);
            }
            case RouteTypeEnum.estimateCarrierRoute: {
                return [actualTour?.polylineId].filter(isNonNil);
            }
            case RouteTypeEnum.shipperRoute: {
                return [dispatchDetails?.order?.polylineId].filter(isNonNil);
            }
            default: {
                return [];
            }
        }
    }, [actualTour, routeType]);

    useRouteGeometry(routePolylineIds);

    const shipperRouteGeometryState = useSelector(selectRoutingGeometryState(dispatchDetails?.order?.polylineId));

    const estimateCarrierRouteGeometryState = useSelector(selectRoutingGeometryState(actualTour?.polylineId));

    const carrierRoutePayloadGeometryState = useSelector(selectRoutingGeometryState(actualTour?.polylineId));
    const carrierRouteTrailerToPickUpGeometryState = useSelector(
        selectRoutingGeometryState(actualTour?.trailerToPickUpPolylineId),
    );
    const carrierRouteTruckToTrailerGeometryState = useSelector(
        selectRoutingGeometryState(actualTour?.truckToTrailerPolylineId),
    );
    const carrierRouteDropTrailerGeometryState = useSelector(
        selectRoutingGeometryState(actualTour?.dropTrailerPolylineId),
    );
    const carrierRouteDropTruckGeometryState = useSelector(selectRoutingGeometryState(actualTour?.dropTruckPolylineId));

    const geometryStates = useMemo(() => {
        switch (routeType) {
            case RouteTypeEnum.carrierRoute: {
                return [
                    carrierRoutePayloadGeometryState,
                    carrierRouteTrailerToPickUpGeometryState,
                    carrierRouteTruckToTrailerGeometryState,
                    carrierRouteDropTrailerGeometryState,
                    carrierRouteDropTruckGeometryState,
                ];
            }
            case RouteTypeEnum.estimateCarrierRoute: {
                return [estimateCarrierRouteGeometryState];
            }
            case RouteTypeEnum.shipperRoute: {
                return [shipperRouteGeometryState];
            }
            default: {
                return [];
            }
        }
    }, [
        routeType,
        carrierRoutePayloadGeometryState,
        carrierRouteTrailerToPickUpGeometryState,
        carrierRouteTruckToTrailerGeometryState,
        carrierRouteDropTrailerGeometryState,
        carrierRouteDropTruckGeometryState,
        estimateCarrierRouteGeometryState,
        shipperRouteGeometryState,
    ]);

    const isLoadingPolylines = useMemo(() => {
        return geometryStates.some((geometryState) => {
            return geometryState?.requestStatus && geometryState?.requestStatus?.loading;
        });
    }, [geometryStates]);

    const boundMapPoints = React.useMemo((): Array<BoundPointT> => {
        return (
            actualTour?.waypoints?.map((waypoint): BoundPointT => {
                return [waypoint?.address?.latitude, waypoint?.address?.longitude];
            }) || []
        );
    }, [actualTour]);

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const lastTruckTrackPoint = last(truckTrack);
    const lastTrailerTrackPoint = last(trailerTrack);

    const isLoading = isLoadingPolylines || truckTrackRequest?.loading || trailerTrackRequest?.loading;

    const renderWaypoint = (waypoint: DispatchWaypointT, index: number) => {
        switch (waypoint.type) {
            case StopEnum.pickupTruck: {
                if (routeType !== RouteTypeEnum.carrierRoute) {
                    return null;
                }

                return (
                    <AssetPickUpLocationPin
                        assetType={AssetTypeEnum.truck}
                        key="hookTruckStop"
                        className={cx('route-point-pin')}
                        lat={waypoint.address?.latitude}
                        lng={waypoint.address?.longitude}
                    />
                );
            }
            case StopEnum.pickupTrailer: {
                if (routeType !== RouteTypeEnum.carrierRoute) {
                    return null;
                }

                return (
                    <AssetPickUpLocationPin
                        assetType={AssetTypeEnum.trailer}
                        key="hookTrailerStop"
                        className={cx('route-point-pin')}
                        lat={waypoint.address?.latitude}
                        lng={waypoint.address?.longitude}
                    />
                );
            }
            case StopEnum.pickupRoadTrain: {
                if (routeType !== RouteTypeEnum.carrierRoute) {
                    return null;
                }

                return (
                    <AssetPickUpLocationPin
                        assetType={null}
                        key="link-pickup"
                        className={cx('route-point-pin')}
                        lat={waypoint.address?.latitude}
                        lng={waypoint.address?.longitude}
                    />
                );
            }
            case StopEnum.driveThrough:
            case StopEnum.pickupDeliveryShipment: {
                let fillColor = StyleGuideColorsEnum.charcoal;
                if (waypoint.type === StopEnum.driveThrough) {
                    fillColor = StyleGuideColorsEnum.gray;
                }

                const isVisited = !!waypoint?.driverLeftTimeStamp;
                if (isVisited) {
                    fillColor = StyleGuideColorsEnum.brandDark;
                }

                return (
                    <NumberPinIcon
                        number={(waypoint.index || 0) + 1}
                        key={`route-point-${waypoint.index}-${index}`}
                        className={cx('route-point-pin')}
                        fillColor={fillColor}
                        lng={waypoint?.address?.longitude}
                        lat={waypoint?.address?.latitude}
                    />
                );
            }
            case StopEnum.dropTrailer: {
                if (routeType !== RouteTypeEnum.carrierRoute) {
                    return null;
                }

                return (
                    <AssetDropOffLocationPin
                        assetType={AssetTypeEnum.trailer}
                        key="dropTrailerStop"
                        className={cx('route-point-pin')}
                        lng={waypoint?.address?.longitude}
                        lat={waypoint?.address?.latitude}
                    />
                );
            }
            case StopEnum.dropTruck: {
                if (routeType !== RouteTypeEnum.carrierRoute) {
                    return null;
                }

                return (
                    <AssetDropOffLocationPin
                        assetType={AssetTypeEnum.truck}
                        key="dropTruckStop"
                        className={cx('route-point-pin')}
                        lng={waypoint?.address?.longitude}
                        lat={waypoint?.address?.latitude}
                    />
                );
            }
            case StopEnum.dropRoadTrain: {
                if (routeType !== RouteTypeEnum.carrierRoute) {
                    return null;
                }

                return (
                    <AssetDropOffLocationPin
                        assetType={null}
                        key="link-dropoff"
                        className={cx('route-point-pin')}
                        lng={waypoint?.address?.longitude}
                        lat={waypoint?.address?.latitude}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <>
            {isLoading && <MapLoader className={cx('loader')} />}
            <GoogleMapReact
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                options={MAP_OPTIONS}
                onGoogleApiLoaded={apiIsLoaded}
            >
                {actualTour?.waypoints?.map((waypoint, index) => {
                    return renderWaypoint(waypoint, index);
                })}
                {lastTruckTrackPoint && (
                    <LastAssetTrackPointPin
                        key="truck-last-point"
                        iconType={AssetTypeEnum.truck}
                        lat={lastTruckTrackPoint?.lat}
                        lng={lastTruckTrackPoint?.lng}
                        timestamp={lastTruckTrackPoint?.timestamp}
                    />
                )}
                {lastTrailerTrackPoint && (
                    <LastAssetTrackPointPin
                        key="trailer-last-point"
                        iconType={AssetTypeEnum.trailer}
                        lat={lastTrailerTrackPoint?.lat}
                        lng={lastTrailerTrackPoint?.lng}
                        timestamp={lastTrailerTrackPoint?.timestamp}
                    />
                )}
            </GoogleMapReact>
            {geometryStates?.map((geometryState, index) => (
                <MapRoute
                    key={`geometry-${index}`}
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                    polylines={geometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            ))}
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={trailerTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={truckTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapBound
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                allMapPoints={boundMapPoints}
            />
            {!!routePolylineIds?.length && (
                <RouteSwitchDropdown
                    className={cx('route-type-switcher')}
                    value={routeType}
                    onChange={setRouteType}
                    isDisabledEstimateCarrierRoute={!actualTour}
                    isDisabledShipperRoute={!dispatchDetails}
                    isDisabledCarrierRoute={!carrierTransportOrder}
                />
            )}
        </>
    );
});

export default DispatchRouteMap;
