import { fork } from 'redux-saga/effects';

import detailsSaga from './details/sagas';
import rfqStatsSaga from './rfq-stats/sagas';

function* partnerSaga() {
    yield fork(rfqStatsSaga);
    yield fork(detailsSaga);
}

export default partnerSaga;
