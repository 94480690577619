import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BrokerInvoiceT } from 'broker-admin/store/invoices/models';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { StyleGuideColorsEnum } from 'common/constants';
import PdfFileIcon from 'common/icons/PdfFileIcon';

type PropsT = {
    invoice: BrokerInvoiceT | null;
};

const ActionsCell: React.FC<PropsT> = React.memo((props) => {
    const { invoice } = props;

    const { t } = useTranslation();

    if (!invoice) {
        return null;
    }

    const { pdfLink } = invoice;

    if (!pdfLink) {
        return null;
    }

    return (
        <TransparentTrigger
            onClick={() => {
                window.open(pdfLink, '_blank')?.focus();
            }}
            leftIcon={<PdfFileIcon fillColor={StyleGuideColorsEnum.charcoal} />}
            reflectionTheme={ReflectionThemeEnum.light}
        />
    );
});

export default ActionsCell;
