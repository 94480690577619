import { FieldsEnum, FormValuesT } from './constants';
import { SystemSettingsChangesT } from 'broker-admin/store/price-settings/system-settings/models';

const prepareApiSystemSettings = (formValues: FormValuesT): SystemSettingsChangesT => {
    return {
        TRANSPORTATION_ORDER_HAS_NO_DRIVER_ALERT_TIME: formValues[FieldsEnum.transportationOrderHasNoDriverAlertTime],
        RUN_OUT_OF_TIME_TO_ASSIGN_DRIVER_LIMIT: formValues[FieldsEnum.assignDriverLimitAlert],
        ALLOWED_GAP_TO_BE_DELAYED: formValues[FieldsEnum.allowedGAPToBeDelayed],
        DELETE_NOTIFICATIONS_OLDER_DAYS: formValues[FieldsEnum.deleteNotificationsOlderDays],
        DOC_EXPIRES_SOON_ALERT: formValues[FieldsEnum.docExpiresSoonAlert],
        RUN_OUT_OF_TIME_TO_ASSIGN_ASSETS_LIMIT: formValues[FieldsEnum.assignAssetLimitAlert],
        TRANSPORTATION_ORDER_HAS_NO_ASSETS_ALERT_TIME: formValues[FieldsEnum.transportationOrderHasNoAssetsAlertTime],
        RADIUS_AROUND_WAYPOINT_METERS: formValues[FieldsEnum.radiusAroundWaypointM],
        TIME_LIMIT_TO_CANCEL_ORDER_HOURS: formValues[FieldsEnum.timeLimitToCancelOrderHours],
        TIME_LIMIT_TO_CANCEL_TRANSPORTATION_ORDER_HOURS:
            formValues[FieldsEnum.timeLimitToCancelTransportationOrderHours],
    };
};

export default prepareApiSystemSettings;
