import { FetchStatisticsQueryT } from 'broker-admin/store/statistics/models';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { StatisticGranularityEnum, StatisticSourceEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import { getDateFromDate } from 'common/utils/time';
import { DEFAULT_DATA_SOURCE } from 'broker-admin/layouts/StatisticsPage/constants';
import { CountryCodeUnionT } from 'common/utils/api/models';

const prepareFetchQuery = (
    queryFilters: QueryFiltersT,
    selectedGranularity: StatisticGranularityEnum,
    dates: DateRangeT | null,
): FetchStatisticsQueryT | null => {
    if (!dates?.from || !dates?.to) {
        return null;
    }

    const shippedId = queryFilters[QueryFiltersKeysEnum.shipperId];
    const carrierId = queryFilters[QueryFiltersKeysEnum.carrierId];

    return {
        granularity: selectedGranularity,
        source: (queryFilters[QueryFiltersKeysEnum.dataSource] as StatisticSourceEnum) || DEFAULT_DATA_SOURCE,
        toDate: getDateFromDate(dates.to),
        fromDate: getDateFromDate(dates.from),
        destinationCountry: (queryFilters[QueryFiltersKeysEnum.toCountryCode] as CountryCodeUnionT) || undefined,
        destinationZipCode: queryFilters[QueryFiltersKeysEnum.toZipCode] || undefined,
        originCountry: (queryFilters[QueryFiltersKeysEnum.fromCountryCode] as CountryCodeUnionT) || undefined,
        originZipCode: queryFilters[QueryFiltersKeysEnum.fromZipCode] || undefined,
        shippedIds: shippedId ? [shippedId] : undefined,
        carrierIds: carrierId ? [carrierId] : undefined,
    };
};

export { prepareFetchQuery };
