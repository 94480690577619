import { FieldsEnum, FormValuesT } from './constants';
import { SystemSettingsT } from 'broker-admin/store/price-settings/system-settings/models';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import { MS_IN_HOUR } from 'common/utils/time';
import { convertSecToMin } from 'common/utils/form-value-converters';

const getInitialValues = (systemSettings: SystemSettingsT | null | undefined): FormValuesT => {
    const startBrokerWorkingHoursH = parseSimpleNumber(systemSettings?.BROKER_DAY_START_HOURS || '') || 0;
    const endBrokerWorkingHoursH = parseSimpleNumber(systemSettings?.BROKER_DAY_END_HOURS || '') || 0;

    return {
        [FieldsEnum.urgentOrderProcessingDelay]: systemSettings?.URGENT_ORDER_PROCESSING_DELAY || '',
        [FieldsEnum.regularOrderProcessingDelay]: systemSettings?.REGULAR_ORDER_PROCESSING_DELAY || '',
        [FieldsEnum.minWindowSize]: convertSecToMin(systemSettings?.MIN_WINDOW_SIZE || ''),
        [FieldsEnum.maxWindowSize]: convertSecToMin(systemSettings?.MAX_WINDOW_SIZE || ''),
        [FieldsEnum.brokerWorkingHours]: [startBrokerWorkingHoursH * MS_IN_HOUR, endBrokerWorkingHoursH * MS_IN_HOUR],
        [FieldsEnum.timeSlotsDelegationLimit]: systemSettings?.TIME_SLOTS_DELEGATION_LIMIT || '',
        [FieldsEnum.orderCreationSeconds]: systemSettings?.ORDER_CREATION_SECONDS || '',
        [FieldsEnum.quantumMinutes]: systemSettings?.QUANTUM_MINUTES || '',
        [FieldsEnum.mapPrecisionDelta]: systemSettings?.MAP_PRECISION_DELTA || '',
    };
};

export default getInitialValues;
