import { put, select, takeEvery } from 'redux-saga/effects';
import { fetchDispatchStatsBegin, fetchDispatchStatsError, fetchDispatchStatsSuccess } from './actions';
import { FETCH_DISPATCHES_STATS_REQUEST } from './types';
import checkNeedRequest from 'common/utils/check-need-request';
import { selectDispatchStatsRequest } from './selectors';
import { FETCH_DISPATCHES_PAGE_REQUEST } from 'broker-admin/store/dispatches/types';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';

function* fetchDispatchStatsSaga(): WrapGeneratorT<void> {
    const requestStatus: ReturnType<typeof selectDispatchStatsRequest> = yield select(selectDispatchStatsRequest);

    const isNeedRequest = checkNeedRequest(requestStatus);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchDispatchStatsBegin());

    const [error, response]: ReturnApiT<typeof brokerTranziitApi.fetchDispatchesStatistics> =
        yield brokerTranziitApi.fetchDispatchesStatistics();
    if (error) {
        yield put(fetchDispatchStatsError(error));
        return;
    }

    yield put(fetchDispatchStatsSuccess(response));
}

function* dispatchStatsSaga(): WrapGeneratorT<void> {
    yield takeEvery([FETCH_DISPATCHES_STATS_REQUEST, FETCH_DISPATCHES_PAGE_REQUEST], fetchDispatchStatsSaga);
}

export default dispatchStatsSaga;
