import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ShipperContractDetailsLayout.scss';
import { PartnerTypeEnum } from 'common/utils/api/models';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import BaseShipperContractDetailsLayout from 'common/layouts/BaseShipperContractDetailsLayout/BaseShipperContractDetailsLayout';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    partnerType: PartnerTypeEnum;
    contractId: ShipperContractIdT;
};

const ShipperContractDetailsLayout: React.FC<PropsT> = React.memo((props) => {
    const { partnerId, partnerType, contractId } = props;

    return (
        <ContentMargins className={cx('inner')}>
            <BaseShipperContractDetailsLayout
                partnerId={partnerId}
                partnerType={partnerType}
                contractId={contractId}
                isAllowEditing
            />
        </ContentMargins>
    );
});

export default ShipperContractDetailsLayout;
