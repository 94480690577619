import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './NameCell.scss';
import { PartnerT } from 'broker-admin/store/partners/models';
import NotificationLabel, {
    NotificationLabelThemeEnum,
} from 'common/components/notifications/NotificationLabel/NotificationLabel';
import { checkAllVerified } from 'broker-admin/layouts/PartnersPage/PartnersTable/utils';

type PropsT = {
    partner: PartnerT;
};

const cx = classNames.bind(styles);

const NameCell: React.FC<PropsT> = React.memo((props) => {
    const { partner } = props;

    const isAllVerified = checkAllVerified(partner);

    return (
        <div className={cx('cell')}>
            {!isAllVerified && (
                <div className={cx('notification')}>
                    <NotificationLabel isShowWarning theme={NotificationLabelThemeEnum.charcoal} />
                </div>
            )}
            <div>
                <div className={cx('name')}>{partner.name}</div>
                <div className={cx('id')}>{partner.number}</div>
            </div>
        </div>
    );
});

export default NameCell;
