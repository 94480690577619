import { put, select, takeEvery } from 'redux-saga/effects';
import {
    FETCH_SYSTEM_SETTINGS_REQUEST,
    FetchSystemSettingsActionT,
    UPDATE_SYSTEM_SETTINGS_REQUEST,
    UpdateSystemSettingsActionT,
} from './types';
import {
    fetchSystemSettings,
    fetchSystemSettingsBegin,
    fetchSystemSettingsError,
    fetchSystemSettingsSuccess,
    updateSystemSettingsBegin,
    updateSystemSettingsError,
    updateSystemSettingsSuccess,
} from './actions';

import { selectSystemSettingsFetchRequest, selectSystemSettingsUpdateRequest } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { fetchSettings } from 'common/store/settings/actions';
import spotBrokerTranziitApi from 'broker-admin/utils/api/spot-broker-tranziit/api';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { ApiSpotSettingsKeysT } from 'broker-admin/utils/api/spot-broker-tranziit/models';
import omit from 'lodash/omit';

const allSpotSystemSettings: ApiSpotSettingsKeysT[] = ['EXPIRES_SOON'];

function* fetchSystemSettingsSaga(action: FetchSystemSettingsActionT): WrapGeneratorT<void> {
    const { options } = action;

    const request: ReturnType<typeof selectSystemSettingsFetchRequest> = yield select(selectSystemSettingsFetchRequest);
    if (!checkNeedRequest(request, options)) {
        return;
    }

    yield put(fetchSystemSettingsBegin());

    const [error, data]: ReturnApiT<typeof brokerTranziitApi.fetchSystemSettings> =
        yield brokerTranziitApi.fetchSystemSettings();

    if (error) {
        yield put(fetchSystemSettingsError(error));
        return;
    }

    const [spotError, spotData]: ReturnApiT<typeof spotBrokerTranziitApi.fetchSystemSettings> =
        yield spotBrokerTranziitApi.fetchSystemSettings();

    if (spotError) {
        yield put(fetchSystemSettingsError(spotError));
        return;
    }

    yield put(
        fetchSystemSettingsSuccess({
            ...data,
            ...spotData,
        }),
    );
}

function* updateSystemSettingsSaga(action: UpdateSystemSettingsActionT): WrapGeneratorT<void> {
    const request: ReturnType<typeof selectSystemSettingsUpdateRequest> = yield select(
        selectSystemSettingsUpdateRequest,
    );

    if (request.loading) {
        return;
    }

    yield put(updateSystemSettingsBegin());

    const { settingsChanges } = action;

    const apiSettingsChanges = omit(settingsChanges, allSpotSystemSettings);
    const hasApiSettingsChanges = !isEmpty(apiSettingsChanges);
    if (hasApiSettingsChanges) {
        const [error]: ReturnApiT<typeof brokerTranziitApi.updateSystemSettings> =
            yield brokerTranziitApi.updateSystemSettings(apiSettingsChanges);

        if (error) {
            yield put(updateSystemSettingsError(error));
            return;
        }
    }

    const apiSpotSettingsChanges = pick(settingsChanges, allSpotSystemSettings);
    const hasApiSpotSettingsChanges = !isEmpty(apiSpotSettingsChanges);
    if (hasApiSpotSettingsChanges) {
        const [error]: ReturnApiT<typeof spotBrokerTranziitApi.updateSystemSettings> =
            yield spotBrokerTranziitApi.updateSystemSettings(apiSpotSettingsChanges);

        if (error) {
            yield put(updateSystemSettingsError(error));
            return;
        }
    }

    const fetchAction = fetchSystemSettings({ isForceUpdate: true });
    yield fetchSystemSettingsSaga(fetchAction);

    yield put(updateSystemSettingsSuccess());

    yield put(fetchSettings({ isForceUpdate: true }));
}

function* systemSettingsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_SYSTEM_SETTINGS_REQUEST, fetchSystemSettingsSaga);
    yield takeEvery(UPDATE_SYSTEM_SETTINGS_REQUEST, updateSystemSettingsSaga);
}

export default systemSettingsSaga;
