import * as React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import NotificationLabel, {
    NotificationLabelThemeEnum,
} from 'common/components/notifications/NotificationLabel/NotificationLabel';
import DropdownMultipleInput from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInput';
import { useTranslation } from 'react-i18next';
import TargetIcon from 'common/icons/TargetIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import RouteIcon from 'common/icons/RouteIcon';
import UnavailableTrailerIcon from 'common/icons/UnavailableTrailerIcon';
import TuneIcon from 'common/icons/TuneIcon';
import { SpecialOptionEnum } from 'design-system/components/dropdowns/DropdownControl/DropdownControl';

export type AssignmentOptionsValueT = {
    isShowUnavailableAssets: boolean;
    isShowActualPlace: boolean;
    isShowAssetSchedule: boolean;
    isIgnoreEmissionStandard: boolean;
    isIgnoreCertificates: boolean;
};

enum AssignmentOptionEnum {
    showUnavailableAssets = 'show-unavailable-assets',
    showActualPlace = 'show-actual-place',
    showAssetSchedule = 'show-asset-schedule',
    ignoreEmissionStandard = 'ignore-emission-standard',
    ignoreCertificates = 'ignore-certificates',
}

type AssignmentOptionT = {
    label: React.ReactNode;
    value: AssignmentOptionEnum;
};

type PropsT = {
    className?: string;
    value: AssignmentOptionsValueT;
    onChange: (options: AssignmentOptionsValueT) => void;
};

const AssignmentOptionsDropdown: React.FC<PropsT> = React.memo((props) => {
    const { className, value, onChange } = props;

    const { t } = useTranslation();

    const selectedOptionValues: Array<AssignmentOptionEnum> = React.useMemo((): Array<AssignmentOptionEnum> => {
        const values: Array<AssignmentOptionEnum> = [];

        if (value.isShowActualPlace) {
            values.push(AssignmentOptionEnum.showActualPlace);
        }

        if (value.isShowAssetSchedule) {
            values.push(AssignmentOptionEnum.showAssetSchedule);
        }

        if (value.isShowUnavailableAssets) {
            values.push(AssignmentOptionEnum.showUnavailableAssets);
        }

        if (value.isIgnoreCertificates) {
            values.push(AssignmentOptionEnum.ignoreCertificates);
        }

        if (value.isIgnoreEmissionStandard) {
            values.push(AssignmentOptionEnum.ignoreEmissionStandard);
        }

        return values;
    }, [value]);

    const handleChange = React.useCallback(
        (values: Array<AssignmentOptionEnum>) => {
            const optionsSet = new Set(values);

            onChange({
                isShowUnavailableAssets: optionsSet.has(AssignmentOptionEnum.showUnavailableAssets),
                isShowActualPlace: optionsSet.has(AssignmentOptionEnum.showActualPlace),
                isShowAssetSchedule: optionsSet.has(AssignmentOptionEnum.showAssetSchedule),
                isIgnoreEmissionStandard: optionsSet.has(AssignmentOptionEnum.ignoreEmissionStandard),
                isIgnoreCertificates: optionsSet.has(AssignmentOptionEnum.ignoreCertificates),
            });
        },
        [onChange],
    );

    const options: Array<AssignmentOptionT | SpecialOptionEnum> = React.useMemo(
        () => [
            {
                label: (
                    <DropdownControlOptionLabel
                        withoutPaddings
                        icon={
                            <TargetIcon
                                strokeColor={StyleGuideColorsEnum.slate}
                                fillColor={StyleGuideColorsEnum.light}
                                size={DEFAULT_ICON_SIZE}
                            />
                        }
                        label={t('assignment.search-form.options.ignore-emission-standard')}
                    />
                ),
                value: AssignmentOptionEnum.ignoreEmissionStandard,
            },
            {
                label: (
                    <DropdownControlOptionLabel
                        withoutPaddings
                        icon={
                            <TargetIcon
                                strokeColor={StyleGuideColorsEnum.slate}
                                fillColor={StyleGuideColorsEnum.light}
                                size={DEFAULT_ICON_SIZE}
                            />
                        }
                        label={t('assignment.search-form.options.ignore-certificates')}
                    />
                ),
                value: AssignmentOptionEnum.ignoreCertificates,
            },
            SpecialOptionEnum.separator,
            {
                label: (
                    <DropdownControlOptionLabel
                        withoutPaddings
                        icon={
                            <TargetIcon
                                strokeColor={StyleGuideColorsEnum.slate}
                                fillColor={StyleGuideColorsEnum.light}
                                size={DEFAULT_ICON_SIZE}
                            />
                        }
                        label={t('assignment.search-form.options.show-actual-place')}
                    />
                ),
                value: AssignmentOptionEnum.showActualPlace,
            },
            {
                label: (
                    <DropdownControlOptionLabel
                        withoutPaddings
                        icon={<RouteIcon fillColor={StyleGuideColorsEnum.slate} />}
                        label={t('assignment.search-form.options.show-previous-next-tours')}
                    />
                ),
                value: AssignmentOptionEnum.showAssetSchedule,
            },
            {
                label: (
                    <DropdownControlOptionLabel
                        withoutPaddings
                        icon={
                            <UnavailableTrailerIcon
                                size={DEFAULT_ICON_SIZE}
                                fillColor={StyleGuideColorsEnum.light}
                                strokeColor={StyleGuideColorsEnum.slate}
                            />
                        }
                        label={t('assignment.search-form.options.show-unavailable-assets')}
                    />
                ),
                value: AssignmentOptionEnum.showUnavailableAssets,
            },
        ],
        [t],
    );

    return (
        <DropdownMultipleInput<AssignmentOptionT, AssignmentOptionT['value']>
            className={className}
            selectedValues={selectedOptionValues}
            options={options}
            onSelect={handleChange}
            renderOption={(option) => {
                if (!option) {
                    return null;
                }

                return <DropdownControlOptionLabel withoutPaddings label={option.label} />;
            }}
            renderTriggerContent={(selectedFilters) => {
                let icon = <TuneIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.brandDark} />;

                if (selectedFilters?.length) {
                    icon = (
                        <NotificationLabel
                            isShowPlusSign
                            count={selectedFilters?.length}
                            theme={NotificationLabelThemeEnum.charcoal}
                        />
                    );
                }

                return (
                    <DropdownControlOptionLabel
                        withoutPaddings
                        icon={icon}
                        label={t('assignment.search-form.options.trigger')}
                    />
                );
            }}
            getOptionValue={(option) => option?.value}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
        />
    );
});

export default AssignmentOptionsDropdown;
