import { formatPageQuerySort } from 'common/utils/page-sort';
import { FetchSpotRequestsPageQueryT } from 'broker-admin/store/spot-requests/models';
import { SpotRequestsQuickFilterEnum } from 'broker-admin/layouts/SpotRequestsPage/SpotRequestListPage/constants';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { SpotRequestsSortEnum, SpotRequestStatusEnum } from 'broker-admin/utils/api/spot-broker-tranziit/models';
import { PageSortT } from 'common/utils/query';

const getQueryFromQuickFilters = (
    quickFilter: SpotRequestsQuickFilterEnum | null | undefined,
): FetchSpotRequestsPageQueryT => {
    switch (quickFilter) {
        case SpotRequestsQuickFilterEnum.open: {
            return {
                status: SpotRequestStatusEnum.open,
            };
        }
        case SpotRequestsQuickFilterEnum.accepted: {
            return {
                status: SpotRequestStatusEnum.accepted,
            };
        }
        case SpotRequestsQuickFilterEnum.assigned: {
            return {
                status: SpotRequestStatusEnum.assigned,
            };
        }
        case SpotRequestsQuickFilterEnum.expired: {
            return {
                status: SpotRequestStatusEnum.expired,
            };
        }
        case SpotRequestsQuickFilterEnum.canceled: {
            return {
                status: SpotRequestStatusEnum.canceled,
            };
        }
        case SpotRequestsQuickFilterEnum.expireSoon: {
            return {
                expireSoon: true,
            };
        }
        case SpotRequestsQuickFilterEnum.newBids: {
            return {
                hasUnreadBids: true,
            };
        }
        default: {
            return {};
        }
    }
};

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<SpotRequestsSortEnum> | null,
): FetchSpotRequestsPageQueryT => {
    const dictTrailerTypeId = queryFilters[QueryFiltersKeysEnum.dictTrailerTypeId];

    return {
        shipperId: queryFilters[QueryFiltersKeysEnum.createdByShipperId] || undefined,

        createdBy: queryFilters[QueryFiltersKeysEnum.createdByUserId] || undefined,

        createdDateFrom: queryFilters[QueryFiltersKeysEnum.createdDateFrom] || undefined,
        createdDateTo: queryFilters[QueryFiltersKeysEnum.createdDateTo] || undefined,

        trailerTypeId: dictTrailerTypeId ? +dictTrailerTypeId : undefined,

        textSearch: queryFilters[QueryFiltersKeysEnum.searchText] || undefined,
        originAddress: queryFilters[QueryFiltersKeysEnum.pickupAddress] || undefined,
        destinationAddress: queryFilters[QueryFiltersKeysEnum.dropOffAddress] || undefined,

        originDateFrom: queryFilters[QueryFiltersKeysEnum.pickupDateFrom] || undefined,
        originDateTo: queryFilters[QueryFiltersKeysEnum.pickupDateTo] || undefined,

        destinationDateFrom: queryFilters[QueryFiltersKeysEnum.dropOffDateFrom] || undefined,
        destinationDateTo: queryFilters[QueryFiltersKeysEnum.dropOffDateTo] || undefined,

        // TODO use tabs
        ...getQueryFromQuickFilters(queryFilters[QueryFiltersKeysEnum.quickFilter]),

        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
