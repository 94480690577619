import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './AssignmentFooterContent.scss';
import AssignmentForm from '../../AssignmentForm/AssignmentForm';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    dispatchId: DispatchIdT | null;
    dispatchDetails: DispatchDetailsT | null;
    onCancel: () => void;
};

const AssignmentFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { dispatchDetails, dispatchId, onCancel } = props;

    if (!dispatchDetails) {
        return null;
    }

    return (
        <div className={cx('content')}>
            <AssignmentForm dispatchId={dispatchId} dispatchDetails={dispatchDetails} onCancel={onCancel} />
        </div>
    );
});

export default AssignmentFooterContent;
