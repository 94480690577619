import { SharableStateT } from '../models';
import { CarrierSuggestStateT } from './types';

export const selectFindCarriersQuery = (state: SharableStateT): CarrierSuggestStateT['query'] =>
    state.carrierSuggest.query;

export const selectFindCarriersRequest = (state: SharableStateT): CarrierSuggestStateT['requestStatus'] =>
    state.carrierSuggest.requestStatus;

export const selectCarriersIds = (state: SharableStateT): CarrierSuggestStateT['ids'] => state.carrierSuggest.ids;

export const selectCarriersById = (state: SharableStateT): CarrierSuggestStateT['byId'] => state.carrierSuggest.byId;
