import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SpotRequestStatStatusEnum, ApiSpotRequestStatusT } from 'broker-admin/utils/api/spot-broker-tranziit/models';

type PropsT = {
    className?: string;
    status: ApiSpotRequestStatusT | null | undefined;
};

export const SPOT_REQUEST_STATUS_T_MAP: Record<ApiSpotRequestStatusT, string> = {
    [SpotRequestStatStatusEnum.open]: 'common:spot-request-status.OPEN',
    [SpotRequestStatStatusEnum.expired]: 'common:spot-request-status.EXPIRED',
    [SpotRequestStatStatusEnum.accepted]: 'common:spot-request-status.ACCEPTED',
    [SpotRequestStatStatusEnum.assigned]: 'common:spot-request-status.ASSIGNED',
    [SpotRequestStatStatusEnum.canceled]: 'common:spot-request-status.CANCELED',
};

const SpotRequestStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(SPOT_REQUEST_STATUS_T_MAP[status]);
});

export default SpotRequestStatus;
