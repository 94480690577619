import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT, WaypointsFieldEnum } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';
import isEmpty from 'lodash/isEmpty';

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {};

    const waypointsFormValues = values[FieldsEnum.waypoints];
    waypointsFormValues.forEach((waypointFormValues, index) => {
        const waypointFormErrors: NonNullable<FormErrorsT[FieldsEnum.waypoints]>[number] = {
            ...checkNotEmpty(t, [WaypointsFieldEnum.timeWindow], waypointFormValues),
        };

        if (!isEmpty(waypointFormErrors)) {
            if (!errors[FieldsEnum.waypoints]) {
                errors[FieldsEnum.waypoints] = [];
            }

            errors[FieldsEnum.waypoints][index] = waypointFormErrors;
        }
    });

    return errors;
};

export default validate;
