import { DispatchT, FetchDispatchesPageQueryT } from './models';

import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_DISPATCHES_PAGE_REQUEST = 'broker-admin/dispatches/FETCH_DISPATCHES_PAGE_REQUEST';
export const FETCH_DISPATCHES_PAGE_REQUEST_BEGIN = 'broker-admin/dispatches/FETCH_DISPATCHES_PAGE_REQUEST_BEGIN';
export const FETCH_DISPATCHES_PAGE_REQUEST_ERROR = 'broker-admin/dispatches/FETCH_DISPATCHES_PAGE_REQUEST_ERROR';
export const FETCH_DISPATCHES_PAGE_REQUEST_SUCCESS = 'broker-admin/dispatches/FETCH_DISPATCHES_PAGE_REQUEST_SUCCESS';

export const RESET_DISPATCHES = 'broker-admin/dispatches/RESET_DISPATCHES';

export type DispatchesStateT = PaginationStateT<DispatchT, FetchDispatchesPageQueryT>;

export type FetchDispatchesPageActionT = {
    type: typeof FETCH_DISPATCHES_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchDispatchesPageQueryT;
    options?: FetchOptionsT;
};

export type FetchDispatchesPageBeginActionT = {
    type: typeof FETCH_DISPATCHES_PAGE_REQUEST_BEGIN;
    pageNumber: PageNumberT;
    query: FetchDispatchesPageQueryT;
};

export type FetchDispatchesPageSuccessActionT = {
    type: typeof FETCH_DISPATCHES_PAGE_REQUEST_SUCCESS;
    pageNumber: PageNumberT;
    pageResponse: PageResponseT<DispatchT> | null;
    query: FetchDispatchesPageQueryT;
};

export type FetchDispatchesPageErrorActionT = {
    type: typeof FETCH_DISPATCHES_PAGE_REQUEST_ERROR;
    query: FetchDispatchesPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
};

export type ResetDispatchesActionT = {
    type: typeof RESET_DISPATCHES;
    mutationListOptions: MutationListOptionsT;
};

export type DispatchesActionT =
    | FetchDispatchesPageActionT
    | FetchDispatchesPageBeginActionT
    | FetchDispatchesPageSuccessActionT
    | FetchDispatchesPageErrorActionT
    | ResetDispatchesActionT;
