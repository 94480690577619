import {
    FETCH_RATES_PAGE_REQUEST,
    FETCH_RATES_PAGE_REQUEST_BEGIN,
    FETCH_RATES_PAGE_REQUEST_ERROR,
    FETCH_RATES_PAGE_REQUEST_SUCCESS,
    FetchRatesPageActionT,
    FetchRatesPageBeginActionT,
    FetchRatesPageErrorActionT,
    FetchRatesPageSuccessActionT,
    RESET_RATES,
    ResetRatesActionT,
    UPDATE_RATES_REQUEST,
    UPDATE_RATES_REQUEST_BEGIN,
    UPDATE_RATES_REQUEST_ERROR,
    UPDATE_RATES_REQUEST_SUCCESS,
    UpdateRatesActionT,
    UpdateRatesBeginActionT,
    UpdateRatesErrorActionT,
    UpdateRatesSuccessActionT,
    UPLOAD_CSV_FILE_REQUEST,
    UPLOAD_CSV_FILE_REQUEST_BEGIN,
    UPLOAD_CSV_FILE_REQUEST_FAIL,
    UPLOAD_CSV_FILE_REQUEST_SUCCESS,
    UploadCsvFileActionT,
    UploadCsvFileBeginActionT,
    UploadCsvFileFailActionT,
    UploadCsvFileSuccessActionT,
} from './types';
import { FetchRatesPageQueryT, RateT } from './models';
import { UpdateRateStrategyEnum } from 'common/utils/api/models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchRatesPage = (
    pageNumber: PageNumberT,
    query: FetchRatesPageQueryT,
    options?: FetchOptionsT,
): FetchRatesPageActionT => ({
    type: FETCH_RATES_PAGE_REQUEST,
    pageNumber,
    query,
    options,
});

export const fetchRatesPageBegin = (
    query: FetchRatesPageQueryT,
    pageNumber: PageNumberT,
): FetchRatesPageBeginActionT => ({
    type: FETCH_RATES_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
});

export const fetchRatesPageSuccess = (
    query: FetchRatesPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<RateT> | null,
): FetchRatesPageSuccessActionT => ({
    type: FETCH_RATES_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
});

export const fetchRatesPageError = (
    query: FetchRatesPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchRatesPageErrorActionT => ({
    type: FETCH_RATES_PAGE_REQUEST_ERROR,
    error,
    pageNumber,
    query,
});

export const updateRates = (
    ids: Array<RateT['id']>,
    value: number,
    strategy: UpdateRateStrategyEnum,
): UpdateRatesActionT => ({
    type: UPDATE_RATES_REQUEST,
    ids,
    value,
    strategy,
});

export const updateRatesBegin = (): UpdateRatesBeginActionT => ({
    type: UPDATE_RATES_REQUEST_BEGIN,
});

export const updateRatesSuccess = (): UpdateRatesSuccessActionT => ({
    type: UPDATE_RATES_REQUEST_SUCCESS,
});

export const updateRatesError = (error: Error): UpdateRatesErrorActionT => ({
    type: UPDATE_RATES_REQUEST_ERROR,
    error,
});

export const resetRates = (mutationListOptions: MutationListOptionsT): ResetRatesActionT => ({
    type: RESET_RATES,
    mutationListOptions,
});

export const uploadCSVFile = (file: File): UploadCsvFileActionT => ({
    type: UPLOAD_CSV_FILE_REQUEST,
    file,
});

export const uploadCSVFileBegin = (): UploadCsvFileBeginActionT => ({
    type: UPLOAD_CSV_FILE_REQUEST_BEGIN,
});

export const uploadCSVFileSuccess = (): UploadCsvFileSuccessActionT => ({
    type: UPLOAD_CSV_FILE_REQUEST_SUCCESS,
});

export const uploadCSVFailError = (error: Error): UploadCsvFileFailActionT => ({
    type: UPLOAD_CSV_FILE_REQUEST_FAIL,
    error,
});
