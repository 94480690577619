import * as React from 'react';
import { useCallback } from 'react';

import classNames from 'classnames/bind';
import styles from './ShipperContractDetailsPage.scss';
import { useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import history, { goBackIfHasRePath } from 'common/utils/history';
import { PartnersDocumentsRoutesEnum, ShipperContractDetailsEnum } from 'broker-admin/constants';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import NavigationTabs, {
    NavigationTabConfigT,
    NavigationTabsPropsT,
} from 'common/components/NavigationTabs/NavigationTabs';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import PageTitle from 'common/components/PageTitle/PageTitle';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import { PartnerTypeEnum } from 'common/utils/api/models';
import BackButton from 'common/components/BackButton/BackButton';
import ShipperContractLanesLayout from 'broker-admin/layouts/ShipperContractDetailsPage/ShipperContractLanesLayout/ShipperContractLanesLayout';
import { useDispatch, useSelector } from 'react-redux';
import { selectShipperContractsByIds } from 'common/store/shipper-contracts/selectors';
import InlineLoader from 'common/components/InlineLoader/InlineLoader';
import { selectShipperContractDetailsState } from 'common/store/shipper-contract-details/selectors';
import PartnerContext from 'common/contexts/partner-context';
import isString from 'lodash/isString';
import ShipperContractDetailsLayout from 'broker-admin/layouts/ShipperContractDetailsPage/ShipperContractDetailsLayout/ShipperContractDetailsLayout';
import useRemoteFormActions from 'common/utils/hooks/useRemoteFormActions';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import { fetchShipperContractDetails } from 'common/store/shipper-contract-details/slice';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { carrierContractDetailsRefreshChannel } from 'common/store/carrier-contract-details/channels';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';

const cx = classNames.bind(styles);

type PropsT = {};

type ParamsT = {
    contractId: ShipperContractIdT;
    partnerType: PartnerTypeEnum;
    partnerId: PartnerIdT;
};

const ShipperContractDetailsPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    const remoteFormActions = useRemoteFormActions();

    const params = useParams<ParamsT>();
    const { contractId, partnerType, partnerId } = params;

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(
            fetchShipperContractDetails({
                partnerId,
                shipperContractId: contractId,
            }),
        );
    }, [partnerId, contractId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(
            fetchShipperContractDetails({
                partnerId,
                shipperContractId: contractId,
            }),
        );
    }, [partnerId, contractId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshHandler = useCallback(
        (event: InferChannelEventT<typeof carrierContractDetailsRefreshChannel>) => {
            if (event.contractId !== contractId) {
                return;
            }

            dispatch(
                fetchShipperContractDetails({
                    partnerId,
                    shipperContractId: event.contractId,
                }),
            );
        },
        [partnerId, contractId],
    );
    useChannelSubscribe(carrierContractDetailsRefreshChannel, refreshHandler);

    const handleSelectTab: NavigationTabsPropsT['onSelectTab'] = (to): void => {
        if (isString(to)) {
            const pathname = generatePath(to, {
                partnerId,
                partnerType,
                contractId,
            });

            history.push({
                pathname,
            });
        } else {
            history.push({
                ...to,
            });
        }
    };

    const tabsConfig = React.useMemo(
        (): NavigationTabConfigT[] => [
            {
                to: ShipperContractDetailsEnum.lanes,
                pathname: generatePath(ShipperContractDetailsEnum.lanes, {
                    partnerId,
                    partnerType,
                    contractId,
                }),
                render: () => <span>{t('common:shipper-contract-details.tabs.lanes')}</span>,
                testSelector: 'shipper-contract-lanes',
            },
            {
                to: ShipperContractDetailsEnum.details,
                pathname: generatePath(ShipperContractDetailsEnum.details, {
                    partnerId,
                    partnerType,
                    contractId,
                }),
                render: () => <span>{t('common:shipper-contract-details.tabs.details')}</span>,
                testSelector: 'shipper-contract-details',
            },
        ],
        [],
    );

    const goToPartnerShipperContracts = () => {
        goBackIfHasRePath(() => {
            const pathname = generatePath(PartnersDocumentsRoutesEnum.partnerShipperContracts, {
                partnerId,
                partnerType,
            });

            history.push({
                pathname,
            });
        });
    };

    const contractsByIds = useSelector(selectShipperContractsByIds(partnerId));
    const contract = contractsByIds[contractId as ShipperContractIdT];

    const contractDetailsState = useSelector(selectShipperContractDetailsState(contractId));

    const isLoadingContract = !contract && !contractDetailsState.data;

    const contractName = contract?.name || contractDetailsState?.data?.name || '-';

    const header = (
        <>
            <PageTitle
                title={t('page-titles.shipper-contract-details', {
                    contractName,
                })}
            />
            <ContentMargins>
                <TopBar>
                    <TopBarContent
                        leftNode={<BackButton onClick={goToPartnerShipperContracts} isDisabled={false} />}
                        title={isLoadingContract ? <InlineLoader lineHeight={20} /> : contractName}
                        rightNode={<NotificationsBarTrigger />}
                    />
                </TopBar>
                <div className={cx('controls')}>
                    <NavigationTabs tabsConfig={tabsConfig} onSelectTab={handleSelectTab} />
                </div>
            </ContentMargins>
        </>
    );

    const defaultRedirectPath = generatePath(ShipperContractDetailsEnum.lanes, {
        partnerId,
        partnerType,
        contractId,
    });

    const partnerContextValue = React.useMemo(() => {
        return {
            partnerType: params.partnerType,
            partnerId: params.partnerId,
        };
    }, [params.partnerId, params.partnerType]);

    return (
        <RemoteFormActionsContext.Provider value={remoteFormActions}>
            <PartnerContext.Provider value={partnerContextValue}>
                <ScrollableContent>
                    {header}
                    <Switch>
                        <Route path={ShipperContractDetailsEnum.details}>
                            <ShipperContractDetailsLayout
                                partnerId={partnerId}
                                partnerType={partnerType}
                                contractId={contractId}
                            />
                        </Route>
                        <Route path={ShipperContractDetailsEnum.lanes}>
                            <ShipperContractLanesLayout
                                partnerId={partnerId}
                                partnerType={partnerType}
                                contractId={contractId}
                            />
                        </Route>
                        <Route>
                            <Redirect to={defaultRedirectPath} />
                        </Route>
                    </Switch>
                    <SideBars />
                </ScrollableContent>
            </PartnerContext.Provider>
        </RemoteFormActionsContext.Provider>
    );
});

export default ShipperContractDetailsPage;
