import requestStatus from 'common/utils/request-status';
import {
    DispatchEditActionT,
    DispatchEditStateT,
    RESET,
    UPDATE_DISPATCH_REQUEST_BEGIN,
    UPDATE_DISPATCH_REQUEST_ERROR,
    UPDATE_DISPATCH_REQUEST_SUCCESS,
    UPDATE_DOCKING_HOURS,
} from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: DispatchEditStateT = {
    dispatchId: null,
    requestStatus: requestStatus.initial,
};

export default (state = initialState, action: DispatchEditActionT | DestroySessionActionT): DispatchEditStateT => {
    switch (action.type) {
        case UPDATE_DISPATCH_REQUEST_BEGIN: {
            const { dispatchId } = action;

            if (state.dispatchId !== dispatchId) {
                return state;
            }

            return {
                ...state,
                requestStatus: requestStatus.loading,
            };
        }

        case UPDATE_DISPATCH_REQUEST_SUCCESS: {
            const { dispatchId } = action;

            if (state.dispatchId !== dispatchId) {
                return state;
            }

            return {
                ...state,
                requestStatus: requestStatus.ok,
            };
        }

        case UPDATE_DISPATCH_REQUEST_ERROR: {
            const { dispatchId, error } = action;

            if (state.dispatchId !== dispatchId) {
                return state;
            }

            return {
                ...state,
                requestStatus: requestStatus.setError(error),
            };
        }

        case UPDATE_DOCKING_HOURS: {
            const { dispatchId } = action;

            return {
                ...state,
                dispatchId,
                requestStatus: requestStatus.initial,
            };
        }

        case DESTROY_SESSION:
        case RESET: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
};
