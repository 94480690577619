import { PartnersStateT } from './types';
import { StateT } from '../models';
import { RequestStatusT } from 'common/utils/request-status';

export const selectPartnersPages = (state: StateT): PartnersStateT['pages'] => state.partners.pages;

export const selectPartnersQuery = (state: StateT): PartnersStateT['query'] => state.partners.query;

export const selectPartnersTotal = (state: StateT): PartnersStateT['total'] => state.partners.total;

export const selectPartnersByIds = (state: StateT): PartnersStateT['byId'] => state.partners.byId;

export const selectPartnerCreationRequest = (state: StateT): RequestStatusT => state.partners.createPartnerRequest;
