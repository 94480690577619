import { BidsSortEnum } from '../constants';
import { SpotBidT } from 'broker-admin/utils/api/spot-broker-tranziit/models';
import { PageSortT, SortDirectionEnum } from 'common/utils/query';

type BidsSortT = (bidA: SpotBidT | null | undefined, bidB: SpotBidT | null | undefined) => number;

const sorters: Record<BidsSortEnum, BidsSortT> = {
    [BidsSortEnum.date]: (bidA, bidB) => {
        const bidDateA = bidA?.bidDate || '';
        const bidDateB = bidB?.bidDate || '';

        if (bidDateA === bidDateB) {
            return 0;
        }

        if (bidDateB < bidDateA) {
            return 1;
        }

        return -1;
    },
    [BidsSortEnum.price]: (bidA, bidB) => {
        return (bidA?.bidPrice || 0) - (bidB?.bidPrice || 0);
    },
};

export const useBidsSort = (
    sort: PageSortT<BidsSortEnum>,
    bids: Array<SpotBidT | null | undefined>,
): Array<SpotBidT | null | undefined> => {
    const { value, direction } = sort;
    if (!value) {
        return bids;
    }

    const sorter = (bidA: SpotBidT | null | undefined, bidB: SpotBidT | null | undefined): number => {
        const sortDirectiont = direction === SortDirectionEnum.ASC ? 1 : -1;
        const sortResult = sorters[value](bidA, bidB);

        return sortDirectiont * sortResult;
    };

    return bids.sort(sorter);
};
