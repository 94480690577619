import { CarriersUtilizationStateT } from './types';
import { SharableStateT } from '../models';
import { CarrierUtilizationT } from './models';
import { getHash } from './utils';

export const selectFetchRequestStatus = (state: SharableStateT): CarriersUtilizationStateT['fetchRequest'] =>
    state.carriersUtilization.fetchRequest;

export const selectCarrierUtilization =
    (id: CarrierIdT, date: string) =>
    (state: SharableStateT): CarrierUtilizationT | null => {
        const hash = getHash(id, date);

        return state.carriersUtilization.byHash[hash] || null;
    };

export const selectCarriersUtilizationByHash = (state: SharableStateT): CarriersUtilizationStateT['byHash'] => {
    return state.carriersUtilization.byHash || {};
};
