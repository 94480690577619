import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import WarningFilterOptionsDropdown, {
    WarningFilterOptionT,
} from 'common/components/Table/WarningFilterOptionsDropdown/WarningFilterOptionsDropdown';
import { SpecialOptionEnum } from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { colorCodingNotificationLabelTheme } from 'common/components/notifications/NotificationLabel/NotificationLabel';
import { DispatchStatsT } from 'broker-admin/store/dispatch-stats/models';

type PropsT = {
    className?: string;
    stat: DispatchStatsT | null;
    queryFilters: QueryFiltersT;
    onQueryFiltersChange: (queryFiltersChanges: Partial<QueryFiltersT>) => void;
};

const warningsQueryFiltersKeys = [
    QueryFiltersKeysEnum.runningOutOfTimeToAssignCarrier,
    QueryFiltersKeysEnum.runOutOfTimeToAssignCarrier,
    QueryFiltersKeysEnum.runningOutOfTimeToAssignAssets,
    QueryFiltersKeysEnum.runOutOfTimeToAssignAssets,
    QueryFiltersKeysEnum.runningOutOfTimeToAssignDriver,
    QueryFiltersKeysEnum.runOutOfTimeToAssignDriver,
    QueryFiltersKeysEnum.tourDelaying,
    QueryFiltersKeysEnum.noShow,
    QueryFiltersKeysEnum.shipmentMissed,
] as const;

const WarningsFilter: React.FC<PropsT> = React.memo((props) => {
    const { className, stat, queryFilters, onQueryFiltersChange } = props;

    const { t } = useTranslation();

    const options = React.useMemo(
        (): Array<WarningFilterOptionT<(typeof warningsQueryFiltersKeys)[number]> | SpecialOptionEnum> => [
            SpecialOptionEnum.separator,
            {
                id: QueryFiltersKeysEnum.runningOutOfTimeToAssignCarrier,
                label: t('dispatches.list.warning-filters.running-out-of-time-to-assign-carrier'),
                notificationProps: {
                    count: stat?.runningOutToAssignCarrier,
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            {
                id: QueryFiltersKeysEnum.runOutOfTimeToAssignCarrier,
                label: t('dispatches.list.warning-filters.run-out-of-time-to-assign-carrier'),
                notificationProps: {
                    count: stat?.runOutToAssignCarrier,
                    theme: colorCodingNotificationLabelTheme.warning,
                },
            },
            SpecialOptionEnum.separator,
            {
                id: QueryFiltersKeysEnum.runningOutOfTimeToAssignAssets,
                label: t('dispatches.list.warning-filters.running-out-of-time-to-assign-assets'),
                notificationProps: {
                    count: stat?.runningOutOfTimeToAssignAssets,
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            {
                id: QueryFiltersKeysEnum.runOutOfTimeToAssignAssets,
                label: t('dispatches.list.warning-filters.run-out-of-time-to-assign-assets'),
                notificationProps: {
                    count: stat?.runOutOfTimeToAssignAssets,
                    theme: colorCodingNotificationLabelTheme.warning,
                },
            },
            {
                id: QueryFiltersKeysEnum.runningOutOfTimeToAssignDriver,
                label: t('dispatches.list.warning-filters.running-out-of-time-to-assign-driver'),
                notificationProps: {
                    count: stat?.runningOutOfTimeToAssignDriver,
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            {
                id: QueryFiltersKeysEnum.runOutOfTimeToAssignDriver,
                label: t('dispatches.list.warning-filters.run-out-of-time-to-assign-driver'),
                notificationProps: {
                    count: stat?.runOutOfTimeToAssignDriver,
                    theme: colorCodingNotificationLabelTheme.warning,
                },
            },
            SpecialOptionEnum.separator,
            {
                id: QueryFiltersKeysEnum.tourDelaying,
                label: t('dispatches.list.warning-filters.tour-delaying'),
                notificationProps: {
                    count: stat?.delaying,
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            SpecialOptionEnum.separator,
            {
                id: QueryFiltersKeysEnum.noShow,
                label: t('dispatches.list.warning-filters.no-show'),
                notificationProps: {
                    count: stat?.noShow,
                    theme: colorCodingNotificationLabelTheme.warning,
                },
            },
            {
                id: QueryFiltersKeysEnum.shipmentMissed,
                label: t('dispatches.list.warning-filters.shipment-missed'),
                notificationProps: {
                    count: stat?.missed,
                    theme: colorCodingNotificationLabelTheme.warning,
                },
            },
        ],
        [t, stat],
    );

    const value = React.useMemo(() => {
        return warningsQueryFiltersKeys.filter((queryFiltersKey) => queryFilters[queryFiltersKey]);
    }, [queryFilters]);

    const handleSelect = (values: Array<(typeof warningsQueryFiltersKeys)[number]>) => {
        const queryFiltersChanges = warningsQueryFiltersKeys.reduce<Partial<QueryFiltersT>>((acc, queryFiltersKey) => {
            acc[queryFiltersKey] = values.includes(queryFiltersKey) ? true : undefined;

            return acc;
        }, {});

        onQueryFiltersChange(queryFiltersChanges);
    };

    return (
        <WarningFilterOptionsDropdown<(typeof warningsQueryFiltersKeys)[number]>
            className={className}
            value={value}
            onSelect={handleSelect}
            options={options}
        />
    );
});

export default WarningsFilter;
