import { put, select, takeEvery } from 'redux-saga/effects';
import {
    FETCH_ADDITIONAL_SERVICES_REQUEST,
    FetchAdditionalServicesActionT,
    UPDATE_ADDITIONAL_SERVICE_REQUEST,
    UpdateAdditionalServiceActionT,
} from './types';
import {
    fetchAdditionalServicesBegin,
    fetchAdditionalServicesError,
    fetchAdditionalServicesSuccess,
    updateAdditionalServiceBegin,
    updateAdditionalServiceError,
    updateAdditionalServiceSuccess,
} from './actions';
import { selectFetchAdditionalServicesRequest, selectUpdateAdditionalServiceRequest } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { AdditionalServiceT } from 'broker-admin/store/price-settings/additional-services/models';
import values from 'lodash/values';
import flatten from 'lodash/flatten';

function* fetchAdditionalServicesSaga(action: FetchAdditionalServicesActionT): WrapGeneratorT<void> {
    const { options } = action;

    const requestStatus: ReturnType<typeof selectFetchAdditionalServicesRequest> = yield select(
        selectFetchAdditionalServicesRequest,
    );
    if (!checkNeedRequest(requestStatus, options)) {
        return;
    }

    yield put(fetchAdditionalServicesBegin());

    const [error, response]: ReturnApiT<typeof brokerTranziitApi.fetchAdditionalServices> =
        yield brokerTranziitApi.fetchAdditionalServices();

    if (error) {
        yield put(fetchAdditionalServicesError(error));
        return;
    }

    const list: Array<AdditionalServiceT> = flatten(values(response));
    yield put(fetchAdditionalServicesSuccess(list));
}

function* updateAdditionalServiceSaga(action: UpdateAdditionalServiceActionT): WrapGeneratorT<void> {
    const { serviceId, cost } = action;

    const requestStatus: ReturnType<typeof selectUpdateAdditionalServiceRequest> = yield select(
        selectUpdateAdditionalServiceRequest,
    );
    if (requestStatus.loading) {
        return;
    }

    yield put(updateAdditionalServiceBegin());

    const [error]: ReturnApiT<typeof brokerTranziitApi.updateAdditionalService> =
        yield brokerTranziitApi.updateAdditionalService(serviceId, cost);
    if (error) {
        yield put(updateAdditionalServiceError(error));
        return;
    }

    yield put(updateAdditionalServiceSuccess(serviceId, cost));
}

function* additionalServicesSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_ADDITIONAL_SERVICES_REQUEST, fetchAdditionalServicesSaga);
    yield takeEvery(UPDATE_ADDITIONAL_SERVICE_REQUEST, updateAdditionalServiceSaga);
}

export default additionalServicesSaga;
