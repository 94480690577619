import { put, takeEvery, select } from 'redux-saga/effects';

import { FETCH_MANAGERS_REQUEST, FetchManagersActionT } from './types';
import { fetchManagersBegin, fetchManagersError, fetchManagersSuccess } from './actions';
import { selectFetchManagersRequest } from './selectors';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';

function* fetchManagersSaga(action: FetchManagersActionT): WrapGeneratorT<void> {
    const request: ReturnType<typeof selectFetchManagersRequest> = yield select(selectFetchManagersRequest);
    if (request.ok) {
        return;
    }

    yield put(fetchManagersBegin());

    const [error, managers]: ReturnApiT<typeof brokerTranziitApi.fetchPartnerManagers> =
        yield brokerTranziitApi.fetchPartnerManagers();
    if (error) {
        yield put(fetchManagersError(error));
        return;
    }

    yield put(fetchManagersSuccess(managers || []));
}

function* userSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_MANAGERS_REQUEST, fetchManagersSaga);
}

export default userSaga;
