export enum FieldsEnum {
    minimalShipmentCost = 'MINIMAL_COST_FOR_SHIPMENT',
    driverSalaryPerHour = 'DRIVER_SALARY_PER_HOUR',
    minimalCostForTruck = 'MINIMAL_COST_FOR_TRUCK',
    minimalCostForTrailer = 'MINIMAL_COST_FOR_TRAILER',
    layoverCostPerHour = 'COST_PER_ONE_HOUR_LAYOVER',
    layoverCostPerDay = 'COST_PER_ONE_DAY_LAYOVER',
    layoverFreeHours = 'LAYOVER_FREE_HOURS',
    urgentOverPrice = 'URGENT_OVERPRICE',
    overPriceForEveryAdditionalStop = 'OVERPRICE_FOR_EVERY_ADDITIONAL_STOP',
    costPerGreenShipment = 'COST_PER_GREEN_SHIPMENT',
    tranziitServiceFeeFixedMode = 'TRANZIIT_SERVICE_FEE_FIXED_MODE',
    tranziitServiceFeePercent = 'TRANZIIT_SERVICE_FEE_PERCENT',
    tranziitServiceFeeFixed = 'TRANZIIT_SERVICE_FEE_FIXED',
    pricingStrategy = 'PRICING_STRATEGY',
    truckConsumption = 'TRUCK_CONSUMPTION',
    reeferConsumption = 'REEFER_CONSUMPTION',
    usePeriodicRulesForMonths = 'use-periodic-rules-for-months',
    usePeriodicRulesForWeekdays = 'use-periodic-rules-for-weekdays',
    rateMatrixSpread = 'RATE_MATRIX_SPREAD',
}

export type FormValuesT = {
    [FieldsEnum.minimalShipmentCost]: string;
    [FieldsEnum.driverSalaryPerHour]: string;
    [FieldsEnum.minimalCostForTruck]: string;
    [FieldsEnum.minimalCostForTrailer]: string;
    [FieldsEnum.layoverCostPerHour]: string;
    [FieldsEnum.layoverCostPerDay]: string;
    [FieldsEnum.layoverFreeHours]: string;
    [FieldsEnum.urgentOverPrice]: string;
    [FieldsEnum.overPriceForEveryAdditionalStop]: string;
    [FieldsEnum.costPerGreenShipment]: string;
    [FieldsEnum.tranziitServiceFeeFixedMode]: string;
    [FieldsEnum.tranziitServiceFeePercent]: string;
    [FieldsEnum.tranziitServiceFeeFixed]: string;
    [FieldsEnum.pricingStrategy]: string;
    [FieldsEnum.truckConsumption]: string;
    [FieldsEnum.reeferConsumption]: string;
    [FieldsEnum.rateMatrixSpread]: string;
    [FieldsEnum.usePeriodicRulesForMonths]: boolean;
    [FieldsEnum.usePeriodicRulesForWeekdays]: boolean;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
