import { isNonNil } from 'common/utils';
import Supercluster from 'supercluster';
import { DispatchAssigmentStateT } from 'broker-admin/store/dispatch-assigment/types';
import { LinksT } from 'broker-admin/store/dispatch-assigment/models';

export type GeoJsonPropertiesT = {
    id: string;
    truckId: string | null;
    trailerId: string | null;
};

type GetVehicleGeoJsonParamsT = {
    truckId: TruckIdT | null;
    trailerId: TrailerIdT | null;
    trailerById: DispatchAssigmentStateT['trailerById'];
    truckById: DispatchAssigmentStateT['truckById'];
};

const getVehicleGeoJson = (params: GetVehicleGeoJsonParamsT) => {
    const { truckId, trailerId, truckById, trailerById } = params;

    const vehicleDetails = trailerById[trailerId as TrailerIdT] || truckById[truckId as TruckIdT];
    if (!vehicleDetails?.expectedLongitude || !vehicleDetails?.expectedLatitude) {
        return null;
    }

    const geoJson: Supercluster.PointFeature<GeoJsonPropertiesT> = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [vehicleDetails.expectedLongitude, vehicleDetails.expectedLatitude],
        },
        properties: {
            id: `${truckId}_${trailerId}`,
            truckId: truckId || null,
            trailerId: trailerId || null,
        },
    };

    return geoJson;
};

type VehicleT = {
    trailerId: TrailerIdT | null;
    truckId: TruckIdT | null;
};

type CreateVehiclesGeoJsonParamsT = {
    vehicles: Array<VehicleT>;
    trailerById: DispatchAssigmentStateT['trailerById'];
    truckById: DispatchAssigmentStateT['truckById'];
    options: {
        showCanBeInTime: boolean;
        showNotCanBeInTime: boolean;
        showSelectedNotCanBeInTime: boolean;
    };
    selectedTrailerId: TrailerIdT | null;
    selectedTruckId: TruckIdT | null;
    vehicleLinks: LinksT;
};

export const createVehiclesGeoJson = (
    params: CreateVehiclesGeoJsonParamsT,
): Array<Supercluster.PointFeature<GeoJsonPropertiesT>> => {
    const { vehicles, truckById, trailerById, options, selectedTrailerId, selectedTruckId, vehicleLinks } = params;

    const availableTrailersToHooks = truckById[selectedTruckId as TruckIdT]?.assetsToHook || null;
    const availableTrailersToHooksSet = availableTrailersToHooks ? new Set(availableTrailersToHooks) : null;
    const availableTrucksToHooks = trailerById[selectedTrailerId as TrailerIdT]?.assetsToHook || null;
    const availableTrucksToHooksSet = availableTrucksToHooks ? new Set(availableTrucksToHooks) : null;

    let outOfAreaSelectedTrailerId = selectedTrailerId;
    let outOfAreaSelectedTruckId = selectedTruckId;

    const prepareVehicleGeoJson = (vehicle: VehicleT): TODO => {
        let viewTruckId = null;
        let viewTrailerId = null;

        const canInTimeTruck =
            !truckById[vehicle.truckId as TruckIdT]?.invalidReason &&
            (availableTrucksToHooksSet ? availableTrucksToHooksSet.has(vehicle.truckId as TruckIdT) : true);
        const isShowTruck =
            (options?.showCanBeInTime && canInTimeTruck === true) ||
            (options?.showNotCanBeInTime && canInTimeTruck === false) ||
            (options?.showSelectedNotCanBeInTime &&
                selectedTruckId &&
                selectedTruckId === vehicle.truckId &&
                canInTimeTruck === false);
        if (vehicle.truckId && isShowTruck) {
            viewTruckId = vehicle.truckId;
        }

        const canInTimeTrailer =
            !trailerById[vehicle.trailerId as TrailerIdT]?.invalidReason &&
            (availableTrailersToHooksSet ? availableTrailersToHooksSet.has(vehicle.trailerId as TrailerIdT) : true);
        const isShowTrailer =
            (options?.showCanBeInTime && canInTimeTrailer === true) ||
            (options?.showNotCanBeInTime && canInTimeTrailer === false) ||
            (options?.showSelectedNotCanBeInTime &&
                selectedTrailerId &&
                selectedTrailerId === vehicle.trailerId &&
                canInTimeTrailer === false);
        if (vehicle.trailerId && isShowTrailer) {
            viewTrailerId = vehicle.trailerId;
        }

        if (!viewTruckId && !viewTrailerId) {
            return null;
        }

        return getVehicleGeoJson({
            truckId: vehicle.truckId,
            trailerId: vehicle.trailerId,
            trailerById,
            truckById,
        });
    };

    const vehiclesGeoJsons = vehicles.map((vehicle) => {
        const vehiclesGeoJson = prepareVehicleGeoJson(vehicle);

        if (vehiclesGeoJson && outOfAreaSelectedTrailerId === vehicle.trailerId) {
            outOfAreaSelectedTrailerId = null;
        }
        if (vehiclesGeoJson && outOfAreaSelectedTruckId === vehicle.truckId) {
            outOfAreaSelectedTruckId = null;
        }

        return vehiclesGeoJson;
    });

    const outOfAreaVehicles: Array<VehicleT> = [];

    const outOfAreaSelectedLinkedTruckId = vehicleLinks.byTrailerId[outOfAreaSelectedTrailerId as TrailerIdT] || null;
    if (outOfAreaSelectedLinkedTruckId) {
        outOfAreaVehicles.push({
            trailerId: outOfAreaSelectedTrailerId,
            truckId: outOfAreaSelectedLinkedTruckId,
        });
    } else {
        if (outOfAreaSelectedTrailerId) {
            outOfAreaVehicles.push({
                trailerId: outOfAreaSelectedTrailerId,
                truckId: null,
            });
        }

        if (outOfAreaSelectedTruckId) {
            outOfAreaVehicles.push({
                trailerId: null,
                truckId: outOfAreaSelectedTruckId,
            });
        }
    }

    const outOfAreaVehiclesGeoJsons = outOfAreaVehicles.map(prepareVehicleGeoJson);

    return [...outOfAreaVehiclesGeoJsons, ...vehiclesGeoJsons].filter(isNonNil);
};
