import { FieldsEnum, FormValuesT } from './constants';
import { SystemSettingsT } from 'broker-admin/store/price-settings/system-settings/models';

const getInitialValues = (systemSettings: SystemSettingsT | null | undefined): FormValuesT => {
    return {
        [FieldsEnum.firstRadiusOfAssignmentFilter]: systemSettings?.FIRST_RADIUS_OF_ASSIGNMENT_FILTER || '',
        [FieldsEnum.secondRadiusOfAssignmentFilter]: systemSettings?.SECOND_RADIUS_OF_ASSIGNMENT_FILTER || '',
        [FieldsEnum.thirdRadiusOfAssignmentFilter]: systemSettings?.THIRD_RADIUS_OF_ASSIGNMENT_FILTER || '',
        [FieldsEnum.timeToDropTrailer]: systemSettings?.TIME_TO_DROP_TRAILER || '',
        [FieldsEnum.timeToHookTrailer]: systemSettings?.TIME_TO_HOOK_TRAILER || '',
        [FieldsEnum.timeToPickupTruck]: systemSettings?.TIME_TO_PICKUP_TRUCK || '',
        [FieldsEnum.timeToDropTruck]: systemSettings?.TIME_TO_DROP_TRUCK || '',
        [FieldsEnum.geoMatrixCoefficient]: systemSettings?.GEO_MATRIX_COEFFICIENT || '',
        [FieldsEnum.averageTruckSpeed]: systemSettings?.AVERAGE_TRUCK_SPEED || '',
    };
};

export default getInitialValues;
