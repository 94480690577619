import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './CostsCell.scss';

import Money from 'common/components/Money/Money';
import { CurrencyEnum } from 'common/constants';
import ColoredDiff from 'common/components/ColoredDiff/ColoredDiff';
import MoneyDiff from 'common/components/MoneyDiff/MoneyDiff';

type PropsT = {
    basePrice: number | undefined;
    lowestBidsPrice: number | undefined;
};

const cx = classNames.bind(styles);

const CostsCell: React.FC<PropsT> = React.memo((props) => {
    const { basePrice, lowestBidsPrice } = props;

    return (
        <div className={cx('wrap')}>
            <div className={cx('money')}>
                <Money amount={basePrice} currency={CurrencyEnum.EUR} />
            </div>
            <div className={cx('money')}>
                <MoneyDiff baseValue={lowestBidsPrice} value={basePrice}>
                    {(diff) => (
                        <ColoredDiff diff={diff}>
                            <Money amount={lowestBidsPrice} currency={CurrencyEnum.EUR} />
                        </ColoredDiff>
                    )}
                </MoneyDiff>
            </div>
        </div>
    );
});

export default CostsCell;
