import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './SettingsPage.scss';
import { useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import history from 'common/utils/history';
import { SettingsRoutesEnum } from 'broker-admin/constants';
import { Route, Switch, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import NavigationTabs, {
    NavigationTabConfigT,
    NavigationTabsPropsT,
} from 'common/components/NavigationTabs/NavigationTabs';
import RateMatrix from './RateMatrix/RateMatrix';
import PriceSettingsRules from './PriceSettingsRules/PriceSettingsRules';
import SystemSettings from 'broker-admin/layouts/SettingsPage/SystemSettings/SystemSettings';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import RemoteFormActions from 'common/components/forms/RemoteFormActions/RemoteFormActions';
import useRemoteFormActions from 'common/utils/hooks/useRemoteFormActions';
import AdditionalServicesSettings from './AdditionalServicesSettings/AdditionalServicesSettings';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import PageTitle from 'common/components/PageTitle/PageTitle';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import isString from 'lodash/isString';

const cx = classNames.bind(styles);

type PropsT = {};

type ParamsT = {};

const SettingsPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    const params = useParams<ParamsT>();

    const handleSelectTab: NavigationTabsPropsT['onSelectTab'] = (to): void => {
        if (isString(to)) {
            const pathname = generatePath(to, params);

            history.push({
                pathname,
            });
        } else {
            history.push({
                ...to,
            });
        }
    };

    const tabsConfig = React.useMemo(
        (): NavigationTabConfigT[] => [
            {
                to: SettingsRoutesEnum.rateMatrix,
                pathname: SettingsRoutesEnum.rateMatrix,
                render: () => <span>{t('price-settings.tabs.rate-matrix')}</span>,
                testSelector: 'rate-matrix',
            },
            {
                to: SettingsRoutesEnum.rules,
                pathname: SettingsRoutesEnum.rules,
                render: () => <span>{t('price-settings.tabs.rules')}</span>,
                testSelector: 'rules',
            },
            {
                to: SettingsRoutesEnum.systemSettings,
                pathname: SettingsRoutesEnum.systemSettings,
                render: () => <span>{t('price-settings.tabs.system-settings')}</span>,
                testSelector: 'system-settings',
            },
            {
                to: SettingsRoutesEnum.additionalServices,
                pathname: SettingsRoutesEnum.additionalServices,
                render: () => <span>{t('price-settings.tabs.additional-services')}</span>,
                testSelector: 'additional-services',
            },
        ],
        [],
    );

    const remoteFormActions = useRemoteFormActions();

    const header = (
        <>
            <PageTitle title={t('page-titles.settings')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent
                        title={t('price-settings.title')}
                        rightNode={
                            <>
                                <RemoteFormActions className={cx('header__controls')} />
                                <NotificationsBarTrigger />
                            </>
                        }
                    />
                </TopBar>
                <div className={cx('controls')}>
                    <NavigationTabs tabsConfig={tabsConfig} onSelectTab={handleSelectTab} />
                    {/* <div className={cx('actions')}> */}
                    {/*    <TransparentTrigger */}
                    {/*        label={t('common:activity')} */}
                    {/*        onClick={() => { */}
                    {/*            // TODO */}
                    {/*        }} */}
                    {/*        rightIcon={<ArrowsIcon />} */}
                    {/*    /> */}
                    {/* </div> */}
                </div>
            </ContentMargins>
        </>
    );

    return (
        <RemoteFormActionsContext.Provider value={remoteFormActions}>
            <ScrollableContent>
                {header}
                <Switch>
                    <Route path={SettingsRoutesEnum.rateMatrix}>
                        <RateMatrix />
                    </Route>
                    <Route path={SettingsRoutesEnum.rules}>
                        <PriceSettingsRules />
                    </Route>
                    <Route path={SettingsRoutesEnum.systemSettings}>
                        <SystemSettings />
                    </Route>
                    <Route path={SettingsRoutesEnum.additionalServices}>
                        <AdditionalServicesSettings />
                    </Route>
                </Switch>
                <SideBars />
            </ScrollableContent>
        </RemoteFormActionsContext.Provider>
    );
});

export default SettingsPage;
