import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkIsPositiveNumberValue, checkNotEmpty } from 'common/utils/form-validators';

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, [FieldsEnum.totalPrice], values),
        ...checkIsPositiveNumberValue(t, [FieldsEnum.totalPrice], values),
    };

    return errors;
};

export default validate;
