import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    strokeColor: StyleGuideColorsEnum;
    size: number;
};

const AssetLinkIcon: React.FC<PropsT> = (props) => {
    const { size, className, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" className={className}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M3.925 10A2.327 2.327 0 016.25 7.675h3V6.25h-3C4.18 6.25 2.5 7.93 2.5 10c0 2.07 1.68 3.75 3.75 3.75h3v-1.425h-3A2.327 2.327 0 013.925 10zM7 10.75h6v-1.5H7v1.5zm6.75-4.5h-3v1.425h3A2.327 2.327 0 0116.075 10a2.327 2.327 0 01-2.325 2.325h-3v1.425h3c2.07 0 3.75-1.68 3.75-3.75 0-2.07-1.68-3.75-3.75-3.75z"
                />
            </g>
        </svg>
    );
};

AssetLinkIcon.displayName = 'AssetLinkIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default AssetLinkIcon;

export const AssetLinkIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }

        if (isDisabled) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            size: DEFAULT_ICON_SIZE,
            strokeColor: hasValue || isFocused ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.light,
        };
    },
};

/**
 *  @deprecated create/use ...Props class
 * */
export const getAssetLinkIconProps = (
    preset:
        | 'default'
        | 'selected'
        | 'assignment-normal'
        | 'assignment-selected'
        | 'assignment-not-in-time-normal'
        | 'assignment-not-in-time-selected',
): PropsT => {
    switch (preset) {
        case 'assignment-normal': {
            return {
                strokeColor: StyleGuideColorsEnum.white,
                size: DEFAULT_ICON_SIZE,
            };
        }
        case 'assignment-selected': {
            return {
                strokeColor: StyleGuideColorsEnum.brandAccent,
                size: DEFAULT_ICON_SIZE,
            };
        }
        case 'assignment-not-in-time-normal': {
            return {
                strokeColor: StyleGuideColorsEnum.slate,
                size: DEFAULT_ICON_SIZE,
            };
        }
        case 'assignment-not-in-time-selected': {
            return {
                strokeColor: StyleGuideColorsEnum.brandDark,
                size: DEFAULT_ICON_SIZE,
            };
        }
        case 'selected': {
            return {
                strokeColor: StyleGuideColorsEnum.brandAccent,
                size: DEFAULT_ICON_SIZE,
            };
        }
        default: {
            return {
                strokeColor: StyleGuideColorsEnum.white,
                size: DEFAULT_ICON_SIZE,
            };
        }
    }
};
