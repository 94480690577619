import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT, MIN_VALID_FROM_VALID_TILL_DAYS } from './constants';
import { checkMaxLength, checkNotEmpty } from 'common/utils/form-validators';
import { getDayDiff } from 'common/utils/time';

const REQUIRED_FIELDS = [FieldsEnum.name, FieldsEnum.activateDate, FieldsEnum.validTillDate, FieldsEnum.file];

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkMaxLength(t, [FieldsEnum.name, FieldsEnum.number], values, 250),
    };

    const activateDate = values[FieldsEnum.activateDate];
    const validTillDate = values[FieldsEnum.validTillDate];

    if (activateDate && validTillDate && getDayDiff(activateDate, validTillDate) < MIN_VALID_FROM_VALID_TILL_DAYS) {
        errors[FieldsEnum.validTillDate] = t('sidebars.upload-shipper-contract.errors.valid-till-inteval-valid-from', {
            count: MIN_VALID_FROM_VALID_TILL_DAYS,
        });
    }

    if (activateDate && validTillDate && activateDate >= validTillDate) {
        errors[FieldsEnum.validTillDate] = t('sidebars.upload-shipper-contract.errors.valid-till-earlier-valid-from');
    }

    return errors;
};

export default validate;
