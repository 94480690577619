import { SpotRequestsStatsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';
import { ApiSpotRequestsStatsT } from 'broker-admin/utils/api/spot-broker-tranziit/models';

export const FETCH_SPOT_REQUESTS_STATS_REQUEST = 'broker/spot-requests-stats/FETCH_SPOT_REQUESTS_STATS_REQUEST';
export const FETCH_SPOT_REQUESTS_STATS_REQUEST_BEGIN =
    'broker/spot-requests-stats/FETCH_SPOT_REQUESTS_STATS_REQUEST_BEGIN';
export const FETCH_SPOT_REQUESTS_STATS_REQUEST_ERROR =
    'broker/spot-requests-stats/FETCH_SPOT_REQUESTS_STATS_REQUEST_ERROR';
export const FETCH_SPOT_REQUESTS_STATS_REQUEST_SUCCESS =
    'broker/spot-requests-stats/FETCH_SPOT_REQUESTS_STATS_REQUEST_SUCCESS';

export type SpotRequestsStatsStateT = {
    stats: SpotRequestsStatsT | null;
    fetchRequest: RequestStatusT;
};

export type FetchSpotRequestsStatsActionT = {
    type: typeof FETCH_SPOT_REQUESTS_STATS_REQUEST;
    options?: FetchOptionsT;
};

export type FetchSpotRequestsStatsBeginActionT = {
    type: typeof FETCH_SPOT_REQUESTS_STATS_REQUEST_BEGIN;
};

export type FetchSpotRequestsStatsSuccessActionT = {
    type: typeof FETCH_SPOT_REQUESTS_STATS_REQUEST_SUCCESS;
    statistics: ApiSpotRequestsStatsT | null;
};

export type FetchSpotRequestsStatsErrorActionT = {
    type: typeof FETCH_SPOT_REQUESTS_STATS_REQUEST_ERROR;
    error: Error;
};

export type SpotRequestsStatsActionT =
    | FetchSpotRequestsStatsActionT
    | FetchSpotRequestsStatsBeginActionT
    | FetchSpotRequestsStatsSuccessActionT
    | FetchSpotRequestsStatsErrorActionT;
