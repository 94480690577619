import { QueryFiltersT } from 'common/models';
import { QueryFiltersKeysEnum } from 'common/constants';
import { FieldsEnum, FormValuesT } from './constants';
import isNumber from 'lodash/isNumber';
import {
    getDateFromDate,
    getEndDayFromDateZero,
    getISOFromDateAndTime,
    getStartDayFromDateZero,
    patchMidnightRelativeTime,
} from 'common/utils/time';
import { simpleStringFormatter } from 'common/utils/form-formatters';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const emissionStandard = values[FieldsEnum.emissionClass];

    const queryFilters: QueryFiltersT = {
        [QueryFiltersKeysEnum.keyAccountManagerId]: values[FieldsEnum.keyAccountManagerId] || undefined,
        [QueryFiltersKeysEnum.keyAccountManagerName]: values[FieldsEnum.keyAccountManagerName] || undefined,
        [QueryFiltersKeysEnum.dispatcherId]: values[FieldsEnum.dispatcherId] || undefined,
        [QueryFiltersKeysEnum.dispatcherName]: values[FieldsEnum.dispatcherName] || undefined,

        [QueryFiltersKeysEnum.shipperId]: values[FieldsEnum.shipperId] || undefined,
        [QueryFiltersKeysEnum.shipperName]: values[FieldsEnum.shipperName] || undefined,
        [QueryFiltersKeysEnum.carrierId]: values[FieldsEnum.carrierId] || undefined,
        [QueryFiltersKeysEnum.carrierName]: values[FieldsEnum.carrierName] || undefined,

        [QueryFiltersKeysEnum.emissionStandard]: isNumber(emissionStandard) ? String(emissionStandard) : undefined,
        [QueryFiltersKeysEnum.dictTrailerId]: values[FieldsEnum.dictTrailerId] || undefined,

        [QueryFiltersKeysEnum.pickupAddress]: simpleStringFormatter(values[FieldsEnum.pickupAddress]) || undefined,
        [QueryFiltersKeysEnum.dropoffAddress]: simpleStringFormatter(values[FieldsEnum.dropoffAddress]) || undefined,

        [QueryFiltersKeysEnum.pickupDateFrom]: undefined,
        [QueryFiltersKeysEnum.pickupDateTo]: undefined,
        [QueryFiltersKeysEnum.pickupTimeWindow]: undefined,

        [QueryFiltersKeysEnum.dropOffDateFrom]: undefined,
        [QueryFiltersKeysEnum.dropOffDateTo]: undefined,
        [QueryFiltersKeysEnum.dropoffTimeWindow]: undefined,

        [QueryFiltersKeysEnum.createdDateFrom]: undefined,
        [QueryFiltersKeysEnum.createdDateTo]: undefined,
    };

    const pickupDates = values[FieldsEnum.pickupDates];
    const pickupTimeWindow = values[FieldsEnum.pickupTimeWindow] || [];

    if (pickupDates?.from && isNumber(pickupTimeWindow[0])) {
        queryFilters[QueryFiltersKeysEnum.pickupDateFrom] = getISOFromDateAndTime(
            pickupDates.from,
            pickupTimeWindow[0],
        );
    }

    if (pickupDates?.to && isNumber(pickupTimeWindow[1])) {
        const ms = patchMidnightRelativeTime(pickupTimeWindow[1]);
        queryFilters[QueryFiltersKeysEnum.pickupDateTo] = getISOFromDateAndTime(pickupDates.to, ms);
    }

    const dropoffDates = values[FieldsEnum.dropoffDates];
    const dropoffTimeWindow = values[FieldsEnum.dropoffTimeWindow] || [];

    if (dropoffDates?.from && isNumber(dropoffTimeWindow[0])) {
        queryFilters[QueryFiltersKeysEnum.dropOffDateFrom] = getISOFromDateAndTime(
            dropoffDates.from,
            dropoffTimeWindow[0],
        );
    }

    if (dropoffDates?.to && isNumber(dropoffTimeWindow[1])) {
        const ms = patchMidnightRelativeTime(dropoffTimeWindow[1]);
        queryFilters[QueryFiltersKeysEnum.dropOffDateTo] = getISOFromDateAndTime(dropoffDates.to, ms);
    }

    const createDates = values[FieldsEnum.createDates];

    if (createDates?.from) {
        const date = getDateFromDate(createDates.from);
        queryFilters[QueryFiltersKeysEnum.createdDateFrom] = getStartDayFromDateZero(date);
    }

    if (createDates?.to) {
        const date = getDateFromDate(createDates.to);
        queryFilters[QueryFiltersKeysEnum.createdDateTo] = getEndDayFromDateZero(date);
    }

    return queryFilters;
};

export default getQueryFilters;
