import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    strokeColor: StyleGuideColorsEnum;
};

const UnLockIcon: React.FC<PropsT> = (props) => {
    const { size, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M10 10.75c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5 1.5-.675 1.5-1.5-.675-1.5-1.5-1.5zM14.5 7h-.75V5.5c0-2.07-1.68-3.75-3.75-3.75a3.747 3.747 0 00-3.63 2.813.747.747 0 00.54.915.75.75 0 00.915-.54A2.247 2.247 0 0110 3.25a2.257 2.257 0 012.25 2.25V7H5.5C4.675 7 4 7.675 4 8.5V16c0 .825.675 1.5 1.5 1.5h9c.825 0 1.5-.675 1.5-1.5V8.5c0-.825-.675-1.5-1.5-1.5zm0 8.25c0 .412-.338.75-.75.75h-7.5a.752.752 0 01-.75-.75v-6c0-.412.338-.75.75-.75h7.5c.412 0 .75.338.75.75v6z"
                />
            </g>
        </svg>
    );
};

UnLockIcon.displayName = 'UnLockIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.charcoal,
    size: DEFAULT_ICON_SIZE,
};

export { storyProps };
export default UnLockIcon;
