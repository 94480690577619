export enum FieldsEnum {
    trailerId = 'trailerId',
    truckId = 'truckId',
    locationToDropTrailer = 'locationToDropTrailer',
    locationToDropTruck = 'locationToDropTruck',
}

export type FormValuesT = {
    [FieldsEnum.trailerId]: TrailerIdT | null;
    [FieldsEnum.truckId]: TruckIdT | null;

    [FieldsEnum.locationToDropTrailer]: LocationT | null;
    [FieldsEnum.locationToDropTruck]: LocationT | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
export type AsyncFormErrorsT = Partial<Record<FieldsEnum, string>>;
