import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size?: number;
    strokeColor: StyleGuideColorsEnum;
};

const CarrierIcon: React.FC<PropsT> = (props) => {
    const { size, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M14.187 5.5L17.4 10l-3.213 4.5h-2.898l3.222-4.5-3.222-4.5h2.898zm-4.5 0L12.9 10l-3.213 4.5H6.789l3.222-4.5-3.222-4.5h2.898zM5 8a2 2 0 110 4 2 2 0 010-4z"
                />
            </g>
        </svg>
    );
};

CarrierIcon.displayName = 'CarrierIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    strokeColor: StyleGuideColorsEnum.gray,
};

export { storyProps };
export default CarrierIcon;

export const CarrierIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }

        if (isDisabled) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            size: DEFAULT_ICON_SIZE,
            strokeColor: hasValue || isFocused ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.light,
        };
    },
};
