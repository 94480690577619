import { FieldsEnum, FormValuesT } from './constants';
import { ALL_SHIPPERS_ID } from 'common/store/shipper-suggest/constants';
import { PriceComponentTypeEnum, RepeatStrategyTypeEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import { QueryFiltersKeysEnum, QueryFiltersT } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/query-models';
import { PriceSettingsRuleGroupEnum } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/constants';

const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const validFrom = queryFilters[QueryFiltersKeysEnum.rulesValidFrom];
    const validTill = queryFilters[QueryFiltersKeysEnum.rulesValidTill];

    return {
        [FieldsEnum.name]: queryFilters[QueryFiltersKeysEnum.rulesName] || '',
        [FieldsEnum.group]: (queryFilters[QueryFiltersKeysEnum.rulesGroup] as PriceSettingsRuleGroupEnum) || null,

        [FieldsEnum.trailerTypeIds]: queryFilters[QueryFiltersKeysEnum.rulesTrailerTypeIds] || [],

        [FieldsEnum.shipperId]: queryFilters[QueryFiltersKeysEnum.rulesShipperId] || ALL_SHIPPERS_ID,

        [FieldsEnum.fromCountryCodes]: queryFilters[QueryFiltersKeysEnum.rulesFromCountryCodes] || [],
        [FieldsEnum.fromCountryZipCodes]: queryFilters[QueryFiltersKeysEnum.rulesFromCountryZipCodes] || '',

        [FieldsEnum.toCountryCodes]: queryFilters[QueryFiltersKeysEnum.rulesToCountryCodes] || [],
        [FieldsEnum.toCountryZipCodes]: queryFilters[QueryFiltersKeysEnum.rulesToCountryZipCodes] || '',

        [FieldsEnum.repeatStrategy]:
            (queryFilters[QueryFiltersKeysEnum.rulesRepeatStrategy] as RepeatStrategyTypeEnum) || null,

        [FieldsEnum.validFrom]: validFrom ? new Date(validFrom) : null,
        [FieldsEnum.validTill]: validTill ? new Date(validTill) : null,

        [FieldsEnum.impactPriceComponent]:
            (queryFilters[QueryFiltersKeysEnum.rulesImpactPriceComponent] as PriceComponentTypeEnum) || null,
    };
};

export default getInitialValues;
