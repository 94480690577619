import { RequestStatusT } from 'common/utils/request-status';

import { PartnerDetailsT } from './models';
import { AddressTypeEnum, PartnerTypeEnum } from 'common/utils/api/models';

export const FETCH_PARTNER_DETAILS_REQUEST = 'broker-admin/partner/details/FETCH_PARTNER_DETAILS_REQUEST';
export const FETCH_PARTNER_DETAILS_REQUEST_BEGIN = 'broker-admin/partner/details/FETCH_PARTNER_DETAILS_REQUEST_BEGIN';
export const FETCH_PARTNER_DETAILS_REQUEST_ERROR = 'broker-admin/partner/details/FETCH_PARTNER_DETAILS_REQUEST_ERROR';
export const FETCH_PARTNER_DETAILS_REQUEST_SUCCESS =
    'broker-admin/partner/details/FETCH_PARTNER_DETAILS_REQUEST_SUCCESS';

export const UPDATE_PARTNER_DETAILS_REQUEST = 'broker-admin/partner/details/UPDATE_PARTNER_DETAILS_REQUEST';
export const UPDATE_PARTNER_DETAILS_REQUEST_BEGIN = 'broker-admin/partner/details/UPDATE_PARTNER_DETAILS_REQUEST_BEGIN';
export const UPDATE_PARTNER_DETAILS_REQUEST_ERROR = 'broker-admin/partner/details/UPDATE_PARTNER_DETAILS_REQUEST_ERROR';
export const UPDATE_PARTNER_DETAILS_REQUEST_SUCCESS =
    'broker-admin/partner/details/UPDATE_PARTNER_DETAILS_REQUEST_SUCCESS';

export const VERIFY_PARTNER_DETAILS_REQUEST = 'broker-admin/partner/details/VERIFY_PARTNER_DETAILS_REQUEST';
export const VERIFY_PARTNER_DETAILS_REQUEST_BEGIN = 'broker-admin/partner/details/VERIFY_PARTNER_DETAILS_REQUEST_BEGIN';
export const VERIFY_PARTNER_DETAILS_REQUEST_ERROR = 'broker-admin/partner/details/VERIFY_PARTNER_DETAILS_REQUEST_ERROR';
export const VERIFY_PARTNER_DETAILS_REQUEST_SUCCESS =
    'broker-admin/partner/details/VERIFY_PARTNER_DETAILS_REQUEST_SUCCESS';

export const LOCK_PARTNER_REQUEST = 'broker-admin/partner/details/LOCK_PARTNER_REQUEST';
export const LOCK_PARTNER_REQUEST_BEGIN = 'broker-admin/partner/details/LOCK_PARTNER_REQUEST_BEGIN';
export const LOCK_PARTNER_REQUEST_ERROR = 'broker-admin/partner/details/LOCK_PARTNER_REQUEST_ERROR';
export const LOCK_PARTNER_REQUEST_SUCCESS = 'broker-admin/partner/details/LOCK_PARTNER_REQUEST_SUCCESS';

export type PartnerDetailsStateT = {
    fetchRequest: RequestStatusT;
    updateRequest: RequestStatusT;
    verifyRequest: RequestStatusT;
    lockRequest: RequestStatusT;
    byId: Record<PartnerIdT, PartnerDetailsT | null>;
};

export type FetchPartnerDetailsActionT = {
    type: typeof FETCH_PARTNER_DETAILS_REQUEST;
    companyId: CompanyIdT;
};

export type FetchPartnerDetailsBeginActionT = {
    type: typeof FETCH_PARTNER_DETAILS_REQUEST_BEGIN;
};

export type FetchPartnerDetailsSuccessActionT = {
    type: typeof FETCH_PARTNER_DETAILS_REQUEST_SUCCESS;
    partnerDetails: PartnerDetailsT | null;
};

export type FetchPartnerDetailsErrorActionT = {
    type: typeof FETCH_PARTNER_DETAILS_REQUEST_ERROR;
    error: Error;
};

export type UpdatePartnerDetailsActionT = {
    type: typeof UPDATE_PARTNER_DETAILS_REQUEST;
    partnerDetailsChanges: Partial<PartnerDetailsT>;
    partnerType: PartnerTypeEnum;
    companyId: CompanyIdT;
};

export type UpdatePartnerDetailsBeginActionT = {
    type: typeof UPDATE_PARTNER_DETAILS_REQUEST_BEGIN;
};

export type UpdatePartnerDetailsSuccessActionT = {
    type: typeof UPDATE_PARTNER_DETAILS_REQUEST_SUCCESS;
    partnerDetails: PartnerDetailsT;
};

export type UpdatePartnerDetailsErrorActionT = {
    type: typeof UPDATE_PARTNER_DETAILS_REQUEST_ERROR;
    error: Error;
};

export type VerifyPartnerDetailsActionT = {
    type: typeof VERIFY_PARTNER_DETAILS_REQUEST;
    isVerify: boolean;
    companyId: CompanyIdT;
    addressType: AddressTypeEnum;
};

export type VerifyPartnerDetailsBeginActionT = {
    type: typeof VERIFY_PARTNER_DETAILS_REQUEST_BEGIN;
};

export type VerifyPartnerDetailsSuccessActionT = {
    type: typeof VERIFY_PARTNER_DETAILS_REQUEST_SUCCESS;
};

export type VerifyPartnerDetailsErrorActionT = {
    type: typeof VERIFY_PARTNER_DETAILS_REQUEST_ERROR;
    error: Error;
};

export type LockPartnerActionT = {
    type: typeof LOCK_PARTNER_REQUEST;
    isLock: boolean;
    companyId: CompanyIdT;
};

export type LockPartnerBeginActionT = {
    type: typeof LOCK_PARTNER_REQUEST_BEGIN;
};

export type LockPartnerSuccessActionT = {
    type: typeof LOCK_PARTNER_REQUEST_SUCCESS;
};

export type LockPartnerErrorActionT = {
    type: typeof LOCK_PARTNER_REQUEST_ERROR;
    error: Error;
};

export type PartnerDetailsActionT =
    | FetchPartnerDetailsActionT
    | FetchPartnerDetailsBeginActionT
    | FetchPartnerDetailsSuccessActionT
    | FetchPartnerDetailsErrorActionT
    | UpdatePartnerDetailsActionT
    | UpdatePartnerDetailsBeginActionT
    | UpdatePartnerDetailsSuccessActionT
    | UpdatePartnerDetailsErrorActionT
    | LockPartnerActionT
    | LockPartnerBeginActionT
    | LockPartnerSuccessActionT
    | LockPartnerErrorActionT
    | VerifyPartnerDetailsActionT
    | VerifyPartnerDetailsBeginActionT
    | VerifyPartnerDetailsSuccessActionT
    | VerifyPartnerDetailsErrorActionT;
