import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AssetClusterPin.scss';
import TruckIcon, { getTruckIconProps } from 'common/icons/TruckIcon';
import TrailerIcon, { getTrailerIconProps } from 'common/icons/TrailerIcon';
import AssetLinkIcon, { getAssetLinkIconProps } from 'common/icons/AssetLinkIcon';
import { getAssignmentIconPreset } from '../utils/get-assignment-icon-preset';
import MapCluster, { MapClusterThemeEnum } from 'common/components/maps/MapCluster/MapCluster';

const cx = classNames.bind(styles);

type PropsT = {
    lat: number;
    lng: number;

    trucksCount: number;
    hasSelectedTruck: boolean;
    trailersCount: number;
    hasSelectedTrailer: boolean;
    linksCount: number;
    hasSelectedLink: boolean;

    clusterId: number;
    onClick: (clusterId: number) => void;
    className?: string;

    canInTime: boolean;
};

const AssetClusterPin: React.FC<PropsT> = React.memo((props) => {
    const {
        lat,
        lng,
        trucksCount,
        hasSelectedTruck,
        trailersCount,
        hasSelectedTrailer,
        linksCount,
        hasSelectedLink,
        className,
        clusterId,
        onClick,
        canInTime,
    } = props;

    return (
        <MapCluster
            lat={lat}
            lng={lng}
            className={className}
            theme={canInTime ? MapClusterThemeEnum.charcoal : MapClusterThemeEnum.light}
            onClick={() => {
                onClick(clusterId);
            }}
        >
            {() => (
                <>
                    {trucksCount !== 0 && (
                        <div className={cx('row')}>
                            <TruckIcon
                                className={cx('icon')}
                                {...getTruckIconProps(getAssignmentIconPreset(hasSelectedTruck, canInTime))}
                            />
                            <span className={cx('text', { 'text--isSelected': hasSelectedTruck })}>{trucksCount}</span>
                        </div>
                    )}
                    {trailersCount !== 0 && (
                        <div className={cx('row')}>
                            <TrailerIcon
                                className={cx('icon')}
                                {...getTrailerIconProps(getAssignmentIconPreset(hasSelectedTrailer, canInTime))}
                            />
                            <span className={cx('text', { 'text--isSelected': hasSelectedTrailer })}>
                                {trailersCount}
                            </span>
                        </div>
                    )}
                    {linksCount !== 0 && (
                        <div className={cx('row')}>
                            <AssetLinkIcon
                                className={cx('icon')}
                                {...getAssetLinkIconProps(getAssignmentIconPreset(hasSelectedLink, canInTime))}
                            />
                            <span className={cx('text', { 'text--isSelected': hasSelectedLink })}>{linksCount}</span>
                        </div>
                    )}
                </>
            )}
        </MapCluster>
    );
});

export default AssetClusterPin;
