import { FieldsEnum, FormValuesT } from './constants';
import moment from 'moment';
import { ALL_SHIPPERS_ID } from 'common/store/shipper-suggest/constants';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import { QueryFiltersKeysEnum, QueryFiltersT } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/query-models';

const QUERY_DATE_FORMAT = 'YYYY-MM-DD';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const queryFilters: QueryFiltersT = {};

    const name = values[FieldsEnum.name];
    if (name) {
        queryFilters[QueryFiltersKeysEnum.rulesName] = simpleStringFormatter(name);
    }

    const shipperId = values[FieldsEnum.shipperId];
    if (shipperId && shipperId !== ALL_SHIPPERS_ID) {
        queryFilters[QueryFiltersKeysEnum.rulesShipperId] = shipperId;
    }

    const group = values[FieldsEnum.group];
    if (group) {
        queryFilters[QueryFiltersKeysEnum.rulesGroup] = group;
    }

    const trailerTypeIds = values[FieldsEnum.trailerTypeIds];
    if (trailerTypeIds?.length) {
        queryFilters[QueryFiltersKeysEnum.rulesTrailerTypeIds] = trailerTypeIds;
    }

    const fromCountryCodes = values[FieldsEnum.fromCountryCodes];
    if (fromCountryCodes?.length) {
        queryFilters[QueryFiltersKeysEnum.rulesFromCountryCodes] = fromCountryCodes;
    }

    const fromCountryZipCodes = values[FieldsEnum.fromCountryZipCodes];
    if (fromCountryZipCodes) {
        queryFilters[QueryFiltersKeysEnum.rulesFromCountryZipCodes] = fromCountryZipCodes;
    }

    const toCountryCodes = values[FieldsEnum.toCountryCodes];
    if (toCountryCodes?.length) {
        queryFilters[QueryFiltersKeysEnum.rulesToCountryCodes] = toCountryCodes;
    }

    const toCountryZipCodes = values[FieldsEnum.toCountryZipCodes];
    if (toCountryZipCodes) {
        queryFilters[QueryFiltersKeysEnum.rulesToCountryZipCodes] = toCountryZipCodes;
    }

    const repeatStrategy = values[FieldsEnum.repeatStrategy];
    if (repeatStrategy) {
        queryFilters[QueryFiltersKeysEnum.rulesRepeatStrategy] = repeatStrategy;
    }

    const validFrom = values[FieldsEnum.validFrom];
    if (validFrom) {
        queryFilters[QueryFiltersKeysEnum.rulesValidFrom] = moment(validFrom).format(QUERY_DATE_FORMAT);
    }

    const validTill = values[FieldsEnum.validTill];
    if (validTill) {
        queryFilters[QueryFiltersKeysEnum.rulesValidTill] = moment(validTill).format(QUERY_DATE_FORMAT);
    }

    const impactPriceComponent = values[FieldsEnum.impactPriceComponent];
    if (impactPriceComponent) {
        queryFilters[QueryFiltersKeysEnum.rulesImpactPriceComponent] = impactPriceComponent;
    }

    return queryFilters;
};

export { getQueryFilters };
