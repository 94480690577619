import { BidsQuickFilterEnum } from '../constants';
import { SpotBidT } from 'broker-admin/utils/api/spot-broker-tranziit/models';
import { ApiBidStatusT, BidStatusEnum } from 'carrier/utils/api/spot-carrier-tranziit/models';

type BidFilterT = (bid: SpotBidT | null | undefined) => boolean;

const inactiveBidStatusesSet = new Set<ApiBidStatusT>([
    BidStatusEnum.rejected,
    BidStatusEnum.expired,
    BidStatusEnum.canceled,
]);

const acceptedBidStatusesSet = new Set<ApiBidStatusT>([BidStatusEnum.accepted, BidStatusEnum.won, BidStatusEnum.lost]);

const filters: Record<BidsQuickFilterEnum, BidFilterT> = {
    [BidsQuickFilterEnum.accepted]: (bid) => {
        return !!bid?.status && acceptedBidStatusesSet.has(bid.status);
    },
    [BidsQuickFilterEnum.match]: (bid) => {
        return !!bid?.match;
    },
    [BidsQuickFilterEnum.inactive]: (bid) => {
        return !!bid?.status && inactiveBidStatusesSet.has(bid.status);
    },
};

export const useBidsFilter = (
    quickFilter: BidsQuickFilterEnum | null,
    bids: Array<SpotBidT | null | undefined>,
): Array<SpotBidT | null | undefined> => {
    if (!quickFilter) {
        return bids;
    }

    return bids.filter(filters[quickFilter]);
};

export const useBidQuickFilterCounts = (
    bids: Array<SpotBidT | null | undefined>,
): Record<BidsQuickFilterEnum, number> => {
    const counts: Record<BidsQuickFilterEnum, number> = {
        [BidsQuickFilterEnum.inactive]: 0,
        [BidsQuickFilterEnum.match]: 0,
        [BidsQuickFilterEnum.accepted]: 0,
    };

    const allQuickFilters: Array<BidsQuickFilterEnum> = [
        BidsQuickFilterEnum.accepted,
        BidsQuickFilterEnum.match,
        BidsQuickFilterEnum.inactive,
    ];

    bids.forEach((bid) => {
        allQuickFilters.forEach((quickFilter) => {
            const isMatch = filters[quickFilter](bid);
            if (isMatch) {
                counts[quickFilter] += 1;
            }
        });
    });

    return counts;
};
