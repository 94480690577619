import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkEmail, checkNotEmpty } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [
    FieldsEnum.name,
    FieldsEnum.legalFormId,
    FieldsEnum.dispatcherId,
    FieldsEnum.keyAccountManagerId,
    FieldsEnum.legalAddressCountryCode,
    FieldsEnum.legalAddressZipCode,
    FieldsEnum.legalAddressCity,
    FieldsEnum.legalAddressStreet1,
] as const;

type MetaT = {
    shouldShowBillingInfo: boolean;
};

const validate = (t: TFunction, values: FormValuesT, { shouldShowBillingInfo }: MetaT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkEmail(t, [FieldsEnum.billingEmail], values),
        ...(shouldShowBillingInfo
            ? checkNotEmpty(
                  t,
                  [
                      FieldsEnum.billingAddressCountryCode,
                      FieldsEnum.billingAddressZipCode,
                      FieldsEnum.billingAddressCity,
                      FieldsEnum.billingAddressStreet1,
                      FieldsEnum.billingEmail,
                  ],
                  values,
              )
            : {}),
    };

    return errors;
};

export default validate;
