import {
    FETCH_SPOT_REQUESTS_PAGE_REQUEST,
    FETCH_SPOT_REQUESTS_PAGE_REQUEST_BEGIN,
    FETCH_SPOT_REQUESTS_PAGE_REQUEST_ERROR,
    FETCH_SPOT_REQUESTS_PAGE_REQUEST_SUCCESS,
    FetchSpotRequestsPageActionT,
    FetchSpotRequestsPageBeginActionT,
    FetchSpotRequestsPageErrorActionT,
    FetchSpotRequestsPageSuccessActionT,
    RESET_SPOT_REQUESTS,
    ResetSpotRequestsActionT,
} from './types';
import { SpotRequestT, FetchSpotRequestsPageQueryT } from './models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchSpotRequestsPage = (
    pageNumber: PageNumberT,
    query: FetchSpotRequestsPageQueryT,
    options?: FetchOptionsT,
): FetchSpotRequestsPageActionT => ({
    type: FETCH_SPOT_REQUESTS_PAGE_REQUEST,
    query,
    pageNumber,
    options,
});

export const fetchSpotRequestsPageBegin = (
    query: FetchSpotRequestsPageQueryT,
    pageNumber: PageNumberT,
): FetchSpotRequestsPageBeginActionT => ({
    type: FETCH_SPOT_REQUESTS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
});

export const fetchSpotRequestsPageSuccess = (
    query: FetchSpotRequestsPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<SpotRequestT> | null,
): FetchSpotRequestsPageSuccessActionT => ({
    type: FETCH_SPOT_REQUESTS_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
});

export const fetchSpotRequestsPageError = (
    query: FetchSpotRequestsPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchSpotRequestsPageErrorActionT => ({
    type: FETCH_SPOT_REQUESTS_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
});

export const resetSpotRequests = (mutationListOptions: MutationListOptionsT): ResetSpotRequestsActionT => ({
    type: RESET_SPOT_REQUESTS,
    mutationListOptions,
});
