import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_PARTNERS_STATS_REQUEST, FetchPartnersStatsActionT } from './types';
import { createPartnerRequestError, fetchPartnersStatsBegin, fetchPartnersStatsSuccess } from './actions';

import { PartnerTypeEnum } from 'common/utils/api/models';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import checkNeedRequest from 'common/utils/check-need-request';
import { selectPartnersStatsRequest } from 'broker-admin/store/partners-stats/selectors';

function* fetchPartnersStatsSaga(actions: FetchPartnersStatsActionT): WrapGeneratorT<void> {
    const { options } = actions;

    const requestStatus: ReturnType<typeof selectPartnersStatsRequest> = yield select(selectPartnersStatsRequest);
    const isNeedRequest = checkNeedRequest(requestStatus, options);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchPartnersStatsBegin());

    const [error, stat]: ReturnApiT<typeof brokerTranziitApi.fetchPartnersStatistics> =
        yield brokerTranziitApi.fetchPartnersStatistics();
    if (error) {
        yield put(createPartnerRequestError(error));
        return;
    }

    const shippersStat = stat?.find((item) => item.type === 'SHIPPER');
    const carrierStat = stat?.find((item) => item.type === 'CARRIER');
    const brokerStat = stat?.find((item) => item.type === 'BROKER');

    yield put(
        fetchPartnersStatsSuccess({
            [PartnerTypeEnum.carrier]: carrierStat,
            [PartnerTypeEnum.shipper]: shippersStat,
            [PartnerTypeEnum.broker]: brokerStat,
        }),
    );
}

function* partnersSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_PARTNERS_STATS_REQUEST, fetchPartnersStatsSaga);
}

export default partnersSaga;
