import { PriceComponentTypeEnum, RepeatStrategyTypeEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import { PriceSettingsRuleGroupEnum } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/constants';
import { CountryCodeT } from 'common/utils/api/models';

export enum QueryKeysEnum {
    rulesPage = 'rules-page',
    rulesFilters = 'rules-filters',
    rulesSort = 'rules-sort',
}

export enum QueryFiltersKeysEnum {
    rulesName = 'rules-name',
    rulesGroup = 'rules-group',
    rulesShipperId = 'rules-shipper-id',
    rulesFromCountryCodes = 'rules-from-country-codes',
    rulesTrailerTypeIds = 'rules-trailer-type-ids',
    rulesFromCountryZipCodes = 'rules-from-country-zip-codes',
    rulesToCountryCodes = 'rules-to-country-codes',
    rulesToCountryZipCodes = 'rules-to-country-zip-zodes',
    rulesRepeatStrategy = 'rules-repeat-strategy',
    rulesValidFrom = 'rules-valid-from',
    rulesValidTill = 'rules-valid-till',
    rulesImpactPriceComponent = 'rules-impact-price-component',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.rulesName]: QueryParam.StringT;
    [QueryFiltersKeysEnum.rulesGroup]: PriceSettingsRuleGroupEnum;
    [QueryFiltersKeysEnum.rulesShipperId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.rulesFromCountryCodes]: Array<CountryCodeT>;
    [QueryFiltersKeysEnum.rulesTrailerTypeIds]: Array<TrailerDictIdT>;
    [QueryFiltersKeysEnum.rulesFromCountryZipCodes]: QueryParam.StringT;
    [QueryFiltersKeysEnum.rulesToCountryCodes]: Array<CountryCodeT>;
    [QueryFiltersKeysEnum.rulesToCountryZipCodes]: QueryParam.StringT;
    [QueryFiltersKeysEnum.rulesRepeatStrategy]: RepeatStrategyTypeEnum;
    [QueryFiltersKeysEnum.rulesValidFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.rulesValidTill]: QueryParam.StringT;
    [QueryFiltersKeysEnum.rulesImpactPriceComponent]: PriceComponentTypeEnum;
}>;
