import { RequestStatusT } from 'common/utils/request-status';

import { FetchPartnersPageQueryT, PartnerT } from './models';
import { ApiInvitePartnerRequestT, ApiPartnerCreationRequestT } from 'common/utils/api/models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_PARTNERS_PAGE_REQUEST = 'broker-admin/partners/FETCH_PARTNERS_PAGE_REQUEST';
export const FETCH_PARTNERS_PAGE_REQUEST_BEGIN = 'broker-admin/partners/FETCH_PARTNERS_PAGE_REQUEST_BEGIN';
export const FETCH_PARTNERS_PAGE_REQUEST_ERROR = 'broker-admin/partners/FETCH_PARTNERS_PAGE_REQUEST_ERROR';
export const FETCH_PARTNERS_PAGE_REQUEST_SUCCESS = 'broker-admin/partners/FETCH_PARTNERS_PAGE_REQUEST_SUCCESS';
export const RESET_PARTNERS = 'broker-admin/partners/RESET_PARTNERS';

export const INVITE_PARTNER_REQUEST = 'broker-admin/partners/INVITE_PARTNER_REQUEST';
export const CREATE_PARTNER_REQUEST = 'broker-admin/partners/CREATE_PARTNER_REQUEST';
export const CREATE_PARTNER_REQUEST_BEGIN = 'broker-admin/partners/CREATE_PARTNER_REQUEST_BEGIN';
export const CREATE_PARTNER_REQUEST_ERROR = 'broker-admin/partners/CREATE_PARTNER_REQUEST_ERROR';
export const CREATE_PARTNER_REQUEST_SUCCESS = 'broker-admin/partners/CREATE_PARTNER_REQUEST_SUCCESS';

export type PartnersStateT = PaginationStateT<PartnerT, FetchPartnersPageQueryT> & {
    createPartnerRequest: RequestStatusT;
};

export type FetchPartnersPageActionT = {
    type: typeof FETCH_PARTNERS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchPartnersPageQueryT;
    options?: FetchOptionsT;
};

export type FetchPartnersPageBeginActionT = {
    type: typeof FETCH_PARTNERS_PAGE_REQUEST_BEGIN;
    pageNumber: PageNumberT;
    query: FetchPartnersPageQueryT;
};

export type FetchPartnersPageSuccessActionT = {
    type: typeof FETCH_PARTNERS_PAGE_REQUEST_SUCCESS;
    pageNumber: PageNumberT;
    query: FetchPartnersPageQueryT;
    pageResponse: PageResponseT<PartnerT> | null;
};

export type FetchPartnersPageErrorActionT = {
    type: typeof FETCH_PARTNERS_PAGE_REQUEST_ERROR;
    query: FetchPartnersPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
};

export type ResetPartnersActionT = {
    type: typeof RESET_PARTNERS;
    mutationListOptions: MutationListOptionsT;
};

export type InvitePartnerRequestActionT = {
    type: typeof INVITE_PARTNER_REQUEST;
    data: ApiInvitePartnerRequestT;
};

export type CreatePartnerRequestActionT = {
    type: typeof CREATE_PARTNER_REQUEST;
    data: ApiPartnerCreationRequestT;
};

export type CreatePartnerRequestBeginActionT = {
    type: typeof CREATE_PARTNER_REQUEST_BEGIN;
};

export type CreatePartnerRequestErrorActionT = {
    type: typeof CREATE_PARTNER_REQUEST_ERROR;
    error: Error;
};

export type CreatePartnerRequestSuccessActionT = {
    type: typeof CREATE_PARTNER_REQUEST_SUCCESS;
};

export type PartnersActionT =
    | FetchPartnersPageActionT
    | FetchPartnersPageBeginActionT
    | FetchPartnersPageSuccessActionT
    | FetchPartnersPageErrorActionT
    | ResetPartnersActionT
    | InvitePartnerRequestActionT
    | CreatePartnerRequestActionT
    | CreatePartnerRequestBeginActionT
    | CreatePartnerRequestErrorActionT
    | CreatePartnerRequestSuccessActionT;
