import {
    FETCH_DONE_STATISTICS_REQUEST_BEGIN,
    FETCH_DONE_STATISTICS_REQUEST_ERROR,
    FETCH_DONE_STATISTICS_REQUEST_SUCCESS,
    FETCH_STATISTICS_REQUEST,
    FetchDoneStatisticsRequestBeginActionT,
    FetchDoneStatisticsRequestErrorActionT,
    FetchDoneStatisticsRequestSuccessActionT,
    FetchStatisticsRequestActionT,
    FetchRejectStatisticsRequestSuccessActionT,
    FetchRejectStatisticsRequestErrorActionT,
    FetchRejectStatisticsRequestBeginActionT,
    FETCH_REJECT_STATISTICS_REQUEST_BEGIN,
    FETCH_REJECT_STATISTICS_REQUEST_SUCCESS,
    FETCH_REJECT_STATISTICS_REQUEST_ERROR,
    FetchStatisticsRequestBeginActionT,
    FETCH_STATISTICS_REQUEST_BEGIN,
} from './types';
import { DoneStatisticT, FetchStatisticsQueryT, RejectStatisticT } from './models';

export const fetchStatistics = (
    query: FetchStatisticsQueryT,
    options?: FetchOptionsT,
): FetchStatisticsRequestActionT => ({
    type: FETCH_STATISTICS_REQUEST,
    query,
    options,
});

export const fetchStatisticsBegin = (
    query: FetchStatisticsQueryT,
    options?: FetchOptionsT,
): FetchStatisticsRequestBeginActionT => ({
    type: FETCH_STATISTICS_REQUEST_BEGIN,
    query,
    options,
});

export const fetchDoneStatisticsBegin = (query: FetchStatisticsQueryT): FetchDoneStatisticsRequestBeginActionT => ({
    type: FETCH_DONE_STATISTICS_REQUEST_BEGIN,
    query,
});

export const fetchDoneStatisticsSuccess = (
    query: FetchStatisticsQueryT,
    statistic: DoneStatisticT | null,
): FetchDoneStatisticsRequestSuccessActionT => ({
    type: FETCH_DONE_STATISTICS_REQUEST_SUCCESS,
    query,
    statistic,
});

export const fetchDoneStatisticsError = (
    query: FetchStatisticsQueryT,
    error: Error,
): FetchDoneStatisticsRequestErrorActionT => ({
    type: FETCH_DONE_STATISTICS_REQUEST_ERROR,
    error,
    query,
});

export const fetchRejectStatisticsBegin = (query: FetchStatisticsQueryT): FetchRejectStatisticsRequestBeginActionT => ({
    type: FETCH_REJECT_STATISTICS_REQUEST_BEGIN,
    query,
});

export const fetchRejectStatisticsSuccess = (
    query: FetchStatisticsQueryT,
    statistic: RejectStatisticT | null,
): FetchRejectStatisticsRequestSuccessActionT => ({
    type: FETCH_REJECT_STATISTICS_REQUEST_SUCCESS,
    query,
    statistic,
});

export const fetchRejectStatisticsError = (
    query: FetchStatisticsQueryT,
    error: Error,
): FetchRejectStatisticsRequestErrorActionT => ({
    type: FETCH_REJECT_STATISTICS_REQUEST_ERROR,
    error,
    query,
});
