import { FieldsEnum, FormValuesT } from './constants';

const DEFAULT_VALUES: FormValuesT = {
    [FieldsEnum.name]: '',
    [FieldsEnum.number]: '',

    [FieldsEnum.activateDate]: null,
    [FieldsEnum.validTillDate]: null,

    [FieldsEnum.file]: null,
};

const getInitialValues = (): FormValuesT => {
    const values: FormValuesT = { ...DEFAULT_VALUES };

    return values;
};

export default getInitialValues;
