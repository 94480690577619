import { put, takeEvery } from 'redux-saga/effects';
import { FETCH_RFQ_STATS_REQUEST, FetchRFQStatsActionT } from './types';
import { fetchRFQStatsBegin, fetchRFQStatsError, fetchRFQStatsSuccess } from './actions';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';

function* fetchRFQStatsSaga(action: FetchRFQStatsActionT): WrapGeneratorT<void> {
    const { query } = action;

    yield put(fetchRFQStatsBegin(query));

    const { companyId, fromDate, toDate } = query;

    const [error, count]: ReturnApiT<typeof brokerTranziitApi.fetchPartnerRFQCount> =
        yield brokerTranziitApi.fetchPartnerRFQCount(companyId, {
            fromDate,
            toDate,
        });

    if (error) {
        yield put(fetchRFQStatsError(query, error));
        return;
    }

    yield put(
        fetchRFQStatsSuccess(query, {
            count: count || 0,
        }),
    );
}

function* RFQStatsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_RFQ_STATS_REQUEST, fetchRFQStatsSaga);
}

export default RFQStatsSaga;
