import * as React from 'react';
import { CommonSidebarDataT, CommonSidebarsTypeEnum, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { EditableShipperContractLaneDetailsSidebarDataT } from './models';
import { PartnerTypeEnum } from 'common/utils/api/models';
import CommonEditableShipperContractLaneDetailsLayout from 'common/layouts/CommonEditableShipperContractLaneDetailsLayout/CommonEditableShipperContractLaneDetailsLayout';

type PropsT = SidebarContentPropsT<EditableShipperContractLaneDetailsSidebarDataT, CommonSidebarDataT>;

const EditableShipperContractLaneDetailsSidebarContent: React.FC<PropsT> = (props) => {
    const { data, onGoBack, onClose, setNeedCloseConfirmation, onOpenNextSidebar } = props;

    if (!onClose) {
        return null;
    }

    const handleOpenUserDetails = (userId: UserIdT) => {
        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId: data.partnerId,
            partnerType: PartnerTypeEnum.broker,
            userId,
        });
    };

    return (
        <CommonEditableShipperContractLaneDetailsLayout
            partnerId={data.partnerId}
            shipperContractId={data.shipperContractId}
            shipperContractLaneId={data.shipperContractLaneId}
            isReadOnly={data.isReadOnly}
            onClose={onClose}
            onGoBack={onGoBack || undefined}
            onOpenUserDetails={handleOpenUserDetails}
            setNeedCloseConfirmation={setNeedCloseConfirmation}
        />
    );
};

export default EditableShipperContractLaneDetailsSidebarContent;
