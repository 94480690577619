import { PriceComponentTypeEnum, RepeatStrategyTypeEnum } from 'broker-admin/utils/api/broker-tranziit/models';

export enum PriceSettingsRuleGroupEnum {
    holidays = 'holidays',
    seasonal = 'seasonal',
    marketCorrection = 'market-correction',
    discount = 'discount',
    ferry = 'ferry',
}

export type RepeatStrategyTypeT = NonNullable<Api.Definitions.ManualRateRuleDto['repeatMode']>;

export const REPEAT_STRATEGY_T_MAP: Record<RepeatStrategyTypeEnum, string> = {
    [RepeatStrategyTypeEnum.none]: 'price-settings.rules.repeat-strategy.NONE',
    [RepeatStrategyTypeEnum.weekly]: 'price-settings.rules.repeat-strategy.WEEKLY',
    [RepeatStrategyTypeEnum.monthly]: 'price-settings.rules.repeat-strategy.MONTHLY',
    [RepeatStrategyTypeEnum.yearly]: 'price-settings.rules.repeat-strategy.YEARLY',
};

export type PriceComponentTypeT = NonNullable<Api.Definitions.ManualRateRuleDto['targetPrice']>;

export const RULE_GROUP_T_MAP: Record<PriceSettingsRuleGroupEnum, string> = {
    [PriceSettingsRuleGroupEnum.holidays]: 'price-settings.rules.groups.holidays',
    [PriceSettingsRuleGroupEnum.seasonal]: 'price-settings.rules.groups.seasonal',
    [PriceSettingsRuleGroupEnum.marketCorrection]: 'price-settings.rules.groups.market-correction',
    [PriceSettingsRuleGroupEnum.discount]: 'price-settings.rules.groups.discount',
    [PriceSettingsRuleGroupEnum.ferry]: 'price-settings.rules.groups.ferry',
};

export const PRICE_COMPONENT_T_MAP: Record<PriceComponentTypeEnum, string> = {
    [PriceComponentTypeEnum.costOfOperation]: 'price-settings.rules.price-components.cost-of-operation',
    [PriceComponentTypeEnum.lineHaul]: 'price-settings.rules.price-components.line-haul',
    [PriceComponentTypeEnum.fuel]: 'price-settings.rules.price-components.fuel',
    [PriceComponentTypeEnum.roadTaxes]: 'price-settings.rules.price-components.road-taxes',
    [PriceComponentTypeEnum.serviceFee]: 'price-settings.rules.price-components.service-fee',
};
