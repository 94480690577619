import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './LocationsCell.scss';
import { BrokerOrderT } from 'broker-admin/store/orders/models';

type PropsT = {
    order: BrokerOrderT;
};

const cx = classNames.bind(styles);

const LocationCell: React.FC<PropsT> = React.memo((props) => {
    const { order } = props;

    return (
        <div>
            <div className={cx('location')}>{order.originAddress}</div>
            <div className={cx('location')}>{order.destinationAddress}</div>
        </div>
    );
});

export default LocationCell;
