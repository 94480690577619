import { RatesStateT } from './types';
import { StateT } from 'broker-admin/store/models';
import { RequestStatusT } from 'common/utils/request-status';

export const selectRatesPages = (state: StateT): RatesStateT['pages'] => state.priceSettings.rates.pages;

export const selectRatesQuery = (state: StateT): RatesStateT['query'] => state.priceSettings.rates.query;

export const selectRatesTotal = (state: StateT): RatesStateT['total'] => state.priceSettings.rates.total;

export const selectRatesByIds = (state: StateT): RatesStateT['byId'] => state.priceSettings.rates.byId;

export const selectUpdateRatesRequest = (state: StateT): RequestStatusT => state.priceSettings.rates.updateRequest;

export const selectUploadCSVFileRequest = (state: StateT): RequestStatusT => state.priceSettings.rates.uploadCSVRequest;
