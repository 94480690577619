import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './SpotRequestDetailsPage.scss';

import { useParams } from 'react-router-dom';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import { useTranslation } from 'react-i18next';
import GoogleMapContext from 'common/contexts/google-map-context';
import HorizontalColumnsLayout from 'common/layouts/HorizontalColumnsLayout/HorizontalColumnsLayout';
import SpotRequestRoute from 'broker-admin/layouts/SpotRequestsPage/SpotRequestDetailsPage/SpotRequestRoute/SpotRequestRoute';
import history, { goBackIfHasRePath } from 'common/utils/history';
import { SpotRoutesEnum } from 'broker-admin/constants';
import { urlFactory } from 'broker-admin/utils/urls';
import BaseSpotRequestDetailsSidebarContent from 'broker-admin/components/BaseSpotRequestDetailsSidebarContent/BaseSpotRequestDetailsSidebarContent';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { logWarning } from 'common/utils/logger';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { PartnerTypeEnum } from 'common/utils/api/models';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';

const cx = classNames.bind(styles);

type ParamsT = {
    spotRequestId: string;
};

type PropsT = {};

const SpotRequestDetailsPage: React.FC<PropsT> = React.memo((props) => {
    const params = useParams<ParamsT>();
    const { spotRequestId } = params;

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const { t } = useTranslation();

    const handleCloseDetails = () => {
        goBackIfHasRePath(
            () => {
                history.push(SpotRoutesEnum.list + history.location.search);
            },
            {
                shouldCloseSidebars: true,
            },
        );
    };

    const handleCloseMap = () => {
        goBackIfHasRePath(() => {
            history.push(urlFactory.spotRequestsListWithDetails(spotRequestId));
        });
    };

    const openLeftSidebar = useOpenLeftSidebar();
    const handleOpenUserDetails = (params: {
        partnerType: PartnerTypeEnum;
        partnerId: PartnerIdT;
        userId: UserIdT | null;
    }) => {
        const { userId, partnerType, partnerId } = params;
        if (!userId) {
            logWarning('failed to open user details in spot request details sidebar, empty userId');
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    return (
        <>
            <GoogleMapContext.Provider value={googleMapsContextValue}>
                <HorizontalColumnsLayout
                    testSelector="spot-request-details"
                    className={cx('wrap')}
                    leftColumnTitle={t('spot-request-details.main-tabs.details')}
                    leftColumnNode={
                        <BaseSpotRequestDetailsSidebarContent
                            id={spotRequestId}
                            onClose={handleCloseDetails}
                            toggleMap={handleCloseMap}
                            isActiveMapTrigger
                            onOpenUserDetails={handleOpenUserDetails}
                        />
                    }
                    rightColumnTitle={t('spot-request-details.main-tabs.info')}
                    rightColumnNode={<SpotRequestRoute id={spotRequestId} />}
                />
            </GoogleMapContext.Provider>
            <SideBars />
        </>
    );
});

export default SpotRequestDetailsPage;
