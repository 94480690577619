import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ContractName.scss';

const cx = classNames.bind(styles);

type PropsT = {
    contractName: string;
};

const ContractName: React.FC<PropsT> = React.memo((props) => {
    const { contractName } = props;

    return <span className={cx('contract-name')}>{contractName}</span>;
});

export default ContractName;
