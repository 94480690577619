import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT, RepeatTypeEnum } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [
    FieldsEnum.name,
    FieldsEnum.group,
    FieldsEnum.validFrom,
    FieldsEnum.impactValue,
    FieldsEnum.shipperId,
    FieldsEnum.trailerTypeIds,
    FieldsEnum.fromCountryCodes,
    FieldsEnum.toCountryCodes,
];

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
    };

    const impactValue = (values[FieldsEnum.impactValue] || '').trim();
    if (impactValue === '+' || impactValue === '-') {
        errors[FieldsEnum.impactValue] = t('common:form-errors.required');
    }

    const dates = values[FieldsEnum.dates];
    if (values[FieldsEnum.repeatType] === RepeatTypeEnum.dates && !dates?.from && !dates?.to) {
        errors[FieldsEnum.dates] = t('common:form-errors.required');
    }

    const days = values[FieldsEnum.days];
    if (values[FieldsEnum.repeatType] === RepeatTypeEnum.days && !days.length) {
        errors[FieldsEnum.days] = t('common:form-errors.required');
    }

    return errors;
};

export default validate;
