import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './CheckboxTree.scss';
import cs from 'classnames';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import Checkbox from '../Checkbox/Checkbox';

const cx = classNames.bind(styles);

export type TreeNodeT<T> = {
    label: React.ReactNode;
    value?: T;
    children: Array<TreeNodeT<T>>;
};

type ExtendedTreeNodeT<T> = TreeNodeT<T> & {
    allSubValues: Array<T>;
};

export type PropsT<T> = {
    className?: string;
    trees: Array<TreeNodeT<T>>;
    values: Array<T>;
    isFullWidth?: boolean;
    onChange: (values: Array<T>, event: React.MouseEvent<HTMLDivElement>) => void;
    isGroup?: boolean;
};

export const getAllSubValues = <T,>(treeNodes: Array<TreeNodeT<T>>): Array<T> => {
    return treeNodes.reduce<Array<T>>((result, treeNode) => {
        const allSubValues = getAllSubValues(treeNode.children);

        const currentValue: T[] = [];
        if (treeNode.value !== undefined) {
            currentValue.push(treeNode.value);
        }

        return [...result, ...currentValue, ...allSubValues];
    }, []);
};

const addAllSubValuesForTreeNode = <T,>(treeNode: TreeNodeT<T>): ExtendedTreeNodeT<T> => {
    return {
        ...treeNode,
        allSubValues: getAllSubValues<T>([treeNode]),
        children: treeNode.children.map(addAllSubValuesForTreeNode),
    };
};

const addAllSubValues = <T,>(trees: Array<TreeNodeT<T>>): Array<ExtendedTreeNodeT<T>> => {
    return trees.map(addAllSubValuesForTreeNode);
};

const CheckboxTree = <ValueT,>(props: PropsT<ValueT>): React.ReactElement => {
    const { trees, values, onChange, isFullWidth, isGroup, className } = props;

    const preparedValues = React.useMemo(() => {
        return addAllSubValues(trees);
    }, [trees]);

    return (
        <div className={cs(cx('wrap'), className)} date-test-selector="checkbox-list">
            {preparedValues.map((treeNode, treeIndex) => {
                const valuesSet = new Set(values);

                const isChecked = treeNode.allSubValues.every((subValue) => valuesSet.has(subValue));
                const isIndeterminated =
                    !isChecked && treeNode.allSubValues.some((subValue) => valuesSet.has(subValue));

                return (
                    <div
                        className={cx('group', {
                            'group--isGroup': !!isGroup,
                        })}
                        key={treeIndex}
                    >
                        <Checkbox
                            className={cx('checkbox')}
                            checked={isChecked}
                            indeterminated={isIndeterminated}
                            label={treeNode.label}
                            isFullWidth={isFullWidth}
                            onChange={(checked, event) => {
                                let newValue = [...values];
                                if (checked) {
                                    newValue = uniq([...values, ...treeNode.allSubValues]);
                                } else {
                                    newValue = difference(values, treeNode.allSubValues);
                                }
                                onChange(newValue, event);
                            }}
                        />
                        <CheckboxTree
                            isGroup
                            trees={treeNode.children}
                            values={values}
                            onChange={onChange}
                            isFullWidth={isFullWidth}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(CheckboxTree) as typeof CheckboxTree;
