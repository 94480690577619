import * as React from 'react';
import { CommonSidebarsTypeEnum, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { SpotRequestDetailsSidebarDataT } from './models';
import BaseSpotRequestDetailsSidebarContent from 'broker-admin/components/BaseSpotRequestDetailsSidebarContent/BaseSpotRequestDetailsSidebarContent';
import { urlFactory } from 'broker-admin/utils/urls';
import history from 'common/utils/history';
import { BrokerSidebarDataT } from 'broker-admin/layouts/SideBars/models';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { logWarning } from 'common/utils/logger';

type PropsT = SidebarContentPropsT<SpotRequestDetailsSidebarDataT, BrokerSidebarDataT>;

const SpotRequestDetailsSidebarContent: React.FC<PropsT> = React.memo((props) => {
    const { data, onClose, onOpenNextSidebar } = props;

    const handleOpenMap = () => {
        const url = urlFactory.spotRequestDetails(data.id);
        history.push(url);
    };

    const handleOpenUserDetails = (params: {
        partnerType: PartnerTypeEnum;
        partnerId: PartnerIdT;
        userId: UserIdT | null;
    }) => {
        const { userId, partnerType, partnerId } = params;
        if (!userId) {
            logWarning('failed to open user details in spot request details sidebar, empty userId');
            return;
        }

        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    return (
        <BaseSpotRequestDetailsSidebarContent
            id={data.id}
            onClose={onClose}
            toggleMap={handleOpenMap}
            onOpenUserDetails={handleOpenUserDetails}
        />
    );
});

export default SpotRequestDetailsSidebarContent;
