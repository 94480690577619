import {
    FETCH_RFQ_STATS_REQUEST,
    FETCH_RFQ_STATS_REQUEST_BEGIN,
    FETCH_RFQ_STATS_REQUEST_ERROR,
    FETCH_RFQ_STATS_REQUEST_SUCCESS,
    FetchRFQStatsActionT,
    FetchRFQStatsBeginActionT,
    FetchRFQStatsErrorActionT,
    FetchRFQStatsSuccessActionT,
    RESET,
    ResetRFQStatsActionT,
} from './types';
import { ApiRFQStatsT, RFQStatsQueryT } from './models';

export const fetchRFQStats = (query: RFQStatsQueryT): FetchRFQStatsActionT => ({
    type: FETCH_RFQ_STATS_REQUEST,
    query,
});

export const fetchRFQStatsBegin = (query: RFQStatsQueryT): FetchRFQStatsBeginActionT => ({
    type: FETCH_RFQ_STATS_REQUEST_BEGIN,
    query,
});

export const fetchRFQStatsSuccess = (query: RFQStatsQueryT, stats: ApiRFQStatsT): FetchRFQStatsSuccessActionT => ({
    type: FETCH_RFQ_STATS_REQUEST_SUCCESS,
    query,
    stats,
});

export const fetchRFQStatsError = (query: RFQStatsQueryT, error: Error): FetchRFQStatsErrorActionT => ({
    type: FETCH_RFQ_STATS_REQUEST_ERROR,
    error,
    query,
});

export const resetRFQStats = (): ResetRFQStatsActionT => ({
    type: RESET,
});
