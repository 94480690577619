import { FieldsEnum, FormValuesT } from './constants';
import { SystemSettingsChangesT } from 'broker-admin/store/price-settings/system-settings/models';

const prepareApiSystemSettings = (formValues: FormValuesT): SystemSettingsChangesT => {
    return {
        SPOT_REQUEST_LIMIT: formValues[FieldsEnum.spotRequestLimit],
        EXPIRES_SOON: formValues[FieldsEnum.expiresSoonAlert],
    };
};

export default prepareApiSystemSettings;
