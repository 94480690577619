import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { UnlockPartnerConfirmationDataT } from './models';
import { RequestStatusT } from 'common/utils/request-status';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    data: UnlockPartnerConfirmationDataT | null;
    requestStatus: RequestStatusT;
    onClose: () => void;
    onCancel: () => void;
    onConfirm: (data: UnlockPartnerConfirmationDataT | null) => void;
};

const TEST_SELECTOR = 'unlock-partner-confirmation';

const UnlockPartnerConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { data, requestStatus, onClose, onCancel, onConfirm } = props;

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: requestStatus.loading,
                            onClick: onCancel,
                        },
                        {
                            children: t('common:error-modal.actions.unlock'),
                            theme: ButtonThemeEnum.danger,
                            isLoading: requestStatus.loading,
                            isDisabled: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                    title={t('partner.details-tab.dialogs.unlock-partner.title')}
                    message={t('partner.details-tab.dialogs.unlock-partner.description')}
                />
            </ModalContent>
        </Modal>
    );
});

export default UnlockPartnerConfirmation;
