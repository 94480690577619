import { DispatchDetailsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_DISPATCH_DETAILS_REQUEST = 'broker-admin/dispatch-details/FETCH_DISPATCH_DETAILS_REQUEST';
export const FETCH_DISPATCH_DETAILS_REQUEST_BEGIN =
    'broker-admin/dispatch-details/FETCH_DISPATCH_DETAILS_REQUEST_BEGIN';
export const FETCH_DISPATCH_DETAILS_REQUEST_ERROR =
    'broker-admin/dispatch-details/FETCH_DISPATCH_DETAILS_REQUEST_ERROR';
export const FETCH_DISPATCH_DETAILS_REQUEST_SUCCESS =
    'broker-admin/dispatch-details/FETCH_DISPATCH_DETAILS_REQUEST_SUCCESS';

export type DispatchDetailsStateT = {
    dispatchDetails: DispatchDetailsT | null;
    requestStatus: RequestStatusT;
};

export type DispatchDetailsByIdStateT = Record<DispatchIdT, DispatchDetailsStateT>;

export type FetchDispatchDetailsRequestActionT = {
    type: typeof FETCH_DISPATCH_DETAILS_REQUEST;
    id: DispatchIdT;
    options?: FetchOptionsT;
};

export type FetchDispatchDetailsRequestBeginActionT = {
    type: typeof FETCH_DISPATCH_DETAILS_REQUEST_BEGIN;
    id: DispatchIdT;
};

export type FetchDispatchDetailsRequestErrorActionT = {
    type: typeof FETCH_DISPATCH_DETAILS_REQUEST_ERROR;
    error: Error;
    id: DispatchIdT;
};

export type FetchDispatchDetailsRequestSuccessActionT = {
    type: typeof FETCH_DISPATCH_DETAILS_REQUEST_SUCCESS;
    dispatchDetails: DispatchDetailsT | null;
    id: DispatchIdT;
};

export type DispatchDetailsActionT =
    | FetchDispatchDetailsRequestActionT
    | FetchDispatchDetailsRequestBeginActionT
    | FetchDispatchDetailsRequestErrorActionT
    | FetchDispatchDetailsRequestSuccessActionT;
