import * as React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { AssetRoutesEnum, PartnersRoutesEnum } from 'broker-admin/constants';
import TruckDetailsPage from 'common/layouts/TruckDetailsPage/TruckDetailsPage';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { generatePath } from 'react-router';
import history, { goBackIfHasRePath } from 'common/utils/history';
import TrailerDetailsPage from 'common/layouts/TrailerDetailsPage/TrailerDetailsPage';
import PartnerContext from 'common/contexts/partner-context';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';

type PropsT = {};

type ParamsT = {
    partnerType: PartnerTypeEnum;
    partnerId: PartnerIdT;
};

type QueryT = {};

const AssetDetailsPage: React.FC<PropsT> = React.memo((props) => {
    const params = useParams<ParamsT>();

    const { partnerType, partnerId } = params;

    const partnerContextValue = React.useMemo(() => {
        return {
            partnerType: params.partnerType,
            partnerId: params.partnerId,
        };
    }, [params.partnerId, params.partnerType]);

    return (
        <PartnerContext.Provider value={partnerContextValue}>
            <Switch>
                <Route path={AssetRoutesEnum.truckDetails}>
                    <TruckDetailsPage
                        onClose={() => {
                            goBackIfHasRePath(
                                () => {
                                    const pathname = generatePath(PartnersRoutesEnum.partnerTrucks, {
                                        partnerType,
                                        partnerId,
                                    });

                                    history.push({
                                        pathname,
                                    });
                                },
                                {
                                    shouldCloseSidebars: true,
                                },
                            );
                        }}
                        onCloseMap={() => {
                            goBackIfHasRePath(() => {
                                const pathname = generatePath(PartnersRoutesEnum.partnerTrucks, {
                                    partnerType,
                                    partnerId,
                                });

                                history.push({
                                    pathname,
                                });
                            });
                        }}
                    />
                </Route>
                <Route path={AssetRoutesEnum.trailerDetails}>
                    <TrailerDetailsPage
                        onClose={() => {
                            goBackIfHasRePath(
                                () => {
                                    const pathname = generatePath(PartnersRoutesEnum.partnerTrailers, {
                                        partnerType,
                                        partnerId,
                                    });

                                    history.push({
                                        pathname,
                                    });
                                },
                                {
                                    shouldCloseSidebars: true,
                                },
                            );
                        }}
                        onCloseMap={() => {
                            goBackIfHasRePath(() => {
                                const pathname = generatePath(PartnersRoutesEnum.partnerTrailers, {
                                    partnerType,
                                    partnerId,
                                });

                                history.push({
                                    pathname,
                                });
                            });
                        }}
                    />
                </Route>
            </Switch>
            <SideBars />
        </PartnerContext.Provider>
    );
});

export default AssetDetailsPage;
