import { put, select, takeEvery } from 'redux-saga/effects';
import {
    FIND_SHIPPERS_REQUEST_BY_ID,
    FIND_SHIPPERS_REQUEST_BY_QUERY,
    FindShippersByIdActionT,
    FindShippersByQueryActionT,
} from './types';
import { findShippersBegin, findShippersError, findShippersSuccess } from './actions';
import { selectFindShippersQuery, selectShippersById } from './selectors';
import isEqual from 'lodash/isEqual';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { PartnerT } from 'common/store/shipper-suggest/models';

function* findShippersByQuerySaga(action: FindShippersByQueryActionT): WrapGeneratorT<void> {
    const { query: rawQuery } = action;

    const query = rawQuery.trim();
    if (!query) {
        return;
    }

    const prevQuery: ReturnType<typeof selectFindShippersQuery> = yield select(selectFindShippersQuery);
    const isSameQuery = isEqual(query, prevQuery);
    if (isSameQuery) {
        return;
    }

    yield put(findShippersBegin(query));

    const [error, partners]: ReturnApiT<typeof brokerTranziitApi.findShippersByName> =
        yield brokerTranziitApi.findShippersByName(query);

    if (error) {
        yield put(findShippersError(error));
        return;
    }

    yield put(findShippersSuccess(partners?.content || []));
}

function* findShippersByIdSaga(action: FindShippersByIdActionT): WrapGeneratorT<void> {
    const { partnerId, query } = action;

    const shippersById: ReturnType<typeof selectShippersById> = yield select(selectShippersById);
    if (shippersById[partnerId]) {
        return;
    }

    yield put(findShippersBegin(query));

    const [error, partnerDetails]: ReturnApiT<typeof brokerTranziitApi.fetchPartnerDetails> =
        yield brokerTranziitApi.fetchPartnerDetails(partnerId);

    if (error) {
        yield put(findShippersError(error));
        return;
    }

    const partners: PartnerT[] = [];

    if (partnerDetails) {
        partners.push({
            id: partnerDetails?.id || '',
            name: partnerDetails?.name,
            // TODO remove ...as
            countryCode: partnerDetails?.legalAddress?.country as PartnerT['countryCode'],
            number: partnerDetails?.number,
            addressVerified: partnerDetails?.addressVerified,
            billingAddressVerified: partnerDetails?.billingAddressVerified,
            documentsVerified: partnerDetails?.documentsVerified,
        });
    }

    yield put(findShippersSuccess(partners));
}

function* shipperSuggestSaga(): WrapGeneratorT<void> {
    yield takeEvery(FIND_SHIPPERS_REQUEST_BY_QUERY, findShippersByQuerySaga);
    yield takeEvery(FIND_SHIPPERS_REQUEST_BY_ID, findShippersByIdSaga);
}

export default shipperSuggestSaga;
