import React from 'react';
import classNames from 'classnames/bind';

import styles from './CreatePartnerSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT } from './constants';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import { useDispatch, useSelector } from 'react-redux';
import { CountryCodeT, PartnerTypeEnum } from 'common/utils/api/models';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import {
    selectFetchRuleDetailsRequest,
    selectUpdateRulesRequest,
} from 'broker-admin/store/price-settings/rules/selectors';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import LegalFormDropdown from 'common/components/dropdowns/LegalFormDropdown/LegalFormDropdown';
import isNumber from 'lodash/isNumber';
import { useCheckCountryCorrectLegacyForm } from 'common/components/dropdowns/LegalFormDropdown/hooks';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import { SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { BrokerSidebarDataT } from 'broker-admin/layouts/SideBars/models';
import { PartherCreationSidebarDataT } from 'broker-admin/layouts/SideBars/CreatePartnerSidebarContent/models';
import { createPartnerRequest } from 'broker-admin/store/partners/actions';
import { selectPartnerCreationRequest } from 'broker-admin/store/partners/selectors';
import useCloseSidebarAfterRequest from 'common/utils/hooks/useCloseSidebarAfterRequest';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';

const cx = classNames.bind(styles);

type PartnerOptionT = {
    label: string;
    value: PartnerTypeEnum;
};

const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(Object.values(FieldsEnum));

type PropsT = SidebarContentPropsT<PartherCreationSidebarDataT, BrokerSidebarDataT>;

const CreatePartnerSidebarContent: React.FC<PropsT> = (props) => {
    const { onGoBack, onClose, setNeedCloseConfirmation } = props;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const creationRequestStatus = useSelector(selectPartnerCreationRequest);

    const fetchRuleDetailsRequest = useSelector(selectFetchRuleDetailsRequest);
    const updateRulesRequest = useSelector(selectUpdateRulesRequest);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues();
        const errors = validateForm(t, values);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const legalFormId = values[FieldsEnum.legalFormId];
            if (!isNumber(legalFormId)) {
                return;
            }

            dispatch(
                createPartnerRequest({
                    name: values[FieldsEnum.name],
                    address: {
                        street1: values[FieldsEnum.street1],
                        street2: values[FieldsEnum.street2],
                        country: values[FieldsEnum.countryCode] as CountryCodeT,
                        zipCode: values[FieldsEnum.zipCode],
                        city: values[FieldsEnum.city],
                    },
                    type: values[FieldsEnum.partnerType],
                    legalFormId,
                }),
            );

            formikHelpers.setTouched({});
        },
    });

    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);
    React.useEffect(() => {
        if (hasAnyFieldValueChanges) {
            setNeedCloseConfirmation(hasAnyFieldValueChanges);
        }
    }, [hasAnyFieldValueChanges]);

    useCloseSidebarAfterRequest(creationRequestStatus, onGoBack || onClose);

    const isShowLoader = fetchRuleDetailsRequest.loading || updateRulesRequest.loading;

    const options = React.useMemo(
        (): Array<PartnerOptionT> => [
            {
                label: t('common:company-type.SHIPPER'),
                value: PartnerTypeEnum.shipper,
            },
            {
                label: t('common:company-type.CARRIER'),
                value: PartnerTypeEnum.carrier,
            },
        ],
        [t],
    );

    const selectedCountry = formik.values[FieldsEnum.countryCode];
    const selectedLegalFormId = formik.values[FieldsEnum.legalFormId];
    useCheckCountryCorrectLegacyForm(
        {
            selectedLegalFormId,
            selectedCountry,
        },
        () => {
            formik.setTouched({ [FieldsEnum.legalFormId]: false }, false);
            formik.setFieldValue(FieldsEnum.legalFormId, null);
        },
    );

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            {isShowLoader && <LoaderOverlay />}
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('partners-table.create-form.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <div className={cx('section')}>
                    <div className={cx('section__block')}>
                        <FormikField
                            className={cx('field--name')}
                            name={FieldsEnum.name}
                            error={formik.errors[FieldsEnum.name]}
                            meta={formik.getFieldMeta(FieldsEnum.name)}
                            label={t('partners-table.create-form.fields.name.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.name}
                                    value={formik.values[FieldsEnum.name]}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            className={cx('field--type')}
                            name={FieldsEnum.partnerType}
                            error={formik.errors[FieldsEnum.partnerType]}
                            meta={formik.getFieldMeta(FieldsEnum.partnerType)}
                            label={t('partners-table.create-form.fields.type.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DropdownInput<PartnerOptionT, PartnerTypeEnum>
                                    selectedValue={formik.values[FieldsEnum.partnerType]}
                                    options={options}
                                    onSelect={props.onChange}
                                    renderOption={(option) => <span>{option?.label}</span>}
                                    getOptionValue={(option) => option?.value}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            className={cx('field--street1')}
                            name={FieldsEnum.street1}
                            error={formik.errors[FieldsEnum.street1]}
                            meta={formik.getFieldMeta(FieldsEnum.street1)}
                            label={t('partners-table.create-form.fields.street1.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.street1}
                                    value={formik.values[FieldsEnum.street1]}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            className={cx('field--street1')}
                            name={FieldsEnum.countryCode}
                            error={formik.errors[FieldsEnum.countryCode]}
                            label=""
                            meta={formik.getFieldMeta(FieldsEnum.countryCode)}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <CountryDropdown
                                    value={formik.values[FieldsEnum.countryCode]}
                                    onChange={props.onChange}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                />
                            )}
                        </FormikField>
                        <FieldGroup spaces={1}>
                            <FormikField
                                className={cx('field--zip')}
                                name={FieldsEnum.zipCode}
                                error={formik.errors[FieldsEnum.zipCode]}
                                meta={formik.getFieldMeta(FieldsEnum.zipCode)}
                                label={t('partners-table.create-form.fields.zip.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.zipCode}
                                        value={formik.values[FieldsEnum.zipCode]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--city')}
                                name={FieldsEnum.city}
                                error={formik.errors[FieldsEnum.city]}
                                meta={formik.getFieldMeta(FieldsEnum.city)}
                                label={t('partners-table.create-form.fields.city.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.city}
                                        value={formik.values[FieldsEnum.city]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        <FormikField
                            className={cx('field--legal-form')}
                            name={FieldsEnum.legalFormId}
                            error={formik.errors[FieldsEnum.legalFormId]}
                            meta={formik.getFieldMeta(FieldsEnum.legalFormId)}
                            label={t('partners-table.create-form.fields.legal-form.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <LegalFormDropdown
                                    countryCode={formik.values[FieldsEnum.countryCode]}
                                    value={formik.values[FieldsEnum.legalFormId]}
                                    onChange={props.onChange}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            name={FieldsEnum.street2}
                            error={formik.errors[FieldsEnum.street2]}
                            meta={formik.getFieldMeta(FieldsEnum.street2)}
                            label={t('partners-table.create-form.fields.street2.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <Input
                                    name={FieldsEnum.street2}
                                    value={formik.values[FieldsEnum.street2]}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                />
                            )}
                        </FormikField>
                    </div>
                </div>
                <FooterSideBarLayout hasPaddings hasTopBorder>
                    <Button
                        theme={ButtonThemeEnum.primary}
                        isLoading={creationRequestStatus.loading}
                        className={cx('button--add')}
                        type="submit"
                    >
                        {t('partners-table.create-form.submit')}
                    </Button>
                </FooterSideBarLayout>
            </SideBarLayout>
        </form>
    );
};

export default CreatePartnerSidebarContent;
