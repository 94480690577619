import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './OrdersTable.scss';
import { useTranslation } from 'react-i18next';
import StatusCell from './cell-renderers/StatusCell/StatusCell';
import PartnersCell from './cell-renderers/PartnersCell/PartnersCell';
import { OrderStatusEnum } from 'common/utils/api/models';
import Table, { TableColumnT, TableRowMetaT, TableRowModsEnum } from 'common/components/Table/Table';
import { BrokerOrderT } from 'broker-admin/store/orders/models';
import EquipmentCell from './cell-renderers/EquipmentCell/EquipmentCell';
import LocationsCell from './cell-renderers/LocationsCell/LocationsCell';
import DatesCell from './cell-renderers/DatesCell/DatesCell';
import CreateCell from './cell-renderers/CreateCell/CreateCell';
import { CompanyTypeEnum } from 'common/constants';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import IdCell from 'common/components/Table/cell-renderers/IdCell/IdCell';

const cx = classNames.bind(styles);

type PropsT = {
    orders: Array<BrokerOrderT>;
    className?: string;
    goToOrderDetails: (event: React.MouseEvent, orderId: OrderIdT) => void;
    goToPartnerDetails: (companyId: PartnerIdT, companyType: CompanyTypeEnum) => void;
    isLoading: boolean;
};

const OrdersTable: React.FC<PropsT> = React.memo((props) => {
    const { orders, className, goToOrderDetails, goToPartnerDetails, isLoading } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<BrokerOrderT, void>> = [
        {
            renderHeader: () => <span>{t('orders.list.table.columns.id')}</span>,
            headerClassName: cx('table__header', 'table__header--id'),
            render: (order: BrokerOrderT) => <IdCell id={order.number} />,
            className: cx('table__column', 'table__column--id'),
        },
        {
            renderHeader: () => <span>{t('orders.list.table.columns.partners')}</span>,
            headerClassName: cx('table__header'),
            render: (order: BrokerOrderT) => <PartnersCell order={order} onOpenPartnerDetails={goToPartnerDetails} />,
            className: cx('table__column', 'table__column--partners'),
        },
        {
            renderHeader: () => <span>{t('orders.list.table.columns.dates')}</span>,
            headerClassName: cx('table__header'),
            render: (order: BrokerOrderT) => <DatesCell order={order} />,
            className: cx('table__column'),
        },
        {
            renderHeader: () => <span>{t('orders.list.table.columns.locations')}</span>,
            headerClassName: cx('table__header'),
            render: (order: BrokerOrderT) => <LocationsCell order={order} />,
            className: cx('table__column', 'table__column--locations'),
        },
        {
            renderHeader: () => <span>{t('orders.list.table.columns.created')}</span>,
            headerClassName: cx('table__header'),
            render: (order: BrokerOrderT) => <CreateCell order={order} />,
            className: cx('table__column', 'table__column--create'),
        },
        {
            renderHeader: () => <span>{t('orders.list.table.columns.equipment')}</span>,
            headerClassName: cx('table__header'),
            render: (order: BrokerOrderT) => <EquipmentCell order={order} />,
            className: cx('table__column'),
        },
        {
            renderHeader: () => <span>{t('orders.list.table.columns.status')}</span>,
            headerClassName: cx('table__header'),
            render: (order: BrokerOrderT) => <StatusCell order={order} />,
            className: cx('table__column', 'table__column--status'),
        },
    ];

    const handleSelectRow = (event: React.MouseEvent, order: BrokerOrderT) => {
        if (!order.id) {
            return;
        }

        goToOrderDetails(event, order.id);
    };

    const getRowMods = (meta: TableRowMetaT, order: BrokerOrderT) => {
        return {
            [TableRowModsEnum.hasLeftGreenBorder]: order.status === OrderStatusEnum.delivered,
            [TableRowModsEnum.hasLeftRedBorder]: false,
        };
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<BrokerOrderT> tableName="orders" isLoading={isLoading} rows={orders}>
                {(rows, isUsedPrevRows) => (
                    <Table<BrokerOrderT, void>
                        className={className}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        onSelectRow={handleSelectRow}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default OrdersTable;
