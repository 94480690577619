import React from 'react';
import classNames from 'classnames/bind';

import styles from './SendingToSpotForm.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { useFormik } from 'formik';
import FormikField from 'common/components/forms/FormikField/FormikField';
import TimeWindowPicker from 'common/components/forms/TimeWindowPicker/TimeWindowPicker';
import { getRelativeStartDayTimeWindow, MS_IN_HOUR, roundTimestamp } from 'common/utils/time';
import { logWarning } from 'common/utils/logger';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { ALL_DAY_RANGE } from 'common/constants';
import Input from 'common/components/Input/Input';
import LinkDatePicker, {
    DatePickerOverlayPositionEnum,
} from 'design-system/components/date-pickers/LinkDatePicker/LinkDatePicker';
import { getDateStringToTimeWindow, mergeWithRelativeTimeWindow } from './utils';
import { selectSpotRequestLimitMs } from 'broker-admin/store/settings/selectors';
import { fetchSettings } from 'common/store/settings/actions';
import moment from 'moment';
import TimeWindowPillLabel from './TimeWindowPillLabel/TimeWindowPillLabel';
import isEqual from 'lodash/isEqual';
import { sendToSpot } from 'broker-admin/store/spot/actions';
import { selectSpotUpdateRequestStatus } from 'broker-admin/store/spot/selectors';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';
import { findActualTour } from 'broker-admin/store/dispatch-details/utils/find-actual-tour';

const cx = classNames.bind(styles);

type PropsT = {
    dispatchDetails: DispatchDetailsT;
    onCancel: () => void;
};

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.deadlineTimeWindow]: [Date.now(), Date.now() + 2 * MS_IN_HOUR],
    [FieldsEnum.comment]: '',
};

const SendingToSpotForm: React.FC<PropsT> = (props) => {
    const { onCancel, dispatchDetails } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchSettings());
    }, []);

    const spotRequest = useSelector(selectSpotUpdateRequestStatus);

    const spotRequestLimitMs = useSelector(selectSpotRequestLimitMs);

    const startValidTillTimeWindow = React.useMemo(() => {
        return roundTimestamp(Date.now(), MS_IN_HOUR);
    }, []);

    const allowedRangeValidTill = React.useMemo((): TimeWindowT | null => {
        if (!dispatchDetails) {
            return null;
        }

        const firstWaypoint = findActualTour(dispatchDetails?.tours)?.waypoints?.[0] || null;
        const pickupDockingHoursFrom =
            firstWaypoint?.correctedDateTimeFrom || firstWaypoint?.originalDateTimeFrom || null;
        const pickupDockingHoursFromTimestamp = moment(pickupDockingHoursFrom).valueOf();

        return [startValidTillTimeWindow, pickupDockingHoursFromTimestamp - spotRequestLimitMs];
    }, [startValidTillTimeWindow, dispatchDetails, spotRequestLimitMs]);

    const [minDateCalendarDate, maxDateCalendarDate] = React.useMemo(() => {
        if (!allowedRangeValidTill) {
            return [null, null];
        }

        const format = (timestamp: number) => moment(timestamp).format('YYYY-MM-DD');

        return [format(allowedRangeValidTill[0]), format(allowedRangeValidTill[1])];
    }, [allowedRangeValidTill]);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values: FormValuesT = {
            ...INITIAL_VALUES,
            [FieldsEnum.deadlineTimeWindow]: allowedRangeValidTill,
            [FieldsEnum.comment]: '',
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, [allowedRangeValidTill]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const dispatchId = dispatchDetails.id;
            if (!dispatchId) {
                logWarning('empty dispatchId');
                return;
            }
            const deadline = values[FieldsEnum.deadlineTimeWindow]?.[1];
            if (!deadline) {
                logWarning('empty deadline');
                return;
            }

            const deadlineStr = moment(deadline).format();

            const comment = values[FieldsEnum.comment] || '';

            dispatch(sendToSpot(dispatchId, deadlineStr, comment));

            formikHelpers.setTouched({});
        },
    });

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);

    const deadlineTimeWindow = formik.values[FieldsEnum.deadlineTimeWindow];

    const { selectedDate, selectedRelativeTimeWindow } = React.useMemo(() => {
        const selectedDate = getDateStringToTimeWindow(deadlineTimeWindow);
        const selectedRelativeTimeWindow = getRelativeStartDayTimeWindow(deadlineTimeWindow, selectedDate);

        return {
            selectedDate,
            selectedRelativeTimeWindow,
        };
    }, [deadlineTimeWindow]);

    const handleChangeDate = (value: Date | null) => {
        const startTimestamp = formik.values[FieldsEnum.deadlineTimeWindow]?.[0] || null;

        const endTimestamp = value ? moment(value).endOf('day').valueOf() - 1 : null;
        const limitedEndTimestamp =
            endTimestamp && allowedRangeValidTill ? Math.min(endTimestamp, allowedRangeValidTill?.[1]) : endTimestamp;

        formik.setTouched({
            [FieldsEnum.deadlineTimeWindow]: true,
        });

        formik.setFieldValue(FieldsEnum.deadlineTimeWindow, [startTimestamp, limitedEndTimestamp]);
    };

    const selectedCalendarDate = React.useMemo(() => {
        return selectedDate ? new Date(selectedDate) : null;
    }, [selectedDate]);

    const availableRelativeValues = React.useMemo((): TimeWindowT | null => {
        return getRelativeStartDayTimeWindow(allowedRangeValidTill, selectedDate);
    }, [allowedRangeValidTill, selectedDate]);

    const handleChangeTimeWindow = (name: FieldsEnum.deadlineTimeWindow, relativeTimeWindow: TimeWindowT): void => {
        const prevTimeWindow = formik.values[name];
        if (!prevTimeWindow) {
            return;
        }

        const timeWindow = mergeWithRelativeTimeWindow(prevTimeWindow, relativeTimeWindow);

        const isSameValue = isEqual(prevTimeWindow, timeWindow);
        if (isSameValue) {
            return;
        }

        formik.setFieldValue(name, timeWindow);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('form')}>
                <FormikField
                    name={FieldsEnum.deadlineTimeWindow}
                    error={formik.errors[FieldsEnum.deadlineTimeWindow]}
                    meta={formik.getFieldMeta(FieldsEnum.deadlineTimeWindow)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    withoutLabel
                    className={cx('field', 'field--time-picker')}
                >
                    {(props) => (
                        <TimeWindowPicker
                            range={ALL_DAY_RANGE}
                            availableValues={availableRelativeValues || ALL_DAY_RANGE}
                            ranges={[]}
                            name={FieldsEnum.deadlineTimeWindow}
                            hasStartStep
                            hasEndStep
                            isDisableLeftRangeControl
                            values={selectedRelativeTimeWindow}
                            setFieldValue={handleChangeTimeWindow}
                            label={t('dispatch-details.send-to-spot.fields.deadline-time-window.label')}
                            duration={
                                <TimeWindowPillLabel
                                    className={cx('time-window-pill-label')}
                                    timeWindow={formik.values[FieldsEnum.deadlineTimeWindow]}
                                />
                            }
                            link={
                                <LinkDatePicker
                                    minDate={minDateCalendarDate}
                                    maxDate={maxDateCalendarDate}
                                    value={selectedCalendarDate}
                                    onChange={handleChangeDate}
                                    overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                />
                            }
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field')}
                    name={FieldsEnum.comment}
                    error={formik.errors[FieldsEnum.comment]}
                    meta={formik.getFieldMeta(FieldsEnum.comment)}
                    label={t('dispatch-details.send-to-spot.fields.comment.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.comment}
                            value={formik.values[FieldsEnum.comment]}
                            placeholder={t('dispatch-details.send-to-spot.fields.comment.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
            </div>
            <div className={cx('actions')}>
                <Button theme={ButtonThemeEnum.secondary} className={cx('action', 'action--cancel')} onClick={onCancel}>
                    {t('dispatch-details.send-to-spot.actions.cancel')}
                </Button>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={hasFormAnyErros || spotRequest.loading}
                    className={cx('action', 'action--send')}
                    isLoading={spotRequest.loading}
                    type="submit"
                >
                    {t('dispatch-details.send-to-spot.actions.submit')}
                </Button>
            </div>
        </form>
    );
};

export default SendingToSpotForm;
