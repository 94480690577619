import * as React from 'react';
import { ShipperContractLaneRoutesEnum } from 'broker-admin/constants';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import { PartnerTypeEnum } from 'common/utils/api/models';
import history, { goBackIfHasRePath } from 'common/utils/history';
import { urlFactory } from 'broker-admin/utils/urls';
import PartnerContext from 'common/contexts/partner-context';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import ShipperContractLaneCreationPage from './ShipperContractLaneCreationPage/ShipperContractLaneCreationPage';
import ShipperContractLaneDetailsPage from './ShipperContractLaneDetailsPage/ShipperContractLaneDetailsPage';

type PropsT = {};

type ParamsT = {
    partnerType: PartnerTypeEnum;
    partnerId: PartnerIdT;
    contractId: ShipperContractIdT;
};

const ShipperContractLanePage: React.FC<PropsT> = React.memo((props) => {
    const params = useParams<ParamsT>();

    const { partnerType, partnerId, contractId } = params;

    const defaultRedirectPath = generatePath(ShipperContractLaneRoutesEnum.newLane, {
        partnerId,
        partnerType,
        contractId,
    });

    const onGoBack = () => {
        goBackIfHasRePath(() => {
            history.push(
                urlFactory.partnerShipperContactLanes({
                    partnerType,
                    partnerId,
                    contractId,
                }),
            );
        });
    };

    const partnerContextValue = React.useMemo(() => {
        return {
            partnerType: params.partnerType,
            partnerId: params.partnerId,
        };
    }, [params.partnerId, params.partnerType]);

    return (
        <PartnerContext.Provider value={partnerContextValue}>
            <Switch>
                <Route path={ShipperContractLaneRoutesEnum.newLane}>
                    <ShipperContractLaneCreationPage contractId={contractId} onClose={onGoBack} />
                </Route>
                <Route path={ShipperContractLaneRoutesEnum.laneDetails}>
                    <ShipperContractLaneDetailsPage onClose={onGoBack} />
                </Route>
                <Route>
                    <Redirect to={defaultRedirectPath} />
                </Route>
            </Switch>
            <SideBars />
        </PartnerContext.Provider>
    );
});

export default ShipperContractLanePage;
