import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkEmail, checkNotEmpty } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [FieldsEnum.email, FieldsEnum.partnerType];

const EMAIL_FIELDS = [FieldsEnum.email];

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkEmail(t, EMAIL_FIELDS, values),
    };

    return errors;
};

export default validate;
