import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './DropdownControlTriggerLabel.scss';

const cx = classNames.bind(styles);

type PropsT = {
    label: string;
    icon?: React.ReactNode;
};

const DropdownControlTriggerLabel: React.FC<PropsT> = (props) => {
    const { label, icon } = props;

    return (
        <div className={cx('option')}>
            {icon && <div className={cx('option__icon')}>{icon}</div>}
            <div className={cx('option__label')}>{label}</div>
        </div>
    );
};

export default DropdownControlTriggerLabel;
