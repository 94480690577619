import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './RFQStats.scss';
import TwinDatePicker from 'design-system/components/date-pickers/TwinDatePicker/TwinDatePicker';
import { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRFQStats, resetRFQStats } from 'broker-admin/store/partner/rfq-stats/actions';
import { selectCompanyDocumentsGroup, selectRFQStatsRequest } from 'broker-admin/store/partner/rfq-stats/selectors';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

type PropsT = {};

const cx = classNames.bind(styles);

const DATE_FORMAT = 'YYYY-MM-DD';

const TODAY = moment().format();

const RFQStats: React.FC<PropsT> = React.memo(() => {
    const dispatch = useDispatch();

    const { partnerId } = usePartnerContext();

    const requestStatus = useSelector(selectRFQStatsRequest);

    const partnerRFQStats = useSelector(selectCompanyDocumentsGroup(partnerId));

    const [dates, setDates] = React.useState<DateRangeT | null>(() => {
        const { query } = partnerRFQStats;

        const queryFrom = query?.fromDate;
        const queryTo = query?.toDate;
        if (queryFrom && queryTo) {
            const parsedFrom = moment(queryFrom, DATE_FORMAT);
            const parsedTo = moment(queryTo, DATE_FORMAT);

            if (parsedFrom.isValid() && parsedTo.isValid() && parsedTo.isAfter(parsedFrom)) {
                return {
                    from: parsedFrom.toDate(),
                    to: moment(queryTo, DATE_FORMAT).toDate(),
                };
            }
        }

        const defaultDateTo = moment().toDate();
        const defaultDateFrom = moment().subtract(1, 'month').toDate();

        return {
            from: defaultDateFrom,
            to: defaultDateTo,
        };
    });

    React.useEffect(() => {
        return () => {
            dispatch(resetRFQStats());
        };
    }, []);

    React.useEffect(() => {
        if (!dates) {
            return;
        }
        const { from, to } = dates;
        if (!from || !to || !partnerId) {
            return;
        }

        dispatch(
            fetchRFQStats({
                companyId: partnerId,
                fromDate: moment(from).format(DATE_FORMAT),
                toDate: moment(to).format(DATE_FORMAT),
            }),
        );
    }, [partnerId, dates]);

    return (
        <form
            className={cx('form')}
            onSubmit={(event) => {
                // enter interceptor
                event.stopPropagation();
                event.preventDefault();
            }}
        >
            <TwinDatePicker
                className={cx('dates')}
                value={dates}
                maxDate={TODAY}
                hasYearMonthForm
                onChange={setDates}
                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                isTransparent
            />
            <PillLabel className={cx('pill')} isRounded isSymmetrical theme={PillLabelThemeEnum.charcoal}>
                {requestStatus?.loading ? (
                    <ControlLoaderIcon fillColor={StyleGuideColorsEnum.white} size={DEFAULT_ICON_SIZE} />
                ) : (
                    <span className={cx('count')}>{partnerRFQStats.stats?.count}</span>
                )}
            </PillLabel>
        </form>
    );
});

export default RFQStats;
