import { fork } from 'redux-saga/effects';

import ratesSaga from './rates/sagas';
import rulesSaga from './rules/sagas';
import systemSettingsSaga from './system-settings/sagas';
import additionalServicesSaga from './additional-services/sagas';

function* priceSettingsSaga() {
    yield fork(ratesSaga);
    yield fork(rulesSaga);
    yield fork(systemSettingsSaga);
    yield fork(additionalServicesSaga);
}

export default priceSettingsSaga;
