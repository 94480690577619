export enum FieldsEnum {
    transportationOrderHasNoDriverAlertTime = 'transportationOrderHasNoDriverAlertTime',
    assignDriverLimitAlert = 'assignDriverLimitAlert',
    allowedGAPToBeDelayed = 'ALLOWED_GAP_TO_BE_DELAYED',
    deleteNotificationsOlderDays = 'DELETE_NOTIFICATIONS_OLDER_DAYS',
    docExpiresSoonAlert = 'DOC_EXPIRES_SOON_ALERT',
    assignAssetLimitAlert = 'assignAssetLimitAlert',
    transportationOrderHasNoAssetsAlertTime = 'transportationOrderHasNoAssetsAlertTime',
    radiusAroundWaypointM = 'RADIUS_AROUND_WAYPOINT_METERS',
    timeLimitToCancelOrderHours = 'TIME_LIMIT_TO_CANCEL_ORDER_HOURS',
    timeLimitToCancelTransportationOrderHours = 'TIME_LIMIT_TO_CANCEL_TRANSPORTATION_ORDER_HOURS',
}

export type FormValuesT = {
    [FieldsEnum.transportationOrderHasNoDriverAlertTime]: string;
    [FieldsEnum.assignDriverLimitAlert]: string;
    [FieldsEnum.allowedGAPToBeDelayed]: string;
    [FieldsEnum.deleteNotificationsOlderDays]: string;
    [FieldsEnum.docExpiresSoonAlert]: string;
    [FieldsEnum.assignAssetLimitAlert]: string;
    [FieldsEnum.transportationOrderHasNoAssetsAlertTime]: string;
    [FieldsEnum.radiusAroundWaypointM]: string;
    [FieldsEnum.timeLimitToCancelOrderHours]: string;
    [FieldsEnum.timeLimitToCancelTransportationOrderHours]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
