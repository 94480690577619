import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    strokeColor: StyleGuideColorsEnum;
    fillColor: StyleGuideColorsEnum;
    size: number;
};

const TargetIcon: React.FC<PropsT> = (props) => {
    const { size, className, strokeColor, fillColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" className={className}>
            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                <path d="M0 0h18v18H0z" />
                <circle cx="9" cy="9" r="1.5" fill={fillColor} />
                <path
                    fill={strokeColor}
                    d="M9 6a3 3 0 100 6 3 3 0 100-6zm0 4.5c-.825 0-1.5-.675-1.5-1.5S8.175 7.5 9 7.5s1.5.675 1.5 1.5-.675 1.5-1.5 1.5zm6.705-2.25A6.746 6.746 0 009.75 2.295V.75h-1.5v1.545A6.746 6.746 0 002.295 8.25H.75v1.5h1.545a6.746 6.746 0 005.955 5.955v1.545h1.5v-1.545a6.746 6.746 0 005.955-5.955h1.545v-1.5h-1.545zM9 14.25A5.246 5.246 0 013.75 9 5.246 5.246 0 019 3.75 5.246 5.246 0 0114.25 9 5.246 5.246 0 019 14.25z"
                />
            </g>
        </svg>
    );
};

TargetIcon.displayName = 'TargetIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    strokeColor: StyleGuideColorsEnum.slate,
    fillColor: StyleGuideColorsEnum.light,
};

export { storyProps };
export default TargetIcon;
