import { BrokerOrdersStateT } from './types';
import { StateT } from '../models';
import { BrokerOrderDetailsT, BrokerOrderT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const selectBrokerOrdersPages = (state: StateT): BrokerOrdersStateT['pages'] => state.orders.pages;

export const selectBrokerOrdersQuery = (state: StateT): BrokerOrdersStateT['query'] => state.orders.query;

export const selectBrokerOrdersTotal = (state: StateT): BrokerOrdersStateT['total'] => state.orders.total;

export const selectBrokerOrdersByIds = (state: StateT): BrokerOrdersStateT['byId'] => state.orders.byId;

export const selectBrokerOrderDetails =
    (id: BrokerOrderT['id']) =>
    (state: StateT): BrokerOrderDetailsT | null => {
        return state.orders.detailsById[id as string] || null;
    };

export const selectBrokerOrderDetailsRequest = (state: StateT): RequestStatusT => state.orders.fetchDetailsRequest;
