export enum FieldsEnum {
    deadlineTimeWindow = 'deadlineTimeWindow',
    comment = 'comment',
}

export type FormValuesT = {
    [FieldsEnum.deadlineTimeWindow]: TimeWindowT | null;
    [FieldsEnum.comment]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
