import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { AddressTypeEnum } from 'common/utils/api/models';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { VerifyPartnerAdressConfirmationDataT } from './models';
import { StyleGuideColorsEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';

type PropsT = {
    data: VerifyPartnerAdressConfirmationDataT | null;
    onConfirm: (data: VerifyPartnerAdressConfirmationDataT | null) => void;
    onCancel: () => void;
    onClose: () => void;
    requestStatus: RequestStatusT;
};

const TEST_SELECTOR = 'verify-partner-address-confirmation';

const I18N_LABELS_MAP: Record<AddressTypeEnum, { title: string; message: string }> = {
    [AddressTypeEnum.postal]: {
        title: 'partner.details-tab.dialogs.verify-postal-address.title',
        message: 'partner.details-tab.dialogs.verify-postal-address.description',
    },
    [AddressTypeEnum.legal]: {
        title: 'partner.details-tab.dialogs.verify-legal-address.title',
        message: 'partner.details-tab.dialogs.verify-legal-address.description',
    },
    [AddressTypeEnum.billing]: {
        title: 'partner.details-tab.dialogs.verify-billing-address.title',
        message: 'partner.details-tab.dialogs.verify-billing-address.description',
    },
};

const VerifyPartnerAdressConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { data, onConfirm, onCancel, onClose, requestStatus } = props;

    const { t } = useTranslation();

    const i18nLabelsMap = I18N_LABELS_MAP[data?.addressType as AddressTypeEnum];

    if (!data) {
        return null;
    }

    const labels = {
        title: t(i18nLabelsMap.title),
        message: t(i18nLabelsMap.message),
    };

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            onClick: onCancel,
                            isDisabled: requestStatus.loading,
                        },
                        {
                            children: t('common:error-modal.actions.verify'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isDisabled: requestStatus.loading,
                            isLoading: requestStatus.loading,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                    {...labels}
                />
            </ModalContent>
        </Modal>
    );
});

export default VerifyPartnerAdressConfirmation;
