import { put, select, takeEvery } from 'redux-saga/effects';
import {
    FETCH_RATES_PAGE_REQUEST,
    FetchRatesPageActionT,
    UPDATE_RATES_REQUEST,
    UpdateRatesActionT,
    UPLOAD_CSV_FILE_REQUEST,
    UploadCsvFileActionT,
} from './types';
import {
    fetchRatesPageBegin,
    fetchRatesPageError,
    fetchRatesPageSuccess,
    resetRates,
    updateRatesBegin,
    updateRatesError,
    updateRatesSuccess,
    uploadCSVFailError,
    uploadCSVFileBegin,
    uploadCSVFileSuccess,
} from './actions';
import { selectRatesPages, selectRatesQuery, selectUpdateRatesRequest, selectUploadCSVFileRequest } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import { ratesPaginationChannel, ratesRefreshChannel } from 'broker-admin/store/price-settings/rates/channels';

function* fetchRatesSaga(action: FetchRatesPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options } = action;

    const prevQuery: ReturnType<typeof selectRatesQuery> = yield select(selectRatesQuery);
    const pages: ReturnType<typeof selectRatesPages> = yield select(selectRatesPages);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus);
    if (isSameQuery && !isNeedRequest && !options?.isForceUpdate) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetRates({ savingPageNumber: pageNumber }));
    }

    yield put(fetchRatesPageBegin(query, pageNumber));

    const [error, response]: ReturnApiT<typeof brokerTranziitApi.fetchRatesPage> =
        yield brokerTranziitApi.fetchRatesPage({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchRatesPageError(query, pageNumber, error));
        return;
    }

    yield put(fetchRatesPageSuccess(query, pageNumber, response));

    checkShouldEmitChangePage(pageNumber, response, ratesPaginationChannel);
}

function* updateRatesSaga(action: UpdateRatesActionT): WrapGeneratorT<void> {
    const request: ReturnType<typeof selectUpdateRatesRequest> = yield select(selectUpdateRatesRequest);
    if (request.loading) {
        return;
    }

    yield put(updateRatesBegin());

    const { ids, value, strategy } = action;

    const [error]: ReturnApiT<typeof brokerTranziitApi.updateRates> = yield brokerTranziitApi.updateRates(
        ids,
        value,
        strategy,
    );
    if (error) {
        yield put(updateRatesError(error));
        return;
    }

    yield put(updateRatesSuccess());

    ratesRefreshChannel.emit({});
}

function* uploadCsvFileSaga(action: UploadCsvFileActionT): WrapGeneratorT<void> {
    const { file } = action;

    const request: ReturnType<typeof selectUploadCSVFileRequest> = yield select(selectUploadCSVFileRequest);
    if (request.loading) {
        return;
    }

    yield put(uploadCSVFileBegin());

    const [error]: ReturnApiT<typeof brokerTranziitApi.uploadRatesCSVFile> = yield brokerTranziitApi.uploadRatesCSVFile(
        file,
    );
    if (error) {
        yield put(uploadCSVFailError(error));
        return;
    }

    yield put(uploadCSVFileSuccess());

    ratesRefreshChannel.emit({});
}

function* ratesSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_RATES_PAGE_REQUEST, fetchRatesSaga);
    yield takeEvery(UPDATE_RATES_REQUEST, updateRatesSaga);
    yield takeEvery(UPLOAD_CSV_FILE_REQUEST, uploadCsvFileSaga);
}

export default ratesSaga;
