import * as React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './SpotRequestDetailsTabs.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { SpotRequestDetailsT } from 'broker-admin/store/spot-request-details/models';
import { formatDiff, getTimeLeft, MS_IN_HOUR, parseDiff } from 'common/utils/time';
import { SpotDetailsTabEnum } from 'broker-admin/components/BaseSpotRequestDetailsSidebarContent/SpotRequestDetails/models';
import ButtonGroup from 'design-system/components/ButtonGroup/ButtonGroup';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;

    isShowStatus: boolean;

    details: SpotRequestDetailsT | null;

    selectedTab: SpotDetailsTabEnum | null;
    onSelectTab: (selectedTab: SpotDetailsTabEnum | null) => void;
};

const ASSIGNMENT_WARNING = 24 * MS_IN_HOUR;

const SpotRequestDetailsTabs: React.FC<PropsT> = React.memo((props) => {
    const { className, details, onSelectTab, isShowStatus, selectedTab } = props;

    const { t } = useTranslation();

    const bidCount = details?.bids?.length || 0;

    const deadline = details?.expiresAt;
    const timeLeft = getTimeLeft(deadline);
    const diff = timeLeft !== null ? timeLeft : null;

    const parsedDiff = isNumber(diff) ? parseDiff(diff) : null;

    const formattedDiff = parsedDiff ? formatDiff(t, parsedDiff) : null;

    if (!details) {
        return null;
    }

    const renderDiffStatus = (diff: number | null) => {
        if (diff === null || !isShowStatus) {
            return null;
        }

        if (diff < 0) {
            return <div className={cx('already-started')}>{t('spot-request-details.start-select-bid.expired')}</div>;
        }

        return (
            <div
                className={cx('status', {
                    'status--is-warning': isNumber(diff) && diff <= ASSIGNMENT_WARNING,
                })}
            >
                <div className={cx('time')}>{formattedDiff}</div>
                <div className={cx('message')}>{t('spot-request-details.start-select-bid.to-assign')}</div>
            </div>
        );
    };
    return (
        <div className={cs(cx('container'), className)}>
            <ButtonGroup className={cx('button-group')}>
                <Button
                    key={SpotDetailsTabEnum.details}
                    theme={ButtonThemeEnum.transparent}
                    onClick={() => {
                        onSelectTab(SpotDetailsTabEnum.details);
                    }}
                    isToggled={selectedTab === SpotDetailsTabEnum.details}
                >
                    {t('spot-request-details.details-tabs.details')}
                </Button>
                <Button
                    key={SpotDetailsTabEnum.bids}
                    theme={ButtonThemeEnum.transparent}
                    onClick={() => {
                        onSelectTab(SpotDetailsTabEnum.bids);
                    }}
                    isToggled={selectedTab === SpotDetailsTabEnum.bids}
                    leftIcon={
                        bidCount ? (
                            <PillLabel isSymmetrical theme={PillLabelThemeEnum.orange} isRounded>
                                <span className={cx('count')}>{bidCount}</span>
                            </PillLabel>
                        ) : null
                    }
                >
                    {t('spot-request-details.details-tabs.bids')}
                </Button>
            </ButtonGroup>
            {renderDiffStatus(diff)}
        </div>
    );
});

export default SpotRequestDetailsTabs;
