import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './BidsCountCell.scss';

import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { useTranslation } from 'react-i18next';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import isNumber from 'lodash/isNumber';

type PropsT = {
    numberOfNewBids: number | null | undefined;
    numberOfReadBids: number | null | undefined;
    numberOfSentBids: number | null | undefined;
};

const cx = classNames.bind(styles);

const BidsCountCell: React.FC<PropsT> = React.memo((props) => {
    const { numberOfNewBids, numberOfReadBids, numberOfSentBids } = props;

    const { t } = useTranslation();

    const renderBid = ({
        count,
        placeholder,
        theme,
        isShowPlusSign,
    }: {
        count: number | null | undefined;
        placeholder: React.ReactNode;
        theme: PillLabelThemeEnum;
        isShowPlusSign?: boolean;
    }): React.ReactNode => {
        if (!isNumber(count)) {
            return null;
        }

        return (
            <Tooltip
                className={cx('tooltip')}
                position={TooltipPositionEnum.topCenter}
                theme={TooltipThemeEnum.black}
                tooltipNode={
                    placeholder ? (
                        <TooltipContent className={cx('tooltip-content')} theme={TooltipContentThemeEnum.black}>
                            {placeholder}
                        </TooltipContent>
                    ) : null
                }
            >
                {(isShow, childrenClassName) => (
                    <PillLabel isSymmetrical theme={theme} className={childrenClassName}>
                        {isShowPlusSign ? '+' : ''}
                        {count}
                    </PillLabel>
                )}
            </Tooltip>
        );
    };

    return (
        <div className={cx('wrap')}>
            {renderBid({
                count: numberOfSentBids,
                placeholder: t('spot-requests.list.table.bidCounts.sent'),
                theme: PillLabelThemeEnum.grey,
            })}
            {numberOfReadBids
                ? renderBid({
                      count: numberOfReadBids,
                      placeholder: t('spot-requests.list.table.bidCounts.accepted'),
                      theme: PillLabelThemeEnum.slate,
                  })
                : null}
            {numberOfNewBids
                ? renderBid({
                      count: numberOfNewBids,
                      isShowPlusSign: true,
                      placeholder: t('spot-requests.list.table.bidCounts.new'),
                      theme: PillLabelThemeEnum.orange,
                  })
                : null}
        </div>
    );
});

export default BidsCountCell;
