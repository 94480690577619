import React, { useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './StatisticsChart.scss';

import useDimensions from 'common/utils/hooks/useDimensions';
import { DataItemT } from './models';

import Chart from './Chart/Chart';
import moment from 'moment';
import { GraphSettingsT } from 'broker-admin/layouts/StatisticsPage/models';
import { StatisticGranularityEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import { DoneStatisticT, RejectStatisticT } from 'broker-admin/store/statistics/models';
import { formatMoney } from 'common/components/Money/Money';
import { CurrencyEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    settings: GraphSettingsT;
    granularity: StatisticGranularityEnum;
    doneStatistics: DoneStatisticT | null;
    rejectStatistics: RejectStatisticT | null;
};

const MARGIN = {
    top: 40,
    bottom: 40,
    left: 60,
    right: 40,
};

const FORMAT_BY_GRANULARITY: Record<StatisticGranularityEnum, string> = {
    [StatisticGranularityEnum.day]: 'DD MMM',
    [StatisticGranularityEnum.week]: 'DD MMM',
    [StatisticGranularityEnum.month]: 'MMM',
};

const StatisticsChart: React.FC<PropsT> = React.memo((props) => {
    const { settings, granularity, doneStatistics, rejectStatistics } = props;

    const { dimensions, ref } = useDimensions({ liveMeasure: true });

    const data = useMemo((): DataItemT[] => {
        const series = doneStatistics?.series || [];

        return series.map((_, index) => {
            const doneStat = doneStatistics?.series?.[index] || null;
            const rejectStat = rejectStatistics?.series?.[index] || null;

            const base = {
                rejectedShipperRate: rejectStat?.avgShipperRate || 0,
                acceptedShipperRate: doneStat?.avgShipperRate || 0,
                estimatedCarrierRate: doneStat?.avgCarrierRateEstimate || 0,
                realCarrierRate: doneStat?.avgCarrierRate || 0,
            };

            return {
                ...base,
                timestamp: moment(doneStat?.dateKey).valueOf(),
                tooltipData: {
                    ...base,
                    margin: doneStat?.avgMarginRate || 0,
                    deadhead: doneStat?.avgDeadheadPercent || 0,
                    avgDistance: Math.round(doneStat?.avgDistanceKm || 0),
                },
            };
        });
    }, [doneStatistics, rejectStatistics]);

    return (
        <div className={cx('container')} ref={ref}>
            {dimensions && (
                <div className={cx('chart')}>
                    <Chart
                        margin={MARGIN}
                        width={dimensions.width}
                        height={dimensions.height}
                        settings={settings}
                        data={data}
                        xTickFormatter={(date) => moment(date).format(FORMAT_BY_GRANULARITY[granularity])}
                        yTickFormatter={(value) =>
                            formatMoney({
                                amount: value,
                                currency: CurrencyEnum.EUR,
                            })
                        }
                    />
                </div>
            )}
        </div>
    );
});

export default StatisticsChart;
