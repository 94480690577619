import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './NextToursDiff.scss';
import { Trans } from 'react-i18next';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { CurrencyEnum, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import RouteIcon from 'common/icons/RouteIcon';
import Money from 'common/components/Money/Money';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { useSelector } from 'react-redux';
import {
    selectSelfCostPrediction,
    selectTrailerAssignmentInfoById,
    selectTruckAssignmentInfoById,
} from 'broker-admin/store/dispatch-assigment/selectors';
import MoneySigned from 'common/components/MoneySigned/MoneySigned';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    selectedTruckId: TruckIdT | null;
    selectedTrailerId: TrailerIdT | null;
};

const getPercent = (diff: number | null | undefined, amount: number | null | undefined): number => {
    if (!isNumber(diff) || !isNumber(amount)) {
        return 0;
    }

    return Math.abs(Math.round((amount ? diff / amount : 0) * 100));
};

const NextToursDiff: React.FC<PropsT> = React.memo((props) => {
    const { className, selectedTruckId, selectedTrailerId } = props;

    const selfCostPrediction = useSelector(selectSelfCostPrediction);

    const truckById = useSelector(selectTruckAssignmentInfoById);
    const truck = truckById[selectedTruckId as TruckIdT] || null;

    const trailerById = useSelector(selectTrailerAssignmentInfoById);
    const trailer = trailerById[selectedTrailerId as TrailerIdT] || null;

    if (!selfCostPrediction) {
        return null;
    }

    const {
        trailerNextTourPriceDifference: trailerNextTourPriceDifferencePercent,
        trailerNextTourNewCost,
        trailerNextTourOldCost,

        truckNextTourPriceDifference: truckNextTourPriceDifferencePercent,
        truckNextTourNewCost,
        truckNextTourOldCost,
    } = selfCostPrediction;

    const summaryNextTourNewCost = (trailerNextTourNewCost || 0) + (truckNextTourNewCost || 0);
    const summaryNextTourOldCost = (trailerNextTourOldCost || 0) + (truckNextTourOldCost || 0);
    const summaryNextTourDifference = summaryNextTourNewCost - summaryNextTourOldCost;
    const summaryNextTourDifferencePercent = getPercent(summaryNextTourDifference, summaryNextTourOldCost);

    const truckNextTourPriceDifference = (truckNextTourNewCost || 0) - (truckNextTourOldCost || 0);
    const trailerNextTourPriceDifference = (trailerNextTourNewCost || 0) - (trailerNextTourOldCost || 0);

    if (!summaryNextTourDifference) {
        return null;
    }

    const isPositiveSummary = summaryNextTourDifference < 0;

    return (
        <div className={cs(cx('wrap'), className)}>
            <Tooltip
                className={cx('tooltip-wrap')}
                position={TooltipPositionEnum.bottomRight}
                theme={TooltipThemeEnum.black}
                tooltipNode={
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={510}>
                        <div className={cx('content')}>
                            <div className={cx('text')}>
                                <ol>
                                    {!!truckNextTourPriceDifferencePercent && (
                                        <li>
                                            <Trans
                                                i18nKey={
                                                    truckNextTourPriceDifferencePercent > 0
                                                        ? 'assignment.next-tours-diff.truck.more-expensive'
                                                        : 'assignment.next-tours-diff.truck.cheaper'
                                                }
                                                components={{
                                                    plateNumber: truck?.plateNumber || ' ',
                                                    amount: (
                                                        <Money
                                                            amount={Math.abs(truckNextTourPriceDifference)}
                                                            currency={CurrencyEnum.EUR}
                                                        />
                                                    ),
                                                    percent: (
                                                        <UnitTypeCount
                                                            type={UnitTypeEnum.percentsAbbreviation}
                                                            count={Math.abs(truckNextTourPriceDifferencePercent)}
                                                        />
                                                    ),
                                                }}
                                            />
                                            <ul>
                                                <li>
                                                    <Trans
                                                        i18nKey="assignment.next-tours-diff.truck.current-cost"
                                                        components={{
                                                            amount: (
                                                                <Money
                                                                    amount={truckNextTourOldCost}
                                                                    currency={CurrencyEnum.EUR}
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                </li>
                                                <li>
                                                    <Trans
                                                        i18nKey="assignment.next-tours-diff.truck.new-cost"
                                                        components={{
                                                            amount: (
                                                                <Money
                                                                    amount={truckNextTourNewCost}
                                                                    currency={CurrencyEnum.EUR}
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                </li>
                                            </ul>
                                        </li>
                                    )}
                                    {!!trailerNextTourPriceDifferencePercent && (
                                        <li>
                                            <Trans
                                                i18nKey={
                                                    trailerNextTourPriceDifferencePercent > 0
                                                        ? 'assignment.next-tours-diff.trailer.more-expensive'
                                                        : 'assignment.next-tours-diff.trailer.cheaper'
                                                }
                                                components={{
                                                    plateNumber: trailer?.plateNumber || ' ',
                                                    amount: (
                                                        <Money
                                                            amount={Math.abs(trailerNextTourPriceDifference)}
                                                            currency={CurrencyEnum.EUR}
                                                        />
                                                    ),
                                                    percent: (
                                                        <UnitTypeCount
                                                            type={UnitTypeEnum.percentsAbbreviation}
                                                            count={Math.abs(trailerNextTourPriceDifferencePercent)}
                                                        />
                                                    ),
                                                }}
                                            />
                                            <ul>
                                                <li>
                                                    <Trans
                                                        i18nKey="assignment.next-tours-diff.trailer.current-cost"
                                                        components={{
                                                            amount: (
                                                                <Money
                                                                    amount={trailerNextTourOldCost}
                                                                    currency={CurrencyEnum.EUR}
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                </li>
                                                <li>
                                                    <Trans
                                                        i18nKey="assignment.next-tours-diff.trailer.new-cost"
                                                        components={{
                                                            amount: (
                                                                <Money
                                                                    amount={trailerNextTourNewCost}
                                                                    currency={CurrencyEnum.EUR}
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                </li>
                                            </ul>
                                        </li>
                                    )}
                                </ol>
                            </div>
                            <div className={cx('icon')}>
                                <RouteIcon
                                    fillColor={
                                        isPositiveSummary
                                            ? StyleGuideColorsEnum.brandAccent
                                            : StyleGuideColorsEnum.orange
                                    }
                                />
                            </div>
                        </div>
                    </TooltipContent>
                }
            >
                {(isShow, childrenClassName) => (
                    <PillLabel
                        className={cs(cx('label'), childrenClassName)}
                        theme={isPositiveSummary ? PillLabelThemeEnum.brandAccent : PillLabelThemeEnum.orange}
                        testSelector="next-tours-diff"
                        isSymmetrical
                        isRounded
                    >
                        <Trans
                            i18nKey="assignment.next-tours-diff.summary"
                            components={{
                                amount: <MoneySigned amount={summaryNextTourDifference} currency={CurrencyEnum.EUR} />,
                                percent: (
                                    <UnitTypeCount
                                        type={UnitTypeEnum.percentsAbbreviation}
                                        count={Math.abs(summaryNextTourDifferencePercent)}
                                    />
                                ),
                            }}
                        />
                    </PillLabel>
                )}
            </Tooltip>
        </div>
    );
});

export default NextToursDiff;
