import { combineReducers } from 'redux';

import ratesReducer from './rates/reducer';
import rulesReducer from './rules/reducer';
import systemSettingsReducer from './system-settings/reducer';
import additionalServicesReducer from './additional-services/reducer';

import { RatesStateT } from './rates/types';
import { RulesStateT } from './rules/types';
import { SystemSettingsStateT } from './system-settings/types';
import { AdditionalServicesStateT } from './additional-services/types';

export default combineReducers({
    rates: ratesReducer,
    rules: rulesReducer,
    systemSettings: systemSettingsReducer,
    additionalServices: additionalServicesReducer,
});

export type PriceSettingsStateT = {
    rates: RatesStateT;
    rules: RulesStateT;
    systemSettings: SystemSettingsStateT;
    additionalServices: AdditionalServicesStateT;
};
