import React from 'react';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import Modal from 'common/components/Modal/Modal';
import { RequestStatusT } from 'common/utils/request-status';
import InvitePartnerForm from 'broker-admin/layouts/PartnersPage/InvitePartnerForm/InvitePartnerForm';
import { ApiInvitePartnerRequestT } from 'common/utils/api/models';

export type InvitePartnerModalDataT = {};

type PropsT = {
    data: InvitePartnerModalDataT | null;
    onClose: () => void;
    onInvitePartner: (data: ApiInvitePartnerRequestT) => void;
    requestStatus: RequestStatusT;
};

const InvitePartnerDialog: React.FC<PropsT> = React.memo((props) => {
    const { data, onClose, requestStatus, onInvitePartner } = props;

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <InvitePartnerForm onInvitePartner={onInvitePartner} addPartnerRequestStatus={requestStatus} />
            </ModalContent>
        </Modal>
    );
});

export default InvitePartnerDialog;
