import { RequestStatusT } from 'common/utils/request-status';

import { FetchRatesPageQueryT, RateT } from './models';
import { UpdateRateStrategyEnum } from 'common/utils/api/models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_RATES_PAGE_REQUEST = 'broker-admin/price-settings/rates/FETCH_RATES_PAGE_REQUEST';
export const FETCH_RATES_PAGE_REQUEST_BEGIN = 'broker-admin/price-settings/rates/FETCH_RATES_PAGE_REQUEST_BEGIN';
export const FETCH_RATES_PAGE_REQUEST_ERROR = 'broker-admin/price-settings/rates/FETCH_RATES_PAGE_REQUEST_ERROR';
export const FETCH_RATES_PAGE_REQUEST_SUCCESS = 'broker-admin/price-settings/rates/FETCH_RATES_PAGE_REQUEST_SUCCESS';
export const RESET_RATES = 'broker-admin/price-settings/rates/RESET_RATES';
export const UPDATE_RATES_REQUEST = 'broker-admin/price-settings/rates/UPDATE_RATES_REQUEST';
export const UPDATE_RATES_REQUEST_BEGIN = 'broker-admin/price-settings/rates/UPDATE_RATES_REQUEST_BEGIN';
export const UPDATE_RATES_REQUEST_ERROR = 'broker-admin/price-settings/rates/UPDATE_RATES_REQUEST_ERROR';
export const UPDATE_RATES_REQUEST_SUCCESS = 'broker-admin/price-settings/rates/UPDATE_RATES_REQUEST_SUCCESS';
export const UPLOAD_CSV_FILE_REQUEST = 'broker-admin/price-settings/rates/UPLOAD_CSV_FILE_REQUEST';
export const UPLOAD_CSV_FILE_REQUEST_BEGIN = 'broker-admin/price-settings/rates/UPLOAD_CSV_FILE_REQUEST_BEGIN';
export const UPLOAD_CSV_FILE_REQUEST_FAIL = 'broker-admin/price-settings/rates/UPLOAD_CSV_FILE_REQUEST_FAIL';
export const UPLOAD_CSV_FILE_REQUEST_SUCCESS = 'broker-admin/price-settings/rates/UPLOAD_CSV_FILE_REQUEST_SUCCESS';

export type RatesStateT = PaginationStateT<RateT, FetchRatesPageQueryT> & {
    updateRequest: RequestStatusT;
    uploadCSVRequest: RequestStatusT;
};

export type FetchRatesPageActionT = {
    type: typeof FETCH_RATES_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchRatesPageQueryT;
    options?: FetchOptionsT;
};

export type FetchRatesPageBeginActionT = {
    type: typeof FETCH_RATES_PAGE_REQUEST_BEGIN;
    query: FetchRatesPageQueryT;
    pageNumber: PageNumberT;
};

export type FetchRatesPageSuccessActionT = {
    type: typeof FETCH_RATES_PAGE_REQUEST_SUCCESS;
    pageNumber: PageNumberT;
    query: FetchRatesPageQueryT;
    pageResponse: PageResponseT<RateT> | null;
};

export type FetchRatesPageErrorActionT = {
    type: typeof FETCH_RATES_PAGE_REQUEST_ERROR;
    query: FetchRatesPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
};

export type ResetRatesActionT = {
    type: typeof RESET_RATES;
    mutationListOptions: MutationListOptionsT;
};

export type UpdateRatesActionT = {
    type: typeof UPDATE_RATES_REQUEST;
    ids: Array<RateT['id']>;
    value: number;
    strategy: UpdateRateStrategyEnum;
};

export type UpdateRatesBeginActionT = {
    type: typeof UPDATE_RATES_REQUEST_BEGIN;
};

export type UpdateRatesSuccessActionT = {
    type: typeof UPDATE_RATES_REQUEST_SUCCESS;
};

export type UpdateRatesErrorActionT = {
    type: typeof UPDATE_RATES_REQUEST_ERROR;
    error: Error;
};

export type UploadCsvFileActionT = {
    type: typeof UPLOAD_CSV_FILE_REQUEST;
    file: File;
};

export type UploadCsvFileBeginActionT = {
    type: typeof UPLOAD_CSV_FILE_REQUEST_BEGIN;
};

export type UploadCsvFileSuccessActionT = {
    type: typeof UPLOAD_CSV_FILE_REQUEST_SUCCESS;
};

export type UploadCsvFileFailActionT = {
    type: typeof UPLOAD_CSV_FILE_REQUEST_FAIL;
    error: Error;
};

export type RatesActionT =
    | FetchRatesPageActionT
    | FetchRatesPageBeginActionT
    | FetchRatesPageSuccessActionT
    | FetchRatesPageErrorActionT
    | UpdateRatesActionT
    | UpdateRatesBeginActionT
    | UpdateRatesSuccessActionT
    | UpdateRatesErrorActionT
    | UploadCsvFileActionT
    | UploadCsvFileBeginActionT
    | UploadCsvFileSuccessActionT
    | UploadCsvFileFailActionT
    | ResetRatesActionT;
