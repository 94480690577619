import * as React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import DropdownMultipleInput from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInput';
import { useTranslation } from 'react-i18next';

export enum TransportOrderTypeEnum {
    spot = 'spot',
    contracted = 'contracted',
}

type OptionT = {
    id: TransportOrderTypeEnum;
    label: React.ReactNode;
};

export type PropsT = {
    className?: string;
    value: Array<TransportOrderTypeEnum>;
    onSelect: (value: Array<TransportOrderTypeEnum>) => void;
    testSelector?: string;
};

const TransportOrderTypeDropdown = (props: PropsT) => {
    const { className, value, onSelect, testSelector } = props;

    const { t } = useTranslation();

    const options = React.useMemo((): Array<OptionT> => {
        return [
            {
                id: TransportOrderTypeEnum.spot,
                label: t('dispatches.list.filters.transport-order-type-filter.spot'),
            },
            {
                id: TransportOrderTypeEnum.contracted,
                label: t('dispatches.list.filters.transport-order-type-filter.contracted'),
            },
        ];
    }, []);

    return (
        <DropdownMultipleInput<OptionT, TransportOrderTypeEnum>
            className={className}
            selectedValues={value}
            options={options}
            onSelect={onSelect}
            testSelector={testSelector}
            renderOption={(option) => {
                if (!option) {
                    return null;
                }

                return <DropdownControlOptionLabel withoutPaddings label={option.label} />;
            }}
            renderTriggerContent={(selectedOptions) => {
                const labelParts: Array<string> = [];

                if (!selectedOptions?.length) {
                    labelParts.push(t('dispatches.list.filters.transport-order-type-filter.all'));
                }
                if (selectedOptions?.find((option) => option.id === TransportOrderTypeEnum.spot)) {
                    labelParts.push(t('dispatches.list.filters.transport-order-type-filter.spot'));
                }
                if (selectedOptions?.find((option) => option.id === TransportOrderTypeEnum.contracted)) {
                    labelParts.push(t('dispatches.list.filters.transport-order-type-filter.contracted'));
                }

                const label = labelParts.join(', ');

                return <DropdownControlOptionLabel withoutPaddings label={label} />;
            }}
            getOptionValue={(option) => option?.id}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
        />
    );
};

export default TransportOrderTypeDropdown;
