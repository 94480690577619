import { RequestStatusT } from 'common/utils/request-status';
import { CarrierUtilizationT } from './models';

export const FETCH_UTILIZATION_REQUEST = 'common/carriers-utilization/FETCH_UTILIZATION_REQUEST';
export const FETCH_UTILIZATION_REQUEST_BEGIN = 'common/carriers-utilization/FETCH_UTILIZATION_REQUEST_BEGIN';
export const FETCH_UTILIZATION_REQUEST_ERROR = 'common/carriers-utilization/FETCH_UTILIZATION_REQUEST_ERROR';
export const FETCH_UTILIZATION_REQUEST_SUCCESS = 'common/carriers-utilization/FETCH_UTILIZATION_REQUEST_SUCCESS';
export const RESET = 'common/carriers-utilization/RESET';

export type CarriersUtilizationStateT = {
    fetchRequest: RequestStatusT;
    byHash: Record<string, CarrierUtilizationT | null>;
};

export type FetchCarrierUtilizationActionT = {
    type: typeof FETCH_UTILIZATION_REQUEST;
    ids: CarrierIdT[];
    date: string;
};

export type FetchCarrierUtilizationBeginActionT = {
    type: typeof FETCH_UTILIZATION_REQUEST_BEGIN;
};

export type FetchCarrierUtilizationSuccessActionT = {
    type: typeof FETCH_UTILIZATION_REQUEST_SUCCESS;
    ids: CarrierIdT[];
    date: string;
    utilizations: Array<CarrierUtilizationT>;
};

export type FetchCarrierUtilizationErrorActionT = {
    type: typeof FETCH_UTILIZATION_REQUEST_ERROR;
    error: Error;
};

export type CarrierUtilizationResetActionT = {
    type: typeof RESET;
};

export type CarriersUtilizationActionT =
    | FetchCarrierUtilizationActionT
    | FetchCarrierUtilizationBeginActionT
    | FetchCarrierUtilizationSuccessActionT
    | FetchCarrierUtilizationErrorActionT
    | CarrierUtilizationResetActionT;
