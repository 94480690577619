import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './CreateCell.scss';
import { BrokerOrderT } from 'broker-admin/store/orders/models';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import { clearDateTimezone, formatDate } from 'common/utils/time';

type PropsT = {
    order: BrokerOrderT;
};

const cx = classNames.bind(styles);

const CreateCell: React.FC<PropsT> = React.memo((props) => {
    const { order } = props;

    return (
        <div className={cx('wrap')}>
            <div className={cx('date')}>{formatDate('DD MMM YYYY', clearDateTimezone(order.createdDate))}</div>
            <div>
                <ClickInterceptorLabel>
                    <span className={cx('creator')}>{order.createdByName}</span>
                </ClickInterceptorLabel>
            </div>
        </div>
    );
});

export default CreateCell;
