import { FieldsEnum, FormValuesT } from './constants';
import { SystemSettingsChangesT } from 'broker-admin/store/price-settings/system-settings/models';
import { parseSimpleNumber } from 'common/utils/input-parsers';

const prepareApiSystemSettings = (formValues: FormValuesT): SystemSettingsChangesT => {
    const firstRadiusOfAssignmentFilter = parseSimpleNumber(formValues[FieldsEnum.firstRadiusOfAssignmentFilter]);
    const secondRadiusOfAssignmentFilter = parseSimpleNumber(formValues[FieldsEnum.secondRadiusOfAssignmentFilter]);
    const thirdRadiusOfAssignmentFilter = parseSimpleNumber(formValues[FieldsEnum.thirdRadiusOfAssignmentFilter]);
    const timeToHookTrailer = parseSimpleNumber(formValues[FieldsEnum.timeToHookTrailer]);
    const timeToDropTrailer = parseSimpleNumber(formValues[FieldsEnum.timeToDropTrailer]);
    const timeToPickupTruck = parseSimpleNumber(formValues[FieldsEnum.timeToPickupTruck]);
    const timeToDropTruck = parseSimpleNumber(formValues[FieldsEnum.timeToDropTruck]);
    const geoMatrixCoefficient = parseSimpleNumber(formValues[FieldsEnum.geoMatrixCoefficient]);
    const averageTruckSpeed = parseSimpleNumber(formValues[FieldsEnum.averageTruckSpeed]);

    return {
        FIRST_RADIUS_OF_ASSIGNMENT_FILTER: firstRadiusOfAssignmentFilter?.toString(),
        SECOND_RADIUS_OF_ASSIGNMENT_FILTER: secondRadiusOfAssignmentFilter?.toString(),
        THIRD_RADIUS_OF_ASSIGNMENT_FILTER: thirdRadiusOfAssignmentFilter?.toString(),
        TIME_TO_HOOK_TRAILER: timeToHookTrailer?.toString(),
        TIME_TO_DROP_TRAILER: timeToDropTrailer?.toString(),
        TIME_TO_PICKUP_TRUCK: timeToPickupTruck?.toString(),
        TIME_TO_DROP_TRUCK: timeToDropTruck?.toString(),
        GEO_MATRIX_COEFFICIENT: geoMatrixCoefficient?.toString(),
        AVERAGE_TRUCK_SPEED: averageTruckSpeed?.toString(),
    };
};

export default prepareApiSystemSettings;
