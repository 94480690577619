import {
    FETCH_INVOICES_PAGE_REQUEST,
    FETCH_INVOICES_PAGE_REQUEST_BEGIN,
    FETCH_INVOICES_PAGE_REQUEST_ERROR,
    FETCH_INVOICES_PAGE_REQUEST_SUCCESS,
    FetchInvoicesPageActionT,
    FetchInvoicesPageBeginActionT,
    FetchInvoicesPageErrorActionT,
    FetchInvoicesPageSuccessActionT,
    RESET_INVOICES,
    ResetInvoicesActionT,
} from './types';
import { FetchInvoicesPageQueryT, BrokerInvoiceT, DepreactedPagingStateT } from './models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchInvoicesPage = (
    pageNumber: PageNumberT,
    query: FetchInvoicesPageQueryT,
    options?: FetchOptionsT,
): FetchInvoicesPageActionT => ({
    type: FETCH_INVOICES_PAGE_REQUEST,
    query,
    pageNumber,
    options,
});

export const fetchInvoicesPageBegin = (
    query: FetchInvoicesPageQueryT,
    pageNumber: PageNumberT,
): FetchInvoicesPageBeginActionT => ({
    type: FETCH_INVOICES_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
});

export const fetchInvoicesPageSuccess = (
    query: FetchInvoicesPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<BrokerInvoiceT> | null,
    pagingState: DepreactedPagingStateT | null,
): FetchInvoicesPageSuccessActionT => ({
    type: FETCH_INVOICES_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
    pagingState,
});

export const fetchInvoicesPageError = (
    query: FetchInvoicesPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchInvoicesPageErrorActionT => ({
    type: FETCH_INVOICES_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
});

export const resetInvoices = (mutationListOptions: MutationListOptionsT): ResetInvoicesActionT => ({
    type: RESET_INVOICES,
    mutationListOptions,
});
