import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkMaxValue, checkMinValue, checkNotEmpty } from 'common/utils/form-validators';
import { PricingTypeEnum } from 'broker-admin/store/price-settings/system-settings/models';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import isNumber from 'lodash/isNumber';

const REQUIRED_FIELDS = [
    FieldsEnum.minimalShipmentCost,
    FieldsEnum.driverSalaryPerHour,
    FieldsEnum.minimalCostForTruck,
    FieldsEnum.minimalCostForTrailer,
    FieldsEnum.layoverCostPerHour,
    FieldsEnum.layoverCostPerDay,
    FieldsEnum.layoverFreeHours,
    FieldsEnum.urgentOverPrice,
    FieldsEnum.overPriceForEveryAdditionalStop,
    FieldsEnum.costPerGreenShipment,
    FieldsEnum.tranziitServiceFeeFixedMode,
    FieldsEnum.tranziitServiceFeePercent,
    FieldsEnum.tranziitServiceFeeFixed,
    FieldsEnum.pricingStrategy,
    FieldsEnum.truckConsumption,
    FieldsEnum.reeferConsumption,
    FieldsEnum.rateMatrixSpread,
] as const;

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),

        ...checkMinValue(t, [FieldsEnum.minimalShipmentCost], values, 0),
        ...checkMaxValue(t, [FieldsEnum.minimalShipmentCost], values, 2000),

        ...checkMinValue(t, [FieldsEnum.driverSalaryPerHour], values, 0),
        ...checkMaxValue(t, [FieldsEnum.driverSalaryPerHour], values, 100),

        ...checkMinValue(t, [FieldsEnum.layoverCostPerHour], values, 0),
        ...checkMaxValue(t, [FieldsEnum.layoverCostPerHour], values, 1000),

        ...checkMinValue(t, [FieldsEnum.minimalCostForTruck, FieldsEnum.minimalCostForTrailer], values, 0),
        ...checkMaxValue(t, [FieldsEnum.minimalCostForTruck, FieldsEnum.minimalCostForTrailer], values, 2000),

        ...checkMinValue(t, [FieldsEnum.layoverCostPerDay], values, 0),
        ...checkMaxValue(t, [FieldsEnum.layoverCostPerDay], values, 10000),

        ...checkMinValue(t, [FieldsEnum.layoverFreeHours], values, 0),
        ...checkMaxValue(t, [FieldsEnum.layoverFreeHours], values, 72),

        ...checkMinValue(t, [FieldsEnum.urgentOverPrice], values, 0),
        ...checkMaxValue(t, [FieldsEnum.urgentOverPrice], values, 1000),

        ...checkMinValue(t, [FieldsEnum.overPriceForEveryAdditionalStop], values, 0),
        ...checkMaxValue(t, [FieldsEnum.overPriceForEveryAdditionalStop], values, 1000),

        ...checkMinValue(t, [FieldsEnum.costPerGreenShipment], values, 0),
        ...checkMaxValue(t, [FieldsEnum.costPerGreenShipment], values, 500),

        ...checkMinValue(t, [FieldsEnum.tranziitServiceFeeFixed], values, 0),
        ...checkMaxValue(t, [FieldsEnum.tranziitServiceFeeFixed], values, 200),

        ...checkMinValue(t, [FieldsEnum.tranziitServiceFeePercent], values, 0),
        ...checkMaxValue(t, [FieldsEnum.tranziitServiceFeePercent], values, 20),

        ...checkMinValue(t, [FieldsEnum.truckConsumption], values, 20),
        ...checkMaxValue(t, [FieldsEnum.truckConsumption], values, 40),

        ...checkMinValue(t, [FieldsEnum.reeferConsumption], values, 0),
        ...checkMaxValue(t, [FieldsEnum.reeferConsumption], values, 10),

        ...checkMinValue(t, [FieldsEnum.rateMatrixSpread], values, 0),
        ...checkMaxValue(t, [FieldsEnum.rateMatrixSpread], values, 100),
    };

    const isDisabledPeriodicRulesForMonths = values[FieldsEnum.pricingStrategy] !== PricingTypeEnum.rateMatrix;
    if (isDisabledPeriodicRulesForMonths && values[FieldsEnum.usePeriodicRulesForMonths]) {
        errors[FieldsEnum.usePeriodicRulesForMonths] = t(
            'price-settings.pricing-form.errors.auto-rules-for-months-only-for-price-matrix',
        );
    }

    const layoverCostPerHour = parseSimpleNumber(values[FieldsEnum.layoverCostPerHour]);
    const layoverCostPerDay = parseSimpleNumber(values[FieldsEnum.layoverCostPerDay]);
    if (isNumber(layoverCostPerHour) && isNumber(layoverCostPerDay) && layoverCostPerHour >= layoverCostPerDay) {
        errors[FieldsEnum.layoverCostPerDay] = t(
            'price-settings.pricing-form.errors.layover-per-day-less-layover-per-hours',
        );
    }

    return errors;
};

export default validate;
