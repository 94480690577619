import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './EditTimeWindowsFooterContent.scss';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import EditTimeWindowsForm from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/EditTimeWindowsForm/EditTimeWindowsForm';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    dispatchDetails: DispatchDetailsT | null;
    onCancel: () => void;
};

const EditTimeWindowsFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { dispatchDetails, onCancel } = props;

    if (!dispatchDetails) {
        return null;
    }

    return (
        <div className={cx('content')}>
            <EditTimeWindowsForm dispatchDetails={dispatchDetails} onCancel={onCancel} />
        </div>
    );
});

export default EditTimeWindowsFooterContent;
