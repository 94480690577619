import { ScheduleEventT } from './models';
import { parseDateString } from 'common/utils/time';
import { ApiLocationT } from 'common/utils/api/models';
import { ApiCoordinateT } from 'broker-admin/utils/api/broker-tranziit/models';
import isNumber from 'lodash/isNumber';

export const getCurrentScheduleEvent = (assetId: AssetIdT, schedule: ScheduleEventT[]): ScheduleEventT | null => {
    const now = Date.now();

    const currentEvents = schedule.filter((event) => {
        const dateFrom = event.truckId === assetId ? event.truckDateFrom : event.trailerDateFrom;
        const start = parseDateString(dateFrom);

        const dateTo = event.truckId === assetId ? event.truckDateTo : event.trailerDateTo;
        const end = parseDateString(dateTo);

        return !!start && !!end && start < now && now < end;
    });

    return currentEvents[0] || null;
};

export const convertToCoordinate = (location: ApiLocationT | null): ApiCoordinateT | null => {
    if (!location) {
        return null;
    }

    const { latitude, longitude } = location;
    if (isNumber(latitude) && isNumber(longitude)) {
        return {
            latitude,
            longitude,
        };
    }

    return null;
};
