import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ShipperContractLanesLayout.scss';
import { PartnerTypeEnum } from 'common/utils/api/models';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import history from 'common/utils/history';
import BaseShipperContractLanesLayout from 'common/layouts/BaseShipperContractLanesLayout/BaseShipperContractLanesLayout';
import { urlFactory } from 'broker-admin/utils/urls';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    partnerType: PartnerTypeEnum;
    contractId: ShipperContractIdT;
};

const ShipperContractLanesLayout: React.FC<PropsT> = React.memo((props) => {
    const { partnerId, partnerType, contractId } = props;

    const goToLaneCreation = () => {
        history.push(
            urlFactory.newPartnerShipperContractLane({
                partnerType,
                partnerId,
                contractId,
            }),
        );
    };

    const goToLaneDetails = (laneId: ShipperContractLaneIdT) => {
        history.push(
            urlFactory.partnerShipperContractLane({
                partnerType,
                partnerId,
                contractId,
                laneId,
            }),
        );
    };

    return (
        <ContentMargins>
            <BaseShipperContractLanesLayout
                partnerId={partnerId}
                contractId={contractId}
                onCreateShipperContractLane={goToLaneCreation}
                onOpenShipperContractLaneDetails={goToLaneDetails}
            />
        </ContentMargins>
    );
});

export default ShipperContractLanesLayout;
