import * as React from 'react';

import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './NameCell.scss';
import { RuleT } from 'broker-admin/store/price-settings/rules/models';
import { PriceSettingsRuleGroupEnum, RULE_GROUP_T_MAP } from '../../../constants';

type PropsT = {
    rule: RuleT;
};

const cx = classNames.bind(styles);

const NameCell: React.FC<PropsT> = React.memo((props) => {
    const { rule } = props;

    const { t } = useTranslation();

    return (
        <div>
            <div className={cx('name')}>{rule.name}</div>
            <div className={cx('group')}>{t(RULE_GROUP_T_MAP[rule.group as PriceSettingsRuleGroupEnum])}</div>
        </div>
    );
});

export default NameCell;
