import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import GoogleMapContext from 'common/contexts/google-map-context';
import HorizontalColumnsLayout from 'common/layouts/HorizontalColumnsLayout/HorizontalColumnsLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import PageTitle from 'common/components/PageTitle/PageTitle';
import {
    calculateCostRequest,
    createLaneRequest,
    resetShipperLaneCreation,
} from 'common/store/shipper-contract-lane-creation/slice';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import SideBarLayoutContextWrap from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import ShipperContractLaneCreationForm from 'broker-admin/layouts/ShipperContractLaneDetailsPage/ShipperContractLaneCreationPage/ShipperContractLaneCreationForm/ShipperContractLaneCreationForm';
import ShipperContractLaneCreationMap from 'broker-admin/layouts/ShipperContractLaneDetailsPage/ShipperContractLaneCreationPage/ShipperContractLaneCreationMap/ShipperContractLaneCreationMap';
import ParanjaSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/ParanjaSideBarLayout/ParanjaSideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import {
    CalculateCostRequestQueryT,
    CreateRequestQueryDraftT,
} from 'common/store/shipper-contract-lane-creation/models';
import { logWarning } from 'common/utils/logger';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import ShipperContractLaneCreationConfirmForm from 'broker-admin/layouts/ShipperContractLaneDetailsPage/ShipperContractLaneCreationPage/ShipperContractLaneCreationConfirmForm/ShipperContractLaneCreationConfirmForm';
import { CreationLaneConfirmDataT } from 'broker-admin/layouts/ShipperContractLaneDetailsPage/ShipperContractLaneCreationPage/ShipperContractLaneCreationConfirmForm/models';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';
import { selectCalculateCostRequest } from 'common/store/shipper-contract-lane-creation/selectors';

type PropsT = {
    contractId: ShipperContractIdT | null;
    onClose: () => void;
};

const ShipperContractLaneCreationPage: React.FC<PropsT> = React.memo((props) => {
    const { onClose, contractId } = props;

    const { partnerId } = usePartnerContext();

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const { t } = useTranslation();

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchCountriesDict());

        return () => {
            dispatch(resetShipperLaneCreation());
        };
    }, []);

    const [createRequestQueryDraft, setCreateRequestQueryDraft] = useState<CreateRequestQueryDraftT | null>(null);
    const [calculateQuery, setCalculateQuery] = useState<CalculateCostRequestQueryT | null>(null);
    const handleSubmit = (query: CalculateCostRequestQueryT, createQueryDraft: CreateRequestQueryDraftT) => {
        if (!contractId) {
            logWarning('failed to calculating cost, empty contractId');
            return;
        }

        setCalculateQuery(query);
        setCreateRequestQueryDraft(createQueryDraft);

        dispatch(
            calculateCostRequest({
                query,
                partnerId,
                contractId,
            }),
        );
    };

    const calculatingCostRequest = useSelector(selectCalculateCostRequest);
    useOnlyChangesEffect(() => {
        if (calculatingCostRequest.error) {
            setCreateRequestQueryDraft(null);
            setCalculateQuery(null);
        }
    }, [calculatingCostRequest]);

    const handleCancel = () => {
        setCalculateQuery(null);
    };

    const handleConfirm = (data: CreationLaneConfirmDataT) => {
        if (!contractId) {
            logWarning('failed to calculating cost, empty contractId');
            return;
        }
        if (!createRequestQueryDraft) {
            logWarning('failed to calculating cost, empty createRequestQueryDraft');
            return;
        }

        dispatch(
            createLaneRequest({
                query: {
                    ...createRequestQueryDraft,
                    totalPrice: data.totalPrice,
                },
                partnerId,
                contractId,
            }),
        );
    };

    return (
        <GoogleMapContext.Provider value={googleMapsContextValue}>
            <PageTitle title={t('page-titles.shipper-lane-creation')} />
            <HorizontalColumnsLayout
                leftColumnTitle={t('shipper-lane-creation.tabs.form')}
                leftColumnNode={
                    <SideBarLayoutContextWrap>
                        <HeaderSideBarLayout>
                            <HeaderSideBarContent title={t('shipper-lane-creation.title')} onClose={onClose} />
                        </HeaderSideBarLayout>
                        <SideBarLayout>
                            <ShipperContractLaneCreationForm contractId={contractId} onSubmit={handleSubmit} />
                        </SideBarLayout>
                        {!!calculateQuery && (
                            <>
                                <ParanjaSideBarLayout />
                                <FooterSideBarLayout isTransparent hasShadow>
                                    <ShipperContractLaneCreationConfirmForm
                                        onCancel={handleCancel}
                                        onConfirm={handleConfirm}
                                    />
                                </FooterSideBarLayout>
                            </>
                        )}
                    </SideBarLayoutContextWrap>
                }
                rightColumnTitle={t('shipper-lane-creation.tabs.map')}
                rightColumnNode={
                    <ShipperContractLaneCreationMap
                        isDisabledDraggable={!!calculateQuery}
                        isAllowShowLoaders={!!calculateQuery}
                    />
                }
            />
        </GoogleMapContext.Provider>
    );
});

export default ShipperContractLaneCreationPage;
