import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './AssignOptionLabel.scss';
import CircleIcon from 'common/icons/CircleIcon';
import getUtilizationColor from 'common/utils/get-utilization-color';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import isNil from 'lodash/isNil';

const cx = classNames.bind(styles);

type PropsT = {
    model?: string;
    plateNumber?: string;
    price?: string;
    assetUtilizationPercent: number | null | undefined;
    isAssetUtilizationLoading: boolean;
    carrierUtilizationPercent: number | null;
    isCarrierUtilizationLoading: boolean;
    isInvalid?: boolean;
};

const AssignOptionLabel: React.FC<PropsT> = React.memo((props) => {
    const {
        model,
        plateNumber,
        price,
        assetUtilizationPercent,
        isAssetUtilizationLoading,
        carrierUtilizationPercent,
        isCarrierUtilizationLoading,
        isInvalid,
    } = props;

    const { t } = useTranslation();

    return (
        <div
            className={cx('option', {
                'option--is-invalid': !!isInvalid,
            })}
        >
            <div className={cx('column')}>
                <div className={cx('model-and-plate-number')}>
                    {plateNumber || ''}
                    {' / '}
                    {model || ''}
                </div>
                <div className={cx('price')}>{price || ''}</div>
            </div>
            <div className={cx('column')}>
                <div className={cx('utilization')}>
                    {!isNil(assetUtilizationPercent) && (
                        <>
                            <CircleIcon borderColor={getUtilizationColor(assetUtilizationPercent)} />
                            <span className={cx('utilization__label')}>
                                {t('assignment.asset-utilization', { percent: assetUtilizationPercent })}
                            </span>
                        </>
                    )}
                    {isNil(assetUtilizationPercent) && isAssetUtilizationLoading && (
                        <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />
                    )}
                </div>
                <div className={cx('utilization')}>
                    {carrierUtilizationPercent !== null && (
                        <>
                            <CircleIcon borderColor={getUtilizationColor(carrierUtilizationPercent)} />
                            <span className={cx('utilization__label')}>
                                {t('assignment.carrier-utilization', { percent: carrierUtilizationPercent })}
                            </span>
                        </>
                    )}
                    {carrierUtilizationPercent === null && isCarrierUtilizationLoading && (
                        <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />
                    )}
                </div>
            </div>
        </div>
    );
});

export default AssignOptionLabel;
