import { RulesStateT } from './types';
import { StateT } from 'broker-admin/store/models';
import { RequestStatusT } from 'common/utils/request-status';
import { RuleDetailsT } from './models';

export const selectRulesPages = (state: StateT): RulesStateT['pages'] => state.priceSettings.rules.pages;

export const selectRulesQuery = (state: StateT): RulesStateT['query'] => state.priceSettings.rules.query;

export const selectRulesTotal = (state: StateT): RulesStateT['total'] => state.priceSettings.rules.total;

export const selectRulesByIds = (state: StateT): RulesStateT['byId'] => state.priceSettings.rules.byId;

export const selectUpdateRulesRequest = (state: StateT): RequestStatusT => state.priceSettings.rules.updateRequest;

export const selectCreateRuleRequest = (state: StateT): RequestStatusT => state.priceSettings.rules.creationRequest;

export const selectDeleteRulesRequest = (state: StateT): RequestStatusT => state.priceSettings.rules.deleteRequest;

export const selectFetchRuleDetailsRequest = (state: StateT): RequestStatusT =>
    state.priceSettings.rules.fetchDetailsRequest;

export const selectRuleDetails =
    (ruleId: RuleIdT | null) =>
    (state: StateT): RuleDetailsT | null => {
        if (ruleId === null) {
            return null;
        }

        return state.priceSettings.rules.detailsById[ruleId];
    };
