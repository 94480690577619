import { isNonNil } from 'common/utils';
import requestStatus from 'common/utils/request-status';
import keyBy from 'lodash/keyBy';
import {
    FETCH_DISPATCHERS_REQUEST_BEGIN,
    FETCH_DISPATCHERS_REQUEST_ERROR,
    FETCH_DISPATCHERS_REQUEST_SUCCESS,
    DispatchersActionT,
    DispatchersStateT,
} from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: DispatchersStateT = {
    request: requestStatus.initial,
    byId: {},
    allIds: [],
};

export default (state = initialState, action: DispatchersActionT | DestroySessionActionT): DispatchersStateT => {
    switch (action.type) {
        case FETCH_DISPATCHERS_REQUEST_BEGIN: {
            return {
                ...state,
                request: requestStatus.loading,
            };
        }
        case FETCH_DISPATCHERS_REQUEST_SUCCESS: {
            const { dispatchers } = action;

            const allIds: Array<string> = dispatchers.map((dispatcher) => dispatcher.id).filter(isNonNil);
            const byId = keyBy(dispatchers, 'id');

            return {
                ...state,
                byId,
                allIds,
                request: requestStatus.ok,
            };
        }
        case FETCH_DISPATCHERS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                request: requestStatus.setError(error),
            };
        }
        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
