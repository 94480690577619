import { RequestStatusT } from 'common/utils/request-status';

import { ApiUpdateDockingHoursQueryT } from 'common/utils/api/models';

export const UPDATE_DOCKING_HOURS = 'broker-admin/dispatch-edit/UPDATE_DOCKING_HOURS';

export const UPDATE_DISPATCH_REQUEST_BEGIN = 'broker-admin/dispatch-edit/UPDATE_DISPATCH_REQUEST_BEGIN';
export const UPDATE_DISPATCH_REQUEST_ERROR = 'broker-admin/dispatch-edit/UPDATE_DISPATCH_REQUEST_ERROR';
export const UPDATE_DISPATCH_REQUEST_SUCCESS = 'broker-admin/dispatch-edit/UPDATE_DISPATCH_REQUEST_SUCCESS';

export const RESET = 'broker-admin/dispatch-edit/RESET';

export type DispatchEditStateT = {
    dispatchId: DispatchIdT | null;
    requestStatus: RequestStatusT;
};

export type UpdateDockingHoursActionT = {
    type: typeof UPDATE_DOCKING_HOURS;
    dispatchId: DispatchIdT;
    query: ApiUpdateDockingHoursQueryT;
};

export type UpdateDispatchRequestBeginActionT = {
    type: typeof UPDATE_DISPATCH_REQUEST_BEGIN;
    dispatchId: DispatchIdT;
};

export type UpdateDispatchRequestSuccessActionT = {
    type: typeof UPDATE_DISPATCH_REQUEST_SUCCESS;
    dispatchId: DispatchIdT;
};

export type UpdateDispatchRequestErrorActionT = {
    type: typeof UPDATE_DISPATCH_REQUEST_ERROR;
    error: Error;
    dispatchId: DispatchIdT;
};

export type ResetActionT = {
    type: typeof RESET;
};

export type DispatchEditActionT =
    | UpdateDockingHoursActionT
    | UpdateDispatchRequestBeginActionT
    | UpdateDispatchRequestSuccessActionT
    | UpdateDispatchRequestErrorActionT
    | ResetActionT;
