import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './ServicesTable.scss';
import { useTranslation } from 'react-i18next';
import Table, { TableColumnT, TableRowMetaT } from 'common/components/Table/Table';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import NameCell from 'broker-admin/layouts/SettingsPage/AdditionalServicesSettings/ServicesTable/cell-renderers/NameCell/NameCell';
import EditableCostCell from 'common/components/Table/cell-renderers/EditableCostCell/EditableCostCell';
import { AdditionalServiceT } from 'broker-admin/store/price-settings/additional-services/models';

const cx = classNames.bind(styles);

type PropsT = {
    list: Array<AdditionalServiceT>;
    isDisabled: boolean;
    isLoading: boolean;
    className: string;
    onUpdateService: (id: AdditionalServiceIdT, value: number) => void;
};

const ServicesTable: React.FC<PropsT> = React.memo((props) => {
    const { list, className, onUpdateService, isDisabled, isLoading } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<AdditionalServiceT, void>> = [
        {
            renderHeader: () => <span>{t('price-settings.additional-services.name-group')}</span>,
            headerClassName: cx('table__header', 'table__header--name'),
            render: (additionalService) => <NameCell additionalService={additionalService} />,
            className: cx('table__column', 'table__column--name'),
        },
        {
            renderHeader: () => <span>{t('price-settings.additional-services.cost')}</span>,
            headerClassName: cx('table__header', 'table__header--cost'),
            render: (additionalService, meta) => (
                <EditableCostCell
                    cost={additionalService.cost}
                    isHoveredRow={meta.isHovered}
                    onUpdate={(cost) => {
                        onUpdateService(additionalService.id, cost);
                    }}
                />
            ),
            className: cx('table__column', 'table__column--cost'),
        },
    ];

    const getRowMods = (meta: TableRowMetaT) => ({
        isSelected: meta.isSelected,
    });

    return (
        <div className={cx('table')}>
            <MemoizedTable<AdditionalServiceT> tableName="additional-services" isLoading={isLoading} rows={list}>
                {(rows, isUsedPrevRows) => (
                    <Table<AdditionalServiceT, void>
                        className={className}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
            {isDisabled && <LoaderOverlay />}
        </div>
    );
});

export default ServicesTable;
