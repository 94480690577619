import { DispatchDetailsT } from '../models';

type TransportationOrdersT = NonNullable<NonNullable<DispatchDetailsT['tours'][number]>['transportationOrders']>;

export const findActualTransportOrders = (
    transportOrders: TransportationOrdersT | null | undefined,
): {
    carrierTransportOrder: TransportationOrdersT[number] | null;
    truckTransportOrder: TransportationOrdersT[number] | null;
    trailerTransportOrder: TransportationOrdersT[number] | null;
} => {
    const truckTransportOrder =
        transportOrders?.find((transportOrder) => {
            return transportOrder?.actual && transportOrder?.truck;
        }) || null;

    const trailerTransportOrder =
        transportOrders?.find((transportOrder) => {
            return transportOrder?.actual && transportOrder?.trailer;
        }) || null;

    return {
        carrierTransportOrder: truckTransportOrder,
        truckTransportOrder,
        trailerTransportOrder,
    };
};
