import { CountryCodeT } from 'common/store/countries-dict/models';

export enum FieldsEnum {
    name = 'name',
    legalFormId = 'legalFormId',
    keyAccountManagerId = 'keyAccountManagerId',
    dispatcherId = 'dispatcherId',

    legalAddressCountryCode = 'legalAddressCountryCode',
    legalAddressZipCode = 'legalAddressZipCode',
    legalAddressCity = 'legalAddressCity',
    legalAddressStreet1 = 'legalAddressStreet1',
    legalAddressStreet2 = 'legalAddressStreet2',

    postalAddressCountryCode = 'postalAddressCountryCode',
    postalAddressZipCode = 'postalAddressZipCode',
    postalAddressCity = 'postalAddressCity',
    postalAddressStreet1 = 'postalAddressStreet1',
    postalAddressStreet2 = 'postalAddressStreet2',

    billingAddressCountryCode = 'billingAddressCountryCode',
    billingAddressZipCode = 'billingAddressZipCode',
    billingAddressCity = 'billingAddressCity',
    billingAddressStreet1 = 'billingAddressStreet1',
    billingAddressStreet2 = 'billingAddressStreet2',
    billingEmail = 'billingEmail',
}

export type FormValuesT = {
    [FieldsEnum.name]: string;
    [FieldsEnum.legalFormId]: number | null;
    [FieldsEnum.keyAccountManagerId]: string | null;
    [FieldsEnum.dispatcherId]: string | null;

    [FieldsEnum.legalAddressCountryCode]: CountryCodeT | null;
    [FieldsEnum.legalAddressZipCode]: string;
    [FieldsEnum.legalAddressCity]: string;
    [FieldsEnum.legalAddressStreet1]: string;
    [FieldsEnum.legalAddressStreet2]: string;

    [FieldsEnum.postalAddressCountryCode]: CountryCodeT | null;
    [FieldsEnum.postalAddressZipCode]: string;
    [FieldsEnum.postalAddressCity]: string;
    [FieldsEnum.postalAddressStreet1]: string;
    [FieldsEnum.postalAddressStreet2]: string;

    [FieldsEnum.billingAddressCountryCode]: CountryCodeT | null;
    [FieldsEnum.billingAddressZipCode]: string;
    [FieldsEnum.billingAddressCity]: string;
    [FieldsEnum.billingAddressStreet1]: string;
    [FieldsEnum.billingAddressStreet2]: string;
    [FieldsEnum.billingEmail]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
