import * as React from 'react';
import isNumber from 'lodash/isNumber';

type PropsT = {
    map: MapT | null | undefined;
    maps: MapsT | null | undefined;
    zoom: number | null | undefined;
};

const MapZoom: React.FC<PropsT> = React.memo((props) => {
    const { map, maps, zoom } = props;

    React.useEffect(() => {
        if (!maps || !map || !isNumber(zoom)) {
            return;
        }

        map.setZoom(zoom);
    }, [map, maps, zoom]);

    return null;
});

export default MapZoom;
