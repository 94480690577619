import {
    FETCH_DISPATCHES_PAGE_REQUEST,
    FETCH_DISPATCHES_PAGE_REQUEST_BEGIN,
    FETCH_DISPATCHES_PAGE_REQUEST_ERROR,
    FETCH_DISPATCHES_PAGE_REQUEST_SUCCESS,
    FetchDispatchesPageActionT,
    FetchDispatchesPageBeginActionT,
    FetchDispatchesPageErrorActionT,
    FetchDispatchesPageSuccessActionT,
    RESET_DISPATCHES,
    ResetDispatchesActionT,
} from './types';
import { DispatchT, FetchDispatchesPageQueryT } from './models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchDispatchesPage = (
    pageNumber: PageNumberT,
    query: FetchDispatchesPageQueryT,
    options?: FetchOptionsT,
): FetchDispatchesPageActionT => ({
    type: FETCH_DISPATCHES_PAGE_REQUEST,
    query,
    pageNumber,
    options,
});

export const fetchDispatchesPageBegin = (
    query: FetchDispatchesPageQueryT,
    pageNumber: PageNumberT,
): FetchDispatchesPageBeginActionT => ({
    type: FETCH_DISPATCHES_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
});

export const fetchDispatchesPageSuccess = (
    query: FetchDispatchesPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<DispatchT> | null,
): FetchDispatchesPageSuccessActionT => ({
    type: FETCH_DISPATCHES_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
});

export const fetchDispatchesPageError = (
    query: FetchDispatchesPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchDispatchesPageErrorActionT => ({
    type: FETCH_DISPATCHES_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
});

export const resetDispatches = (mutationListOptions: MutationListOptionsT): ResetDispatchesActionT => ({
    type: RESET_DISPATCHES,
    mutationListOptions,
});
