import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './OrderRouteMap.scss';

import GoogleMapReact from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import GoogleMapContext from 'common/contexts/google-map-context';
import { ApiShipmentStatusT, ShipmentStatusEnum } from 'common/utils/api/models';
import last from 'lodash/last';
import { AssetTypeEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';
import LastAssetTrackPointPin from 'common/components/maps/pins/LastAssetTrackPointPin/LastAssetTrackPointPin';
import { TrackPointT } from 'common/store/asset-track/models';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import MapBound, { BoundPointT } from 'common/components/maps/MapBound/MapBound';
import DestinationLocationPin from 'common/components/maps/pins/DestinationLocationPin/DestinationLocationPin';
import OriginLocationPin from 'common/components/maps/pins/OriginLocationPin/OriginLocationPin';
import MapLoader from '../maps/MapLoader/MapLoader';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cx = classNames.bind(styles);

type MapPointT = {
    latitude?: number; // double
    longitude?: number; // double
};

type PropsT = {
    withRouteTrack?: boolean;
    origin?: MapPointT | null;
    destination?: MapPointT | null;
    loadStatus?: ApiShipmentStatusT;
    route: Array<GooglePolylineT> | null;
    routeRequest?: RequestStatusT;
    truckTrack?: Array<TrackPointT>;
    truckTrackRequest?: RequestStatusT;
    trailerTrack?: Array<TrackPointT>;
    trailerTrackRequest?: RequestStatusT;
    isLoading?: boolean;
};

const visitedOriginLoadStatusSet = new Set([
    ShipmentStatusEnum.inTransit,
    ShipmentStatusEnum.unloading,
    ShipmentStatusEnum.delivered,
]);

const visitedDestinationLoadStatusSet = new Set([ShipmentStatusEnum.delivered]);

const OrderRouteMap: React.FC<PropsT> = React.memo((props) => {
    const {
        origin,
        destination,
        loadStatus,
        route,
        routeRequest,
        withRouteTrack,
        truckTrack,
        truckTrackRequest,
        trailerTrack,
        trailerTrackRequest,
        children,
    } = props;

    const googleMapContext = React.useContext(GoogleMapContext);

    const geometryLibrary = googleMapContext?.googleMaps?.libraries?.geometry;

    const allMapPoints = React.useMemo((): Array<BoundPointT> => {
        const path = (route || []).reduce((acc: Array<LatLngT>, polyline) => {
            const decodedPath = geometryLibrary?.encoding?.decodePath(polyline) || [];

            return [...acc, ...decodedPath];
        }, []);

        return [
            [destination?.latitude, destination?.longitude],
            [origin?.latitude, origin?.longitude],
            ...(path || []).map((routePoint): BoundPointT => [routePoint.lat(), routePoint.lng()]),
        ];
    }, [destination, origin, route, geometryLibrary]);

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const lastTruckTrackPoint = last(truckTrack);
    const lastTrailerTrackPoint = last(trailerTrack);

    const isRouteRequestLoading = routeRequest && !(routeRequest?.error || routeRequest?.ok);
    const isLoading =
        props.isLoading || isRouteRequestLoading || truckTrackRequest?.loading || trailerTrackRequest?.loading;

    return (
        <>
            {isLoading && <MapLoader />}
            <GoogleMapReact
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                options={MAP_OPTIONS}
                onGoogleApiLoaded={apiIsLoaded}
            >
                {origin && (
                    <OriginLocationPin
                        key="origin"
                        isVisited={visitedOriginLoadStatusSet.has(loadStatus as ShipmentStatusEnum)}
                        lat={origin.latitude}
                        lng={origin.longitude}
                    />
                )}
                {destination && (
                    <DestinationLocationPin
                        key="destination"
                        isVisited={visitedDestinationLoadStatusSet.has(loadStatus as ShipmentStatusEnum)}
                        lat={destination.latitude}
                        lng={destination.longitude}
                    />
                )}
                {lastTruckTrackPoint && (
                    <LastAssetTrackPointPin
                        key="truck-last-point"
                        iconType={AssetTypeEnum.truck}
                        lat={lastTruckTrackPoint?.lat}
                        lng={lastTruckTrackPoint?.lng}
                        timestamp={lastTruckTrackPoint?.timestamp}
                    />
                )}
                {lastTrailerTrackPoint && (
                    <LastAssetTrackPointPin
                        key="trailer-last-point"
                        iconType={AssetTypeEnum.trailer}
                        lat={lastTrailerTrackPoint?.lat}
                        lng={lastTrailerTrackPoint?.lng}
                        timestamp={lastTrailerTrackPoint?.timestamp}
                    />
                )}
            </GoogleMapReact>
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                polylines={route}
                theme={withRouteTrack ? MapRouteThemeEnum.trackFuture : MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={trailerTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={truckTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapBound
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                allMapPoints={allMapPoints}
            />
            {children}
        </>
    );
});

export default OrderRouteMap;
