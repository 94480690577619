import {
    FETCH_DISPATCH_DETAILS_REQUEST,
    FETCH_DISPATCH_DETAILS_REQUEST_BEGIN,
    FETCH_DISPATCH_DETAILS_REQUEST_ERROR,
    FETCH_DISPATCH_DETAILS_REQUEST_SUCCESS,
    FetchDispatchDetailsRequestActionT,
    FetchDispatchDetailsRequestBeginActionT,
    FetchDispatchDetailsRequestErrorActionT,
    FetchDispatchDetailsRequestSuccessActionT,
} from './types';
import { DispatchDetailsT } from './models';

export const fetchDispatchDetails = (id: DispatchIdT, options?: FetchOptionsT): FetchDispatchDetailsRequestActionT => ({
    type: FETCH_DISPATCH_DETAILS_REQUEST,
    id,
    options,
});

export const fetchDispatchDetailsBegin = (id: DispatchIdT): FetchDispatchDetailsRequestBeginActionT => ({
    type: FETCH_DISPATCH_DETAILS_REQUEST_BEGIN,
    id,
});

export const fetchDispatchDetailsSuccess = (
    id: DispatchIdT,
    dispatchDetails: DispatchDetailsT | null,
): FetchDispatchDetailsRequestSuccessActionT => ({
    type: FETCH_DISPATCH_DETAILS_REQUEST_SUCCESS,
    dispatchDetails,
    id,
});

export const fetchDispatchDetailsError = (id: DispatchIdT, error: Error): FetchDispatchDetailsRequestErrorActionT => ({
    type: FETCH_DISPATCH_DETAILS_REQUEST_ERROR,
    error,
    id,
});
