import { put, select, takeEvery } from 'redux-saga/effects';
import { fetchSpotRequestsBegin, fetchSpotRequestsStatsError, fetchSpotRequestsStatsSuccess } from './actions';
import { FETCH_SPOT_REQUESTS_STATS_REQUEST, FetchSpotRequestsStatsActionT } from './types';
import checkNeedRequest from 'common/utils/check-need-request';
import { selectSpotRequestsStatsRequest } from './selectors';
import { FETCH_SPOT_REQUESTS_PAGE_REQUEST, FetchSpotRequestsPageActionT } from 'broker-admin/store/spot-requests/types';
import spotBrokerTranziitApi from 'broker-admin/utils/api/spot-broker-tranziit/api';

function* fetchSpotRequestsStatsSaga(
    actions: FetchSpotRequestsStatsActionT | FetchSpotRequestsPageActionT,
): WrapGeneratorT<void> {
    const { options } = actions;

    const requestStatus: ReturnType<typeof selectSpotRequestsStatsRequest> = yield select(
        selectSpotRequestsStatsRequest,
    );

    const isNeedRequest = checkNeedRequest(requestStatus, options);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchSpotRequestsBegin());

    const [error, response]: ReturnApiT<typeof spotBrokerTranziitApi.fetchSpotRequestsStats> =
        yield spotBrokerTranziitApi.fetchSpotRequestsStats();
    if (error) {
        yield put(fetchSpotRequestsStatsError(error));
        return;
    }

    yield put(fetchSpotRequestsStatsSuccess(response));
}

function* spotRequestsStatsSaga(): WrapGeneratorT<void> {
    yield takeEvery([FETCH_SPOT_REQUESTS_STATS_REQUEST, FETCH_SPOT_REQUESTS_PAGE_REQUEST], fetchSpotRequestsStatsSaga);
}

export default spotRequestsStatsSaga;
