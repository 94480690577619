import { parseSimpleNumber } from 'common/utils/input-parsers';
import isNumber from 'lodash/isNumber';
import { simpleNumberFormatter } from 'common/utils/form-formatters';
import { SEC_IN_HOUR, SEC_IN_MIN } from 'common/utils/time';

export const convertMsToHours = (msValue: string): string => {
    const ms = parseSimpleNumber(msValue);
    if (!isNumber(ms) || Number.isNaN(ms)) {
        return '';
    }

    return simpleNumberFormatter(ms / SEC_IN_HOUR);
};

export const convertHoursToMs = (hoursValue: string): string => {
    const hours = parseSimpleNumber(hoursValue);
    if (!isNumber(hours) || Number.isNaN(hours)) {
        return '';
    }

    return simpleNumberFormatter(hours * SEC_IN_HOUR);
};

export const convertSecToMin = (secValue: string): string => {
    const sec = parseSimpleNumber(secValue);
    if (!isNumber(sec) || Number.isNaN(sec)) {
        return '';
    }

    return simpleNumberFormatter(sec / SEC_IN_MIN);
};

export const convertMinToSec = (minValue: string): string => {
    const min = parseSimpleNumber(minValue);
    if (!isNumber(min) || Number.isNaN(min)) {
        return '';
    }

    return simpleNumberFormatter(min * SEC_IN_MIN);
};
