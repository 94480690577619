import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './OrdersPage.scss';
import { Route, Switch } from 'react-router-dom';

import { OMSRoutesEnum, routesEnum } from 'broker-admin/constants';

import NewOrderPage from 'common/layouts/NewOrderPage/NewOrderPage';
import OrderListPage from './OrderListPage/OrderListPage';
import OrderDetailsPage from './OrderDetailsPage/OrderDetailsPage';
import history from 'common/utils/history';

const cx = classNames.bind(styles);

type PropsT = {};

const OrdersPage: React.FC<PropsT> = React.memo((props) => {
    return (
        <Switch>
            <Route path={OMSRoutesEnum.newOrder}>
                <NewOrderPage
                    isBroker
                    onClose={() => {
                        history.push({
                            pathname: routesEnum.home,
                        });
                    }}
                />
            </Route>
            <Route path={OMSRoutesEnum.orderDetails}>
                <OrderDetailsPage />
            </Route>
            <Route path="*">
                <OrderListPage />
            </Route>
        </Switch>
    );
});

export default OrdersPage;
