import * as React from 'react';

import Button, { ButtonThemeEnum } from 'common/components/Button/Button';

import classNames from 'classnames/bind';
import styles from './SystemSettings.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import { DEFAULT_ICON_SIZE, QueryKeysEnum, StyleGuideColorsEnum } from 'common/constants';
import {
    selectSystemSettings,
    selectSystemSettingsFetchRequest,
    selectSystemSettingsUpdateRequest,
} from 'broker-admin/store/price-settings/system-settings/selectors';
import { fetchSystemSettings, updateSystemSettings } from 'broker-admin/store/price-settings/system-settings/actions';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import LeftCardLayout from 'common/layouts/LeftCardLayout/LeftCardLayout';
import WalletIcon, { getWalletIconProps } from 'common/icons/WalletIcon';
import CargoUnitIcon, { getCargoUnitIconProps } from 'common/icons/CargoUnitIcon';
import DriverIcon, { getDriverIconProps } from 'common/icons/DriverIcon';
import TimeWindowIcon, { getTimeWindowIconProps } from 'common/icons/TimeWindowIcon';
import GeoAreaIcon, { getGeoAreaIconProps } from 'common/icons/GeoAreaIcon';
import useQuery from 'common/utils/hooks/useQuery';
import history from 'common/utils/history';
import { formatQuery } from 'common/utils/query';
import values from 'lodash/values';
import PricingSettingsForm from './PricingSettingsForm/PricingSettingsForm';
import NewOrderParametersSettingsForm from './NewOrderParametersSettingsForm/NewOrderParametersSettingsForm';
import DriverSettingsForm from './DriverSettingsForm/DriverSettingsForm';
import AlertsSettingsForm from './AlertsSettingsForm/AlertsSettingsForm';
import AssignmentSettingsForm from './AssignmentSettingsForm/AssignmentSettingsForm';
import { SystemSettingsChangesT } from 'broker-admin/store/price-settings/system-settings/models';
import RadarIcon from 'common/icons/RadarIcon';
import SpotSettingsForm from 'broker-admin/layouts/SettingsPage/SystemSettings/SpotSettingsForm/SpotSettingsForm';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';

const cx = classNames.bind(styles);

type PropsT = {};

enum TabTypeEnum {
    pricing = 'pricing',
    newOrderParameters = 'new-order-parameters',
    driver = 'driver',
    alerts = 'alerts',
    assignment = 'assignment',
    spot = 'spot',
}

type TabConfigT = {
    type: TabTypeEnum;
    icon: React.ReactElement;
};

type QueryT = {
    [QueryKeysEnum.systemSettingsTab]: string;
};

const DEFAULT_TAB_TYPE = TabTypeEnum.pricing;

const parseQueryTabType = (queryTabType: string | undefined): TabTypeEnum => {
    if (!queryTabType) {
        return DEFAULT_TAB_TYPE;
    }

    const availableTabs: string[] = values(TabTypeEnum);
    if (availableTabs.includes(queryTabType)) {
        return queryTabType as TabTypeEnum;
    }

    return DEFAULT_TAB_TYPE;
};

const TAB_MAP_T: Record<TabTypeEnum, string> = {
    [TabTypeEnum.pricing]: 'system-settings.tabs.pricing',
    [TabTypeEnum.newOrderParameters]: 'system-settings.tabs.new-order-parameters',
    [TabTypeEnum.driver]: 'system-settings.tabs.driver',
    [TabTypeEnum.alerts]: 'system-settings.tabs.alerts',
    [TabTypeEnum.assignment]: 'system-settings.tabs.assignment',
    [TabTypeEnum.spot]: 'system-settings.tabs.spot',
};

const SystemSettings: React.FC<PropsT> = React.memo((props) => {
    const systemSettings = useSelector(selectSystemSettings);

    const updateRequest = useSelector(selectSystemSettingsUpdateRequest);
    const fetchRequest = useSelector(selectSystemSettingsFetchRequest);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchSystemSettings());
    }, []);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchSystemSettings());
    }, []);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const query = useQuery<QueryT>();

    const handleTabClick = (tabType: TabTypeEnum) => {
        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.systemSettingsTab]: tabType,
            }),
        });
    };

    const handleUpdateSettings = (systemSettingsChanges: SystemSettingsChangesT): void => {
        dispatch(updateSystemSettings(systemSettingsChanges));
    };

    const selectedTabType = parseQueryTabType(query[QueryKeysEnum.systemSettingsTab]);

    const tabsConfig: TabConfigT[] = [
        {
            type: TabTypeEnum.pricing,
            icon: (
                <WalletIcon {...getWalletIconProps(selectedTabType === TabTypeEnum.pricing ? 'selected' : 'default')} />
            ),
        },
        {
            type: TabTypeEnum.newOrderParameters,
            icon: (
                <CargoUnitIcon
                    {...getCargoUnitIconProps(
                        selectedTabType === TabTypeEnum.newOrderParameters ? 'selected' : 'default',
                    )}
                    size={DEFAULT_ICON_SIZE}
                />
            ),
        },
        {
            type: TabTypeEnum.driver,
            icon: (
                <DriverIcon
                    {...getDriverIconProps(selectedTabType === TabTypeEnum.driver ? 'selected' : 'default')}
                    size={DEFAULT_ICON_SIZE}
                />
            ),
        },
        {
            type: TabTypeEnum.alerts,
            icon: (
                <TimeWindowIcon
                    {...getTimeWindowIconProps(selectedTabType === TabTypeEnum.alerts ? 'selected' : 'default')}
                />
            ),
        },
        {
            type: TabTypeEnum.assignment,
            icon: (
                <GeoAreaIcon
                    {...getGeoAreaIconProps(selectedTabType === TabTypeEnum.assignment ? 'selected' : 'default')}
                />
            ),
        },
        {
            type: TabTypeEnum.spot,
            icon: (
                <RadarIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={
                        selectedTabType === TabTypeEnum.spot
                            ? StyleGuideColorsEnum.brandDark
                            : StyleGuideColorsEnum.slate
                    }
                />
            ),
        },
    ];

    const isLoading = fetchRequest.loading || updateRequest.loading;

    const commonFormProps = {
        isLoading,
        systemSettings,
        onUpdate: handleUpdateSettings,
    };
    return (
        <ContentMargins className={cx('wrap')}>
            {isLoading && <LoaderOverlay />}
            <LeftCardLayout
                testSelector="system-settings"
                cardNode={
                    <div>
                        {tabsConfig.map((tabsConfig) => (
                            <Button
                                key={tabsConfig.type}
                                className={cx('tab')}
                                theme={ButtonThemeEnum.transparent}
                                isToggled={tabsConfig.type === selectedTabType}
                                leftIcon={tabsConfig.icon}
                                onClick={() => handleTabClick(tabsConfig.type)}
                            >
                                {t(TAB_MAP_T[tabsConfig.type])}
                            </Button>
                        ))}
                    </div>
                }
            >
                {selectedTabType === TabTypeEnum.pricing && (
                    <PricingSettingsForm {...commonFormProps} title={t(TAB_MAP_T[TabTypeEnum.pricing])} />
                )}
                {selectedTabType === TabTypeEnum.newOrderParameters && (
                    <NewOrderParametersSettingsForm
                        {...commonFormProps}
                        title={t(TAB_MAP_T[TabTypeEnum.newOrderParameters])}
                    />
                )}
                {selectedTabType === TabTypeEnum.driver && (
                    <DriverSettingsForm {...commonFormProps} title={t(TAB_MAP_T[TabTypeEnum.driver])} />
                )}
                {selectedTabType === TabTypeEnum.alerts && (
                    <AlertsSettingsForm {...commonFormProps} title={t(TAB_MAP_T[TabTypeEnum.alerts])} />
                )}
                {selectedTabType === TabTypeEnum.assignment && (
                    <AssignmentSettingsForm {...commonFormProps} title={t(TAB_MAP_T[TabTypeEnum.assignment])} />
                )}
                {selectedTabType === TabTypeEnum.spot && (
                    <SpotSettingsForm {...commonFormProps} title={t(TAB_MAP_T[TabTypeEnum.spot])} />
                )}
            </LeftCardLayout>
        </ContentMargins>
    );
});

export default SystemSettings;
