import { disableCompaniesDataSourceSet, FieldsEnum, FormValuesT } from './constants';
import { QueryFiltersT, QueryFiltersKeysEnum } from 'broker-admin/layouts/StatisticsPage/query-models';
import { DEFAULT_DATA_SOURCE } from 'broker-admin/layouts/StatisticsPage/constants';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const dataSource = values[FieldsEnum.dataSource] || undefined;

    let companiesQueryFilters: QueryFiltersT = {
        [QueryFiltersKeysEnum.shipperId]: values[FieldsEnum.shipperId] || undefined,
        [QueryFiltersKeysEnum.shipperName]: values[FieldsEnum.shipperName] || undefined,
        [QueryFiltersKeysEnum.carrierId]: values[FieldsEnum.carrierId] || undefined,
        [QueryFiltersKeysEnum.carrierName]: values[FieldsEnum.carrierName] || undefined,
    };

    if (disableCompaniesDataSourceSet.has(dataSource)) {
        companiesQueryFilters = {};
    }

    const queryFilters: QueryFiltersT = {
        [QueryFiltersKeysEnum.dataSource]: dataSource === DEFAULT_DATA_SOURCE ? undefined : dataSource,
        [QueryFiltersKeysEnum.fromCountryCode]: values[FieldsEnum.fromCountryCode] || undefined,
        [QueryFiltersKeysEnum.fromZipCode]: values[FieldsEnum.fromZipCode] || undefined,
        [QueryFiltersKeysEnum.toCountryCode]: values[FieldsEnum.toCountryCode] || undefined,
        [QueryFiltersKeysEnum.toZipCode]: values[FieldsEnum.toZipCode] || undefined,
        ...companiesQueryFilters,
    };

    return queryFilters;
};

export default getQueryFilters;
