import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';

export const getOnlyQueryFilterChanges = <T extends Record<string, any>>(values: T, initValues: T): T => {
    const queryKeys = keys(values);

    const changedValues: T = {
        ...values,
    };

    queryKeys.forEach((queryKey) => {
        const isSameValue = isEqual(values[queryKey], initValues[queryKey]);
        if (isSameValue) {
            delete changedValues[queryKey];
        }
    });

    return changedValues;
};
