export type SpotBidT = SpotApi.Definitions.ApiBidFullDetails;

export type ApiSpotRequestT = SpotApi.Definitions.ApiFilterResult;

export type ApiSpotRequestStatusT = NonNullable<SpotApi.Definitions.ApiFilterResult['status']>;
export enum SpotRequestStatusEnum {
    open = 'OPEN',
    expired = 'EXPIRED',
    accepted = 'ACCEPTED',
    assigned = 'ASSIGNED',
    canceled = 'CANCELED',
}

export type ApiSpotRequestStatStatusT = NonNullable<SpotApi.Definitions.ApiSpotStatuses200ResponseInner['status']>;
export enum SpotRequestStatStatusEnum {
    open = 'OPEN',
    accepted = 'ACCEPTED',
    expireSoon = 'EXPIRE_SOON',
    newBids = 'NEW_BIDS',
    assigned = 'ASSIGNED',
    expired = 'EXPIRED',
    canceled = 'CANCELED',
    unknown = 'UNKNOWN',
}

export type ApiSpotRequestsStatsT = Array<SpotApi.Definitions.ApiSpotStatuses200ResponseInner>;

export enum SpotRequestsSortEnum {
    expirationTime = 'deadline',
    placedTime = 'createdDate',
    originTime = 'originTimeSlotFrom',
    destinationTime = 'destinationTimeSlotFrom',
}

export type ApiSpotRequestDetailsT = SpotApi.Definitions.ApiSpotFullDetails;

export type ApiSpotSettingsKeysT = SpotApi.Definitions.SettingDto['name'];
export type ApiSpotSystemSettingsT = Partial<Record<ApiSpotSettingsKeysT, string>>;
