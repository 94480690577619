import {
    AdditionalServicesActionT,
    AdditionalServicesStateT,
    FETCH_ADDITIONAL_SERVICES_REQUEST_BEGIN,
    FETCH_ADDITIONAL_SERVICES_REQUEST_ERROR,
    FETCH_ADDITIONAL_SERVICES_REQUEST_SUCCESS,
    UPDATE_ADDITIONAL_SERVICE_REQUEST_BEGIN,
    UPDATE_ADDITIONAL_SERVICE_REQUEST_ERROR,
    UPDATE_ADDITIONAL_SERVICE_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: AdditionalServicesStateT = {
    list: [],
    updateRequest: requestStatus.initial,
    fetchRequest: requestStatus.initial,
};

export default (
    state = initialState,
    action: AdditionalServicesActionT | DestroySessionActionT,
): AdditionalServicesStateT => {
    switch (action.type) {
        case FETCH_ADDITIONAL_SERVICES_REQUEST_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }

        case FETCH_ADDITIONAL_SERVICES_REQUEST_SUCCESS: {
            return {
                ...state,
                list: action.list,
                fetchRequest: requestStatus.ok,
            };
        }

        case FETCH_ADDITIONAL_SERVICES_REQUEST_ERROR: {
            return {
                ...state,
                fetchRequest: requestStatus.setError(action.error),
            };
        }

        case UPDATE_ADDITIONAL_SERVICE_REQUEST_BEGIN: {
            return {
                ...state,
                updateRequest: requestStatus.loading,
            };
        }

        case UPDATE_ADDITIONAL_SERVICE_REQUEST_SUCCESS: {
            const { serviceId, cost } = action;

            const newList = state.list.map((additionalService) => {
                if (additionalService.id === serviceId) {
                    return {
                        ...additionalService,
                        cost,
                    };
                }

                return additionalService;
            });

            return {
                ...state,
                list: newList,
                updateRequest: requestStatus.ok,
            };
        }

        case UPDATE_ADDITIONAL_SERVICE_REQUEST_ERROR: {
            return {
                ...state,
                updateRequest: requestStatus.setError(action.error),
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
