import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './PartnerName.scss';

const cx = classNames.bind(styles);

const PartnerName: React.FC<{ partnerName: string }> = ({ partnerName }) => (
    <span className={cx('partner-name')}>{partnerName}</span>
);

export default PartnerName;
