import { StateT } from 'broker-admin/store/models';
import { StatisticsStateT } from './types';

export const selectStatisticsFetchQuery = (state: StateT): StatisticsStateT['fetchQuery'] => {
    return state.statistics.fetchQuery;
};

export const selectDoneStatisticsStatus = (state: StateT): StatisticsStateT['doneRequestStatus'] => {
    return state.statistics.doneRequestStatus;
};

export const selectDoneStatistics = (state: StateT): StatisticsStateT['doneData'] => {
    return state.statistics.doneData;
};

export const selectRejectStatisticsStatus = (state: StateT): StatisticsStateT['rejectRequestStatus'] => {
    return state.statistics.rejectRequestStatus;
};

export const selectRejectStatistics = (state: StateT): StatisticsStateT['rejectData'] => {
    return state.statistics.rejectData;
};
