export enum BidsSortEnum {
    date = 'date',
    price = 'price',
}

export enum BidsQuickFilterEnum {
    accepted = 'accepted',
    match = 'match',
    inactive = 'inactive',
}
