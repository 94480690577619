import * as React from 'react';
import { useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './StartSelectBidFooterContent.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { RequestStatusT } from 'common/utils/request-status';

import useModalDialog from 'common/utils/hooks/useModalDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import AssignCarrierConfirmation, {
    AssignCarrierConfirmationDataT,
} from '../../dialogs/AssignCarrierConfirmation/AssignCarrierConfirmation';
import { useDispatch } from 'react-redux';
import { assignBidToSpotRequest } from 'broker-admin/store/spot-request-details/actions';
import keyBy from 'lodash/keyBy';
import { SpotRequestDetailsT } from 'broker-admin/store/spot-request-details/models';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    spotRequestId: SpotRequestIdT | null;
    selectedBidId: BidIdT | null;
    details: SpotRequestDetailsT | null;
    updateRequestStatus: RequestStatusT;
};

const StartSelectBidFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { details, spotRequestId, selectedBidId, updateRequestStatus } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const bidById = useMemo(() => {
        return keyBy(details?.bids, 'id');
    }, [details]);

    const assignCarrierConfirmation = useModalDialog<AssignCarrierConfirmationDataT>();
    const handleCancelSpotRequest = () => {
        const selectedBid = bidById[selectedBidId as string];
        if (!selectedBid || !selectedBidId || !spotRequestId) {
            return;
        }

        assignCarrierConfirmation.setData({
            selectedBidId,
            spotRequestId,
            carrierName: selectedBid?.carrierName || '-',
        });
    };

    useCloseModalDialogAfterRequest(updateRequestStatus, [assignCarrierConfirmation]);

    const handleStartSelectBid = (data: AssignCarrierConfirmationDataT) => {
        dispatch(assignBidToSpotRequest(data.spotRequestId, data.selectedBidId));
    };

    if (!details) {
        return null;
    }

    return (
        <>
            {selectedBidId ? (
                <Button
                    theme={ButtonThemeEnum.primary}
                    className={cx('action', 'action--start')}
                    onClick={handleCancelSpotRequest}
                    isLoading={updateRequestStatus.loading}
                >
                    {t('spot-request-details.start-select-bid.actions.select')}
                </Button>
            ) : null}
            <AssignCarrierConfirmation
                data={assignCarrierConfirmation.data}
                onConfirmation={handleStartSelectBid}
                requestStatus={updateRequestStatus}
                onCancel={assignCarrierConfirmation.onCancel}
                onClose={assignCarrierConfirmation.onClose}
            />
        </>
    );
});

export default StartSelectBidFooterContent;
