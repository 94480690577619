import { FieldsEnum, FormValuesT } from './constants';
import { SystemSettingsChangesT } from 'broker-admin/store/price-settings/system-settings/models';
import { MS_IN_HOUR } from 'common/utils/time';
import { simpleNumberFormatter } from 'common/utils/form-formatters';
import { convertMinToSec } from 'common/utils/form-value-converters';

const prepareApiSystemSettings = (formValues: FormValuesT): SystemSettingsChangesT => {
    const brokerWorkingHours = formValues[FieldsEnum.brokerWorkingHours];

    return {
        URGENT_ORDER_PROCESSING_DELAY: formValues[FieldsEnum.urgentOrderProcessingDelay],
        REGULAR_ORDER_PROCESSING_DELAY: formValues[FieldsEnum.regularOrderProcessingDelay],
        MIN_WINDOW_SIZE: convertMinToSec(formValues[FieldsEnum.minWindowSize]),
        MAX_WINDOW_SIZE: convertMinToSec(formValues[FieldsEnum.maxWindowSize]),
        BROKER_DAY_START_HOURS: simpleNumberFormatter(brokerWorkingHours[0] / MS_IN_HOUR),
        BROKER_DAY_END_HOURS: simpleNumberFormatter(brokerWorkingHours[1] / MS_IN_HOUR),
        TIME_SLOTS_DELEGATION_LIMIT: formValues[FieldsEnum.timeSlotsDelegationLimit],
        ORDER_CREATION_SECONDS: formValues[FieldsEnum.orderCreationSeconds],
        QUANTUM_MINUTES: formValues[FieldsEnum.quantumMinutes],
        MAP_PRECISION_DELTA: formValues[FieldsEnum.mapPrecisionDelta],
    };
};

export default prepareApiSystemSettings;
