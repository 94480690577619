import React from 'react';

export enum TimeZoneEnum {
    accountTimezone = 'account-timezone',
}

export type TimeZoneT = TimeZoneEnum | number;

export type TimeZoneContextValueT = {
    timeZone: TimeZoneT | null;
    utcOffsetMin: number | null;
};

export type TimeZoneContextT = {
    value: TimeZoneContextValueT;
    setValue: (value: TimeZoneContextValueT) => void;
};

const TimeZoneContext = React.createContext<TimeZoneContextT | null>(null);

export default TimeZoneContext;
