import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
};

const WalletIcon: React.FC<PropsT> = (props) => {
    const { fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M10.75 13.75c-.825 0-1.5-.675-1.5-1.5v-4.5c0-.825.675-1.5 1.5-1.5h4.5v-1.5H4.75v10.5h10.5v-1.5h-4.5z"
                />
                <path
                    fill={strokeColor}
                    d="M15.25 3.25c.825 0 1.5.675 1.5 1.5v1.71c.442.255.75.735.75 1.29v4.5a1.5 1.5 0 01-.75 1.29v1.71c0 .825-.675 1.5-1.5 1.5H4.75a1.5 1.5 0 01-1.5-1.5V4.75a1.5 1.5 0 011.5-1.5zm0 1.5H4.75v10.5h10.5v-1.5h-4.5c-.825 0-1.5-.675-1.5-1.5v-4.5c0-.825.675-1.5 1.5-1.5h4.5v-1.5zm.75 3h-5.25v4.5H16v-4.5zm-3 1.125a1.125 1.125 0 110 2.25 1.125 1.125 0 010-2.25z"
                />
            </g>
        </svg>
    );
};

WalletIcon.displayName = 'WalletIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.light,
    strokeColor: StyleGuideColorsEnum.gray,
};

export { storyProps };
export default WalletIcon;

/**
 *  @deprecated create/use ...Props class
 * */
export const getWalletIconProps = (preset: 'default' | 'selected'): PropsT => {
    switch (preset) {
        case 'selected': {
            return {
                fillColor: StyleGuideColorsEnum.brandAccent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        default: {
            return {
                fillColor: StyleGuideColorsEnum.light,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }
    }
};
