import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_SPOT_REQUESTS_PAGE_REQUEST, FetchSpotRequestsPageActionT } from './types';
import {
    fetchSpotRequestsPageBegin,
    fetchSpotRequestsPageError,
    fetchSpotRequestsPageSuccess,
    resetSpotRequests,
} from './actions';
import { selectSpotRequestsPages, selectSpotRequestsQuery } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import spotBrokerTranziitApi from 'broker-admin/utils/api/spot-broker-tranziit/api';
import { spotRequestsPaginationChannel } from 'broker-admin/store/spot-requests/channels';

function* fetchSpotRequestsSaga(action: FetchSpotRequestsPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options } = action;

    const prevQuery: ReturnType<typeof selectSpotRequestsQuery> = yield select(selectSpotRequestsQuery);
    const pages: ReturnType<typeof selectSpotRequestsPages> = yield select(selectSpotRequestsPages);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetSpotRequests({ savingPageNumber: pageNumber }));
    }

    yield put(fetchSpotRequestsPageBegin(query, pageNumber));

    const [error, response]: ReturnApiT<typeof spotBrokerTranziitApi.fetchSpotRequests> =
        yield spotBrokerTranziitApi.fetchSpotRequests({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchSpotRequestsPageError(query, pageNumber, error));
        return;
    }

    yield put(fetchSpotRequestsPageSuccess(query, pageNumber, response));

    checkShouldEmitChangePage(pageNumber, response, spotRequestsPaginationChannel);
}

function* spotRequestsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_SPOT_REQUESTS_PAGE_REQUEST, fetchSpotRequestsSaga);
}

export default spotRequestsSaga;
