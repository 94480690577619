import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerTransportOrderDetails.scss';
import TransportOrderDetailsPageLayout from 'common/layouts/TransportOrderDetailsPageLayout/TransportOrderDetailsPageLayout';
import { urlFactory } from 'broker-admin/utils/urls';
import history from 'common/utils/history';
import { logWarning } from 'common/utils/logger';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { BrokerSidebarsTypeEnum } from 'broker-admin/layouts/SideBars/constants';

const cx = classNames.bind(styles);

type PropsT = {};

const PartnerTransportOrderDetails: React.FC<PropsT> = React.memo((props) => {
    const { partnerId, partnerType } = usePartnerContext();

    const goToBack = React.useCallback(() => {
        const url = urlFactory.partnerTransportOrders(partnerType, partnerId);
        history.push(url);
    }, [partnerType, partnerId]);

    const openLeftSidebar = useOpenLeftSidebar();

    const goToTrailerDetails = React.useCallback(
        (trailerId: TrailerIdT | null) => {
            if (!trailerId) {
                logWarning('empty trailerId');
                return;
            }

            openLeftSidebar(
                {
                    type: CommonSidebarsTypeEnum.trailerDetails,
                    partnerId,
                    trailerId,
                },
                {
                    isForceShowBackAction: true,
                },
            );
        },
        [partnerType, partnerId],
    );

    const goToTruckDetails = React.useCallback(
        (truckId: TruckIdT | null) => {
            if (!truckId) {
                logWarning('empty trailerId');
                return;
            }

            openLeftSidebar(
                {
                    type: CommonSidebarsTypeEnum.truckDetails,
                    partnerId,
                    truckId,
                },
                {
                    isForceShowBackAction: true,
                },
            );
        },
        [partnerType, partnerId],
    );

    const goToSpotRequestDetails = React.useCallback((spotRequestId: SpotRequestIdT | null) => {
        if (!spotRequestId) {
            logWarning('empty spotId');
            return;
        }

        openLeftSidebar(
            {
                type: BrokerSidebarsTypeEnum.spotRequestDetails,
                id: spotRequestId,
            },
            {
                isForceShowBackAction: true,
            },
        );
    }, []);

    return (
        <TransportOrderDetailsPageLayout
            isBroker
            isReadOnly
            goToBack={goToBack}
            goToTrailerDetails={goToTrailerDetails}
            goToTruckDetails={goToTruckDetails}
            goToSpotRequestDetails={goToSpotRequestDetails}
        />
    );
});

export default PartnerTransportOrderDetails;
