import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor?: StyleGuideColorsEnum;
};

const GeoAreaIcon: React.FC<PropsT> = (props) => {
    const { fillColor, ...restProps } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...restProps}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M13.69 14.732l1.065 1.073a7.457 7.457 0 01-3.99 1.658v-1.515a5.96 5.96 0 002.925-1.216zM9.235 2.538v1.514c-2.94.39-5.213 2.91-5.213 5.948 0 3.037 2.273 5.557 5.213 5.947v1.516C5.462 17.065 2.522 13.87 2.522 10s2.94-7.065 6.714-7.463zm8.242 8.212a7.463 7.463 0 01-1.657 3.99l-1.072-1.065a5.958 5.958 0 001.215-2.925zM10 6.25c1.575 0 3 1.207 3 3.075 0 1.245-.998 2.722-3 4.425-2.003-1.703-3-3.18-3-4.425C7 7.457 8.425 6.25 10 6.25zm0 2.145a.803.803 0 100 1.603.803.803 0 000-1.603zm5.82-3.135a7.456 7.456 0 011.657 3.99h-1.514a5.895 5.895 0 00-1.215-2.917zm-5.055-2.722c1.5.15 2.88.75 3.99 1.657L13.69 5.268a5.954 5.954 0 00-2.925-1.215z"
                />
            </g>
        </svg>
    );
};

GeoAreaIcon.displayName = 'GeoAreaIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
};

export { storyProps };
export default GeoAreaIcon;

/**
 *  @deprecated create/use ...Props class
 * */
export const getGeoAreaIconProps = (preset: 'default' | 'selected'): Pick<PropsT, 'fillColor'> => {
    switch (preset) {
        case 'selected': {
            return {
                fillColor: StyleGuideColorsEnum.brandDark,
            };
        }
        default: {
            return {
                fillColor: StyleGuideColorsEnum.slate,
            };
        }
    }
};
