import * as React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './DestinationLocationPin.scss';

import PickedPinIcon from 'common/icons/PickedPinIcon';
import DestinationPinIcon from 'common/icons/DestinationPinIcon';

type PropsT = {
    className?: string;
    isVisited?: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    lat?: number;
    // eslint-disable-next-line react/no-unused-prop-types
    lng?: number;
};

const cx = classNames.bind(styles);

const DestinationLocationPin: React.FC<PropsT> = React.memo((props) => {
    const { isVisited, className } = props;

    const DestinationIconComponent = isVisited ? PickedPinIcon : DestinationPinIcon;

    return <DestinationIconComponent key="destination" className={cs(cx('location'), className)} />;
});

export default DestinationLocationPin;
