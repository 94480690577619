const getAssignmentIconPreset = (isSelected: boolean, canInTime: boolean) => {
    if (!canInTime) {
        if (isSelected) {
            return 'assignment-not-in-time-selected';
        }

        return 'assignment-not-in-time-normal';
    }

    if (isSelected) {
        return 'assignment-selected';
    }

    return 'assignment-normal';
};

export { getAssignmentIconPreset };
