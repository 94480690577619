import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './LocationsCell.scss';
import { CountryCodeT, CountryT } from 'common/store/countries-dict/models';
import CountyFlagLabel from 'common/components/CountyFlagLabel/CountyFlagLabel';
import { useTranslation } from 'react-i18next';
import { ZIP_CODES_SEPARATOR } from 'common/constants';
import { isNonNil } from 'common/utils';

type PropsT = {
    countryCodes: Array<CountryCodeT>;
    zipCodes: Array<string>;
    countryByCode: Partial<Record<CountryT['code'], CountryT>>;
};

const cx = classNames.bind(styles);

const LocationsCell: React.FC<PropsT> = React.memo((props) => {
    const { countryCodes, zipCodes, countryByCode } = props;

    const { t } = useTranslation();

    const countries = countryCodes.map((countryCode) => countryByCode[countryCode]).filter(isNonNil);

    return (
        <div>
            <CountyFlagLabel countries={countries} />
            <div className={cx('zip-codes')}>
                {zipCodes.join(ZIP_CODES_SEPARATOR) || t('price-settings.rules.all-zips')}
            </div>
        </div>
    );
});

export default LocationsCell;
