import {
    FIND_CARRIERS_REQUEST,
    FIND_CARRIERS_REQUEST_BEGIN,
    FIND_CARRIERS_REQUEST_ERROR,
    FIND_CARRIERS_REQUEST_SUCCESS,
    FindCarriersActionT,
    FindCarriersBeginActionT,
    FindCarriersErrorActionT,
    FindCarriersSuccessActionT,
    RESET,
    ResetActionT,
} from './types';
import { FindCarriersQueryT, PartnerT } from './models';

export const findCarriers = (query: FindCarriersQueryT): FindCarriersActionT => ({
    type: FIND_CARRIERS_REQUEST,
    query,
});

export const findCarriersBegin = (query: FindCarriersQueryT): FindCarriersBeginActionT => ({
    type: FIND_CARRIERS_REQUEST_BEGIN,
    query,
});

export const findCarriersSuccess = (partners: PartnerT[]): FindCarriersSuccessActionT => ({
    type: FIND_CARRIERS_REQUEST_SUCCESS,
    partners,
});

export const findCarriersError = (error: Error): FindCarriersErrorActionT => ({
    type: FIND_CARRIERS_REQUEST_ERROR,
    error,
});

export const reset = (): ResetActionT => ({
    type: RESET,
});
