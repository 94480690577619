import { SpotRequestsStateT } from './types';
import { StateT } from '../models';

export const selectSpotRequestsPages = (state: StateT): SpotRequestsStateT['pages'] => state.spotRequests.pages;

export const selectSpotRequestsQuery = (state: StateT): SpotRequestsStateT['query'] => state.spotRequests.query;

export const selectSpotRequestsTotal = (state: StateT): SpotRequestsStateT['total'] => state.spotRequests.total;

export const selectSpotRequestsByIds = (state: StateT): SpotRequestsStateT['byId'] => state.spotRequests.byId;
