import { StateT } from 'broker-admin/store/models';
import { RequestStatusT } from 'common/utils/request-status';
import { SystemSettingsT } from './models';

export const selectSystemSettings = (state: StateT): SystemSettingsT | null => state.priceSettings.systemSettings.data;

export const selectSystemSettingsFetchRequest = (state: StateT): RequestStatusT =>
    state.priceSettings.systemSettings.fetchRequest;

export const selectSystemSettingsUpdateRequest = (state: StateT): RequestStatusT =>
    state.priceSettings.systemSettings.updateRequest;
