import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './RulesTable.scss';
import { useTranslation } from 'react-i18next';
import StatusCell from './cell-renderers/StatusCell/StatusCell';
import Table, { TableColumnT, TableRowMetaT } from 'common/components/Table/Table';
import { RuleT } from 'broker-admin/store/price-settings/rules/models';
import CheckboxHeader from 'common/components/Table/header-renderers/CheckboxHeader/CheckboxHeader';
import CheckboxCell from 'common/components/Table/cell-renderers/CheckboxCell/CheckboxCell';
import { useSelector } from 'react-redux';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';
import UpdateCell from './cell-renderers/UpdateCell/UpdateCell';
import ValidPeriodCell from './cell-renderers/ValidPeriodCell/ValidPeriodCell';
import ControlsCell, { ActionsPropsT } from './cell-renderers/ControlsCell/ControlsCell';
import NameCell from './cell-renderers/NameCell/NameCell';
import LocationsCell from './cell-renderers/LocationsCell/LocationsCell';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import RuleCell from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/RulesTable/cell-renderers/RuleCell/RuleCell';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import { CountryCodeT } from 'common/store/countries-dict/models';

const cx = classNames.bind(styles);

type PropsT = {
    rules: Array<RuleT>;
    isDisabled: boolean;
    isLoading: boolean;
    className?: string;
    selectedRulesSet: Set<RuleT>;
    onSelectRules: (selectedRulesSet: Set<RuleT>) => void;
    onClickRow: (event: React.MouseEvent, rule: RuleT) => void;
    onOpenUserDetails: (userId: UserIdT | null) => void;
} & ActionsPropsT;

const RulesTable: React.FC<PropsT> = React.memo((props) => {
    const {
        rules,
        className,
        selectedRulesSet,
        onSelectRules,
        onActiveRule,
        onDeactiveRule,
        onDeleteRule,
        onClickRow,
        onOpenUserDetails,
        isDisabled,
        isLoading,
    } = props;

    const { t } = useTranslation();

    const countryByCode = useSelector(selectCountriesByCode);

    const columns: Array<TableColumnT<RuleT, void>> = [
        {
            renderHeader: () => (
                <CheckboxHeader<RuleT> selectedRowsSet={selectedRulesSet} rows={rules} onChange={onSelectRules} />
            ),
            headerClassName: cx('table__header', 'table__header--checkbox'),
            render: (rule: RuleT) => (
                <CheckboxCell<RuleT> row={rule} selectedRowsSet={selectedRulesSet} onChange={onSelectRules} />
            ),
            className: cx('table__column', 'table__column--checkbox'),
            testSelector: 'checkbox',
        },
        {
            renderHeader: () => <span>{t('price-settings.rules.table.columns.name')}</span>,
            headerClassName: cx('table__header', 'table__header--name'),
            render: (rule: RuleT) => <NameCell rule={rule} />,
            className: cx('table__column', 'table__column--name'),
            testSelector: 'name',
        },
        {
            renderHeader: () => <span>{t('price-settings.rules.table.columns.from')}</span>,
            headerClassName: cx('table__header', 'table__header--locations'),
            render: (rule: RuleT) => (
                <LocationsCell
                    countryCodes={(rule.countriesFrom as Array<CountryCodeT>) || []}
                    countryByCode={countryByCode}
                    zipCodes={rule.zipsFrom}
                />
            ),
            className: cx('table__column', 'table__column--locations'),
            testSelector: 'from-locations',
        },
        {
            renderHeader: () => <span>{t('price-settings.rules.table.columns.to')}</span>,
            headerClassName: cx('table__header', 'table__header--locations'),
            render: (rule: RuleT) => (
                <LocationsCell
                    countryCodes={(rule.countriesTo as Array<CountryCodeT>) || []}
                    countryByCode={countryByCode}
                    zipCodes={rule.zipsTo}
                />
            ),
            className: cx('table__column', 'table__column--locations'),
            testSelector: 'to-locations',
        },
        {
            renderHeader: () => <span>{t('price-settings.rules.table.columns.rule')}</span>,
            headerClassName: cx('table__header', 'table__header--rule'),
            render: (rule: RuleT) => <RuleCell rule={rule} />,
            className: cx('table__column', 'table__column--rule'),
            testSelector: 'rule',
        },
        {
            renderHeader: () => <span>{t('price-settings.rules.table.columns.update')}</span>,
            headerClassName: cx('table__header', 'table__header--update'),
            render: (rule: RuleT) => <UpdateCell rule={rule} onOpenUserDetails={onOpenUserDetails} />,
            className: cx('table__column', 'table__column--update'),
            testSelector: 'update',
        },
        {
            renderHeader: () => <span>{t('price-settings.rules.table.columns.valid')}</span>,
            headerClassName: cx('table__header', 'table__header--valid-period'),
            render: (rule: RuleT) => <ValidPeriodCell rule={rule} />,
            className: cx('table__column', 'table__column--valid-period'),
            testSelector: 'valid-period',
        },
        {
            renderHeader: () => <span>{t('price-settings.rules.table.columns.status')}</span>,
            headerClassName: cx('table__header', 'table__header--status'),
            render: (rule: RuleT) => <StatusCell rule={rule} />,
            className: cx('table__column', 'table__column--status'),
            testSelector: 'status',
        },
        {
            renderHeader: () => null,
            headerClassName: cx('table__header', 'table__header--controls'),
            render: (rule: RuleT) => (
                <ControlsCell
                    id={String(rule.id)}
                    isActive={rule.active}
                    onActiveRule={onActiveRule}
                    onDeactiveRule={onDeactiveRule}
                    onDeleteRule={onDeleteRule}
                />
            ),
            className: cx('table__column', 'table__column--controls'),
            testSelector: 'controls',
        },
    ];

    const getRowMods = (meta: TableRowMetaT) => ({
        isSelected: meta.isSelected,
    });

    return (
        <div className={cx('table')}>
            <MemoizedTable<RuleT> tableName="rules" isLoading={isLoading} rows={rules}>
                {(rows, isUsedPrevRows) => (
                    <Table<RuleT, void>
                        className={className}
                        selectedRowsSet={selectedRulesSet}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        onSelectRow={onClickRow}
                        isLoading={isLoading}
                        testSelector="rules"
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
            {isDisabled && <LoaderOverlay />}
        </div>
    );
});

export default RulesTable;
