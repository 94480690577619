import * as React from 'react';

type PropsT = {
    map: MapT | null | undefined;
    maps: MapsT | null | undefined;
    center: GeoPointT | null | undefined;
};

const MapCenter: React.FC<PropsT> = React.memo((props) => {
    const { map, maps, center } = props;

    React.useEffect(() => {
        if (!maps || !map || !center) {
            return;
        }

        const centerLatLng = new maps.LatLng(center.lat, center.lng);
        map.setCenter(centerLatLng);
    }, [map, maps, center?.lat, center?.lng]);

    return null;
});

export default MapCenter;
