import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { ALL_CARRIERS_ID, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { getDateFromISO } from 'common/utils/time';

import { changeUserSelection, setShowSelectedAssetSchedule } from 'broker-admin/store/dispatch-assigment/actions';

import ExcludeCountries from './ExcludeCountries/ExcludeCountries';
import CarrierDropdown, { CarrierOptionT } from './CarrierDropdown/CarrierDropdown';
import RadiusDropdown, { RadiusOptionT } from './RadiusDropdown/RadiusDropdown';
import AssignmentOptionsDropdown, {
    AssignmentOptionsValueT,
} from './AssignmentOptionsDropdown/AssignmentOptionsDropdown';

import styles from './SearchForm.scss';
import { selectAssignmentRadiuses } from 'broker-admin/store/settings/selectors';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { selectShowSelectedAssetSchedules, selectUserSelection } from 'broker-admin/store/dispatch-assigment/selectors';
import CalendarIcon from 'common/icons/CalendarIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import { useTranslation } from 'react-i18next';
import { ApiCountriesT } from 'common/utils/api/models';
import TimeZoneDropdown from './TimeZoneDropdown/TimeZoneDropdown';
import { findActualTour } from 'broker-admin/store/dispatch-details/utils/find-actual-tour';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    dispatchId: DispatchIdT | null;
    dispatchDetails: DispatchDetailsT | null;
};

type ExcludedCountriesT = ApiCountriesT[];

const SearchForm: React.FC<PropsT> = React.memo((props) => {
    const { className, dispatchId, dispatchDetails } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const userSelection = useSelector(selectUserSelection);

    const [excludedCountries, setExcludedCountries] = React.useState<ExcludedCountriesT>(
        userSelection.excludedCountries,
    );

    React.useEffect(() => {
        if (userSelection.excludedCountries !== excludedCountries) {
            setExcludedCountries(userSelection.excludedCountries);
        }
    }, [userSelection.excludedCountries]);

    const handleChangeExcludedCountries = (excludedCountries: ExcludedCountriesT): void => {
        setExcludedCountries(excludedCountries);

        dispatch(changeUserSelection(dispatchId, { excludedCountries }));
    };

    const [selectedAssignmentOptions, setAssignmentOptions] = React.useState<AssignmentOptionsValueT>({
        isShowActualPlace: userSelection.isShowActualPlace,
        isShowAssetSchedule: userSelection.isShowAssetSchedule,
        isShowUnavailableAssets: userSelection.isShowUnavailableAssets,
        isIgnoreEmissionStandard: userSelection.isIgnoreEmissionStandard,
        isIgnoreCertificates: userSelection.isIgnoreCertificates,
    });

    React.useEffect(() => {
        setAssignmentOptions({
            isShowActualPlace: userSelection.isShowActualPlace,
            isShowAssetSchedule: userSelection.isShowAssetSchedule,
            isShowUnavailableAssets: userSelection.isShowUnavailableAssets,
            isIgnoreEmissionStandard: userSelection.isIgnoreEmissionStandard,
            isIgnoreCertificates: userSelection.isIgnoreCertificates,
        });
    }, [userSelection.isShowActualPlace, userSelection.isShowAssetSchedule, userSelection.isShowUnavailableAssets]);

    const handleChangeAssignmentOptions = (value: AssignmentOptionsValueT): void => {
        setAssignmentOptions(value);

        dispatch(
            changeUserSelection(dispatchId, {
                isShowActualPlace: value.isShowActualPlace,
                isShowAssetSchedule: value.isShowAssetSchedule,
                isShowUnavailableAssets: value.isShowUnavailableAssets,
                isIgnoreEmissionStandard: value.isIgnoreEmissionStandard,
                isIgnoreCertificates: value.isIgnoreCertificates,
            }),
        );
    };

    const assignmentRadiuses = useSelector(selectAssignmentRadiuses);
    const defaultAssignmentRadius = assignmentRadiuses?.[0] || null;

    const [selectedRadius, setRadius] = React.useState<RadiusOptionT['value']>(userSelection.radiusKm || null);

    React.useEffect(() => {
        if (userSelection.radiusKm !== selectedRadius) {
            setRadius(userSelection.radiusKm);
        }
    }, [userSelection.radiusKm]);

    const handleChangeRadius = (radius: RadiusOptionT['value']): void => {
        setRadius(radius);

        dispatch(changeUserSelection(dispatchId, { radiusKm: radius }));
    };

    React.useEffect(() => {
        if (selectedRadius === null && defaultAssignmentRadius) {
            dispatch(changeUserSelection(dispatchId, { radiusKm: defaultAssignmentRadius }));
        }
    }, [defaultAssignmentRadius]);

    const [selectedCarrierId, setCarrierId] = React.useState<CarrierOptionT['id']>(
        userSelection.carrierId || ALL_CARRIERS_ID,
    );

    React.useEffect(() => {
        const carrierId = userSelection.carrierId || ALL_CARRIERS_ID;
        if (selectedCarrierId !== carrierId) {
            setCarrierId(carrierId);
        }
    }, [userSelection.carrierId]);

    const handleSetCarrierId = (carrierId: CarrierOptionT['id']): void => {
        setCarrierId(carrierId);

        dispatch(changeUserSelection(dispatchId, { carrierId }));
    };

    const actualTour = findActualTour(dispatchDetails?.tours);
    const firstWaypoint = actualTour?.waypoints?.[0] || null;
    const firstWaypointDateTimeFrom =
        firstWaypoint?.correctedDateTimeFrom || firstWaypoint?.originalDateTimeFrom || null;

    const date = getDateFromISO(firstWaypointDateTimeFrom);

    const isShowSelectedAssetSchedules = useSelector(selectShowSelectedAssetSchedules);

    const openSelectedAssetSchedules = () => {
        dispatch(setShowSelectedAssetSchedule(true));
    };

    return (
        <div className={cs(cx('form'), className)}>
            <div
                className={cx('row', {
                    'row--first': true,
                })}
            >
                <div className={cx('column')}>
                    <RadiusDropdown
                        className={cx('field', 'field--radius')}
                        radius={selectedRadius}
                        setRadius={handleChangeRadius}
                    />
                    <AssignmentOptionsDropdown
                        className={cx('field', 'field--filters')}
                        value={selectedAssignmentOptions}
                        onChange={handleChangeAssignmentOptions}
                    />
                </div>
                <div className={cx('column')}>
                    <CarrierDropdown
                        className={cx('field', 'field--carrier')}
                        date={date}
                        carrierId={selectedCarrierId}
                        setCarrierId={handleSetCarrierId}
                    />
                </div>
            </div>
            <div
                className={cx('row', {
                    'row--secondary': true,
                })}
            >
                <ExcludeCountries
                    excludedCountries={excludedCountries}
                    setExcludedCountries={handleChangeExcludedCountries}
                />
                <div className={cx('subrow')}>
                    <TimeZoneDropdown className={cx('timezone-switcher')} dispatchDetails={dispatchDetails} />
                    <TransparentTrigger
                        onClick={openSelectedAssetSchedules}
                        isPressed={isShowSelectedAssetSchedules}
                        label={t('assignment.search-form.open-selected-asset-schedule')}
                        className={cx('timeline-trigger')}
                        leftIcon={
                            <CalendarIcon
                                size={DEFAULT_ICON_SIZE}
                                fillColor={StyleGuideColorsEnum.brandAccent}
                                strokeColor={StyleGuideColorsEnum.brandDark}
                            />
                        }
                        reflectionTheme={ReflectionThemeEnum.light}
                        rightIcon={<ArrowsIcon />}
                    />
                </div>
            </div>
        </div>
    );
});

export default SearchForm;
