import { formatPageQuerySort } from 'common/utils/page-sort';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { DepreactedPagingTokensT, FetchInvoicesPageQueryT } from 'broker-admin/store/invoices/models';
import { InvoicesQuickFilterEnum } from 'broker-admin/layouts/InvoicesListPage/constants';
import { InvoiceStatusEnum } from 'common/utils/api/models';
import { PageSortT } from 'common/utils/query';
import { BrokerInvoicesSortEnum } from 'broker-admin/utils/api/broker-tranziit/models';

const getQueryFromQuickFilters = (quickFilter: InvoicesQuickFilterEnum | null | undefined): FetchInvoicesPageQueryT => {
    switch (quickFilter) {
        case InvoicesQuickFilterEnum.open: {
            return {
                status: InvoiceStatusEnum.open,
            };
        }
        case InvoicesQuickFilterEnum.void: {
            return {
                status: InvoiceStatusEnum.void,
            };
        }
        case InvoicesQuickFilterEnum.paid: {
            return {
                status: InvoiceStatusEnum.paid,
            };
        }
        case InvoicesQuickFilterEnum.uncollectible: {
            return {
                status: InvoiceStatusEnum.uncollectible,
            };
        }
        default: {
            return {};
        }
    }
};

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<BrokerInvoicesSortEnum> | null,
    deprecatedPagingToken: DepreactedPagingTokensT,
): FetchInvoicesPageQueryT => {
    return {
        invoiceNumber: queryFilters[QueryFiltersKeysEnum.searchText] || undefined,

        // TODO use tabs
        ...getQueryFromQuickFilters(queryFilters[QueryFiltersKeysEnum.quickFilter]),

        startingAfter: deprecatedPagingToken?.startingAfter || undefined,
        endingBefore: deprecatedPagingToken?.endingBefore || undefined,

        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
