import * as React from 'react';
import { useTranslation } from 'react-i18next';

import LeftMenuLayoutBase from 'common/layouts/LeftMenuLayout/LeftMenuLayout';
import { BadgeTypeEnum, MenuLinkT } from 'common/layouts/LeftMenuLayout/Navigation/Navigation';
import { OMSRoutesEnum, routesEnum, SettingsRoutesEnum } from 'broker-admin/constants';
import SettingsIcon from 'common/icons/SettingsIcon';
import { selectPermissions } from 'common/store/auth/selectors';
import { useSelector } from 'react-redux';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { BusinessPartnerMenuIcon, DatabaseMenuIcon, PlusMenuIcon } from 'common/layouts/LeftMenuLayout/menu-icons';
import ChartIcon from 'common/icons/ChartIcon';
import RadarIcon from 'common/icons/RadarIcon';
import InvoiceIcon, { getInvoiceIconProps } from 'common/icons/InvoiceIcon';
import FeaturesContext from 'common/contexts/features';
import { ClientFeatureEnum, ServerFeatureEnum } from 'common/utils/hooks/useFeaturesContextValue';

const badges = {
    [routesEnum.orders]: [
        {
            type: BadgeTypeEnum.info,
            text: 10,
        },
    ],
};

type PropsT = {};

const LeftMenuLayout: React.FC<PropsT> = React.memo((props) => {
    const { children } = props;
    const { t } = useTranslation();
    const permissions = useSelector(selectPermissions);

    const featuresContext = React.useContext(FeaturesContext);

    const menuLinks: Array<MenuLinkT | null> = [
        permissions.canCreateOrder
            ? {
                  renderIcon: (isActive) => <PlusMenuIcon isActive={isActive} />,
                  text: t('menu.new-order'),
                  to: OMSRoutesEnum.newOrder,
                  matchPathname: OMSRoutesEnum.newOrder,
                  testSelector: 'new-order',
              }
            : null,
        featuresContext?.checkActiveClientFeature?.(ClientFeatureEnum.brokerOrders)
            ? {
                  renderIcon: (isActive) => <DatabaseMenuIcon isActive={isActive} />,
                  text: t('menu.OMS'),
                  to: routesEnum.orders,
                  matchPathname: routesEnum.orders,
                  exact: true,
                  testSelector: 'orders',
              }
            : null,
        {
            renderIcon: (isActive) => <DatabaseMenuIcon isActive={isActive} />,
            text: t('menu.dispatches'),
            to: routesEnum.dispatches,
            matchPathname: routesEnum.dispatches,
            testSelector: 'dispatches',
        },
        permissions?.canViewInvoices
            ? {
                  renderIcon: (isActive) => <InvoiceIcon {...getInvoiceIconProps(isActive ? 'selected' : 'default')} />,
                  text: t('menu.invoices'),
                  to: routesEnum.invoices,
                  matchPathname: routesEnum.invoices,
                  testSelector: 'invoices',
              }
            : null,
        {
            renderIcon: (isActive) => <BusinessPartnerMenuIcon isActive={isActive} />,
            text: t('menu.partners'),
            to: routesEnum.partners,
            matchPathname: routesEnum.partners,
            testSelector: 'partners',
        },
        featuresContext?.checkActiveServerFeature?.(ServerFeatureEnum.spot) && permissions?.canViewSpot
            ? {
                  renderIcon: (isActive) => (
                      <RadarIcon
                          size={DEFAULT_ICON_SIZE}
                          strokeColor={isActive ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.slate}
                      />
                  ),
                  text: t('menu.spot'),
                  to: routesEnum.spotRequests,
                  matchPathname: routesEnum.spotRequests,
                  testSelector: 'spot',
              }
            : null,
        permissions.canViewPriceSettings
            ? {
                  renderIcon: (isActive) => (
                      <SettingsIcon
                          fillOpacity={isActive ? 0.3 : 1}
                          fillColor={isActive ? StyleGuideColorsEnum.brandAccent : StyleGuideColorsEnum.light}
                          strokeColor={isActive ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.slate}
                      />
                  ),
                  text: t('menu.settings'),
                  to: SettingsRoutesEnum.rateMatrix,
                  matchPathname: routesEnum.settings,
                  testSelector: 'settings',
              }
            : null,
        permissions.canViewFullStatistic
            ? {
                  renderIcon: (isActive) => (
                      <ChartIcon
                          fillOpacity={isActive ? 0.3 : 1}
                          fillColor={isActive ? StyleGuideColorsEnum.brandAccent : StyleGuideColorsEnum.light}
                          strokeColor={isActive ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.slate}
                      />
                  ),
                  text: t('menu.statistics'),
                  to: routesEnum.statistics,
                  matchPathname: routesEnum.statistics,
                  testSelector: 'statistics',
              }
            : null,
    ];

    return (
        <LeftMenuLayoutBase
            menuLinks={menuLinks}
            accountLink={routesEnum.account}
            logoLink={routesEnum.home}
            badges={badges}
            isAllowHelpButton
        >
            {children}
        </LeftMenuLayoutBase>
    );
});

export default LeftMenuLayout;
