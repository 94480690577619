import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './SearchForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectCountriesAllCodes, selectCountriesByCode } from 'common/store/countries-dict/selectors';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import { useTranslation } from 'react-i18next';
import DropdownSearchMultipleInput from 'design-system/components/dropdowns/DropdownSearchMultipleInput/DropdownSearchMultipleInput';
import CountyFlagLabel from 'common/components/CountyFlagLabel/CountyFlagLabel';
import CountryFlagPlaceholderOption from 'common/components/dropdowns/options/CountryFlagPlaceholderOption/CountryFlagPlaceholderOption';
import { CountryCodeT } from 'common/store/countries-dict/models';
import isEqual from 'lodash/isEqual';
import { getCountryHash } from 'common/store/countries-dict/utils';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    initialFromCountryCodes: CountryCodeT[];
    initialToCountryCodes: CountryCodeT[];
    onChange: (fromCountryCodes: CountryCodeT[], toCountryCodes: CountryCodeT[]) => void;
};

const SearchForm: React.FC<PropsT> = (props) => {
    const { onChange, initialFromCountryCodes, initialToCountryCodes, className } = props;

    const { t } = useTranslation();

    const [fromCountryCodes, setFromCountryCodes] = React.useState<CountryCodeT[]>(initialFromCountryCodes);
    const [toCountryCodes, setToCountryCodes] = React.useState<CountryCodeT[]>(initialToCountryCodes);

    React.useEffect(() => {
        if (!isEqual(initialFromCountryCodes, fromCountryCodes)) {
            setFromCountryCodes(initialFromCountryCodes);
        }
        if (!isEqual(initialToCountryCodes, toCountryCodes)) {
            setToCountryCodes(initialToCountryCodes);
        }
    }, [initialFromCountryCodes, initialToCountryCodes]);

    React.useEffect(() => {
        onChange(fromCountryCodes, toCountryCodes);
    }, [fromCountryCodes, toCountryCodes]);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const countriesAllCodes = useSelector(selectCountriesAllCodes);
    const countryByCode = useSelector(selectCountriesByCode);

    const renderOption = (countryCode: CountryCodeT) => {
        return <CountyFlagLabel country={countryByCode[countryCode]} />;
    };

    const getOptionValue = (countryCode: CountryCodeT) => countryCode;

    const getOptionQueryHash = (countryCode: CountryCodeT) => {
        const country = countryByCode[countryCode] || null;

        return getCountryHash(country);
    };

    const renderTrigger = (countryCodes: Array<CountryCodeT>) => {
        if (!countryCodes?.length) {
            return <CountryFlagPlaceholderOption label={t('common:not-selected')} />;
        }

        const countries = countryCodes.map((code) => countryByCode[code] || null);

        return <CountyFlagLabel countries={countries} />;
    };

    return (
        <div className={cs(cx('wrap'), className)}>
            <DropdownSearchMultipleInput<CountryCodeT, CountryCodeT>
                className={cx('field', {
                    'field--from': true,
                })}
                selectedValues={fromCountryCodes}
                inputPlaceholder={t('price-settings.rate-matrix.fields.from.input-placeholder')}
                onSelect={setFromCountryCodes}
                options={countriesAllCodes}
                renderOption={renderOption}
                getOptionValue={getOptionValue}
                getOptionQueryHash={getOptionQueryHash}
                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                renderTrigger={renderTrigger}
            />
            <DropdownSearchMultipleInput<CountryCodeT, CountryCodeT>
                className={cx('field', {
                    'field--to': true,
                })}
                selectedValues={toCountryCodes}
                inputPlaceholder={t('price-settings.rate-matrix.fields.to.input-placeholder')}
                onSelect={setToCountryCodes}
                options={countriesAllCodes}
                renderOption={renderOption}
                getOptionValue={getOptionValue}
                getOptionQueryHash={getOptionQueryHash}
                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                renderTrigger={renderTrigger}
            />
        </div>
    );
};

export default SearchForm;
