import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './UpdatedPartnerShipperLaneContractCard.scss';
import { Trans } from 'react-i18next';
import ShipperContractLaneId from 'broker-admin/components/notification-cards/entities/ShipperContractLaneId/ShipperContractLaneId';

const cx = classNames.bind(styles);

type PropsT = {
    id: string;
};

const UpdatedPartnerShipperLaneContractCard: React.FC<PropsT> = React.memo((props) => {
    const { id } = props;

    return (
        <div className={cx('inner')}>
            <Trans
                i18nKey="notifications.partner-shipper-contract-lane-updated"
                components={{
                    id: <ShipperContractLaneId id={id} />,
                }}
            />
        </div>
    );
});

export default UpdatedPartnerShipperLaneContractCard;
