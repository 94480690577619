import React from 'react';

import moment from 'moment';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './TwinDatePicker.scss';
import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';

/* eslint-disable import/no-unresolved */
import Calendar, { CalendarDateRangeValuePropsT } from 'design-system/components/Calendar/Calendar';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import DateInputTrigger from 'design-system/components/date-pickers/internals/DateInputTrigger/DateInputTrigger';
import DatePickerOverlay, { DatePickerOverlayPositionEnum } from '../internals/DatePickerOverlay/DatePickerOverlay';
import useTestSelector from 'common/utils/hooks/useTestSelector';

const cx = classNames.bind(styles);

export type IconMetaT = {
    isLoading: boolean;
    isDisabled: boolean;
    isFocused: boolean;
    hasError: boolean;
    hasWarning: boolean;
    hasSuccess: boolean;
    isHighlighted: boolean;
    hasValue: boolean;
};

export type TwinDateBasePropsT = {
    name?: string;
    placeholder?: string;
    isDisabled?: boolean;
    minDate?: string;
    maxDate?: string;
    overlayPosition?: DatePickerOverlayPositionEnum;
    hasError?: boolean;
    hasWarning?: boolean;
    hasYearMonthForm?: boolean;
    autoComplete?: string;
    testSelectorPrefix?: string;
    hasChanges?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
    inputClassName?: string;
    className?: string;
    renderLeftIcon?: (iconMeta: IconMetaT) => React.ReactNode;
    hasClearControl?: boolean;
    isTransparent?: boolean;
};

export type TwinDatePickerPropsT = TwinDateBasePropsT & {
    value: CalendarDateRangeValuePropsT['value'];
    initialValue?: CalendarDateRangeValuePropsT['initialValue'];
    focusInitialDate?: CalendarDateRangeValuePropsT['focusInitialDate'];
    onChange: CalendarDateRangeValuePropsT['onChange'];
};

const ISO_DATE_FORMAT = 'YYYY-MM-DD';

const ENTER_KEY = 'Enter';

const formatMinMaxDate = (date: Date | null | undefined): string | undefined => {
    if (!date) {
        return undefined;
    }

    return moment(date).format(ISO_DATE_FORMAT);
};

const getMonthDate = (monthOffset: number): Date => {
    const date = new Date();

    date.setMonth(date.getMonth() + monthOffset);

    return date;
};

/* eslint-disable react/destructuring-assignment */
const TwinDatePicker: React.FC<TwinDatePickerPropsT> = (props) => {
    const {
        name,
        value,
        placeholder,
        onFocus,
        onBlur,
        isDisabled,
        minDate,
        maxDate,
        overlayPosition,
        hasError,
        hasWarning,
        hasYearMonthForm,
        hasChanges,
        autoComplete,
        testSelectorPrefix,
        inputClassName,
        className,
        renderLeftIcon,
        hasClearControl,
        isTransparent,
    } = props;

    const [isOpen, toggleOpen] = React.useState(false);

    const handleFromDateRangeChange: CalendarDateRangeValuePropsT['onChange'] = (selectedRange, selectedDate) => {
        const range = selectedRange;
        // TODO check UX experience
        // selectedDate ? {
        //     to: props.value?.to,
        //     from: selectedDate,
        // } : selectedRange;

        props.onChange(range, selectedDate);
    };

    const handleToDateRangeChange: CalendarDateRangeValuePropsT['onChange'] = (selectedRange, selectedDate) => {
        const range = selectedRange;
        // TODO check UX experience
        // selectedDate ? {
        //     from: props.value?.from,
        //     to: selectedDate,
        // } : selectedRange;

        props.onChange(range, selectedDate);
    };

    const handleOpen = () => {
        if (isDisabled) {
            return;
        }

        toggleOpen(true);
    };

    const handleOuterEvent = () => {
        toggleOpen(false);
    };

    const handleChangeInputValue: CalendarDateRangeValuePropsT['onChange'] = (value, selectedDate) => {
        props.onChange(value, selectedDate);
    };

    const handleInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ENTER_KEY) {
            toggleOpen(false);
        }
    };

    const testSelector = useTestSelector(testSelectorPrefix, 'twin-date-picker');

    const focusDateFrom = props.value?.from || getMonthDate(0);
    const focusDateTo = props.value?.to || getMonthDate(1);

    return (
        <div className={cs(cx('dropdown'), className)} date-test-selector={testSelector}>
            <OutsideClickHandler isDisabled={!isOpen} onOutsideClick={handleOuterEvent}>
                <div className={cx('input')}>
                    <div className={cx('trigger')} onClick={handleOpen}>
                        <DateInputTrigger
                            isRange
                            value={value}
                            className={inputClassName}
                            name={name || 'datepicker'}
                            minDate={minDate}
                            maxDate={maxDate}
                            placeholder={placeholder}
                            onChange={handleChangeInputValue}
                            hasError={hasError}
                            onKeyUp={handleInputKeyUp}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            hasWarning={hasWarning}
                            isDisabled={isDisabled}
                            isForcedFocused={isOpen}
                            hasChanges={hasChanges}
                            autoComplete={autoComplete}
                            renderLeftIcon={renderLeftIcon}
                            renderRightIcon={() => <ArrowsIcon />}
                            hasClearControl={hasClearControl}
                            isTransparent={isTransparent}
                        />
                    </div>
                    {isOpen && (
                        <DatePickerOverlay position={overlayPosition} className={cx('overlay')}>
                            <Calendar
                                className={cx('calendar')}
                                minDate={minDate}
                                maxDate={formatMinMaxDate(props.value?.to)}
                                hasYearMonthForm={hasYearMonthForm}
                                testSelectorPrefix="twin-date-picker_left"
                                isRange
                                value={props.value}
                                initialValue={props.initialValue}
                                focusInitialDate={focusDateFrom}
                                onChange={handleFromDateRangeChange}
                            />
                            <Calendar
                                className={cx('calendar')}
                                minDate={formatMinMaxDate(props.value?.from)}
                                maxDate={maxDate}
                                hasYearMonthForm={hasYearMonthForm}
                                testSelectorPrefix="twin-date-picker_right"
                                isRange
                                value={props.value}
                                initialValue={props.initialValue}
                                focusInitialDate={focusDateTo}
                                onChange={handleToDateRangeChange}
                            />
                        </DatePickerOverlay>
                    )}
                </div>
            </OutsideClickHandler>
        </div>
    );
};

export default TwinDatePicker;
export { DatePickerOverlayPositionEnum };
