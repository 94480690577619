import * as React from 'react';
import AuthLayout from 'common/layouts/AuthLayout/AuthLayout';
import SignUpForm from 'common/components/SignUpForm/SignUpForm';
import { useTranslation } from 'react-i18next';
import RedirectSignedUser from 'common/components/RedirectSignedUser/RedirectSignedUser';
import PageTitle from 'common/components/PageTitle/PageTitle';

type PropsT = {};

const SignUpPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    return (
        <>
            <RedirectSignedUser />
            <AuthLayout testSelector="sign-up-page">
                <PageTitle title={t('common:page-titles.sign-up')} />
                <SignUpForm
                    titleSignUpUser={t('sign-up.user-form.title')}
                    titleSignUpCompany={t('sign-up.company-form.title')}
                />
            </AuthLayout>
        </>
    );
});

export default SignUpPage;
