import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ExcludeCountriesMapWidget from 'common/components/ExcludeCountriesMapWidget/ExcludeCountriesMapWidget';
import { CountryCodeT } from 'common/utils/api/models';

type PropsT<T extends CountryCodeT> = {
    className?: string;
    excludedCountries: Array<T>;
    setExcludedCountries: (excludedCountries: Array<T>) => void;
};

const ExcludeCountries = <T extends CountryCodeT>(props: PropsT<T>) => {
    const { className, excludedCountries, setExcludedCountries } = props;

    const { t } = useTranslation();

    return (
        <ExcludeCountriesMapWidget
            className={className}
            excludedCountries={excludedCountries}
            setExcludedCountries={setExcludedCountries}
            triggerLabel={t('assignment.search-form.excluded-countries.trigger')}
            inputPlaceholder={t('assignment.search-form.excluded-countries.search-input-placeholder')}
        />
    );
};

export default React.memo(ExcludeCountries) as typeof ExcludeCountries;
