import { RequestStatusT } from 'common/utils/request-status';
import { FindCarriersQueryT, PartnerT } from './models';

export const FIND_CARRIERS_REQUEST = 'common/carrier-suggest/FIND_CARRIERS_REQUEST';
export const FIND_CARRIERS_REQUEST_BEGIN = 'common/carrier-suggest/FIND_CARRIERS_REQUEST_BEGIN';
export const FIND_CARRIERS_REQUEST_ERROR = 'common/carrier-suggest/FIND_CARRIERS_REQUEST_ERROR';
export const FIND_CARRIERS_REQUEST_SUCCESS = 'common/carrier-suggest/FIND_CARRIERS_REQUEST_SUCCESS';

export const RESET = 'common/carrier-suggest/RESET';

export type CarrierSuggestStateT = {
    query: FindCarriersQueryT | null;
    requestStatus: RequestStatusT;
    ids: Array<PartnerT['id']>;
    byId: Record<PartnerT['id'], PartnerT>;
};

export type FindCarriersActionT = {
    type: typeof FIND_CARRIERS_REQUEST;
    query: FindCarriersQueryT;
};

export type FindCarriersBeginActionT = {
    type: typeof FIND_CARRIERS_REQUEST_BEGIN;
    query: FindCarriersQueryT;
};

export type FindCarriersSuccessActionT = {
    type: typeof FIND_CARRIERS_REQUEST_SUCCESS;
    partners: PartnerT[];
};

export type FindCarriersErrorActionT = {
    type: typeof FIND_CARRIERS_REQUEST_ERROR;
    error: Error;
};

export type ResetActionT = {
    type: typeof RESET;
};

export type CarrierSuggestActionT =
    | FindCarriersActionT
    | FindCarriersBeginActionT
    | FindCarriersSuccessActionT
    | FindCarriersErrorActionT
    | ResetActionT;
