import { FieldsEnum, FormValuesT } from './constants';
import { PartnerDetailsT } from 'broker-admin/store/partner/details/models';

const getInitialValues = (partnerDetails: PartnerDetailsT | null): FormValuesT => {
    return {
        [FieldsEnum.name]: partnerDetails?.name || '',
        [FieldsEnum.legalFormId]: typeof partnerDetails?.legalFormId === 'number' ? partnerDetails.legalFormId : null,
        [FieldsEnum.keyAccountManagerId]: partnerDetails?.keyAccountManagerId || null,
        [FieldsEnum.dispatcherId]: partnerDetails?.defaultDispatcherId || null,

        [FieldsEnum.legalAddressCountryCode]: partnerDetails?.legalAddress?.country || null,
        [FieldsEnum.legalAddressZipCode]: partnerDetails?.legalAddress?.zipCode || '',
        [FieldsEnum.legalAddressCity]: partnerDetails?.legalAddress?.city || '',
        [FieldsEnum.legalAddressStreet1]: partnerDetails?.legalAddress?.street1 || '',
        [FieldsEnum.legalAddressStreet2]: partnerDetails?.legalAddress?.street2 || '',

        [FieldsEnum.postalAddressCountryCode]: partnerDetails?.postalAddress?.country || null,
        [FieldsEnum.postalAddressZipCode]: partnerDetails?.postalAddress?.zipCode || '',
        [FieldsEnum.postalAddressCity]: partnerDetails?.postalAddress?.city || '',
        [FieldsEnum.postalAddressStreet1]: partnerDetails?.postalAddress?.street1 || '',
        [FieldsEnum.postalAddressStreet2]: partnerDetails?.postalAddress?.street2 || '',

        [FieldsEnum.billingAddressCountryCode]: partnerDetails?.billingAddress?.country || null,
        [FieldsEnum.billingAddressZipCode]: partnerDetails?.billingAddress?.zipCode || '',
        [FieldsEnum.billingAddressCity]: partnerDetails?.billingAddress?.city || '',
        [FieldsEnum.billingAddressStreet1]: partnerDetails?.billingAddress?.street1 || '',
        [FieldsEnum.billingAddressStreet2]: partnerDetails?.billingAddress?.street2 || '',
        [FieldsEnum.billingEmail]: partnerDetails?.billingEmail || '',
    };
};

export default getInitialValues;
