import { FormValuesT, FieldsEnum } from './constants';
import { QueryFiltersT } from 'broker-admin/layouts/SpotRequestsPage/SpotRequestListPage/query-models';

const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.createdByUserId]: null,
        [FieldsEnum.createdByUserName]: null,
        [FieldsEnum.createdByShipperId]: null,
        [FieldsEnum.createdByShipperName]: null,
        [FieldsEnum.dictTrailerTypeId]: null,
        [FieldsEnum.createDates]: null,
        [FieldsEnum.pickupAddress]: null,
        [FieldsEnum.pickupDates]: null,
        [FieldsEnum.dropoffAddress]: null,
        [FieldsEnum.dropoffDates]: null,
    };

    return values;
};

export default getInitialValues;
