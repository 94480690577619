import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ExpiredPartnerDocumentCard.scss';
import { Trans } from 'react-i18next';
import { DocumentTypeT, PartnerTypeT } from 'common/utils/api/models';
import AsyncDictDocumentNameLabel from 'common/components/AsyncDictDocumentNameLabel/AsyncDictDocumentNameLabel';
import PartnerName from 'broker-admin/components/notification-cards/entities/PartnerName/PartnerName';

const cx = classNames.bind(styles);

type PropsT = {
    partnerName: string;
    partnerType: PartnerTypeT | null;
    documentType: DocumentTypeT;
};

const ExpiredPartnerDocumentCard: React.FC<PropsT> = React.memo((props) => {
    const { partnerName, partnerType, documentType } = props;

    return (
        <div className={cx('inner')}>
            <Trans
                i18nKey="notifications.partner-document-expired"
                components={{
                    partnerName: <PartnerName partnerName={partnerName} />,
                    documentName: (
                        <AsyncDictDocumentNameLabel
                            className={cx('document-name')}
                            partnerType={partnerType}
                            documentType={documentType}
                        />
                    ),
                }}
            />
        </div>
    );
});

export default ExpiredPartnerDocumentCard;
