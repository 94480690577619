import { StateT } from 'broker-admin/store/models';
import { DispatchAssigmentStateT } from './types';
import keyBy from 'lodash/keyBy';
import { createSelector } from 'reselect';
import { CarrierT } from 'broker-admin/store/dispatch-assigment/models';

export const selectAvailableVehiclesQuery = (state: StateT): DispatchAssigmentStateT['availableVehicles']['query'] =>
    state.dispatchAssigment.availableVehicles.query;

export const selectUserSelection = (state: StateT): DispatchAssigmentStateT['userSelection'] =>
    state.dispatchAssigment.userSelection;

export const selectAvailableVehiclesRequest = (
    state: StateT,
): DispatchAssigmentStateT['availableVehicles']['requestStatus'] =>
    state.dispatchAssigment.availableVehicles.requestStatus;

export const selectAvailableVehicles = (state: StateT): DispatchAssigmentStateT['availableVehicles']['list'] =>
    state.dispatchAssigment.availableVehicles.list;

export const selectVehicleLinks = (state: StateT): DispatchAssigmentStateT['links'] => state.dispatchAssigment.links;

export const selectAvailableCarriersRequest = (
    state: StateT,
): DispatchAssigmentStateT['availableCarriers']['requestStatus'] =>
    state.dispatchAssigment.availableCarriers.requestStatus;

export const selectAvailableCarriers = (state: StateT): DispatchAssigmentStateT['availableCarriers']['carriers'] =>
    state.dispatchAssigment.availableCarriers.carriers;

export const selectAvailableCarriersById = createSelector(
    selectAvailableCarriers,
    (availableCarriers): Record<CarrierT['id'], CarrierT> => {
        return keyBy(availableCarriers, 'id');
    },
);

export const selectTruckSuggestQuery = (state: StateT): DispatchAssigmentStateT['truckSuggest']['query'] =>
    state.dispatchAssigment.truckSuggest.query;

export const selectTruckSuggestRequestStatus = (
    state: StateT,
): DispatchAssigmentStateT['truckSuggest']['requestStatus'] => state.dispatchAssigment.truckSuggest.requestStatus;

export const selectTruckSuggestIds = (state: StateT): DispatchAssigmentStateT['truckSuggest']['ids'] =>
    state.dispatchAssigment.truckSuggest.ids;

export const selectTruckAssignmentInfoById = (state: StateT): DispatchAssigmentStateT['truckById'] =>
    state.dispatchAssigment.truckById;

export const selectTrailerSuggestQuery = (state: StateT): DispatchAssigmentStateT['trailerSuggest']['query'] =>
    state.dispatchAssigment.trailerSuggest.query;

export const selectTrailerSuggestRequestStatus = (
    state: StateT,
): DispatchAssigmentStateT['trailerSuggest']['requestStatus'] => state.dispatchAssigment.trailerSuggest.requestStatus;

export const selectTrailerSuggestIds = (state: StateT): DispatchAssigmentStateT['trailerSuggest']['ids'] =>
    state.dispatchAssigment.trailerSuggest.ids;

export const selectTrailerAssignmentInfoById = (state: StateT): DispatchAssigmentStateT['trailerById'] =>
    state.dispatchAssigment.trailerById;

export const selectSelfCostPredictionQuery = (state: StateT): DispatchAssigmentStateT['selfCostPrediction']['query'] =>
    state.dispatchAssigment.selfCostPrediction.query;

export const selectSelfCostPrediction = (state: StateT): DispatchAssigmentStateT['selfCostPrediction']['result'] =>
    state.dispatchAssigment.selfCostPrediction.result;

export const selectSelfCostPredictionRequestStatus = (
    state: StateT,
): DispatchAssigmentStateT['selfCostPrediction']['requestStatus'] =>
    state.dispatchAssigment.selfCostPrediction.requestStatus;

export const selectAssignmentRequestStatus = (state: StateT): DispatchAssigmentStateT['assigmentStatus'] =>
    state.dispatchAssigment.assigmentStatus;

export const selectCurrentPositionById = (state: StateT): DispatchAssigmentStateT['currentPositionAssetById'] =>
    state.dispatchAssigment.currentPositionAssetById;

export const selectScheduleAssetById = (state: StateT): DispatchAssigmentStateT['scheduleAssetById'] =>
    state.dispatchAssigment.scheduleAssetById;

export const selectShowSelectedAssetSchedules = (
    state: StateT,
): DispatchAssigmentStateT['isShowSelectedAssetSchedules'] => state.dispatchAssigment.isShowSelectedAssetSchedules;

export const selectSelectedScheduleEventId = (state: StateT): DispatchAssigmentStateT['selectedScheduleEventId'] =>
    state.dispatchAssigment.selectedScheduleEventId;
