export enum FieldsEnum {
    driverWorkingDay = 'DRIVER_WORKING_DAY',
    driverRestTime = 'DRIVER_REST_TIME',
}

export type FormValuesT = {
    [FieldsEnum.driverWorkingDay]: string;
    [FieldsEnum.driverRestTime]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
