import * as React from 'react';

import LeftMenuLayout from '../LeftMenuLayout/LeftMenuLayout';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routesEnum } from 'broker-admin/constants';

import AccountPage from 'common/layouts/AccountPage/AccountPage';

import PartnersPage from '../PartnersPage/PartnersPage';
import PartnerPage from '../PartnerPage/PartnerPage';
import SettingsPage from '../SettingsPage/SettingsPage';
import OrdersPage from '../OrdersPage/OrdersPage';
import AssetDetailsPage from '../AssetDetailsPage/AssetDetailsPage';
import DispatchesPage from '../DispatchesPage/DispatchesPage';
import StatisticsPage from '../StatisticsPage/StatisticsPage';
import User from 'common/components/User/User';
import Company from 'common/components/Company/Company';
import RedirectPendingUser from 'common/components/RedirectPendingUser/RedirectPendingUser';
import SpotRequestsPage from '../SpotRequestsPage/SpotRequestsPage';
import InvoicesListPage from '../InvoicesListPage/InvoicesListPage';
import { QueryKeysEnum } from 'common/constants';
import { formatQuery } from 'common/utils/query';
import ShipperContractDetailsPage from 'broker-admin/layouts/ShipperContractDetailsPage/ShipperContractDetailsPage';
import ShipperContractLanePage from 'broker-admin/layouts/ShipperContractLaneDetailsPage/ShipperContractLanePage';

type PropsT = {};

const ClientPage: React.FC<PropsT> = React.memo((props) => {
    return (
        <>
            <User />
            <RedirectPendingUser />
            <Company />
            <LeftMenuLayout>
                <Switch>
                    <Route exact path={routesEnum.home}>
                        <Redirect to={routesEnum.dispatches} />
                    </Route>
                    <Route path={routesEnum.dispatches}>
                        <DispatchesPage />
                    </Route>
                    <Route path={routesEnum.spotRequests}>
                        <SpotRequestsPage />
                    </Route>
                    <Route path={routesEnum.orders}>
                        <OrdersPage />
                    </Route>
                    <Route path={routesEnum.invoices}>
                        <InvoicesListPage />
                    </Route>
                    <Route exact path={routesEnum.partners}>
                        <PartnersPage />
                    </Route>
                    <Route path={routesEnum.partner}>
                        <PartnerPage />
                    </Route>
                    <Route path={routesEnum.shipperContractDetails}>
                        <ShipperContractDetailsPage />
                    </Route>
                    <Route path={routesEnum.shipperLaneDetails}>
                        <ShipperContractLanePage />
                    </Route>
                    <Route path={routesEnum.assetDetails}>
                        <AssetDetailsPage />
                    </Route>
                    <Route path={routesEnum.settings}>
                        <SettingsPage />
                    </Route>
                    <Route path={routesEnum.statistics}>
                        <StatisticsPage />
                    </Route>
                    <Route path={routesEnum.account}>
                        <AccountPage />
                    </Route>
                    <Route path="*">
                        <Redirect
                            to={{
                                pathname: routesEnum.notFound,
                                search: formatQuery({
                                    [QueryKeysEnum.sourceUrl]: window.location.href,
                                }),
                            }}
                        />
                    </Route>
                </Switch>
            </LeftMenuLayout>
        </>
    );
});

export default ClientPage;
