import { SpotStateT, UPDATE_REQUEST_BEGIN, UPDATE_REQUEST_ERROR, UPDATE_REQUEST_SUCCESS, SpotActionT } from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: SpotStateT = {
    requestStatus: requestStatus.initial,
};

export default (state = initialState, action: SpotActionT | DestroySessionActionT): SpotStateT => {
    switch (action.type) {
        case UPDATE_REQUEST_BEGIN: {
            return {
                ...state,
                requestStatus: requestStatus.loading,
            };
        }

        case UPDATE_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                requestStatus: requestStatus.setError(error),
            };
        }

        case UPDATE_REQUEST_SUCCESS: {
            return {
                ...state,
                requestStatus: requestStatus.ok,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
