import React from 'react';
import { useTranslation } from 'react-i18next';
import { REPEAT_STRATEGY_T_MAP } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/constants';
import { checkIsRepeatStrategy } from '../../utils';

type PropsT = {
    repeatStrategy: string | null | undefined;
};

const RepeatStrategyFilterPill: React.FC<PropsT> = React.memo((props) => {
    const { repeatStrategy } = props;

    const { t } = useTranslation();

    if (checkIsRepeatStrategy(repeatStrategy)) {
        return <span>{t(REPEAT_STRATEGY_T_MAP[repeatStrategy])}</span>;
    }

    return <span>{repeatStrategy}</span>;
});

export default RepeatStrategyFilterPill;
