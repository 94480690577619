import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLegalFormsDict } from 'common/store/legal-forms-dict/actions';
import { CountryCodeT } from 'common/store/countries-dict/models';
import { selectLegalFormsDictById, selectLegalFormsDictRequest } from 'common/store/legal-forms-dict/selectors';

const useLegalFormDict = (countryCode: CountryCodeT | null) => {
    const dispatch = useDispatch();

    const legalFormsRequestStatus = useSelector(selectLegalFormsDictRequest(countryCode));
    const legalFormsById = useSelector(selectLegalFormsDictById(countryCode));

    React.useEffect(() => {
        if (countryCode) {
            dispatch(fetchLegalFormsDict(countryCode));
        }
    }, [countryCode]);

    return {
        legalFormsRequestStatus,
        legalFormsById,
    };
};

export { useLegalFormDict };
