import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerMembersPage.scss';
import Members from 'common/layouts/Members/Members';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import { useSelector } from 'react-redux';
import { selectPartnerDetailsById } from 'broker-admin/store/partner/details/selectors';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

const cx = classNames.bind(styles);

type PropsT = {};

const PartnerMembersPage: React.FC<PropsT> = React.memo(() => {
    const { partnerId } = usePartnerContext();

    const partnerDetailsById = useSelector(selectPartnerDetailsById);
    const partnerDetails = partnerDetailsById[partnerId] || null;

    return (
        <ContentMargins>
            <Members isLockedProfile={partnerDetails?.locked} />
        </ContentMargins>
    );
});

export default PartnerMembersPage;
