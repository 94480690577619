import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkMaxValue, checkMinValue, checkNotEmpty } from 'common/utils/form-validators';
import values from 'lodash/values';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import isNumber from 'lodash/isNumber';

const REQUIRED_FIELDS = values<FieldsEnum>(FieldsEnum);

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkMinValue(t, [FieldsEnum.firstRadiusOfAssignmentFilter], values, 10),
        ...checkMaxValue(t, [FieldsEnum.firstRadiusOfAssignmentFilter], values, 500),
        ...checkMaxValue(t, [FieldsEnum.secondRadiusOfAssignmentFilter], values, 1000),
        ...checkMaxValue(t, [FieldsEnum.thirdRadiusOfAssignmentFilter], values, 2000),
        ...checkMinValue(
            t,
            [
                FieldsEnum.timeToHookTrailer,
                FieldsEnum.timeToDropTrailer,
                FieldsEnum.timeToPickupTruck,
                FieldsEnum.timeToDropTruck,
            ],
            values,
            0,
        ),
        ...checkMaxValue(
            t,
            [
                FieldsEnum.timeToHookTrailer,
                FieldsEnum.timeToDropTrailer,
                FieldsEnum.timeToPickupTruck,
                FieldsEnum.timeToDropTruck,
            ],
            values,
            240,
        ),

        ...checkMinValue(t, [FieldsEnum.geoMatrixCoefficient], values, 1),
        ...checkMaxValue(t, [FieldsEnum.geoMatrixCoefficient], values, 2),
        ...checkMinValue(t, [FieldsEnum.averageTruckSpeed], values, 20),
        ...checkMaxValue(t, [FieldsEnum.averageTruckSpeed], values, 120),
    };

    const firstRadiusOfAssignmentFilter = parseSimpleNumber(values[FieldsEnum.firstRadiusOfAssignmentFilter]);
    const secondRadiusOfAssignmentFilter = parseSimpleNumber(values[FieldsEnum.secondRadiusOfAssignmentFilter]);
    const thirdRadiusOfAssignmentFilter = parseSimpleNumber(values[FieldsEnum.thirdRadiusOfAssignmentFilter]);

    if (
        isNumber(firstRadiusOfAssignmentFilter) &&
        isNumber(secondRadiusOfAssignmentFilter) &&
        secondRadiusOfAssignmentFilter <= firstRadiusOfAssignmentFilter
    ) {
        errors[FieldsEnum.secondRadiusOfAssignmentFilter] = t(
            'price-settings.assignment-form.errors.secondary-less-first',
        );
    }

    if (
        isNumber(firstRadiusOfAssignmentFilter) &&
        isNumber(thirdRadiusOfAssignmentFilter) &&
        thirdRadiusOfAssignmentFilter <= firstRadiusOfAssignmentFilter
    ) {
        errors[FieldsEnum.thirdRadiusOfAssignmentFilter] = t('price-settings.assignment-form.errors.third-less-first');
    }

    if (
        isNumber(secondRadiusOfAssignmentFilter) &&
        isNumber(thirdRadiusOfAssignmentFilter) &&
        thirdRadiusOfAssignmentFilter <= secondRadiusOfAssignmentFilter
    ) {
        errors[FieldsEnum.thirdRadiusOfAssignmentFilter] = t('price-settings.assignment-form.errors.third-less-second');
    }

    return errors;
};

export default validate;
