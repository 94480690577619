import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './ImportCsv.scss';
import { useTranslation } from 'react-i18next';
import CloseIcon from 'common/icons/CloseIcon';
import DoneIcon from 'common/icons/DoneIcon';
import Loader from 'common/components/Loader/Loader';
import Button from 'common/components/Button/Button';
import { selectUploadCSVFileRequest } from 'broker-admin/store/price-settings/rates/selectors';
import { useSelector } from 'react-redux';
import { StyleGuideColorsEnum } from 'common/constants';
import { ApiRequestError } from 'common/utils/api/errors/api-request-error';

const cx = classNames.bind(styles);

type PropsT = {
    onClose: () => void;
};

const ImportCsv: React.FC<PropsT> = (props) => {
    const { onClose } = props;

    const { t } = useTranslation();

    const requestStatus = useSelector(selectUploadCSVFileRequest);

    let content = null;

    if (requestStatus.loading) {
        content = (
            <>
                <div className={cx('status')}>{t('price-settings.rate-matrix.import-csv.status.checking-file')}</div>
                <div className={cx('status-icon')}>
                    <Loader size={20} className={cx('loader')} />
                </div>
            </>
        );
    }

    if (requestStatus.ok) {
        content = (
            <>
                <div className={cx('status')}>{t('price-settings.rate-matrix.import-csv.status.success')}</div>
                <div className={cx('status-icon')}>
                    <DoneIcon fillColor={StyleGuideColorsEnum.brandAccent} size={20} />
                </div>
                <div className={cx('description')} />
                <Button
                    className={cx('action')}
                    onClick={() => {
                        onClose();
                    }}
                >
                    {t('price-settings.rate-matrix.import-csv.action.ok')}
                </Button>
            </>
        );
    }

    if (requestStatus.error) {
        let errorDescription = requestStatus?.error?.message;
        if (requestStatus.error instanceof ApiRequestError) {
            errorDescription = requestStatus?.error?.response?.message;
        }

        content = (
            <>
                <div className={cx('status')}>{t('price-settings.rate-matrix.import-csv.status.wrong')}</div>
                <div className={cx('status-icon')}>
                    <CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />
                </div>
                <div className={cx('description')}>{errorDescription}</div>
                <Button
                    className={cx('action')}
                    onClick={() => {
                        onClose();
                    }}
                >
                    {t('price-settings.rate-matrix.import-csv.action.ok')}
                </Button>
            </>
        );
    }

    return (
        <div className={cx('wrap')}>
            <div className={cx('icon')} />
            <div className={cx('content')}>{content}</div>
        </div>
    );
};

export default ImportCsv;
