import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_DISPATCHES_PAGE_REQUEST, FetchDispatchesPageActionT } from './types';
import {
    fetchDispatchesPageBegin,
    fetchDispatchesPageError,
    fetchDispatchesPageSuccess,
    resetDispatches,
} from './actions';
import { selectDispatchesPages, selectDispatchesQuery } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import { dispatchesPaginationChannel } from 'broker-admin/store/dispatches/channels';

function* fetchDispatchesSaga(action: FetchDispatchesPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options } = action;

    const prevQuery: ReturnType<typeof selectDispatchesQuery> = yield select(selectDispatchesQuery);
    const pages: ReturnType<typeof selectDispatchesPages> = yield select(selectDispatchesPages);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetDispatches({ savingPageNumber: pageNumber }));
    }

    yield put(fetchDispatchesPageBegin(query, pageNumber));

    const [error, response]: ReturnApiT<typeof brokerTranziitApi.fetchDispatchesPage> =
        yield brokerTranziitApi.fetchDispatchesPage({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchDispatchesPageError(query, pageNumber, error));
        return;
    }

    yield put(fetchDispatchesPageSuccess(query, pageNumber, response));

    checkShouldEmitChangePage(pageNumber, response, dispatchesPaginationChannel);
}

function* dispatchesSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_DISPATCHES_PAGE_REQUEST, fetchDispatchesSaga);
}

export default dispatchesSaga;
