import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ExpiredPartnerCarrierContractCard.scss';
import { Trans } from 'react-i18next';
import ContractName from 'broker-admin/components/notification-cards/entities/ContractName/ContractName';

const cx = classNames.bind(styles);

type PropsT = {
    contractName: string;
};

const ExpiredPartnerCarrierContractCard: React.FC<PropsT> = React.memo((props) => {
    const { contractName } = props;

    return (
        <div className={cx('inner')}>
            <Trans
                i18nKey="notifications.partner-carrier-contract-expired"
                components={{
                    contractName: <ContractName contractName={contractName} />,
                }}
            />
        </div>
    );
});

export default ExpiredPartnerCarrierContractCard;
